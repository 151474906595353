import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Avatar, Spin } from "antd";
import Icon from "@ant-design/icons";
import merge from "lodash/merge";

// components
import Table from "../../../../shared/Table/Table";
import LabeledInput from "../../../../shared/LabeledInput";
import TableRow from "../../../../shared/Table/TableRow";
import TableFooter from "../../../../shared/Table/TableFooter";

// redux
import { getItemsRequest } from "../../../../../redux/items/itemsActions";

// helpers
import formatPrice from "../../../../../helpers/formatPrice";
import { Utils } from "../../../../../helpers";
import navigation from "../../../../../helpers/navigation";

const statusesFilterOptions = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Pending",
    value: "pending"
  },
  {
    label: "Available",
    value: "available"
  },
  {
    label: "Reserved",
    value: "reserved"
  },
  {
    label: "Sold",
    value: "sold"
  },
  {
    label: "Declined",
    value: "declined"
  }
];

const dateFilterOptions = [
  {
    label: "Newest items",
    value: "-date"
  },
  {
    label: "Oldest items",
    value: "date"
  },
  {
    label: "LA items",
    value: "LOVE_ANTIQUES"
  },
  {
    label: "Website builder items",
    value: "DEALER_WEBSITE"
  }
];


const ItemsTab = ({
  match,
  location,
  history,
  getItems,
  items,
  dealerData,
  totalNumberOfItems,
  itemsLoading
}) => {
  const [query, setQuery] = useState(
    {
      status: { label: "All", value: "" },
      ordering: { label: "Oldest items", value: "date" }
    }
  );

  const { page, perPage } = navigation.getTableQueryParams(location);
  const url = match.params.url;
  const dealerId = dealerData && dealerData.id;
  const dealerItems = items && items.filter((item) => item.dealer && item.dealer.id === dealerId);

  useEffect(() => {
    getItems({ page, perPage, query: { ...Utils.formatFilters(query), dealer: dealerId } });
  }, [page, perPage, dealerData.id]); // eslint-disable-line

  const handleSortItems = (value, option, key) => {
    const isStatusTriggered = key === "status";
    const isOrderingTriggered = key === "ordering";
    const isStatusValuePending = value === "pending";

    let orderingToSet = {};

    if (isOrderingTriggered) {
      orderingToSet = { label: option.children, value };
    }

    if (isStatusTriggered) {
      orderingToSet = { label: "Newest items", value: "-date" };

      if (isStatusValuePending) {
        orderingToSet = { label: "Oldest items", value: "date" };
      }
    }

    const newQuery = merge(query, {
      [key]: {
        label: option.children,
        value
      },
      ordering: orderingToSet
    });

    if (value === "All dealers") delete newQuery.dealer;

    setQuery(newQuery);

    navigation.setPage(1, location, history);

    getItems({
      page: 1,
      perPage,
      query: { ...Utils.formatFilters(newQuery), dealer: dealerId }
    });
  };

  if (itemsLoading) {
    return (
      <div className="justify-center mt-15">
        <Spin size="large" />
      </div>
    )
  }

  return (
    <div className="dealer-items-tab">
      <div className="dealer-items-tab--title">{url} Items</div>
      <div className="dealer-items-tab--filters-container">
        <LabeledInput
          className="status mr-16p"
          label="Status"
          type="select"
          options={statusesFilterOptions}
          value={query.status && query.status.label}
          placeholder="By Status"
          onChange={(value, option) => handleSortItems(value, option, "status")}
          horizontal
        />
        <LabeledInput
          className="status mr-16p"
          label=""
          type="select"
          options={dateFilterOptions}
          value={query.ordering && query.ordering.label}
          placeholder="By Date"
          onChange={(value, option) => handleSortItems(value, option, "ordering")}
          horizontal
        />
      </div>
      <div className="dealer-items-tab--table">
        {
          dealerItems.length ? (
            <Table
              columnWidths={["13%", "40%", "9%", "12%", "12%"]}
              headerCells={[
                { label: "status" },
                { label: "item" },
                { label: "price" },
                { label: "ref" },
                { label: "created" }
              ]}
            >
              {
                ({ columnWidths }) => {
                  return dealerItems && dealerItems.map(item => {
                    const {
                      id,
                      images,
                      status,
                      title,
                      price,
                      ref,
                      createdAt
                    } = item;
                    const mainImage = images.find(e => e.isMain) || null;
                    const laStatus = status.laStatus?.toLowerCase() || "";
                    const wbStatus = status.wbStatus?.toLowerCase() || "";

                    return (
                      <div key={`dealer-items-${id}`} className="dealer-items-tab--content__row">
                        <TableRow columnWidths={columnWidths}>
                          <div className="dealer-items-tab--table--status-column flex">
                            {dealerData.status.active !== "INACTIVE" && item.status.showIn !== "DEALER_WEBSITE" && (
                              <span className="dealer-items-tab--table--status-column--row flex">
                                <span className="dealer-items-tab--table--status-column--label">LA:</span>
                                <div className="status-panel disabled">
                                  <div className={`status-panel--item text-capitalize shorter ${laStatus}`}>
                                    {laStatus}
                                  </div>
                                </div>
                              </span>
                            )}
                            {dealerData.isBuilderFunctionality && item.status.showIn !== "LOVE_ANTIQUES" && (
                              <span className="dealer-items-tab--table--status-column--row flex">
                                <span className="dealer-items-tab--table--status-column--label">WB:</span>
                                <div className="status-panel disabled">
                                  <div className={`status-panel--item text-capitalize shorter ${wbStatus}`}>
                                    {wbStatus}
                                  </div>
                                </div>
                              </span>
                            )}
                          </div>
                          <div className="dealer-items-tab--table--item-column flex">
                            {mainImage ? (
                              <img className="img" src={mainImage.url} alt={mainImage.name} />
                            ) : (
                              <Avatar style={{ backgroundColor: "#ccc" }} className="img" shape="square">
                                none
                              </Avatar>
                            )}
                            <div className="dealer-items-tab--table--item-column--status">
                              <div className="dealer-items-tab--table--item-column--title">{title}</div>
                            </div>
                          </div>

                          <div className="dealer-items-tab--table--price-column flex">
                            { `£${formatPrice(price)}` }
                          </div>

                          <div className="dealer-items-tab--table--ref-column flex">
                            { Utils.emptyFieldFormat(ref, "-") }
                          </div>

                          <div className="dealer-items-tab--table--created-at-column flex">
                            { moment(createdAt).format("DD-MMM-YYYY") }
                          </div>

                          <div className="dealer-items-tab--table--actions-column flex">
                            <Icon
                              type="eye"
                              style={{ fontSize: "20px", color: "#717171" }}
                            />
                          </div>
                        </TableRow>
                      </div>
                    )
                  });
                }
              }

              {
                items && items.length > 0 &&
                <TableFooter
                  page={page}
                  perPage={perPage}
                  total={totalNumberOfItems}
                />
              }
            </Table>)
            : (
              <div className="dealer-items-tab--empty-data-message">
                There are no items available for this dealer.
              </div>
            )
        }
      </div>
    </div>
  )
};

ItemsTab.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dealerData: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  totalNumberOfItems: PropTypes.number,
  itemsLoading: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default connect(
  ({ dealers, items }) => ({
    dealerData: dealers.dealerData,
    items: items.items,
    totalNumberOfItems: items.totalNumberOfItems,
    itemsLoading: items.loading
  }),
  dispatch => ({
    getItems: payload => dispatch(getItemsRequest(payload)),
  })
)(withRouter(ItemsTab));

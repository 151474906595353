import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Spin } from "antd";
import _ from "lodash";
import { saveAs } from "file-saver";

import { getContentPagesRequest, updatePageStatusRequest } from "../../../redux/content/pages/pagesActions";
import ContentApi from "../../../api/content";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";
import EditPage from "./EditPage";
import LabeledInput from "../../shared/LabeledInput";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";

const sortPagesList = (pages, sortValue) => {
  const sortBy = sortValue === "By Title" ? "title" : "url";
  pages.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return -1;
    if (a[sortBy] > b[sortBy]) return 1;

    return 0;
  });

  return pages;
};

const Pages = ({
  location,
  history,
  getContentPages,
  pages,
  updatePageStatus,
  totalNumberOfPages = 10,
  loading
}) => {
  const [selectedPage, setSelectedPage] = useState(null);
  const [isEditFullScreen, setEditFullScreen] = useState(false);
  const [sortValue, setSortValue] = useState();
  const { order, search, page, perPage } = navigation.getTableQueryParams(location);


  // Effects

  useEffect(() => {
    getContentPages({ page, perPage });
  }, [page, perPage]); // eslint-disable-line


  // Methods

  const _download = () => {
    ContentApi.exportPages({ page, perPage }).then(data => {
      saveAs(data, "pages.xls");
    });
  };

  const onCheck = (e, pageUpdate) => {
    pageUpdate = Object.assign(pageUpdate, { isActive: e.target.checked });
    updatePageStatus({ pageUpdate, page, perPage });
  };

  const handleSortPages = (value) => {
    setSortValue(value)
  };

  const handleCloseEditPage = () => {
    setEditFullScreen(false);
    setSelectedPage("");
  };


  // Render

  const pagesToRender = Utils.filterAndOrder(pages, { order, search, searchKey: "title" });
  const sortedPagesToRender = sortValue
    ? sortPagesList(pagesToRender, sortValue)
    : pagesToRender;
  const currentSelectedPage = _.find(pages, { id: selectedPage });

  return (
    <div className="component pages">
      { loading ? <Spin /> : (
        <TableAndFormLayout showForm={!!currentSelectedPage} isFormFullscreen={isEditFullScreen}>
          <TableAndFormLayout.Header>
            <div className="component--header">
              <div className="component--header-section">
                <AddButton onClick={() => history.push("/content/pages/add-new-page")} />

                <div className="button" onClick={_download}>
                  <img className="button--icon" src="/images/xls_3x.svg" alt="xlm" />
                  <span className="button--text">Export XLS</span>
                </div>

                <div className="component--header-sort-button">
                  <LabeledInput
                    className="sort"
                    label="Sort"
                    type="select"
                    options={["By Title", "By URL"]}
                    value={sortValue}
                    placeholder="By Title"
                    onChange={(e) => handleSortPages(e)}
                    horizontal
                  />
                </div>
              </div>

              <SearchInput value={search} onChange={value => navigation.setSearch(value, location, history)} />
            </div>
          </TableAndFormLayout.Header>
          <TableAndFormLayout.Table>
            <Table
              className={`pages--table ${currentSelectedPage ? "pages--table__shrink" : ""}`}
              columnWidths={["40px", "25%", "auto"]}
              headerCells={[
                {},
                { label: "Page Title" },
                { label: "Url" }
              ]}
            >
              {
                sortedPagesToRender.map(pageToRender => {
                  return (
                    <TableRow
                      key={`pages-${pageToRender.id}`}
                      isHighlighted={selectedPage === pageToRender.id || !pageToRender.isActive}
                    >
                      <Checkbox
                        onChange={e => onCheck(e, pageToRender)}
                        checked={pageToRender.isActive}
                      />
                      <div className="title-column flex" onClick={() => setSelectedPage(pageToRender.id)}>{pageToRender.title}</div>
                      <div className="url-column flex" onClick={() => setSelectedPage(pageToRender.id)}>{pageToRender.url}</div>
                    </TableRow>
                  )
                })
              }

              {
                totalNumberOfPages > 0 &&
                  <TableFooter
                    key="footer"
                    page={page}
                    perPage={perPage}
                    total={totalNumberOfPages}
                  />
              }
            </Table>
          </TableAndFormLayout.Table>
          <TableAndFormLayout.Form>
            {
              currentSelectedPage ? (
                <EditPage
                  key={`selectedPage:${currentSelectedPage.id}`}
                  page={currentSelectedPage}
                  isFullScreen={isEditFullScreen}
                  toggleFullScreen={setEditFullScreen}
                  close={handleCloseEditPage}
                  pagePerPage = {{ page, perPage }}
                />
              ) : null
            }
          </TableAndFormLayout.Form>
        </TableAndFormLayout>
      )}
    </div>
  )
};

Pages.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getContentPages: PropTypes.func.isRequired,
  pages: PropTypes.array.isRequired,
  updatePageStatus: PropTypes.func.isRequired,
  totalNumberOfPages: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    pages: state.pages.pages,
    totalNumberOfPages: state.pages.totalNumberOfPages,
    loading: state.pages.loading,
  }),

  dispatch => ({
    getContentPages: payload => dispatch(getContentPagesRequest(payload)),
    updatePageStatus: payload => dispatch(updatePageStatusRequest(payload)),
  })
)(Pages);

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";

const createPathArray = (path) => {
  return path.split("/").slice(1, 3);
};

function SidebarRow({ label, link, icon: Icon, isInner }) {
  const { pathname } = useLocation();

  const isHighlighted = useMemo(() => {
    return _.isEqual(createPathArray(pathname), createPathArray(link));
  }, [pathname, link]);

  const className = classNames("sidebar--link", {
    selected: isHighlighted,
    "sidebar--link__inner": isInner,
  });

  return (
    <Link className={className} to={link}>
      {Icon && (
        <div className="sidebar--link-icon-holder">
          <Icon
            fillColor={isHighlighted ? "#FF0000" : "#717171"}
            className="sidebar--link-icon"
          />
        </div>
      )}
      {label}
    </Link>
  );
}

SidebarRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string.isRequired,
  icon: PropTypes.any,
  isInner: PropTypes.bool,
  style: PropTypes.object,
};

export default SidebarRow;

import React from "react";
import { Col, Form, Input, Radio, Row } from "antd";
import PropTypes from "prop-types";

// api
import content from "../../../../api/content";

// components
import ImageUploader from "../../../Dealers/components/DealerItem/ImagesTab/ImageUploader";
import TextLogo from "../TextLogo";


export const DirectoryEntryCoverImage = ({ form, textLogo }) => {
  const logo = Form.useWatch("logo", form);
  const shouldUseTextLogo = Form.useWatch("shouldUseTextLogo", form);

  const uploadNewLogo = (values) => {
    content
      .uploadNewImage(values)
      .then((resp) => {
        form.setFieldValue("logo", resp?.response)
      })
      .catch(console.error);
  }

  return (
    <div className="cover-image">
      <Form.Item name="logo" noStyle hidden>
        <Input />
      </Form.Item>
      <Form.Item name="shouldUseTextLogo" noStyle hidden>
        <Input />
      </Form.Item>
      <Form.Item name="textLogo" noStyle hidden>
        <Input />
      </Form.Item>
      <Radio.Group
        className="w-100 mb-16"
        value={shouldUseTextLogo}
        onChange={(evt) => form.setFieldValue("shouldUseTextLogo", evt.target.value)}
      >
        <Row>
          <Col md={10} xs={24}>
            <Radio value={false}>Picture</Radio>
          </Col>
          <Col md={14} xs={24}>
            <Radio value={true}>Text Logo</Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Row>
        <Col md={10} xs={24}>
          <ImageUploader
            currentImage={logo?.url || null}
            setPhoto={uploadNewLogo}
            removeImage={() => form.setFieldValue("logo", null)}
            maxWidth={256}
            maxHeight={256}
            bottomText="Image must be at least 300 x 300 pixels"
          />
        </Col>
        <Col md={14} xs={24}>
          <TextLogo
            defaultValue={textLogo}
            onChange={(value) => form.setFieldValue("textLogo", value)}
          />
        </Col>
      </Row>
    </div>
  );
};

DirectoryEntryCoverImage.propTypes = {
  form: PropTypes.object.isRequired,
  textLogo: PropTypes.string,
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Button } from "antd";

import LabeledInput from "../../shared/LabeledInput";
import { createNewPageRequest } from "../../../redux/content/pages/pagesActions";


const AddNewPage = ({
  error,
  processing,
  createNewPage,
  history
}) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const defaultURL = "https://www.loveantiques.com/";
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;
  const serverError = error || {};


  // Methods

  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    history.push({ pathname: "/content/pages" })
  }, [error, processing]); // eslint-disable-line

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createNewPage = () => {
    setFormSubmitted(true);

    createNewPage({
      isActive,
      title,
      pageContent,
      url
    });
  };


  // Render Functions

  const renderFields = () => (
    <>
      <div className="add-new-page--row checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isActive}
          onChange={onCheck}
        >
          Active
        </Checkbox>
      </div>
      <LabeledInput
        label="Title"
        isRequired
        value={title}
        onChange={value => { setTitle(value) }}
        errorText={serverError.title}
      />
      <div className="add-new-page--url-input-holder">
        <LabeledInput
          label="URL"
          isRequired
          value={url}
          suffix={urlFieldImage}
          addonBefore={defaultURL}
          inputClassName="input-with-prefix"
          errorText={serverError.url}
          onChange={value => { setUrl(value) }}
        />
      </div>
      <LabeledInput
        label="Page Content"
        type="rich-text"
        value={pageContent}
        onChange={value => { setPageContent(value) }}
        className="add-new-page--page-content-input"
      />
    </>
  );

  const renderActionsButtons = () => (
    <>
      <div className="add-new-page--button-holder">
        <Button className="ant-btn-primary" onClick={_createNewPage}>
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button
            className="btn-secondary"
            onClick={() => history.push({ pathname: "/content/pages" })}
          >
            <img src="/images/delete_3x.svg" alt="" />
            DELETE ITEM
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="add-new-page">
      <div className="add-new-page--row">
        <span className="add-new-page--title">Add New Page</span>
      </div>

      {renderFields()}
      {renderActionsButtons()}
    </div>
  )
}

AddNewPage.propTypes = {
  createNewPage: PropTypes.func.isRequired,
  error: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => state.pages,
  dispatch => ({
    createNewPage: payload => dispatch(createNewPageRequest(payload))
  })
)(AddNewPage);

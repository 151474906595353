import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { Badge, Select } from "antd";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { CreatePromoCode } from "../components/PromoCode/CreatePromoCode";

// react-queries
import { usePromoCodesList } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";

const FILTER_STATUS_OPTIONS = [
  { label: "All", value: "" },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const PromoCodes = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    page,
    perPage,
    statusOption = "",
  } = navigation.getTableQueryParams(location);

  const { data: promoCodes, isLoading } = usePromoCodesList({
    page: page,
    page_size: perPage,
    ...(statusOption && {
      is_active: statusOption === "ACTIVE" ? "true" : "false",
    }),
  });

  const handleStatusOptionChange = (value) => {
    const newQueryParams = { page: 1, statusOption: value };

    navigation.setDynamicKeys(newQueryParams, location, history);
  };

  return (
    <div>
      <div className="flex justify-between">
        <CreatePromoCode />
        <div className="flex items-center justify-center">
          <p className="mb-0 mr-16">Status:</p>
          <Select
            placeholder="Status"
            style={{ width: "160px" }}
            options={FILTER_STATUS_OPTIONS}
            value={statusOption}
            onChange={handleStatusOptionChange}
          />
        </div>
      </div>

      <Table
        isLoading={isLoading}
        columnWidths={["auto", "auto", "auto", "auto", "auto", "auto"]}
        headerCells={[
          { label: "Status" },
          { label: "Code" },
          { label: "Term (days)" },
          { label: "Activations" },
          { label: "Created" },
          { label: "Active To" },
        ]}
      >
        {promoCodes?.results?.map((promoCode) => {
          const {
            id,
            isActive,
            code,
            termDays,
            activations,
            activationsLimit,
            createdAt,
            activeTo,
          } = promoCode;

          return (
            <TableRow key={id}>
              <div className="flex">
                <Badge
                  text={isActive ? "Active" : "Inactive"}
                  color={isActive ? "green" : "red"}
                />
              </div>
              <div className="flex">{code}</div>
              <div className="flex">{termDays}</div>
              <div className="flex">
                {activations}/{activationsLimit}
              </div>
              <div className="flex created-column">
                {moment(createdAt).format("DD-MMM-YYYY")}
              </div>
              <div className="flex created-column">
                {moment(activeTo).format("DD-MMM-YYYY")}
              </div>
            </TableRow>
          );
        })}
      </Table>

      {promoCodes?.count > 0 && (
        <TableFooter
          key="promo-codes-footer"
          page={page}
          perPage={perPage}
          total={promoCodes.count}
        />
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Button, Checkbox } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { createOfferRequest, resetErrorsRequest } from "../../../../redux/settings/offers/offersActions";
import ContentApi from "../../../../api/content";

import LabeledInput from "../../../shared/LabeledInput";
import ImageUpload from "../../../shared/ImageUpload";


function AddNewOffer({ history, createOffer, processing, errors, resetErrors }) {
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [locationId, setLocationId] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [banner, setBanner] = useState(false);
  const [bannerError, setBannerError] = useState("");
  const [endOpen, setEndOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Effects
  useEffect(() => resetErrors, []); // eslint-disable-line

  useEffect(() => {
    if (!submitted || processing || Object.keys(errors).length) return;

    history.push("/settings/offers");
  }, [submitted, processing, errors]); // eslint-disable-line

  // Methods
  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const disabledStartDate = start => {
    if (!start || !toDate) {
      return false;
    }

    return start.valueOf() > toDate.valueOf();
  };

  const disabledEndDate = end => {
    if (!end || !fromDate) {
      return false;
    }

    return end.valueOf() <= fromDate.valueOf()
  };

  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file).then(result => {
      if (type === "images") {
        setImages(Array.from([...images, { ...result.response, uid: Date.now(), status: "done" }]));
      }
      if (type === "banner") {
        setBannerError("");
        setBanner(result.response);
      }
    }).catch(error => {
      if (type === "banner") setBannerError(error.message || error.data[0]);
      if (type === "images") {
        setImages(Array.from([...images, { originFileObj: file, uid: Date.now(), status: "error" }]))
      }
    })
  };

  const handleDeleteImage = ({ fileList }) => {
    if (fileList.length < images.length) setImages(fileList);
  };

  const onSave = () => {
    setSubmitted(true);

    createOffer({
      isActive,
      name,
      url,
      locationId,
      fromDate: fromDate && moment(fromDate).format("YYYY-MM-DD"),
      toDate: toDate && moment(toDate).format("YYYY-MM-DD"),
      description,
      images,
      banner: banner || null
    })
  };

  return (
    <div className="offers--add">
      <div className="general-form--row">
        <p className="general-form--title">Add Offer</p>
      </div>

      <div className="offers--add__small">
        <div className="general-form--row general-form--checkbox-holder">
          <Checkbox
            className="checkbox-small"
            checked={isActive}
            onChange={e => setIsActive(e.target.checked)}
          >
            Active
          </Checkbox>
        </div>

        <LabeledInput
          label="Name"
          value={name}
          onChange={setName}
          errorText={errors.name && errors.name[0]}
          isRequired
        />

        <LabeledInput
          label="URL"
          value={url}
          onChange={setUrl}
          errorText={errors.url && errors.url[0]}
          icon={<img src="/images/link_3x.svg" alt="" />}
        />

        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="Location ID"
              value={locationId}
              onChange={setLocationId}
              errorText={errors.locationId && errors.locationId[0]}
            />
          </div>
          <div className="general-form--column" />
        </div>

        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="From Date"
              disabledDate={disabledStartDate}
              type="date"
              value={fromDate}
              onChange={value => setFromDate(value)}
              onOpenChange={handleStartOpenChange}
              errorText={errors.fromDate && errors.fromDate[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="To Date"
              disabledDate={disabledEndDate}
              type="date"
              value={toDate}
              onChange={value => setToDate(value)}
              onOpenChange={setEndOpen}
              open={endOpen}
              errorText={errors.toDate && errors.toDate[0]}
            />
          </div>
        </div>

        <LabeledInput
          type="textarea"
          label="Description"
          value={description}
          onChange={setDescription}
          rows={5}
          errorText={errors.description && errors.description[0]}
        />
      </div>

      <ImageUpload
        topText="Images"
        bottomText="Please upload up to 3 images"
        height={186}
        setImage={file => uploadImage(file, "images")}
        lastUploadedImage={false}
        fileList={images}
        multiple={true}
        max={3}
        onChange={handleDeleteImage}
      />

      <ImageUpload
        topText="Banner Image"
        bottomText="Image must be at least 1420 x 440 pixels"
        height={256}
        setImage={file => uploadImage(file, "banner")}
        lastUploadedImage={banner}
        error={bannerError}
      />

      <div className="general-form--button-holder">
        <Button loading={processing} className="ant-btn-primary" onClick={onSave}>
          SAVE & CLOSE
        </Button>
        <Button className="btn-secondary" onClick={() => history.push("/settings/offers")}>
          CANCEL
        </Button>
      </div>
    </div>
  )
}

AddNewOffer.propTypes = {
  history: PropTypes.object.isRequired,
  createOffer: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  resetErrors: PropTypes.func.isRequired
};

export default connect(
  state => state.offers,
  dispatch => ({
    createOffer: payload => dispatch(createOfferRequest(payload)),
    resetErrors: () => dispatch(resetErrorsRequest())
  })
)(AddNewOffer);

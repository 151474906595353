import React, { useState } from "react";
import PropTypes from "prop-types";
import { DayPickerRangeController } from "react-dates";
import { withRouter } from "react-router-dom";

import LabeledInput from "./LabeledInput";
import { Utils } from "../../helpers";
import navigation from "../../helpers/navigation";


function CustomDateRangePicker({ dateFilter, location, history }) {
  const [customDateRange, setCustomDateRange] = useState({ startDate: null, endDate: null });
  const [focusedInputDatePicker, setFocusedInputDatePicker] = useState("startDate");
  const [rangePickerVisible, setRangePickerVisible] = useState(false);


  // Methods
  const onDateFilterChange = (value = "") => {
    // Show datepicker if Custom was selected
    if (value === "custom") {
      setCustomDateRange({ startDate: null, endDate: null });

      return setRangePickerVisible(true);
    }

    navigation.setDynamicKeys({ page: 1, dateFilter: value?.replace(" ", "_") }, location, history);
  };

  const onDateRangeChange = ({ startDate, endDate }) => {
    setCustomDateRange({ startDate, endDate });

    // Hide range picker
    if (focusedInputDatePicker === "endDate") setRangePickerVisible(false);

    if (!startDate || !endDate) return;

    // Set filter in query params
    const value = `${startDate.format("YYYY-MM-DD")} ${endDate.format("YYYY-MM-DD")}`;
    onDateFilterChange(value);
  };

  const onDateRangeFocusChange = focused => {
    if (!focused) focused = "startDate";

    setFocusedInputDatePicker(focused);
  };

  const onDateRangeOutsideClick = () => {
    setCustomDateRange({ ...customDateRange });
    setRangePickerVisible(false)
  };


  // Render
  return (
    <div className="custom-drp">
      <LabeledInput
        className="custom-drp--select"
        type="select"
        placeholder="By Date"
        options={[
          {
            label: "Last 14 days",
            value: Utils.calculateLastNDays(14).toString()
          },
          {
            label: "Last 30 days",
            value: Utils.calculateLastNDays(30).toString()
          },
          {
            label: "Last month",
            value: Utils.calculateLastMonth().toString()
          },
          {
            label: "Custom",
            value: "custom"
          }
        ]}
        // eslint-disable-next-line
        value={dateFilter?.replace("_", " ") || undefined}
        onChange={onDateFilterChange}
        allowClear={true}
      />

      {rangePickerVisible && (
        <DayPickerRangeController
          startDate={customDateRange.startDate}
          endDate={customDateRange.endDate}
          onDatesChange={onDateRangeChange}
          focusedInput={focusedInputDatePicker}
          onFocusChange={onDateRangeFocusChange}
          withPortal
          numberOfMonths={2}
          isOutsideRange={() => false}
          onOutsideClick={onDateRangeOutsideClick}
        />
      )}
    </div>
  );
}

CustomDateRangePicker.propTypes = {
  dateFilter: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(withRouter(CustomDateRangePicker));

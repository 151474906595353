import { all, put, call, takeLatest, select } from "redux-saga/effects";

import * as types from "./itemsActionsTypes";
import itemsApi from "../../api/items";
import _ from "lodash";
import navigation from "../../helpers/navigation";


function *getItems(action) {
  try {
    const correctApi = action.payload.query.query ? itemsApi.getItemsWithSearch : itemsApi.getItems;
    const response = yield call(correctApi, action.payload);

    yield put({ type: types.GET_ITEMS_REQUEST_SUCCESS, payload: response });
    if (action.callBack) {
      yield call(() => {
        action.callBack(false);
      });
    }
  } catch (error) {
    yield put({ type: types.GET_ITEMS_REQUEST_ERROR, payload: error });
  }
}

function *patchItems(action) {
  const { page, perPage } = navigation.getTableQueryParams(window.location);
  const params = { page, perPage };

  try {
    const { id, data, query, isRefetch = true } = action.payload;
    yield call(itemsApi.patchItems, id, data);
    yield put({ type: types.PATCH_ITEMS_DATA_SUCCESS });

    if (isRefetch) {
      yield put({ type: types.GET_ITEMS_REQUEST, payload: { ...params, query } });
    }
  } catch (error) {
    yield put({ type: types.PATCH_ITEMS_DATA_ERROR, payload: error });
  }
}

function *getChoices(action) {
  try {
    const response = yield call(itemsApi.getChoices, action.payload);
    yield put({ type: types.GET_CHOICES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CHOICES_REQUEST, payload: error })
  }
}

function *deleteItemsCatalogElement(action) {
  try {
    const { id, itemId, ...rest } = action.payload;
    yield call(itemsApi.deleteItemsCatalogElement, id, itemId);
    yield put({ type: types.DELETE_ITEMS_CATALOG_ELEMENT_SUCCESS });
    yield put({ type: types.GET_ITEMS_REQUEST, payload: rest });
  } catch (error) {
    yield put({ type: types.DELETE_ITEMS_CATALOG_ELEMENT_ERROR, payload: error });
  }
}

function *updateItemsStatus(action) {
  try {
    const { ref, data, ...rest } = action.payload;
    yield call(itemsApi.updateItemsStatus, ref, data);
    yield put({ type: types.UPDATE_ITEMS_STATUS_SUCCESS });
    yield put({ type: types.GET_ITEMS_REQUEST, payload: rest })
  } catch (error) {
    yield put({ type: types.UPDATE_ITEMS_STATUS_ERROR, payload: error });
  }
}

function *rotateImage(action) {
  try {
    const { items } = yield select(state => state.items);

    const { id, degree } = action.payload;
    const response = yield call(itemsApi.postRotateImage, { id, degree });

    const updatedItems = items.map(item => {
      const index = item.images.findIndex(e => e.id === id);

      // Image not found
      if (index === -1) {
        return item;
      }

      const newImages = _.cloneDeep(item.images);
      newImages[index] = { ...newImages[index], ...response };

      return { ...item, images: newImages };
    });

    yield put({ type: types.ROTATE_IMAGE_SUCCESS, payload: updatedItems });
  } catch (error) {
    yield put({ type: types.ROTATE_IMAGE_ERROR, payload: error });
  }
}

function *updateItemOnHomeStatus(action) {
  try {
    const { items } = yield select(state => state.items);
    const response = yield call(itemsApi.updateShowItemStatus, action.payload);

    const newItems = items.map(item => item.id === response.id ? response : item);
    yield put({ type: types.UPDATE_SHOW_ON_HOME_STATUS_REQUEST_SUCCESS, payload: newItems });
  } catch (error) {
    yield put({ type: types.UPDATE_SHOW_ON_HOME_STATUS_ERROR, payload: error });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_ITEMS_REQUEST, getItems),
    yield takeLatest(types.PATCH_ITEMS_DATA_REQUEST, patchItems),
    yield takeLatest(types.GET_CHOICES_REQUEST, getChoices),
    yield takeLatest(types.DELETE_ITEMS_CATALOG_ELEMENT_REQUEST, deleteItemsCatalogElement),
    yield takeLatest(types.UPDATE_ITEMS_STATUS_REQUEST, updateItemsStatus),
    yield takeLatest(types.ROTATE_IMAGE_REQUEST, rotateImage),
    yield takeLatest(types.UPDATE_SHOW_ON_HOME_STATUS_REQUEST, updateItemOnHomeStatus)
  ])
}

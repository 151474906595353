import React from "react";
import PropTypes from "prop-types";


function ZoomInIcon({ fillColor = "#FFFFFF", className = "" }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>2E597A32-1E60-4386-AE50-1D228406F8EE@3x</title>
      <desc>Created with sketchtool.</desc>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Items-With-Default-Cat" transform="translate(-439.000000, -342.000000)" fill={fillColor}>
          <g id="icon/24/zoom-in" transform="translate(436.000000, 339.000000)">
            <path d="M17.2048833,15.9519543 L21,19.7461849 L19.7461849,21 L15.9519543,17.2048833 C14.5401835,18.3366142 12.7841892,18.9521657 10.9747957,18.9495914 C6.57270848,18.9495914 3,15.3768829 3,10.9747957 C3,6.57270848 6.57270848,3 10.9747957,3 C15.3768829,3 18.9495914,6.57270848 18.9495914,10.9747957 C18.9521657,12.7841892 18.3366142,14.5401835 17.2048833,15.9519543 Z M15.3071365,15.1785651 C16.3949469,14.0598964 17.00245,12.5603633 17,11 C17,7.68456977 14.3145657,5 11,5 C7.68456977,5 5,7.68456977 5,11 C5,14.3145657 7.68456977,17 11,17 C12.5603633,17.00245 14.0598964,16.3949469 15.1785651,15.3071365 L15.3071365,15.1785651 L15.3071365,15.1785651 Z M10,10 L10,7 L12,7 L12,10 L15,10 L15,12 L12,12 L12,15 L10,15 L10,12 L7,12 L7,10 L10,10 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

ZoomInIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default ZoomInIcon

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import LabeledInput from "../../shared/LabeledInput";

import { getPhoneEnquiriesRequest } from "../../../redux/enquiries/phoneEnquiries/phoneEnquiriesActions";
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";
import CustomDateRangePicker from "../../shared/CustomDateRangePicker";

const RESULT_MAP = {
  "ANSWERED": "Answered",
  "HUNG_UP": "Caller Hung Up",
};


function PhoneEnquiries(props) {
  const { items, totalItems, location, history } = props;
  const { search, page, perPage, order, dateFilter, typeFilter } = navigation.getTableQueryParams(location);

  useEffect(() => {
    props.getPhoneEnquiries({ page, perPage, search, dateFilter, is_la: typeFilter });
  }, [page, perPage, search, dateFilter, typeFilter]); // eslint-disable-line

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const onTypeFilterChange = value => {
    navigation.setDynamicKeys({ page: 1, typeFilter: value }, location, history);
  };

  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="enquiries">
      <TableAndFormLayout showForm={false}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="flex w-100">
              <LabeledInput
                className="enquiries--select"
                label="Type"
                type="select"
                value={typeFilter}
                options={[
                  { value: "", label: "All" },
                  { value: "false", label: "Website enquiries" },
                  { value: "true", label: "LoveAntiques enquiries" }
                ]}
                onChange={onTypeFilterChange}
                horizontal
              />

              <CustomDateRangePicker dateFilter={dateFilter} />
            </div>

            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["150px", "160px", "160px", "75px", "70px", "130px", "100px", "60px"]}
            headerCells={[
              { label: "Dealer" },
              { label: "Called Number" },
              { label: "Source" },
              { label: "Date" },
              { label: "Time" },
              { label: "Duration" },
              { label: "Result" },
              { label: "Id" },
            ]}
          >
            {
              itemsToRender.map(item => {
                const domainToRender = item.domain.split("www.")[1].replace("/", "");

                return (
                  <TableRow key={`group:${item.id}`}>
                    <div className="flex table-text">{item.dealer.firstName} {item.dealer.lastName}</div>
                    <div className="flex table-sm-text">{item.calledNumber}</div>
                    <div className="flex table-sm-text destination-column">
                      <a
                        href={item.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="destination-column--content"
                      >
                        {domainToRender}
                      </a>
                    </div>
                    <div className="flex table-sm-text">{moment(item.datetime).format("DD/MM/YYYY")}</div>
                    <div className="flex table-sm-text">{moment(item.datetime).format("hh:mmA")}</div>
                    <div className="flex table-sm-text">{Utils.duration(item.duration)}</div>

                    <div className="flex">
                      <span className={`phone-enq--status ${item.resultStatus === "HUNG_UP" ? "hung" : "" }`}>
                        {RESULT_MAP[item.resultStatus] || ""}
                      </span>
                    </div>

                    <div className="flex table-sm-text">{item.id}</div>
                  </TableRow>
                )
              })
            }

            {totalItems && (
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            )}
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
}

PhoneEnquiries.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  refItems: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getPhoneEnquiries: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    items: state.phoneEnquiries.items,
    totalItems: state.phoneEnquiries.total,
    refItems: state.phoneEnquiries.refItems,
  }),
  dispatch => ({
    getPhoneEnquiries: params => dispatch(getPhoneEnquiriesRequest(params)),
  })
)(PhoneEnquiries);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { Spin, Select } from "antd";

import { getAdminAccountsHistoryRequest } from "../../../redux/settings/adminAccounts/adminAccountsActions";
import navigation from "../../../helpers/navigation";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableRow from "../../shared/Table/TableRow";
import CustomDateRangePicker from "../../shared/CustomDateRangePicker";
import AdminsSearch from "../../shared/AdminsSearch";
import admins from "../../../api/admins";


const AdminAccountsHistory = ({
  items,
  total,
  getAdminAccountsHistory,
  location,
  history,
  loading
}) => {
  const { page, perPage, dateFilter } = navigation.getTableQueryParams(location);
  const [searchAdminQuery, setSearchAdminQuery] = useState();
  const [activityTypeOptions, setActivityTypeOptions] = useState([]);
  const [activityType, setActivityType] = useState(null);


  // Methods
  useEffect(() => {
    admins.getFilterOptions().then(resp => {

      setActivityTypeOptions([{ label: "All", value: null }, ...resp]);
    })
  }, []); // eslint-disable-line

  useEffect(() => {
    const params = {
      page,
      perPage,
      activityType: activityType || "",
    };
    if (dateFilter) {
      const arrayOfDates = dateFilter.split("_");
      const fromDate = arrayOfDates[0];
      const toDate = arrayOfDates[1];
      params.fromDate = fromDate;
      params.toDate = toDate;
    }
    if (searchAdminQuery) params.name = searchAdminQuery;
    if (searchAdminQuery === "All Admins") delete params.name;

    getAdminAccountsHistory(params);
  }, [page, perPage, dateFilter, searchAdminQuery, activityType]); // eslint-disable-line

  const handleAdminFilterChange = (value) => {
    navigation.setDynamicKeys({ page: 1 }, location, history);
    setSearchAdminQuery(value);
  };

  return (
    <div className="admin-accounts-history">
      <div className="admin-accounts-history--filters-row">
        <div className="admin-accounts-history--date-filter">
          <CustomDateRangePicker dateFilter={dateFilter} />
        </div>
        <div className="admin-accounts-history--date-filter">
          <AdminsSearch chooseAdmin={(value) => handleAdminFilterChange(value)} />
        </div>
        <Select
          placeholder="By Action Type"
          className="admin-accounts-history--actions-filter"
          options={activityTypeOptions}
          value={activityType}
          onSelect={e => setActivityType(e)}
        />
      </div>
      {
        loading ?
          <Spin /> :
          _.isEmpty(items) ?
            <div className="admin-accounts-history--no-history">No history was found</div> :
            <>
              <Table
                columnWidths={["33.33%", "33.33%", "33.33%"]}
                headerCells={[
                  { label: "Name" },
                  { label: "Activity Time" },
                  { label: "Activity Type" },
                ]}
              >
                {
                  items.map(el => {
                    return (
                      <TableRow key={`group:${el.id}`}>
                        <div className="flex table-text">{el.name}</div>
                        <div className="flex table-sm-text">{moment(el.activityTime).format("DD/MM/YYYY HH:mm:ss")}</div>
                        <div className="flex table-sm-text">{el.activityType}</div>
                      </TableRow>
                    )
                  })
                }

                {
                  total &&
                  <TableFooter
                    key="footer"
                    page={page}
                    perPage={perPage}
                    total={total}
                  />
                }
              </Table>
            </>
      }
    </div>
  )
};

AdminAccountsHistory.propTypes = {
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  getAdminAccountsHistory: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    items: state.adminAccounts.historyList,
    total: state.adminAccounts.historyTotal,
    loading: state.adminAccounts.loading,
  }),
  dispatch => ({
    getAdminAccountsHistory: payload => dispatch(getAdminAccountsHistoryRequest(payload))
  })
)(AdminAccountsHistory);

import React from "react";
import { Route, Switch, Redirect, matchPath } from "react-router-dom";
import PropTypes from "prop-types";

import TabNavigation from "../../shared/TabNavigation";
import BlogArticles from "./BlogArticles/BlogArticles";
import BlogCategories from "./BlogCategories/BlogCategories";
import BlogAuthors from "./BlogAuthors/BlogAuthors";
import AddNewArticle from "./BlogArticles/AddNewArticle";
import EditArticle from "./BlogArticles/EditArticle";
import AddNewAuthor from "./BlogAuthors/AddNewAuthor";
import AddNewCategory from "./BlogCategories/AddNewCategory";


const renderNavigationTabs = (currentLocation) => {
  const isMatchAddArticleLocation = matchPath(currentLocation, {
    path: [
      "/content/blog/articles/add-new-article",
      "/content/blog/articles/add-new-article/preview/:url",
    ]
  });
  const isMatchAddCategoryLocation = matchPath(currentLocation, {
    path: ["/content/blog/categories/add-new-category"]
  });
  const isMatchEditArticleLocation = matchPath(currentLocation, {
    path: [
      "/content/blog/articles/edit-article",
      "/content/blog/articles/edit-article/preview/:url",
    ]
  });
  const isMatchPreviewArticleLocation = matchPath(currentLocation, {
    path: ["/content/blog/articles/preview/:url"]
  });
  const isMatchAddAuthorsLocation = matchPath(currentLocation, {
    path: ["/content/blog/authors/add-new-author"]
  });

  if (isMatchAddArticleLocation ||
    isMatchEditArticleLocation ||
    isMatchPreviewArticleLocation ||
    isMatchAddCategoryLocation ||
    isMatchAddAuthorsLocation
  ) {
    return null;
  }

  return (
    <TabNavigation
      items={[
        { label: "ARTICLES", link: "/content/blog/articles" },
        { label: "CATEGORIES", link: "/content/blog/categories" },
        { label: "AUTHORS", link: "/content/blog/authors" },
      ]}
    />
  )
}

function Blog({ location }) {
  return (
    <div className="component">
      {renderNavigationTabs(location.pathname)}

      <Switch>
        <Route path="/content/blog/articles/add-new-article" component={AddNewArticle} />
        <Route path="/content/blog/articles/edit-article/:url" component={EditArticle} />
        <Route path="/content/blog/articles" component={BlogArticles} />
        <Route exact path="/content/blog/categories" component={BlogCategories} />
        <Route path="/content/blog/categories/add-new-category" component={AddNewCategory} />
        <Route exact path="/content/blog/authors" component={BlogAuthors} />
        <Route path="/content/blog/authors/add-new-author" component={AddNewAuthor} />
        <Redirect to="/content/blog/articles" />
      </Switch>
    </div>
  );
}

Blog.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Blog;

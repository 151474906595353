import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { connect } from "react-redux";

import { Utils } from "../../../../helpers";
import { getBlogAuthorsRequest, updateBlogAuthorStatusRequest } from "../../../../redux/content/blog/blogActions";

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import navigation from "../../../../helpers/navigation";


function BlogAuthors({
  location,
  history,
  authors,
  getBlogAuthors,
  authorsTotal,
  updateBlogAuthorStatus
}) {
  const { order, page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getBlogAuthors({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const onCheck = (e, author) => {
    author = Object.assign(author, { isVisible: e.target.checked });
    updateBlogAuthorStatus({ author, page, perPage });
  };

  const authorsToRender = Utils.filterAndOrder(authors, { order });

  return (
    <div className="blog-authors-wrapper">
      <div className="blog--add-button">
        <AddButton
          onClick={() => history.push("/content/blog/authors/add-new-author")}
        />
      </div>

      <Table
        className="article--table"
        columnWidths={["40px", "15%", "15%", "auto"]}
        headerCells={[
          {},
          { label: "NAME" },
          { label: "URL" },
          { label: "DESCRIPTION" }
        ]}
      >
        {
          authorsToRender.map(author => {

            return (
              <TableRow key={`authors-${author.id}`}>
                <Checkbox
                  checked={author.isVisible}
                  onChange={e => onCheck(e, author)}
                />

                <div className="flex authors--row-block article--row-block__name">
                  <span>{author.name}</span>
                </div>
                <div className="flex authors--row-block__url">{author.url}</div>

                <div className="flex authors--row-block__description">{author.description}</div>
              </TableRow>
            )
          })
        }

        {
          authorsTotal > 0 && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={authorsTotal}
            />
          )
        }
      </Table>
    </div>
  )
}

BlogAuthors.propTypes = {
  location: PropTypes.object.isRequired,
  authors: PropTypes.array.isRequired,
  authorsTotal: PropTypes.number.isRequired,
  getBlogAuthors: PropTypes.func.isRequired,
  updateBlogAuthorStatus: PropTypes.func,
  history: PropTypes.object.isRequired
};

export default connect(
  state => state.blogs,
  dispatch => ({
    getBlogAuthors: payload => dispatch(getBlogAuthorsRequest(payload)),
    updateBlogAuthorStatus: payload => dispatch(updateBlogAuthorStatusRequest(payload))
  })
)(BlogAuthors);

import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "antd";

import { forgotPasswordRequest, clearError } from "../../redux/auth/authActions";


const ForgotPassword = ({ isProcessing, error, forgotPassword, clearAuthError }) => {
  const [showMessage, setShowMessage] = useState(false);

  // Component will mount
  useEffect(() => clearAuthError, []); // eslint-disable-line

  const validate = values => {
    const errors = {};

    if (!values.email) errors.general = "Email is required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) errors.general = "Invalid email address";

    return errors;
  };

  const onSubmit = values => {
    forgotPassword({ ...values, url: `${window.location.origin}/auth/reset-password` });
    setShowMessage(true);
  };


  return (
    <div className="auth">
      <div className="auth-block">
        <img className="auth-block--logo" src="/images/cms@3x.svg" width="144" height="32" alt="" />

        <span className="auth-block--input-label">
          Enter your email address and we will send you a link to reset your password.
        </span>

        <Formik
          initialValues={{ email: "dev-antique@gmail.com" }}
          validate={validate}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => (
            <Form className="auth-block--form">
              {
                (formikProps.errors.general || error) && (
                  <div className="auth-block--form-error">
                    <img className="auth-block--form-error-icon" src="/images/warning@3x.svg" alt="" />
                    {formikProps.errors.general || error}
                  </div>
                )
              }

              <div className="auth-block--input-header">
                <label className="auth-block--input-label">Email</label>
              </div>
              <Field type="email" name="email" className="auth-block--form-input" />

              <Button
                className="auth-block--form-submit"
                type="primary"
                disabled={isProcessing}
                loading={isProcessing}
                onClick={formikProps.handleSubmit}
              >
                {isProcessing ? "" : "SEND" }
              </Button>

              {
                showMessage && (
                  <span className="auth-block--success">Email was successfully sent to you</span>
                )
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

ForgotPassword.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default connect(
  state => ({ isProcessing: state.auth.processing, error: state.auth.error }),
  dispatch => ({
    forgotPassword: data => dispatch(forgotPasswordRequest(data)),
    clearAuthError: () => dispatch(clearError())
  })
)(ForgotPassword);

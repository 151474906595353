import { all, put, call, takeLatest } from "redux-saga/effects";
import { message } from "antd";

import enquiriesApi from "../../../api/enquiries";
import * as types from "./emailEnquiriesActionsTypes";
import navigation from "../../../helpers/navigation";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    let response = {};
    if (params.search) {
      response = yield call(enquiriesApi.searchEmail, params);
    } else {
      response = yield call(enquiriesApi.getEmail, params);
    }

    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *patch({ id, data, status }) {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(enquiriesApi.patchEmail, id, data);

    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST, params: { page, perPage, search, status } });
  } catch (error) {
    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *rejectAll() {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(enquiriesApi.rejectAllBlacklist);

    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *deleteEmailEnquiry({ id }) {
  const { page, perPage } = navigation.getTableQueryParams(window.location);
  const params = { page, perPage };

  yield put({ type: types.SHOW_PROCESSING });

  try {
    yield call(enquiriesApi.deleteEmailEnquiry, id);

    yield put({ type: types.GET_EMAIL_ENQUIRIES_REQUEST, params });
  } catch (error) {
    if (error.detail) {
      message.error(error.detail);
    }
    yield put({ type: types.DELETE_EMAIL_ENQUIRY_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_EMAIL_ENQUIRIES_REQUEST, get),
    yield takeLatest(types.PATCH_EMAIL_ENQUIRIES_REQUEST, patch),
    yield takeLatest(types.REJECT_ALL_BLACKLISTED_REQUEST, rejectAll),
    yield takeLatest(types.DELETE_EMAIL_ENQUIRY, deleteEmailEnquiry),
  ])
}

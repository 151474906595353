export const GET_EMAIL_PROFILES_REQUEST = "GET_EMAIL_PROFILES_REQUEST";
export const GET_EMAIL_PROFILES_REQUEST_SUCCESS = "GET_EMAIL_PROFILES_REQUEST_SUCCESS";
export const GET_EMAIL_PROFILES_REQUEST_ERROR = "GET_EMAIL_PROFILES_REQUEST_ERROR";

export const CREATE_EMAIL_PROFILE_REQUEST = "CREATE_EMAIL_PROFILE_REQUEST";
export const CREATE_EMAIL_PROFILE_REQUEST_SUCCESS = "CREATE_EMAIL_PROFILE_REQUEST_SUCCESS";
export const CREATE_EMAIL_PROFILE_REQUEST_ERROR = "CREATE_EMAIL_PROFILE_REQUEST_ERROR";

export const RESET_EMAIL_PROFILES_ERRORS = "RESET_EMAIL_PROFILES_ERRORS";

export const SHOW_EMAIL_PROFILES_PROCESSING = "SHOW_EMAIL_PROFILES_PROCESSING";
export const HIDE_EMAIL_PROFILES_PROCESSING = "HIDE_EMAIL_PROFILES_PROCESSING";

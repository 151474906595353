export const isNotEmptyString = (string) => {
  return typeof string === "string" && string?.trim().length > 0;
}

export const firstLetterCapitalize = (string) => {
  if (!isNotEmptyString(string)) return "";

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const buildFullName = (user, defaultValue = "") => {
  const firstName = user?.firstName?.trim() || "";
  const lastName = user?.lastName?.trim() || "";

  return [firstName, lastName].join(" ").trim() || defaultValue;
};

export const fromSnakeCaseToNormal = (string) => {
  if (!isNotEmptyString(string)) return "";

  return firstLetterCapitalize(string?.replace(/_/gi, " ") || "");
};

import React from "react";
import { DatePicker, Form, Input } from "antd";
import PropTypes from "prop-types";

// components
import { LantFormSection } from "./LantForm/LantFormSection";
import { LantFormItem } from "./LantForm/LantFormItem";
import { LantForm } from "./LantForm/LantForm";

// helpers
import { formErrorsHandler } from "../helpers";
import moment from "moment";


export const AddNewNoteForm = ({ onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields()
      .then(({ reminderIn, ...values }) => {
        onSubmit({
          ...values,
          reminderIn: reminderIn ? reminderIn.format("YYYY-MM-DD") : null
        });
      })
      .catch(formErrorsHandler);
  };

  return (
    <>
      <div style={{ margin: "24px 0 0 0" }} />
      <LantForm
        form={form}
        title="Add New Note"
        onSubmit={handleSubmit}
        onCancel={onCancel}
      >
        <LantFormSection>
          <LantFormItem name="reminderIn" label="Set Reminder" lg={10} xs={24}>
            <DatePicker
              format="YYYY-MM-DD"
              disabledDate={(date) => moment().isAfter(date)}
              className="w-100"
            />
          </LantFormItem>
          <LantFormItem name="status" label="Status" lg={10} xs={24} rules={[{ required: true }]}>
            <Input />
          </LantFormItem>
          <LantFormItem name="notes" label="Note" lg={20} xs={24} rules={[{ required: true }]}>
            <Input.TextArea rows={3} />
          </LantFormItem>
        </LantFormSection>
      </LantForm>
    </>
  );
};

AddNewNoteForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";

// components
import { LantForm, LantFormItem } from "../../../components";

// react-queries
import { useFairEventCreate, useFairEventUpdate } from "../../../react-query";

const { RangePicker } = DatePicker;

export const DirectoryEventModal = ({
  defaultDescription,
  fairId,
  event,
  onClose,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const buyTicketLink = event?.buyTicketLink?.includes("https://")
      ? event?.buyTicketLink?.replace("https://", "")
      : event?.buyTicketLink;

    const facebookUrl = event?.facebookUrl?.includes("https://")
      ? event?.facebookUrl?.replace("https://", "")
      : event?.facebookUrl;

    form.setFieldsValue({
      ...event,
      buyTicketLink,
      facebookUrl,
      eventDate: event?.fromDate
        ? [moment(event.fromDate), moment(event.toDate || event.fromDate)]
        : [],
    });
  }, [event]); // eslint-disable-line

  const closeModal = () => {
    onClose();
    form.resetFields();
  };

  const createDirectoryEvent = useFairEventCreate({
    onSuccess: closeModal,
  });

  const updateDirectoryEvent = useFairEventUpdate({
    onSuccess: closeModal,
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then(({ eventDate, ...values }) => {
        const buyTicketLink = values.buyTicketLink?.includes("https://")
          ? values?.buyTicketLink
          : `https://${values?.buyTicketLink}`;
        
        const facebookUrl = values?.facebookUrl?.includes("https://")
          ? values?.facebookUrl
          : `https://${values?.facebookUrl}`;

        const newEvent = {
          ...event,
          ...values,
          ...(values?.buyTicketLink && { buyTicketLink }),
          ...(values?.facebookUrl && { facebookUrl }),
          fromDate: eventDate[0]?.format("YYYY-MM-DD"),
          toDate: eventDate[1]?.format("YYYY-MM-DD"),
          fair: fairId,
        };

        if (newEvent.id) {
          updateDirectoryEvent.mutate(newEvent);
        } else {
          createDirectoryEvent.mutate(newEvent);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Modal
      title={event?.id ? "Edit Fair Event" : "Create Fair Event"}
      open={!!event}
      bodyStyle={{ paddingBottom: "0" }}
      okText="Save"
      confirmLoading={
        createDirectoryEvent.isLoading || updateDirectoryEvent.isLoading
      }
      onCancel={closeModal}
      onOk={handleSubmit}
    >
      <LantForm form={form} initialValues={{ description: defaultDescription }}>
        <LantFormItem
          name="eventDate"
          label="Fair Date"
          rules={[{ required: true }]}
        >
          <RangePicker
            style={{ width: "100%" }}
            disabledDate={(day) => day.isBefore(moment(), "days")}
          />
        </LantFormItem>
        <LantFormItem
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </LantFormItem>
        <LantFormItem
          name="admission"
          label="Admission"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </LantFormItem>
        <LantFormItem name="openingTimes" label="Opening Times">
          <Input.TextArea />
        </LantFormItem>
        <LantFormItem
          name="buyTicketLink"
          label="Link to website to buy tickets"
          rules={[{ type: "url", message: "Please enter a valid URL" }]}
        >
          <Input addonBefore="https://" />
        </LantFormItem>
        <LantFormItem
          name="facebookUrl"
          label="Facebook Event Link"
          rules={[{ message: "Please enter a valid Facebook URL", pattern: /(?:https?:\/\/)?(?:www\.)?facebook\.com/ }]}
        >
          <Input addonBefore="https://" placeholder="https://www.facebook.com/example" />
        </LantFormItem>
      </LantForm>
    </Modal>
  );
};

DirectoryEventModal.propTypes = {
  defaultDescription: PropTypes.string,
  fairId: PropTypes.number,
  event: PropTypes.any,
  onClose: PropTypes.func,
};

import React, { useState, useEffect } from "react";
import { Select, Modal, Button, Checkbox, Input, Form, Popconfirm, message, Row, Col } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";

// helpers
import navigation from "../../../helpers/navigation";
import dealers from "../../../api/dealers";

// redux
import { setDealersTestimonialsCountAction } from "../../../redux/dealers/dealersActions";
import { useReduxAction } from "../../../hooks";
import TextArea from "antd/lib/input/TextArea";
import DeleteIcon from "../../Icon/DeleteIcon";


const selectOptions = [
  { value: "all", label: "All Testimonials" },
  { value: "approved", label: "Approved" },
  { value: "notApproved", label: "Not Approved" },
];

function DealerTestimonials() {
  const [modalData, setModalsData] = useState(null);
  const [testimonialsResp, setTestimonialsResp] = useState({ count: 0, results: [] });

  const setCount = useReduxAction(setDealersTestimonialsCountAction);
  const history = useHistory();
  const { page = 1, perPage = 15, filter: navigationFilter } = navigation.getTableQueryParams(history.location);

  const [form] = Form.useForm();

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        ...modalData,
        createdAt: moment(modalData.createdAt).format("DD.MM.YYYY hh:mm")
      });
    } else {
      form.resetFields();
    }
  }, [modalData]); // eslint-disable-line

  const filter = navigationFilter || "notApproved";

  const fetchData = (params) => {
    dealers.getDealersTestimonials(params)
      .then(resp => {
        setCount(resp.count || 0);
        setTestimonialsResp(resp);
      })
  }

  useEffect(() => {
    fetchData({ page, perPage, isApproved: !filter || filter === "all" ? null : `${filter === "approved"}` })
  }, [page, perPage, filter]); // eslint-disable-line

  const onDelete = (id) => {
    dealers.deleteDealersTestimonial(id)
      .then(() => {
        message.success("Testimony was deleted");
        fetchData({ page, perPage, isApproved: !filter || filter === "all" ? null : `${filter === "approved"}` });
      })
      .catch(() => message.error("Something went wrong!"));
  }

  const onApprove = (id, isApproved) => {
    dealers.patchDealersTestimonial(id, { isApproved })
      .then(() => setTestimonialsResp(state => {
        return {
          ...testimonialsResp,
          results: state.results.map(item => (item.id !== id ? item : { ...item, isApproved }))
        };
      }))
      .catch(() => {
        message.error("Something went wrong!");
        setTestimonialsResp(state => {
          return {
            ...state,
            results: state.results.map(item => (item.id !== id ? item : { ...item, isApproved: !isApproved }))
          };
        });
      })
      .finally(() => setModalsData(null));
  }
  const onSubmit = (id, data) => {
    dealers.patchDealersTestimonial(id, { ...data })
      .then((response) => setTestimonialsResp(state => {
        return {
          ...state,
          results: state.results.map(item => (item.id !== id ? item : { ...item, ...response }))
        };
      }))
      .catch(() => {
        message.error("Something went wrong!");
      })
      .finally(() => setModalsData(null))
  }

  return (
    <div className="dealers-list">
      <div className="component--header">
        <div className="dealers-list--sort-by-status-select">
          <Select
            placeholder="Filter by:"
            className="select"
            defaultValue="notApproved"
            options={selectOptions}
            value={filter}
            onSelect={e => navigation.setDynamicKeys({ page, perPage, filter: e }, history.location, history)}
          />
        </div>
      </div>
      <div className="dealers-list--content flex">
        <Table
          columnWidths={["70px", "200px", "200px", "auto", "150px", "150px"]}
          headerCells={[
            { label: "" },
            { label: "author" },
            { label: "reviewed dealer" },
            { label: "testimonial" },
            { label: "created at" },
            { label: "actions" }
          ]}
        >
          {testimonialsResp.results.map((item, index) => (
            <TableRow key={index}>
              <div onClick={e => e.stopPropagation()}>
                <Checkbox checked={item.isApproved} onChange={e => onApprove(item.id, e.target.checked)} />
              </div>
              <div className="dealers-list--details-column flex">
                {item.author}
              </div>
              <div className="dealers-list--details-column flex">
                {item.dealer}
              </div>
              <div className="dealers-list--address-column flex">
                <span className="dealers-list--address-column_text" onClick={() => setModalsData(item)}>
                  {item.testimonial.length > 100 ? `${item.testimonial.slice(0, 100)}...` : item.testimonial}
                </span>
              </div>
              <div className="dealers-list--details-column flex">
                {moment(item.createdAt).format("DD.MM.YYYY hh:mm")}
              </div>
              <div className="dealers-list--actions-column" onClick={e => e.stopPropagation()}>
                <Popconfirm
                  title="Are you sure you want to delete this testimony?"
                  onConfirm={() => onDelete(item.id)}
                  placement="topRight"
                >
                  <DeleteIcon className="cursor-pointer" />
                </Popconfirm>
              </div>
            </TableRow>
          ))}

          {testimonialsResp.count > 0 && (
            <TableFooter
              key="dealers-table-footer"
              page={page}
              perPage={perPage}
              total={testimonialsResp.count}
            />
          )}
        </Table>
      </div>

      <Modal
        open={!!modalData}
        onCancel={() => setModalsData(null)}
        footer={null}
      >
        {!!modalData && (
          <>
            <Form
              key={`modal-${modalData.id}`}
              form={form}
              layout="vertical"
              onFinish={(values) => onSubmit(modalData.id, {
                isApproved: values.isApproved,
                author: values.author,
                testimonial: values.testimonial
              })}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="createdAt" label="created at" className="dealer-testimony__container-label">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="dealer" label="Reviewed dealer" className="dealer-testimony__container-label">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="author" label="Author" className="dealer-testimony__container-label">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="isApproved" valuePropName="checked" className="dealer-testimony__container-label" label="is approved">
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="testimonial" label="Testimonial" className="dealer-testimony__container-label">
                    <TextArea />
                  </Form.Item >
                </Col>
              </Row>
              <div className="general-form--button-holder justify-end">
                <Button htmlType="submit" className="ant-btn-primary">
                  SAVE & CLOSE
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this testimony?"
                  onConfirm={() => {
                    onDelete(modalData.id);
                    setModalsData(null);
                  }}
                  placement="topRight"
                >
                  <Button type="primary" className="ant-btn-primary">
                    <DeleteOutlined/>DELETE
                  </Button>
                </Popconfirm>
              </div>
            </Form>
          </>
        )}
      </Modal>
    </div>
  );
}

export default DealerTestimonials;

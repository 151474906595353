import fetch from "./customFetch";


const settings = {
  async getAdvancedPreferences() {
    try {
      return await fetch({
        url: "/settings/advanced-preferences/",
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchAdvancedPreferences(data) {
    try {
      return await fetch({
        url: "/settings/advanced-preferences/",
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSystemPreferences() {
    try {
      return await fetch({
        url: "/settings/system-preferences/",
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchSystemPreferences(data) {
    try {
      return await fetch({
        url: "/settings/system-preferences/",
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getGeneralProperties() {
    try {
      return await fetch({
        url: "/settings/general-properties/",
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchGeneralProperties(data) {
    try {
      return await fetch({
        url: "/settings/general-properties/",
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getEmailProfiles({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/settings/email-profile/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postEmailProfile(data) {
    try {
      return await fetch({
        url: "/settings/email-profile/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getPayPalObject() {
    try {
      return await fetch({
        url: "/settings/paypal/",
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePayPal(data) {
    try {
      return await fetch({
        url: "/settings/paypal/",
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getOffers({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/settings/offers/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchOffer(id, data) {
    try {
      return await fetch({
        url: `/settings/offers/${id}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createOffer(data) {
    try {
      return await fetch({
        url: "/settings/offers/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getUserOffers({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/settings/offers/user-offers/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createUserOffer(data) {
    try {
      return await fetch({
        url: "/settings/offers/user-offers/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default settings;

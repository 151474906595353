import React from "react";
import PropTypes from "prop-types";

import {
  Map,
  Marker,
  TileLayer
} from "react-leaflet";

const LocationComponent = ({ location, setLocation }) => (
  <Map center={location} zoom={10}>
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
    />
    <Marker
      position={location}
      draggable={true}
      onDragend={({ target }) => {
        const newLocation = [target._latlng.lat, target._latlng.lng];
        setLocation(newLocation);
      }}
    />
  </Map>
)

LocationComponent.propTypes = {
  location: PropTypes.array,
  setLocation: PropTypes.func.isRequired
};

export default LocationComponent;

import React from "react";
import PropTypes from "prop-types";


const DownloadIcon = ({ fillColor = "#717171", className = "", onClick }) => {
  return (
    <svg onClick={onClick} className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/edit-copy-14-icon/24/download" fill={fillColor}>
          <path d="M5.6,17.4444444 L18.4,17.4444444 L18.4,12 L20,12 L20,18.2222222 C20,18.651777 19.6418278,19 19.2,19 L4.8,19 C4.3581722,19 4,18.651777 4,18.2222222 L4,12 L5.6,12 L5.6,17.4444444 Z M13.6,9.66666667 L17.6,9.66666667 L12,15.1111111 L6.4,9.66666667 L10.4,9.66666667 L10.4,5 L13.6,5 L13.6,9.66666667 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

DownloadIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DownloadIcon;

import * as types from "./ordersActionsTypes";
import selector from "./ordersSelectors";

const initialState = {
  orders: [],
  ordersTotalNumber: 0,
  ordersChoices: [],
  error: null,
  processing: false,
  historiesTotalNumber: 0
};

const items = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS_REQUEST_SUCCESS:
    case types.SEARCH_ORDERS_REQUEST_SUCCESS:
    case types.PATCH_ORDER_REQUEST_SUCCESS:
    case types.GET_FILTERED_ORDERS_REQUEST_SUCCESS:
      return {
        ...state,
        orders: action.payload.results,
        ordersTotalNumber: action.payload.count,
        historiesTotalNumber: selector.totalNumberOfOrderHistory(action.payload.results)
      };

    case types.GET_ORDERS_CHOICES_SUCCESS:
      return {
        ...state,
        statusChoices: action.payload.status,
        paymentStatusChoices: action.payload.paymentStatus
      };

    case types.GET_ORDERS_REQUEST_ERROR:
    case types.GET_ORDERS_CHOICES_ERROR:
    case types.SEARCH_ORDERS_REQUEST_ERROR:
    case types.PATCH_ORDER_REQUEST_ERROR:
    case types.GET_FILTERED_ORDERS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.TOGGLE_SEARCH_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      }

    default:
      return state;
  }
};

export default items;

import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = ({ id }) => {
  return fetch({
    url: `/subscriptions/subscriptions-cancel-requests/${id}/approve/`,
    method: "POST",
    data: {}
  });
};

export const useTradeSubscriptionCancelRequestApprove = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      /*TODO(fair/directory)*/
      queryClient.invalidateQueries(["directory-entry", args?.[0]?.directoryEntryId]);

      queryClient.invalidateQueries([
        "subscription-cancel-request-list",
        JSON.stringify({ page: 1, page_size: 1, status: "SUBMITTED" })
      ]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

import React from "react";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";

// react-queries
import { useTradeSubscriptionPlanList } from "../../../react-query";


export const DirectoryPlans = () => {
  const { data } = useTradeSubscriptionPlanList();

  return (
    <div className="directory-plans">
      <Table
        className="directory-plans--table"
        columnWidths={["auto", "150px", "70px", "70px"]}
        headerCells={[
          { label: "NAME" },
          { label: "PERIOD" },
          { label: "PRICE" },
          { label: "SAVING" },
        ]}
      >
        {data?.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex">
                {item.name}
              </div>
              <div className="flex">
                {item.period}
              </div>
              <div className="flex">
                {item.price}
              </div>
              <div className="flex">
                {item.saving}
              </div>
            </TableRow>
          )
        })}
      </Table>
    </div>
  )
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Spin, Button } from "antd";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//components
import { LinkInput } from "../../shared/LinkInput";
import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";

//redux
import {
  createTradeAssociationRequest,
  updateTradeAssociationRequest,
  deleteTradeAssociationRequest,
} from "../../../redux/dealers/dealersActions";

//helpers
import dealersAPI from "../../../api/dealers";
import contentAPI from "../../../api/content";

const AddEditTradeAssociationPage = (props) => {
  const [tradeAssociationProcessing, setTradeAssociationProcessing] =
    useState(false);
  const [tradeAssociationName, setTradeAssociationName] = useState("");
  const [tradeAssociationLink, setTradeAssociationLink] = useState("");
  const [tradeAssociationImage, setTradeAssociationImage] = useState({});
  const [error, setError] = useState({});

  let associationLinkError = error.tradeAssociationLink;
  if (props.error && props.error.link) {
    associationLinkError = props.error.link[0];
  }

  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      return;
    }

    setTradeAssociationProcessing(true);
    dealersAPI
      .getTradeAssociationData(params.id)
      .then((resp) => {
        setTradeAssociationLink(resp.link);
        setTradeAssociationName(resp.name);
        setTradeAssociationImage(resp.logo);
      })
      .catch(console.warn)
      .finally(() => setTradeAssociationProcessing(false));
  }, []); // eslint-disable-line

  const uploadImage = (file) => {
    contentAPI
      .uploadNewImage(file)
      .then((result) => setTradeAssociationImage(result.response))
      .catch(console.warn);
  };

  const handleSubmit = () => {
    setError({});
    const formErrors = {};
    if (!tradeAssociationName) {
      formErrors.tradeAssociationName = "This field may not be blank";
    }

    if (!tradeAssociationLink) {
      formErrors.tradeAssociationLink = "This field may not be blank";
    }

    if (!Object.keys(tradeAssociationImage).length) {
      formErrors.tradeAssociationImage = "You should upload image";
    }

    if (Object.keys(formErrors).length) {
      setError(formErrors);

      return;
    }

    const data = {
      name: tradeAssociationName,
      link: tradeAssociationLink,
      logo: tradeAssociationImage,
    };

    const callback = () => props.history.goBack();

    if (params.id) {
      return props.updateTradeAssociation(params.id, data, callback);
    }

    props.createTradeAssociation(data, callback);
  };

  const handleDelete = () => {
    if (params.id) {
      return props.deleteTradeAssociation(params.id, props.history.goBack);
    }

    props.history.goBack();
  };

  if (tradeAssociationProcessing) {
    return (
      <div className="trade-associations--spinner-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="trade-associations single-association-container">
      <div className="single-association-container--name">
        {params.id ? `EDIT “${tradeAssociationName}”` : "Add Trade Association"}
      </div>

      <div className="single-association-container--form">
        <LabeledInput
          label="Association Name"
          isRequired
          value={tradeAssociationName}
          onChange={setTradeAssociationName}
          errorText={error.tradeAssociationName}
        />

        <LinkInput
          label="Link"
          isRequired
          value={tradeAssociationLink}
          onChange={setTradeAssociationLink}
          errorText={associationLinkError}
        />

        <div className="single-association-container--form--uploader">
          <span className="single-association-container--form--uploader--hint">
            Upload <span className="red-color">*</span>
          </span>
          <ImageUpload
            width={200}
            height={75}
            bottomText={`Logotypes appear on dealer profile and website.
            Recommended size 120x120px in a .png format`}
            lastUploadedImage={tradeAssociationImage}
            setImage={(file) => uploadImage(file)}
            error={error.tradeAssociationImage}
          />
        </div>

        <div className="single-association-container--form--btns-container">
          <Button className="ant-btn-primary" onClick={handleSubmit}>
            SAVE & CLOSE
          </Button>

          <Button className="delete-btn" onClick={handleDelete}>
            {params.id && (
              <img
                className="ant-btn-icon"
                src="/images/delete_3x.svg"
                alt="Delete Icon"
              />
            )}
            {params.id ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </div>
      </div>
    </div>
  );
};

AddEditTradeAssociationPage.propTypes = {
  history: PropTypes.object.isRequired,
  createTradeAssociation: PropTypes.func.isRequired,
  updateTradeAssociation: PropTypes.func.isRequired,
  deleteTradeAssociation: PropTypes.func.isRequired,
  error: PropTypes.any,
};

export default connect(
  (state) => ({
    error: state.dealers.error,
  }),
  {
    createTradeAssociation: createTradeAssociationRequest,
    updateTradeAssociation: updateTradeAssociationRequest,
    deleteTradeAssociation: deleteTradeAssociationRequest,
  }
)(AddEditTradeAssociationPage);

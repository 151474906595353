import React from "react";
import PropTypes from "prop-types";


function CloseIcon({ fillColor = "#717171", className = "", onClick }) {
  return (
    <svg onClick={onClick} className={className} width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
        <g transform="translate(-1317.000000, -336.000000)" fill={fillColor}>
          <g transform="translate(1312.000000, 331.000000)">
            <polygon id="Path" points="12 10.586 16.95 5.636 18.364 7.05 13.414 12 18.364 16.95 16.95 18.364 12 13.414 7.05 18.364 5.636 16.95 10.586 12 5.636 7.05 7.05 5.636" />
          </g>
        </g>
      </g>
    </svg>
  )
}

CloseIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default CloseIcon

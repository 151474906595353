import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (data) => {
  return fetch({
    url: "/directory/directory-notes/",
    method: "POST",
    data
  });
};

export const useDirectoryNoteCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["directory-notes-list"]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

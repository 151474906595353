import { useMutation } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = ({ id, ...data }) => {
  return fetch({
    url: `/directory/${id}/`,
    method: "PATCH",
    data
  });
};

export const useDirectoryEntryPatch = (options) => {
  return useMutation(fetcher, options);
};

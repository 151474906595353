import React from "react";
import PropTypes from "prop-types";


function HelpIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/help-copy-2-icon/24/help" fill={fillColor}>
          <path d="M12,21 C7.0293,21 3,16.9707 3,12 C3,7.0293 7.0293,3 12,3 C16.9707,3 21,7.0293 21,12 C21,16.9707 16.9707,21 12,21 Z M11,15 L11,17 L13,17 L13,15 L11,15 Z M13,13.355 C14.6610148,12.8543757 15.705157,11.2131154 15.4547342,9.49646657 C15.2043113,7.77981775 13.7348104,6.50524497 12,6.5 C10.3315752,6.49987284 8.89487097,7.6771082 8.567,9.313 L10.529,9.706 C10.6838697,8.93117447 11.4132539,8.40934647 12.1965836,8.51294995 C12.9799134,8.61655344 13.5485571,9.31005909 13.4966679,10.0985049 C13.4447788,10.8869506 12.7901514,11.4999504 12,11.5 C11.4477153,11.5 11,11.9477153 11,12.5 L11,14 L13,14 L13,13.355 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

HelpIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default HelpIcon

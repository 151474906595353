import { all, put, call, takeLatest } from "redux-saga/effects";


import * as types from "./helpTextActionsTypes";
import contentAPI from "../../../api/content";


function *getHelpTextItems (action) {
  try {
    const response = yield call(contentAPI.getHelpTextItems, action.payload);
    
    yield put({ type: types.LOADING, payload: true });
    yield put({ type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.LOADING, payload: false });
  }
}

function *patchHelpTextItem (action) {
  try {
    const { helpTextItem, page, perPage } = action.payload;

    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: true });
    yield call(contentAPI.patchHelpTextItem, helpTextItem);
    yield put({ type: types.PATCH_HELP_TEXT_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_HELP_TEXT_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: false });
  }
}

function *createHelpTextItem (action) {
  try {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: true });
    yield call(contentAPI.createHelpTextItem, action.payload);
    yield put({ type: types.CREATE_HELP_TEXT_ITEM_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_HELP_TEXT_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: false });
  }
}

function *getHelpTextChoices (action) {
  try {
    yield put({ type: types.LOADING, payload: true });
    const response = yield call(contentAPI.getHelpTextChoices, action.payload);
    yield put({ type: types.GET_HELP_TEXT_CHOICES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_HELP_TEXT_CHOICES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.LOADING, payload: false });
  }
}


function *putHelpTextItem (action) {
  try {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: true });
    const { id, data, page, perPage } = action.payload;
    yield call(contentAPI.putHelpTextItem, id, data);
    yield put({ type: types.PUT_HELP_TEXT_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PUT_HELP_TEXT_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: false });
  }
}

function *deleteHelpTextItem (action) {
  try {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: true });
    const { id, page, perPage } = action.payload;
    yield call(contentAPI.deleteHelpTextItem, id);
    yield put({ type: types.DELETE_HELP_TEXT_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.DELETE_HELP_TEXT_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST, getHelpTextItems),
    yield takeLatest(types.PATCH_HELP_TEXT_ITEM_REQUEST, patchHelpTextItem),
    yield takeLatest(types.CREATE_HELP_TEXT_ITEM_REQUEST, createHelpTextItem),
    yield takeLatest(types.GET_HELP_TEXT_CHOICES_REQUEST, getHelpTextChoices),
    yield takeLatest(types.PUT_HELP_TEXT_ITEM_REQUEST, putHelpTextItem),
    yield takeLatest(types.PUT_HELP_TEXT_ITEM_REQUEST, putHelpTextItem),
    yield takeLatest(types.DELETE_HELP_TEXT_ITEM_REQUEST, deleteHelpTextItem),
  ])
}

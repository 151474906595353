import * as types from "./emailEnquiriesActionsTypes";


export const getEmailEnquiriesRequest = params => {
  return { type: types.GET_EMAIL_ENQUIRIES_REQUEST, params };
};

export const patchEmailEnquiriesRequest = (id, data, status) => {
  return { type: types.PATCH_EMAIL_ENQUIRIES_REQUEST, id, data, status };
};

export const rejectAllBlacklistedRequest = () => {
  return { type: types.REJECT_ALL_BLACKLISTED_REQUEST };
};

export const deleteEmailEnquiry = id => {
  return { type: types.DELETE_EMAIL_ENQUIRY, id };
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import SearchInput from "../../shared/SearchInput";

// redux
import {
  deleteAnonymousSubscriberRequest,
  getSubscribersRequest
} from "../../../redux/userManagement/users/usersActions";

// helpers
import navigation from "../../../helpers/navigation";
import { Utils } from "../../../helpers";

// assets
const deleteIcon = "/images/delete_3x.svg";

const Subscribers = ({
  location,
  history,
  subscribers,
  totalNumberOfSubscribers,
  getSubscribers,
  deleteAnonymousSubscriber,
}) => {
  const { order, search, page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getSubscribers({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line

  const subscribersToRender = Utils.filterAndOrder(subscribers, { order });

  return (
    <div className="component users">
      <TableAndFormLayout>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="component--header-section left" />
            <div className="component--header-section">
              <SearchInput
                placeholder="Type to search by email"
                value={search}
                onChange={value => navigation.setDynamicKeys({ page: 1, search: value }, location, history)}
              />
            </div>
          </div>
        </TableAndFormLayout.Header>
        <TableAndFormLayout.Table>
          <div className="flex users--content">
            <Table
              columnWidths={["auto", "25%", "auto", "10%"]}
              headerCells={[
                { label: "email address" },
                { label: "saved search" },
                { label: "history" },
                { label: "actions" }
              ]}
            >
              {subscribersToRender.map((subscriber) => {
                return (
                  <TableRow key={`user__${subscriber.id}`}>
                    <div className="flex email-address column">
                      {subscriber.targetEmail}
                    </div>

                    <div>
                      <div>
                        <span className="same-width">Dealers</span>
                        <span className="red ml-1">
                          {subscriber.dealer ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Artisans</span>
                        <span className="red ml-1">
                          {subscriber.artisan || subscriber.catalogElement?.catalog === "Artisan" ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Periods</span>
                        <span className="red ml-1">
                          {subscriber.period ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Keyword searches</span>
                        <span className="red ml-1">
                          {subscriber.keywordSearch ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Categories</span>
                        <span className="red ml-1">
                          {subscriber.category ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Origins</span>
                        <span className="red ml-1">
                          {subscriber.catalogElement?.catalog === "Origin" ? 1 : 0}
                        </span>
                      </div>

                      <div>
                        <span className="same-width">Materials</span>
                        <span className="red ml-1">
                          {subscriber.catalogElement?.catalog === "Material" ? 1 : 0}
                        </span>
                      </div>
                    </div>

                    <div className="history column flex">
                      <div className="flex">
                        <span className="left">Subscribed</span>
                        <span className="bold">
                          {Utils.emptyFieldFormat(subscriber.createdAt && moment(subscriber.createdAt).format("DD/MM/YYYY HH:mm:ss"), "-")}
                        </span>
                      </div>
                      <div className="flex">
                        <span className="left">Last email sent</span>
                        <span className="bold">
                          {Utils.emptyFieldFormat(subscriber.lastRunAt && moment(subscriber.lastRunAt).format("DD/MM/YYYY HH:mm:ss"), "-")}
                        </span>
                      </div>
                    </div>

                    <div className="flex actions-column">
                      <img
                        className="groups--table-icon"
                        src={deleteIcon}
                        alt=""
                        width="16"
                        onClick={() => {
                          deleteAnonymousSubscriber({ id: subscriber.id, page, perPage, search });
                        }}
                      />
                    </div>
                  </TableRow>
                )
              })}

              {totalNumberOfSubscribers && (
                <TableFooter
                  key="users-table-footer"
                  page={page}
                  perPage={perPage}
                  total={totalNumberOfSubscribers}
                />
              )}
            </Table>
          </div>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
};

Subscribers.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  subscribers: PropTypes.arrayOf(PropTypes.shape({
    artisan: PropTypes.object,
    catalogElement: PropTypes.object,
    category: PropTypes.object,
    dealer: PropTypes.object,
    id: PropTypes.number.isRequired,
    includeInEmail: PropTypes.string,
    keywordSearch: PropTypes.string,
    lastRunAt: PropTypes.string,
    period: PropTypes.object,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    targetEmail: PropTypes.string
  })).isRequired,
  totalNumberOfSubscribers: PropTypes.number.isRequired,
  getSubscribers: PropTypes.func.isRequired,
  deleteAnonymousSubscriber: PropTypes.func.isRequired,
};

export default connect(
  state => state.users,
  dispatch => ({
    getSubscribers: payload => dispatch(getSubscribersRequest(payload)),
    deleteAnonymousSubscriber: payload => dispatch(deleteAnonymousSubscriberRequest(payload)),
  })
)(Subscribers);

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Avatar, Modal } from "antd";
import { connect } from "react-redux";

// components
import TableRow from "../../shared/Table/TableRow";
import DeleteIcon from "../../Icon/DeleteIcon";

// helpers
import formatPrice from "../../../helpers/formatPrice";
import { selectCurrencySymbol } from "../../../helpers/currency";
import { deleteEmailEnquiry } from "../../../redux/enquiries/emailEnquiries/emailEnquiriesActions";


function EmailEnquiryRow({
  columnWidths,
  enquiry,
  onStatusClick,
  userRole,
  history,
  deleteEmailEnquiryItem,
}) {
  const { item, dealer } = enquiry;

  const enquiryTypeToRender = !enquiry.isLa ? "WEBSITE_ENQUIRY" : enquiry.type;

  let img = "";
  if (item && item.images && item.images.length) {
    const image = lodash.find(item.images, { isMain: true });
    if (image) img = image.url;
  }

  const onDealerNameClick = () => {
    if (userRole === "Super Admin" || userRole === "AdminLv1") {
      history.push({ pathname: `/dealers/dealers-list/${dealer.url}/status-tab` });
    }

    return null;
  };

  return (
    <div className="w-100" key={`group:${enquiry.id}`}>
      <TableRow columnWidths={columnWidths} className="email-enq--table-row">
        <div className="email-enq--status-block">
          <StatusLabel label="Release + Email" value="RELEASED" onClick={onStatusClick} enquiry={enquiry} />
          <StatusLabel label="Pending" value="PENDING" onClick={onStatusClick} enquiry={enquiry} />
          <StatusLabel label="Delete" value="DELETE" onClick={onStatusClick} enquiry={enquiry} />
          <StatusLabel label="Reject" value="REJECT" onClick={onStatusClick} enquiry={enquiry} />
          <StatusLabel label="Blacklist" value="REJECT_AND_SPAM" onClick={onStatusClick} enquiry={enquiry} shouldConfirm />
        </div>
        <div className="flex email-enq--item-block">
          <Avatar className="table-image" src={img} size={32} shape="square" />
          <span className="table-sm-text">{item && item.title ? item.title : ""}</span>
        </div>

        <div className="flex table-text">
          {item && item.price && `${selectCurrencySymbol(item?.currency)} ${formatPrice(item.price)}`}
        </div>

        <div
          className="flex enquiries--column-cell email-enq--dealer-column"
          onClick={onDealerNameClick}
        >
          <span className="table-text">{dealer.businessName}</span>
          <span className="table-sm-text">{dealer.email}</span>
        </div>

        <div className="flex enquiries--column-cell">
          <span className="table-text">{enquiry.customerName}</span>
          <span className="table-sm-text">{enquiry.customerEmail}</span>
        </div>

        <div className="flex table-sm-text">{enquiryTypeToRender}</div>

        <div className="flex table-sm-text">
          {moment(enquiry.createdAt).format("ddd D MMM YYYY HH:mm")}
        </div>

        <div className="flex justify-end">
          <DeleteIcon
            className="groups--table-icon"
            fillColor="#FF0000"
            onClick={() => deleteEmailEnquiryItem(enquiry.id)}
          />
        </div>
      </TableRow>

      <div className="email-enq--info">
        <div className="email-enq--info-block">
          <div className="flex email-enq--text">
            <span className="email-enq--label">Contact</span>
            <span className="email-enq--value">{enquiry.customerPhone}</span>
          </div>

          <div className="flex email-enq--text">
            <div className="email-enq--label">msgtype</div>
            <div className="email-enq--value">{enquiry.msgType}</div>
          </div>

          {Boolean(enquiry.customerIp) && (
            <div className="flex email-enq--text">
              <div className="email-enq--label">IP</div>
              <div className="email-enq--value">{enquiry.customerIp}</div>
            </div>
          )}

          <div className="flex email-enq--text">
            <div className="email-enq--label">Text</div>
            <div className="email-enq--value">{enquiry.message}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

EmailEnquiryRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  enquiry: PropTypes.object.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  deleteEmailEnquiryItem: PropTypes.func.isRequired,
};


function StatusLabel({ label, value, onClick, enquiry, shouldConfirm }) {
  const classNames = ["email-enq--status"];
  if (enquiry.status === value) classNames.push("active");

  const onItemClick = () => {
    if (!shouldConfirm) return onClick(enquiry.id, value);

    Modal.confirm({
      content: <p>Are you sure you want to add this item to blacklist?</p>,
      onOk: () => onClick(enquiry.id, value),
    });
  };

  return (
    <span className={classNames.join(" ")} onClick={onItemClick}>{label}</span>
  )
}

StatusLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  enquiry: PropTypes.shape({
    createdAt: PropTypes.string,
    customerEmail: PropTypes.string,
    customerIp: PropTypes.string,
    customerName: PropTypes.string,
    customerPhone: PropTypes.string,
    dealer: PropTypes.object,
    dfa: PropTypes.string,
    domain: PropTypes.string,
    id: PropTypes.number,
    isLa: PropTypes.bool,
    isSpam: PropTypes.bool,
    item: PropTypes.shape({
      currency: PropTypes.shape({
        currency: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.number,
      }),
      dateCreated: PropTypes.string,
      id: PropTypes.number,
      images: PropTypes.array,
      price: PropTypes.number,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    message: PropTypes.string,
    msgType: PropTypes.string,
    origin: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  shouldConfirm: PropTypes.bool,
};

export default connect(
  null,
  dispatch => ({
    deleteEmailEnquiryItem: id => dispatch(deleteEmailEnquiry(id))
  })
)(EmailEnquiryRow);

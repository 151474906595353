import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import DoubleStatusesBlock from "./DoubleStatusesBlock";
import TripleStatusesBlock from "./TripleStatusesBlock";


const DealerStatusSection = ({ statusData, match }) => {
  const {
    confirmed,
    itemStatus,
    loginEnabled,
    showPageWhenInactive,
    dealerSince
  } = statusData;

  const dealerUrl = match.params.url;

  const formattedDealerSinceData = moment(dealerSince).format("M/D/Y");

  return (
    <div className="status-card">
      <div className="status-card--title">Dealer Status</div>
      <div className="status-card--content" style={{ minWidth: "540px" }}>
        <div className="status-card--field">
          <div className="status-card--field-label">Confirmation</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={confirmed}
              leftSideTitle="Confirmed"
              rightSideTitle="Unconfirmed"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="confirmed"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Status</div>
          <div className="status-card--field-value">
            <TripleStatusesBlock
              status={itemStatus}
              leftSideValue="Active"
              middleSideValue="Pending"
              rightSideValue="Inactive"
              dealerUrl={dealerUrl}
              statusTitle="itemStatus"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Login</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={loginEnabled}
              leftSideTitle="Enabled"
              rightSideTitle="Disabled"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="loginEnabled"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Page when Inactive</div>
          <div className="status-card--field-value">
            <DoubleStatusesBlock
              value={showPageWhenInactive}
              leftSideTitle="Show"
              rightSideTitle="Hide"
              leftSideValue={true}
              rightSideValue={false}
              dealerUrl={dealerUrl}
              statusTitle="showPageWhenInactive"
            />
          </div>
        </div>
        <div className="status-card--field">
          <div className="status-card--field-label">Dealer Since</div>
          <div className="status-card--field-value status-card--field-value__bold">
            {formattedDealerSinceData}
          </div>
        </div>
      </div>
    </div>
  )
};

DealerStatusSection.propTypes = {
  statusData: PropTypes.object.isRequired,
  dealerSince: PropTypes.string,
  match: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    processing: state.dealers.processing,
    error: state.dealers.error,
  })
)(withRouter(DealerStatusSection));

import React from "react";
import PropTypes from "prop-types";


function DashboardIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -76.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-icon/24/dashboard" transform="translate(16.000000, 72.000000)">
              <path d="M12.8888889,20 L12.8888889,11.1111111 L20,11.1111111 L20,20 L12.8888889,20 Z M4,12.8888889 L4,4 L11.1111111,4 L11.1111111,12.8888889 L4,12.8888889 Z M9.33333333,11.1111111 L9.33333333,5.77777778 L5.77777778,5.77777778 L5.77777778,11.1111111 L9.33333333,11.1111111 Z M4,20 L4,14.6666667 L11.1111111,14.6666667 L11.1111111,20 L4,20 Z M5.77777778,18.2222222 L9.33333333,18.2222222 L9.33333333,16.4444444 L5.77777778,16.4444444 L5.77777778,18.2222222 Z M14.6666667,18.2222222 L18.2222222,18.2222222 L18.2222222,12.8888889 L14.6666667,12.8888889 L14.6666667,18.2222222 Z M12.8888889,4 L20,4 L20,9.33333333 L12.8888889,9.33333333 L12.8888889,4 Z M14.6666667,5.77777778 L14.6666667,7.55555556 L18.2222222,7.55555556 L18.2222222,5.77777778 L14.6666667,5.77777778 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

DashboardIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardIcon

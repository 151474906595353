import * as types from "./systemPreferencesActionsTypes";

export const getAdvancedPreferencesRequest = () => {
  return { type: types.GET_SYSTEM_PREFERENCES_REQUEST };
};

export const patchAdvancedPreferencesRequest = payload => {
  return { type: types.PATCH_ADVANCED_PREFERENCES_REQUEST, payload };
};

export const patchSystemPreferencesRequest = payload => {
  return { type: types.PATCH_SYSTEM_PREFERENCES_REQUEST, payload };
};

export const patchGeneralPropertiesRequest = payload => {
  return { type: types.PATCH_GENERAL_PROPERTIES_REQUEST, payload };
};

export const resetErrorRequest = payload => {
  return { type: types.RESET_ERROR, payload };
};

export const resetErrorsRequest = () => {
  return { type: types.RESET_ERRORS };
};

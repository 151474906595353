import React from "react";
import PropTypes from "prop-types";
import { Avatar, Checkbox } from "antd";
import { connect } from "react-redux";

import TableRow from "../../shared/Table/TableRow";
import DeleteIcon from "../../Icon/DeleteIcon";
import EditIcon from "../../Icon/EditIcon";


const HomeAndLivingRow = ({
  columnWidths,
  item,
  onChangeActive,
  onEdit,
  onRemove,
  userRole,
}) => {
  const hasUpdatePermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv2" || role === "AdminLv1");
  const isSuperAdmin = userRole.some(role => role === "Super Admin");

  return (
    <div className="w-100">
      <TableRow columnWidths={columnWidths}>
        <Checkbox
          checked={item.isActive}
          onChange={event => onChangeActive(item.url, event)}
        />
        <div className="flex groups--title-column">
          {item.image && <Avatar className="table-image" src={item.image.url} size={32} shape="square" />}
          <div className="table-text">{item.title}</div>
        </div>

        <div className="flex table-sm-text">{item.url}</div>
        <div className="flex table-sm-text">test</div>
        <div className="flex table-sm-text">test</div>
        <div className="flex home-and-living--table--actions-column">
          <img
            className="home-and-living--table-icon"
            src="/images/eye_3x.svg"
            width="18"
            onClick={() => {}}
            alt=""
          />

          {hasUpdatePermission && (
            <EditIcon className="home-and-living--table-icon" onClick={() => onEdit(item)} />
          )}

          {isSuperAdmin && (
            <DeleteIcon className="home-and-living--table-icon" onClick={() => onRemove(item.url)} />
          )}
        </div>
      </TableRow>
    </div>
  )
}

HomeAndLivingRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    userRole: state.auth.userProfile.roles,
  })
)(HomeAndLivingRow);

import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import HeaderTab from "./HeaderTab/HeaderTab";
import HistoryTab from "./HistoryTab/HistoryTab";
import TabNavigation from "../../shared/TabNavigation";

import OrderHeaderIcon from "../../Icon/OrderHeaderIcon";
import OrderHistoryIcon from "../../Icon/OrderHistoryIcon";


const OrderComponent = ({ match }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderId = match.params.id;

  return (
    <>
      <TabNavigation
        items={[
          {
            label: "HEADER",
            icon: OrderHeaderIcon,
            link: `/orders/${orderId}/header?${queryParams?.toString()}`,
          },
          {
            label: "HISTORY",
            icon: OrderHistoryIcon,
            link: `/orders/${orderId}/history?${queryParams?.toString()}`,
          },
        ]}
      />
      <Switch>
        <Route path="/orders/:id/header" component={HeaderTab} />
        <Route path="/orders/:id/history" component={HistoryTab} />
        <Redirect to="/orders/:id/header" />
      </Switch>
    </>
  );
};

OrderComponent.propTypes = {
  match: PropTypes.object.isRequired,
};

export default OrderComponent;

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./systemPreferencesActionsTypes";
import settingsApi from "../../../api/settings";

function *getSystemPreferences() {
  try {
    yield put({ type: types.SHOW_SYSTEM_PREFERENCES_PROCESSING });
    const advancedPreferences = yield call(settingsApi.getAdvancedPreferences);
    const systemPreferences = yield call(settingsApi.getSystemPreferences);
    const generalProperties = yield call(settingsApi.getGeneralProperties);

    yield put({
      type: types.GET_SYSTEM_PREFERENCES_REQUEST_SUCCESS,
      payload: {
        result: {
          ...advancedPreferences,
          ...systemPreferences,
          ...generalProperties
        },
        advancedFields: Object.keys(advancedPreferences),
        systemPreferencesFields: Object.keys(systemPreferences),
        generalPropertiesField: Object.keys(generalProperties)
      }
    });

  } catch (error) {
    yield put({ type: types.GET_SYSTEM_PREFERENCES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_SYSTEM_PREFERENCES_PROCESSING });
  }
}

function *patchAdvancedPreferences(action) {
  try {
    yield put({ type: types.SHOW_SYSTEM_PREFERENCES_LOADING });
    const response = yield call(settingsApi.patchAdvancedPreferences, action.payload);
    yield put({ type: types.PATCH_ADVANCED_PREFERENCES_REQUEST_SUCCESS, payload: response });

  } catch (error) {
    yield put({ type: types.PATCH_ADVANCED_PREFERENCES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_SYSTEM_PREFERENCES_LOADING });
  }
}

function *patchSystemPreferences(action) {
  try {
    yield put({ type: types.SHOW_SYSTEM_PREFERENCES_LOADING });
    const response = yield call(settingsApi.patchSystemPreferences, action.payload);
    yield put({ type: types.PATCH_SYSTEM_PREFERENCES_REQUEST_SUCCESS, payload: response })

  } catch (error) {
    yield put({ type: types.PATCH_SYSTEM_PREFERENCES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_SYSTEM_PREFERENCES_LOADING });
  }
}

function *patchGeneralProperties(action) {
  try {
    yield put({ type: types.SHOW_SYSTEM_PREFERENCES_LOADING });
    const response = yield call(settingsApi.patchGeneralProperties, action.payload);
    yield put({ type: types.PATCH_GENERAL_PROPERTIES_REQUEST_SUCCESS, payload: response });

  } catch (error) {
    yield put({ type: types.PATCH_GENERAL_PROPERTIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_SYSTEM_PREFERENCES_LOADING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_SYSTEM_PREFERENCES_REQUEST, getSystemPreferences),
    yield takeLatest(types.PATCH_ADVANCED_PREFERENCES_REQUEST, patchAdvancedPreferences),
    yield takeLatest(types.PATCH_SYSTEM_PREFERENCES_REQUEST, patchSystemPreferences),
    yield takeLatest(types.PATCH_GENERAL_PROPERTIES_REQUEST, patchGeneralProperties)
  ])
}

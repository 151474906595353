import React from "react";
import PropTypes from "prop-types";


const BillingAndShippingSection = ({ billingData }) => {
  const { name, company, address, telephone, email, deliveryOption } = billingData;
  
  return (
    <div className="order-header--billing-and-shipping-wrapper">
      <div className="header-tab-column-title">Billing and shipping</div>
      <div className="billing-and-shipping-column-info">
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Name</div>
          <div className="name-field-value">
            {name || "-"}
          </div>
        </div>
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Company</div>
          <div className="company-field-value">
            {company || "-"}
          </div>
        </div>
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Address</div>
          <div className="address-field-value">
            {address || "-"}
          </div>
        </div>
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Telephone</div>
          <div className="order-number-field-value">
            {telephone || "-"}
          </div>
        </div>
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Email</div>
          <div className={email ? "email-field-value" : "order-number-field-value"}>
            {email || "-"}
          </div>
        </div>
        <div className="billing-and-shipping-column-info-field">
          <div className="header-tab-column-label">Delivery to</div>
          <div className="order-number-field-value">
            {deliveryOption && deliveryOption.length
              ? deliveryOption.map(item => item.chosenOption).join(",")
              : "-"
            }
          </div>
        </div>
      </div>
    </div>
  )
}

BillingAndShippingSection.propTypes = {
  billingData: PropTypes.object.isRequired
};

export default BillingAndShippingSection;

import fetch from "./customFetch";


function paramsToQuery(params = {}) {
  const { page, perPage, search, dateFilter, ...rest } = params;
  const query = new URLSearchParams();

  if (page) query.set("page", page);
  if (perPage) query.set("page_size", perPage);
  if (search) query.set("query", search);
  if (dateFilter) {
    const [start, end] = dateFilter.split("_");
    query.set("from_date", start);
    query.set("to_date", end);
  }

  Object.keys(rest).forEach(key => {
    if (rest[key]) query.set(key, rest[key])
  });

  return query.toString();
}


const enquiries = {
  async getActivity(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/activities/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchActivity(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/activities/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchDelivery(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/delivery-quotes/search/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDelivery(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/delivery-quotes/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getEmail(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/email/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchEmail(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/email/search/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchEmail(id, data) {
    try {
      return await fetch({
        url: `/enquiries/email/${id}/email-status/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getPhone(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/phone/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchPhone(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/phone/search/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getBlacklists(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/blacklist/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getBlacklistsChoices() {
    try {
      return await fetch({
        url: "/enquiries/blacklist/choices/?page=1&page_size=200",
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchBlacklists(params) {
    try {
      const query = paramsToQuery(params);

      return await fetch({
        url: `/enquiries/blacklist/search/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createBlacklists(data) {
    try {
      return await fetch({
        url: "/enquiries/blacklist/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async putBlacklists(id, data) {
    try {
      return await fetch({
        url: `/enquiries/blacklist/${id}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchBlacklists(id, data) {
    try {
      return await fetch({
        url: `/enquiries/blacklist/${id}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteBlacklists(id) {
    try {
      return await fetch({
        url: `/enquiries/blacklist/${id}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSpamList({ page = 1, perPage = 10, search = "" }) {
    try {
      return await fetch({
        url: `/enquiries/email/email-spam/?page=${page}&page_size=${perPage}&search=${search}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async rejectAllBlacklist() {
    try {
      return await fetch({
        url: "/enquiries/email/reject-all-blacklisted/",
        method: "PUT"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteEmailEnquiry(id) {
    try {
      return await fetch({
        url: `/enquiries/email/${id}/`,
        method: "DELETE"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async resendEmailEnquiry(id) {
    try {
      return await fetch({
        url: `/enquiries/email/${id}/resent-to-dealer/`,
        method: "POST"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteEnquiryFromBlacklist(id) {
    try {
      return await fetch({
        url: `/enquiries/blacklist/${id}/`,
        method: "DELETE"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default enquiries;

import React from "react";
import PropTypes from "prop-types";


function EditIcon({ fillColor = "#717171", className = "", onClick }) {
  return (
    <svg onClick={onClick} className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1070.000000, -187.000000)" fill={fillColor}>
          <g transform="translate(1066.000000, 183.000000)">
            <path d="M15.3321696,9.9267625 L14.0732375,8.6678304 L5.78066776,16.9604001 L5.78066776,18.2193322 L7.03959986,18.2193322 L15.3321696,9.9267625 Z M16.5911017,8.6678304 L17.8500338,7.40889829 L16.5911017,6.14996619 L15.3321696,7.40889829 L16.5911017,8.6678304 Z M7.77679631,20 L4,20 L4,16.2223134 L15.9616357,4.2606777 C16.3093109,3.91310743 16.8728925,3.91310743 17.2205678,4.2606777 L19.7393223,6.77943224 C20.0868926,7.12710749 20.0868926,7.6906891 19.7393223,8.03836434 L7.77768665,20 L7.77679631,20 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

EditIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default EditIcon

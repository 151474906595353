import { all } from "redux-saga/effects";

import authSagas from "./auth/authSagas";
import catalogsSagas from "./catalog/catalogSagas";
import artisansSagas from "./artisans/artisansSagas";
import blogSagas from "./content/blog/blogSagas";
import pagesSagas from "./content/pages/pagesSagas";
import itemsSagas from "./items/itemsSagas";
import dashboardSagas from "./dashboard/dashboardSagas";
import groupsSagas from "./groups/groupSagas";
import helpTextSagas from "./content/helptext/helpTextSagas";
import dealers from "./dealers/dealersSagas";
import ordersSagas from "./orders/ordersSagas";
import invoicesOverviewSagas from "./invoices/overview/overviewSagas";
import invoiceListSagas from "./invoices/invoicelist/invoiceListSagas";
import invoiceDealerCharges from "./invoices/dealercharges/dealerChargesSagas";
import enquiryActivitySagas from "./enquiries/enquiryActivity/enquiryActivitySagas";
import emailEnquiriesSagas from "./enquiries/emailEnquiries/emailEnquiriesSagas";
import phoneEnquiriesSagas from "./enquiries/phoneEnquiries/phoneEnquiriesSagas";
import blacklistsEnquiriesSagas from "./enquiries/blacklistsEnquiries/blacklistsEnquiriesSagas";
import usersSagas from "./userManagement/users/usersSagas";
import currencyCodesSagas from "./settings/currency/currencyCodes/currencyCodesSagas";
import systemPreferencesSagas from "./settings/systemPreferences/systemPreferencesSagas";
import paymentsSagas from "./settings/payments/paymentsSagas";
import emailProfilesSagas from "./settings/emailProfiles/emailProfilesSagas";
import offersSagas from "./settings/offers/offersSagas";
import categoriesSagas from "./categories/categoriesSagas";
import currencyRatesSagas from "./settings/currency/currencyRates/currencyRatesSagas";
import currencyFeedSagas from "./settings/currency/currencyFeed/currencyFeedSagas";
import adminAccountsSagas from "./settings/adminAccounts/adminAccountsSagas";
import homeAndLivingSagas from "./homeAndLiving/homeAndLivingSagas";
import periodsSagas from "./periods/periodsSagas";
import seoSagas from "./seo/seoSagas";
import analyticsSagas from "./analytics/analyticsSagas";
import websiteSagas from "./website/websiteSagas";


export default function *rootSaga() {
  yield all([
    authSagas(),
    catalogsSagas(),
    artisansSagas(),
    blogSagas(),
    pagesSagas(),
    itemsSagas(),
    dashboardSagas(),
    groupsSagas(),
    helpTextSagas(),
    dealers(),
    ordersSagas(),
    invoicesOverviewSagas(),
    invoiceListSagas(),
    invoiceDealerCharges(),
    enquiryActivitySagas(),
    emailEnquiriesSagas(),
    phoneEnquiriesSagas(),
    blacklistsEnquiriesSagas(),
    usersSagas(),
    currencyCodesSagas(),
    systemPreferencesSagas(),
    paymentsSagas(),
    emailProfilesSagas(),
    offersSagas(),
    categoriesSagas(),
    currencyRatesSagas(),
    currencyFeedSagas(),
    adminAccountsSagas(),
    homeAndLivingSagas(),
    periodsSagas(),
    seoSagas(),
    analyticsSagas(),
    websiteSagas(),
  ]);
}

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Popconfirm } from "antd";
import { v4 as uuidv4 } from "uuid";

export default function StatusPanel({
  statuses = [],
  onItemClick,
  isStatusLarger,
  hasPermissionToChangeStatus,
  itemToRender
}) {
  const isPendingStatus = statuses.find(({ active }) => active)?.name === "Pending";

  return (
    <div
      className={`status-panel ml-2 ${hasPermissionToChangeStatus ? "enabled" : "disabled"}`}
      onClick={(evt) => evt.stopPropagation()}
    >
      {statuses.map((item, index) => {
        const itemName = get(item, "name", "");
        const disabled = isPendingStatus && ["Reserved", "Sold"].includes(itemName);
        const clickable = !disabled && !item.active && onItemClick && hasPermissionToChangeStatus ? "clickable" : "";
        const active = item.active ? itemName.toLowerCase() : "";
        const isApprovedItemStatusOption = isStatusLarger && item.name === "Available";

        return (
          <Fragment key={`${itemName}-${index}`}>
            {!disabled && item.name !== "Declined" && !item.active && hasPermissionToChangeStatus ? (
              <Popconfirm
                title="Are you sure you want to change item status?"
                key={uuidv4()}
                cancelText="No"
                okText="Yes"
                onConfirm={e => {
                  !disabled && onItemClick && onItemClick(e, item, itemToRender)
                }}
                onCancel={e => e.stopPropagation()}
                okButtonProps={{ size: "middle", className: "item-status-pop-btn" }}
                cancelButtonProps={{ size: "middle", className: "item-status-pop-btn" }}
              >
                <div
                  onClick={e => e.stopPropagation()}
                  className={`status-panel--item ${isApprovedItemStatusOption ? "larger" : "shorter"} ${active} ${clickable}`}
                >
                  {item.name}
                </div>
              </Popconfirm>
            ) : (
              <div
                onClick={e => !disabled && onItemClick && onItemClick(e, item, itemToRender)}
                className={`status-panel--item ${isApprovedItemStatusOption ? "larger" : "shorter"} ${active} ${clickable}`}
              >
                {item.name}
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

StatusPanel.propTypes = {
  statuses: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  isStatusLarger: PropTypes.bool,
  hasPermissionToChangeStatus: PropTypes.bool,
  itemToRender: PropTypes.object
};

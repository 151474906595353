import * as types from "./itemsActionsTypes";

const initialState = {
  items: [],
  choices: {},
  totalNumberOfItems: 0,
  error: null,
  processing: false,
  loading: false
};

const items = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        totalNumberOfItems: action.payload.count,
        loading: false
      };

    case types.GET_CHOICES_REQUEST_SUCCESS:
      return {
        ...state,
        choices: action.payload
      };

    case types.DELETE_ITEMS_CATALOG_ELEMENT_SUCCESS:
    case types.PATCH_ITEMS_DATA_SUCCESS:
      return {
        ...state,
        error: null
      };
    case types.GET_ITEMS_REQUEST:
    case types.ROTATE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case types.ROTATE_IMAGE_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null
      };

    case types.GET_ITEMS_REQUEST_ERROR:
    case types.GET_CHOICES_REQUEST_ERROR:
    case types.UPDATE_ITEMS_STATUS_ERROR:
    case types.PATCH_ITEMS_DATA_ERROR:
    case types.UPDATE_SHOW_ON_HOME_STATUS_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.UPDATE_SHOW_ON_HOME_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state;
  }
};

export default items;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "antd";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";

import LabeledInput from "../../../../shared/LabeledInput";

import {
  addDealerNoteRequest, resetErrorAction,
} from "../../../../../redux/dealers/dealersActions";


const AddNewNote = ({
  addDealerNote,
  resetError,
  processing,
  history,
  match,
  error,
}) => {
  const dealerURL = match.params.url;
  const [isReminderFocused, setIsReminderFocused] = useState(false);
  const [reminderDate, setReminderDate] = useState();
  const [status, setStatus] = useState();
  const [note, setNote] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);


  // Mark form as submitted
  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  // Redirect on successful submit
  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    return history.push(`/dealers/dealers-list/${dealerURL}/notes-tab`);
  }, [error, processing, formSubmitted]); // eslint-disable-line

  // Reset error on unmount
  useEffect(() => resetError, []); // eslint-disable-line


  // Methods
  const onSubmit = () => {
    const data = { status, notes: note };
    if (reminderDate && typeof reminderDate === "object") data.reminderIn = reminderDate.format("YYYY-M-D");

    addDealerNote({ url: dealerURL, data });
  };


  // Render
  const serverError = error || {};

  const dateClassNames = ["add-new-note--date-picker labeled-input--input"];
  if (serverError.reminderIn) dateClassNames.push("add-new-note--date-picker__error");

  const renderSetReminderField = () => (
    <div className="add-new-note--set-reminder-field">
      <div className="add-new-note--set-reminder-field--label">Set Reminder</div>

      <div className={dateClassNames.join(" ")}>
        <SingleDatePicker
          date={reminderDate}
          onDateChange={date => setReminderDate(date)}
          focused={isReminderFocused}
          onFocusChange={({ focused }) => setIsReminderFocused(focused)}
          numberOfMonths={1}
          withPortal={true}
          noBorder
          placeholder="Set Reminder"
          id="reminder-date-picker"
        />
      </div>
    </div>
  );

  const renderStatusField = () => (
    <div className="add-new-note--status-field">
      <LabeledInput
        label="Status"
        type="input"
        value={status}
        placeholder="Enter note status"
        onChange={setStatus}
        isRequired
        hasError={!!serverError.status}
      />
    </div>
  );

  const renderNotesField = () => (
    <div className="add-new-note--note-field">
      <LabeledInput
        label="Note"
        type="textarea"
        value={note}
        onChange={setNote}
        rows={8}
        isRequired
        hasError={!!serverError.notes}
      />
    </div>
  );

  const renderActionButtons = () => (
    <div className="add-new-note--action-buttons">
      <div className="add-new-note--action-buttons--save-button">
        <Button
          className="ant-btn-primary"
          loading={processing}
          onClick={() => onSubmit()}
        >
          SAVE & CLOSE
        </Button>
      </div>
      <Button
        onClick={() => history.push(`/dealers/dealers-list/${dealerURL}/notes-tab`)}
      >
        CANCEL
      </Button>
    </div>
  );

  return (
    <div className="add-new-note">
      <div className="add-new-note--title">Add New Note</div>
      {renderSetReminderField()}
      {renderStatusField()}
      {renderNotesField()}
      {renderActionButtons()}
    </div>
  );
};

AddNewNote.propTypes = {
  history: PropTypes.object.isRequired,
  addDealerNote: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  error: PropTypes.object,
  processing: PropTypes.bool.isRequired
};

export default connect(
  ({ dealers }) => dealers,
  dispatch => ({
    addDealerNote: payload => dispatch(addDealerNoteRequest(payload)),
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddNewNote);

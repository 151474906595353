import React from "react";
import { Empty, Spin } from "antd";
import PropTypes from "prop-types";
import { Utils } from "../helpers";


export const LantItemLayout = ({ children, item, error, processing = false }) => {
  return (
    <div className={`edit-${item}`}>
      <Spin spinning={processing}>
        {error?.status === 404 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={`${Utils.humanizePath(item)} not found.`}
          />
        ) : (
          children
        )}
      </Spin>
    </div>
  );
};

LantItemLayout.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.string.isRequired,
  error: PropTypes.object,
  processing: PropTypes.bool
}

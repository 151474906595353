import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import lodash from "lodash";
import { Spin } from "antd";

import navigation from "../../../../../helpers/navigation";
import Table from "../../../../shared/Table/Table";
import TableFooter from "../../../../shared/Table/TableFooter";
import TableRow from "../../../../shared/Table/TableRow";
import { getDealerLogsRequest } from "../../../../../redux/dealers/dealersActions";


const DealerLogsTab = ({
  dealerData,
  items,
  total,
  loading,
  location,
  getDealerLogs,
}) => {
  const { page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    if (dealerData?.user) {
      getDealerLogs({
        page,
        perPage,
        userId: dealerData.user
      });
    }
  }, [dealerData, page, perPage]); // eslint-disable-line

  return (
    <div className="admin-accounts-history">
      <Spin spinning={loading}>
        {!loading && lodash.isEmpty(items) ? (
          <div className="admin-accounts-history--no-history">No history was found</div>
        ) : (
          <>
            <Table
              columnWidths={["calc(100% - 400px)", "140px", "260px"]}
              headerCells={[
                { label: "Action" },
                { label: "Activity Time" },
                { label: "Activity Type" },
              ]}
            >
              {items.map(el => (
                <TableRow key={`group:${el.id}`}>
                  <div className="flex table-text">{el.logMessage}</div>
                  <div className="flex table-sm-text">
                    {moment(el.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  </div>
                  <div className="flex table-sm-text">{el.logType}</div>
                </TableRow>
              ))}

              {total && (
                <TableFooter
                  key="footer"
                  page={page}
                  perPage={perPage}
                  total={total}
                />
              )}
            </Table>
          </>
        )}
      </Spin>
    </div>
  )
};

DealerLogsTab.propTypes = {
  dealerData: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    logMessage: PropTypes.string,
    logType: PropTypes.string,
    user: PropTypes.string,
  })).isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  getDealerLogs: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    dealerData: state.dealers.dealerData,
    items: state.dealers.historyList,
    total: state.dealers.historyTotal,
    loading: state.dealers.loading,
  }),
  dispatch => ({
    getDealerLogs: payload => dispatch(getDealerLogsRequest(payload))
  })
)(DealerLogsTab);

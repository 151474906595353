import { all, put, call, takeLatest } from "redux-saga/effects";

import enquiriesApi from "../../../api/enquiries";
import * as types from "./phoneEnquiriesActionsTypes";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    let response = {};
    if (params.search) {
      response = yield call(enquiriesApi.searchPhone, params);
    } else {
      response = yield call(enquiriesApi.getPhone, params);
    }

    // Get dealers for each enquiry
    const dealers = {};

    yield put({ type: types.GET_PHONE_ENQUIRIES_REQUEST_SUCCESS, payload: { ...response, refItems: dealers } });
  } catch (error) {
    yield put({ type: types.GET_PHONE_ENQUIRIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_PHONE_ENQUIRIES_REQUEST, get),
  ])
}

import React from "react";
import PropTypes from "prop-types";


function DealersIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-19.000000, -355.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-34-icon/24/dealer" transform="translate(16.000000, 352.000000)">
              <path d="M16.9090909,7.85714286 L21,7.85714286 L21,9.47619048 L16.9090909,9.47619048 L16.9090909,7.85714286 Z M15.2727273,11.9047619 L21,11.9047619 L21,13.5238095 L15.2727273,13.5238095 L15.2727273,11.9047619 Z M17.7272727,15.952381 L21,15.952381 L21,17.5714286 L17.7272727,17.5714286 L17.7272727,15.952381 Z M3,20 C3,16.4232988 5.93049982,13.5238095 9.54545455,13.5238095 C13.1604093,13.5238095 16.0909091,16.4232988 16.0909091,20 L14.4545455,20 C14.4545455,17.3174741 12.2566706,15.1428571 9.54545455,15.1428571 C6.8342385,15.1428571 4.63636364,17.3174741 4.63636364,20 L3,20 L3,20 Z M9.54545455,12.7142857 C6.83318182,12.7142857 4.63636364,10.5407143 4.63636364,7.85714286 C4.63636364,5.17357143 6.83318182,3 9.54545455,3 C12.2577273,3 14.4545455,5.17357143 14.4545455,7.85714286 C14.4545455,10.5407143 12.2577273,12.7142857 9.54545455,12.7142857 Z M9.54545455,11.0952381 C11.3536364,11.0952381 12.8181818,9.64619048 12.8181818,7.85714286 C12.8181818,6.06809524 11.3536364,4.61904762 9.54545455,4.61904762 C7.73727273,4.61904762 6.27272727,6.06809524 6.27272727,7.85714286 C6.27272727,9.64619048 7.73727273,11.0952381 9.54545455,11.0952381 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

DealersIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default DealersIcon

import * as types from "./websiteActionTypes";

export const getWebsitePublishRequests = (payload) => ({
  type: types.GET_WEBSITE_PUBLISH_REQUESTS,
  payload
});

export const changeWebsitePublishRequestStatus = (payload) => ({
  type: types.CHANGE_PUBLISH_REQUEST_STATUS,
  payload
});

export const getWebsiteDomainRequests = (payload) => ({
  type: types.GET_WEBSITE_DOMAIN_REQUESTS,
  payload
});

export const changeWebsiteDomainRequestStatus = (payload) => ({
  type: types.CHANGE_DOMAIN_REQUEST_STATUS,
  payload,
});

export const getDealerWebsitesRequest = (payload) => ({
  type: types.GET_DEALER_WEBSITES_REQUEST,
  payload
});

export const changeDealerWebsiteStatusRequest = (payload) => ({
  type: types.CHANGE_DEALER_WEBSITE_STATUS_REQUEST,
  payload
});

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { AddNewNoteForm } from "../../../components";

// react-queries
import { useDirectoryNoteCreate, useDirectoryNoteList } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";
import { buildFullName } from "../../../helpers";
import AddButton from "../../shared/AddButton";


export const DirectoryNotes = ({ directoryEntry }) => {
  const location = useLocation();
  const { page, perPage } = navigation.getTableQueryParams(location);

  const [isAddNewTab, setIsAddNewTab] = useState(false);

  const { data, isLoading } = useDirectoryNoteList({
    directory_entry: directoryEntry,
    page: page,
    page_size: perPage
  }, {
    enabled: !!directoryEntry
  });

  const createDirectoryNote = useDirectoryNoteCreate({
    onSuccess: () => setIsAddNewTab(false)
  });

  const onCreateNote = (values) => {
    createDirectoryNote.mutate({ ...values, directoryEntry });
  };

  return (
    <div className="directory-notes">
      {isAddNewTab ? (
        <AddNewNoteForm
          onCancel={() => setIsAddNewTab(false)}
          onSubmit={onCreateNote}
        />
      ) : (
        <>
          <div className="dealer-notes-tab--add-button">
            <AddButton onClick={() => setIsAddNewTab(true)} />
          </div>

          {data?.count ? (
            <>
              <Table
                className="dealer-notes-tab--table"
                columnWidths={["12%", "11%", "7%", "61%", "9%"]}
                headerCells={[
                  { label: "date" },
                  { label: "status" },
                  { label: "user" },
                  { label: "notes" },
                  { label: "reminder in" },
                ]}
              >
                {data.results.map((el) => (
                  <TableRow key={`dealer-notes-tab--row-${el.id}`}>
                    <div className="dealer-notes-tab--date-column flex">
                      {moment(el.date).format("D/M/YYYY hh:mm:ss")}
                    </div>
                    <div className="dealer-notes-tab--status-column flex">
                      {el.status}
                    </div>
                    <div className="dealer-notes-tab--user-column flex">
                      {buildFullName(el.user) || el.user}
                    </div>
                    <div className="dealer-notes-tab--notes-column flex">
                      {el.notes}
                    </div>
                    <div className="dealer-notes-tab--reminder-in-column flex">
                      {el.reminderIn ? moment().to(moment(el.reminderIn)) : "-"}
                    </div>
                  </TableRow>
                ))}
              </Table>
              <TableFooter
                page={page}
                perPage={perPage}
                total={data.count}
              />
            </>
          ) : !isLoading && (
            <div className="dealer-notes-tab--empty-data-message">
              There aren't available notes for this directory entry.
            </div>
          )}
        </>
      )}
    </div>
  )
};

DirectoryNotes.propTypes = {
  directoryEntry: PropTypes.number,
};

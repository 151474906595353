import fetch from "./customFetch";
import buildQueryString from "../helpers/buildQueryString";


const dealers = {
  async getDealers(params) {
    const { fromDate, toDate, page = 1, perPage = 10, ...rest } = params;
    const query = buildQueryString({
      "page": page,
      "page_size": perPage,
      "from_date": fromDate,
      "to_date": toDate,
      ...rest,
    });

    try {
      return await fetch({
        url: `/dealers/?${query}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
  async getDealerLogs(params) {
    const query = buildQueryString({
      page: params.page,
      page_size: params.perPage,
      user_id: params.userId
    });

    try {
      return await fetch({
        url: `/settings/logs/?${query}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealersStatusChoices(data) {
    try {
      return await fetch({
        url: "/dealers/status/choices/",
        method: "GET",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteDealer(url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealersNews({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/dealers/news/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSingleDealersNews(id) {
    try {
      return await fetch({
        url: `/dealers/news/${id}/`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteSingleDealersNews(id) {
    try {
      return await fetch({
        url: `/dealers/news/${id}/`,
        method: "DELETE"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealersNews(data) {
    try {
      return await fetch({
        url: `/dealers/news/${data.url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createDealersNewsItem(data) {
    try {
      return await fetch({
        url: "/dealers/news/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerSpecialities({ page = 1, perPage = 10, name }) {
    const query = new URLSearchParams();


    if (name) query.set("name", name);
    if (page && perPage) {
      query.set("page", page);
      query.set("page_size", perPage)
    }

    try {
      return await fetch({
        url: `/dealers/specialities/?${query.toString()}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerCategories({ page = 1, perPage = 10, name }) {
    const query = new URLSearchParams();


    if (name) query.set("name", name);
    if (page && perPage) {
      query.set("page", page);
      query.set("page_size", perPage)
    }

    try {
      return await fetch({
        url: `/dealers/categories/?${query.toString()}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerSpecialitiesList({ page, pageSize }) {
    const query = new URLSearchParams();

    if (page && pageSize) {
      query.set("page", page);
      query.set("page_size", pageSize)
    }

    try {
      return await fetch({
        url: `/dealers/specialities/autocomplete/?${query.toString()}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createDealerSpeciality(data) {
    try {
      return await fetch({
        url: "/dealers/specialities/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteDealerSpeciality(url) {
    try {
      return await fetch({
        url: `/dealers/specialities/${url}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateDealerSpeciality({ data, url }) {
    try {
      return await fetch({
        url: `/dealers/specialities/${url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerData(url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealer(data, url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerAddresses(url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/addresses/`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealerAddress(data, url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/addresses/${data.id}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerAddress(url, id) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/addresses/${id}/`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerInvoices(params) {
    const { status, fromDate, toDate, page = 1, perPage = 10, dealerUrl } = params;
    let url = `/dealers/url/${dealerUrl}/invoices/?page=${page}&page_size=${perPage}&ordering=-date`;

    if (status) url = url.concat("&status=", status);
    if (fromDate) url = url.concat("&from_date=", fromDate);
    if (toDate) url = url.concat("&to_date=", toDate);

    try {
      return await fetch({
        url,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteDealerInvoice(url, id) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/invoices/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerNotes({ page = 1, perPage = 10, url }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/notes/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createDealerNote({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/notes/`,
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerStatusData(url) {
    try {
      return await fetch({
        url: `/dealers/status/${url}/`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealerStatusData({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/status/${url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createDealerAddress({ url, data }) {

    try {
      return await fetch({
        url: `/dealers/url/${url}/addresses/`,
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchListOfDealers(query) {
    try {
      return await fetch({
        url: `/dealers/url/autocomplete/?query=${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerImages(url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/images/`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealerImages({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/images/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async putDealerImages({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/images/`,
        method: "PUT",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerStockImages(url) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/stock-images/`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealerStockImages({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/stock-images/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createDealerStockImages({ url, data }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/stock-images/`,
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteDealerStockImages({ url, id }) {
    try {
      return await fetch({
        url: `/dealers/url/${url}/stock-images/${id}/`,
        method: "DELETE",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchDealers({ page, pageSize }, query) {
    let url = `/dealers/url/search/?page=${page}&page_size=${pageSize}&query=${encodeURIComponent(query)}`;

    if (!query) url = `/dealers/?page=${page}&page_size=${pageSize}`;

    try {
      return await fetch({
        url,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealersTokens(dealerUrl) {
    try {
      return await fetch({
        url: `dealers/token/${dealerUrl}/`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getTradeAssociations() {
    try {
      return await fetch({
        url: "/associations/?page=1&page_size=100",
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async changeTradeAssociationsOrder(data) {
    try {
      return await fetch({
        url: "/associations/reorder/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteTradeAssociation(id) {
    try {
      return await fetch({
        url: `/associations/${id}/`,
        method: "DELETE"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getTradeAssociationData(id) {
    try {
      return await fetch({
        url: `/associations/${id}/`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createTradeAssociation(data) {
    try {
      return await fetch({
        url: "/associations/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateTradeAssociation(id, data) {
    try {
      return await fetch({
        url: `/associations/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealersApiTokens({ page = 1, pageSize = 10 }, query = "") {
    try {
      const queryParams = new URLSearchParams();

      if (page) {
        queryParams.set("page", page);
      }

      if (pageSize) {
        queryParams.set("page_size", pageSize);
      }

      if (query) {
        queryParams.set("query", query);
      }

      return await fetch({
        url: `dealers/love-antiques-tokens/?${queryParams.toString()}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateDealerApiTokenStatus({ tokenId, data }) {
    try {
      return await fetch({
        url: `dealers/love-antiques-tokens/${tokenId}/`,
        method: "PUT",
        data: {
          ...data,
          tokenPending: false,
        }
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async revokeDealerApiTokenStatus({ dealerId }) {
    try {
      return await fetch({
        url: `dealers/love-antiques-tokens/revoke-token/?id=${dealerId}`,
        method: "DELETE",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealersTestimonials(query = {}) {
    try {
      const { page = 1, perPage = 15, isApproved = false, author, dealer } = query;

      const queryParams = new URLSearchParams();

      if (page) {
        queryParams.set("page", page);
      }
      if (perPage) {
        queryParams.set("page_size", perPage);
      }
      if (isApproved) {
        queryParams.set("is_approved", isApproved);
      }
      if (author) {
        queryParams.set("author", author);
      }
      if (dealer) {
        queryParams.set("dealer", dealer);
      }

      return await fetch({
        url: `dealers/testimonials/?${queryParams.toString()}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchDealersTestimonial(id, data) {
    try {
      return await fetch({
        url: `dealers/testimonials/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteDealersTestimonial(id) {
    try {
      return await fetch({
        url: `dealers/testimonials/${id}/`,
        method: "DELETE",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerPopupNews(params) {
    const { page, perPage } = params || { page: 1, perPage: 10 };

    const queryParams = new URLSearchParams();

    if (page) {
      queryParams.set("page", page);
    }
    if (perPage) {
      queryParams.set("page_size", perPage);
    }

    try {
      return await fetch({
        url: `dealers/popups/?${queryParams.toString()}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postDealerPopupNews(data) {
    try {
      return await fetch({
        url: "dealers/popups/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSingleDealerPopupNews(id) {
    try {
      return await fetch({
        url: `dealers/popups/${id}/`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async putSingleDealerPopupNews(id, data) {
    try {
      return await fetch({
        url: `dealers/popups/${id}/`,
        method: "PUT",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchSingleDealerPopupNews(id, data) {
    try {
      return await fetch({
        url: `dealers/popups/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteSingleDealerPopupNews(id) {
    try {
      return await fetch({
        url: `dealers/popups/${id}/`,
        method: "DELETE",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async showDealerNewsPopup(params) {
    const { showPopup } = params || { showPopup: "true" };

    const queryParams = new URLSearchParams();
    queryParams.set("show_popup", showPopup);

    try {
      return await fetch({
        url: `dealers/popups/show-dealer-popup/?${queryParams.toString()}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }

  }
};

export default dealers;

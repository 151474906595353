export const GET_WEBSITE_PUBLISH_REQUESTS = "GET_WEBSITE_PUBLISH_REQUESTS";
export const GET_WEBSITE_PUBLISH_REQUESTS_ERROR = "GET_WEBSITE_PUBLISH_REQUESTS_ERROR";
export const GET_WEBSITE_PUBLISH_REQUESTS_SUCCESS = "GET_WEBSITE_PUBLISH_REQUESTS_SUCCESS";

export const CHANGE_PUBLISH_REQUEST_STATUS = "CHANGE_PUBLISH_REQUEST_STATUS";
export const CHANGE_PUBLISH_REQUEST_STATUS_ERROR = "CHANGE_PUBLISH_REQUEST_STATUS_ERROR";

export const GET_WEBSITE_DOMAIN_REQUESTS = "GET_WEBSITE_DOMAIN_REQUESTS";
export const GET_WEBSITE_DOMAIN_REQUESTS_ERROR = "GET_WEBSITE_DOMAIN_REQUESTS_ERROR";
export const GET_WEBSITE_DOMAIN_REQUESTS_SUCCESS = "GET_WEBSITE_DOMAIN_REQUESTS_SUCCESS";

export const CHANGE_DOMAIN_REQUEST_STATUS = "CHANGE_DOMAIN_REQUEST_STATUS";
export const CHANGE_DOMAIN_REQUEST_STATUS_ERROR = "CHANGE_DOMAIN_REQUEST_STATUS_ERROR";

export const GET_DEALER_WEBSITES_REQUEST = "GET_DEALER_WEBSITES_REQUEST";
export const GET_DEALER_WEBSITES_REQUEST_ERROR = "GET_DEALER_WEBSITES_REQUEST_ERROR";
export const GET_DEALER_WEBSITES_REQUEST_SUCCESS = "GET_DEALER_WEBSITES_REQUEST_SUCCESS";

export const CHANGE_DEALER_WEBSITE_STATUS_REQUEST = "CHANGE_DEALER_WEBSITE_STATUS_REQUEST";
export const CHANGE_DEALER_WEBSITE_STATUS_REQUEST_ERROR = "CHANGE_DEALER_WEBSITE_STATUS_REQUEST_ERROR";

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({ url: `/subscriptions/subscriptions-cancel-requests/?${buildQueryString(params)}`, method: "GET" });
};

export const useTradeSubscriptionCancelRequestList = (params, options) => {
  return useQuery(
    ["trade-subscription-cancel-request-list", JSON.stringify(params)],
    () => fetcher(params),
    options
  );
};

import React from "react";
import PropTypes from "prop-types";
import { matchPath, Switch, Route } from "react-router-dom";

import TabNavigation from "../../shared/TabNavigation";

import AddAdminAccount from "./AddAdminAccount";
import AdminAccountsHistory from "./AdminAccountsHistory";
import EditAdminAccount from "./EditAdminAccount";
import AdminAccountsList from "./AdminAccountsList";


const AdminAccounts = ({ location }) => {
  const renderNavigationTabs = currentLocation => {
    const isMatchLocation = matchPath(currentLocation, {
      path: ["/settings/admin-accounts", "/settings/admin-accounts/history"],
      exact: true
    });

    return (
      isMatchLocation && (
        <TabNavigation
          items={[
            { label: "ACCOUNTS", link: "/settings/admin-accounts" },
            { label: "HISTORY", link: "/settings/admin-accounts/history" }
          ]}
        />
      )
    );
  };

  return (
    <div className="component">
      {renderNavigationTabs(location.pathname)}

      <Switch>
        <Route path="/settings/admin-accounts/add-new-admin-account" component={AddAdminAccount} />
        <Route path="/settings/admin-accounts/history" component={AdminAccountsHistory} />
        <Route path="/settings/admin-accounts/:id" component={EditAdminAccount} />
        <Route path="/settings/admin-accounts" component={AdminAccountsList} />
      </Switch>
    </div>
  );
};

AdminAccounts.propTypes = {
  location: PropTypes.object.isRequired,
};

export default AdminAccounts;

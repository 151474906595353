import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// components
import LabeledInput from "../LabeledInput";

// helpers
import navigation from "../../../helpers/navigation";


function getPageButtonValues(page, amountOfPages) {
  const pageButtons = [page];

  // Add next two pages after current and divider if it hasn't reached last page
  for (let i = page + 1; i <= page + 3; i++) {
    if (i >= amountOfPages) break;
    if (i === page + 3) {
      pageButtons.push("...");
      break;
    }

    pageButtons.push(i);
  }
  // Add last page if not there yet
  if (!pageButtons.includes(amountOfPages)) pageButtons.push(amountOfPages);

  // Add next two pages after current and divider if it hasn't reached last page
  for (let i = page - 1; i >= page - 3; i--) {
    if (i <= 1) break;
    if (i === page - 3) {
      pageButtons.unshift("...");
      break;
    }

    pageButtons.unshift(i);
  }
  // Add first page if not there yet
  if (!pageButtons.includes(1)) pageButtons.unshift(1);

  return pageButtons;
}


function TableFooter({ page, perPage, total, location, history }) {
  const amountOfPages = Math.ceil(total / perPage);
  const blockBack = page === 1;
  const blockForward = page === amountOfPages;
  const toPage = ((page - 1) * perPage) + perPage;

  function _goToPage(value) {
    if (value === "...") return;
    if (value === "back" && blockBack) return;
    else if (value === "forward" && blockForward) return;

    let newPage = value;
    if (value === "back") newPage = page - 1;
    else if (value === "forward") newPage = page + 1;

    navigation.setPage(newPage, location, history)
  }

  function setPerPage(newPerPage) {
    const newAmountOfPages = Math.ceil(total / newPerPage);
    const previousPageNumber = page - 1 || 1;

    if (page > newAmountOfPages) navigation.setPageAndPerPage(newAmountOfPages, newPerPage, location, history);
    else navigation.setPageAndPerPage(previousPageNumber, newPerPage, location, history);
  }

  return (
    <div className="table--footer">
      <div className="table--footer-show">
        <LabeledInput
          className="table--footer-show-select"
          label="Show"
          type="select"
          options={[5, 10, 15]}
          value={perPage}
          onChange={setPerPage}
          horizontal
        />
      </div>

      <div className="table--footer-pages">
        <div
          className={classNames("table--footer-pages-control", blockBack && "table--footer-pages-control__hidden")}
          onClick={() => _goToPage("back")}
        >
          {"<"}
        </div>

        {getPageButtonValues(page, amountOfPages).map((number, index) => {
          return (
            <div
              key={`pageButton${index}`}
              className={classNames(
                "table--footer-pages-button",
                number === page && "table--footer-pages-button__selected",
                number === "..." && "table--footer-pages-button__divider",
                number > 99 && "table--footer-pages-button__three-digits"
              )}
              onClick={() => _goToPage(number)}
            >
              {number}
            </div>
          );
        })}

        <div
          className={classNames("table--footer-pages-control", blockForward && "table--footer-pages-control__hidden")}
          onClick={() => _goToPage("forward")}
        >
          {">"}
        </div>
      </div>

      <div className="table--footer-total">
        Record {((page - 1) * perPage) + 1}..{toPage < total ? toPage : total} of {total}
      </div>
    </div>
  )
}

TableFooter.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(TableFooter);

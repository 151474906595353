import React from "react";
import PropTypes from "prop-types";


function InvoicesIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-21.000000, -276.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-29-icon/24/invoice" transform="translate(16.000000, 272.000000)">
              <path d="M5.83333333,4 L19.1666667,4 C19.626904,4 20,4.3581722 20,4.8 L20,20 L16.3146613,18.4 L12.5369749,20 L8.79897173,18.4 L5,20 L5,4.8 C5,4.3581722 5.37309604,4 5.83333333,4 Z M6.66666667,5.6 L6.66666667,17.5508505 L8.12938395,16.9348029 C8.55932384,16.7537265 9.0488785,16.7551329 9.47768183,16.9386762 L12.5405428,18.249691 L15.641914,16.9361373 C16.0753789,16.7525477 16.5697491,16.7547347 17.0014331,16.9421515 L18.3333333,17.5203997 L18.3333333,5.6 L6.66666667,5.6 Z M9.16666667,8.8 L15.8333333,8.8 L15.8333333,10.4 L9.16666667,10.4 L9.16666667,8.8 Z M9.16666667,12.8 L15.8333333,12.8 L15.8333333,14.4 L9.16666667,14.4 L9.16666667,12.8 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

InvoicesIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default InvoicesIcon

import React from "react";
import PropTypes from "prop-types";


const AccountIcon = ({ fillColor = "#FFFFFF", className = "" }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/user" fill={fillColor}>
          <path d="M6,20 C6,16.4232988 8.91014913,13.5238095 12.5,13.5238095 C16.0898509,13.5238095 19,16.4232988 19,20 L17.375,20 C17.375,17.3174741 15.1923882,15.1428571 12.5,15.1428571 C9.80761184,15.1428571 7.625,17.3174741 7.625,20 L6,20 L6,20 Z M12.5,12.7142857 C9.8065625,12.7142857 7.625,10.5407143 7.625,7.85714286 C7.625,5.17357143 9.8065625,3 12.5,3 C15.1934375,3 17.375,5.17357143 17.375,7.85714286 C17.375,10.5407143 15.1934375,12.7142857 12.5,12.7142857 Z M12.5,11.0952381 C14.295625,11.0952381 15.75,9.64619048 15.75,7.85714286 C15.75,6.06809524 14.295625,4.61904762 12.5,4.61904762 C10.704375,4.61904762 9.25,6.06809524 9.25,7.85714286 C9.25,9.64619048 10.704375,11.0952381 12.5,11.0952381 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

AccountIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default AccountIcon

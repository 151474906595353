import * as types from "./homeAndLivingActionsTypes";

const initialState = {
  homeAndLivingList: [],
  singleItem: {},
  totalNumber: 0,
  error: null,
  processing: false
};

const homeAndLiving = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HOME_AND_LIVING_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        homeAndLivingList: action.payload.results,
        totalNumber: action.payload.count,
      };

    case types.GET_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        singleItem: action.payload
      }

    case types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS:
    case types.DELETE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS:
    case types.CREATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.GET_HOME_AND_LIVING_LIST_REQUEST_ERROR:
    case types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR:
    case types.DELETE_HOME_AND_LIVING_ITEM_REQUEST_ERROR:
    case types.CREATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload
      }

    default:
      return state;
  }
};

export default homeAndLiving;

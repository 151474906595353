import React from "react";
import { Switch, Route } from "react-router-dom";

//components
import TabNavigation from "../../shared/TabNavigation";
import MainArtisan from "./Artisans";
import ArtisansTags from "./ArtisansTags";
import AddEditArtisans from "./AddEditArtisans/AddEditArtisans";
import AddEditArtisansTag from "./ArtisansTags/AddEditArtisansTag";


function Artisans() {
  return (<>
    <div className="artisans__tags-navigation">
      <TabNavigation
        items={[
          { label: "ARTISANS", link: "/catalog/artisan" },
          { label: "ARTISAN TAGS", link: "/catalog/artisan/tags" },
        ]}
      />
    </div>
    <Switch>
      <Route exact path="/catalog/artisan" component={MainArtisan} />
      <Route exact path="/catalog/artisan/add" component={AddEditArtisans} />
      <Route exact path="/catalog/artisan/tags" component={ArtisansTags} />
      <Route exact path="/catalog/artisan/tags/add" component={AddEditArtisansTag} />
    </Switch>
  </>)
}

export default Artisans;

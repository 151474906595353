import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./homeAndLivingActionsTypes";
import catalog from "../../api/catalog";
import navigation from "../../helpers/navigation";


function *getHomeAndLivingList(action) {
  try {
    let response = {};
    if (action.payload.search) {
      response = yield call(catalog.searchInHomeAndLivingList, action.payload);
    } else {
      response = yield call(catalog.getHomeAndLivingList, action.payload);
    }
    yield put({ type: types.GET_HOME_AND_LIVING_LIST_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_HOME_AND_LIVING_LIST_REQUEST_ERROR, payload: error });
  }
}

function *getHomeAndLivingItem(action) {
  try {
    const response = yield call(catalog.getHomeAndLivingItem, action.payload);
    yield put({ type: types.GET_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_HOME_AND_LIVING_ITEM_REQUEST_ERROR, payload: error });
  }
}

function *updateHomeAndLivingItem(action) {
  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(catalog.updateHomeAndLivingItem, action.payload);
    yield put({ type: types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_HOME_AND_LIVING_LIST_REQUEST, payload: { page, perPage, search } })
  } catch (error) {
    yield put({ type: types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *deleteHomeAndLivingItem(action) {
  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(catalog.deleteHomeAndLivingItem, action.payload);
    yield put({ type: types.DELETE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_HOME_AND_LIVING_LIST_REQUEST, payload: { page, perPage, search } })
  } catch (error) {
    yield put({ type: types.DELETE_HOME_AND_LIVING_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *createHomeAndLivingItem (action) {
  const { page, perPage, search } = navigation.getTableQueryParams(window.location);

  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(catalog.createHomeAndLivingItem, action.payload);
    yield put({ type: types.CREATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS });
    yield put({ type: types.GET_HOME_AND_LIVING_LIST_REQUEST, payload: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.CREATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_HOME_AND_LIVING_LIST_REQUEST, getHomeAndLivingList),
    yield takeLatest(types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST, updateHomeAndLivingItem),
    yield takeLatest(types.DELETE_HOME_AND_LIVING_ITEM_REQUEST, deleteHomeAndLivingItem),
    yield takeLatest(types.GET_HOME_AND_LIVING_ITEM_REQUEST, getHomeAndLivingItem),
    yield takeLatest(types.CREATE_HOME_AND_LIVING_ITEM_REQUEST, createHomeAndLivingItem),
  ])
}

import React from "react";
import { Col, Form } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";


export const LantFormItem = ({
  children,
  name,
  label,
  className,
  style,
  span = 24,
  xs,
  sm,
  md,
  lg,
  rules,
  serverError,
  ...rest
}) => {
  return (
    <Col span={span} xs={xs} sm={sm} md={md} lg={lg} style={style}>
      <Form.Item
        name={name}
        label={label}
        className={classNames("lant-form--item", className)}
        messageVariables={{ label: label }}
        rules={rules?.map((el) => el.required && !el.message ? { ...el, message: `${label} is required.` } : el)}
        {...serverError && typeof serverError === "string" ? { help: serverError, validateStatus: "error" } : {}}
        {...rest}
      >
        {children}
      </Form.Item>
    </Col>
  )
}

LantFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  span: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  rules: PropTypes.array,
  serverError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  valuePropName: PropTypes.string,
  wrapperProps: PropTypes.object,
};

import React from "react";
import PropTypes from "prop-types";


function RotateToLeft({ fillColor = "#FFFFFF", className = "", onClick }) {
  return (
    <svg onClick={onClick} className={className} width="15px" height="18px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>F4DC0129-D374-4CE1-8BD2-CF9C230C6495@3x</title>
      <desc>Created with sketchtool.</desc>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Items-Lightbox" transform="translate(-677.000000, -831.000000)" fill={fillColor}>
          <g id="icon/24/anticlockwise" transform="translate(672.000000, 829.000000)">
            <path d="M13.6783333,6.44259285 L15.2025,7.99152662 L14.0233333,9.19050981 L10.4883333,5.59525491 L14.0233333,2 L15.2025,3.19898319 L13.6783333,4.74791696 L15.8333333,4.74791696 C18.1345198,4.74791696 20,6.64474756 20,8.98460669 L20,12.3739585 L18.3333333,12.3739585 L18.3333333,8.98460669 C18.3333333,7.58069122 17.2140452,6.44259285 15.8333333,6.44259285 L13.6783333,6.44259285 Z M15,10.6792826 L15,19.1526621 C15,19.6206339 14.626904,20 14.1666667,20 L5.83333333,20 C5.37309604,20 5,19.6206339 5,19.1526621 L5,10.6792826 C5,10.2113108 5.37309604,9.83194464 5.83333333,9.83194464 L14.1666667,9.83194464 C14.626904,9.83194464 15,10.2113108 15,10.6792826 Z M13.3333333,11.5266205 L6.66666667,11.5266205 L6.66666667,18.3053241 L13.3333333,18.3053241 L13.3333333,11.5266205 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

RotateToLeft.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default RotateToLeft

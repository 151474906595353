import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import { connect } from "react-redux";
import _ from "lodash";

import SearchInput from "../../shared/SearchInput";

function ModalHeader({ categories, activeKeys, modalTabChanged, setSearch, selectedItem }) {
  const [selectedKey, setSelectedKey] = useState([]);
  const [itemMainPhoto, setItemMainPhoto] = useState("");

  useEffect(() => {
    const itemImages = selectedItem && _.get(selectedItem, "images", []);
    const mainPhoto = itemImages ? itemImages.find(obj => obj.isMain) ? itemImages.find(obj => obj.isMain).url : "" : "";

    setItemMainPhoto(mainPhoto);
  }, []); // eslint-disable-line

  return (
    <div className="custom-modal-header">
      <div className="custom-modal-header--info">
        <h1>{selectedItem ? selectedItem.title : ""}</h1>
        {itemMainPhoto && <img src={itemMainPhoto} alt={selectedItem ? selectedItem.title : ""} />}
      </div>
      <SearchInput placeholder="Text" onChange={setSearch} />
      <Menu
        onClick={({ key }) => {
          modalTabChanged(key, categories);
          setSelectedKey([key]);
        }}
        mode="horizontal"
        selectedKeys={selectedKey.length ? selectedKey : activeKeys}
        className="overflow-x-auto"
      >
        {
          Object.keys(categories).map(category => (
            <Menu.Item key={category}>
              { category }
            </Menu.Item>
          ))
        }
      </Menu>
    </div>
  )
}

ModalHeader.propTypes = {
  categories: PropTypes.object.isRequired,
  activeKeys: PropTypes.array.isRequired,
  modalTabChanged: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    selectedItem: state.items.selectedItem,
  }),
)(ModalHeader);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  message,
  Popconfirm
} from "antd";
import { useLocation, useHistory } from "react-router-dom";

//components
import LabeledInput from "../../../shared/LabeledInput";
import GeneralFormControl from "../../../shared/GeneralFormControl";

//helpers
import artisans from "../../../../api/artisans";


function AddEditArtisansTag(props) {
  const { item, close } = props;

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(item ? item.name || "" : "");
  const [description, setDescription] = useState(item ? item.description || "" : "");

  const location = useLocation();
  const history = useHistory();

  const isEdit = !!item;
  const hasDeletePermission = props.userRole.some(role => role === "Super Admin") || !isEdit;


  const onSave = () => {
    setLoading(true);

    const data = {
      name,
      description
    }

    if (!item) {
      artisans.postArtisanTags(data)
        .then(() => message.success("New artisan tag was created"))
        .catch(() => message.error("Something went wrong!"))
        .finally(() => {
          setLoading(false);
          history.replace("/catalog/artisan/tags")
        });
    } else {
      artisans.putArtisanTags(item.id, data)
        .then(() => message.success("Artisan tag was updated"))
        .catch(() => message.error("Something went wrong!"))
        .finally(() => {
          setLoading(false);
          history.replace("/catalog/artisan/tags")
        });
    }
  }

  const onDelete = () => {
    artisans.deleteArtisanTags(item.id).then(() => {
      history.push({ pathname: location.pathname.replace("/add", "") });
      message.success(`Artisan tag (${item.name}) was deleted`);
    })
  }


  // Render

  return (
    <div className="general-form">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"} Artisan</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput label="Tag Name" value={name} isRequired onChange={setName} />
        </div>
      </div>

      <LabeledInput label="Description" type="rich-text" value={description} onChange={setDescription} />

      <div className="general-form--button-holder">
        <Button className="ant-btn-primary" onClick={onSave} loading={loading}>
          SAVE & CLOSE
        </Button>

        <Popconfirm
          placement="top"
          title="Are you sure you want to delete this artisan tag?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete()}
          disabled={!hasDeletePermission || !isEdit}
        >
          <Button
            onClick={() => !isEdit && history.push({ pathname: location.pathname.replace("/add", "") })}
            disabled={!hasDeletePermission}
          >
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE TAG" : "CANCEL"}
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

AddEditArtisansTag.propTypes = {
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    userRole: state.auth.userProfile.roles
  }), null)(AddEditArtisansTag);

import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { Col, Input, Row, Select } from "antd";
import PropTypes from "prop-types";

const MIN_FONT_SIZE = 20;
const MAX_FONT_SIZE = 46;

const FONT_WEIGHTS = [
  { value: 300, label: "Light" },
  { value: 400, label: "Regular" },
  { value: 500, label: "Medium" },
  { value: 600, label: "Semi Bold" },
  { value: 700, label: "Bold" }
];

export const DEFAULT_LOGO_SETTINGS = {
  text: "Logo",
  secondLineText: "",
  style: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Dancing Script"
  }
};


export const TextLogo = ({
  backgroundColor,
  defaultValue,
  onChange,
  errors,
  renderLogoTextInput,
  label = "Logo"
}) => {
  const colorPickerRef = useRef(null);

  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [logoSettings, setLogoSetting] = useState(DEFAULT_LOGO_SETTINGS);

  const handleClickOutsideColorPicker = (event) => {
    if (colorPickerRef && !colorPickerRef.current.contains(event.target)) {
      setIsPickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideColorPicker);

    return () => {
      document.removeEventListener("click", handleClickOutsideColorPicker);
    }
  }, []);

  const handleChange = (data) => {
    setLogoSetting(data);
    typeof onChange === "function" && onChange(JSON.stringify(data));
  }

  useEffect(() => {
    try {
      if (defaultValue) {
        setLogoSetting({
          ...logoSettings,
          ...JSON.parse(defaultValue)
        });
      }
    } catch (e) {
      console.error("TextLogo component, 60 line: ", e, defaultValue);
    }
  }, [defaultValue]); // eslint-disable-line

  const fontSizes = [];
  for (let size = MIN_FONT_SIZE; size <= MAX_FONT_SIZE; size += 2) {
    fontSizes.push(<Select.Option key={`font-size-${size}`} value={`${size}px`}>{size}</Select.Option>);
  }

  return (
    <div>
      <div className="text-logo__preview" style={{ backgroundColor }}>
        <div style={logoSettings.style}>
          {logoSettings.text}
          {!!logoSettings.secondLineText && (
            <p className="text-logo__preview_second-line">{logoSettings.secondLineText}</p>
          )}
        </div>
      </div>
      {typeof renderLogoTextInput === "function" ? (
        renderLogoTextInput(logoSettings, handleChange)
      ) : (
        <Row gutter={16}>
          <Col span={12}>
            <label>Logo</label>
            <Input
              className="text-logo__input"
              label={label}
              // showEnteredTextLength={true}
              // props={{
              //   maxLength: 35,
              //   value: logoSettings.text
              // }}
              value={logoSettings.text}
              error={errors && errors.text}
              onChange={(event) => handleChange({ ...logoSettings, text: event.target.value })}
            />
          </Col>
          <Col span={12}>
            <label>Logo second line</label>
            <Input
              containerClassName="text-logo__input"
              label="Logo second line"
              value={logoSettings.secondLineText}
              onChange={(event) => handleChange({ ...logoSettings, secondLineText: event.target.value })}
            />
          </Col>
        </Row>
      )}
      <div className="text-logo__controls">
        <div>
          <div className="text-logo__control-label">Font</div>
          <Select
            key={logoSettings.style.fontFamily}
            className="text-logo__select text-logo__select--font"
            defaultValue={logoSettings.style.fontFamily}
            onChange={value => handleChange({
              ...logoSettings,
              style: { ...logoSettings.style, fontFamily: value }
            })}
          >
            <Select.Option value="Allura">
              <span className="text-logo__option--allura">Allura</span>
            </Select.Option>
            <Select.Option value="Dancing Script">
              <span className="text-logo__option--dancing-script">Dancing Script</span>
            </Select.Option>
            <Select.Option value="Euphoria Script">
              <span className="text-logo__option--euphoria-script">Euphoria Script</span>
            </Select.Option>
            <Select.Option value="Lato">
              <span className="text-logo__option--lato">Lato</span>
            </Select.Option>
            <Select.Option value="Libre Baskerville">
              <span className="text-logo__option--libre-baskerville">Libre Baskerville</span>
            </Select.Option>
            <Select.Option value="Lobster">
              <span className="text-logo__option--lobster">Lobster</span>
            </Select.Option>
            <Select.Option value="Lovers Quarrel">
              <span className="text-logo__option--lovers-quarrel">Lovers Quarrel</span>
            </Select.Option>
            <Select.Option value="Montserrat">
              <span className="text-logo__option--montserrat">Montserrat</span>
            </Select.Option>
            <Select.Option value="Oswald">
              <span className="text-logo__option--oswald">Oswald</span>
            </Select.Option>
            <Select.Option value="Playball">
              <span className="text-logo__option--playball">Playball</span>
            </Select.Option>
            <Select.Option value="Roboto">
              <span className="text-logo__option--roboto">Roboto</span>
            </Select.Option>
            <Select.Option value="Russo One">
              <span className="text-logo__option--russo-one">Russo One</span>
            </Select.Option>
            <Select.Option value="Sail">
              <span className="text-logo__option--sail">Sail</span>
            </Select.Option>
            <Select.Option value="Source Sans Pro">
              <span className="text-logo__option--source-sans-pro">Source Sans Pro</span>
            </Select.Option>
            <Select.Option value="Kaushan Script">
              <span className="text-logo__option--kaushan-script">Kaushan Script</span>
            </Select.Option>
            <Select.Option value="Arizonia">
              <span className="text-logo__option--arizonia">Arizonia</span>
            </Select.Option>
            <Select.Option value="Monsieur La Doulaise">
              <span className="text-logo__option--monsieur-la-doulaise">Monsieur La Doulaise</span>
            </Select.Option>
            <Select.Option value="Big Shoulders Stencil Text">
              <span className="text-logo__option--big_shoulders_stencil_text">Big Shoulders Stencil Text</span>
            </Select.Option>
            <Select.Option value="Monoton">
              <span className="text-logo__option--monoton">Monoton</span>
            </Select.Option>
            <Select.Option value="Buda">
              <span className="text-logo__option--buda">Buda</span>
            </Select.Option>
            <Select.Option value="Vast Shadow">
              <span className="text-logo__option--vast_shadow">Vast Shadow</span>
            </Select.Option>
            <Select.Option value="Forum">
              <span className="text-logo__option--forum">Forum</span>
            </Select.Option>
            <Select.Option value="Tangerine">
              <span className="text-logo__option--tangerine">Tangerine</span>
            </Select.Option>
            <Select.Option value="Arapey">
              <span className="text-logo__option--arapey">Arapey</span>
            </Select.Option>
            <Select.Option value="Special Elite">
              <span className="text-logo__option--special_elite">Special Elite</span>
            </Select.Option>
            <Select.Option value="Josefin Slab">
              <span className="text-logo__option--josefin_slab">Josefin Slab</span>
            </Select.Option>
            <Select.Option value="Spartan">
              <span className="text-logo__option--spartan">Spartan</span>
            </Select.Option>
            <Select.Option value="EB Garamond">
              <span className="text-logo__option--eb_garamond">EB Garamond</span>
            </Select.Option>
            <Select.Option value="Nanum Gothic">
              <span className="text-logo__option--nanum_gothic">Nanum Gothic</span>
            </Select.Option>
            <Select.Option value="Caramel">
              <span className="text-logo__option--caramel">Caramel</span>
            </Select.Option>
            <Select.Option value="Cinzel Decorative">
              <span className="text-logo__option--cinzel_decorative">Cinzel Decorative</span>
            </Select.Option>
            <Select.Option value="Playfair Display SC">
              <span className="text-logo__option--playfair_display_sc">Playfair Display SC</span>
            </Select.Option>
            <Select.Option value="Chakra Petch">
              <span className="text-logo__option--chakra_petch">Chakra Petch</span>
            </Select.Option>
            <Select.Option value="Cinzel">
              <span className="text-logo__option--cinzel">Cinzel</span>
            </Select.Option>
            <Select.Option value="Crimson Text">
              <span className="text-logo__option--crimson-text">Crimson Text</span>
            </Select.Option>
            <Select.Option value="Windsong">
              <span className="text-logo__option--windsong">Windsong</span>
            </Select.Option>
            <Select.Option value="Bodoni Classico">
              <span className="text-logo__option--bodoni_classico">Bodoni Classico</span>
            </Select.Option>
            <Select.Option value="Classic Roman">
              <span className="text-logo__option--classic-roman">Classic Roman</span>
            </Select.Option>
            <Select.Option value="Explorer">
              <span className="text-logo__option--explorer">Explorer</span>
            </Select.Option>
          </Select>
        </div>
        <div>
          <div className="text-logo__control-label">Weight</div>
          <Select
            key={logoSettings.style.fontWeight}
            className="text-logo__select text-logo__select--weight"
            defaultValue={FONT_WEIGHTS.find(weight => weight.value === logoSettings.style.fontWeight).label}
            onChange={value => handleChange({
              ...logoSettings,
              style: { ...logoSettings.style, fontWeight: value }
            })}
          >
            {FONT_WEIGHTS.map(fontWeight => {
              return <Select.Option
                key={`font-weight-${fontWeight.value}`}
                value={fontWeight.value}
              >
                {fontWeight.label}
              </Select.Option>;
            })}
          </Select>
        </div>
        <div>
          <div className="text-logo__control-label">Size</div>
          <Select
            key={logoSettings.style.fontSize}
            className="text-logo__select text-logo__select--size"
            defaultValue={logoSettings.style.fontSize}
            onChange={value => handleChange({
              ...logoSettings,
              style: { ...logoSettings.style, fontSize: value }
            })}
          >
            {fontSizes}
          </Select>
        </div>
        <div ref={colorPickerRef} className="text-logo__color-selector-container">
          <div className="text-logo__control-label">Color</div>
          <div
            onClick={() => setIsPickerVisible(!isPickerVisible)}
            className="text-logo__color-selector"
            style={{ background: logoSettings.style.color }}
          />
          {isPickerVisible && (
            <div className="text-logo__color-picker">
              <ChromePicker
                disableAlpha={true}
                color={logoSettings.style.color}
                onChangeComplete={color => {
                  handleChange({
                    ...logoSettings,
                    style: { ...logoSettings.style, color: color.hex }
                  })
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

TextLogo.propTypes = {
  backgroundColor: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  label: PropTypes.node,
  renderLogoTextInput: PropTypes.func,
};

export default TextLogo;

import { all, put, call, takeLatest } from "redux-saga/effects";

import analytics from "../../api/analytics";
import * as types from "./analyticsActionTypes";

import {
  getEnquiriesAnalyticsRequestError,
  getEnquiriesAnalyticsRequestSuccess,
  getItemsAnalyticsRequestError,
  getItemsAnalyticsRequestSuccess,
  getDealerAnalyticsRequestError,
  getDealerAnalyticsRequestSuccess,
} from "./analyticsActions";
import { message } from "antd";


export function *getEnquiriesAnalytics ({ payload }) {
  yield put({ type: types.ENQUIRIES_ANALYTICS_LOADING, payload: true });
  try {
    const response = yield call(analytics.getEnquiriesAnalytics, payload);
    yield put(getEnquiriesAnalyticsRequestSuccess(response));
  } catch (error) {
    message.error(
      "Something went wrong while loading analytics data, try again later",
      3
    );
    yield put(getEnquiriesAnalyticsRequestError(error.message));
  } finally {
    yield put({ type: types.ENQUIRIES_ANALYTICS_LOADING, payload: false });
  }
}

export function *getItemsAnalytics ({ payload }) {
  yield put({ type: types.ITEMS_ANALYTICS_LOADING, payload: true });
  try {
    const response = yield call(analytics.getItemsAnalytics, payload);
    yield put(getItemsAnalyticsRequestSuccess(response));
  } catch (error) {
    message.error(
      "Something went wrong while loading analytics data, try again later",
      3
    );
    yield put(getItemsAnalyticsRequestError(error.message));
  } finally {
    yield put({ type: types.ITEMS_ANALYTICS_LOADING, payload: false });
  }
}

export function *getDealerAnalytics ({ payload }) {
  yield put({ type: types.DEALER_ANALYTICS_REQUEST_LOADING, payload: true });
  try {
    const response = yield call(analytics.getDealerAnalyticsRequest, payload);
    yield put(getDealerAnalyticsRequestSuccess(response));
  } catch (error) {
    message.error(
      "Something went wrong while loading analytics data, try again later",
      3
    );
    yield put(getDealerAnalyticsRequestError(error.message));
  } finally {
    yield put({ type: types.DEALER_ANALYTICS_REQUEST_LOADING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_ENQUIRIES_ANALYTICS_REQUEST, getEnquiriesAnalytics),
    yield takeLatest(types.GET_ITEMS_ANALYTICS_REQUEST, getItemsAnalytics),
    yield takeLatest(types.GET_DEALER_ANALYTICS_REQUEST, getDealerAnalytics),
  ])
}

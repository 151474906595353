import * as types from "./overviewActionsTypes";

const initialState = {
  data: {
    status: {},
    activeInvoices: {},
    lastMonth: {
      totalNet: {},
      totalGross: {}
    },
    thisMonth: {
      totalNet: {},
      totalGross: {}
    },
    tableOverview: []
  },
  error: null,
  processing: false,
};

const overview = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICES_OVERVIEW_SUCCESS:
      return {
        ...state,
        data: action.payload
      };

    case types.TOGGLE_INVOICES_OVERVIEW_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      };

    case types.GET_INVOICES_OVERVIEW_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default overview;

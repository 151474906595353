export const GET_ADMIN_ACCOUNTS_REQUEST = "GET_ADMIN_ACCOUNTS_REQUEST";
export const GET_ADMIN_ACCOUNTS_REQUEST_SUCCESS = "GET_ADMIN_ACCOUNTS_REQUEST_SUCCESS";
export const GET_ADMIN_ACCOUNTS_REQUEST_ERROR = "GET_ADMIN_ACCOUNTS_REQUEST_ERROR";

export const DELETE_ADMIN_ACCOUNT_REQUEST = "DELETE_ADMIN_ACCOUNT_REQUEST";
export const DELETE_ADMIN_ACCOUNT_REQUEST_SUCCESS = "DELETE_ADMIN_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_ADMIN_ACCOUNT_REQUEST_ERROR = "DELETE_ADMIN_ACCOUNT_REQUEST_ERROR";

export const UPDATE_ADMIN_ACCOUNT_REQUEST = "UPDATE_ADMIN_ACCOUNT_REQUEST";
export const UPDATE_ADMIN_ACCOUNT_REQUEST_SUCCESS = "UPDATE_ADMIN_ACCOUNT_REQUEST_SUCCESS";
export const UPDATE_ADMIN_ACCOUNT_REQUEST_ERROR = "UPDATE_ADMIN_ACCOUNT_REQUEST_ERROR";

export const GET_ADMIN_ROLES_REQUEST = "GET_ADMIN_ROLES_REQUEST";
export const GET_ADMIN_ROLES_REQUEST_SUCCESS = "GET_ADMIN_ROLES_REQUEST_SUCCESS";
export const GET_ADMIN_ROLES_REQUEST_ERROR = "GET_ADMIN_ROLES_REQUEST_ERROR";

export const ADD_ADMIN_ACCOUNT_REQUEST = "ADD_ADMIN_ACCOUNT_REQUEST";
export const ADD_ADMIN_ACCOUNT_REQUEST_SUCCESS = "ADD_ADMIN_ACCOUNT_REQUEST_SUCCESS";
export const ADD_ADMIN_ACCOUNT_REQUEST_ERROR = "ADD_ADMIN_ACCOUNT_REQUEST_ERROR";

export const SEARCH_ADMINS_REQUEST = "SEARCH_ADMINS_REQUEST";
export const SEARCH_ADMINS_REQUEST_SUCCESS = "SEARCH_ADMINS_REQUEST_SUCCESS";
export const SEARCH_ADMINS_REQUEST_ERROR = "SEARCH_ADMINS_REQUEST_ERROR";

export const SHOW_LOADING = "SHOW_LOADING_ADMINS_ACCOUNTS";
export const HIDE_LOADING = "HIDE_LOADING_ADMINS_ACCOUNTS";

export const TOGGLE_PROCESSING = "TOGGLE_PROCESSING_ADMINS_ACCOUNTS";

export const GET_ADMIN_ACCOUNTS_HISTORY_REQUEST = "GET_ADMIN_ACCOUNTS_HISTORY_REQUEST";
export const GET_ADMIN_ACCOUNTS_HISTORY_REQUEST_SUCCESS = "GET_ADMIN_ACCOUNTS_HISTORY_REQUEST_SUCCESS";

export const RESET_PASSWORD_REQUEST = "ADMIN_RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "ADMIN_RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_ERROR = "ADMIN_RESET_PASSWORD_REQUEST_ERROR";

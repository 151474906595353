import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import ordersAPI from "../../../../api/orders";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import Table from "../../../shared/Table/Table";
import PaypalBuyerThankYou from "./templates/PaypalBuyerThankYou";
import PaypalDealerConfirmation from "./templates/PaypalDealerConfirmation";

import { getOrdersRequest } from "../../../../redux/orders/ordersActions";

import navigation from "../../../../helpers/navigation";
import { fromSnakeCaseToNormal } from "../../../../helpers/string";

const getDescriptionEmail = (description) => {
  const emailMatch = description.match(
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/
  );

  return emailMatch ? emailMatch[0] : "";
};

const getDescriptionType = (description) => {
  const typeMatch = description.match(/Email\s+([A-Z_]+)/);

  return typeMatch ? typeMatch[1] : "";
};

const OrderHistory = ({
  location,
  match,
  orders,
  getOrders,
  historiesTotalNumber,
}) => {
  const { page, perPage } = navigation.getTableQueryParams(location);
  const [collapsedItems, setCollapsedItem] = useState([22364]);

  useEffect(() => {
    ordersAPI.getOrders();
  }, []); // eslint-disable-line

  useEffect(() => {
    getOrders({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const order =
    orders && orders.find(({ id }) => id.toString(10) === match.params.id);
  const historyData = _.get(order, "history", []);

  return (
    <div className=":order-history">
      {historyData.length ? (
        <Table
          columnWidths={["25%", "25%", "50%"]}
          headerCells={[
            { label: "Date" },
            { label: "Type" },
            { label: "Receiver Email" },
          ]}
        >
          {({ columnWidths }) => historyData.map(({ id, date, description }) => {
            const isCollapsed = collapsedItems.includes(id);

            const email = getDescriptionEmail(description);
            const type = getDescriptionType(description);

            const historyDateFormatted = moment(date).format("L h:mm:ss");

            return (
              <div
                key={`order-history-${id}`}
                className="order-history--content__row"
              >
                <TableRow
                  columnWidths={columnWidths}
                  onClick={() => setCollapsedItem((prev) => {
                    if (prev.includes(id)) {
                      return prev.filter((item) => item !== id);
                    }

                    return [...prev, id];
                  })
                  }
                >
                  <div className="flex">{historyDateFormatted}</div>
                  <div className="flex">{fromSnakeCaseToNormal(type)}</div>
                  <div className="flex">{email}</div>
                </TableRow>

                {isCollapsed && (
                  <div className="order-history--email-wrapper">
                    {type === "PAYPAL_DEALER_CONFIRMATION" ? (
                      <PaypalDealerConfirmation order={order} />
                    ) : (
                      <PaypalBuyerThankYou order={order} />
                    )}
                  </div>
                )}
              </div>
            );
          })
          }

          {historiesTotalNumber && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={historiesTotalNumber}
            />
          )}
        </Table>
      ) : (
        <div className="order-history--empty-data-message">
          There aren't available history for this dealer.
        </div>
      )}
    </div>
  );
};

OrderHistory.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
  getOrders: PropTypes.func.isRequired,
  historiesTotalNumber: PropTypes.number.isRequired,
};

export default connect(
  ({ orders }) => orders,
  (dispatch) => ({
    getOrders: (payload) => dispatch(getOrdersRequest(payload)),
  })
)(withRouter(OrderHistory));

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu } from "antd";

// components
import { LantItemLayout } from "../../../components";
import { DirectoryEntryStatus } from "../components/DirectoryEntry/DirectoryEntryStatus";
import { DirectoryEntryDetails } from "../components/DirectoryEntry/DirectoryEntryDetails";
import { DirectoryEntryMedia } from "../components/DirectoryEntry/DirectoryEntryMedia";
import { DirectoryEntrySubscription } from "../components/DirectoryEntry/DirectoryEntrySubscription";
import { DirectoryEntryHistory } from "../components/DirectoryEntry/DirectoryEntryHistory";
import { DirectoryNotes } from "./DirectoryNotes";

// react-queries
import { useDirectoryEntry } from "../../../react-query";

const NAVIGATION_TABS = [
  { key: "status", label: "STATUS" },
  { key: "details", label: "DETAILS" },
  { key: "media", label: "MEDIA" },
  { key: "subscription", label: "SUBSCRIPTION" },
  { key: "notes", label: "NOTES" },
  { key: "history", label: "DIRECTORY LOGS" },
];


export const EditDirectoryEntry = () => {
  const { id, tab } = useParams();

  const history = useHistory();

  const navigateToTab = (newTab, name) => {
    history.replace({
      pathname: `/directory/directory-entries/${id}/${newTab}`,
      state: { path: name }
    });
  };

  const { data, error, isFetching, isLoading } = useDirectoryEntry(id, {
    onSuccess: (resp) => navigateToTab(tab, resp.name)
  });

  const renderTab = () => {
    switch (tab) {
      case "details":
        return <DirectoryEntryDetails data={data} isFair={false} />;
      case "media":
        return <DirectoryEntryMedia data={data} />;
      case "subscription":
        return <DirectoryEntrySubscription data={data} isFair={false} isLoading={isLoading} />;
      case "notes":
        return <DirectoryNotes directoryEntry={data?.id} />;
      case "history":
        return <DirectoryEntryHistory directoryEntry={data?.id} />;
      default:
        return <DirectoryEntryStatus data={data} isFair={false} isLoading={isLoading} />;
    }
  };

  return (
    <LantItemLayout item="directory" error={error} processing={isFetching}>
      <Menu
        mode="horizontal"
        selectedKeys={[tab]}
        onSelect={(e) => navigateToTab(e.key, data?.name)}
      >
        {NAVIGATION_TABS.map(({ key, label }) => (
          <Menu.Item key={key} className="tab-navigation--item">
            {label}
          </Menu.Item>
        ))}
      </Menu>

      {renderTab()}
    </LantItemLayout>
  );
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";

// helpers
import formatPrice from "../../../helpers/formatPrice";
import { getInvoiceDealerChargesRequest } from "../../../redux/invoices/dealercharges/dealerChargesActions";
import navigation from "../../../helpers/navigation";
import { Utils } from "../../../helpers";

function DealerCharges({ location, getInvoiceDealerCharges, items, totalItems }) {
  const { order, search = "", page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getInvoiceDealerCharges({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const dealerChargesToRender = Utils.filterAndOrder(items, { order, search, searchKey: "dealer" });

  return (
    <div className="component dealer-charges">
      <div className="flex dealer-charges--content">
        <Table
          columnWidths={["9%", "15%", "13%", "13%", "13%", "15%", "10%", "12%"]}
          headerCells={[
            { label: "status" },
            { label: "dealer" },
            { label: "number invoices" },
            { label: "charge total (net)" },
            { label: "discount total" },
            { label: "line total total (net)" },
            { label: "tax total" },
            { label: "invoice total" },
          ]}
        >
          {
            dealerChargesToRender.map(dealerCharge => {
              const status = dealerCharge.status ? "active" : "inactive";

              return (
                <TableRow key={`dealer-charges-${dealerCharge.id}`}>
                  <div className="flex">
                    <div className="status-panel disabled">
                      <div className={`status-panel--item text-capitalize shorter ${status}`}>
                        {status}
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    {Utils.emptyFieldFormat(dealerCharge.dealer, "-")}
                  </div>
                  <div className="flex number-invoices-column">
                    {Utils.emptyFieldFormat(dealerCharge.numberInvoices, "-")}
                  </div>
                  <div className="flex">
                    {"£"}{Utils.emptyFieldFormat(formatPrice(dealerCharge.chargeTotal), "-")}
                  </div>
                  <div className="flex">
                    {"£"}{Utils.emptyFieldFormat(formatPrice(dealerCharge.discountTotal), "-")}
                  </div>
                  <div className="flex">
                    {"£"}{Utils.emptyFieldFormat(formatPrice(dealerCharge.lineTotalTotal), "-")}
                  </div>
                  <div className="flex">
                    {"£"}{Utils.emptyFieldFormat(formatPrice(dealerCharge.taxTotal), "-")}
                  </div>
                  <div className="flex">
                    {"£"}{Utils.emptyFieldFormat(formatPrice(dealerCharge.invoiceTotal), "-")}
                  </div>
                </TableRow>
              )
            })
          }

          {
            totalItems &&
            <TableFooter
              key="table-footer"
              page={page}
              perPage={perPage}
              total={totalItems}
            />
          }
        </Table>
      </div>
    </div>
  )
}

DealerCharges.propTypes = {
  location: PropTypes.object.isRequired,
  getInvoiceDealerCharges: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default connect(
  state => ({
    ...state.dealerCharges
  }),
  dispatch => ({
    getInvoiceDealerCharges: payload => dispatch(getInvoiceDealerChargesRequest(payload)),
  })
)(DealerCharges);

import React from "react";
import PropTypes from "prop-types";


function OrderHeaderIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Orders" transform="translate(-1299.000000, -204.000000)" fill={fillColor}>
          <g id="icon/24/edit-copy-73-icon/24/header" transform="translate(1296.000000, 200.000000)">
            <path d="M3.9,4 L20.1,4 C20.5970563,4 21,4.39796911 21,4.88888889 L21,19.1111111 C21,19.6020309 20.5970563,20 20.1,20 L3.9,20 C3.40294373,20 3,19.6020309 3,19.1111111 L3,4.88888889 C3,4.39796911 3.40294373,4 3.9,4 Z M8.4,5.77777778 L4.8,5.77777778 L4.8,18.2222222 L8.4,18.2222222 L8.4,5.77777778 Z M10.2,5.77777778 L10.2,18.2222222 L19.2,18.2222222 L19.2,5.77777778 L10.2,5.77777778 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>

  )
}

OrderHeaderIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default OrderHeaderIcon

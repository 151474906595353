import ContentIcon from "../view/Icon/ContentIcon";
import CatalogIcon from "../view/Icon/CatalogIcon";
import DashboardIcon from "../view/Icon/DashboardIcon";
import DealersIcon from "../view/Icon/DealersIcon";
import DirectoryIcon from "../view/Icon/DirectoryIcon";
import InvoicesIcon from "../view/Icon/InvoicesIcon";
import ItemsIcon from "../view/Icon/ItemsIcon";
import EnquiriesIcon from "../view/Icon/EnquiriesIcon";
import OrdersIcon from "../view/Icon/OrdersIcon";
import SettingsIcon from "../view/Icon/SettingsIcon";
import UserManagementIcon from "../view/Icon/UserManagmentIcon";
import WebsiteBuilderIcon from "../view/Icon/WebsiteBuilderIcon";

export const SIDEBAR_MENU_ITEMS = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: DashboardIcon,
    userPermission: [
      "Super Admin",
      "AdminLv1",
      "AdminLv2",
      "Admin Financial",
      "Blogger",
      "Item Verifier",
      "Admin Seo",
    ],
  },
  {
    label: "Content",
    link: "/content",
    icon: ContentIcon,
    items: [
      { label: "Pages", link: "/content/pages" },
      { label: "Help Text", link: "/content/help-text" },
      { label: "Blog", link: "/content/blog" },
    ],
    userPermission: [
      "Super Admin",
      "AdminLv1",
      "AdminLv2",
      "Blogger",
      "Admin Seo",
    ],
  },
  {
    label: "Catalog",
    link: "/catalog",
    icon: CatalogIcon,
    items: [
      { label: "Groups", link: "/catalog/groups" },
      { label: "Categories", link: "/catalog/categories" },
      { label: "Period", link: "/catalog/period" },
      { label: "Artisan", link: "/catalog/artisan" },
      { label: "Home & Living", link: "/catalog/home-and-living" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Seo"],
  },
  {
    label: "Items",
    link: "/items",
    icon: ItemsIcon,
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Item Verifier"],
  },
  {
    label: "Orders",
    link: "/orders",
    icon: OrdersIcon,
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "Invoices",
    link: "/invoices",
    icon: InvoicesIcon,
    items: [
      { label: "Overview", link: "/invoices/overview" },
      { label: "Invoice List", link: "/invoices/invoice-list" },
      { label: "Dealer Charges", link: "/invoices/dealer-charges" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "Admin Financial"],
  },
  {
    label: "Enquiries",
    link: "/enquiries",
    icon: EnquiriesIcon,
    items: [
      { label: "Enquiry Activity", link: "/enquiries/enquiry-activity" },
      { label: "Email Enquiries", link: "/enquiries/email-enquiries" },
      { label: "Delivery Enquiries", link: "/enquiries/enquiry-delivery" },
      { label: "Phone Enquiries", link: "/enquiries/phone-enquiries" },
      { label: "Blacklists", link: "/enquiries/blacklists" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "Website Builder",
    link: "/website-builder",
    icon: WebsiteBuilderIcon,
    items: [
      { label: "Domain Requests", link: "/website-builder/domain-requests" },
      { label: "Publish Requests", link: "/website-builder/publish-requests" },
      { label: "Dealer Website", link: "/website-builder/dealer-website" },
    ],
    userPermission: ["Super Admin"],
  },
  {
    label: "Dealers",
    link: "/dealers",
    icon: DealersIcon,
    items: [
      { label: "Dealers List", link: "/dealers/dealers-list" },
      { label: "Dealer News", link: "/dealers/dealer-news" },
      { label: "Trade Associations", link: "/dealers/trade-associations" },
      { label: "Dealer Specialities", link: "/dealers/dealer-specialities" },
      { label: "API Key Requests", link: "/dealers/api-key-requests" },
      { label: "Dealers Testimonials", link: "/dealers/dealers-testimonials" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "Directory",
    link: "/directory",
    icon: DirectoryIcon,
    items: [
      { label: "Directory Entries", link: "/directory/directory-entries" },
      {
        label: "Directory Categories",
        link: "/directory/directory-categories",
      },
      { label: "Directory Specialities", link: "/directory/directory-specialities" },
      { label: "Directory Requests", link: "/directory/directory-requests" },
      { label: "Fairs", link: "/directory/fairs" },
      { label: "Fairs Specialities", link: "/directory/fairs-specialities" },
      { label: "Fairs Requests", link: "/directory/fairs-requests" },
      { label: "Subscriptions", link: "/directory/subscriptions" },
      {
        label: "Cancel Requests",
        link: "/directory/subscription-cancel-requests",
      },
      { label: "Subscription Plans", link: "/directory/subscription-plans" },
      { label: "Promo Codes", link: "/directory/promo-codes" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "Trade Users",
    link: "/trade-users",
    icon: DealersIcon,
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "User Management",
    link: "/user-management",
    icon: UserManagementIcon,
    items: [
      { label: "Users", link: "/user-management/users" },
      { label: "Subscribers", link: "/user-management/subscribers" },
      { label: "Search Query Log", link: "/user-management/search-query-log" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
  {
    label: "Settings",
    link: "/settings",
    icon: SettingsIcon,
    items: [
      { label: "System Preferences", link: "/settings/system-preferences" },
      { label: "Payments", link: "/settings/payments" },
      { label: "Email Profiles", link: "/settings/email-profiles" },
      { label: "Offers", link: "/settings/offers" },
      { label: "Currency", link: "/settings/currency" },
      { label: "Admin Accounts", link: "/settings/admin-accounts" },
      { label: "Pinterest Integration", link: "/settings/pinterest" },
    ],
    userPermission: ["Super Admin", "AdminLv1", "AdminLv2", "Admin Financial"],
  },
];

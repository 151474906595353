import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Tooltip } from "antd";

import TableRow from "../shared/Table/TableRow";
import EmailIcon from "../Icon/EmailIcon";
import StatusBarWithThreeColours from "../shared/StatusBarWithThreeColours";

const SEND_EMAIL_TOOLTIP = "Send email to dealer";

const PublishAndDomainRequestsRow = ({
  columnWidths,
  createdAt,
  request,
  handleChangeRequestStatus,
  dealerData,
  domainName
}) => {
  return (
    <div className="w-100">
      <TableRow columnWidths={columnWidths} className="website-builder--container--content--table-row">
        <div className="publish-req--status-block">
          <StatusBarWithThreeColours
            label="Done"
            value="DONE"
            item={request}
            onClick={handleChangeRequestStatus}
          />
          <StatusBarWithThreeColours
            label="Pending"
            value="PENDING"
            item={request}
            onClick={handleChangeRequestStatus}
          />
          <StatusBarWithThreeColours
            label="Declined"
            value="DECLINED"
            item={request}
            onClick={handleChangeRequestStatus}
          />
        </div>

        <div>{dealerData.firstName}</div>

        <div>{dealerData.lastName}</div>

        <div>{dealerData.businessName}</div>

        <div>{dealerData.email}</div>

        <div>{moment(createdAt).format("DD-MMM-YYYY")}</div>

        <div>{domainName}</div>

        <div>
          <Tooltip title={SEND_EMAIL_TOOLTIP}>
            <a
              href={`mailto:${dealerData.email}`}
              style={{ marginLeft: "10px" }}
            >
              <EmailIcon />
            </a>
          </Tooltip>
        </div>
      </TableRow>
    </div>
  )
};

PublishAndDomainRequestsRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  createdAt: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  dealerData: PropTypes.object.isRequired,
  handleChangeRequestStatus: PropTypes.func.isRequired,
  domainName: PropTypes.string,
};

export default PublishAndDomainRequestsRow;

import React from "react";
import PropTypes from "prop-types";


function WaitingIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-347.000000, -1007.000000)" fill={fillColor} id="icon/empty-copy-44-icon/24/time">
          <g transform="translate(343.000000, 1003.000000)">
            <path d="M12,20 C7.5816,20 4,16.4184 4,12 C4,7.5816 7.5816,4 12,4 C16.4184,4 20,7.5816 20,12 C20,16.4184 16.4184,20 12,20 Z M12,18.4 C15.5346224,18.4 18.4,15.5346224 18.4,12 C18.4,8.4653776 15.5346224,5.6 12,5.6 C8.4653776,5.6 5.6,8.4653776 5.6,12 C5.6,15.5346224 8.4653776,18.4 12,18.4 Z M12.8,12 L16,12 L16,13.6 L11.2,13.6 L11.2,8 L12.8,8 L12.8,12 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

WaitingIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default WaitingIcon

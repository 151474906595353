import React from "react";
import PropTypes from "prop-types";


function Tag({ label, styleType }) {
  const classNames = ["table-tag"];
  if (styleType === "a") classNames.push("b");
  if (label === "No Categories") classNames.push("no-category");

  return (
    <div className={classNames.join(" ")}>{label}</div>
  )
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  styleType: PropTypes.string,
};

export default Tag;

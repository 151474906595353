import React, { useEffect } from "react";
import { Switch, Route, Redirect, matchPath } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TabNavigation from "../../../shared/TabNavigation";
import StatusTab from "./StatusTab/StatusTab";
import DetailsTab from "./DetailsTab/DetailsTab";
import ItemsTab from "./ItemsTab/ItemsTab";
import AddressTab from "./AddressTab/AddressTab";
import InvoicesTab from "./InvoicesTab/InvoicesTab";
import EditAddress from "./AddressTab/EditAddress";
import NotesTab from "./NotesTab/NotesTab";
import AddNewNote from "./NotesTab/AddNewNote";
import AddAddress from "./AddressTab/AddAddress";
import ImagesTab from "./ImagesTab/ImagesTab";
import DealerLogsTab from "./DealerLogsTab/DealerLogsTab";
import { clearSingleDealerAction, getSingleDealerRequest } from "../../../../redux/dealers/dealersActions";

const renderTabNavigation = (match, location) => {
  const dealerURL = match.params.url;
  const isMatchEditAddressLocation = matchPath(location, {
    path: [
      "/dealers/dealers-list/:url/addresses-tab/:id/",
      "/dealers/dealers-list/:url/notes-tab/add-new-note"
    ],
    exact: true,
  });

  if (isMatchEditAddressLocation) return null;

  return (
    <TabNavigation
      items={[
        {
          label: "STATUS",
          link: `/dealers/dealers-list/${dealerURL}/status-tab`
        },
        {
          label: "DETAILS",
          link: `/dealers/dealers-list/${dealerURL}/details-tab`
        },
        {
          label: "ITEMS",
          link: `/dealers/dealers-list/${dealerURL}/items-tab`
        },
        {
          label: "ADDRESSES",
          link: `/dealers/dealers-list/${dealerURL}/addresses-tab`
        },
        {
          label: "IMAGES",
          link: `/dealers/dealers-list/${dealerURL}/images-tab`
        },
        {
          label: "INVOICES",
          link: `/dealers/dealers-list/${dealerURL}/invoices-tab`
        },
        {
          label: "NOTES",
          link: `/dealers/dealers-list/${dealerURL}/notes-tab`
        },
        {
          label: "DEALER LOGS",
          link: `/dealers/dealers-list/${dealerURL}/dealer-logs-tab`
        },
      ]}
    />
  )
};

const DealerItem = ({ match, location, getSingleDealer, clearSingleDealer }) => {
  const dealerURL = match.params.url;

  useEffect(() => {
    getSingleDealer(dealerURL);
  }, [dealerURL]); // eslint-disable-line

  useEffect(() => {
    return () => clearSingleDealer();
  }, []) // eslint-disable-line

  return (
    <div className="dealer-item">
      {renderTabNavigation(match, location.pathname)}
      <Switch>
        <Route path="/dealers/dealers-list/:url/status-tab" component={StatusTab} />
        <Route path="/dealers/dealers-list/:url/details-tab" component={DetailsTab} />
        <Route path="/dealers/dealers-list/:url/items-tab" component={ItemsTab} />
        <Route exact path="/dealers/dealers-list/:url/addresses-tab" component={AddressTab} />
        <Route exact path="/dealers/dealers-list/:url/addresses-tab/add-address" component={AddAddress} />
        <Route exact path="/dealers/dealers-list/:url/addresses-tab/:id" component={EditAddress} />
        <Route path="/dealers/dealers-list/:url/images-tab" component={ImagesTab} />
        <Route path="/dealers/dealers-list/:url/invoices-tab" component={InvoicesTab} />
        <Route path="/dealers/dealers-list/:url/notes-tab/add-new-note" component={AddNewNote} />
        <Route path="/dealers/dealers-list/:url/notes-tab" component={NotesTab} />
        <Route path="/dealers/dealers-list/:url/dealer-logs-tab" component={DealerLogsTab} />
        <Redirect to="/dealers/dealers-list/:url/status-tab" />
      </Switch>
    </div>
  );
}

DealerItem.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getSingleDealer: PropTypes.func.isRequired,
  clearSingleDealer: PropTypes.func.isRequired,
};

export default connect(
  null,
  dispatch => ({
    getSingleDealer: payload => dispatch(getSingleDealerRequest(payload)),
    clearSingleDealer: () => dispatch(clearSingleDealerAction()),
  })
)(DealerItem);

import React from "react";
import PropTypes from "prop-types";


function SidebarPlainRow({ label, icon: Icon, isHighlighted }) {
  const classNames = ["sidebar--link"];
  if (isHighlighted) classNames.push("selected");

  return (
    <div className={classNames.join(" ")}>
      { Icon && (
        <div className="sidebar--link-icon-holder">
          <Icon fillColor={isHighlighted ? "#FF0000" : "#717171"} className="sidebar--link-icon" />
        </div>
      )}
      {label}
    </div>
  );
}

SidebarPlainRow.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
  isStandalone: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};

export default SidebarPlainRow;

import React from "react";
import PropTypes from "prop-types";
import { Col, Input, Select } from "antd";

// components
import { LantFormItem } from "../../../../components";

const DIRECTORY_STATUS_OPTIONS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
  { value: "PENDING", label: "Pending" },
];

export const DirectoryEntryEmailSection = ({ isEdit }) => {
  if (isEdit) {
    return (
      <>
        <LantFormItem
          name="email"
          label="Email"
          span={8}
          rules={[{ type: "email", required: true }]}
        >
          <Input type="email" />
        </LantFormItem>
        <LantFormItem name="status" label="Status" span={4}>
          <Select options={DIRECTORY_STATUS_OPTIONS} />
        </LantFormItem>
      </>
    );
  }
  
  return (
    <Col lg={12} xs={24}>
      <LantFormItem
        name="email"
        label="Email"
        rules={[{ type: "email", required: true }]}
      >
        <Input type="email" />
      </LantFormItem>
    </Col>
  );
};

DirectoryEntryEmailSection.propTypes = {
  isEdit: PropTypes.bool,
  isFair: PropTypes.bool,
};

import axios from "axios";
import axiosRetry from "axios-retry";

import { API_URL } from "../constants";
import { message } from "antd";

let authTokenRequest = null;

const client = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

axiosRetry(client, {
  retryDelay: (retryCount) => {
    if (retryCount === 3) {
      return 60000;
    }

    return 1000;
  },
});

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = requestNewToken();
    authTokenRequest.finally(resetAuthTokenRequest);
  }

  return authTokenRequest;
}

function requestNewToken() {
  const token = JSON.parse(localStorage.getItem("token") || "{}");

  return axios
    .post(`${API_URL}/auth/refresh/`, { refresh: token.refresh })
    .then((res) => {
      localStorage.setItem("token", JSON.stringify(res.data));
    });
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

client.interceptors.response.use(
  (r) => r,
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (error.response.status === 500) {
      message.error("Internal Server Error");
    }

    // Dont't try to renew while login in
    if (error.response.status === 401 && originalRequest.url) {
      const lastTwoParts = originalRequest.url
        .split("/")
        .filter((o) => o)
        .splice(-2);

      const joined = lastTwoParts.join("/");

      if (joined === "api/auth") {
        return Promise.reject(error.response ? error.response : error);
      }
    }

    if (error.response.status === 401 && !originalRequest.__isRetryRequest) {
      originalRequest.__isRetryRequest = true;

      return getAuthToken()
        .then(() => {
          const token = JSON.parse(localStorage.getItem("token") || "{}");
          originalRequest.headers.Authorization = `JWT ${token.access}`;

          return client(originalRequest);
        })
        .catch(() => Promise.reject(Error("Failed to obtain renew token")));
    }

    return Promise.reject(error.response ? error.response : error);
  }
);

const customFetch = function (options) {
  const token = JSON.parse(localStorage.getItem("token") || "{}");

  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    return Promise.reject(error);
  };

  if (token.access) {
    if (!options.headers) {
      options.headers = {};
    }

    options.headers.Authorization = `JWT ${token.access}`;
  }

  return client(options).then(onSuccess).catch(onError);
};

export default customFetch;

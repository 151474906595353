import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, message } from "antd";
import lodash from "lodash";

// components
import { LantItemLayout } from "../../../components";
import { DirectoryCategoryForm } from "../components/DirectoryCategoryForm";

// react-queries
import { useDirectoryCategory, useDirectoryCategoryUpdate } from "../../../react-query";

// helpers
import { setServerErrorsToForm } from "../../../helpers";
import { DISABLED_DIRECTORY_CATEGORIES } from "../../../constants";


export const EditDirectoryCategory = () => {
  const { id } = useParams();

  const history = useHistory();
  const [form] = Form.useForm();

  const redirectToList = () => {
    history.replace("/directory/directory-categories");
  };

  const { data, error, isFetching } = useDirectoryCategory(id, {
    onSuccess: (resp) => {
      history.replace({
        pathname: `/directory/directory-categories/${resp.id}`,
        state: { path: resp.title }
      });

      form.setFieldsValue(resp);
    },
    enabled: !!id
  });

  const updateDirectoryCategory = useDirectoryCategoryUpdate({
    onSuccess: () => {
      message.success("Category was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      const obj = {};
      if (err?.data?.title?.title) {
        obj.title = err.data.title.title;
      } else if (err?.data?.url?.url) {
        obj.url = err.data.url.url;
      }

      setServerErrorsToForm(form, lodash.isEmpty(obj) ? err?.data : obj);
    }
  });

  const onSubmit = (values) => {
    updateDirectoryCategory.mutate({ ...values, id });
  };

  return (
    <LantItemLayout item="directory-category" error={error} processing={isFetching}>
      <DirectoryCategoryForm
        form={form}
        title={`Edit ${data?.title || ""}`}
        isDisabled={DISABLED_DIRECTORY_CATEGORIES.includes(data?.id)}
        onSubmit={onSubmit}
        onCancel={redirectToList}
      />
    </LantItemLayout>
  );
};

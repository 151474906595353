export const GET_SYSTEM_PREFERENCES_REQUEST = "GET_SYSTEM_PREFERENCES_REQUEST";
export const GET_SYSTEM_PREFERENCES_REQUEST_SUCCESS = "GET_SYSTEM_PREFERENCES_REQUEST_SUCCESS";
export const GET_SYSTEM_PREFERENCES_REQUEST_ERROR = "GET_SYSTEM_PREFERENCES_REQUEST_ERROR";

export const PATCH_ADVANCED_PREFERENCES_REQUEST = "PATCH_ADVANCED_PREFERENCES_REQUEST";
export const PATCH_ADVANCED_PREFERENCES_REQUEST_SUCCESS = "PATCH_ADVANCED_PREFERENCES_REQUEST_SUCCESS";
export const PATCH_ADVANCED_PREFERENCES_REQUEST_ERROR = "PATCH_ADVANCED_PREFERENCES_REQUEST_ERROR";

export const PATCH_SYSTEM_PREFERENCES_REQUEST = "PATCH_SYSTEM_PREFERENCES_REQUEST";
export const PATCH_SYSTEM_PREFERENCES_REQUEST_SUCCESS = "PATCH_SYSTEM_PREFERENCES_REQUEST_SUCCESS";
export const PATCH_SYSTEM_PREFERENCES_REQUEST_ERROR = "PATCH_SYSTEM_PREFERENCES_REQUEST_ERROR";

export const PATCH_GENERAL_PROPERTIES_REQUEST = "PATCH_GENERAL_PROPERTIES_REQUEST";
export const PATCH_GENERAL_PROPERTIES_REQUEST_SUCCESS = "PATCH_GENERAL_PROPERTIES_REQUEST_SUCCESS";
export const PATCH_GENERAL_PROPERTIES_REQUEST_ERROR = "PATCH_GENERAL_PROPERTIES_REQUEST_ERROR";

export const SHOW_SYSTEM_PREFERENCES_PROCESSING = "SHOW_SYSTEM_PREFERENCES_PROCESSING";
export const HIDE_SYSTEM_PREFERENCES_PROCESSING = "HIDE_SYSTEM_PREFERENCES_PROCESSING";

export const SHOW_SYSTEM_PREFERENCES_LOADING = "SHOW_SYSTEM_PREFERENCES_LOADING";
export const HIDE_SYSTEM_PREFERENCES_LOADING = "HIDE_SYSTEM_PREFERENCES_LOADING";

export const RESET_ERROR = "RESET_SYSTEM_PREFERENCES_ERROR";
export const RESET_ERRORS = "RESET_SYSTEM_PREFERENCES_ERRORS";

import React from "react";
import PropTypes from "prop-types";


function ContentIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-21.000000, -116.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-33-icon/24/file" transform="translate(16.000000, 112.000000)">
              <path d="M19.1666667,20 L5.83333333,20 C5.37309604,20 5,19.6418278 5,19.2 L5,4.8 C5,4.3581722 5.37309604,4 5.83333333,4 L19.1666667,4 C19.626904,4 20,4.3581722 20,4.8 L20,19.2 C20,19.6418278 19.626904,20 19.1666667,20 Z M18.3333333,18.4 L18.3333333,5.6 L6.66666667,5.6 L6.66666667,18.4 L18.3333333,18.4 Z M9.16666667,8 L15.8333333,8 L15.8333333,9.6 L9.16666667,9.6 L9.16666667,8 Z M9.16666667,11.2 L15.8333333,11.2 L15.8333333,12.8 L9.16666667,12.8 L9.16666667,11.2 Z M9.16666667,14.4 L15.8333333,14.4 L15.8333333,16 L9.16666667,16 L9.16666667,14.4 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

ContentIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default ContentIcon

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { Checkbox, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

// components
import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";

// helpers
import dealers from "../../../../api/dealers";
import { Utils } from "../../../../helpers";
import navigation from "../../../../helpers/navigation";
import {
  getDealersNewsRequest,
  patchDealersNewsItemRequest,
} from "../../../../redux/dealers/dealersActions";

const DealersNews = ({
  location,
  history,
  getDealersNews,
  dealersNews,
  dealerNewsTotal,
  patchDealersNewsItem,
  userRole,
}) => {
  const { page, perPage, order } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");

  useEffect(() => {
    getDealersNews({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const onCheck = (e, data) => {
    data = Object.assign(data, { isActive: e.target.checked });
    patchDealersNewsItem({
      data,
      page,
      perPage
    })
  };

  const dealersNewsToRender = Utils.filterAndOrder(dealersNews, { order });

  const handleDelete = (id) => {
    dealers.deleteSingleDealersNews(id)
      .then(() => getDealersNews({ page, perPage }))
      .catch(() => message.error("Something is wrong"));
  }

  const renderDealersNewsTable = () => (
    <>
      <Table
        className="dealers-news--table"
        columnWidths={["40px", "15%", "auto", "10%", "80px"]}
        headerCells={[
          {},
          { label: "TITLE" },
          { label: "ARTICLE" },
          { label: "DATE" },
          { label: "ACTIONS" },
        ]}
      >
        {
          dealersNewsToRender && dealersNewsToRender.map((newsItem) => {
            const {
              id,
              url,
              createdAt,
              isActive,
              content,
              title,
            } = newsItem;
            const dateToDisplay = moment(createdAt).format("L HH:mm:ss");

            return (
              <TableRow key={`dealers-news--row-${id}`}>
                <Checkbox
                  checked={isActive}
                  onChange={e => onCheck(e, newsItem)}
                />
                <Link to={`/dealers/dealer-news/${url}`} className="dealers-news--title-column flex">{title}</Link>
                <div className="dealers-news--article-column flex" dangerouslySetInnerHTML={{ __html: content }} />
                <div className="dealers-news--date-column flex">{dateToDisplay}</div>
                <div className="dealers-news--actions-column">
                  <Link to={`/dealers/dealer-news/${url}`} className="dealers-news--title-column flex">
                    <EditOutlined className="dealers-news--action-icon" />
                  </Link>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => handleDelete(url)}
                  >
                    <DeleteOutlined className="dealers-news--action-icon" />
                  </Popconfirm>
                </div>
              </TableRow>
            )
          })
        }
        {
          dealerNewsTotal > 0 && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={dealerNewsTotal}
            />
          )
        }
      </Table>
    </>
  )

  return (
    <div className="dealers-news">
      <div className="dealers-news__header-controls">
        {!isDisabled &&
            <AddButton
              onClick={() => history.push("/dealers/dealer-news/add-new")}
            />
        }
      </div>

      {renderDealersNewsTable()}

    </div>
  )
}

DealersNews.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDealersNews: PropTypes.func.isRequired,
  dealersNews: PropTypes.array,
  dealerNewsTotal: PropTypes.number,
  patchDealersNewsItem: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    dealersNews: state.dealers.dealersNews,
    dealerNewsTotal: state.dealers.dealerNewsTotal,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getDealersNews: payload => dispatch(getDealersNewsRequest(payload)),
    patchDealersNewsItem: payload => dispatch(patchDealersNewsItemRequest(payload)),
  })
)(DealersNews);

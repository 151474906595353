import React from "react";
import PropTypes from "prop-types";

import Icon from "@ant-design/icons";


function StatusBlock({ number, text, isNumberHighlighted, isTextHighlighted, percentage }) {
  const numberClassNames = ["overview--status-block-number"];
  if (isNumberHighlighted) numberClassNames.push("dashboard--status-block-number__highlighted");

  const textClassNames = ["overview--status-block-text"];
  if (isTextHighlighted) numberClassNames.push("dashboard--status-block-text__highlighted");

  const renderPercentage = () => {
    if (!percentage) return;

    if (percentage > 0) {
      return (
        <div className="up">
          <Icon type="rise" />
          +{ percentage }
        </div>
      )
    }

    if (percentage < 0) {
      return (
        <div className="down">
          <Icon type="fall" />
          { percentage }
        </div>
      )
    }
  };

  return (
    <div className="flex overview--status-block">
      <div className="body">
        <span className={numberClassNames.join(" ")}>{number}</span>
        {renderPercentage()}
      </div>
      <span className={textClassNames.join(" ")}>{text}</span>
    </div>
  )
}

StatusBlock.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.string.isRequired,
  down: PropTypes.string,
  up: PropTypes.string,
  isNumberHighlighted: PropTypes.bool,
  isTextHighlighted: PropTypes.bool,
  percentage: PropTypes.number
};

export default StatusBlock;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { selectCurrencySymbol } from "../../../../../helpers/currency";

import "../../../../../assets/styles/components/orders/templates/paypalDealerConfirmation.scss";

const PaypalDealerConfirmation = ({ order }) => {
  return (
    <div className="root-container">
      <div className="template-name">
        Purchase confirmation from LoveAntiques.com
      </div>

      <div className="dealer-note">
        <p>
          Dear{" "}
          <span className="outlined-text">
            {order.dealer.businessName || "Dealer"}
          </span>
          ,
        </p>
        A purchase has been made through your website via PayPal. Please log in
        to your LoveAntiques account to view the details.
      </div>

      <div className="grey-block">
        <table>
          <tr>
            <td className="info-row-title">Item Reference:</td>
            <td className="info-row-value"> {order.item.ref || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Item Title:</td>
            <td className="info-row-value">{order.item.title || "-"}</td>
          </tr>
        </table>
      </div>

      <div className="grey-block">
        <p className="grey-block-title">Purchase Information</p>

        <table>
          <tr>
            <td className="info-row-title">Date:</td>
            <td className="info-row-value">
              {order.createdAt
                ? moment(order.createdAt).format("L h:mm:ss")
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">
              {order.payment.method === "PAYPAL" ? "Paypal" : "Stripe"}{" "}
              Reference:
            </td>
            <td className="info-row-value">{order.payment.ref || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Cart ID:</td>
            <td className="info-row-value">{order.header.cartId || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Order No.:</td>
            <td className="info-row-value">
              {typeof order.header.orderNumber === "number"
                ? order.header.orderNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">Item Amount:</td>
            <td className="info-row-value">
              {`${order.total.productTotal || "-"} ${selectCurrencySymbol(
                order.total.currency
              )}`}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">Shipping Amount:</td>
            <td className="info-row-value">
              {`${order.total.shippingTotal || "-"} ${selectCurrencySymbol(
                order.total.currency
              )}`}
            </td>
          </tr>
        </table>
      </div>

      <div className="grey-block">
        <p className="grey-block-title">Buyer Information</p>

        <table>
          <tr>
            <td className="info-row-title">Name:</td>
            <td className="info-row-value">{order.billTo.name || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Email:</td>
            <td className="info-row-value">
              <a href={`mailto:${order.billTo.email}`}>
                {order.billTo.email || "-"}
              </a>
            </td>
          </tr>
          <tr>
            <td className="info-row-title">Address:</td>
            <td className="info-row-value">{order.billTo.address || "-"}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

PaypalDealerConfirmation.propTypes = {
  order: PropTypes.object.isRequired,
};

export default PaypalDealerConfirmation;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Empty, Select, Spin } from "antd";
import debounce from "lodash/debounce";


function AsyncSelect({ defaultValue, selected, setSelected, getOptions, label = "" }) {
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);

  // Methods
  const searchCategories = value => {
    if (!value) return;

    setLoading(true);
    setSelectOptions([]);
    getOptions({ page: 1, perPage: 200, search: value })
      .then(result => {
        setSelectOptions(result.results);
      })
      .catch(console.warn)
      .finally(() => setLoading(false))
  };

  const searchCategoriesDebounced = useCallback(debounce(searchCategories, 150), []);


  // Render
  return (
    <div className="flex labeled-input labeled-input__horizontal">
      <span className="labeled-input--label">{ label }</span>
      <Select
        showSearch
        allowClear
        defaultValue={defaultValue}
        onSearch={searchCategoriesDebounced}
        filterOption={false}
        notFoundContent={
          loading ?
            <Spin size="small" /> :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
        }
        className="seo-cat--select labeled-input--input"
        value={selected}
        onChange={setSelected}
        placeholder="All Categories"
      >
        {
          selectOptions.map(el => {
            return <Select.Option key={el.url} value={el.id}>{el.title}</Select.Option>
          })
        }
      </Select>
    </div>
  )
}

AsyncSelect.propTypes = {
  selected: PropTypes.number,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  setSelected: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
};

export default AsyncSelect;

export const DIRECTORY_ENTRY_STATUSES = [
  { status: "ACTIVE" },
  { status: "PENDING" },
  { status: "INACTIVE" },
];
export const DIRECTORY_REQUEST_CLAIM_STATUSES = [
  { status: "APPROVED", title: "Please be aware you will assign the owner to the business directory entry" },
  { status: "SUBMITTED" },
  { status: "DECLINED", title: "Are you sure you want to decline the business directory entry claim request?" },
];
export const DIRECTORY_REQUEST_TAKEDOWN_STATUSES = [
  { status: "APPROVED", title: "Please be aware this business will become inactive and will not  be shown in the directory listings" },
  { status: "SUBMITTED" },
  { status: "DECLINED", title: "Are you sure you want to decline the business directory entry 'Remove Listing' request?" },
];
export const DIRECTORY_SUBSCRIPTION_STATUSES = [
  { status: "ACTIVE" },
  { status: "INACTIVE" },
];
export const DIRECTORY_CANCEL_SUBSCRIPTION_STATUSES = [
  { status: "SUBMITTED" },
  { status: "APPROVED" },
];
export const DIRECTORY_STATUS_OPTIONS = [
  { value: "", label: "All statuses" },
  { value: "True", label: "Premium" },
  { value: "False", label: "Not Premium" },
];
export const DIRECTORY_UPGRADED_TO_PREMIUM_OPTIONS = [
  { value: "", label: "All" },
  { value: "True", label: "Manually" },
  { value: "False", label: "Automatically" },
]

import { all, put, call, takeLatest } from "redux-saga/effects";

import currency from "../../../../api/currency";
import * as types from "./currencyCodesActionsTypes";
import navigation from "../../../../helpers/navigation";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(currency.get, params);
    yield put({ type: types.GET_CURRENCY_CODES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CURRENCY_CODES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *create ({ data }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(currency.create, data);
    yield put({ type: types.GET_CURRENCY_CODES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.CURRENCY_CODES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export function *update ({ data, id, isPatch }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) {
      yield call(currency.updatePatch, id, data);
    }
    else {
      yield call(currency.updatePut, id, data);
    }

    yield put({ type: types.GET_CURRENCY_CODES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.CURRENCY_CODES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export function *remove (action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(currency.delete, action.payload.id);
    yield put({ type: types.GET_CURRENCY_CODES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.CURRENCY_CODES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_CURRENCY_CODES_REQUEST, get),
    yield takeLatest(types.CREATE_CURRENCY_CODES_REQUEST, create),
    yield takeLatest(types.UPDATE_CURRENCY_CODES_REQUEST, update),
    yield takeLatest(types.DELETE_CURRENCY_CODES_REQUEST, remove),
  ])
}

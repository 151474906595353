import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Input } from "antd";

// components
import { LantForm, LantFormItem } from "../../../components";

export const DirectoryCategoryForm = ({ form, title, isDisabled, onSubmit, onCancel }) => {
  const handleSubmit = () => {
    if (typeof onSubmit !== "function") {
      return;
    }

    form.validateFields()
      .then((values) => {
        onSubmit(values)
      })
      .catch((err) => console.error(err));
  };

  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  return (
    <LantForm
      form={form}
      initialValues={{ isActive: false }}
      title={title}
      formFooterProps={{
        cancelBtnProps: { label: "Close" },
      }}
      {...(!isDisabled && { onSubmit: handleSubmit })}
      onCancel={onCancel}
    >
      <LantFormItem name="isActive" valuePropName="checked">
        <Checkbox disabled={isDisabled}>Active</Checkbox>
      </LantFormItem>
      <LantFormItem
        name="title"
        label="Title"
        lg={12}
        rules={[{ required: true }]}
      >
        <Input disabled={isDisabled} />
      </LantFormItem>
      <LantFormItem name="url" label="URL" lg={12} rules={[{ required: true }]}>
        <Input
          inputClassName="input-with-prefix"
          addonBefore={"https://www.loveantiques.com/"}
          disabled={isDisabled}
          suffix={urlFieldImage}
        />
      </LantFormItem>
      <LantFormItem name="titleTag" label="Title tag">
        <Input disabled={isDisabled} />
      </LantFormItem>
      <LantFormItem name="h1" label="H2">
        <Input disabled={isDisabled} />
      </LantFormItem>
      <LantFormItem name="metaDescription" label="Meta description">
        <Input.TextArea disabled={isDisabled} />
      </LantFormItem>
    </LantForm>
  );
}

DirectoryCategoryForm.propTypes = {
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

import * as types from "./dashboardActionTypes";


export const getUsersRequest = () => {
  return { type: types.GET_USERS_REQUEST };
};

export const elasticSearchRequest = (payload) => {
  return { type: types.ELASTIC_SEARCH_REQUEST, payload };
};

import * as types from "./usersActionsTypes";

export const getUsersRequest = payload => {
  return { type: types.GET_USERS_LIST_REQUEST, payload };
};

export const patchUserRequest = payload => {
  return { type: types.PATCH_USER_REQUEST, payload };
};

export const deleteUserRequest = payload => {
  return { type: types.DELETE_USER_REQUEST, payload };
};

export const resetErrorsAction = () => {
  return { type: types.RESET_ERRORS };
};

export const convertUserRoleRequest = payload => {
  return { type: types.CONVERT_USER_ROLE_REQUEST, payload };
};

export const getSubscribersRequest = payload => {
  return { type: types.GET_SUBSCRIBERS_LIST_REQUEST, payload };
};

export const deleteAnonymousSubscriberRequest = payload => {
  return { type: types.DELETE_ANONYMOUS_SUBSCRIBER_REQUEST, payload };
};

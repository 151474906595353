import React from "react";
import PropTypes from "prop-types";


function OrderHeaderIcon({ className = "", fillColor = "#717171" }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Orders" transform="translate(-1331.000000, -203.000000)" fill={fillColor}>
          <g id="icon/24/edit-copy-72-icon/24/history" transform="translate(1328.000000, 200.000000)">
            <path d="M12,21 C7.0293,21 3,16.9707 3,12 C3,7.0293 7.0293,3 12,3 C16.9707,3 21,7.0293 21,12 C21,16.9707 16.9707,21 12,21 Z M12,19.2 C15.9764502,19.2 19.2,15.9764502 19.2,12 C19.2,8.0235498 15.9764502,4.8 12,4.8 C8.0235498,4.8 4.8,8.0235498 4.8,12 C4.8,15.9764502 8.0235498,19.2 12,19.2 Z M12.9,12 L16.5,12 L16.5,13.8 L11.1,13.8 L11.1,7.5 L12.9,7.5 L12.9,12 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>

  )
}

OrderHeaderIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default OrderHeaderIcon

import { all, put, call, takeLatest } from "redux-saga/effects";

import currency from "../../../../api/currency";
import * as types from "./currencyFeedActionsTypes";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(currency.getFeed, params);
    yield put({ type: types.GET_CURRENCY_FEED_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENCY_FEED_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_CURRENCY_FEED_REQUEST, get),
  ])
}

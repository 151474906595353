import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import LabeledInput from "../../shared/LabeledInput";
import EmailEnquiryRow from "./EmailEnquiryRow";

import {
  getEmailEnquiriesRequest,
  patchEmailEnquiriesRequest,
} from "../../../redux/enquiries/emailEnquiries/emailEnquiriesActions";
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";
import CustomDateRangePicker from "../../shared/CustomDateRangePicker";
import DealerSelectWithSearch from "../../shared/DealerSelectWithSearch";


function EmailEnquiries({
  items,
  totalItems,
  location,
  history,
  userProfile,
  getEmailEnquiries,
  patchEmailEnquiries
}) {
  const { search, page, perPage, order, dateFilter, typeFilter } = navigation.getTableQueryParams(location);
  const [dealerId, setDealerId] = useState();
  const [selectedStatusOption, setSelectedStatusOption] = useState("pending");

  useEffect(() => {
    const params = {
      page,
      perPage,
      search,
      status: selectedStatusOption,
      dateFilter
    };
    if (dealerId) params.dealer = dealerId;
    if (dealerId === "All dealers") delete params.dealer;
    if (typeFilter) params.is_la = typeFilter;

    getEmailEnquiries(params);
  }, [page, perPage, search, selectedStatusOption, dateFilter, dealerId, typeFilter]); // eslint-disable-line

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const onStatusClick = (id, newStatus) => {
    patchEmailEnquiries(id, { status: newStatus }, selectedStatusOption);
  };

  const onTypeFilterChange = value => {
    navigation.setDynamicKeys({ page: 1, typeFilter: value }, location, history);
  };

  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="enquiries">
      <TableAndFormLayout showForm={false}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="flex">
              <LabeledInput
                className="enquiries--select"
                label="Status"
                type="select"
                value={selectedStatusOption}
                options={[
                  { value: "", label: "All" },
                  { value: "pending", label: "Pending" },
                  { value: "delete", label: "Delete" },
                  { value: "reject", label: "Reject" },
                  { value: "released", label: "Released" }
                ]}
                onChange={value => setSelectedStatusOption(value)}
                horizontal
              />

              <CustomDateRangePicker dateFilter={dateFilter} />

              <LabeledInput
                className="enquiries--select"
                label="Type"
                type="select"
                value={typeFilter}
                options={[
                  { value: "", label: "All" },
                  { value: "false", label: "Website enquiries" },
                  { value: "true", label: "LoveAntiques enquiries" }
                ]}
                onChange={onTypeFilterChange}
                horizontal
              />

              <div className="email-enq--filter-by-dealer">
                <DealerSelectWithSearch
                  placeholder="By Dealer"
                  onSelect={(value) => setDealerId(value)}
                />
              </div>
            </div>

            <div className="flex">
              <SearchInput value={search} onChange={onSearch} />
            </div>
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["120px", "auto", "75px", "auto", "auto", "120px", "135px", "65px"]}
            headerCells={[
              { label: "Status" },
              { label: "Item" },
              { label: "Price" },
              { label: "Dealer" },
              { label: "Customer" },
              { label: "Type" },
              { label: "Date" },
              { label: "Actions" },
            ]}
          >
            {({ columnWidths }) => {
              return itemsToRender.map(enquiry => {
                return (
                  <EmailEnquiryRow
                    key={`group:${enquiry.id}`}
                    columnWidths={columnWidths}
                    enquiry={enquiry}
                    onStatusClick={onStatusClick}
                    userRole={userProfile.roles[0]}
                    history={history}
                  />
                )
              })
            }}

            {totalItems && (
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            )}
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
}

EmailEnquiries.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEmailEnquiries: PropTypes.func.isRequired,
  patchEmailEnquiries: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    items: state.emailEnquiries.items,
    totalItems: state.emailEnquiries.total,
    userProfile: state.auth.userProfile,
  }),
  dispatch => ({
    getEmailEnquiries: params => dispatch(getEmailEnquiriesRequest(params)),
    patchEmailEnquiries: (id, data, status) => dispatch(patchEmailEnquiriesRequest(id, data, status)),
  })
)(EmailEnquiries);

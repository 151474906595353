import lodash from "lodash";
import { message } from "antd";

export const formErrorsHandler = (err) => {
  // show validation errors in console
  console.error("Form validation error: ", err);

  // get the first error field and scroll to it
  const node = document.querySelector(".ant-form-item-has-error");

  if (node) {
    node.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export const setServerErrorsToForm = (form, error) => {
  try {
    if (typeof error === "object" && !lodash.isEmpty(error)) {
      const fields = Object.keys(form.getFieldsValue());
      const formErrors = [];
      const messageErrors = [];

      Object.entries(error).forEach(([name, errors = []]) => {
        if (fields.some((el) => el === name)) {
          formErrors.push({ name, errors });
        } else {
          messageErrors.push(`${name}: ${lodash.flatten(errors).join(" ")}`);
        }
      });

      if (formErrors.length) {
        form.setFields(formErrors);
      }

      if (messageErrors.length) {
        message.error(messageErrors);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

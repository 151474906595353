/* eslint-disable */
import React, { useState, useEffect } from "react";

const AdminHelper = {
  confirmationDoubleToggleButton: (confirmed, leftSideValue, rightSideValue, setConfirmedValue) => {
    const [activeSide, setActiveSide] = useState(confirmed);

    useEffect(() => {
      setActiveSide(confirmed)
    }, [confirmed]);

    return (
      <div className="confirmation-double-toggle-button">
        <div
          className={`confirmation-double-toggle-button--left-side${activeSide ? "-active" : ""}`}
          onClick={() => {
            setActiveSide(true);
            setConfirmedValue("CONFIRMED");
          }}
        >
          {leftSideValue}
        </div>
        <div
          className={`confirmation-double-toggle-button--right-side${activeSide ? "" : "-active"}`}
          onClick={() => {
            setActiveSide(false);
            setConfirmedValue("UNCONFIRMED");
          }}
        >
          {rightSideValue}
        </div>
      </div>
    );
  },

  loginDoubleToggleButton: (login, leftSideValue, rightSideValue, setLoginValue) => {
    const [activeSide, setActiveSide] = useState(login);

    useEffect(() => {
      setActiveSide(login)
    }, [login]);

    const leftSideClass = activeSide ? "login-double-toggle-button--left-side-active" : "login-double-toggle-button--left-side";
    const rightSideClass = activeSide ? "login-double-toggle-button--right-side" : "login-double-toggle-button--right-side-active";

    return (
      <div className="login-double-toggle-button">
        <div
          className={leftSideClass}
          onClick={() => {
            setActiveSide(true);
            setLoginValue(true);
          }}
        >
          {leftSideValue}
        </div>
        <div
          className={rightSideClass}
          onClick={() => {
            setActiveSide(false);
            setLoginValue(false);
          }}
        >
          {rightSideValue}
        </div>
      </div>
    );
  },
};

export default AdminHelper;

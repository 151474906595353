import * as types from "./artisansActionTypes";


export const getArtisansRequest = params => {
  return { type: types.GET_ARTISANS_REQUEST, params };
};

export const createArtisansRequest = (data, callback) => {
  return { type: types.CREATE_ARTISANS_REQUEST, data, callback };
};

export const updateArtisansRequest = (url, data, isPatch, callback) => {
  return { type: types.UPDATE_ARTISANS_REQUEST, url, data, isPatch, callback };
};

export const deleteArtisansRequest = (url, callback) => {
  return { type: types.DELETE_ARTISANS_REQUEST, url, callback };
};

export const getArtisansTagsRequest = () => {
  return { type: types.GET_ARTISANS_TAGS_REQUEST };
};

import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

// components
import TableRow from "../../../shared/Table/TableRow";
import Table from "../../../shared/Table/Table";
import AddButton from "../../../shared/AddButton";
import { ConfirmDeleteModal } from "../../../../components";
import { DirectoryEventModal } from "../DirectoryEventModal";
import { InlineIcon } from "../../../../icons";

// react-queries
import { useFairEventDelete } from "../../../../react-query";


export const FairEvents = ({ data }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);

  const deleteItem = useFairEventDelete({
    onSuccess: () => setDeletingItem(null)
  });

  const onDelete = () => {
    deleteItem.mutate(deletingItem.id)
  }

  if (!data?.events) {
    return null;
  }

  return (
    <div className="directory-entry-events">
      <div className="component--header mt-20">
        <AddButton onClick={() => setCurrentItem({})} />
      </div>

      <Table
        className="directory-categories--table"
        columnWidths={["auto", "auto", "auto", "160px", "80px", "70px"]}
        headerCells={[
          { label: "Opening Times" },
          { label: "Admission" },
          { label: "Description" },
          { label: "Buy Ticket Link" },
          { label: "Facebook Link" },
          { label: "Date" },
          { label: "Actions" }
        ]}
      >
        {data.events.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex">
                {item.openingTimes || "-"}
              </div>
              <div className="flex">
                {item.admission}
              </div>
              <div className="flex">
                {item.description}
              </div>
              <div className="flex">
                <a href={item.buyTicketLink} target="_blank" rel="noopener noreferrer">
                  {item.buyTicketLink}
                </a>
              </div>
              <div className="flex">
                <a href={item.facebookUrl} target="_blank" rel="noopener noreferrer">
                  {item.facebookUrl}
                </a>
              </div>
              <div className="flex created-column">
                {moment(item.fromDate).format("DD MMM YYYY")}
              </div>
              <div className="flex lant-table--actions-column" style={{ fontSize: "16px" }}>
                <InlineIcon type="edit" onClick={() => setCurrentItem(item)} />
                <InlineIcon type="delete" danger onClick={() => setDeletingItem(item)} />
              </div>
            </TableRow>
          )
        })}
      </Table>

      <DirectoryEventModal
        event={currentItem}
        fairId={data?.id}
        defaultDescription={data?.description}
        onClose={() => setCurrentItem(null)}
      />

      <ConfirmDeleteModal
        title="Delete event"
        open={!!deletingItem?.id}
        processing={deleteItem.isLoading}
        closeModal={() => setDeletingItem(null)}
        onConfirm={onDelete}
      />
    </div>
  );
};

FairEvents.propTypes = {
  data: PropTypes.object,
}

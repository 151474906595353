import React from "react";
import PropTypes from "prop-types";


const KeyIcon = ({ fillColor = "#717171", className = "" }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/edit-copy-74-icon/24/password" fill={fillColor}>
          <path d="M11.4394209,11.4582828 L17.9483801,5 L19.1209712,6.16346182 L17.9483801,7.32774645 L20,9.36339323 L18.8274088,10.5276779 L16.7749596,8.49120827 L15.6023684,9.65467009 L17.3612552,11.3998628 L16.188664,12.5641475 L14.4297772,10.8181319 L12.6120121,12.6217446 C13.7808342,14.3840358 13.4174277,16.7378714 11.7705234,18.0721882 C10.123619,19.4065049 7.7265543,19.2891942 6.21998035,17.8005485 C4.7098594,16.3078039 4.58650039,13.9221659 5.93472046,12.2839118 C7.28294052,10.6456577 9.66341673,10.2886192 11.4394209,11.4582828 Z M10.9111744,16.6362638 C11.56888,16.0190042 11.8367285,15.0959683 11.6105333,14.2261948 C11.3843381,13.3564213 10.6998086,12.6772213 9.82321024,12.4527872 C8.94661187,12.2283531 8.01633319,12.4941162 7.39423009,13.1467012 C6.45141649,14.1152681 6.46490005,15.6548536 7.42453525,16.6070174 C8.38417044,17.5591812 9.9358367,17.5725598 10.9120037,16.6370866 L10.9111744,16.6362638 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

KeyIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default KeyIcon

import * as types from "./helpTextActionsTypes";

export const getContentHelpTextRequest = payload => {
  return { type: types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST, payload };
};

export const patchHelpTextItemRequest = payload => {
  return { type: types.PATCH_HELP_TEXT_ITEM_REQUEST, payload };
};

export const createHelpTextItemRequest = payload => {
  return { type: types.CREATE_HELP_TEXT_ITEM_REQUEST, payload };
};

export const deleteHelpTextItemRequest = payload => {
  return { type: types.DELETE_HELP_TEXT_ITEM_REQUEST, payload };
};

export const getHelpTextChoicesRequest = payload => {
  return { type: types.GET_HELP_TEXT_CHOICES_REQUEST, payload };
};

export const putHelpTextItemRequest = payload => {
  return { type: types.PUT_HELP_TEXT_ITEM_REQUEST, payload };
};

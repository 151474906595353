import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Checkbox, Tooltip } from "antd";

import AddEditBlacklistsEnquiries from "./AddEditBlacklisted";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import LabeledInput from "../../shared/LabeledInput";
import navigation from "../../../helpers/navigation";
import AddButton from "../../shared/AddButton";
import DeleteIcon from "../../Icon/DeleteIcon";

import {
  deleteBlacklistsEnquiriesRequest,
  getBlacklistsEnquiriesRequest,
  updateBlacklistsEnquiriesRequest,
  getBlacklistsEnquiriesChoicesRequest,
  deleteEnquiryFromBlacklist,
} from "../../../redux/enquiries/blacklistsEnquiries/blacklistsEnquiriesActions";
import { Utils } from "../../../helpers";

const REVERT_TOOLTIP = "Remove Enquiry from Blacklist";


const Blacklisted = ({
  items,
  totalItems,
  options,
  location,
  history,
  onToggleEdit,
  getBlacklistsEnquiriesChoices,
  getBlacklistsEnquiries,
  updateBlacklistsEnquiries,
  deleteBlacklistsEnquiries,
  userRole,
  deleteFromBlacklist,
}) => {
  const [selected, setSelected] = useState(null);

  const { search, page, perPage, order, filter } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");

  // Hide Tab navigator on edit
  useEffect(() => {
    onToggleEdit(!!selected);
  }, [selected]); // eslint-disable-line

  // Get Blacklist types
  useEffect(() => {
    getBlacklistsEnquiriesChoices({});
  }, []); // eslint-disable-line

  // Pagination/search api call here
  useEffect(() => {
    getBlacklistsEnquiries({ page, perPage, search, type: filter });
  }, [page, perPage, search, filter]); // eslint-disable-line


  // Methods
  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onStartEditClick = item => {
    if (selected) return;

    setSelected(item);
  };

  const onChangeActive = (itemId, event) => {
    updateBlacklistsEnquiries(itemId, { isActive: event.target.checked });
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) target = selected.id;

    deleteBlacklistsEnquiries(target);
    setSelected(null);
  };

  const onTypeFilterChange = value => {
    navigation.setDynamicKeys({ page: 1, filter: value }, location, history);
  };


  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  const typeOptions = [{ label: "All", value: "" }];
  Object.keys(options).forEach(key => {
    typeOptions.push({ label: options[key], value: key });
  });

  return (
    <div className="enquiries">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="flex">
              {!isDisabled && <AddButton className="blacklists-enq--add-button" onClick={onAddClick} />}

              <LabeledInput
                className="enquiries--select"
                label="Type"
                type="select"
                value={filter}
                options={typeOptions}
                onChange={onTypeFilterChange}
                horizontal
              />
            </div>

            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["40px", "60px", "200px", "100px", "auto", "100px", "150px", "70px"]}
            headerCells={[
              {},
              { label: "ID" },
              { label: "Value" },
              { label: "Type" },
              { label: "Reason" },
              { label: "Origin" },
              { label: "dfa" },
              { label: "Actions" },
            ]}
          >
            {
              itemsToRender.map(item => {
                return (
                  <TableRow key={`group:${item.id}`}>
                    <Checkbox checked={item.isActive} onChange={e => onChangeActive(item.id, e)} />
                    <div className="flex table-sm-text">{item.id}</div>
                    <div className="flex table-text" onClick={() => onStartEditClick(item)}>{item.value}</div>
                    <div className="flex table-sm-text">{options[item.type] || item.type}</div>
                    <div className="flex table-sm-text">{item.reason}</div>
                    <div className="flex table-sm-text">{item.origin}</div>
                    <div className="flex table-sm-text">{moment(item.dfa).format("DD/MM/YYYY HH:mm:ss")}</div>
                    <div>
                      <Tooltip title={REVERT_TOOLTIP}>
                        <div
                          className="delete-icon-root"
                          onClick={() => deleteFromBlacklist(item.id, { page, perPage, search, type: filter })}
                        >
                          <DeleteIcon
                            className="icon"
                            fillColor="#FF0000"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </TableRow>
                )
              })
            }

            {
              totalItems &&
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditBlacklistsEnquiries
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

Blacklisted.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getBlacklistsEnquiries: PropTypes.func.isRequired,
  updateBlacklistsEnquiries: PropTypes.func.isRequired,
  deleteBlacklistsEnquiries: PropTypes.func.isRequired,
  getBlacklistsEnquiriesChoices: PropTypes.func.isRequired,
  onToggleEdit: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
  deleteFromBlacklist: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    items: state.blacklistsEnquiries.items,
    totalItems: state.blacklistsEnquiries.total,
    options: state.blacklistsEnquiries.options,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getBlacklistsEnquiries: params => dispatch(getBlacklistsEnquiriesRequest(params)),
    updateBlacklistsEnquiries: (id, data) => dispatch(updateBlacklistsEnquiriesRequest(id, data, true)),
    deleteBlacklistsEnquiries: id => dispatch(deleteBlacklistsEnquiriesRequest(id)),
    getBlacklistsEnquiriesChoices: () => dispatch(getBlacklistsEnquiriesChoicesRequest()),
    deleteFromBlacklist: (id, params) => dispatch(deleteEnquiryFromBlacklist(id, params)),
  })
)(Blacklisted);

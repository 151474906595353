import React, { useEffect, useState } from "react";
import { Popconfirm, message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  patchDealerStatusDataRequest,
  resetErrorAction,
} from "../../../../../redux/dealers/dealersActions";

const TripleStatusesBlock = ({
  status,
  leftSideValue,
  middleSideValue,
  rightSideValue,
  dealerUrl,
  statusTitle,
  patchDealerStatusData,
  error,
  clearError
}) => {
  let leftSideClass = "item-status-triple-toggle-button--left-side";
  let middleSideClass = "item-status-triple-toggle-button--middle-side";
  let rightSideClass = "item-status-triple-toggle-button--right-side";
  const [activeSide, setActiveSide] = useState(status);

  if (activeSide && activeSide === "ACTIVE") {
    leftSideClass = "item-status-triple-toggle-button--left-side-active";
    middleSideClass = "item-status-triple-toggle-button--middle-side";
    rightSideClass = "item-status-triple-toggle-button--right-side";
  }

  if (activeSide && activeSide === "PENDING") {
    leftSideClass = "item-status-triple-toggle-button--left-side";
    middleSideClass = "item-status-triple-toggle-button--middle-side-active";
    rightSideClass = "item-status-triple-toggle-button--right-side";
  }

  if (activeSide && activeSide === "INACTIVE") {
    leftSideClass = "item-status-triple-toggle-button--left-side";
    middleSideClass = "item-status-triple-toggle-button--middle-side";
    rightSideClass = "item-status-triple-toggle-button--right-side-active";
  }

  // Effects

  useEffect(() => {
    setActiveSide(status);
  }, [status]);

  useEffect(() => {
    error && error.detail && message.error(error.detail);
    clearError();
  }, [error]); // eslint-disable-line

  // Methods

  const patchDealerStatus = (valueToSet) => {
    const dataToSend = {};

    dataToSend[statusTitle] = valueToSet;
    patchDealerStatusData({ data: dataToSend, url: dealerUrl }, () => setActiveSide(valueToSet));
  };

  // Render

  return (
    <div className="item-status-triple-toggle-button">
      <Popconfirm
        title="Are you sure? Changes you are going to make will impact dealer’s page/items appearance on the website"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          patchDealerStatus(leftSideValue.toUpperCase());
        }}
      >
        <div
          className={leftSideClass}
        >
          {leftSideValue}
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure? Changes you are going to make will impact dealer’s page/items appearance on the website"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          patchDealerStatus(middleSideValue.toUpperCase());
        }}
      >
        <div
          className={middleSideClass}
        >
          {middleSideValue}
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure? Changes you are going to make will impact dealer’s page/items appearance on the website"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          patchDealerStatus(rightSideValue.toUpperCase());
        }}
      >
        <div
          className={rightSideClass}
        >
          {rightSideValue}
        </div>
      </Popconfirm>
    </div>
  );
};

TripleStatusesBlock.propTypes = {
  status: PropTypes.string,
  leftSideValue: PropTypes.string.isRequired,
  middleSideValue: PropTypes.string.isRequired,
  rightSideValue: PropTypes.string.isRequired,
  patchDealerStatusData: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  dealerUrl: PropTypes.string,
  error: PropTypes.object,
  statusTitle: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  error: state.dealers.error
});

export default connect(
  mapStateToProps,
  dispatch => ({
    clearError: resetErrorAction,
    patchDealerStatusData: (entityUrl, data) => dispatch(patchDealerStatusDataRequest(entityUrl, data)),
  })
)(TripleStatusesBlock);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Route,
  Redirect,
  matchPath
} from "react-router-dom";

import TabNavigation from "../../shared/TabNavigation";
import AddEditCurrencyCodes from "./CurrencyCodes/AddEditCurrencyCodes";
import CurrencyCodes from "./CurrencyCodes/CurrencyCodes";
import CurrencyRates from "./CurrencyRates/CurrencyRates";
import CurrencyFeedHistory from "./CurrencyFeedHistory/CurrencyFeedHistory";

const renderNavigationTabs = (currentLocation, showTabNavigator) => {
  const isMatchLocation = matchPath(currentLocation, {
    path: [
      "/settings/currency/codes",
      "/settings/currency/rates",
      "/settings/currency/feed-history"
    ],
    exact: true
  });

  if (!showTabNavigator) return null;

  return isMatchLocation && (
    <div className="currency--tab-navigation">
      <TabNavigation
        items={[
          { label: "CURRENCY CODES", link: "/settings/currency/codes" },
          { label: "RATES", link: "/settings/currency/rates" },
          { label: "FEED HISTORY", link: "/settings/currency/feed-history" },
        ]}
      />
    </div>
  )
};

const Currency = ({ location }) => {
  const [showTabNavigator, setShowTabNavigator] = useState(true);

  useEffect(() => {
    setShowTabNavigator(location.pathname !== "/settings/currency/codes/add");
  }, [location.pathname]); // eslint-disable-line

  const onToggleEdit = isEditing => {
    setShowTabNavigator(!isEditing);
  };

  return (
    <div className="component">
      {renderNavigationTabs(location.pathname, showTabNavigator)}

      <Switch>
        <Route path="/settings/currency/codes/add" component={AddEditCurrencyCodes} />
        <Route path="/settings/currency/codes" render={props => <CurrencyCodes {...props} onToggleEdit={onToggleEdit} />} />
        <Route path="/settings/currency/rates" component={CurrencyRates} />
        <Route path="/settings/currency/feed-history" component={CurrencyFeedHistory} />
        <Redirect to="/settings/currency/codes" />
      </Switch>
    </div>
  )
}

Currency.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Currency;

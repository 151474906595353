import React, { useState, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Collapse,
  Avatar,
  Button,
  Switch,
  Tooltip,
  Modal as ModalWindow,
  Spin,
  Dropdown,
  Menu
} from "antd";
import Carousel, { Modal, ModalGateway } from "react-images";
import _ from "lodash";
import { StarOutlined, LoadingOutlined, CheckOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { FormProvider, useForm } from "react-hook-form";
import ReactPlayer from "react-player";

import AddEntity from "../Catalog/HomeAndLiving/AddEntity";
import ModalHeader from "./AddCategories/ModalHeader";
import LabeledInput from "../shared/LabeledInput";
import SharedSelectForForm from "../shared/SharedSelectForForm";
import CreateArtisanModal from "./CreateArtisanModal";

import ZoomInIcon from "../Icon/ZoomInIcon";
import RotateToLeftIcon from "../Icon/RotateToLeftIcon";
import RotateToRight from "../Icon/RotateToRightIcon";
import DeleteIcon from "../Icon/DeleteIcon";

import customFetch from "../../api/customFetch";
import { TriggerPatchProvider } from "../../helpers/triggerPatchContext";

import formCategoriesDefaultValues from "../../helpers/formCategoriesDefaultValues";
import formCategoriesToSend from "../../helpers/formCategoriesToSend";
import itemsApi from "../../api/items";

const HIDE_SHOW_ON_HOME_PAGE_TOOLTIP = "Show item on home page";

export default function ItemListContent({
  open,
  mainImage,
  patchItems,
  rotateImage,
  fetchData,
  groups,
  loading,
  error,
  queryStatus,
  showOnHomePage,
  itemRef,
  itemCreationDate,
  showInHomePage
}) {
  const [activeInput, setActiveInput] = useState("");
  const [activeInputText, setActiveInputText] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createArtisanModalIsOpen, setCreateArtisanModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [catalogModalIsOpen, setCatalogModalIsOpen] = useState(false);
  const [activeModalItem, setActiveModalItem] = useState({});
  const [activeModalItemUrl, setActiveModalItemUrl] = useState("");
  const [searchCategoryModal, setSearchCategoryModal] = useState("");
  const inputRef = useRef(null);

  const [heightValue, setHeightValue] = useState();
  const [heightMeasurement, setHeightMeasurement] = useState("mm");
  const [widthValue, setWidthValue] = useState();
  const [widthMeasurement, setWidthMeasurement] = useState("mm");
  const [depthValue, setDepthValue] = useState();
  const [depthMeasurement, setDepthMeasurement] = useState("mm");
  const [lengthValue, setLengthValue] = useState();
  const [lengthMeasurement, setLengthMeasurement] = useState("mm");
  const [diameterValue, setDiameterValue] = useState();
  const [diameterMeasurement, setDiameterMeasurement] = useState("mm");
  const [weightValue, setWeightValue] = useState();
  const [customMeasurements, setCustomMeasurements] = useState();
  const [weightMeasurement, setWeightMeasurement] = useState("g");
  const [MFGDate, setMFGDate] = useState(itemCreationDate || "");
  const [isAdditionalCategoriesCollapsed, setIsAdditionalCategoriesCollapsed] = useState(false);
  const [itemToRender, setItemToRender] = useState({});
  const [itemToRenderProcessing, setItemToRenderProcessing] = useState(false);
  const [shouldRequestItemData, setShouldRequestItemData] = useState(true);
  const [userInteracted, setUserInteracted] = useState({
    mainCategory1: false,
    mainCategory2: false,
    mainCategory3: false,
    mainCategory4: false,
    mainCategory5: false
  });

  const methods = useForm({ mode: "onChange" });

  const [activeTabOfModal, setActiveTabOfModal] = useState("");

  const itemDeliveryInfo = _.get(itemToRender, "deliveryInfo.deliveryInfo", "");

  let dependencies = {};
  if (!_.isEmpty(itemToRender)) {
    dependencies = {
      ...itemToRender.catalogsElements,
      Artisans: { items: itemToRender.artisans },
      Categories: { items: itemToRender.categories },
      Periods: { items: itemToRender.periods }
    }
  } else {
    dependencies = groups.reduce((res, el) => {
      res[el.title] = { url: el.url, items: [] }

      return res;
    }, {});
    dependencies.Artisans = { items: [] };
    dependencies.Categories = { items: [] };
    dependencies.Periods = { items: [] };
  }

  const [itemDependencies, setItemDependencies] = useState(dependencies);
  const [dependencyWasDeleted] = useState(false);
  const [dependencyWasAdded, setDependencyWasAdded] = useState(false);

  const images = useMemo(() => _.sortBy(itemToRender.images, e => (!e.isMain)).map(image => ({
    src: image.url,
    name: image.name,
    id: image.id
  })), [itemToRender.images]);

  const video = _.get(itemToRender, "videos[0]", {});
  const videoUrl = video.useFromUrl ? video.url : _.get(video, "video.url");

  // Helpers

  const groupedCatalogsElementsIds = () => {
    const copyOfItemDependencies = _.cloneDeep(itemDependencies);
    delete copyOfItemDependencies.Periods;
    delete copyOfItemDependencies.Artisans;
    delete copyOfItemDependencies.Categories;

    return Object.values(copyOfItemDependencies)
      .filter(e => e.items.length)
      .map(obj => obj.items.map(el => el.id)).flat();
  };

  const _patchItems = () => {
    const artisanItems = _.get(itemDependencies, "Artisans.items", []);
    const artisanIds = artisanItems === [] ? [] : artisanItems.map(el => el.id).flat();
    const categoriesItems = _.get(itemDependencies, "Categories.items", []);
    const categoriesIds = categoriesItems.map(el => el.id).flat();
    const periodsItems = _.get(itemDependencies, "Periods.items", []);
    const periodsIds = periodsItems.map(el => el.id).flat() || [];

    const dataToBeUpdated = {
      periods: periodsIds,
      categories: categoriesIds,
      artisans: artisanIds,
      catalogsElements: groupedCatalogsElementsIds()
    };

    patchItems({ id: itemRef, ...fetchData, data: dataToBeUpdated });
  };

  const formMeasurementToSend = (value, measurement) => {
    return value ? { ...({ value, measurement }) } : null;
  };

  // Effects

  useEffect(() => {
    if (open && shouldRequestItemData) {
      setItemToRenderProcessing(true);
      itemsApi.getItemDetails(itemRef)
        .then(itemResponse => setItemToRender(itemResponse))
        .finally(() => {
          setItemToRenderProcessing(false);
          setShouldRequestItemData(false);
        })
    }
  }, [open, shouldRequestItemData]); // eslint-disable-line

  useEffect(() => {
    methods.reset({
      ...itemToRender,
      ...formCategoriesDefaultValues(!_.isEmpty(itemToRender) ? itemToRender.categories : [])
    });
  }, [itemToRenderProcessing]); // eslint-disable-line

  useEffect(() => {
    if (!dependencyWasDeleted) return;
    _patchItems();
  }, [itemDependencies]); // eslint-disable-line

  useEffect(() => {
    if (!dependencyWasAdded) return;
    _patchItems();
  }, [itemDependencies]); // eslint-disable-line

  useEffect(() => {
    const { height, width, depth, length, diameter, weight, otherMeasurements } = itemToRender.measurements || {};

    height && setHeightValue(height.value);
    height && setHeightMeasurement(height.measurement);
    width && setWidthValue(width.value);
    width && setWidthMeasurement(width.measurement);
    depth && setDepthValue(depth.value);
    depth && setDepthMeasurement(depth.measurement);
    length && setLengthValue(length.value);
    length && setLengthMeasurement(length.measurement);
    diameter && setDiameterValue(diameter.value);
    diameter && setDiameterMeasurement(diameter.measurement);
    weight && setWeightValue(weight.value);
    weight && setWeightMeasurement(weight.measurement);
    otherMeasurements && setCustomMeasurements(otherMeasurements);
  }, [itemToRender.measurements]); // eslint-disable-line

  // Methods

  const saveChangesAndCloseModal = (updatedDependencies) => {
    const copyOfItemsDependencies = _.cloneDeep(itemDependencies);

    for (const key in updatedDependencies) {
      if (copyOfItemsDependencies[key].items !== updatedDependencies[key]) {
        copyOfItemsDependencies[key].items = updatedDependencies[key];
      }
    }

    setItemDependencies(copyOfItemsDependencies);
    setDependencyWasAdded(true);
    setCatalogModalIsOpen(false);
  };

  const modalTabChanged = (key, dependenciesList) => {
    setActiveTabOfModal(key);
    setActiveModalItemUrl(dependenciesList[key].url);
    setActiveModalItem({
      key: [key],
      dependencies: dependenciesList
    })
  };

  const toggleLightbox = selectedIndex => {
    setModalIsOpen(!modalIsOpen);
    setCurrentIndex(selectedIndex);
  };

  const handleChangeMainImage = (imageId) => {
    const newImages = [];

    let secondaryPriority = 1;
    for (const image of itemToRender.images) {
      if (image.id === imageId) newImages.push({ ...image, isMain: true, priority: 0 });
      else {
        newImages.push({ ...image, isMain: false, priority: secondaryPriority });
        secondaryPriority++;
      }
    }

    newImages.sort((a, b) => a.priority - b.priority);

    patchItems({
      id: itemRef,
      ...fetchData,
      data: { images: newImages },
    })

    setModalIsOpen(false);
  };

  // Render Methods

  const renderDataField = (title, value, input) => {
    const handleClickOutside = e => {
      if (inputRef.current && inputRef.current.contains(e.target)) return;

      setActiveInput("");
      document.removeEventListener("click", handleClickOutside, false);
    };


    if (activeInput === title) {
      let fieldText = activeInputText;

      if (title === "deliveryInfo") {
        fieldText = {
          deliveryInfo: activeInputText
        }
      }

      const submit = e => {
        e.preventDefault();

        patchItems({ id: itemRef, ...fetchData, data: { [title]: fieldText } });
        setActiveInput("");
      };

      return (
        <div>
          {
            input ?
              <input
                autoFocus
                defaultValue={value}
                type="text"
                ref={inputRef}
                onChange={e => setActiveInputText(e.target.value.trim())}
              /> :
              <textarea
                autoFocus
                defaultValue={value}
                onChange={e => setActiveInputText(e.target.value.trim())}
                ref={inputRef}
              />
          }
          <Button
            className="submit-button"
            type="primary"
            shape="round"
            size="small"
            onClick={submit}
          >
            <CheckOutlined />
          </Button>
        </div>
      )
    }

    return (
      <>
        <div
          className="disabled-input"
          onClick={() => {
            setActiveInputText(value);
            setActiveInput(title);

            document.addEventListener("click", handleClickOutside, false);
          }}
        >
          {value}
        </div>
        {
          !!(error && error[title]) && (
            <div className="has-error">
              <div className="ant-form-explain">{error[title][0] || "Error"}</div>
            </div>
          )
        }
      </>
    )
  };

  const renderDateMfgField = () => {
    const submit = e => {
      e.preventDefault();

      patchItems({ id: itemRef, data: { dateCreated: MFGDate }, query: queryStatus });

      if (!error) {
        const activePanel = document.querySelectorAll(".ant-collapse-item-active");
        if (activePanel.length) {
          for (let i = 0; i < activePanel.length; i++) {
            setTimeout(() => {
              const el = document.querySelector(".ant-collapse-item-active");
              el.children[0].click();
            }, 100);
          }
        }
      }
    };

    return (
      <>
        <div className="items--mfg-created-date-field">
          <LabeledInput
            label="Set mfg/created date"
            value={MFGDate}
            onChange={setMFGDate}
          />
        </div>
        <Button
          className="submit-button"
          type="primary"
          shape="round"
          size="small"
          onClick={submit}
        >
          <CheckOutlined />
        </Button>
      </>
    );
  };

  const renderHeightRow = () => {
    const menu = (
      <Menu onClick={(item) => setHeightMeasurement(item.key)}>
        <Menu.Item key="mm">{"mm"}</Menu.Item>
        <Menu.Item key="cm">{"cm"}</Menu.Item>
        <Menu.Item key="m">{"m"}</Menu.Item>
        <Menu.Item key="in">{"in"}</Menu.Item>
        <Menu.Item key="ft">{"ft"}</Menu.Item>
        <Menu.Item key="yd">{"yd"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--height-field" style={{ marginRight: "20px" }}>
        <React.Fragment>
          <LabeledInput
            label="Height"
            value={heightValue}
            onChange={setHeightValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{heightMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderWidthRow = () => {
    const menu = (
      <Menu onClick={(item) => setWidthMeasurement(item.key)}>
        <Menu.Item key="mm">{"mm"}</Menu.Item>
        <Menu.Item key="cm">{"cm"}</Menu.Item>
        <Menu.Item key="m">{"m"}</Menu.Item>
        <Menu.Item key="in">{"in"}</Menu.Item>
        <Menu.Item key="ft">{"ft"}</Menu.Item>
        <Menu.Item key="yd">{"yd"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--width-field">
        <React.Fragment>
          <LabeledInput
            label="Width"
            value={widthValue}
            onChange={setWidthValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{widthMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderDepthRow = () => {
    const menu = (
      <Menu onClick={(item) => setDepthMeasurement(item.key)}>
        <Menu.Item key="mm">{"mm"}</Menu.Item>
        <Menu.Item key="cm">{"cm"}</Menu.Item>
        <Menu.Item key="m">{"m"}</Menu.Item>
        <Menu.Item key="in">{"in"}</Menu.Item>
        <Menu.Item key="ft">{"ft"}</Menu.Item>
        <Menu.Item key="yd">{"yd"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--depth-field" style={{ marginRight: "20px" }}>
        <React.Fragment>
          <LabeledInput
            label="Depth"
            value={depthValue}
            onChange={setDepthValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{depthMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderLengthRow = () => {
    const menu = (
      <Menu onClick={(item) => setLengthMeasurement(item.key)}>
        <Menu.Item key="mm">{"mm"}</Menu.Item>
        <Menu.Item key="cm">{"cm"}</Menu.Item>
        <Menu.Item key="m">{"m"}</Menu.Item>
        <Menu.Item key="in">{"in"}</Menu.Item>
        <Menu.Item key="ft">{"ft"}</Menu.Item>
        <Menu.Item key="yd">{"yd"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--length-field">
        <React.Fragment>
          <LabeledInput
            label="Length"
            value={lengthValue}
            onChange={setLengthValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{lengthMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderDiameterRow = () => {
    const menu = (
      <Menu onClick={(item) => setDiameterMeasurement(item.key)}>
        <Menu.Item key="mm">{"mm"}</Menu.Item>
        <Menu.Item key="cm">{"cm"}</Menu.Item>
        <Menu.Item key="m">{"m"}</Menu.Item>
        <Menu.Item key="in">{"in"}</Menu.Item>
        <Menu.Item key="ft">{"ft"}</Menu.Item>
        <Menu.Item key="yd">{"yd"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--diameter-field" style={{ marginRight: "20px" }}>
        <React.Fragment>
          <LabeledInput
            label="Diameter"
            value={diameterValue}
            onChange={setDiameterValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{diameterMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderWeightRow = () => {
    const menu = (
      <Menu onClick={(item) => setWeightMeasurement(item.key)}>
        <Menu.Item key="mg">{"mg"}</Menu.Item>
        <Menu.Item key="g">{"g"}</Menu.Item>
        <Menu.Item key="kg">{"kg"}</Menu.Item>
        <Menu.Item key="lb">{"lb"}</Menu.Item>
        <Menu.Item key="oz">{"oz"}</Menu.Item>
        <Menu.Item key="stone">{"stone"}</Menu.Item>
      </Menu>
    );

    return (
      <div className="items--measurements-section--weight-field">
        <React.Fragment>
          <LabeledInput
            label="Weight"
            value={weightValue}
            onChange={setWeightValue}
          />
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>{weightMeasurement}</div>
          </Dropdown>
        </React.Fragment>
      </div>
    );
  };

  const renderCustomMeasurementsField = () => {
    return (
      <LabeledInput
        label="Custom Measurements"
        value={customMeasurements}
        onChange={setCustomMeasurements}
        type="textarea"
      />
    )
  };

  const renderSaveMeasurementsButton = () => {
    const measurementsData = itemToRender.measurements ?
      {
        measurements: {
          show: itemToRender.measurements.show,
          measurements: itemToRender.measurements.measurements,
          height: formMeasurementToSend(heightValue, heightMeasurement),
          width: formMeasurementToSend(widthValue, widthMeasurement),
          depth: formMeasurementToSend(depthValue, depthMeasurement),
          length: formMeasurementToSend(lengthValue, lengthMeasurement),
          diameter: formMeasurementToSend(diameterValue, diameterMeasurement),
          weight: formMeasurementToSend(weightValue, weightMeasurement),
          otherMeasurements: customMeasurements || null
        }
      } :
      null;

    const handleSaveMeasurements = () => {
      patchItems({ id: itemRef, ...fetchData, data: measurementsData });

      if (!error) {
        const activePanel = document.querySelectorAll(".ant-collapse-item-active");
        if (activePanel.length) {
          for (let i = 0; i < activePanel.length; i++) {
            setTimeout(() => {
              const el = document.querySelector(".ant-collapse-item-active");
              el.children[0].click();
            }, 100);
          }
        }
      }
    };

    return (
      <div className="items--measurements-section--save-button">
        <Button
          className="save-measurements-button"
          type="primary"
          shape="round"
          size="small"
          onClick={handleSaveMeasurements}
        >
          <CheckOutlined />
        </Button>
      </div>
    );
  };

  const renderMeasurementsSection = () => (
    <div className="items--measurements-section">
      <div className="items--measurements-section--container">
        {renderHeightRow()}
        {renderWidthRow()}
      </div>

      <div className="items--measurements-section--container">
        {renderDepthRow()}
        {renderLengthRow()}
      </div>

      <div className="items--measurements-section--container">
        {renderDiameterRow()}
        {renderWeightRow()}
      </div>

      <div className="items--measurements-section--container--custom">
        {renderCustomMeasurementsField()}
      </div>
      {renderSaveMeasurementsButton()}
    </div>
  );

  const renderImages = () => itemToRender.images.filter(e => !e.isMain).map((image) => {
    const index = images.findIndex(e => e.id === image.id);

    return (
      <div className="images-list--item zoom-in" key={index} onClick={() => toggleLightbox(index)}>
        <ZoomInIcon />
        <img src={image.url} alt="Item view" />
      </div>
    )
  });

  const customCarouselFooter = ({ currentView }) => {
    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
      <div className="carousel-footer">
        <span className="name">{currentView.name}</span>
        <div className="navigation">
          {
            loading ? <Spin indicator={loadingIcon} /> :
              <>
                <StarOutlined
                  onClick={() => handleChangeMainImage(currentView.id)}
                />
                <RotateToLeftIcon onClick={() => rotateImage({ id: currentView.id, degree: 90 })} className="mr-16p" />
                <RotateToRight onClick={() => rotateImage({ id: currentView.id, degree: -90 })} className="mr-24p" />
                <DeleteIcon
                  onClick={() => {
                    patchItems({
                      id: itemRef,
                      ...fetchData,
                      data: { images: itemToRender.images.filter(e => e.id !== currentView.id) },
                    });

                    setModalIsOpen(false);
                  }}
                />
              </>
          }
        </div>
      </div>
    )
  };

  const handleSearchElements = (url, parentCategory) => searchQuery => {
    const parentCategoryId = parentCategory && parentCategory.id ? parentCategory.id : parentCategory;

    const query = new URLSearchParams();
    if (searchQuery) query.set("query", searchQuery);
    query.set("parent", parentCategoryId ? parentCategoryId : null);
    query.set("linked", false);
    query.set("is_visible", true);

    return customFetch({
      url: `${url}/?${query.toString()}`,
      method: "GET"
    })
  };

  const handleFocus = (parentCategory, url) => {
    const parentCategoryId = parentCategory && parentCategory.id ? parentCategory.id : parentCategory;

    const query = new URLSearchParams();
    if (parentCategoryId) query.set("parent", parentCategoryId);

    return customFetch({
      url: `${url}?${query.toString()}`,
      method: "GET"
    })
  };

  const handleClearSubcategory = fieldToClear => {
    methods.setValue(fieldToClear, []);
  };

  const handlePatchItem = (name, value) => {
    let dataToSend = {};
    const splittedName = name.split(".");
    const formValues = methods.getValues();

    if (splittedName[0] === "catalogsElements") {
      dataToSend = {
        catalogsElements: [
          ...methods.watch("catalogsElements.Origin.items").map(el => typeof el === "object" ? el.id : el),
          ...methods.watch("catalogsElements.Material.items").map(el => typeof el === "object" ? el.id : el),
        ]
      };
    } else if (name.includes("Category")) {
      dataToSend.categories = formCategoriesToSend(formValues);
    } else dataToSend[name] = value;

    patchItems({ id: itemRef, data: dataToSend, query: queryStatus, isRefetch: false });
  };

  return (
    <Spin size="large" spinning={itemToRenderProcessing}>
      <div className={`items--list__content ${open ? "open" : ""}`}>
        <Row gutter={16}>
          <Col span={12} className="items--list__content__left">
            <div className="item">
              {renderDataField("title", itemToRender.title, true)}
            </div>
            <Row className="images item" gutter={40}>
              <Col span={12}>
                <label>Main image</label>
                {mainImage.url ? (
                  <div
                    className="thumbnail zoom-in"
                    onClick={() => toggleLightbox(images.findIndex(e => e.id === mainImage.id))}
                  >
                    <ZoomInIcon />
                    <img src={mainImage.url} alt="thumbnail" />
                  </div>
                ) : (
                  <Avatar style={{ backgroundColor: "#ccc" }} className="thumbnail empty-image" shape="square">
                    none
                  </Avatar>
                )}
              </Col>
              {!!itemToRender.images?.length && (
                <Col span={12}>
                  <label>Images</label>
                  <div className="images-list">
                    {renderImages()}
                  </div>
                </Col>
              )}
              {!!videoUrl && (
                <Col span={24}>
                  <label>Video</label>
                  <ReactPlayer
                    url={videoUrl}
                    controls
                    width="100%"
                    height="250px"
                  />
                </Col>
              )}
            </Row>
            <Collapse
              expandIconPosition="right"
              bordered={false}
              defaultActiveKey={[1, 2, 3, 4, 6]}
            >
              <Collapse.Panel key="1" header="Description">
                {renderDataField("description", itemToRender.description)}
              </Collapse.Panel>
              <Collapse.Panel key="4" header="Measurements">
                {renderMeasurementsSection(itemToRender.measurements)}
              </Collapse.Panel>
              <Collapse.Panel key="3" header="Delivery">
                {renderDataField("deliveryInfo", itemDeliveryInfo)}
              </Collapse.Panel>
              <Collapse.Panel key="2" header="Condition">
                {renderDataField("condition", itemToRender.condition)}
              </Collapse.Panel>
              <Collapse.Panel key="6" header="Date Mfg/Created">
                {renderDateMfgField()}
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col span={12} className="items--list__content__right">

            <TriggerPatchProvider patchHandler={handlePatchItem}>
              <FormProvider {...methods}>
                <Collapse
                  expandIconPosition="right"
                  bordered={false}
                  defaultActiveKey={[1, 2, 3, 4, 5]}
                  className="additional-carousel"
                >
                  <Collapse.Panel key="1" header="Categories">
                    <div className="additional-carousel--categories">
                      <SharedSelectForForm
                        label="Main category #1"
                        className="additional-carousel--categories--select"
                        placeholder="Start typing name"
                        handleSearch={handleSearchElements("/categories/search")}
                        isShowSearch={true}
                        name="mainCategory1"
                        isMultiple={false}
                        isTwoInRow={true}
                        handleClear={() => handleClearSubcategory("subCategory1")}
                        handleFocus={() => handleFocus(null, "/categories/optimized/")}
                        shouldFocus={true}
                        setUserInteracted={setUserInteracted}
                        userInteracted={userInteracted}
                      />

                      <SharedSelectForForm
                        label="Sub categories #1"
                        className="additional-carousel--categories--select"
                        placeholder="Select main category first"
                        handleSearch={handleSearchElements("/categories/search", methods.watch("mainCategory1"))}
                        isShowSearch={true}
                        name="subCategory1"
                        isMultiple={true}
                        isDisabled={!methods.watch("mainCategory1")}
                        isTwoInRow={true}
                        handleFocus={() => handleFocus(methods.watch("mainCategory1"), "/categories/optimized/")}
                        shouldFocus={true}
                        userInteracted={userInteracted}
                        parentCategoryName="mainCategory1"
                      />
                    </div>
                    <div className="additional-carousel--categories">
                      <SharedSelectForForm
                        label="Main category #2"
                        className="additional-carousel--categories--select"
                        placeholder="Start typing name"
                        handleSearch={handleSearchElements("/categories/search")}
                        isShowSearch={true}
                        name="mainCategory2"
                        isMultiple={false}
                        isTwoInRow={true}
                        handleClear={() => handleClearSubcategory("subCategory2")}
                        handleFocus={() => handleFocus(null, "/categories/optimized/")}
                        shouldFocus={true}
                        setUserInteracted={setUserInteracted}
                        userInteracted={userInteracted}
                      />

                      <SharedSelectForForm
                        label="Sub categories #2"
                        className="additional-carousel--categories--select"
                        placeholder="Select main category first"
                        handleSearch={handleSearchElements("/categories/search", methods.watch("mainCategory2"))}
                        isShowSearch={true}
                        name="subCategory2"
                        isMultiple={true}
                        isDisabled={!methods.watch("mainCategory2")}
                        isTwoInRow={true}
                        handleFocus={() => handleFocus(methods.watch("mainCategory2"), "/categories/optimized/")}
                        shouldFocus={true}
                        userInteracted={userInteracted}
                        parentCategoryName="mainCategory2"
                      />
                    </div>
                    <div className="additional-carousel--categories">
                      <SharedSelectForForm
                        label="Main category #3"
                        className="additional-carousel--categories--select"
                        placeholder="Start typing name"
                        handleSearch={handleSearchElements("/categories/search")}
                        isShowSearch={true}
                        name="mainCategory3"
                        isMultiple={false}
                        isTwoInRow={true}
                        handleClear={() => handleClearSubcategory("subCategory3")}
                        handleFocus={() => handleFocus(null, "/categories/optimized/")}
                        shouldFocus={true}
                        setUserInteracted={setUserInteracted}
                        userInteracted={userInteracted}
                      />

                      <SharedSelectForForm
                        label="Sub categories #3"
                        className="additional-carousel--categories--select"
                        placeholder="Select main category first"
                        handleSearch={handleSearchElements("/categories/search", methods.watch("mainCategory3"))}
                        isShowSearch={true}
                        name="subCategory3"
                        isMultiple={true}
                        isDisabled={!methods.watch("mainCategory3")}
                        isTwoInRow={true}
                        handleFocus={() => handleFocus(methods.watch("mainCategory3"), "/categories/optimized/")}
                        shouldFocus={true}
                        userInteracted={userInteracted}
                        parentCategoryName="mainCategory3"
                      />
                    </div>

                    <Collapse
                      expandIconPosition="right"
                      bordered={false}
                      onChange={() => setIsAdditionalCategoriesCollapsed(!isAdditionalCategoriesCollapsed)}
                      className="additional-categories-carousel"
                    >
                      <Collapse.Panel
                        key="7"
                        header={`${isAdditionalCategoriesCollapsed ? "Less" : "More"} categories`}
                      >
                        <div className="additional-carousel--categories">
                          <SharedSelectForForm
                            label="Main category #4"
                            className="additional-carousel--categories--select"
                            placeholder="Start typing name"
                            handleSearch={handleSearchElements("/categories/search")}
                            isShowSearch={true}
                            name="mainCategory4"
                            isMultiple={false}
                            isTwoInRow={true}
                            handleClear={() => handleClearSubcategory("subCategory4")}
                            handleFocus={() => handleFocus(null, "/categories/optimized/")}
                            shouldFocus={true}
                            setUserInteracted={setUserInteracted}
                            userInteracted={userInteracted}
                          />

                          <SharedSelectForForm
                            label="Sub categories #4"
                            className="additional-carousel--categories--select"
                            placeholder="Select main category first"
                            handleSearch={handleSearchElements("/categories/search", methods.watch("mainCategory4"))}
                            isShowSearch={true}
                            name="subCategory4"
                            isMultiple={true}
                            isDisabled={!methods.watch("mainCategory4")}
                            isTwoInRow={true}
                            handleFocus={() => handleFocus(methods.watch("mainCategory4"), "/categories/optimized/")}
                            shouldFocus={true}
                            userInteracted={userInteracted}
                            parentCategoryName="mainCategory4"
                          />
                        </div>
                        <div className="additional-carousel--categories">
                          <SharedSelectForForm
                            label="Main category #5"
                            className="additional-carousel--categories--select"
                            placeholder="Start typing name"
                            handleSearch={handleSearchElements("/categories/search")}
                            isShowSearch={true}
                            name="mainCategory5"
                            isMultiple={false}
                            isTwoInRow={true}
                            handleClear={() => handleClearSubcategory("subCategory5")}
                            handleFocus={() => handleFocus(null, "/categories/optimized/")}
                            shouldFocus={true}
                            setUserInteracted={setUserInteracted}
                            userInteracted={userInteracted}
                          />

                          <SharedSelectForForm
                            label="Sub categories #5"
                            className="additional-carousel--categories--select"
                            placeholder="Select main category first"
                            handleSearch={handleSearchElements("/categories/search", methods.watch("mainCategory5"))}
                            isShowSearch={true}
                            name="subCategory5"
                            isMultiple={true}
                            isDisabled={!methods.watch("mainCategory5")}
                            isTwoInRow={true}
                            handleFocus={() => handleFocus(methods.watch("mainCategory5"), "/categories/optimized/")}
                            shouldFocus={true}
                            userInteracted={userInteracted}
                            parentCategoryName="mainCategory5"
                          />
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </Collapse.Panel>
                  <Collapse.Panel key="2" header="Period">
                    <SharedSelectForForm
                      className="additional-carousel--select"
                      placeholder="No Assigned"
                      handleSearch={handleSearchElements("/periods/search")}
                      isShowSearch={true}
                      name="periods"
                      isMultiple={true}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel key="3" header="Material">
                    <SharedSelectForForm
                      className="additional-carousel--select"
                      placeholder="No Assigned"
                      handleSearch={handleSearchElements("/catalogs/material/elements/search")}
                      isShowSearch={true}
                      name="catalogsElements.Material.items"
                      isMultiple={true}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel key="4" header="Origin">
                    <SharedSelectForForm
                      className="additional-carousel--select"
                      placeholder="No Assigned"
                      handleSearch={handleSearchElements("/catalogs/origin/elements/search")}
                      isShowSearch={true}
                      name="catalogsElements.Origin.items"
                      isMultiple={true}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel key="5" header="Artisan">
                    <SharedSelectForForm
                      className="additional-carousel--select"
                      placeholder="No Assigned"
                      handleSearch={handleSearchElements("/artisans/search")}
                      isShowSearch={true}
                      name="artisans"
                      isMultiple={true}
                      key={JSON.stringify(itemToRender.artisans || [])}
                      optionLabelProp="label"
                      labelValueForOption="title"
                    />
                    <Button onClick={() => setCreateArtisanModalIsOpen(true)}>
                      <PlusCircleOutlined />
                    </Button>
                    <CreateArtisanModal
                      visible={createArtisanModalIsOpen}
                      handleClose={(e) => {
                        if (e) {
                          const itemArtisansList = methods.watch("artisans") || [];

                          const itemArtisans = itemArtisansList.map(item => item.id).filter(item => !!item);
                          handlePatchItem("artisans", [...itemArtisans, e.id]);

                          const artisansToSet = [];

                          itemArtisansList.forEach(item => {
                            if (typeof item === "number") {
                              const fullArtisanData = itemToRender.artisans.find(artisan => artisan.id === item);

                              if (fullArtisanData) {
                                artisansToSet.push(fullArtisanData);
                              }
                            } else {
                              artisansToSet.push(item);
                            }
                          });
                          methods.reset({
                            ...itemToRender,
                            artisans: [...artisansToSet, e],
                          });
                        }

                        setCreateArtisanModalIsOpen(false)
                      }}
                    />
                  </Collapse.Panel>
                </Collapse>
              </FormProvider>
            </TriggerPatchProvider>
            <div className="additional-carousel--switch">
              <Tooltip placement="bottom" title={HIDE_SHOW_ON_HOME_PAGE_TOOLTIP}>
                <Switch
                  onChange={(value, event) => {
                    showOnHomePage(event, value, itemToRender ? itemToRender.ref : "")
                  }}
                  defaultChecked={showInHomePage}
                />
              </Tooltip>
            </div>
          </Col>
        </Row>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal allowFullscreen={false} onClose={toggleLightbox}>
              <Carousel
                views={images}
                currentIndex={currentIndex}
                components={{
                  Footer: customCarouselFooter
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {
          catalogModalIsOpen && <ModalWindow
            className="modal-block"
            centered
            visible
            onCancel={() => setCatalogModalIsOpen(false)}
            footer={null}
            title={
              <ModalHeader
                modalTabChanged={modalTabChanged}
                categories={activeModalItem.dependencies}
                activeKeys={activeModalItem.key || []}
                setSearch={setSearchCategoryModal}
              />
            }
          >
            <AddEntity
              activeGroup={activeModalItemUrl}
              search={searchCategoryModal}
              setCategory={dependency => setActiveModalItem({
                key: activeModalItem.key,
                dependencies: { ...activeModalItem.dependencies, [activeModalItem.key]: dependency }
              })}
              activeTab={activeTabOfModal}
              dependenciesOfSelectedTab={itemDependencies[activeTabOfModal].items}
              onSave={saveChangesAndCloseModal}
              onClose={setCatalogModalIsOpen}
            />
          </ModalWindow>
        }
      </div>
    </Spin>
  )
}

ItemListContent.propTypes = {
  open: PropTypes.bool.isRequired,
  mainImage: PropTypes.object,
  currentView: PropTypes.object,
  patchItems: PropTypes.func.isRequired,
  rotateImage: PropTypes.func.isRequired,
  fetchData: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.any,
  itemRef: PropTypes.string.isRequired,
  queryStatus: PropTypes.object,
  showOnHomePage: PropTypes.func.isRequired,
  itemCreationDate: PropTypes.string.isRequired,
  showInHomePage: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function AddButton({ className, onClick }) {
  return (
    <div className={classNames("add-button", className)} onClick={onClick}>
      <img src="/images/add_3x.svg" alt="" />
      <span className="add-button--text">ADD</span>
    </div>
  );
}

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default AddButton

import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";


function TabNavigation({ items, location }) {
  return (
    <div className="tab-navigation">
      <Menu mode="horizontal" selectedKeys={[location.pathname]}>
        {
          items.map(item => {
            const { link, label, icon: Icon } = item;

            return (
              <Menu.Item key={link} className="tab-navigation--item">
                {Icon && (
                  <Icon
                    className="tab-navigation--icon"
                    fillColor={location.pathname === link ? "#FF0000" : "#717171"}
                  />
                )}
                <Link to={link}>{label}</Link>
              </Menu.Item>
            )
          })
        }
      </Menu>
    </div>
  )
}

TabNavigation.propTypes = {
  items: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(TabNavigation);

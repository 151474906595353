import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import merge from "lodash/merge";
import { Spin, message, DatePicker } from "antd";
import { saveAs } from "file-saver";

// components
import SearchInput from "../../shared/SearchInput";
import LabeledInput from "../../shared/LabeledInput";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";

// redux
import { getDealersRequest } from "../../../redux/dealers/dealersActions";
import {
  getInvoiceListRequest,
  deleteInvoiceRequest,
} from "../../../redux/invoices/invoicelist/invoiceListActions";

// helpers
import invoicesAPI from "../../../api/invoices";
import navigation from "../../../helpers/navigation";
import { Utils } from "../../../helpers";

function InvoiceList({
  location,
  history,
  loading,
  dealersData,
  getDealers,
  getInvoiceList,
  items,
  totalItems,
  error,
  deleteInvoice,
  userRole,
}) {
  const [query, setQuery] = useState({});
  const [dateRange, setDateRange] = useState(null);
  const { order, search, page, perPage } =
    navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  const getNewInvoicesList = (newQuery) => {
    if (page !== 1) {
      navigation.setPage(1, location, history);
    } else {
      getInvoiceList({
        page: 1,
        perPage,
        search,
        query: Utils.formatFilters(newQuery),
      });
    }
  }
  const handleSortItems = (value, option, key) => {
    const newQuery = merge(query, {
      [key]: {
        label: option.props.children,
        value,
      },
    });

    setQuery(newQuery);

    // validation for filter change on first page
    getNewInvoicesList(newQuery);
  };

  useEffect(() => {
    getDealers({ page: 1, perPage: 200 });
  }, []); // eslint-disable-line

  useEffect(() => {
    getInvoiceList({
      page,
      perPage,
      search,
      query: Utils.formatFilters(query),
    });
  }, [page, perPage, search]); // eslint-disable-line

  useEffect(() => {
    if (dateRange) {
      if (dateRange[0] && dateRange[1]) {
        const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
        const endDate = moment(dateRange[1]).format("YYYY-MM-DD");

        handleSortItems(
          `${startDate} ${endDate}`,
          {
            props: {
              children: `${startDate} - ${endDate}`,
            },
          },
          "date"
        );
      }
    }
  }, [dateRange]); // eslint-disable-line

  useEffect(() => {
    if (error && page >= Math.ceil(totalItems / perPage)) {
      navigation.setPage(
        Math.ceil((totalItems - 1) / perPage) || 1,
        location,
        history
      );
    }
  }, [error]); // eslint-disable-line

  const downloadInvoice = (id, number) => {
    invoicesAPI
      .getInvoicePDF(id)
      .then((data) => saveAs(data, `Invoice_${number}.pdf`))
      .catch((e) => message.error(e.data || "Something went wrong!"));
  };

  const invoicesToRender = Utils.filterAndOrder(items, { order });

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <div className="component invoice-list">
          <div className="component--header">
            <div className="component--header-section">
              <LabeledInput
                className="status mr-16p"
                label="Status"
                type="select"
                options={[
                  {
                    label: "All",
                    value: "",
                  },
                  {
                    label: "Active",
                    value: "active",
                  },
                  {
                    label: "Inactive",
                    value: "inactive",
                  },
                ]}
                value={(query.status && query.status.label) || "All"}
                placeholder="By Status"
                onChange={(value, option) => handleSortItems(value, option, "status")
                }
                horizontal
              />
              <LabeledInput
                className="mr-16p"
                label="Dealer"
                type="select"
                value={(query.dealer && query.dealer.label) || "All"}
                options={dealersData.dealers.map((dealer) => ({
                  label: `${dealer.firstName} ${dealer.lastName}`,
                  value: dealer.id,
                }))}
                onChange={(value, option) => handleSortItems(value, option, "dealer")
                }
                placeholder="By Dealer"
                horizontal
              />
              <DatePicker.RangePicker
                allowClear={false}
                className="mr-16p w-100"
                value={
                  dateRange && dateRange.length === 2
                    ? [moment(dateRange[0]), moment(dateRange[1])]
                    : null
                }
                ranges={{
                  "Last 14 days": [moment().subtract(14, "d"), moment()],
                  "Last 30 days": [moment().subtract(30, "d"), moment()],
                  "Last month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={(value) => {
                  if (value) {
                    const [newFromDate, newToDate] = value;

                    setDateRange([
                      newFromDate.format("YYYY-MM-DD"),
                      newToDate.format("YYYY-MM-DD"),
                    ]);

                    return;
                  }

                  setDateRange([]);
                }}
              />
            </div>

            <SearchInput
              value={search}
              onChange={(value) => navigation.setDynamicKeys(
                { page: 1, search: value },
                location,
                history
              )
              }
            />
          </div>

          <div className="flex invoice-list--content">
            <Table
              columnWidths={[
                "86px",
                "15%",
                "13%",
                "8%",
                "12%",
                "10%",
                "8%",
                "8%",
                "auto",
                "110px",
              ]}
              headerCells={[
                { label: "status" },
                { label: "dealer" },
                { label: "invoice number" },
                { label: "method" },
                { label: "invoice date" },
                { label: "due date" },
                { label: "total" },
                { label: "id" },
                { label: "actions" },
              ]}
            >
              {invoicesToRender.map((invoice) => {
                const invoiceStatus = invoice.status?.toLowerCase() || "";

                return (
                  <TableRow key={`invoice-${invoice.id}`}>
                    <div className="flex">
                      <div className="status-panel disabled">
                        <div
                          className={`status-panel--item text-capitalize shorter ${invoiceStatus}`}
                        >
                          {invoiceStatus}
                        </div>
                      </div>
                    </div>
                    <div className="flex dealer-column">
                      {Utils.emptyFieldFormat(
                        `${invoice.dealer.firstName} ${invoice.dealer.lastName}`,
                        "-"
                      )}
                    </div>
                    <div className="flex">
                      {Utils.emptyFieldFormat(invoice.invoiceNumber, "-")}
                    </div>
                    <div className="flex">
                      {Utils.emptyFieldFormat(invoice.method, "-")}
                    </div>
                    <div className="flex">
                      {invoice.invoiceDate
                        ? moment(invoice.invoiceDate).format("DD/MM/YYYY")
                        : "-"}
                    </div>
                    <div className="flex">
                      {invoice.dueDate
                        ? moment(invoice.dueDate).format("DD/MM/YYYY")
                        : "-"}
                    </div>
                    <div className="flex total-column">£{invoice.total}</div>
                    <div className="flex">
                      {Utils.emptyFieldFormat(invoice.id, "-")}
                    </div>
                    <div className="flex actions-column">
                      {!isDisabled && (
                        <React.Fragment>
                          <img
                            className="groups--table-icon"
                            src="/images/download@3x.svg"
                            alt=""
                            width="24"
                            onClick={() => downloadInvoice(invoice.id, invoice.invoiceNumber)
                            }
                          />
                          <img
                            className="groups--table-icon"
                            src="/images/delete_3x.svg"
                            alt=""
                            width="16"
                            onClick={() => deleteInvoice({
                              id: invoice.id,
                              page,
                              perPage,
                              search,
                              query: Utils.formatFilters(query),
                            })
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </TableRow>
                );
              })}

              {totalItems && (
                <TableFooter
                  key="invoice-list-footer-table"
                  page={page}
                  perPage={perPage}
                  total={totalItems}
                />
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
}

InvoiceList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dealersData: PropTypes.object.isRequired,
  error: PropTypes.any,
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  getDealers: PropTypes.func.isRequired,
  getInvoiceList: PropTypes.func.isRequired,
  deleteInvoice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({
    ...state.invoiceList,
    dealersData: state.dealers,
    userRole: state.auth.userProfile.roles,
  }),
  (dispatch) => ({
    getDealers: (payload) => dispatch(getDealersRequest(payload)),
    getInvoiceList: (payload) => dispatch(getInvoiceListRequest(payload)),
    deleteInvoice: (payload) => dispatch(deleteInvoiceRequest(payload)),
  })
)(InvoiceList);

import React from "react";
import PropTypes from "prop-types";

const WebsiteBuilderIcon = ({ fillColor = "#FFFFFF", className = "" }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>62F8D984-F5CE-4CBC-B9C2-B83B038FEF3C@3x</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="WebsiteBuilder_DealerWebsite" transform="translate(-16.000000, -352.000000)">
          <g id="Website" transform="translate(16.000000, 352.000000)" fill={fillColor}>
            <path d="M4,16 L20,16 L20,5 L4,5 L4,16 Z M13,18 L13,20 L17,20 L17,22 L7,22 L7,20 L11,20 L11,18 L2.992,18 C2.72678869,17.9984163 2.47311496,17.8913333 2.28699136,17.7023953 C2.10086777,17.5134573 1.99760354,17.2582052 1.9999592,16.993 L1.9999592,4.007 C1.9999592,3.451 2.455,3 2.992,3 L21.008,3 C21.556,3 22,3.449 22,4.007 L22,16.993 C22,17.549 21.545,18 21.008,18 L13,18 L13,18 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

WebsiteBuilderIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default WebsiteBuilderIcon;
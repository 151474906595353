import * as types from "./blogActionTypes";


const initialState = {
  articles: [],
  articlesTotal: 0,
  categories: [],
  categoriesTotal: 0,
  authors: [],
  authorsTotal: 0,
  lastUploadedImage: null,
  error: "",
  processing: false,
  articleExtendedData: {},
};


const blogs = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BLOG_ARTICLES_REQUEST_SUCCESS:
      return {
        ...state,
        articlesTotal: action.payload.count,
        articles: action.payload.results,
        lastUploadedImage: null,
      };

    case types.GET_BLOG_ARTICLES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.GET_BLOG_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        categories: action.payload.results,
        categoriesTotal: action.payload.count
      };

    case types.GET_BLOG_CATEGORIES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.GET_BLOG_AUTHORS_REQUEST_SUCCESS:
      return {
        ...state,
        authors: action.payload.results,
        authorsTotal: action.payload.count
      };

    case types.GET_BLOG_AUTHORS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.CREATE_NEW_ARTICLE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.CREATE_NEW_ARTICLE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.UPLOAD_NEW_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        lastUploadedImage: action.payload.response
      };

    case types.UPLOAD_NEW_IMAGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.CREATE_NEW_AUTHOR_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.CREATE_NEW_AUTHOR_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.CREATE_NEW_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.CREATE_NEW_CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.GET_BLOG_ARTICLE_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        articleExtendedData: action.payload,
        error: null
      };

    case types.GET_BLOG_ARTICLE_DATA_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.TOGGLE_ARTICLE_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      }

    case types.TOGGLE_ADD_AUTHOR_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      }

    case types.UPDATE_SHOW_BLOG_STATUS_ERROR:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state;
  }
};

export default blogs;

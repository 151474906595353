import * as types from "./currencyRatesActionsTypes";


const initialState = {
  items: [],
  total: 0,
  loading: false,
  processing: false,
  error: "",
};


const currencyRates = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };


    case types.GET_CURRENCY_RATES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count
      };

    case types.GET_CURRENCY_RATES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default currencyRates;

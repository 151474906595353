import React from "react";
import PropTypes from "prop-types";


function AddIcon({ fillColor = "#ffffff", className = "", onClick }) {
  return (
    <svg className={className} onClick={onClick} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="icon/24/add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Path" fill={fillColor} points="11 11 11 5 13 5 13 11 19 11 19 13 13 13 13 19 11 19 11 13 5 13 5 11" />
      </g>
    </svg>

  )
}

AddIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default AddIcon;

import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const PatchContext = createContext({});

export const TriggerPatchProvider = ({ patchHandler, children }) => {
  return (
    <PatchContext.Provider value={{ patchHandler: patchHandler }}>
      {children}
    </PatchContext.Provider>
  )
}

TriggerPatchProvider.propTypes = {
  patchHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export const useTriggerPatch = () => useContext(PatchContext).patchHandler;

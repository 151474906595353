import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

//components
import TableFooter from "../../shared/Table/TableFooter";
import LabeledInput from "../../shared/LabeledInput";
import AsyncSelect from "../../shared/AsyncSelect";
import Table from "../../shared/Table/Table";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import SeoCategoriesRow from "./SeoCategoriesRow";
import SearchInput from "../../shared/SearchInput";

//helpers
import navigation from "../../../helpers/navigation";
import { getSeoRequest, patchSeoRequest } from "../../../redux/seo/seoActions";
import { Utils } from "../../../helpers";
import periods from "../../../api/periods";
import content from "../../../api/content";


function SeoCategories({ items, total, getSeo, patchSeo, location, history }) {
  const [filterOptions, setFilterOptions] = useState({
    period: "",
    material: "",
    origin: ""
  });

  const {
    search,
    filter,
    page = 1,
    perPage,
    order,
    period,
    material,
    origin,
    title
  } = navigation.getTableQueryParams(location);

  // Pagination/search api call here
  useEffect(() => {
    getSeo({ page, perPage, status: search, category: filter, origin, period, material, title });
  }, [page, perPage, search, filter, material, origin, period, title]); // eslint-disable-line

  useEffect(() => {
    navigation.setDynamicKeys({ page: 1, ...filterOptions }, location, history);
  }, [filterOptions]); // eslint-disable-line


  // Methods
  const onStatusFilter = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const onSearchFilter = useCallback(debounce(
    value => navigation.setDynamicKeys({ page: 1, title: value }, location, history
    ), 150), [])

  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  const handleCategoryStatusChange = (id, data) => {
    patchSeo(id, data, () => {
      getSeo({ page, perPage, status: search, category: filter, origin, period, material, title });
    });
  };

  return (
    <div className="seo-cat">
      <TableAndFormLayout showForm={false}>
        <TableAndFormLayout.Header>
          <div className="seo-cat__header">
            <div className="seo-cat__filer-row">
              <LabeledInput
                label="Status"
                type="select"
                value={search}
                options={[{
                  label: "All",
                  value: "",
                }, {
                  label: "Ok",
                  value: "APPROVED"
                }, {
                  label: "Suspended",
                  value: "SUSPENDED"
                }, {
                  label: "Ignore",
                  value: "IGNORE"
                }]}
                onChange={onStatusFilter}
                horizontal
              />
              <AsyncSelect
                getOptions={periods.search}
                selected={filterOptions.period || "All"}
                defaultValue="All"
                setSelected={value => setFilterOptions({ ...filterOptions, period: value })}
                label="Periods"
              />
              <AsyncSelect
                getOptions={content.searchByOrigin}
                selected={filterOptions.origin || "All"}
                defaultValue="All"
                label="Origins"
                setSelected={value => setFilterOptions({ ...filterOptions, origin: value })}
              />
              <AsyncSelect
                getOptions={content.searchByMaterial}
                selected={filterOptions.material || "All"}
                defaultValue="All"
                setSelected={value => setFilterOptions({ ...filterOptions, material: value })}
                label="Materials"
              />
            </div>
            <div className="seo-cat__search-box">
              <span className="seo-cat__search-label">Filter by name:</span>
              <SearchInput value={title} onChange={onSearchFilter} />
            </div>
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["100px", "30%", "150px", "auto"]}
            headerCells={[
              { label: "Status" },
              { label: "Name" },
              { label: "Available" },
              { label: "Details" },
            ]}
          >
            {({ columnWidths }) => {
              return itemsToRender.map(item => {
                return (
                  <SeoCategoriesRow
                    key={`group:${item.id}`}
                    columnWidths={columnWidths}
                    item={item}
                    patchItem={handleCategoryStatusChange}
                  />
                );
              })
            }}

            {
              total &&
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={total}
              />
            }
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
}

SeoCategories.propTypes = {
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  getSeo: PropTypes.func.isRequired,
  patchSeo: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => state.seo,
  dispatch => ({
    getSeo: params => dispatch(getSeoRequest(params)),
    patchSeo: (id, data, callback) => dispatch(patchSeoRequest(id, data, callback)),
  })
)(SeoCategories);

import * as types from "./authActionsTypes";


export const loginRequest = data => {
  return { type: types.LOGIN_REQUEST, data };
};

export const userProfileRequest = () => {
  return { type: types.GET_USER_PROFILE_REQUEST };
};

export const forgotPasswordRequest = data => {
  return { type: types.FORGOT_PASSWORD_REQUEST, data };
};

export const resetPasswordRequest = (data, cb) => {
  return { type: types.RESET_PASSWORD_REQUEST, data, cb };
};

export const logoutRequest = () => {
  return { type: types.LOGOUT_REQUEST };
};

export const clearError = () => {
  return { type: types.CLEAR_ERROR };
};

import * as types from "./authActionsTypes";


const token = JSON.parse(localStorage.getItem("token") || "{}");

export const initialState = {
  isAuthenticated: !!token.access,
  processing: false,
  token: token.access || "",
  error: "",
  userProfile: {
    firstName: "",
    lastName: "",
    roles: []
  }
};


const auth = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        processing: true,
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        processing: false,
      };

    case types.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        token: action.response.access || "",
        isAuthenticated: true,
        error: "",
      };

    case types.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload
      };

    case types.LOGOUT_REQUEST_SUCCESS:
      return {
        ...initialState,
        isAuthenticated: false,
        token: "",
      };

    case types.LOGIN_REQUEST_ERROR:
    case types.LOGOUT_REQUEST_ERROR:
    case types.FORGOT_PASSWORD_REQUEST_ERROR:
    case types.RESET_PASSWORD_REQUEST_ERROR:
    case types.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: ""
      };

    default:
      return state;
  }
};

export default auth;

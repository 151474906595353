import { all, put, call, takeLatest } from "redux-saga/effects";

import dashboard from "../../api/dashboard";
import * as types from "./dashboardActionTypes";


export function *getUsers () {
  try {
    const response = yield call(dashboard.getUsers);
    yield put({ type: types.GET_USERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_USERS_REQUEST_ERROR, payload: error.message });
  }
}

export function *handleElasticSearch (action) {
  try {
    yield put({ type: types.TOGGLE_ELASTIC_SEARCH_PROCESSING });
    const response = yield call(dashboard.handleElasticSearch, action.payload);
    yield put({ type: types.ELASTIC_SEARCH_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.ELASTIC_SEARCH_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_ELASTIC_SEARCH_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_USERS_REQUEST, getUsers),
    yield takeLatest(types.ELASTIC_SEARCH_REQUEST, handleElasticSearch),
  ])
}

import * as types from "./invoiceListActionsTypes";

const initialState = {
  loading: false,
  items: [],
  totalItems: 0,
  error: null,
  processing: false,
  invoicePDF: {},
};

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        totalItems: action.payload.count
      };

    case types.TOGGLE_INVOICES_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case types.TOGGLE_INVOICES_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      };

    case types.GET_INVOICE_LIST_ERROR:
    case types.DELETE_INVOICE_ERROR:
    case types.GET_INVOICE_PDF_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.GET_INVOICE_PDF_REQUEST_SUCCESS:
      return {
        ...state,
        invoicePDF: action.payload,
      };

    default:
      return state;
  }
};

export default invoices;

import * as types from "./emailProfilesActionsTypes";

const initialState = {
  items: [],
  totalNumber: 0,
  errors: {},
  processing: false,
};

const emailProfiles = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EMAIL_PROFILES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        totalNumber: action.payload.count
      };

    case types.GET_EMAIL_PROFILES_REQUEST_ERROR:
    case types.CREATE_EMAIL_PROFILE_REQUEST_ERROR:
      return {
        ...state,
        errors: action.payload
      };

    case types.CREATE_EMAIL_PROFILE_REQUEST_SUCCESS:
    case types.RESET_EMAIL_PROFILES_ERRORS:
      return {
        ...state,
        errors: {}
      };

    case types.SHOW_EMAIL_PROFILES_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_EMAIL_PROFILES_PROCESSING:
      return {
        ...state,
        processing: false
      };

    default:
      return state;
  }
};

export default emailProfiles;

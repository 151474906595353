import { all, put, call, takeLatest } from "redux-saga/effects";

import catalog from "../../api/catalog";
import * as types from "./groupsActionsTypes";
import { checkError } from "../helpers";


export function *get () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(catalog.get);
    yield put({ type: types.GET_GROUPS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield checkError(error);
    yield put({ type: types.GET_GROUPS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *create ({ data }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(catalog.create, data);
    yield put({ type: types.GET_GROUPS_REQUEST });
  } catch (error) {
    yield put({ type: types.CREATE_GROUP_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *update ({ data, url, isPatch }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    if (isPatch) {
      yield call(catalog.updatePatch, url, data);
    } else {
      yield call(catalog.updatePut, url, data);
    }

    yield put({ type: types.GET_GROUPS_REQUEST });
  } catch (error) {
    yield put({ type: types.CREATE_GROUP_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *remove ({ url }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(catalog.delete, url);
    yield put({ type: types.GET_GROUPS_REQUEST });
  } catch (error) {
    yield put({ type: types.CREATE_GROUP_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_GROUPS_REQUEST, get),
    yield takeLatest(types.CREATE_GROUP_REQUEST, create),
    yield takeLatest(types.UPDATE_GROUP_REQUEST, update),
    yield takeLatest(types.DELETE_GROUP_REQUEST, remove),
  ])
}

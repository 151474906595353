import React from "react";
import { Modal } from "antd";
import PropsTypes from "prop-types";

// components
import CreateArtisanForm from "./CreateArtisanForm";


function CreateArtisanModal({ visible = false, handleClose }) {
  return (
    <Modal
      title="Create Artisan"
      visible={visible}
      footer={null}
      onCancel={() => handleClose()}
    >
      {visible && <CreateArtisanForm visible handleClose={handleClose} />}
    </Modal>
  );
}

CreateArtisanModal.propTypes = {
  visible: PropsTypes.bool.isRequired,
  handleClose: PropsTypes.func.isRequired,
};

export default CreateArtisanModal;

export const GET_BLOG_ARTICLES_REQUEST = "GET_BLOG_ARTICLES_REQUEST";
export const GET_BLOG_ARTICLES_REQUEST_SUCCESS = "GET_BLOG_ARTICLES_REQUEST_SUCCESS";
export const GET_BLOG_ARTICLES_REQUEST_ERROR = "GET_BLOG_ARTICLES_REQUEST_ERROR";

export const GET_BLOG_CATEGORIES_REQUEST = "GET_BLOG_CATEGORIES_REQUEST";
export const GET_BLOG_CATEGORIES_REQUEST_SUCCESS = "GET_BLOG_CATEGORIES_REQUEST_SUCCESS";
export const GET_BLOG_CATEGORIES_REQUEST_ERROR = "GET_BLOG_CATEGORIES_REQUEST_ERROR";

export const GET_BLOG_AUTHORS_REQUEST = "GET_BLOG_AUTHORS_REQUEST";
export const GET_BLOG_AUTHORS_REQUEST_SUCCESS = "GET_BLOG_AUTHORS_REQUEST_SUCCESS";
export const GET_BLOG_AUTHORS_REQUEST_ERROR = "GET_BLOG_AUTHORS_REQUEST_ERROR";

export const PATCH_BLOG_ARTICLE_REQUEST = "PATCH_BLOG_ARTICLE_REQUEST";
export const PATCH_BLOG_ARTICLE_REQUEST_SUCCESS = "PATCH_BLOG_ARTICLE_REQUEST_SUCCESS";
export const PATCH_BLOG_ARTICLE_REQUEST_ERROR = "PATCH_BLOG_ARTICLE_REQUEST_ERROR";

export const DELETE_BLOG_ARTICLE_REQUEST = "DELETE_ARTICLE_REQUEST";
export const DELETE_BLOG_ARTICLE_REQUEST_ERROR = "DELETE_ARTICLE_REQUEST_ERROR";

export const PATCH_BLOG_AUTHOR_STATUS_REQUEST = "PATCH_BLOG_AUTHOR_STATUS_REQUEST";
export const PATCH_BLOG_AUTHOR_STATUS_ERROR = "PATCH_BLOG_AUTHOR_STATUS_ERROR";

export const PATCH_BLOG_CATEGORY_STATUS_REQUEST = "PATCH_BLOG_CATEGORY_STATUS_REQUEST";
export const PATCH_BLOG_CATEGORY_STATUS_ERROR = "PATCH_BLOG_CATEGORY_STATUS_ERROR";

export const CREATE_NEW_ARTICLE_REQUEST = "CREATE_NEW_ARTICLE_REQUEST";
export const CREATE_NEW_ARTICLE_REQUEST_ERROR = "CREATE_NEW_ARTICLE_REQUEST_ERROR";
export const CREATE_NEW_ARTICLE_REQUEST_SUCCESS = "CREATE_NEW_ARTICLE_REQUEST_SUCCESS";

export const UPLOAD_NEW_IMAGE_REQUEST = "UPLOAD_NEW_IMAGE_REQUEST";
export const UPLOAD_NEW_IMAGE_REQUEST_ERROR = "UPLOAD_NEW_IMAGE_REQUEST_ERROR";
export const UPLOAD_NEW_IMAGE_REQUEST_SUCCESS = "UPLOAD_NEW_IMAGE_REQUEST_SUCCESS";

export const CREATE_NEW_AUTHOR_REQUEST = "CREATE_NEW_AUTHOR_REQUEST";
export const CREATE_NEW_AUTHOR_REQUEST_SUCCESS = "CREATE_NEW_AUTHOR_REQUEST_SUCCESS";
export const CREATE_NEW_AUTHOR_REQUEST_ERROR = "CREATE_NEW_AUTHOR_REQUEST_ERROR";

export const CREATE_NEW_CATEGORY_REQUEST = "CREATE_NEW_CATEGORY_REQUEST";
export const CREATE_NEW_CATEGORY_REQUEST_SUCCESS = "CREATE_NEW_CATEGORY_REQUEST_SUCCESS";
export const CREATE_NEW_CATEGORY_REQUEST_ERROR = "CREATE_NEW_CATEGORY_REQUEST_ERROR";

export const TOGGLE_ADD_AUTHOR_PROCESSING = "TOGGLE_ADD_AUTHOR_PROCESSING";
export const TOGGLE_ADD_CATEGORY_PROCESSING = "TOGGLE_ADD_CATEGORY_PROCESSING";
export const TOGGLE_ARTICLE_PROCESSING = "TOGGLE_ARTICLE_PROCESSING";

export const GET_BLOG_ARTICLE_DATA_REQUEST = "GET_BLOG_ARTICLE_DATA_REQUEST";
export const GET_BLOG_ARTICLE_DATA_REQUEST_SUCCESS = "GET_BLOG_ARTICLE_DATA_REQUEST_SUCCESS";
export const GET_BLOG_ARTICLE_DATA_REQUEST_ERROR = "GET_BLOG_ARTICLE_DATA_REQUEST_ERROR";

export const UPDATE_SHOW_BLOG_STATUS = "UPDATE_SHOW_BLOG_STATUS";
export const UPDATE_SHOW_BLOG_STATUS_ERROR = "UPDATE_SHOW_BLOG_STATUS_ERROR";

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// components
import { DeleteIcon } from "./svg/DeleteIcon";
import { EditIcon } from "./svg/EditIcon";
import { EyeOutlined } from "./svg/EyeOutlined";

export const InlineIcon = ({ type, className, size, danger, disabled, customIcon, ...rest }) => {
  const icon = useMemo(() => {
    switch (type) {
      case "delete":
        return <DeleteIcon />;
      case "edit":
        return <EditIcon />;
      case "eye":
      case "view":
        return <EyeOutlined />;
      default:
        return null;
    }
  }, [type]);

  return icon ? (
    <span
      role="img"
      aria-label={type}
      tabIndex="-1"
      className={classNames(
        "lant-icon",
        `lant-icon--${type}`,
        danger && "danger",
        disabled && "disabled",
        className
      )}
      {...(size ? { style: { fontSize: `${size}px` } } : {})}
      {...rest}
    >
      {icon}
    </span>
  ) : null;
};

InlineIcon.propTypes = {
  customIcon: PropTypes.element,
  type: PropTypes.oneOf(["view", "edit", "delete", "eye"]).isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
};

export const formatCreationTime = (
  date,
  returnHours = true,
  returnMinutes = true,
  returnSeconds = true
) => {
  const dateOfCreation = new Date(date);
  const hoursAmount = dateOfCreation.getHours();
  const minutesAmount = dateOfCreation.getMinutes();
  const secondsAmount = dateOfCreation.getSeconds();

  const properHoursFormat = hoursAmount < 10 ? `0${hoursAmount}` : hoursAmount;
  const properMinutesFormat = minutesAmount < 10 ? `0${minutesAmount}` : minutesAmount;
  const properSecondsFormat = secondsAmount < 10 ? `0${secondsAmount}` : secondsAmount;

  return `${returnHours ? `${properHoursFormat}:` : ""}${returnMinutes ? `${properMinutesFormat}:` : ""}${returnSeconds ? properSecondsFormat : ""}`;
};

import * as types from "./modalActionsTypes";


const initialState = {
  isOpen: false,
  content: null,
  props: {}
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        content: action.content,
        props: action.props,
      };

    case types.CLOSE_MODAL:
      return {
        isOpen: false,
        content: null,
        props: {}
      };

    default:
      return state;
  }
};

export default modal;

import fetch from "./customFetch";

//helpers
import buildQueryString from "../helpers/buildQueryString";


const artisans = {
  async get({ page, perPage }) {
    try {
      return await fetch({
        url: `/artisans/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async search({ page, perPage, search, isVisible, isApproved }) {
    try {
      const query = buildQueryString({
        page,
        "page_size": perPage,
        "query": search,
        "is_visible": isVisible,
        "is_approved": isApproved,
      });

      return await fetch({
        url: `/artisans/search/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async create(data) {
    try {
      return await fetch({
        url: "/artisans/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      if (err.message) {
        throw err.message;
      }

      throw err;
    }
  },

  async updatePut(url, data) {
    try {
      return await fetch({
        url: `/artisans/${url}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatch(url, data) {
    try {
      return await fetch({
        url: `/artisans/${url}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async delete(url) {
    try {
      return await fetch({
        url: `/artisans/${url}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getTags({ page = 1, perPage = 200 }) {
    const query = buildQueryString({ page, "page_size": perPage });

    try {
      return await fetch({
        url: `/artisans/tags/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postArtisanTags(data) {
    try {
      return await fetch({
        url: "/artisans/tags/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async putArtisanTags(id, data) {
    try {
      return await fetch({
        url: `/artisans/tags/${id}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteArtisanTags(id) {
    try {
      return await fetch({
        url: `/artisans/tags/${id}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default artisans;

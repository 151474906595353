import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Select } from "antd";
import _ from "lodash";

import {
  getAdminAccountsRequest,
  searchAdminsRequest,
} from "../../redux/settings/adminAccounts/adminAccountsActions";


const AdminsSearch = ({
  getAdminAccounts,
  searchedAdmins,
  searchAdmins,
  adminAccounts,
  placeholder,
  className,
  chooseAdmin
}) => {
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [placeholderValue, setPlaceholderValue] = useState(placeholder);


  // Methods

  useEffect(() => {
    getAdminAccounts({ page: 1, perPage: 10 });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!_.isEmpty(searchedAdmins)) return setOptions(searchedAdmins);
    const adminAccountsTransformedData = adminAccounts && adminAccounts.map(({ id, firstName, lastName }) => (
      { id, firstName, lastName }
    ));
    setOptions(adminAccountsTransformedData);
  }, [adminAccounts, searchedAdmins]); // eslint-disable-line

  useEffect(() => {
    query && searchAdmins(query);
  }, [query]); // eslint-disable-line

  const onChange = (value) => {
    if (value && value.length >= 1) setQuery(value);
  };


  // Render

  return (
    <div className={`admins-search ${className}-wrapper`}>
      <Select
        showSearch
        className={`search-admins-field ${className}`}
        placeholder={placeholderValue ? placeholderValue : "By admin"}
        optionFilterProp="children"
        onChange={(value, option) => chooseAdmin(value, option)}
        onFocus={() => setPlaceholderValue("Search admin")}
        onBlur={() => setPlaceholderValue("By admin")}
        onSearch={onChange}
      >
        <Select.Option key="all-admin-accounts-select-option" value="All Admins">All Admins</Select.Option>
        {
          options && options.map(({ firstName, lastName }, index) => {
            const dealerFullName = `${firstName} ${lastName}`;

            return (
              <Select.Option key={index} value={dealerFullName}>{dealerFullName}</Select.Option>
            );
          })
        }
      </Select>
    </div>
  );
};

AdminsSearch.propTypes = {
  getAdminAccounts: PropTypes.func.isRequired,
  searchedAdmins: PropTypes.array.isRequired,
  searchAdmins: PropTypes.func.isRequired,
  adminAccounts: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  chooseAdmin: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    searchedAdmins: state.adminAccounts.searchedAdmins,
    adminAccounts: state.adminAccounts.adminAccounts,
  }),
  dispatch => ({
    searchAdmins: payload => dispatch(searchAdminsRequest(payload)),
    getAdminAccounts: payload => dispatch(getAdminAccountsRequest(payload)),
  })
)(AdminsSearch);

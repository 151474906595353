export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";
export const GET_OFFERS_REQUEST_SUCCESS = "GET_OFFERS_REQUEST_SUCCESS";
export const GET_OFFERS_REQUEST_ERROR = "GET_OFFERS_REQUEST_ERROR";

export const GET_USER_OFFERS_REQUEST = "GET_USER_OFFERS_REQUEST";
export const GET_USER_OFFERS_REQUEST_SUCCESS = "GET_USER_OFFERS_REQUEST_SUCCESS";
export const GET_USER_OFFERS_REQUEST_ERROR = "GET_USER_OFFERS_REQUEST_ERROR";

export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST";
export const CREATE_OFFER_REQUEST_ERROR = "CREATE_OFFER_REQUEST_ERROR";

export const CREATE_USER_OFFER_REQUEST = "CREATE_USER_OFFER_REQUEST";
export const CREATE_USER_OFFER_REQUEST_ERROR = "CREATE_USER_OFFER_REQUEST_ERROR";

export const CLEAR_ERRORS = "CLEAR_OFFERS_ERRORS";

export const PATCH_OFFERS_REQUEST = "PATCH_OFFERS_REQUEST";
export const PATCH_OFFERS_REQUEST_ERROR = "PATCH_OFFERS_REQUEST_ERROR";

export const SHOW_PROCESSING = "SHOW_OFFERS_PROCESSING";
export const HIDE_PROCESSING = "HIDE_OFFERS_PROCESSING";

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./invoiceListActionsTypes";
import invoicesApi from "../../../api/invoices";

import {
  getInvoicePDFRequestSuccess,
  getInvoicePDFRequestError,
} from "./invoiceListActions";

function *getInvoiceList(action) {
  try {
    yield put({ type: types.TOGGLE_INVOICES_LOADING, payload: true });
    let response = {};
    if (action.payload.search) {
      response = yield call(invoicesApi.getInvoicesSearch, action.payload);
    } else {
      response = yield call(invoicesApi.getInvoices, action.payload);
    }

    yield put({ type: types.GET_INVOICE_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_INVOICE_LIST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_INVOICES_LOADING, payload: false });
  }
}

function *deleteInvoice(action) {
  try {
    const { id, ...rest } = action.payload;

    yield put({ type: types.TOGGLE_INVOICES_PROCESSING });
    yield call(invoicesApi.deleteInvoice, id);

    yield put({ type: types.GET_INVOICE_LIST_REQUEST, payload: rest });
  } catch (error) {
    yield put({ type: types.DELETE_INVOICE_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_INVOICES_PROCESSING });
  }
}

function *getInvoicePDF(action) {
  try {
    yield put({ type: types.TOGGLE_INVOICES_PROCESSING });
    const response = yield call(invoicesApi.getInvoicePDF, action.payload);
    yield put(getInvoicePDFRequestSuccess(response));
  } catch (error) {
    yield put(getInvoicePDFRequestError(error));
  } finally {
    yield put({ type: types.TOGGLE_INVOICES_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_INVOICE_LIST_REQUEST, getInvoiceList),
    yield takeLatest(types.DELETE_INVOICE_REQUEST, deleteInvoice),
    yield takeLatest(types.GET_INVOICE_PDF_REQUEST, getInvoicePDF),
  ])
}

import fetch from "./customFetch";
import buildQueryString from "../helpers/buildQueryString";

const seo = {
  async get({ perPage = 10, ...rest }) {
    const query = buildQueryString({
      ...rest,
      page_size: perPage,
    });

    try {
      return await fetch({
        url: `/seo/urls/?${query}`,
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patch(id, data) {
    try {
      return await fetch({
        url: `/seo/urls/${id}/`,
        method: "PATCH",
        data
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default seo;

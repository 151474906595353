import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu } from "antd";

// components
import { LantItemLayout } from "../../../components";
import { DirectoryEntryStatus } from "../components/DirectoryEntry/DirectoryEntryStatus";
import { DirectoryEntryDetails } from "../components/DirectoryEntry/DirectoryEntryDetails";
import { DirectoryEntryMedia } from "../components/DirectoryEntry/DirectoryEntryMedia";
import { FairEvents } from "../components/DirectoryEntry/FairEvents";
import { DirectoryEntrySubscription } from "../components/DirectoryEntry/DirectoryEntrySubscription";
import { FairNotes } from "./FairNotes";

// react-queries
import { useFair } from "../../../react-query";
import { FairHistory } from "../components/DirectoryEntry/FairHistory";

const NAVIGATION_TABS = [
  { key: "status", label: "STATUS" },
  { key: "details", label: "DETAILS" },
  { key: "media", label: "MEDIA" },
  { key: "events", label: "EVENTS" },
  { key: "subscription", label: "SUBSCRIPTION" },
  { key: "notes", label: "NOTES" },
  { key: "history", label: "FAIR LOGS" },
];


export const EditFair = () => {
  const { id, tab } = useParams();

  const history = useHistory();

  const navigateToTab = (newTab, name) => {
    history.replace({
      pathname: `/directory/fairs/${id}/${newTab}`,
      state: { path: name }
    });
  };

  const { data, error, isFetching, isLoading } = useFair(id, {
    onSuccess: (resp) => navigateToTab(tab, resp.name)
  });

  const renderTab = () => {
    switch (tab) {
      case "details":
        return <DirectoryEntryDetails data={data} isFair={true} />;
      case "media":
        return <DirectoryEntryMedia data={data} isFair={true} />;
      case "events":
        return <FairEvents data={data} />;
      case "subscription":
        return <DirectoryEntrySubscription data={data} isFair={true} isLoading={isLoading} />;
      case "notes":
        return <FairNotes fairId={data?.id} />;
      case "history":
        return <FairHistory fairId={data?.id} />;
      default:
        return <DirectoryEntryStatus data={data} isFair={true} isLoading={isLoading} />;
    }
  };

  return (
    <LantItemLayout item="fair" error={error} processing={isFetching}>
      <Menu
        mode="horizontal"
        selectedKeys={[tab]}
        onSelect={(e) => navigateToTab(e.key, data?.name)}
      >
        {NAVIGATION_TABS.map(({ key, label }) => (
          <Menu.Item key={key} className="tab-navigation--item">
            {label}
          </Menu.Item>
        ))}
      </Menu>

      {renderTab()}
    </LantItemLayout>
  );
};

export const SHOW_PROCESSING = "SHOW_CATEGORIES_PROCESSING";
export const HIDE_PROCESSING = "HIDE_CATEGORIES_PROCESSING";
export const TOGGLE_PROCESSING = "TOGGLE_CATEGORIES_PROCESSING";

export const RESET_ERROR = "RESET_CATEGORIES_ERROR";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_REQUEST_SUCCESS = "GET_CATEGORIES_REQUEST_SUCCESS";
export const GET_CATEGORIES_REQUEST_ERROR = "GET_CATEGORIES_REQUEST_ERROR";

export const CREATE_CATEGORIES_REQUEST = "CREATE_CATEGORIES_REQUEST";
export const CREATE_CATEGORIES_REQUEST_SUCCESS = "CREATE_CATEGORIES_REQUEST_SUCCESS";
export const CREATE_CATEGORIES_REQUEST_ERROR = "CREATE_CATEGORIES_REQUEST_ERROR";

export const UPDATE_CATEGORIES_REQUEST = "UPDATE_CATEGORIES_REQUEST";
export const DELETE_CATEGORIES_REQUEST = "DELETE_CATEGORIES_REQUEST";

export const GET_CATEGORY_FAQ_REQUEST = "GET_CATEGORY_FAQ_REQUEST";
export const GET_CATEGORY_FAQ_REQUEST_ERROR = "GET_CATEGORY_FAQ_REQUEST_ERROR";
export const GET_CATEGORY_FAQ_REQUEST_SUCCESS = "GET_CATEGORY_FAQ_REQUEST_SUCCESS";

export const CREATE_CATEGORY_FAQ_REQUEST = "CREATE_CATEGORY_FAQ_REQUEST";
export const CREATE_CATEGORY_FAQ_REQUEST_ERROR = "CREATE_CATEGORY_FAQ_REQUEST_ERROR";
export const CREATE_CATEGORY_FAQ_REQUEST_SUCCESS = "CREATE_CATEGORY_FAQ_REQUEST_SUCCESS";

export const PATCH_CATEGORY_FAQ_REQUEST = "PATCH_CATEGORY_FAQ_REQUEST";
export const PATCH_CATEGORY_FAQ_REQUEST_ERROR = "PATCH_CATEGORY_FAQ_REQUEST_ERROR";
export const PATCH_CATEGORY_FAQ_REQUEST_SUCCESS = "PATCH_CATEGORY_FAQ_REQUEST_SUCCESS";

export const TOGGLE_CATEGORY_FAQ_LOADING = "TOGGLE_CATEGORY_FAQ_LOADING";
export const TOGGLE_CATEGORY_FAQ_PROCESSING = "TOGGLE_CATEGORY_FAQ_PROCESSING";

import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import TabNavigation from "../../shared/TabNavigation";
import Blacklisted from "./Blacklisted";
import Spam from "./Spam";

const renderTabNavigation = (showTabNavigator) => {
  if (!showTabNavigator) return null;

  return (
    <TabNavigation
      items={[
        {
          label: "BLACKLISTED",
          link: "/enquiries/blacklists/blacklisted"
        },
        {
          label: "SPAM",
          link: "/enquiries/blacklists/spam"
        }
      ]}
    />
  )
};

const BlackLists = () => {
  const [showTabNavigator, setShowTabNavigator] = useState(true);

  const onToggleEdit = (isEditing) => setShowTabNavigator(!isEditing);
  
  return (
    <>
      {renderTabNavigation(showTabNavigator)}
      <Switch>
        <Route
          path="/enquiries/blacklists/blacklisted"
          render={props => <Blacklisted {...props} onToggleEdit={onToggleEdit} />}
        />
        <Route
          path="/enquiries/blacklists/spam"
          component={Spam}
        />
        <Redirect to="/enquiries/blacklists/blacklisted" />
      </Switch>
    </>
  );
}

export default BlackLists;

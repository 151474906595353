import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./offersActionsTypes";
import settingsApi from "../../../api/settings";


function *getOffers(action) {
  try {
    yield put({ type: types.SHOW_PROCESSING });

    const response = yield call(settingsApi.getOffers, action.payload);
    yield put({ type: types.GET_OFFERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_OFFERS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

function *patchOffer(action) {
  try {
    const { id, data, ...rest } = action.payload;

    yield call(settingsApi.patchOffer, id, data);
    yield put({ type: types.GET_OFFERS_REQUEST, payload: rest });
  } catch (error) {
    yield put({ type: types.PATCH_OFFERS_REQUEST_ERROR, payload: error });
  }
}

function *createOffer(action) {
  try {
    yield put({ type: types.SHOW_PROCESSING });
    yield put({ type: types.CLEAR_ERRORS });

    yield call(settingsApi.createOffer, action.payload);
  } catch (error) {
    yield put({ type: types.CREATE_OFFER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

function *getUserOffers(action) {
  try {
    yield put({ type: types.SHOW_PROCESSING });

    const response = yield call(settingsApi.getUserOffers, action.payload);
    yield put({ type: types.GET_USER_OFFERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_USER_OFFERS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

function *createUserOffer(action) {
  try {
    yield put({ type: types.SHOW_PROCESSING });
    yield put({ type: types.CLEAR_ERRORS });

    yield call(settingsApi.createUserOffer, action.payload);
  } catch (error) {
    yield put({ type: types.CREATE_USER_OFFER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_OFFERS_REQUEST, getOffers),
    yield takeLatest(types.PATCH_OFFERS_REQUEST, patchOffer),
    yield takeLatest(types.CREATE_OFFER_REQUEST, createOffer),
    yield takeLatest(types.GET_USER_OFFERS_REQUEST, getUserOffers),
    yield takeLatest(types.CREATE_USER_OFFER_REQUEST, createUserOffer)
  ])
}

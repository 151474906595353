export const ENGLAND_OPTIONS = [
  {
    value: "Avon",
    label: "Avon",
  },
  {
    value: "Bedfordshire",
    label: "Bedfordshire",
  },
  {
    value: "Berkshire",
    label: "Berkshire",
  },
  {
    value: "City of Brighton and Hove",
    label: "City of Brighton and Hove",
  },
  {
    value: "City of Bristol",
    label: "City of Bristol",
  },
  {
    value: "Buckinghamshire",
    label: "Buckinghamshire",
  },
  {
    value: "Cambridgeshire",
    label: "Cambridgeshire",
  },
  {
    value: "Cheshire",
    label: "Cheshire",
  },
  {
    value: "Cornwall",
    label: "Cornwall",
  },
  {
    value: "Cumberland",
    label: "Cumberland",
  },
  {
    value: "Cumbria",
    label: "Cumbria",
  },
  {
    value: "Derbyshire",
    label: "Derbyshire",
  },
  {
    value: "Devon",
    label: "Devon",
  },
  {
    value: "Dorset",
    label: "Dorset",
  },
  {
    value: "Durham",
    label: "Durham",
  },
  {
    value: "East Suffolk",
    label: "East Suffolk",
  },
  {
    value: "East Sussex",
    label: "East Sussex",
  },
  {
    value: "Essex",
    label: "Essex",
  },
  {
    value: "Gloucestershire",
    label: "Gloucestershire",
  },
  {
    value: "Greater London",
    label: "Greater London",
  },
  {
    value: "Greater Manchester",
    label: "Greater Manchester",
  },
  {
    value: "Hampshire",
    label: "Hampshire",
  },
  {
    value: "Herefordshire",
    label: "Herefordshire",
  },
  {
    value: "Hertfordshire",
    label: "Hertfordshire",
  },
  {
    value: "Humberside",
    label: "Humberside",
  },
  {
    value: "Huntingdon and Peterborough",
    label: "Huntingdon and Peterborough",
  },
  {
    value: "Huntingdonshire",
    label: "Huntingdonshire",
  },
  {
    value: "Isle of Ely",
    label: "Isle of Ely",
  },
  {
    value: "Isle of Wight",
    label: "Isle of Wight",
  },
  {
    value: "Kent",
    label: "Kent",
  },
  {
    value: "Lancashire",
    label: "Lancashire",
  },
  {
    value: "Leicestershire",
    label: "Leicestershire",
  },
  {
    value: "Lincolnshire",
    label: "Lincolnshire",
  },
  {
    value: "Lincolnshire, Parts of Holland",
    label: "Lincolnshire, Parts of Holland",
  },
  {
    value: "Lincolnshire, Parts of Kesteven",
    label: "Lincolnshire, Parts of Kesteven",
  },
  {
    value: "Lincolnshire, Parts of Lindsey",
    label: "Lincolnshire, Parts of Lindsey",
  },
  {
    value: "London",
    label: "London",
  },
  {
    value: "Merseyside",
    label: "Merseyside",
  },
  {
    value: "Middlesex",
    label: "Middlesex",
  },
  {
    value: "Norfolk",
    label: "Norfolk",
  },
  {
    value: "Northamptonshire",
    label: "Northamptonshire",
  },
  {
    value: "Northumberland",
    label: "Northumberland",
  },
  {
    value: "North Humberside",
    label: "North Humberside",
  },
  {
    value: "North Yorkshire",
    label: "North Yorkshire",
  },
  {
    value: "Nottinghamshire",
    label: "Nottinghamshire",
  },
  {
    value: "Oxfordshire",
    label: "Oxfordshire",
  },
  {
    value: "Soke of Peterborough",
    label: "Soke of Peterborough",
  },
  {
    value: "Rutland",
    label: "Rutland",
  },
  {
    value: "Shropshire",
    label: "Shropshire",
  },
  {
    value: "Somerset",
    label: "Somerset",
  },
  {
    value: "South Humberside",
    label: "South Humberside",
  },
  {
    value: "South Yorkshire",
    label: "South Yorkshire",
  },
  {
    value: "Staffordshire",
    label: "Staffordshire",
  },
  {
    value: "Suffolk",
    label: "Suffolk",
  },
  {
    value: "Surrey",
    label: "Surrey",
  },
  {
    value: "Sussex",
    label: "Sussex",
  },
  {
    value: "Tyne and Wear",
    label: "Tyne and Wear",
  },
  {
    value: "Warwickshire",
    label: "Warwickshire",
  },
  {
    value: "West Midlands",
    label: "West Midlands",
  },
  {
    value: "Westmorland",
    label: "Westmorland",
  },
  {
    value: "West Suffolk",
    label: "West Suffolk",
  },
  {
    value: "West Sussex",
    label: "West Sussex",
  },
  {
    value: "West Yorkshire",
    label: "West Yorkshire",
  },
  {
    value: "Wiltshire",
    label: "Wiltshire",
  },
  {
    value: "Worcestershire",
    label: "Worcestershire",
  },
  {
    value: "Yorkshire",
    label: "Yorkshire",
  },
  {
    value: "Yorkshire, East Riding",
    label: "Yorkshire, East Riding",
  },
  {
    value: "Yorkshire, North Riding",
    label: "Yorkshire, North Riding",
  },
  {
    value: "Yorkshire, West Riding",
    label: "Yorkshire, West Riding",
  },
];

export const NORTHERN_IRELAND_OPTIONS = [
  {
    value: "Antrim",
    label: "Antrim",
  },
  {
    value: "Armagh",
    label: "Armagh",
  },
  {
    value: "City of Belfast",
    label: "City of Belfast",
  },
  {
    value: "Down",
    label: "Down",
  },
  {
    value: "Fermanagh",
    label: "Fermanagh",
  },
  {
    value: "Londonderry",
    label: "Londonderry",
  },
  {
    value: "City of Derry",
    label: "City of Derry",
  },
  {
    value: "Tyrone",
    label: "Tyrone",
  },
];

export const SCOTLAND_OPTIONS = [
  {
    value: "City of Aberdeen",
    label: "City of Aberdeen",
  },
  {
    value: "Aberdeenshire",
    label: "Aberdeenshire",
  },
  {
    value: "Angus (Forfarshire)",
    label: "Angus (Forfarshire)",
  },
  {
    value: "Argyll",
    label: "Argyll",
  },
  {
    value: "Ayrshire",
    label: "Ayrshire",
  },
  {
    value: "Banffshire",
    label: "Banffshire",
  },
  {
    value: "Berwickshire",
    label: "Berwickshire",
  },
  {
    value: "Bute",
    label: "Bute",
  },
  {
    value: "Caithness",
    label: "Caithness",
  },
  {
    value: "Clackmannanshire",
    label: "Clackmannanshire",
  },
  {
    value: "Cromartyshire",
    label: "Cromartyshire",
  },
  {
    value: "Dumfriesshire",
    label: "Dumfriesshire",
  },
  {
    value: "Dunbartonshire (Dumbarton)",
    label: "Dunbartonshire (Dumbarton)",
  },
  {
    value: "City of Dundee",
    label: "City of Dundee",
  },
  {
    value: "East Ayrshire",
    label: "East Ayrshire",
  },
  {
    value: "East Dunbartonshire",
    label: "East Dunbartonshire",
  },
  {
    value: "East Lothian (Haddingtonshire)",
    label: "East Lothian (Haddingtonshire)",
  },
  {
    value: "East Renfrewshire",
    label: "East Renfrewshire",
  },
  {
    value: "City of Edinburgh",
    label: "City of Edinburgh",
  },
  {
    value: "Falkirk",
    label: "Falkirk",
  },
  {
    value: "Fife",
    label: "Fife",
  },
  {
    value: "City of Glasgow",
    label: "City of Glasgow",
  },
  {
    value: "Highland",
    label: "Highland",
  },
  {
    value: "Inverclyde",
    label: "Inverclyde",
  },
  {
    value: "Inverness-shire",
    label: "Inverness-shire",
  },
  {
    value: "Kincardineshire",
    label: "Kincardineshire",
  },
  {
    value: "Kinross-shire",
    label: "Kinross-shire",
  },
  {
    value: "Kirkcudbrightshire",
    label: "Kirkcudbrightshire",
  },
  {
    value: "Lanarkshire",
    label: "Lanarkshire",
  },
  {
    value: "Midlothian (County of Edinburgh)",
    label: "Midlothian (County of Edinburgh)",
  },
  {
    value: "Moray (Elginshire)",
    label: "Moray (Elginshire)",
  },
  {
    value: "Nairnshire",
    label: "Nairnshire",
  },
  {
    value: "Na h-Eileanan Siar",
    label: "Na h-Eileanan Siar",
  },
  {
    value: "North Ayrshire",
    label: "North Ayrshire",
  },
  {
    value: "North Lanarkshire",
    label: "North Lanarkshire",
  },
  {
    value: "Orkney",
    label: "Orkney",
  },
  {
    value: "Perth and Kinross",
    label: "Perth and Kinross",
  },
  {
    value: "Peeblesshire",
    label: "Peeblesshire",
  },
  {
    value: "Perthshire",
    label: "Perthshire",
  },
  {
    value: "Renfrewshire",
    label: "Renfrewshire",
  },
  {
    value: "Ross and Cromarty",
    label: "Ross and Cromarty",
  },
  {
    value: "Ross-shire",
    label: "Ross-shire",
  },
  {
    value: "Roxburghshire",
    label: "Roxburghshire",
  },
  {
    value: "Scottish Borders",
    label: "Scottish Borders",
  },
  {
    value: "Shetland Islands",
    label: "Shetland Islands",
  },
  {
    value: "South Ayrshire",
    label: "South Ayrshire",
  },
  {
    value: "South Lanarkshire",
    label: "South Lanarkshire",
  },
  {
    value: "Selkirkshire",
    label: "Selkirkshire",
  },
  {
    value: "Shetland (Zetland)",
    label: "Shetland (Zetland)",
  },
  {
    value: "Stirlingshire",
    label: "Stirlingshire",
  },
  {
    value: "Sutherland",
    label: "Sutherland",
  },
  {
    value: "West Dunbartonshire",
    label: "West Dunbartonshire",
  },
  {
    value: "West Lothian (Linlithgowshire)",
    label: "West Lothian (Linlithgowshire)",
  },
  {
    value: "Wigtownshire",
    label: "Wigtownshire",
  },
];

export const WALES_OPTIONS = [
  {
    value: "Anglesey",
    label: "Anglesey",
  },
  {
    value: "Blaenau Gwent",
    label: "Blaenau Gwent",
  },
  {
    value: "Brecknockshire",
    label: "Brecknockshire",
  },
  {
    value: "Bridgend",
    label: "Bridgend",
  },
  {
    value: "Caerphilly",
    label: "Caerphilly",
  },
  {
    value: "Caernarfonshire",
    label: "Caernarfonshire",
  },
  {
    value: "Cardiganshire",
    label: "Cardiganshire",
  },
  {
    value: "Cardiff",
    label: "Cardiff",
  },
  {
    value: "Carmarthenshire",
    label: "Carmarthenshire",
  },
  {
    value: "Ceredigion",
    label: "Ceredigion",
  },
  {
    value: "Clwyd",
    label: "Clwyd",
  },
  {
    value: "Conwy",
    label: "Conwy",
  },
  {
    value: "Denbighshire",
    label: "Denbighshire",
  },
  {
    value: "Dyfed",
    label: "Dyfed",
  },
  {
    value: "Flintshire",
    label: "Flintshire",
  },
  {
    value: "Glamorgan",
    label: "Glamorgan",
  },
  {
    value: "Gwent",
    label: "Gwent",
  },
  {
    value: "Gwynedd",
    label: "Gwynedd",
  },
  {
    value: "Isle of Anglesey",
    label: "Isle of Anglesey",
  },
  {
    value: "Merionethshire",
    label: "Merionethshire",
  },
  {
    value: "Merthyr Tydfil",
    label: "Merthyr Tydfil",
  },
  {
    value: "Mid Glamorgan",
    label: "Mid Glamorgan",
  },
  {
    value: "Monmouthshire",
    label: "Monmouthshire",
  },
  {
    value: "Montgomeryshire",
    label: "Montgomeryshire",
  },
  {
    value: "Neath Port Talbot",
    label: "Neath Port Talbot",
  },
  {
    value: "Newport",
    label: "Newport",
  },
  {
    value: "Pembrokeshire",
    label: "Pembrokeshire",
  },
  {
    value: "Powys",
    label: "Powys",
  },
  {
    value: "Radnorshire",
    label: "Radnorshire",
  },
  {
    value: "Rhondda Cynon Taf",
    label: "Rhondda Cynon Taf",
  },
  {
    value: "South Glamorgan",
    label: "South Glamorgan",
  },
  {
    value: "Swansea",
    label: "Swansea",
  },
  {
    value: "Torfaen",
    label: "Torfaen",
  },
  {
    value: "Vale of Glamorgan",
    label: "Vale of Glamorgan",
  },
  {
    value: "West Glamorgan",
    label: "West Glamorgan",
  },
  {
    value: "Wrexham",
    label: "Wrexham",
  },
];

export const GB_COUNTRY_LIST = [
  {
    label: "England",
    options: ENGLAND_OPTIONS,
  },
  {
    label: "Northern Ireland",
    options: NORTHERN_IRELAND_OPTIONS,
  },
  {
    label: "Scotland",
    options: SCOTLAND_OPTIONS,
  },
  {
    label: "Wales",
    options: WALES_OPTIONS,
  },
];


import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ListOfOrders from "./components/ListOfOrders";
import OrderComponent from "./components/OrderComponent";


function Orders() {
  return (
    <div className="component">
      <Switch>
        <Route exact path="/orders/" component={ListOfOrders} />
        <Route path="/orders/:id" component={OrderComponent} />
        <Redirect to="/orders/" />
      </Switch>
    </div>
  )
}

export default Orders;

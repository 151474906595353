const formCategoriesDefaultValues = categories => {
  const result = {};
  const format = {
    mainCategory1: null,
    subCategory1: [],
    mainCategory2: null,
    subCategory2: [],
    mainCategory3: null,
    subCategory3: [],
    mainCategory4: null,
    subCategory4: [],
    mainCategory5: null,
    subCategory5: [],
  };

  categories.forEach((category) => {
    if (!category.parentId) {
      result[category.id] = { ...category };
    }
  });

  categories.forEach((category) => {
    if (!category.parentId) {
      return;
    }
    if (!result.hasOwnProperty(category.parentId)) {
      return;
    }

    if (!result[category.parentId].subCategories) {
      result[category.parentId].subCategories = [];
    }
    
    result[category.parentId].subCategories.push(category);
  });

  Object.values(result).forEach((mainCategory, i) => {
    const { subCategories, ...rest } = mainCategory;

    format[`mainCategory${i + 1}`] = rest;
    format[`subCategory${i + 1}`] = subCategories;
  });

  return format;
};

export default formCategoriesDefaultValues;

import * as types from "./systemPreferencesActionsTypes";
import _ from "lodash";

const initialState = {
  data: {},
  advancedFields: [],
  systemPreferencesFields: [],
  generalPropertiesField: [],
  processing: false,
  loading: false,
  errors: {},
};

const systemPreferences = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SYSTEM_PREFERENCES_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.result,
        advancedFields: action.payload.advancedFields,
        systemPreferencesFields: action.payload.systemPreferencesFields,
        generalPropertiesField: action.payload.generalPropertiesField
      };

    case types.PATCH_ADVANCED_PREFERENCES_REQUEST_SUCCESS:
    case types.PATCH_SYSTEM_PREFERENCES_REQUEST_SUCCESS:
    case types.PATCH_GENERAL_PROPERTIES_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case types.GET_SYSTEM_PREFERENCES_REQUEST_ERROR:
    case types.PATCH_ADVANCED_PREFERENCES_REQUEST_ERROR:
    case types.PATCH_SYSTEM_PREFERENCES_REQUEST_ERROR:
    case types.PATCH_GENERAL_PROPERTIES_REQUEST_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      };

    case types.SHOW_SYSTEM_PREFERENCES_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_SYSTEM_PREFERENCES_PROCESSING:
      return {
        ...state,
        processing: false
      };

    case types.SHOW_SYSTEM_PREFERENCES_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_SYSTEM_PREFERENCES_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.RESET_ERROR:
      return {
        ...state,
        errors: _.omit(state.errors, action.payload)
      };

    case types.RESET_ERRORS:
      return {
        ...state,
        errors: {}
      };

    default:
      return state;
  }
};

export default systemPreferences;

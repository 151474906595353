import * as types from "./pagesActionsTypes";


const initialState = {
  pages: [],
  totalNumberOfPages: 0,
  error: null,
  processing: false,
  loading: false
};


const pages = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENT_PAGES_REQUEST_SUCCESS:
      return {
        ...state,
        pages: action.payload.results,
        totalNumberOfPages: action.payload.count
      };

    case types.GET_CONTENT_PAGES_REQUEST_ERROR:
    case types.CREATE_NEW_PAGE_REQUEST_FAILED:
    case types.UPDATE_PAGE_REQUEST_ERROR:
    case types.DELETE_PAGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.CREATE_NEW_PAGE_REQUEST_SUCCESS:
    case types.UPDATE_PAGE_REQUEST_SUCCESS:
    case types.DELETE_PAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.TOGGLE_PAGE_PROCESSING:
      return {
        ...state,
        processing: action.payload
      };

    case types.LOADING:
      return {
        ...state,
        loading: action.payload
      };

    default:
      return state;
  }
};

export default pages;

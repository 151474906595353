import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  message,
  Popconfirm
} from "antd";
import get from "lodash/get";

//components
import LabeledInput from "../../../shared/LabeledInput";
import GeneralFormControl from "../../../shared/GeneralFormControl";
import ImageUpload from "../../../shared/ImageUpload"
import AddEditArtisansLinkedField from "./AddEditArtisansLinkedField";
import AddEditArtisansTagsField from "./AddEditArtisansTagsField";

//redux
import {
  createArtisansRequest,
  updateArtisansRequest
} from "../../../../redux/artisans/artisansActions";

//helpers
import ContentApi from "../../../../api/content";


function AddEditArtisans(props) {
  const { item, close, remove, processing, error, history, location } = props;
  const oldUrlBeforeEdit = get(item, "url", "");

  const [submitted, setSubmitted] = useState(false);

  const [visible, setVisible] = useState(get(item, "isVisible", true));
  const [approved, setApproved] = useState(get(item, "isApproved", true));
  const [name, setName] = useState(get(item, "name", ""));
  const [pseudo, setPseudo] = useState(get(item, "pseudonym", ""));
  const [title, setTitle] = useState(get(item, "title", ""));
  const [url, setUrl] = useState(oldUrlBeforeEdit);

  const [overrideSubheader, setOverrideSubheader] = useState(get(item, "overrideSubheader", ""));
  const [overrideUrl, setOverrideUrl] = useState(get(item, "overrideUrl", ""));
  const [metaDescription, setMetaDescription] = useState(get(item, "metaDescription", ""));

  const [linked, setLinked] = useState(get(item, "artisans", []));
  const [tags, setTags] = useState(get(item, "tags", []));
  const [image, setImage] = useState(get(item, "image", false));
  const [bannerImage, setBannerImage] = useState(get(item, "banner", false));
  const [description, setDescription] = useState(get(item, "description", ""));
  const [bottomDescription, setBottomDescription] = useState(get(item, "bottomDescription", ""));

  const isEdit = !!item;

  const err = typeof error === "object" ? error : {};
  const fullURL = get(item, "fullUrl", "");
  const urlPrefix = item && fullURL.replace(`/${item.url}`, "/");
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;
  const hasDeletePermission = props.userRole.some(role => role === "Super Admin") || !isEdit;

  // Close form after successful submit
  useEffect(() => {
    if (!submitted || processing || error) {
      return;
    }

    if (isEdit) {
      return close();
    }
    history.push({ pathname: location.pathname.replace("/add", "") })
  }, [submitted, processing]); // eslint-disable-line


  // Methods
  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file).then(result => {
      if (type === "image") {
        setImage(result.response);
      } else if (type === "banner") {
        setBannerImage(result.response);
      }
    }).catch(console.warn)
  };

  const onSave = () => {
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }

    setSubmitted(true);

    const callback = () => message.success(`Artisan was successfully ${isEdit ? "updated" : "created"}`);

    const data = {
      isVisible: visible,
      name: name,
      isApproved: approved,
      isSuggested: !approved,
      pseudonym: pseudo || null, // null is meant to handle proper validation on back end
      title: title,
      url: url,
      overridePageTitle: `Antique ${title} For Sale | LoveAntiques.com`,
      overrideHeading: `${title} ${(tags[0] || { name: "" }).name}`,
      overrideSubheader: overrideSubheader,
      overrideUrl: overrideUrl,
      metaDescription: metaDescription,
      artisans: linked.map(el => el.id),
      tags: tags.map(tag => tag.id),
      description: description,
      bottomDescription: bottomDescription,
    };
    if (image) {
      data.image = image;
    }
    if (bannerImage) {
      data.banner = bannerImage;
    }

    if (isEdit) {
      return props.updateArtisans(oldUrlBeforeEdit, data, callback);
    }
    props.createArtisans(data, callback);
  };


  // Render

  return (
    <div className="general-form">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"} Artisan</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row general-form--checkbox-holder general-form--checkbox-holder-artisan">
        <Checkbox className="checkbox-small" checked={visible} onChange={setVisible}>Active</Checkbox>
        <Checkbox className="checkbox-small" checked={approved} onChange={e => setApproved(e.target.checked)}>Approved</Checkbox>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput label="Category Name" value={name} onChange={setName} isRequired errorText={err.name} />
          <LabeledInput label="Pseudonym" value={pseudo} onChange={setPseudo} />
          <LabeledInput label="Title" value={title} onChange={setTitle} isRequired errorText={err.title} />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="URL"
            value={url}
            onChange={setUrl}
            suffix={urlFieldImage}
            addonBefore={urlPrefix ? urlPrefix : "https://www.loveantiques.com/"}
            inputClassName="input-with-prefix"
            errorText={err.url}
          />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="Override Page Title"
            value={get(item, "sfName", title && `Antique ${title} For Sale | LoveAntiques.com`)}
            readonly
          />
          <LabeledInput
            label="Override Heading"
            value={`${title} ${(tags[0] || { name: "" }).name}`}
            readonly
          />

          <LabeledInput label="Override Subheader" value={overrideSubheader} onChange={setOverrideSubheader} />
        </div>

        <div className="general-form--column">
          <LabeledInput label="Override URL" value={overrideUrl} onChange={setOverrideUrl} icon={<img src="/images/link_3x.svg" alt="" />} />
          <LabeledInput label="Override Meta Description" type="textarea" value={metaDescription} onChange={setMetaDescription} />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <AddEditArtisansLinkedField linked={linked} setLinked={setLinked} />
          <AddEditArtisansTagsField tags={tags} setTags={setTags} />
        </div>

        <div className="general-form--column" />
      </div>

      <div className="general-form--row general-form--row__align-start">
        <div className="add-catalog-entity--image-picker">
          <ImageUpload
            lastUploadedImage={image}
            setImage={file => uploadImage(file, "image")}
            topText="Category Image"
            bottomText="Image must be at least 300 x 300 pixels"
            width={256}
            height={256}
          />
        </div>

        <ImageUpload
          lastUploadedImage={bannerImage}
          setImage={file => uploadImage(file, "banner")}
          topText="Category Banner"
          bottomText="Image must be at least 1420 x 440 pixels"
          width={826}
          height={256}
        />
      </div>

      <LabeledInput label="Description" type="rich-text" value={description} onChange={setDescription} />
      <LabeledInput label="Bottom Description" type="rich-text" value={bottomDescription} onChange={setBottomDescription} />

      <div className="general-form--button-holder">
        <Button className="ant-btn-primary" onClick={onSave} loading={processing}>
          SAVE & CLOSE
        </Button>

        <Popconfirm
          placement="top"
          title="Are you sure you want to delete this category?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => remove()}
          disabled={!hasDeletePermission || !isEdit}
        >
          <Button
            disabled={!hasDeletePermission}
            {...(!isEdit && {
              onClick: () => history.push({
                pathname: location.pathname.replace("/add", ""),
              }),
            })}
          >
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

AddEditArtisans.propTypes = {
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  createArtisans: PropTypes.func.isRequired,
  updateArtisans: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    processing: state.artisans.processing,
    error: state.artisans.error,
    userRole: state.auth.userProfile.roles
  }),
  dispatch => ({
    createArtisans: (data, callback) => dispatch(createArtisansRequest(data, callback)),
    updateArtisans: (url, data, callback) => dispatch(updateArtisansRequest(url, data, false, callback)),
  })
)(AddEditArtisans);

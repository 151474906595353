import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Checkbox, Button, Select, Spin, Empty, message } from "antd";
import moment from "moment";
import debounce from "lodash/debounce";

//components
import ArticlePreview from "./ArticlePreview";
import TabNavigation from "../../../shared/TabNavigation";
import LabeledInput from "../../../shared/LabeledInput";
import ImageUpload from "../../../shared/ImageUpload";

//redux
import {
  createNewArticleRequest,
  uploadImageToServer,
  getBlogCategoriesRequest,
  getBlogAuthorsRequest
} from "../../../../redux/content/blog/blogActions";

//helpers
import contentApi from "../../../../api/content";


const AddNewArticle = ({
  error,
  history,
  createNewArticle,
  uploadImage,
  lastUploadedImage,
  getBlogCategories,
  getBlogAuthors,
  categories: categoriesForSelect,
  authors: authorsForSelect
}) => {
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [articleImage, setArticleImage] = useState(null);
  const [notIndexArticle, setNotIndexArticle] = useState(false);

  const [searchingCategories, setSearchingCategories] = useState(false);
  const [existingCategories, setExistingCategories] = useState([]);
  const [searchingAuthors, setSearchingAuthors] = useState(false);
  const [existingAuthors, setExistingAuthors] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");

  // helpers
  const transformTitleToURL = () => {
    const transformedURL = title.toLowerCase().replace(/ /ig, "-").replace(/&/ig, "and");
    setUrl(transformedURL);
  };


  useEffect(() => {
    getBlogCategories({});
    getBlogAuthors({});
  }, []); // eslint-disable-line

  useEffect(() => {
    categoriesForSelect && setExistingCategories(categoriesForSelect);
  }, [categoriesForSelect]); // eslint-disable-line

  useEffect(() => {
    authorsForSelect && setExistingAuthors(authorsForSelect);
  }, [authorsForSelect]); // eslint-disable-line

  // Upload image
  useEffect(() => {
    articleImage && uploadImage(articleImage);
  }, [articleImage]); // eslint-disable-line

  useEffect(() => {
    title && transformTitleToURL();
  }, [title]); // eslint-disable-line


  // Methods
  const onAddBlogSuccess = async () => {
    await message.success("Changes were saved", 2);
    history.push({ pathname: "/content/blog/articles" })
  }

  const onCheck = e => {
    setIsActive(e.target.checked);
  };

  const _createNewArticle = () => {
    const data = {
      isActive,
      title,
      url,
      text: pageContent,
      categories,
      authors,
      meta: metaDescription,
      noIndex: notIndexArticle,
    };
    if (lastUploadedImage) {
      data.image = lastUploadedImage;
    }

    createNewArticle({ data, callback: onAddBlogSuccess });
  };

  const searchCategories = value => {
    if (!value) {
      return;
    }

    setSearchingCategories(true);
    setExistingCategories([]);
    contentApi.getCategories({ page: 1, perPage: 200, search: value }).then(result => {
      setSearchingCategories(false);
      setExistingCategories(result.results);
    }).catch(console.warn)
  };

  const searchCategoriesDebounced = useCallback(debounce(searchCategories, 150), []);

  const searchAuthors = value => {
    if (!value) {
      return;
    }

    setSearchingAuthors(true);
    setExistingAuthors([]);
    contentApi.getAuthors({ page: 1, perPage: 200, search: value }).then(result => {
      setSearchingAuthors(false);
      setExistingAuthors(result.results);
    }).catch(console.warn)
  };
  const searchAuthorsDebounced = useCallback(debounce(searchAuthors, 150), []);


  const todayDate = moment(moment(), "YYYY/MM/DD");
  const currentMonth = todayDate.format("M");
  const currentYear = todayDate.format("YYYY");
  const defaultURL = `https://www.loveantiques.com/blog/${currentYear}/${currentMonth}/`;
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  const serverError = error || {};

  return (
    <>
      <TabNavigation
        items={[
          { label: "ARTICLE", link: "/content/blog/articles/add-new-article" },
          { label: "PREVIEW", link: `/content/blog/articles/add-new-article/preview/${url || "temp"}` },
        ]}
      />
      <Switch>
        <Route
          path="/content/blog/articles/add-new-article/preview/:url"
          render={(props) => <ArticlePreview {...props} currentArticle={{ title, pageContent }} />}
        />
        <Route
          path="/content/blog/articles/add-new-article/"
          render={() => (
            <div className="add-new-article">
              <div className="add-new-article--row">
                <span className="add-new-article--title">Add Article</span>
              </div>

              <div className="edit-article--row-checkboxes checkbox-holder">
                <Checkbox
                  className="checkbox-small"
                  checked={isActive}
                  onChange={onCheck}
                >
                  Active
                </Checkbox>
                <Checkbox
                  className="checkbox-small"
                  checked={notIndexArticle}
                  onChange={(e) => setNotIndexArticle(e.target.checked)}
                >
                  Not index this article
                </Checkbox>
              </div>

              <LabeledInput
                label="Title"
                isRequired
                value={title}
                onChange={setTitle}
                errorText={serverError.title}
              />

              <div className="add-new-article--url-input-holder">
                <LabeledInput
                  label="URL"
                  isRequired
                  value={url}
                  onChange={setUrl}
                  inputClassName="input-with-prefix"
                  addonBefore={defaultURL}
                  suffix={urlFieldImage}
                  errorText={serverError.url}
                />
              </div>

              <div className="add-new-article--categories-select">
                <p className="add-new-article--categories-select-label">
                  Categories
                </p>

                <Select
                  showSearch
                  allowClear
                  onSearch={searchCategoriesDebounced}
                  notFoundContent={
                    searchingCategories ?
                      <Spin size="small" /> :
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
                  }
                  className="labeled-input--input"
                  mode="multiple"
                  value={categories}
                  onChange={setCategories}
                  placeholder="Please select categories"
                  filterOption={false}
                >
                  {
                    existingCategories.map(el => {
                      return <Select.Option key={el.url} value={el.id}>{el.name}</Select.Option>
                    })
                  }
                </Select>
              </div>

              <div className="add-new-article--authors-select">
                <p className="add-new-article--authors-select-label">Authors</p>

                <Select
                  showSearch
                  allowClear
                  onSearch={searchAuthorsDebounced}
                  notFoundContent={
                    searchingAuthors ?
                      <Spin size="small" /> :
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
                  }
                  className="labeled-input--input"
                  mode="multiple"
                  value={authors}
                  onChange={setAuthors}
                  placeholder="Please select categories"
                  filterOption={false}
                >
                  {
                    existingAuthors.map(el => {
                      return <Select.Option key={el.url} value={el.id}>{el.name}</Select.Option>
                    })
                  }
                </Select>
              </div>

              <LabeledInput
                label="Page Content"
                type="rich-text"
                value={pageContent}
                onChange={setPageContent}
                className="add-new-article--page-content-input"
              />

              <LabeledInput
                label="Meta Description"
                type="textarea"
                value={metaDescription}
                onChange={setMetaDescription}
                className="edit-article--page-content-input"
              />

              <ImageUpload
                topText="Cover Image"
                setImage={setArticleImage}
                lastUploadedImage={lastUploadedImage || {}}
                bottomText="Image must be at least 300 x 300 pixels"
                width={256}
                height={256}
              />

              <div className="add-new-article--button-holder">
                <Button className="ant-btn-primary" onClick={_createNewArticle}>
                  SAVE & CLOSE
                </Button>
                <div className="cancel-btn-holder">
                  <Button
                    className="cancel-btn"
                    onClick={() => history.push("/content/blog/articles")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    </>
  );
};

AddNewArticle.propTypes = {
  createNewArticle: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  lastUploadedImage: PropTypes.object,
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  getBlogCategories: PropTypes.func.isRequired,
  getBlogAuthors: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
};

export default connect(
  state => state.blogs,
  dispatch => ({
    createNewArticle: payload => dispatch(createNewArticleRequest(payload)),
    uploadImage: payload => dispatch(uploadImageToServer(payload)),
    getBlogCategories: payload => dispatch(getBlogCategoriesRequest(payload)),
    getBlogAuthors: payload => dispatch(getBlogAuthorsRequest(payload)),
  })
)(AddNewArticle);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import EditIcon from "../../../Icon/EditIcon";
import { Input } from "antd";

import navigation from "../../../../helpers/navigation";

import {
  patchOrderRequest
} from "../../../../redux/orders/ordersActions";


const OrderNotesSection = ({
  order = {},
  location,
  patchOrder
}) => {
  const orderNotes = order && _.get(order, "notes", "");
  const [fieldValue, setFieldValue] = useState(orderNotes);

  useEffect(() => {
    setFieldValue(orderNotes)
  }, [orderNotes]); // eslint-disable-line

  const _patchOrder = () => {
    order.payment.status = order.payment.status === "Completed" ? "COMPLETED" : "UNCOMPLETED";
    order.item = order.item.id;
    order.dealer = order.dealer.id;

    const orderObject = Object.assign(order, { notes: fieldValue });
    const { page, perPage } = navigation.getTableQueryParams(location);

    patchOrder({
      data: orderObject,
      page,
      perPage
    });
  };

  return (
    <div className="order-header--order-notes-wrapper">
      <div className="header-tab-column-title flex">
        Order Notes
        <EditIcon fillColor="#F70501" />
      </div>
      <div className="order-notes-column-info">
        <div className="order-notes-column-info-field">
          <div className="order-notes-field-value">
            <Input.TextArea
              rows={4}
              autosize={true}
              placeholder="Add notes here..."
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={_patchOrder}
              className="order-notes-textarea"
            />
          </div>
        </div>
      </div>
    </div>
  )
};

OrderNotesSection.propTypes = {
  order: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  patchOrder: PropTypes.func.isRequired,
};

export default connect(
  null,
  dispatch => ({
    patchOrder: payload => dispatch(patchOrderRequest(payload))
  })
)(withRouter(OrderNotesSection));

import React from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { StatusColumn } from "../../../components";

// react-queries
import { useFairsRequestList, useFairsRequestPatch, useTradeProfilePatch } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";
import { buildFullName } from "../../../helpers";
import {
  DIRECTORY_REQUEST_CLAIM_STATUSES,
  DIRECTORY_REQUEST_TAKEDOWN_STATUSES,
} from "../../../constants";
import { message } from "antd";
import fetch from "../../../api/customFetch";


export const FairsRequests = () => {
  const location = useLocation();

  const { page, perPage } = navigation.getTableQueryParams(location);

  const { data: fairsRequests, refetch } = useFairsRequestList({
    page: page,
    page_size: perPage
  });

  const updateTradeProfile = useTradeProfilePatch();

  const patchDirectoryRequest = useFairsRequestPatch({
    onSuccess: (resp) => {
      fetch({ url: `/profiles/trade-profiles/?email=${resp?.user?.email}`, method: "GET" })
        .then((users) => {

          /* TODO(LANT-5480): BE */
          if (!users?.results?.[0]?.isFair) {
            updateTradeProfile.mutate({
              id: users.results[0].id,
              isFair: true,
              isFairChanged: true
            })
          }
        });

      refetch().then(() => {
        message.success(`Request was ${resp.status?.toLowerCase() || "updated"} successfully!`);
      });
    }
  });

  return (
    <div className="fairs-requests">
      <Table
        className="crm-table"
        columnWidths={["100px", "200px", "220px", "100px", "auto", "220px"]}
        headerCells={[
          { label: "Status" },
          { label: "Fair details" },
          { label: "Request from" },
          { label: "Action" },
          { label: "Message" },
          { label: "Owner" },
        ]}
      >
        {fairsRequests?.results?.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex pl-16">
                <StatusColumn
                  currentStatus={item.status}
                  statuses={item.variant === "TAKEDOWN" ? DIRECTORY_REQUEST_TAKEDOWN_STATUSES : DIRECTORY_REQUEST_CLAIM_STATUSES}
                  // {...(item.status === "SUBMITTED" && {
                  //   onClick: (status) => patchDirectoryRequest.mutate({ id: item.id, status })
                  // })}
                  onClick={
                    (status) => patchDirectoryRequest.mutate({ id: item.id, status })
                  }
                />
              </div>
              <div className="flex">
                {!!item.fair?.id && (
                  <Link
                    to={`/directory/directory-entries/${item.fair.id}/details`}
                    className="detail-column"
                  >
                    <div className="detail-column--business-name pb-1">
                      {item.fair.name}
                    </div>
                    <div className="detail-column--text">
                      {item.fair.email || ""}
                    </div>
                    <div className="detail-column--text">
                      {item.fair.addressLineOne}
                    </div>
                    <div className="detail-column--text">
                      {item.fair.city}
                    </div>
                    {!!item.fair.ukCounty && (
                      <div className="detail-column--text">
                        {item.fair.ukCounty}
                      </div>
                    )}
                    {!!item.fair.createdAt && (
                      <div className="detail-column--date">
                        {`Since ${moment(item.fair?.createdAt).format("MMMM DD, YYYY")}`}
                      </div>
                    )}
                  </Link>
                )}
              </div>
              <div className="flex">
                <div>
                  {buildFullName(item.user)}
                  <div className="detail-column--text">
                    {item.user?.email || ""}
                  </div>
                </div>
              </div>
              <div className="flex">
                {item.variant}
              </div>
              <div className="flex">
                {item.message}
              </div>
              <div className="flex">
                <div>
                  {buildFullName(item.fair?.owner, item.fair?.owner?.businessName || "-")}
                  <div className="detail-column--text">
                    {item.fair?.email || item.fair?.owner?.user?.email || ""}
                  </div>
                </div>
              </div>
            </TableRow>
          )
        })}
      </Table>

      {fairsRequests?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={fairsRequests.count}
        />
      )}
    </div>
  )
};

import * as types from "./catalogActionTypes";


const initialState = {
  items: {},
  total: 0,
  error: "",
  processing: false,
  loading: false
};


const catalog = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    case types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.url]: action.payload.response.results,
        },
        total: action.payload.response.count
      };

    case types.CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    case types.RESET_ERROR: {
      return {
        ...state,
        error: ""
      }
    }

    default:
      return state;
  }
};

export default catalog;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Modal as ModalWindow,
  Spin,
  Empty,
  message,
} from "antd";
import differenceBy from "lodash/differenceBy";
import debounce from "lodash/debounce";

// components
import { LinkInput } from "../../../../shared/LinkInput";
import LabeledInput from "../../../../shared/LabeledInput";

// redux
import {
  getDealersStatusChoicesRequest,
  getDealerSpecialitiesRequest,
  getDealerCategoriesRequest,
} from "../../../../../redux/dealers/dealersActions";

// react-queries
import { useDealerProfilePatch } from "../../../../../react-query";

// helpers
import dealersAPI from "../../../../../api/dealers";

const DetailsTab = ({
  history,
  getDealersStatusChoices,
  dealerData,
  processing,
  getDealerSpecialities,
  dealerSpecialities,
  getDealerCategories,
  dealerCategories,
}) => {
  const [serverError, setServerError] = useState(null);
  const [dealerTitle, setDealerTitle] = useState();
  const [dealerFirstName, setDealerFirstName] = useState();
  const [dealerLastName, setDealerLastName] = useState();
  const [dealerDescription, setDealerDescription] = useState();
  const [dealerExpertise, setDealerExpertise] = useState();
  const [dealerBusinessName, setDealerBusinessName] = useState();
  const [dealerWebsite, setDealerWebsite] = useState();
  const [dealerEmail, setDealerEmail] = useState();
  const [dealerShowInFeatured, setDealerShowInFeatured] = useState();
  const [dealerPayPalStatus, setDealerPayPalStatus] = useState();
  const [dealerPayPalEmail, setDealerPayPalEmail] = useState();

  const [searchingSpecialities, setSearchingSpecialities] = useState(false);
  const [specialitiesList, setSpecialitiesList] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);

  const [searchingCategories, setSearchingCategories] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [isCertifiedDealer, setIsCertifiedDealer] = useState();
  const [isApprovedListing, setIsApprovedListing] = useState();
  const [isOverTenSales, setIsOverTenSales] = useState();

  const [openConfirmationModal, setOpenConfirmationModal] = useState();

  const goBackToDealers = () => {
    history.push({ pathname: "/dealers/dealers-list" });
  };

  const updateDealerProfile = useDealerProfilePatch({
    onSuccess: (resp) => {
      goBackToDealers();

      message.success(
        `${resp.businessName} info has been successfully updated!`
      );
    },
    onError: (err) => {
      setServerError(err?.data);

      if (
        err?.data?.title ||
        err?.data?.firstName ||
        err?.data?.lastName ||
        err?.data?.website ||
        err?.data?.paypalEmail ||
        err?.data?.categories
      ) {
        const container = document.querySelector(
          ".dealer-details-tab--content"
        );

        if (container) {
          container.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        setOpenConfirmationModal(true);
      }
    },
  });

  useEffect(() => {
    getDealersStatusChoices({});
    getDealerSpecialities({});
    getDealerCategories({});
  }, []); // eslint-disable-line

  // Set categories
  useEffect(() => {
    const {
      title = "Mr",
      firstName,
      lastName,
      description,
      expertise,
      businessName,
      website,
      email,
      showInFeatured,
      isPaypalEnabled,
      paypalEmail,
      certifiedDealer,
      approvedListing,
      overTenSales,
    } = dealerData;

    title && setDealerTitle(title);
    firstName && setDealerFirstName(firstName);
    lastName && setDealerLastName(lastName);
    description && setDealerDescription(description);
    expertise && setDealerExpertise(expertise);
    businessName && setDealerBusinessName(businessName);
    website && setDealerWebsite(website);
    email && setDealerEmail(email);
    paypalEmail && setDealerPayPalEmail(paypalEmail);

    setDealerShowInFeatured(!!showInFeatured);
    setDealerPayPalStatus(!!isPaypalEnabled);
    setIsCertifiedDealer(!!certifiedDealer);
    setIsApprovedListing(!!approvedListing);
    setIsOverTenSales(!!overTenSales);
  }, [dealerData]); // eslint-disable-line

  // Prefill specialities drop down with selected specialities and other 10 specialities

  useEffect(() => {
    const listOfSelectedAndPreFilledSpecialities = [];
    const listOfSelectedAndPreFilledCategories = [];

    const { specialities, categories } = dealerData;

    specialities &&
      setSelectedSpecialities(
        specialities ? specialities.map(({ id }) => id) : []
      );
    categories &&
      setSelectedCategories(categories ? categories.map(({ id }) => id) : []);

    dealerSpecialities &&
      listOfSelectedAndPreFilledSpecialities.push(...dealerSpecialities);
    dealerCategories &&
      listOfSelectedAndPreFilledCategories.push(...dealerCategories);

    if (specialities) {
      // Filter duplicated specialities
      const uniqueSelectedSpecialities = differenceBy(
        specialities,
        dealerSpecialities,
        "id"
      );

      uniqueSelectedSpecialities &&
        listOfSelectedAndPreFilledSpecialities.push(
          ...uniqueSelectedSpecialities
        );
    }

    if (categories) {
      // Filter duplicated categories
      const uniqueSelectedCategories = differenceBy(
        categories,
        dealerCategories,
        "id"
      );

      uniqueSelectedCategories &&
        listOfSelectedAndPreFilledCategories.push(...uniqueSelectedCategories);
    }

    setSpecialitiesList(listOfSelectedAndPreFilledSpecialities);
    setCategoriesList(listOfSelectedAndPreFilledCategories);
  }, [dealerData, dealerSpecialities, dealerCategories]); // eslint-disable-line

  const _patchDealer = () => {
    const data = Object.assign(dealerData, {
      title: dealerTitle,
      firstName: dealerFirstName,
      lastName: dealerLastName,
      description: dealerDescription,
      expertise: dealerExpertise,
      specialities: selectedSpecialities,
      categories: selectedCategories,
      businessName: dealerBusinessName,
      email: dealerEmail,
      showInFeatured: dealerShowInFeatured,
      isPaypalEnabled: dealerPayPalStatus,
      paypalEmail: dealerPayPalEmail,
      certifiedDealer: isCertifiedDealer,
      approvedListing: isApprovedListing,
      overTenSales: isOverTenSales,
      website: dealerWebsite
    });

    updateDealerProfile.mutate(data);
  };

  const searchSpecialities = (value) => {
    if (!value) return;

    setSearchingSpecialities(true);
    setSpecialitiesList([]);
    dealersAPI
      .getDealerSpecialities({ page: 1, perPage: 200, search: value })
      .then((result) => {
        setSearchingSpecialities(false);
        setSpecialitiesList(result.results);
      })
      .catch(console.warn);
  };

  const searchSpecialitiesDebounced = useCallback(
    debounce(searchSpecialities, 150),
    []
  );

  const searchCategories = (value) => {
    if (!value) return;

    setSearchingCategories(true);
    setCategoriesList([]);
    dealersAPI
      .getDealerCategories({ page: 1, perPage: 200, search: value })
      .then((result) => {
        setSearchingCategories(false);
        setCategoriesList(result.results);
      })
      .catch(console.warn);
  };

  const searchCategoriesDebounced = useCallback(
    debounce(searchCategories, 150),
    []
  );

  return (
    <div className="dealer-details-tab">
      <div className="dealer-details-tab--content">
        <div className="dealer-details-tab--name-and-contact-details">
          <div className="dealer-details-tab--name-and-contact-details--title">
            Name & Contact Details
          </div>
          <div className="dealer-details-tab--name-and-contact-details--fields-row">
            <div>
              <div className="dealer-details-tab--name-and-contact-details--left-and-middle-column-wrapper">
                <div className="dealer-details-tab--name-and-contact-details--fields--left-column">
                  <div className="left-column--title-input">
                    <LabeledInput
                      label="Title"
                      value={dealerTitle}
                      onChange={(value) => {
                        setDealerTitle(value);
                        serverError?.title && setServerError(null);
                      }}
                      errorText={serverError?.title}
                    />
                  </div>
                  <div className="left-column--first-name-input">
                    <LabeledInput
                      label="First Name"
                      value={dealerFirstName}
                      onChange={(value) => {
                        setDealerFirstName(value);
                        serverError?.firstName && setServerError(null);
                      }}
                      errorText={serverError?.firstName}
                    />
                  </div>
                  <div className="left-column--last-name-input">
                    <LabeledInput
                      label="Last Name"
                      value={dealerLastName}
                      onChange={(value) => {
                        setDealerLastName(value);
                        serverError?.lastName && setServerError(null);
                      }}
                      errorText={serverError?.lastName}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="dealer-details-tab--name-and-contact-details--fields--right-column">
              <div className="dealer-details-tab--description-field-label">
                Description
              </div>
              <Input.TextArea
                value={dealerDescription}
                onChange={({ target }) => setDealerDescription(target.value)}
                id="dealer-details-tab--description-field"
              />
              <div className="dealer-details-tab--expertise-field-label">
                Expertise
              </div>
              <Input.TextArea
                value={dealerExpertise}
                onChange={({ target }) => setDealerExpertise(target.value)}
                id="dealer-details-tab--expertise-field"
              />
            </div>
          </div>

          <div className="dealer-details-tab--name-and-contact-details--specialities-row">
            <div
              className="dealer-details-tab--specialities-select"
              style={{ width: "100%" }}
            >
              <p className="dealer-details-tab--specialities-select-label">
                Specialities
              </p>
              <Select
                showSearch
                allowClear
                onSearch={searchSpecialitiesDebounced}
                notFoundContent={
                  searchingSpecialities ? (
                    <Spin size="small" />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No data. Type to search"
                    />
                  )
                }
                className="labeled-input--input"
                mode="multiple"
                value={selectedSpecialities}
                onChange={setSelectedSpecialities}
                placeholder="Please select specialities"
                filterOption={false}
              >
                {specialitiesList.map((el) => {
                  return (
                    <Select.Option key={el.url} value={el.id}>
                      {el.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--business-details">
          <div className="dealer-details-tab--business-details--title">
            Business Details
          </div>
          <div className="dealer-details-tab--business-details--content">
            <div className="dealer-details-tab--business-details--content--business-name-input">
              <LabeledInput
                label="Business Name"
                value={dealerBusinessName}
                onChange={setDealerBusinessName}
              />
            </div>
            <div className="dealer-details-tab--name-and-contact-details--specialities-row">
              <div
                className="dealer-details-tab--specialities-select"
                style={{ width: "100%" }}
              >
                <p className="dealer-details-tab--specialities-select-label">
                  Type
                </p>
                <Select
                  showSearch
                  allowClear
                  onSearch={searchCategoriesDebounced}
                  notFoundContent={
                    searchingCategories ? (
                      <Spin size="small" />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No data. Type to search"
                      />
                    )
                  }
                  className="labeled-input--input"
                  mode="multiple"
                  value={selectedCategories}
                  onChange={(value) => {
                    setSelectedCategories(value);

                    serverError?.categories && setServerError(null);
                  }}
                  placeholder="Please select type"
                  filterOption={false}
                >
                  {categoriesList.map((el) => {
                    return (
                      <Select.Option key={el.id} value={el.id}>
                        {el.title}
                      </Select.Option>
                    );
                  })}
                </Select>
                {serverError?.categories && (
                  <p className="labeled-input--error">
                    {serverError.categories}
                  </p>
                )}
              </div>
            </div>
            <div className="dealer-details-tab--business-details--content--wesite-and-email-row">
              <div className="dealer-details-tab--business-details--content--website-input">
                <LinkInput
                  value={dealerWebsite}
                  errorText={serverError?.website}
                  onChange={(value) => {
                    setDealerWebsite(value);
                    serverError?.website && setServerError(null);
                  }}
                />
              </div>
              <div className="dealer-details-tab--business-details--content--email-input">
                <LabeledInput
                  label="Log in Email"
                  type="email"
                  value={dealerEmail}
                  onChange={(value) => {
                    setDealerEmail(value);
                    serverError?.email && setServerError(null);
                  }}
                  errorText={serverError?.email}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--featured-dealer">
          <div className="dealer-details-tab--featured-dealer--title">
            Featured Dealer
          </div>
          <div className="dealer-details-tab--featured-dealer--content">
            <div className="dealer-details-tab--featured-dealer--content--checkbox-row">
              <Checkbox
                checked={dealerShowInFeatured}
                onChange={({ target }) => setDealerShowInFeatured(target.checked)
                }
              />
              <div className="dealer-details-tab--featured-dealer--content--checkbox-label">
                Show in Featured
              </div>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--paypal-details">
          <div className="dealer-details-tab--paypal-details--title">
            PayPal Details
          </div>
          <div className="dealer-details-tab--paypal-details--content">
            <div className="dealer-details-tab--paypal-details--content--pay-pal-status">
              <Radio.Group
                onChange={({ target }) => setDealerPayPalStatus(target.value)}
                value={dealerPayPalStatus}
              >
                <Radio value={true}>Enabled</Radio>
                <Radio value={false}>Disabled</Radio>
              </Radio.Group>
            </div>
            <div className="dealer-details-tab--paypal-details--content--pay-pal-email-input">
              <LabeledInput
                label="PayPal Email Address"
                value={dealerPayPalEmail}
                onChange={(value) => {
                  setDealerPayPalEmail(value);
                  serverError?.paypalEmail && setServerError(null);
                }}
                errorText={serverError?.paypalEmail}
              />
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--certified-dealer">
          <div className="dealer-details-tab--certified-dealer--title">
            Certified Dealer
          </div>
          <div className="dealer-details-tab--certified-dealer--content">
            <div className="dealer-details-tab--certified-dealer--content--pay-pal-status">
              <Radio.Group
                onChange={({ target }) => setIsCertifiedDealer(target.value)}
                value={isCertifiedDealer}
              >
                <Radio value={true}>Enabled</Radio>
                <Radio value={false}>Disabled</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--approved-listing">
          <div className="dealer-details-tab--approved-listing--title">
            Approved Listing
          </div>
          <div className="dealer-details-tab--approved-listing--content">
            <div className="dealer-details-tab--approved-listing--content--pay-pal-status">
              <Radio.Group
                onChange={({ target }) => setIsApprovedListing(target.value)}
                value={isApprovedListing}
              >
                <Radio value={true}>Enabled</Radio>
                <Radio value={false}>Disabled</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--sales-count">
          <div className="dealer-details-tab--sales-count--title">
            Is Over 10 Sales
          </div>
          <div className="dealer-details-tab--sales-count--content">
            <div className="dealer-details-tab--sales-count--content--pay-pal-status">
              <Radio.Group
                onChange={({ target }) => setIsOverTenSales(target.value)}
                value={isOverTenSales}
              >
                <Radio value={true}>Enabled</Radio>
                <Radio value={false}>Disabled</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="dealer-details-tab--action-buttons">
          <div className="dealer-details-tab--action-buttons--save-button">
            <Button
              className="ant-btn-primary"
              onClick={_patchDealer}
              loading={processing}
            >
              SAVE & CLOSE
            </Button>
          </div>

          <Button onClick={goBackToDealers}>CANCEL</Button>
        </div>

        <ModalWindow
          className="modal-block patch-dealer-details-modal"
          centered
          open={openConfirmationModal}
          onCancel={() => setOpenConfirmationModal(false)}
          onOk={() => setOpenConfirmationModal(false)}
          title={
            <div className="patch-dealer-details-modal--info">
              <div className="patch-dealer-details-modal--warning">
                {serverError?.email ||
                  serverError?.nonFieldErrors?.[0] ||
                  "Oops, something went wrong! Please try again."}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

DetailsTab.propTypes = {
  match: PropTypes.object.isRequired,
  dealerData: PropTypes.object.isRequired,
  getDealersStatusChoices: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  getDealerSpecialities: PropTypes.func.isRequired,
  dealerSpecialities: PropTypes.array.isRequired,
  getDealerCategories: PropTypes.func.isRequired,
  dealerCategories: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({
    dealerData: state.dealers.dealerData,
    processing: state.dealers.processing,
    dealerSpecialities: state.dealers.dealerSpecialities,
    dealerCategories: state.dealers.dealerCategories,
  }),
  (dispatch) => ({
    getDealersStatusChoices: (payload) => dispatch(getDealersStatusChoicesRequest(payload)),
    getDealerSpecialities: (payload) => dispatch(getDealerSpecialitiesRequest(payload)),
    getDealerCategories: (payload) => dispatch(getDealerCategoriesRequest(payload)),
  })
)(withRouter(React.memo(DetailsTab)));

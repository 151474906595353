import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Pagination } from "antd";
import { connect } from "react-redux";

import AccountPreview from "./AccountPreview";
import AddButton from "../../shared/AddButton";
import { getAdminAccountsRequest } from "../../../redux/settings/adminAccounts/adminAccountsActions";
import navigation from "../../../helpers/navigation";


function AdminAccountsList({ adminAccounts, total, getAdminAccounts, location, history, userRole }) {
  const { page } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  useEffect(() => {
    getAdminAccounts({ page, perPage: 6 });
  }, [page]); // eslint-disable-line


  const onPageChange = newPage => {
    navigation.setPage(newPage, location, history);
  };


  return (
    <div className="admin-accounts">
      <div className="admin-accounts--add-button">
        {!isDisabled && (
          <AddButton onClick={() => history.push("/settings/admin-accounts/add-new-admin-account")} />
        )}
      </div>

      <div className="admin-accounts--accounts-row">
        {
          adminAccounts.map(
            ({
              id,
              firstName,
              lastName,
              nickname,
              phone,
              mobile,
              email,
              roles,
              lastLoginDate,
              image
            }) => {
              return (
                <AccountPreview
                  key={`${id}-admin-account`}
                  id={id}
                  history={history}
                  firstName={firstName}
                  lastName={lastName}
                  nickName={nickname}
                  role={roles}
                  email={email}
                  phone={phone}
                  mobile={mobile}
                  lastLoginDate={lastLoginDate}
                  image={image}
                />
              )
            }
          )}
      </div>

      <div className="admin-accounts--pagination">
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          hideOnSinglePage
          onChange={onPageChange}
        />
      </div>
    </div>
  )
}

AdminAccountsList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getAdminAccounts: PropTypes.func.isRequired,
  adminAccounts: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  userRole: PropTypes.array.isRequired,
};


export default connect(
  state => ({
    adminAccounts: state.adminAccounts.adminAccounts,
    total: state.adminAccounts.total,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getAdminAccounts: payload => dispatch(getAdminAccountsRequest(payload))
  })
)(AdminAccountsList);

export const GET_INVOICE_LIST_REQUEST = "GET_INVOICE_LIST_REQUEST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_ERROR = "GET_INVOICE_LIST_ERROR";

export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";

export const GET_INVOICE_PDF_REQUEST = "GET_INVOICE_PDF_REQUEST";
export const GET_INVOICE_PDF_REQUEST_ERROR = "GET_INVOICE_PDF_REQUEST_ERROR";
export const GET_INVOICE_PDF_REQUEST_SUCCESS = "GET_INVOICE_PDF_REQUEST_SUCCESS";

export const TOGGLE_INVOICES_LOADING = "TOGGLE_INVOICES_LOADING";
export const TOGGLE_INVOICES_PROCESSING = "TOGGLE_INVOICES_PROCESSING";

import * as types from "./paymentsActionsTypes";

export const getPayPalRequest = () => {
  return { type: types.GET_PAYPAL_REQUEST };
};

export const patchPayPalRequest = payload => {
  return { type: types.PATCH_PAYPAL_REQUEST, payload };
};

export const resetErrorsAction = () => {
  return { type: types.RESET_ERRORS };
};

import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (data) => {
  return fetch({
    url: "/subscriptions/promocodes/",
    method: "POST",
    data,
  });
};

export const usePromoCodesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["directory", "promo-codes", "list"]);

      typeof options?.onSuccess === "function" &&
        options.onSuccess(resp, ...args);
    },
  });
};

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import EnquiryActivity from "./EnquiryActivity/EnquiryActivity";
import EmailEnquiries from "./EmailEnquiries/EmailEnquiries";
import PhoneEnquiries from "./PhoneEnquiries/PhoneEnquiries";
import AddEditBlacklisted from "./Blacklists/AddEditBlacklisted";
import Blacklists from "./Blacklists/Blacklists";
import DeliveryEnquiries from "./DeliveryEnquiries";


function Enquiries() {
  return (
    <div className="component">
      <Switch>
        <Route path="/enquiries/enquiry-activity" component={EnquiryActivity} />
        <Route path="/enquiries/email-enquiries" component={EmailEnquiries} />
        <Route path="/enquiries/phone-enquiries" component={PhoneEnquiries} />
        {/*<Route path="/enquiries/manual-phone-api" component={EnquiryActivity} />*/}
        <Route path="/enquiries/blacklists/blacklisted/add" component={AddEditBlacklisted} />
        <Route path="/enquiries/blacklists" component={Blacklists} />
        <Route path="/enquiries/enquiry-delivery" component={DeliveryEnquiries} />
        <Redirect to="/enquiries/enquiry-activity" />
      </Switch>
    </div>
  )
}

export default Enquiries;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Checkbox } from "antd";
import moment from "moment";
import PropTypes from "prop-types";

import navigation from "../../../../helpers/navigation";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";

import { Utils } from "../../../../helpers";
import {
  getCurrencyRatesRequest,
  updateCurrencyRatesRequest,
} from "../../../../redux/settings/currency/currencyRates/currencyRatesActions";


function CurrencyRates({ items, totalItems, getCurrencyRates, updateCurrencyRates }) {
  const location = useLocation();

  const { page, perPage, order } = navigation.getTableQueryParams(location);


  // Pagination api call here
  useEffect(() => {
    getCurrencyRates({ page, perPage });
  }, [page, perPage]); // eslint-disable-line


  // Methods
  const onChangeActive = (event, id) => {
    updateCurrencyRates(id, { isActive: event.target.checked });
  };


  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="currency">
      <Table
        columnWidths={["40px", "90px", "100px", "75px", "75px", "100px", "20%", "auto"]}
        headerCells={[
          {},
          { label: "Rate ID" },
          { label: "Sequence" },
          { label: "From" },
          { label: "To" },
          { label: "Rate" },
          { label: "Date" },
          { label: "DLM" },
        ]}
      >
        {
          itemsToRender.map(item => {
            return (
              <TableRow key={`group:${item.id}`}>
                <Checkbox checked={item.isActive} onChange={e => onChangeActive(e, item.id)} />
                <div className="flex table-sm-text">{item.id}</div>
                <div className="flex table-sm-text">sec</div>
                <div className="flex table-text">{item.fromCurrency.currency}</div>
                <div className="flex table-text">{item.toCurrency.currency}</div>
                <div className="flex table-text">{item.rate.toFixed(6)}</div>
                <div className="flex table-sm-text">{moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                <div className="flex table-sm-text">{moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
              </TableRow>
            )
          })
        }
      </Table>

      {
        totalItems && (
          <TableFooter
            page={page}
            perPage={perPage}
            total={totalItems}
          />
        )
      }
    </div>
  )
}

CurrencyRates.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  getCurrencyRates: PropTypes.func.isRequired,
  updateCurrencyRates: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    items: state.currencyRates.items,
    totalItems: state.currencyRates.total,
  }),
  dispatch => ({
    getCurrencyRates: params => dispatch(getCurrencyRatesRequest(params)),
    updateCurrencyRates: (id, data) => dispatch(updateCurrencyRatesRequest(id, data, true)),
  })
)(CurrencyRates);

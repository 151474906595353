import { all, put, call, takeLatest } from "redux-saga/effects";

import currency from "../../../../api/currency";
import * as types from "./currencyRatesActionsTypes";
import navigation from "../../../../helpers/navigation";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(currency.getRates, params);
    yield put({ type: types.GET_CURRENCY_RATES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CURRENCY_RATES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *update ({ data, id }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(currency.updateRatesPatch, id, data);

    yield put({ type: types.GET_CURRENCY_RATES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.GET_CURRENCY_RATES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_CURRENCY_RATES_REQUEST, get),
    yield takeLatest(types.UPDATE_CURRENCY_RATES_REQUEST, update),
  ])
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import CustomDateRangePicker from "../../shared/CustomDateRangePicker";

import { getEnquiryActivityRequest } from "../../../redux/enquiries/enquiryActivity/enquiryActivityActions";

import { buildFullName, Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";


function EnquiryActivity(props) {
  const { items, totalItems, location, history } = props;
  const { search, page, perPage, order, dateFilter } = navigation.getTableQueryParams(location);

  const dateRange = {};
  if (dateFilter) {
    const [start, end] = dateFilter.split("_");
    if (start) dateRange.startDate = moment(start);
    if (end) dateRange.endDate = moment(end);
  }

  useEffect(() => {
    props.getEnquiryActivity({ page, perPage, search, dateFilter });
  }, [page, perPage, search, dateFilter]); // eslint-disable-line

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="enquiries">
      <TableAndFormLayout showForm={false}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="flex enquiries--filter-holder">
              <CustomDateRangePicker dateFilter={dateFilter} />
            </div>

            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["auto", "85px", "125px", "160px", "220px", "85px", "120px"]}
            headerCells={[
              { label: "Dealer" },
              { label: "Orders" },
              { label: "Email Enquiry" },
              { label: "Call Dealer Enquiry" },
              { label: "Website Dealer Email Enquiry" },
              { label: "Total" },
              { label: "Date" },
            ]}
          >
            {
              itemsToRender.map(item => {
                return (
                  <TableRow key={`group:${item.id}`}>
                    <div className="flex table-text">{buildFullName(item.dealer)}</div>
                    <div className="flex table-sm-text">{item.orders}</div>
                    <div className="flex table-sm-text">{item.emailEnquiry}</div>
                    <div className="flex table-sm-text">{item.callDealerEnquiry}</div>
                    <div className="flex table-sm-text">{item.websiteDealerEmailEnquiry}</div>
                    <div className="flex table-text">{item.total}</div>
                    <div className="flex table-sm-text">{moment(item.date).format("DD/MM/YYYY")}</div>
                  </TableRow>
                )
              })
            }

            {
              totalItems &&
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            }
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
}

EnquiryActivity.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEnquiryActivity: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    items: state.enquiryActivity.items,
    totalItems: state.enquiryActivity.total,
  }),
  dispatch => ({
    getEnquiryActivity: params => dispatch(getEnquiryActivityRequest(params)),
  })
)(EnquiryActivity);

import * as types from "./groupsActionsTypes";


export const getGroupsRequest = () => {
  return { type: types.GET_GROUPS_REQUEST };
};

export const createGroupRequest = data => {
  return { type: types.CREATE_GROUP_REQUEST, data };
};

export const updateGroupRequest = (url, data, isPatch = false) => {
  return { type: types.UPDATE_GROUP_REQUEST, data, url, isPatch };
};

export const deleteGroupRequest = url => {
  return { type: types.DELETE_GROUP_REQUEST, url };
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR }
};

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/directory/categories/${id}/`, method: "GET" });
};

export const useDirectoryCategory = (id, options) => {
  return useQuery(["directory-category", id], () => fetcher(id), options);
};

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import lodash from "lodash";
import { Avatar } from "antd";

// components
import TableRow from "../../shared/Table/TableRow";

// helpers
import formatPrice from "../../../helpers/formatPrice";
import { selectCurrencySymbol } from "../../../helpers/currency";

function DeliveryEnquiriesRow({
  columnWidths,
  enquiry,
}) {
  const { item, dealer, contactNumber, email, name, text } = enquiry;

  let img = "";
  if (item && item.images && item.images.length) {
    const image = lodash.find(item.images, { isMain: true });
    if (image) img = image.url;
  }

  return (
    <div className="w-100" key={`group:${enquiry.id}`}>
      <TableRow columnWidths={columnWidths} className="email-enq--table-row">
        <div className="flex table-text">
          {moment(enquiry.createdAt).format("ddd D MMM YYYY HH:mm")}
        </div>

        <div className="flex email-enq--item-block">
          <Avatar className="table-image" src={img} size={80} shape="square" />
          <div>
            <div className="table-text">{item && item.title ? item.title : ""}</div>
            <div className="flex table-text">{item && `${selectCurrencySymbol(item?.currency)}${formatPrice(+item.price)}`}</div>
          </div>
        </div>

        <div className="flex enquiries--column-cell email-enq--dealer-column">
          <span className="table-text">{dealer.businessName}</span>
          <span className="table-text">{dealer.email}</span>
        </div>

        <div className="flex enquiries--column-cell">
          <span className="table-text">{name}</span>
          <span className="table-text">{email}</span>
          <span className="table-text">{contactNumber}</span>
        </div>
        <div className="flex enquiries--column-cell">
          <span className="table-text">{ text }</span>
        </div>
      </TableRow>
    </div>
  )
}

DeliveryEnquiriesRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  enquiry: PropTypes.object.isRequired,
};

export default DeliveryEnquiriesRow;

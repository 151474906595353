import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./adminAccountsActionsTypes";
import admins from "../../../api/admins";


function *getAdminAccounts(action) {
  try {
    const response = yield call(admins.getAdminAccounts, action.payload);
    yield put({ type: types.GET_ADMIN_ACCOUNTS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ADMIN_ACCOUNTS_REQUEST_ERROR, payload: error.message });
  }
}

function *deleteAdminAccount(action) {
  try {
    const { id } = action.payload;
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(admins.deleteAdminAccount, id);
    yield put({ type: types.DELETE_ADMIN_ACCOUNT_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.DELETE_ADMIN_ACCOUNT_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *updateAdminAccount(action) {
  try {
    const { admin } = action.payload;

    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(admins.updateAdminAccount, admin);
    yield put({ type: types.UPDATE_ADMIN_ACCOUNT_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.UPDATE_ADMIN_ACCOUNT_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *addAdminAccount(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(admins.addAdminAccount, action.payload);
    yield put({ type: types.ADD_ADMIN_ACCOUNT_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_ADMIN_ACCOUNT_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *getAdminRoles() {
  try {
    const response = yield call(admins.getAdminRoles);
    yield put({ type: types.GET_ADMIN_ROLES_REQUEST_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_ADMIN_ROLES_REQUEST_SUCCESS, payload: error });
  }
}

function *getAdminAccountsHistory(action) {
  try {
    yield put({ type: types.SHOW_LOADING });
    const response = yield call(admins.getAdminHistory, action.payload);
    yield put({ type: types.GET_ADMIN_ACCOUNTS_HISTORY_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ADMIN_ACCOUNTS_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function *searchAdmins(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    const response = yield call(admins.searchAdmins, action.payload);
    yield put({ type: types.SEARCH_ADMINS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SEARCH_ADMINS_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function *resetPassword(action) {
  const { id, data } = action.payload;

  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    const response = yield call(admins.resetPassword, id, data);
    yield put({ type: types.RESET_PASSWORD_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_ADMIN_ACCOUNTS_REQUEST, getAdminAccounts),
    yield takeLatest(types.DELETE_ADMIN_ACCOUNT_REQUEST, deleteAdminAccount),
    yield takeLatest(types.UPDATE_ADMIN_ACCOUNT_REQUEST, updateAdminAccount),
    yield takeLatest(types.GET_ADMIN_ROLES_REQUEST, getAdminRoles),
    yield takeLatest(types.ADD_ADMIN_ACCOUNT_REQUEST, addAdminAccount),
    yield takeLatest(types.GET_ADMIN_ACCOUNTS_HISTORY_REQUEST, getAdminAccountsHistory),
    yield takeLatest(types.SEARCH_ADMINS_REQUEST, searchAdmins),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
  ])
}

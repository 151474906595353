import React from "react";
import PropTypes from "prop-types";


function EmailIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="16px" height="15px" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Items-With-Default-Cat" transform="translate(-1098.000000, -188.000000)" fill={fillColor}>
          <g id="icon/24/edit-copy-85-icon/24/email" transform="translate(1094.000000, 183.000000)">
            <path d="M4.8,5 L19.2,5 C19.6418278,5 20,5.37309604 20,5.83333333 L20,19.1666667 C20,19.626904 19.6418278,20 19.2,20 L4.8,20 C4.3581722,20 4,19.626904 4,19.1666667 L4,5.83333333 C4,5.37309604 4.3581722,5 4.8,5 Z M18.4,8.53166667 L12.0576,14.4483333 L5.6,8.51333333 L5.6,18.3333333 L18.4,18.3333333 L18.4,8.53166667 Z M6.0088,6.66666667 L12.0488,12.2183333 L18.0016,6.66666667 L6.0088,6.66666667 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

EmailIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default EmailIcon

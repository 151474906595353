import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import TabNavigation from "../../shared/TabNavigation";
import OffersOffers from "./OffersOffers/OffersOffers";
import AddNewOffer from "./OffersOffers/AddNewOffer";
import OffersUserOffers from "./OffersUserOffers/OffersUserOffers";
import AddNewUserOffer from "./OffersUserOffers/AddNewUserOffer";


function Offers({ location }) {
  let tabNavigator = null;
  const addOffersLinks = ["/settings/offers/add", "/settings/offers/user-offers/add"];

  if (!addOffersLinks.includes(location.pathname)) {
    tabNavigator = (
      <TabNavigation
        items={[
          { label: "OFFERS", link: "/settings/offers" },
          { label: "USER OFFERS", link: "/settings/offers/user-offers" },
        ]}
      />
    )
  }

  return (
    <div className="component">
      { tabNavigator }

      <Switch>
        <Route exact path="/settings/offers" component={OffersOffers} />
        <Route path="/settings/offers/add" component={AddNewOffer} />
        <Route exact path="/settings/offers/user-offers" component={OffersUserOffers} />
        <Route path="/settings/offers/user-offers/add" component={AddNewUserOffer} />
        <Redirect to="/settings/offers" />
      </Switch>
    </div>
  )
}

Offers.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Offers;

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import DomainRequests from "./DomainRequests/DomainRequests";
import PublishRequests from "./PublishRequests/PublishRequests";
import DealerWebsite from "./DealerWebsite/DealerWebsite";

const WebsiteBuilder = () => {
  return (
    <div className="component">
      <Switch>
        <Route path="/website-builder/domain-requests" component={DomainRequests} />
        <Route path="/website-builder/publish-requests" component={PublishRequests} />
        <Route path="/website-builder/dealer-website" component={DealerWebsite} />
        <Redirect to="/website-builder/domain-requests" />
      </Switch>
    </div>
  )
};

export default WebsiteBuilder;

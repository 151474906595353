import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { ChromePicker } from "react-color";
import { Button, Modal as ModalWindow, message } from "antd";

import ImageUpload from "../../shared/ImageUpload";
import LabeledInput from "../../shared/LabeledInput";
import KeyIcon from "../../Icon/KeyIcon";
import DeleteIcon from "../../Icon/DeleteIcon";
import helper from "./helpers/AdminHelper";

import {
  deleteAdminAccountRequest,
  updateAdminAccountRequest,
  getAdminRolesRequest,
} from "../../../redux/settings/adminAccounts/adminAccountsActions";
import { forgotPasswordRequest } from "../../../redux/auth/authActions";
import { useClickOutside } from "../../../hooks";
import adminsAPI from "../../../api/admins";
import contentAPI from "../../../api/content";


const EditAdminAccount = ({
  history,
  processing,
  error,
  match,
  deleteAdminAccount,
  updateAdminAccount,
  getAdminRoles,
  adminRoles,
  forgotPassword,
  resetPasswordError,
  resetPasswordProcessing,
}) => {
  const [confirmationAccountField, setConfirmationAccountField] = useState();
  const [loginAccountValue, setLoginAccountValue] = useState();
  const [accountFirstName, setAccountFirstName] = useState();
  const [accountLastName, setAccountLastName] = useState();
  const [accountNickName, setAccountNickName] = useState();
  const [accountRole, setAccountRole] = useState();
  const [accountEmail, setAccountEmail] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [accountMobile, setAccountMobile] = useState();
  const [accountLastActivity, setAccountLastActivity] = useState();
  const [accountImage, setAccountImage] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [resetPasswordBtnPressed, setResetPasswordBtnPressed] = useState(false);
  const [color, setColor] = useState("");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const ref = useRef(null);

  useClickOutside(ref, () => setColorPickerOpen(false));

  // Get selected admin info
  useEffect(() => {
    adminsAPI.getOne(match.params.id).then(adminAccountData => {
      const confirmation = _.get(adminAccountData, "confirmation");
      const isActive = _.get(adminAccountData, "isActive");
      const firstName = _.get(adminAccountData, "firstName", "");
      const lastName = _.get(adminAccountData, "lastName", "");
      const nickName = _.get(adminAccountData, "nickname", "");
      const roles = _.get(adminAccountData, "roles");
      const email = _.get(adminAccountData, "email");
      const phone = _.get(adminAccountData, "phone");
      const mobile = _.get(adminAccountData, "mobile");
      const lastActivity = _.get(adminAccountData, "lastActivity");
      const image = _.get(adminAccountData, "image", false);
      const colorValue = _.get(adminAccountData, "color", "");
      const adminRole = adminRoles && adminRoles.find(({ name }) => {
        const role = roles && roles[0];

        return name === role;
      });

      setConfirmationAccountField(confirmation);
      setLoginAccountValue(isActive);
      setAccountFirstName(firstName);
      setAccountLastName(lastName);
      setAccountNickName(nickName);
      setAccountRole(adminRole && adminRole.id);
      setAccountEmail(email);
      setAccountPhone(phone);
      setAccountMobile(mobile);
      setAccountLastActivity(lastActivity);
      setAccountImage(image);
      setColor(colorValue);
    })
  }, []); // eslint-disable-line

  useEffect(() => {
    getAdminRoles();
  }, []); // eslint-disable-line

  // Mark form as submitted
  useEffect(() => {
    resetPasswordError && message.error("Oops, something went wrong! Please try again.", 3);
  }, [resetPasswordError]); // eslint-disable-line

  useEffect(() => {
    if (!processing) return;
    setOpenConfirmationModal(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!resetPasswordProcessing && !resetPasswordError && resetPasswordBtnPressed) {
      message.success("Password reset email was successfully sent!", 3);
      setResetPasswordBtnPressed(false);
    }
  }, [resetPasswordProcessing, resetPasswordBtnPressed, resetPasswordError]); // eslint-disable-line


  // Methods
  const validationHandler = fields => {
    const errors = {};
    Object.keys(fields).forEach(key => {
      if (!fields[key]) errors[key] = "Field is required";
    });
    setValidationErrors(errors);

    return !Object.keys(errors).length;
  };

  const uploadImage = (file, type) => {
    contentAPI.uploadNewImage(file).then(result => {
      if (type === "image") setAccountImage(result.response);
    }).catch(console.warn)
  };

  const fieldOnChange = (setValue, key) => value => {
    const copyValidationErrors = { ...validationErrors };
    delete copyValidationErrors[key];
    setValidationErrors(copyValidationErrors);

    setValue(value)
  };

  const _updateAdminAccount = () => {
    const requiredFields = {
      email: accountEmail,
      firstName: accountFirstName,
      lastName: accountLastName,
      role: accountRole,
    };
    const admin = {
      ...requiredFields,
      color,
      mobile: accountMobile,
      phone: accountPhone,
      isActive: loginAccountValue,
      nickname: accountNickName,
      confirmation: confirmationAccountField,
      id: match.params.id,
    };

    if (accountImage) admin.image = accountImage;
    if (validationHandler(requiredFields)) updateAdminAccount({ admin });
  };

  const _resetPassword = () => {
    forgotPassword({ email: accountEmail, url: `${window.location.origin}/auth/reset-password` });
    setResetPasswordBtnPressed(true);
  };

  const _deleteAdminAccount = () => {
    deleteAdminAccount({ id: match.params.id });
  };

  const onOkModal = () => {
    setOpenConfirmationModal(false);
    if (!error) {
      history.push("/settings/admin-accounts");
    }
  };


  const renderConfirmationModal = () => {
    console.log("error", error);

    const modalErrorMsg = _.get(
      error,
      "nonFieldErrors[0]", "Oops, something went wrong! Please try again."
    );

    return (
      <ModalWindow
        centered
        visible={openConfirmationModal}
        className="modal-block edit-admin-acc-confirmation-modal"
        onCancel={() => setOpenConfirmationModal(false)}
        onOk={onOkModal}
        title={(
          <div className="edit-admin-acc-confirmation-modal--info">
            {
              !error ?
                <div className="edit-admin-acc-confirmation-modal--warning">Admin account info has been successfully updated!</div> :
                <div className="edit-admin-acc-confirmation-modal--warning">{modalErrorMsg}</div>
            }
          </div>
        )}
      />
    )
  };


  // Render

  const err = typeof error === "object" ? error : {};
  const lastActivityTime = accountLastActivity ? moment(accountLastActivity).format("L k:m:s") : "-";

  return (
    <div className="edit-admin-account">
      <div className="edit-admin-account--top-section">
        <div className="edit-admin-account--top-section--account-image-and-info">
          <div className="edit-admin-account--top-section--account-image-and-info--account-image">
            <ImageUpload
              lastUploadedImage={accountImage || {}}
              setImage={file => uploadImage(file, "image")}
              width={65}
              height={65}
            />
          </div>

          <div className="edit-admin-account--top-section--account-image-and-info--account-info">
            <div className="edit-admin-account--top-section--account-image-and-info--account-info--confirmation">
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--label">
                Confirmation
              </div>

              <div className="edit-admin-account--top-section--account-image-and-info--account-info--value">
                {helper.confirmationDoubleToggleButton(confirmationAccountField === "CONFIRMED", "Confirmed", "Unconfirmed", setConfirmationAccountField)}
              </div>
            </div>

            <div className="edit-admin-account--top-section--account-image-and-info--account-info--login">
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--label">Login</div>
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--value">
                {helper.loginDoubleToggleButton(loginAccountValue, "Enabled", "Disabled", setLoginAccountValue)}
              </div>
            </div>

            <div className="edit-admin-account--top-section--account-image-and-info--account-info--last-activity">
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--label">
                Last Activity
              </div>
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--value">
                {lastActivityTime}
              </div>
            </div>
            <div>
              <div className="edit-admin-account--color-input">
                <div className="edit-admin-account--top-section--account-image-and-info--account-info--label">Chart Color</div>
                <div
                  className="edit-admin-account--color-input-preview"
                  style={{ backgroundColor: color }}
                  onClick={() => setColorPickerOpen(!colorPickerOpen)}
                />
                {
                  colorPickerOpen
                    ? (
                      <div ref={ref} className="edit-admin-account--color-picker">
                        <ChromePicker
                          disableAlpha={true}
                          color={color}
                          onChangeComplete={e => setColor(e.hex)}
                        />
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          </div>
        </div>

        <div className="edit-admin-account--top-section--action-buttons">
          <div className="edit-admin-account--top-section--action-buttons--password-reset">
            <Button className="password-reset-btn" onClick={_resetPassword}>
              <KeyIcon/>
              PASSWORD RESET
            </Button>
          </div>

          <div className="edit-admin-account--top-section--action-buttons--delete admin">
            <Button className="delete-admin-btn" onClick={_deleteAdminAccount}>
              <DeleteIcon/>
              DELETE ADMIN
            </Button>
          </div>
        </div>
      </div>

      <div className="edit-admin-account--fields-section">
        <div className="edit-admin-account--fields-section--first-name-and-last-name-row">
          <LabeledInput
            isRequired
            label="First Name"
            value={accountFirstName}
            onChange={fieldOnChange(setAccountFirstName, "firstName")}
            errorText={(err && err.firstName) || validationErrors.firstName}
          />
          <LabeledInput
            isRequired
            label="Last Name"
            value={accountLastName}
            onChange={fieldOnChange(setAccountLastName, "lastName")}
            errorText={(err && err.lastName) || validationErrors.lastName}
          />
        </div>

        <div className="edit-admin-account--fields-section--nickname-and-role-row">
          <LabeledInput
            label="Nickname"
            value={accountNickName}
            onChange={setAccountNickName}
            errorText={err && err.nickname}
          />

          <LabeledInput
            label="Role"
            type="select"
            value={accountRole}
            onChange={fieldOnChange(setAccountRole, "role")}
            options={adminRoles.map(r => ({ label: r.name, value: r.id }))}
            errorText={validationErrors.role}
          />
        </div>
        <div className="edit-admin-account--fields-section--email-row">
          <LabeledInput
            isRequired
            label="Email"
            value={accountEmail}
            onChange={fieldOnChange(setAccountEmail, "email")}
            errorText={(err && err.email) || validationErrors.email}
          />
        </div>
        <div className="edit-admin-account--fields-section--phone-and-mobile-row">
          <LabeledInput
            label="Phone"
            value={accountPhone}
            onChange={setAccountPhone}
            errorText={(err && err.phone)}
          />
          <LabeledInput
            label="Mobile"
            value={accountMobile}
            onChange={setAccountMobile}
            errorText={(err && err.mobile)}
          />
        </div>
      </div>

      <div className="edit-admin-account--action-buttons">
        <Button
          className="ant-btn-primary edit-admin-account--action-buttons--save-btn"
          onClick={_updateAdminAccount}
        >
          SAVE & CLOSE
        </Button>

        <div className="del-btn-holder">
          <Button
            className="btn-secondary edit-admin-account--action-buttons--cancel-btn"
            onClick={() => history.push("/settings/admin-accounts")}
          >
            CANCEL
          </Button>
        </div>
      </div>
      {renderConfirmationModal()}
    </div>
  );
};

EditAdminAccount.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  deleteAdminAccount: PropTypes.func.isRequired,
  updateAdminAccount: PropTypes.func.isRequired,
  getAdminRoles: PropTypes.func.isRequired,
  adminRoles: PropTypes.array.isRequired,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  forgotPassword: PropTypes.func.isRequired,
  resetPasswordError: PropTypes.object,
  resetPasswordProcessing: PropTypes.object,
};

export default connect(
  state => ({
    ...state.adminAccounts,
    resetPasswordError: state.auth.error,
    resetPasswordProcessing: state.auth.processing,
  }),
  dispatch => ({
    deleteAdminAccount: payload => dispatch(deleteAdminAccountRequest(payload)),
    updateAdminAccount: payload => dispatch(updateAdminAccountRequest(payload)),
    getAdminRoles: payload => dispatch(getAdminRolesRequest(payload)),
    forgotPassword: payload => dispatch(forgotPasswordRequest(payload)),
  })
)(EditAdminAccount);

import React from "react";
import PropTypes from "prop-types";


function InfoIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icon/24/info-copy-3-icon/24/info" fill={fillColor}>
          <path d="M12,21 C7.0293,21 3,16.9707 3,12 C3,7.0293 7.0293,3 12,3 C16.9707,3 21,7.0293 21,12 C21,16.9707 16.9707,21 12,21 Z M11,11 L11,17 L13,17 L13,11 L11,11 Z M11,7 L11,9 L13,9 L13,7 L11,7 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

InfoIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default InfoIcon

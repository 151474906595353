import React from "react";
import PropTypes from "prop-types";
import { Avatar, Checkbox, Popconfirm } from "antd";
import lodash from "lodash";
import { connect } from "react-redux";

//components
import TableRow from "../../shared/Table/TableRow";
import DeleteIcon from "../../Icon/DeleteIcon";
import EditIcon from "../../Icon/EditIcon";


function PeriodsRow(props) {
  const {
    columnWidths, item, onChangeActive, onAddSub,
    onEdit, onRemove, childLevel = 0, isLast, isParentLast, isSearch, userRole
  } = props;

  const hasUpdatePermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1" || role === "AdminLv2");
  const isSuperAdmin = userRole.some(role => role === "Super Admin");

  let childRows = null;
  if (item.children && item.children.length && !isSearch) {
    childRows = item.children.map((child, index) => {
      const childCopy = lodash.cloneDeep(child);
      childCopy.parent = item.id;
      childCopy.parentItem = item;

      return (
        <PeriodsRow
          userRole={userRole}
          key={`catalog-entity-child:${childLevel}:${child.id}`}
          columnWidths={columnWidths}
          item={childCopy}
          onChangeActive={onChangeActive}
          onAddSub={onAddSub}
          onEdit={onEdit}
          onRemove={onRemove}
          childLevel={childLevel + 1}
          isLast={index === item.children.length - 1}
          isParentLast={isLast}
          isSearch={isSearch}
        />
      )
    })
  }

  // Tree style lines
  const lines = [];
  for (let i = 0; i < childLevel; i++) {
    if (isSearch) {
      break;
    }

    const classNames = ["groups--lines"];
    if (i !== childLevel - 1) {
      classNames.push("groups--lines__no-horizontal");
    }
    if (isLast && i === childLevel - 1) {
      classNames.push("groups--lines__last");
    }
    if (isParentLast && i !== childLevel - 1) {
      classNames.push("groups--lines__no-lines");
    }

    lines.push(<div key={`line:${item.id}:${i}`} className={classNames.join(" ")} />)
  }


  return (
    <div className="w-100">
      <TableRow columnWidths={columnWidths}>
        <Checkbox checked={item.isActive} onChange={event => onChangeActive(item.url, event)} />
        <div className="flex groups--title-column">
          {lines}
          {item.image && <Avatar className="table-image" src={item.image.url} size={32} shape="square" />}
          <span className="table-text">{item.title}</span>
        </div>

        <div className="flex table-sm-text">{item.url}</div>
        <div className="flex table-sm-text">{item.availableItemsCount}</div>
        <div className="flex table-sm-text">{item.reservedItemsCount}</div>
        <div className="flex">
          <img
            className="groups--table-icon"
            src="/images/eye_3x.svg"
            alt=""
            width="18"
            onClick={() => { }}
          />

          {isSuperAdmin &&
            <img
              className="groups--table-icon"
              src="/images/add-sub@3x.svg"
              alt=""
              width="16"
              onClick={() => onAddSub(item.url)}
            />
          }

          {hasUpdatePermission && (
            <EditIcon className="groups--table-icon" onClick={() => onEdit(item)} />
          )}

          {isSuperAdmin && (
            <Popconfirm
              title="Are you sure you want to delete this period?"
              onConfirm={() => onRemove(item.url)}
              placement="topRight"
            >
              <DeleteIcon className="groups--table-icon" />
            </Popconfirm>
          )}
        </div>
      </TableRow>

      {childRows}
    </div>
  )
}

PeriodsRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onChangeActive: PropTypes.func.isRequired,
  onAddSub: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  childLevel: PropTypes.number,
  isLast: PropTypes.bool,
  isParentLast: PropTypes.bool,
  isSearch: PropTypes.bool.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    userRole: state.auth.userProfile.roles,
  }),
  null
)(PeriodsRow);

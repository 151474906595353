import React from "react";
import PropTypes from "prop-types";
import { Avatar, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { buildFullName } from "../../helpers";


const AdminInfo = ({ firstName, lastName, role, avatar }) => {
  return (
    <Popover
      content={(
        <div className="dashboard--admin-info-block--popup-role">
          {role}
        </div>
      )}
      title={buildFullName({ firstName, lastName })}
    >
      <div className="dashboard--admin-info-block">
        <Avatar {...(avatar?.url ? { src: avatar.url } : { icon: <UserOutlined /> })} />
        <div className="dashboard--admin-info-block--name">{firstName}</div>
        <div className="dashboard--admin-info-block--role">{role}</div>
      </div>
    </Popover>
  )
}

AdminInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  avatar: PropTypes.any
};

export default AdminInfo;

import * as types from "./currencyRatesActionsTypes";


export const getCurrencyRatesRequest = params => {
  return { type: types.GET_CURRENCY_RATES_REQUEST, params };
};

export const updateCurrencyRatesRequest = (id, data, isPatch = false) => {
  return { type: types.UPDATE_CURRENCY_RATES_REQUEST, data, id, isPatch };
};

import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "antd";

import { resetPasswordRequest, clearError } from "../../redux/auth/authActions";


const ResetPassword = ({ isProcessing, error, resetPassword, clearAuthError, location, history }) => {
  // Component will mount
  useEffect(() => clearAuthError, []); // eslint-disable-line

  const validate = values => {
    const errors = {};

    if (!values.newPassword) errors.general = "Password cannot be empty";
    if (!values.confirmedPassword) errors.general = "Confirm Password cannot be empty";
    if (values.newPassword !== values.confirmedPassword) errors.general = "Passwords must be equal";

    return errors;
  };

  const onSubmit = values => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    resetPassword({ ...values, token: token || "" }, () => history.replace("/auth/login"))
  };


  return (
    <div className="auth">
      <div className="auth-block">
        <img className="auth-block--logo" src="/images/cms@3x.svg" width="144" height="32" alt="" />

        <Formik
          initialValues={{ newPassword: "", confirmedPassword: "" }}
          validate={validate}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => (
            <Form className="auth-block--form">
              {
                (formikProps.errors.general || error) && (
                  <div className="auth-block--form-error">
                    <img className="auth-block--form-error-icon" src="/images/warning@3x.svg" alt="" />
                    {formikProps.errors.general || error}
                  </div>
                )
              }

              <div className="auth-block--input-header">
                <label className="auth-block--input-label">Password</label>
              </div>
              <Field type="password" name="newPassword" className="auth-block--form-input" />

              <div className="auth-block--input-header">
                <label className="auth-block--input-label">Confirm Password</label>
              </div>
              <Field type="password" name="confirmedPassword" className="auth-block--form-input" />

              <Button
                className="auth-block--form-submit"
                type="primary"
                disabled={isProcessing}
                loading={isProcessing}
                onClick={formikProps.handleSubmit}
              >
                {isProcessing ? "" : "SUBMIT" }
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

ResetPassword.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  error: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({ isProcessing: state.auth.processing, error: state.auth.error }),
  dispatch => ({
    resetPassword: (data, cb) => dispatch(resetPasswordRequest(data, cb)),
    clearAuthError: () => dispatch(clearError())
  })
)(ResetPassword);

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_REQUEST_SUCCESS = "GET_ORDERS_REQUEST_SUCCESS";
export const GET_ORDERS_REQUEST_ERROR = "GET_ORDERS_REQUEST_ERROR";

export const GET_ORDERS_CHOICES_REQUEST = "GET_ORDERS_CHOICES_REQUEST";
export const GET_ORDERS_CHOICES_SUCCESS = "GET_ORDERS_CHOICES_SUCCESS";
export const GET_ORDERS_CHOICES_ERROR = "GET_ORDERS_CHOICES_ERROR";

export const GET_DEALERS_LIST_REQUEST = "GET_DEALERS_LIST_REQUEST";
export const GET_DEALERS_LIST_SUCCESS = "GET_DEALERS_LIST_SUCCESS";
export const GET_DEALERS_LIST_ERROR = "GET_DEALERS_LIST_ERROR";

export const SEARCH_ORDERS_REQUEST = "SEARCH_ORDERS_REQUEST";
export const SEARCH_ORDERS_REQUEST_SUCCESS = "SEARCH_ORDERS_REQUEST_SUCCESS";
export const SEARCH_ORDERS_REQUEST_ERROR = "SEARCH_ORDERS_REQUEST_ERROR";

export const PATCH_ORDER_REQUEST = "PATCH_ORDER_REQUEST";
export const PATCH_ORDER_REQUEST_SUCCESS = "PATCH_ORDER_REQUEST_SUCCESS";
export const PATCH_ORDER_REQUEST_ERROR = "PATCH_ORDER_REQUEST_ERROR";

export const GET_FILTERED_ORDERS_REQUEST = "GET_FILTERED_ORDERS_REQUEST";
export const GET_FILTERED_ORDERS_REQUEST_SUCCESS = "GET_FILTERED_ORDERS_REQUEST_SUCCESS";
export const GET_FILTERED_ORDERS_REQUEST_ERROR = "GET_FILTERED_ORDERS_REQUEST_ERROR";

export const TOGGLE_SEARCH_PROCESSING = "TOGGLE_SEARCH_PROCESSING";

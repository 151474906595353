export const selectCurrencySymbol = (currency) => {
  const value = typeof currency === "object" ? currency.currency : currency;

  switch (value) {
    case "USD":
      return "$";

    case "EUR":
      return "€";

    case "GBP":
      return "£";

    default:
      return "£";
  }
};

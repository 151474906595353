import fetch from "./customFetch";


const content = {
  async getPages({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/pages/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createNewPage(data) {
    try {
      return await fetch({
        url: "/pages/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchPageStatus(data) {
    try {
      return await fetch({
        url: `/pages/${data.id}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePage(id, data) {
    try {
      return await fetch({
        url: `/pages/${id}/`,
        method: "PUT",
        id,
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deletePage(id) {
    try {
      return await fetch({
        url: `/pages/${id}/`,
        method: "DELETE",
        id
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async exportPages({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/pages/export/?page=${page}&page_size=${perPage}`,
        method: "GET",
        responseType: "blob",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getArticles({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/blog/articles/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getCategories({ page = 1, perPage = 10, search }) {
    const query = new URLSearchParams();

    if (page) query.set("page", page);
    if (perPage) query.set("page_size", perPage);
    if (search) query.set("name", search);

    try {
      return await fetch({
        url: `/blog/categories/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getAuthors({ page = 1, perPage = 10, search }) {
    const query = new URLSearchParams();

    if (page) query.set("page", page);
    if (perPage) query.set("page_size", perPage);
    if (search) query.set("name", search);

    try {
      return await fetch({
        url: `/blog/authors/?${query.toString()}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchBlogArticle(url, data) {
    try {
      return await fetch({
        url: `/blog/articles/${url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteBlogArticle(url) {
    try {
      return await fetch({
        url: `/blog/articles/${url}/`,
        method: "DELETE"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchBlogAuthorStatus(data) {
    try {
      return await fetch({
        url: `/blog/authors/${data.url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchBlogCategoryStatus(data) {
    try {
      return await fetch({
        url: `/blog/categories/${data.url}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createNewArticle(data) {
    try {
      const response = await fetch({
        url: "/blog/articles/",
        method: "POST",
        data
      });

      return ({ response });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getBlogArticleData(url) {
    try {
      return await fetch({
        url: `/blog/articles/${url}/`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async uploadNewImage(data) {
    try {
      const response = await fetch({
        url: "/static/image/",
        headers: { "Content-Type": data.type },
        method: "POST",
        data,
      });

      return ({ response });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async uploadNewVideo(data) {
    try {
      return await fetch({
        url: `/static/video/${data.name}/`,
        headers: { "Content-Type": data.type },
        method: "POST",
        data
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }
      throw err;
    }
  },

  async uploadFile(data) {
    try {
      const response = await fetch({
        url: `/static/attachment/${data.name}/`,
        headers: { "Content-Type": data.type, },
        method: "POST",
        data,
      });

      return ({ response });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getHelpTextItems({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/help-text-items/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchHelpTextItem(data) {
    try {
      return await fetch({
        url: `/help-text-items/${data.id}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createHelpTextItem(data) {
    try {
      return await fetch({
        url: "/help-text-items/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getHelpTextChoices(data) {
    try {
      return await fetch({
        url: "/help-text-items/choices/?help_title=PayPal",
        method: "GET",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async putHelpTextItem(id, data) {
    try {
      return await fetch({
        url: `/help-text-items/${id}/`,
        method: "PUT",
        id,
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteHelpTextItem(id) {
    try {
      return await fetch({
        url: `/help-text-items/${id}/`,
        method: "DELETE",
        id,
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createBlogAuthor(data) {
    try {
      return await fetch({
        url: "/blog/authors/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createBlogCategory(data) {
    try {
      return await fetch({
        url: "/blog/categories/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateShowBlogOnHome({ url, data }) {
    try {
      return await fetch({
        url: `/blog/articles/${url}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchByOrigin({ page = 1, perPage = 200, search = "" }) {
    try {
      return await fetch({
        url: `/catalogs/origin/elements/search?page=${page}&page_size=${perPage}&query=${search}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchByMaterial({ page = 1, perPage = 200, search = "" }) {
    try {
      return await fetch({
        url: `/catalogs/material/elements/search?page=${page}&page_size=${perPage}&query=${search}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default content;

import { all, put, call, takeLatest } from "redux-saga/effects";

import categories from "../../api/categories";
import * as types from "./categoriesActionTypes";
import navigation from "../../helpers/navigation";


export function* get({ params }) {
  try {
    let response = {};
    if (params.search) {
      response = yield call(categories.search, params);
    } else {
      response = yield call(categories.get, params);
    }

    yield put({ type: types.GET_CATEGORIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CATEGORIES_REQUEST_ERROR, payload: error });
  }
}

export function* create({ data, callback }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(categories.create, data);

    yield put({ type: types.GET_CATEGORIES_REQUEST, params: { page: 1, perPage: 10 } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export function* update({ url, data, isPatch, callback }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) {
      yield call(categories.updatePatch, url, data);
    } else {
      yield call(categories.updatePut, url, data);
    }

    yield put({ type: types.GET_CATEGORIES_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export function* remove({ url, callback }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(categories.delete, url);

    yield put({ type: types.GET_CATEGORIES_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

export function* getCategoryFAQs({ payload }) {
  yield put({ type: types.TOGGLE_CATEGORY_FAQ_LOADING, payload: true });
  try {
    const response = yield call(categories.getCategoryFAQs, payload);
    yield put({ type: types.GET_CATEGORY_FAQ_REQUEST_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.GET_CATEGORY_FAQ_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CATEGORY_FAQ_LOADING, payload: false });
  }
}

export function* createCategoryFAQs({ payload }) {
  yield put({ type: types.TOGGLE_CATEGORY_FAQ_PROCESSING, payload: true });
  try {
    yield call(categories.createCategoryFAQs, payload);
    yield put({ type: types.CREATE_CATEGORY_FAQ_REQUEST_SUCCESS })
  } catch (error) {
    yield put({ type: types.CREATE_CATEGORY_FAQ_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CATEGORY_FAQ_PROCESSING, payload: false });
  }
}

export function* patchCategoryFAQ({ payload }) {
  yield put({ type: types.TOGGLE_CATEGORY_FAQ_PROCESSING, payload: true });
  try {
    const response = yield call(categories.patchCategoryFAQ, payload);
    yield put({ type: types.PATCH_CATEGORY_FAQ_REQUEST_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: types.PATCH_CATEGORY_FAQ_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_CATEGORY_FAQ_PROCESSING, payload: false });
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_CATEGORIES_REQUEST, get),
    yield takeLatest(types.CREATE_CATEGORIES_REQUEST, create),
    yield takeLatest(types.UPDATE_CATEGORIES_REQUEST, update),
    yield takeLatest(types.DELETE_CATEGORIES_REQUEST, remove),
    yield takeLatest(types.GET_CATEGORY_FAQ_REQUEST, getCategoryFAQs),
    yield takeLatest(types.CREATE_CATEGORY_FAQ_REQUEST, createCategoryFAQs),
    yield takeLatest(types.PATCH_CATEGORY_FAQ_REQUEST, patchCategoryFAQ),
  ])
}

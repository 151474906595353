import React, { useState, useEffect } from "react";
import PropsTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Input, Button, Select, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import * as yup from "yup";
import isArray from "lodash/isArray";
import get from "lodash/get";

// components
import LabeledInput from "../../shared/LabeledInput";

// helpers
import { useYupValidationResolver } from "../../../hooks";
import artisans from "../../../api/artisans";

const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;
const validationSchema = yup.object().shape({
  tags: yup.array().required("This field is required").typeError("This field is required"),
  name: yup.string().required("Name can not be empty"),
  url: yup.string().required("Url can not be empty"),
  description: yup.string(),
});
const validationFields = ["name", "description", "url", "pseudonym"];

function CreateArtisanForm({ visible, handleClose }) {
  const [nonFieldError, setNonFieldError] = useState(null);
  const [artisanTags, setArtisanTags] = useState([]);

  useEffect(() => {
    visible && artisans.getTags({}).then(({ results }) => {
      const artisanTagOption = results.map(tag => ({ value: tag.id, label: tag.name }));
      setArtisanTags(artisanTagOption);
    })
  }, [visible]); // eslint-disable-line

  const { setValue, formState: { errors }, watch, setError, reset, ...methods } = useForm({
    defaultValues: {
      name: "",
      pseudonym: null,
      description: "",
      url: "",
      tags: null,
    },
    shouldUnregister: false,
    resolver: useYupValidationResolver(validationSchema),
  });
  const { name, pseudonym, description, tags, url } = watch();

  const onSubmit = data => {
    artisans.create(data)
      .then(resp => {
        handleClose(resp);
        message.success("Artisan suggestion was sent to our admins.");
        reset();
      })
      .catch((e) => {
        if (validationFields.some(item => Object.keys(e).includes(item))) {
          Object.keys(e).forEach(key => {
            setError(key, { message: e[key][0] });
          })
        } else if (isArray(e)) {
          setNonFieldError(e.join("\n"));
        }
        message.error("Something went wrong. Please try again.");
      });
  }

  return (
    <form className="items-list__create-artisan-form" onSubmit={methods.handleSubmit(onSubmit)}>
      <div>
        <span className="input-field--input-label">name*</span>
        <Input
          value={name}
          name="name*"
          onChange={e => setValue("name", e.target.value)}
        />
        { errors && errors.name
          ? <p className="items-list__create-artisan-error">{ errors.name.message }</p>
          : null
        }
      </div>
      <div>
        <span className="input-field--input-label">{"pseudonym (Also known as)"}</span>
        <Input
          value={pseudonym}
          onChange={e => setValue("pseudonym", e.target.value)}
        />
      </div>
      <div className="items-list__create-artisan-full-width">
        <LabeledInput
          label="URL*"
          value={url}
          onChange={e => setValue("url", e)}
          suffix={urlFieldImage}
          addonBefore="https://www.loveantiques.com/"
          inputClassName="input-with-prefix"
          errorText={get(errors, "url.message")}
        />
      </div>
      <div className="items-list__create-artisan-full-width">
        <span className="input-field--input-label">tags*</span>
        <Select
          mode="multiple"
          suffixIcon={<DownOutlined/>}
          showArrow
          className="items-list__create-artisan-select"
          options={artisanTags}
          value={tags || []}
          onChange={e => setValue("tags", e)}
        />
        { errors && errors.tags
          ? <p className="items-list__create-artisan-error">{ errors.tags.message }</p>
          : null
        }
      </div>
      <div className="items-list__create-artisan-full-width">
        <span className="input-field--input-label">description</span>
        <Input.TextArea
          value={description}
          onChange={e => setValue("description", e.target.value)}
        />
        { errors && errors.description
          ? <p className="items-list__create-artisan-error">{ errors.description.message }</p>
          : null
        }
      </div>
      {
        nonFieldError
          ? <p className="items-list__create-artisan-error">{ nonFieldError }</p>
          : null
      }
      <div className="items-list__create-artisan-full-width items-list__create-artisan-btn-group">
        <Button
          type="ghost"
          onClick={() => {
            reset();
            handleClose();
          }}
        >
        Cancel
        </Button>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
        >
        Add
        </Button>
      </div>
    </form>
  );
}

CreateArtisanForm.propTypes = {
  visible: PropsTypes.bool.isRequired,
  handleClose: PropsTypes.func.isRequired,
};

export default CreateArtisanForm;

import React from "react";
import PropTypes from "prop-types";


function TableHeaderCell(props) {
  const { className, style, changeOrder, currentOrder = {}, label, orderKey } = props;

  function _onClick() {
    if (orderKey && typeof changeOrder === "function") changeOrder(orderKey);
  }

  let orderIcon = null;
  if (orderKey) {
    orderIcon = "/images/select_default.svg";
    if (currentOrder.key === orderKey && currentOrder.direction === "asc") orderIcon = "/images/select_asc.svg";
    else if (currentOrder.key === orderKey && currentOrder.direction === "desc") orderIcon = "/images/select_desc.svg";

    /* TODO fix all images imports */
    orderIcon = <img src={orderIcon} alt="" />;
  }

  return (
    <div className={`${className} ${orderKey && "with-cursor"}`} style={style} onClick={_onClick}>
      <span className="table--header-label">{label || ""}</span>
      {orderIcon}
    </div>
  )
}

TableHeaderCell.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  changeOrder: PropTypes.func,
  currentOrder: PropTypes.object,
  label: PropTypes.string,
  orderKey: PropTypes.string,
};

export default TableHeaderCell;

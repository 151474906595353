import React from "react";
import PropTypes from "prop-types";


function RotateToRight({ fillColor = "#FFFFFF", className = "", onClick }) {
  return (
    <svg onClick={onClick} className={className} width="15px" height="18px" viewBox="0 0 15 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>E57BE1F3-A215-4CDD-BA8D-D3CA5AAAD968@3x</title>
      <desc>Created with sketchtool.</desc>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Items-Lightbox" transform="translate(-709.000000, -831.000000)" fill={fillColor}>
          <g id="icon/24/anticlockwise-copy-icon/24/clockwise" transform="translate(704.000000, 829.000000)">
            <path d="M11.3216667,4.74791696 L9.7975,3.19898319 L10.9766667,2 L14.5116667,5.59525491 L10.9766667,9.19050981 L9.7975,7.99152662 L11.3216667,6.44259285 L9.16666667,6.44259285 C7.78595479,6.44259285 6.66666667,7.58069122 6.66666667,8.98460669 L6.66666667,12.3739585 L5,12.3739585 L5,8.98460669 C5,6.64474756 6.86548021,4.74791696 9.16666667,4.74791696 L11.3216667,4.74791696 Z M10,10.6792826 C10,10.2113108 10.373096,9.83194464 10.8333333,9.83194464 L19.1666667,9.83194464 C19.626904,9.83194464 20,10.2113108 20,10.6792826 L20,19.1526621 C20,19.6206339 19.626904,20 19.1666667,20 L10.8333333,20 C10.373096,20 10,19.6206339 10,19.1526621 L10,10.6792826 Z M11.6666667,11.5266205 L11.6666667,18.3053241 L18.3333333,18.3053241 L18.3333333,11.5266205 L11.6666667,11.5266205 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

RotateToRight.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default RotateToRight

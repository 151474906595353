import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./blogActionTypes";
import content from "../../../api/content";


function *getArticles (action) {
  try {
    const response = yield call(content.getArticles, action.payload);
    yield put({ type: types.GET_BLOG_ARTICLES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_ARTICLES_REQUEST_ERROR, payload: error.message });
  }
}

function *getCategories(action) {
  try {
    const res = yield call(content.getCategories, action.payload);
    yield put({ type: types.GET_BLOG_CATEGORIES_REQUEST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: types.GET_BLOG_CATEGORIES_REQUEST_ERROR, payload: error.message });
  }
}

function *getAuthors(action) {
  try {
    const res = yield call(content.getAuthors, action.payload);
    yield put({ type: types.GET_BLOG_AUTHORS_REQUEST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: types.GET_BLOG_AUTHORS_REQUEST_ERROR, payload: error.message });
  }
}

function *updateBlogArticleRequest (action) {
  try {
    const { url, article, page, perPage, callback } = action.payload;
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
    yield call(content.patchBlogArticle, url, article);
    yield put({ type: types.PATCH_BLOG_ARTICLE_REQUEST_SUCCESS });
    yield put({ type: types.GET_BLOG_ARTICLES_REQUEST, payload: { page, perPage } });
    yield call(callback);
  } catch (error) {
    yield put({ type: types.PATCH_BLOG_ARTICLE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
  }
}

function *deleteBlogArticleRequest (action) {
  try {
    const { url, history } = action.payload;
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
    yield call(content.deleteBlogArticle, url);
    history.push({ pathname: "/content/blog/articles?page=1" });
  } catch (error) {
    yield put({ type: types.DELETE_BLOG_ARTICLE_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
  }
}

function *getBlogArticleData (action) {
  try {
    const { url } = action.payload;
    const response = yield call(content.getBlogArticleData, url);
    yield put({ type: types.GET_BLOG_ARTICLE_DATA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLOG_ARTICLE_DATA_REQUEST_ERROR, payload: error.message });
  }
}

function *updateBlogAuthorStatusRequest (action) {
  try {
    const { author, page, perPage } = action.payload;
    yield call(content.patchBlogAuthorStatus, author);
    yield put({ type: types.GET_BLOG_AUTHORS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_BLOG_AUTHOR_STATUS_ERROR, payload: error });
  }
}

function *updateBlogCategoryStatusRequest (action) {
  try {
    const { category, page, perPage } = action.payload;
    yield call(content.patchBlogCategoryStatus, category);
    yield put({ type: types.GET_BLOG_CATEGORIES_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_BLOG_CATEGORY_STATUS_ERROR, payload: error });
  }
}

function *createNewArticle (action) {
  try {
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
    yield call(content.createNewArticle, action.payload.data);
    yield put({ type: types.CREATE_NEW_ARTICLE_REQUEST_SUCCESS });
    yield call(action.payload.callback);
  } catch (error) {
    yield put({ type: types.CREATE_NEW_ARTICLE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_ARTICLE_PROCESSING });
  }
}

function *uploadImageToServer (action) {
  try {
    const response = yield call(content.uploadNewImage, action.payload);
    yield put({ type: types.UPLOAD_NEW_IMAGE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.UPLOAD_NEW_IMAGE_REQUEST_ERROR, payload: error.message });
  }
}

function *createBlogAuthor (action) {
  try {
    yield put({ type: types.TOGGLE_ADD_AUTHOR_PROCESSING });
    yield call(content.createBlogAuthor, action.payload);
    yield put({ type: types.CREATE_NEW_AUTHOR_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_NEW_AUTHOR_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_ADD_AUTHOR_PROCESSING });
  }
}

function *createBlogCategory (action) {
  try {
    yield put({ type: types.TOGGLE_ADD_CATEGORY_PROCESSING });
    yield call(content.createBlogCategory, action.payload);
    yield put({ type: types.CREATE_NEW_CATEGORY_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_NEW_CATEGORY_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_ADD_CATEGORY_PROCESSING });
  }
}

function *updateShowBlogStatus(action) {
  try {
    yield call(content.updateShowBlogOnHome, action.payload);
  } catch (error) {
    yield put({ type: types.UPDATE_SHOW_BLOG_STATUS_ERROR, payload: error })
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_BLOG_ARTICLES_REQUEST, getArticles),
    yield takeLatest(types.GET_BLOG_CATEGORIES_REQUEST, getCategories),
    yield takeLatest(types.GET_BLOG_AUTHORS_REQUEST, getAuthors),
    yield takeLatest(types.PATCH_BLOG_ARTICLE_REQUEST, updateBlogArticleRequest),
    yield takeLatest(types.DELETE_BLOG_ARTICLE_REQUEST, deleteBlogArticleRequest),
    yield takeLatest(types.PATCH_BLOG_AUTHOR_STATUS_REQUEST, updateBlogAuthorStatusRequest),
    yield takeLatest(types.PATCH_BLOG_CATEGORY_STATUS_REQUEST, updateBlogCategoryStatusRequest),
    yield takeLatest(types.CREATE_NEW_ARTICLE_REQUEST, createNewArticle),
    yield takeLatest(types.UPLOAD_NEW_IMAGE_REQUEST, uploadImageToServer),
    yield takeLatest(types.CREATE_NEW_AUTHOR_REQUEST, createBlogAuthor),
    yield takeLatest(types.CREATE_NEW_CATEGORY_REQUEST, createBlogCategory),
    yield takeLatest(types.GET_BLOG_ARTICLE_DATA_REQUEST, getBlogArticleData),
    yield takeLatest(types.UPDATE_SHOW_BLOG_STATUS, updateShowBlogStatus),
  ])
}

import * as types from "./seoActionsTypes";


const initialState = {
  items: [],
  total: 0,
  loading: false,
  processing: false
};


const seo = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false
      };

    case types.GET_SEO_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count,
      };

    default:
      return state;
  }
};

export default seo;

import moment from "moment";
import lodash from "lodash";

const DEFAULT_TIME_FORMAT = "YYYY-MM-DD";


export const Utils = {
  dateToISODate(date) {
    const target = new Date(date);

    return `${target.getFullYear()}-${target.getMonth() + 1}-${target.getDate()}`;
  },

  filterAndOrder(items, params) {
    let result = null;

    // Filter by search query
    if (params.search) {
      const key = params.searchKey || "";
      result = items.filter(item => item[key].toLowerCase().includes(params.search.toLowerCase()));
    }

    // Order by key and direction
    if (params.order && params.order.key) {
      result = lodash.orderBy((result || items), [params.order.key], [params.order.direction])
    }

    return result || items;
  },

  emptyFieldFormat(str, symbol) {
    return str || (typeof str === "number" ? str : symbol)
  },

  calculateLastNDays(countDays) {
    const now = moment().format(DEFAULT_TIME_FORMAT);
    const last14Days = moment(now).subtract(countDays, "d").format(DEFAULT_TIME_FORMAT);

    return `${last14Days} ${now}`;
  },

  calculateLastMonth() {
    const now = moment().format(DEFAULT_TIME_FORMAT);
    const startOfMonth = moment(now).startOf("month").format(DEFAULT_TIME_FORMAT);
    const endOfMonth = moment(now).endOf("month").format(DEFAULT_TIME_FORMAT);

    return `${startOfMonth} ${endOfMonth}`;
  },

  formatFilters(queryOptions) {
    const searchQuery = {};
    let searchParams = lodash.cloneDeep(queryOptions);

    if (searchParams.date) {
      const dates = searchParams.date.value.split(" ");
      delete searchParams.date;

      searchParams = lodash.merge(searchParams, {
        from_date: {
          value: dates[0]
        },
        to_date: {
          value: dates[1]
        }
      });
    }

    for (const item in searchParams) {
      if (searchParams[item]) {
        lodash.merge(searchQuery, { [item]: searchParams[item].value });
      }
    }

    return searchQuery
  },

  duration(seconds) {
    let result = "";
    const dur = moment.duration(seconds, "seconds");
    const durHours = dur.asHours();
    const durMinutes = dur.asMinutes();
    const durSeconds = dur.asSeconds();

    if (durHours > 1) {
      result += `${Math.floor(durHours)}h `;
    }
    if (durMinutes > 1) {
      result += `${Math.floor(durMinutes)}m `;
    }
    if (durSeconds > 1) {
      result += `${Math.floor(durSeconds)}s `;
    }

    return result.trim();
  },

  humanizePath(path) {
    path = path.replace(/-+/g, " ");
    path = path.replace(/_+/g, " ");
    path = path.replace(/\s+/g, " ");
    path = path.trim();

    // Capitalize first letters
    const split = path.split(" ");
    split.forEach((part, index) => {
      split[index] = part.charAt(0).toUpperCase() + part.slice(1);
    });

    return split.join(" ");
  },
};

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({ url: `/subscriptions/plans/?${buildQueryString(params)}`, method: "GET" });
};

export const useTradeSubscriptionPlanList = (params, options) => {
  return useQuery(["trade-subscription-plan-list", JSON.stringify(params)], () => fetcher(params), options);
};

import * as types from "./blacklistsEnquiriesActionsTypes";


export const getBlacklistsEnquiriesRequest = params => {
  return { type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST, params };
};

export const createBlacklistsEnquiriesRequest = data => {
  return { type: types.CREATE_BLACKLISTS_ENQUIRIES_REQUEST, data };
};

export const updateBlacklistsEnquiriesRequest = (id, data, isPatch) => {
  return { type: types.UPDATE_BLACKLISTS_ENQUIRIES_REQUEST, id, data, isPatch };
};

export const deleteBlacklistsEnquiriesRequest = id => {
  return { type: types.DELETE_BLACKLISTS_ENQUIRIES_REQUEST, id };
};

export const getBlacklistsEnquiriesChoicesRequest = () => {
  return { type: types.GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST };
};

export const resetBlacklistsEnquiriesErrorAction = () => {
  return { type: types.RESET_ERROR };
};

export const getSpamListRequest = (payload) => {
  return { type: types.GET_SPAM_LIST_REQUEST, payload };
};

export const deleteEnquiryFromBlacklist = (id, params) => {
  return { type: types.DELETE_ENQUIRY_FROM_BLACKLIST, id, params };
};

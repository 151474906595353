const formatPrice = (price = "-", options = {}) => {
  if (!price) {
    return price;
  }
  
  if (typeof price === "string" || Number.isInteger(price)) {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const { maximumFractionDigits = 2 } = options;

  return price.toFixed(maximumFractionDigits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default formatPrice;

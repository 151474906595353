import React from "react";
import PropTypes from "prop-types";
import moment from "moment";


const renderPaymentStatusField = (status) => {
  let className = "";
  let formattedStatus = "";
  if (status === "UNCOMPLETED") {
    className = "payment-status-uncompleted";
    formattedStatus = "Uncompleted";
  }
  if (status === "COMPLETED") {
    className = "payment-status-completed";
    formattedStatus = "Completed"
  }

  return (
    <div className={className}>{formattedStatus}</div>
  )
}

const PaymentSection = ({ paymentData }) => {
  const { ref, status, customerRef, date, method } = paymentData;
  
  return (
    <div className="order-header--payment-wrapper">
      <div className="header-tab-column-title">Payment</div>
      <div className="payment-column-info">
        <div className="payment-column-info-field">
          <div className="header-tab-column-label">Payment Status</div>
          {status ? renderPaymentStatusField(status) : "-"}
        </div>
        <div className="payment-column-info-field">
          <div className="header-tab-column-label">Payment Method</div>
          <div className="payment-method-field-value">
            {method || "-"}
          </div>
        </div>
        <div className="payment-column-info-field">
          <div className="header-tab-column-label">Payment Date</div>
          <div className="payment-date-field-value">
            {date ? moment(date).format("LLL") : "-"}
          </div>
        </div>
        <div className="payment-column-info-field">
          <div className="header-tab-column-label">Payment Ref</div>
          <div className="payment-ref-field-value">
            {ref || "-"}
          </div>
        </div>
        <div className="payment-column-info-field">
          <div className="header-tab-column-label">Customer Ref</div>
          <div className="customer-ref-field-value">
            {customerRef || "-"}
          </div>
        </div>
      </div>
    </div>
  )
}

PaymentSection.propTypes = {
  paymentData: PropTypes.object.isRequired
};

export default PaymentSection;

import { all, put, call, takeLatest } from "redux-saga/effects";

import auth from "../../api/auth";
import * as types from "./authActionsTypes";


function *login ({ data }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(auth.login, data);
    yield put({ type: types.LOGIN_REQUEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOGIN_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getProfile() {
  try {
    const data = yield call(auth.getUserProfile);
    yield put({ type: types.GET_USER_PROFILE_SUCCESS, payload: data });

  } catch (error) {
    yield put({ type: types.GET_USER_PROFILE_ERROR, error: error.message });
  }
}

function *forgotPassword ({ data }) {
  yield put({ type: types.CLEAR_ERROR });
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(auth.forgotPassword, data);
  } catch (error) {
    yield put({ type: types.FORGOT_PASSWORD_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *resetPassword ({ data, cb }) {
  yield put({ type: types.CLEAR_ERROR });
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(auth.resetPassword, data);
    yield call(() => {
      if (typeof cb === "function") {
        cb();
      }
    })
  } catch (error) {
    yield put({ type: types.RESET_PASSWORD_REQUEST_ERROR, error: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}


function *logout () {
  yield put({ type: types.SHOW_LOADING });

  try {
    yield call(auth.logout);
    yield put({ type: types.LOGOUT_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.LOGOUT_REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.LOGIN_REQUEST, login),
    yield takeLatest(types.GET_USER_PROFILE_REQUEST, getProfile),
    yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPassword),
    yield takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
    yield takeLatest(types.LOGOUT_REQUEST, logout),
  ])
}

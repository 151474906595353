export const GET_ENQUIRIES_ANALYTICS_REQUEST = "GET_ENQUIRIES_ANALYTICS_REQUEST";
export const GET_ENQUIRIES_ANALYTICS_ERROR = "GET_ENQUIRIES_ANALYTICS_ERROR";
export const GET_ENQUIRIES_ANALYTICS_SUCCESS = "GET_ENQUIRIES_ANALYTICS_SUCCESS";

export const ENQUIRIES_ANALYTICS_LOADING = "ENQUIRIES_ANALYTICS_LOADING";

export const GET_ITEMS_ANALYTICS_REQUEST = "GET_ITEMS_ANALYTICS_REQUEST";
export const GET_ITEMS_ANALYTICS_ERROR = "GET_ITEMS_ANALYTICS_ERROR";
export const GET_ITEMS_ANALYTICS_SUCCESS = "GET_ITEMS_ANALYTICS_SUCCESS";

export const ITEMS_ANALYTICS_LOADING = "ITEMS_ANALYTICS_LOADING";

export const GET_DEALER_ANALYTICS_REQUEST = "GET_DEALER_ANALYTICS_REQUEST";
export const GET_DEALER_ANALYTICS_REQUEST_ERROR = "GET_DEALER_ANALYTICS_REQUEST_ERROR";
export const GET_DEALER_ANALYTICS_REQUEST_SUCCESS = "GET_DEALER_ANALYTICS_REQUEST_SUCCESS";

export const DEALER_ANALYTICS_REQUEST_LOADING = "DEALER_ANALYTICS_REQUEST_LOADING";

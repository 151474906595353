import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import PropTypes from "prop-types";

import LabeledInput from "../../shared/LabeledInput";
import SearchInput from "../../shared/SearchInput";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import PublishAndDomainRequestsRow from "../PublishAndDomainRequestsRow";

import {
  getWebsitePublishRequests,
  changeWebsitePublishRequestStatus
} from "../../../redux/website/websiteActions";

import navigation from "../../../helpers/navigation";

const PublishRequests = ({
  location,
  history,
  publishRequests,
  totalPublishRequestsCount,
  getPublishRequests,
  updateWebsitePublishRequestStatus,
}) => {
  const { page, perPage, search, statusOption, dateOption } = navigation.getTableQueryParams(location);

  // Effects

  useEffect(() => {
    getPublishRequests({ page, perPage, statusOption, dateOption, search });
  }, [page, perPage, search, statusOption, dateOption]); // eslint-disable-line

  // Methods

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const handleChangeStatus = value => {
    navigation.setDynamicKeys({ page: 1, statusOption: value }, location, history);
  };

  const handleChangeDateOption = value => {
    navigation.setDynamicKeys({ page: 1, dateOption: value }, location, history);
  };

  const handleChangePublishRequestStatus = (id, newStatus) => {
    const data = {
      status: newStatus
    };
    updateWebsitePublishRequestStatus({ id, data, location, history });
  };

  // Render

  return (
    <div className="website-builder--container">
      <TableAndFormLayout>
        <TableAndFormLayout.Header>
          <div className="website-builder--container--header">
            <div className="website-builder--container--header--left-side">
              <LabeledInput
                label="Status"
                type="select"
                className="requests-select"
                value={statusOption}
                options={[{
                  label: "All Requests",
                  value: ""
                }, {
                  label: "Done",
                  value: "DONE"
                }, {
                  label: "Pending",
                  value: "PENDING"
                }, {
                  label: "Declined",
                  value: "DECLINED"
                }]}
                onChange={handleChangeStatus}
                horizontal
              />

              <Select
                className="select"
                value={dateOption}
                onChange={handleChangeDateOption}
              >
                <Select.Option value="-date">Newest First</Select.Option>
                <Select.Option value="date">Oldest First</Select.Option>
              </Select>
            </div>

            <div className="website-builder--container--header--right-side">
              <SearchInput value={search} onChange={onSearch} />
            </div>
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table className="Header">
          <div className="website-builder--container--content">
            <Table
              columnWidths={["10%", "11%", "13%", "15%", "15%", "14%", "14%", "8%"]}
              headerCells={[
                { label: "Status" },
                { label: "First Name" },
                { label: "Last Name" },
                { label: "Business Name" },
                { label: "Dealer Email" },
                { label: "Date of Request" },
                { label: "Domain Name" },
                { label: "Actions" },
              ]}
            >
              {({ columnWidths }) => {
                return publishRequests.map(item => {
                  return (
                    <PublishAndDomainRequestsRow
                      key={item.id}
                      columnWidths={columnWidths}
                      createdAt={item.createdAt}
                      request={item}
                      dealerData={item.site.dealer}
                      handleChangeRequestStatus={handleChangePublishRequestStatus}
                      domainName={item.site.dealer.siteDomain}
                    />
                  )
                })
              }}

              {totalPublishRequestsCount && (
                <TableFooter
                  key="footer"
                  page={page}
                  perPage={perPage}
                  total={totalPublishRequestsCount}
                />
              )}
            </Table>
          </div>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
};

PublishRequests.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  publishRequests: PropTypes.array.isRequired,
  totalPublishRequestsCount: PropTypes.number.isRequired,
  getPublishRequests: PropTypes.func.isRequired,
  updateWebsitePublishRequestStatus: PropTypes.func.isRequired,
}

export default connect(
  state => ({
    publishRequests: state.website.publishRequests.publishRequests,
    totalPublishRequestsCount: state.website.publishRequests.totalPublishRequests,
  }),
  {
    getPublishRequests: getWebsitePublishRequests,
    updateWebsitePublishRequestStatus: changeWebsitePublishRequestStatus,
  }
)(PublishRequests);

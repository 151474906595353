import React, { useCallback, useState, useRef, useEffect } from "react";
import _ from "lodash";
import { Select } from "antd";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { usePrevious } from "../../hooks";

import { useTriggerPatch } from "../../helpers/triggerPatchContext";


const SharedSelectForForm = (props) => {
  const triggerPatch = useTriggerPatch();
  const { getValues } = useFormContext();

  const [options, setOptions] = useState(getValues(props.name) || []);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const selectRef = useRef(null);

  const mode = props.isMultiple ? "multiple" : "";


  const previousDisableState = usePrevious(props.isDisabled);

  useEffect(() => {
    if (props.parentCategoryName) {
      if (previousDisableState && !props.isDisabled && props.userInteracted[props.parentCategoryName]) {
        setImmediate(() => {
          selectRef.current.focus()
        })
      }
    }
  }, [previousDisableState, props.isDisabled, props.userInteracted]); // eslint-disable-line

  // Methods
  const onSearch = useCallback(_.debounce((value) => {
    props.handleSearch(value)
      .then(response => setOptions(response.results))
      .catch(() => setOptions([]));
  }, 200), [props.handleSearch]);

  const onFocus = () => {
    if (props.setUserInteracted) {
      const userInteractedValues = {
        ...props.userInteracted,
        [props.name]: true
      }
      props.setUserInteracted(userInteractedValues);
    }

    props.handleFocus()
      .then(response => setOptions(response.results))
      .catch(() => setOptions([]));

    setIsOptionsOpen(true);
  };

  // Render

  return (
    <React.Fragment>

      <Controller
        name={props.name}
        render={({ onChange, value }) => {
          let modifiedOptions = options;
          if (!options.length && value && value.length && typeof value[0] === "object") modifiedOptions = [...value];
          if (!options.length && value && value.id) modifiedOptions = [value];

          let modifiedValue = value && value.id ? value.id : value;
          if (Array.isArray(value)) {
            modifiedValue = value.map(v => typeof v === "object" ? v.id : v);
          }

          return (
            <div className={`select-wrapper ${props.isTwoInRow ? "two-in-row" : ""}`}>
              {props.label && <label className="additional-carousel--label">{`${props.label.toUpperCase()}`}</label>}

              <Select
                className={props.className}
                placeholder={props.placeholder}
                onSearch={onSearch}
                showSearch={props.isShowSearch}
                notFoundContent={null}
                filterOption={false}
                mode={mode}
                value={modifiedValue}
                onChange={newValue => {
                  onChange(newValue || null);
                  triggerPatch(props.name, newValue);

                  if (!props.isMultiple) {
                    setIsOptionsOpen(false);
                    selectRef.current.blur();
                  }
                }}
                optionLabelProp={props.optionLabelProp || "children"}
                disabled={props.isDisabled}
                allowClear
                onClear={props.handleClear}
                onFocus={() => props.shouldFocus ? onFocus() : setIsOptionsOpen(true)}
                onBlur={() => setIsOptionsOpen(false)}
                ref={selectRef}
                open={isOptionsOpen}
              >
                {
                  modifiedOptions.map(item => (
                    <Select.Option key={item.id} value={item.id} label={item[props.labelValueForOption] || ""}>
                      {item.title}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
          )
        }}
      >
      </Controller>
    </React.Fragment>
  )
};

SharedSelectForForm.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isTwoInRow: PropTypes.bool,
  isShowSearch: PropTypes.bool,
  shouldFocus: PropTypes.bool,
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  handleFocus: PropTypes.func,
  itemToRenderProcessing: PropTypes.bool,
  dataLoaded: PropTypes.bool,
  userInteracted: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setUserInteracted: PropTypes.func,
  parentCategoryName: PropTypes.string,
  optionLabelProp: PropTypes.string,
  labelValueForOption: PropTypes.string
}

export default SharedSelectForForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";

import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditCategories from "./AddEditCategories";
import CategoriesRow from "./CategoriesRow";

import {
  getCategoriesRequest,
  updateCategoriesRequest,
  deleteCategoriesRequest
} from "../../../redux/categories/categoriesActions";


function Categories(props) {
  const { history, location, items, total, userRole } = props;

  const [selected, setSelected] = useState(null);
  const { search, page, perPage, order } = navigation.getTableQueryParams(location);

  const creationPermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1");

  // Pagination/search api call here
  useEffect(() => {
    props.getCategories({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line


  // Methods
  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onChangeActive = (itemUrl, event) => {
    props.updateCategories(itemUrl, { isActive: event.target.checked });
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) {
      target = selected.url;
    }

    props.deleteCategories(target, () => message.success("Category was succesfully deleted", 2));
  };

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const onAddSubClick = itemUrl => {
    history.push({
      pathname: `${location.pathname}/add`,
      search: `?parent=${itemUrl}`
    });
  };

  // Render

  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="categories">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            {creationPermission && <AddButton onClick={onAddClick} />}
            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["40px", "auto", "20%", "10%", "10%", "142px"]}
            headerCells={[
              {},
              { label: "Name" },
              { label: "Url" },
              { label: "Items" },
              { label: "Artisans" },
              { label: "Actions" },
            ]}
          >
            {({ columnWidths }) => {
              return itemsToRender.map(item => {
                return (
                  <CategoriesRow
                    key={`catalog-entity:${item.id}`}
                    columnWidths={columnWidths}
                    item={item}
                    onChangeActive={onChangeActive}
                    onEdit={onStartEditClick}
                    onAddSub={onAddSubClick}
                    onRemove={onRemove}
                    isSearch={!!search}
                  />
                )
              })
            }}
            {
              total && (
                <TableFooter key="table-footer" page={page} perPage={perPage} total={total} />
              )
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditCategories
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

Categories.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  getCategories: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  deleteCategories: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    items: state.categories.items,
    total: state.categories.total,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getCategories: params => dispatch(getCategoriesRequest(params)),
    updateCategories: (url, data) => dispatch(updateCategoriesRequest(url, data, true)),
    deleteCategories: (url, callback) => dispatch(deleteCategoriesRequest(url, callback)),
  })
)(Categories);

import fetch from "./customFetch";


const currency = {
  async get({ page, perPage }) {
    try {
      return await fetch({
        url: `/settings/currency/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async create(data) {
    try {
      return await fetch({
        url: "/settings/currency/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatch(id, data) {
    try {
      return await fetch({
        url: `/settings/currency/${id}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePut(id, data) {
    try {
      return await fetch({
        url: `/settings/currency/${id}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async delete(id) {
    try {
      return await fetch({
        url: `/settings/currency/${id}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getRates({ page, perPage }) {
    try {
      return await fetch({
        url: `/settings/currency/rates/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateRatesPatch(id, data) {
    try {
      return await fetch({
        url: `/settings/currency/rates/${id}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getFeed({ page, perPage }) {
    try {
      return await fetch({
        url: `/settings/currency/feed/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default currency;

import { useQuery, useMutation, useQueryClient } from "react-query";

// helpers
import fetch from "../../../../api/customFetch";
import buildQueryString from "../../../../helpers/buildQueryString";


const DIRECTORY_SPECIALITIES_URL = "/directory/specialities/";
const FAIRS_SPECIALITIES_URL = "/fairs/specialities/";


export function useSpecialities({ type = "directory", params }) {
  const url = type === "directory" ? DIRECTORY_SPECIALITIES_URL : FAIRS_SPECIALITIES_URL;
  const fetchKey = [
    url,
    buildQueryString(params)
  ].filter(Boolean).join("?");

  return useQuery([fetchKey], () => fetch({ url: fetchKey, method: "GET" }), {
    enabled: !!url,
  });
}

export function useSingleSpeciality({ id, type, options = {} }) {
  const url = type === "directory" ? DIRECTORY_SPECIALITIES_URL : FAIRS_SPECIALITIES_URL;
  const fetchKey = `${url}${id}/`;

  return useQuery([fetchKey], () => fetch({ url: fetchKey, method: "GET" }), {
    enabled: !!fetchKey,
    ...options
  });
}

export function usePatchSpeciality({ type = "directory", options = {} }) {
  const queryClient = useQueryClient();

  const url = type === "directory" ? DIRECTORY_SPECIALITIES_URL : FAIRS_SPECIALITIES_URL;
  const keysToInvalidate = queryClient.queryCache.queries.filter(item => item.queryKey.join("").includes(url)).map(item => item.queryKey).flat();

  return useMutation(({ id, data }) => fetch({ url: `${url}${id}/`, method: "PATCH", data }), {
    onSuccess: () => queryClient.invalidateQueries(keysToInvalidate),
    ...options
  });
}

export function useCreateSpeciality({ type = "directory", options = {} }) {
  const queryClient = useQueryClient();

  const url = type === "directory" ? DIRECTORY_SPECIALITIES_URL : FAIRS_SPECIALITIES_URL;
  const keysToInvalidate = queryClient.queryCache.queries.filter(item => item.queryKey.join("").includes(url)).map(item => item.queryKey).flat();

  return useMutation((data) => fetch({ url, method: "POST", data }), {
    onSuccess: () => queryClient.invalidateQueries(keysToInvalidate),
    ...options
  });
}

export function useDeleteSpeciality({ type = "directory" }) {
  const queryClient = useQueryClient();

  const url = type === "directory" ? DIRECTORY_SPECIALITIES_URL : FAIRS_SPECIALITIES_URL;
  const keysToInvalidate = queryClient.queryCache.queries.filter(item => item.queryKey.join("").includes(url)).map(item => item.queryKey).flat();

  return useMutation((id) => fetch({ url: `${url}${id}/`, method: "DELETE" }), {
    onSuccess: () => queryClient.invalidateQueries(keysToInvalidate),
  });
}

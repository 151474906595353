import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Upload } from "antd";
import Icon from "@ant-design/icons";


function ImageUpload({
  lastUploadedImage,
  setImage,
  topText,
  bottomText,
  width,
  height,
  multiple = false,
  error,
  max,
  fileList = [],
  onChange
}) {
  const [loading, setLoading] = useState(false);
  const [listOfImages, setListOfImages] = useState([]);

  useEffect(() => {
    if (error) setLoading(false);
  }, [error]); // eslint-disable-line

  useEffect(() => {
    if (fileList.length > listOfImages.length) setLoading(false);

    setListOfImages(fileList);
  }, [fileList.length]); // eslint-disable-line

  const customRequest = file => {
    setLoading(true);
    setImage(file);
  };

  const renderUploadContent = () => {
    if (!multiple && lastUploadedImage && lastUploadedImage.url) {
      return (
        <img className="image-upload--image" src={lastUploadedImage.url} alt="avatar" />
      );
    }

    if (multiple && fileList.length === max) return;

    return (
      <Icon
        style={{ height: (multiple && height) ? `${height}px` : null }}
        className="image-upload--icon"
        type={loading ? "loading" : "picture"}
      />
    )
  };

  // Render

  return (
    <div className="image-upload">
      { topText && <span className="image-upload--top-text">{topText}</span>}

      <div
        className="image-upload--uploader"
        style={{ maxWidth: width ? `${width}px` : "100%", height: (!multiple && height) ? `${height}px` : "100%" }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          showUploadList={multiple ? {
            showDownloadIcon: false,
            showRemoveIcon: !loading
          } : false}
          customRequest={({ file }) => customRequest(file)}
          fileList={fileList}
          onChange={onChange}
        >
          {renderUploadContent()}
        </Upload>
      </div>

      { bottomText && <span className="image-upload--bottom-text">{bottomText}</span> }
      { error && <p className="labeled-input--error">{ error }</p> }
    </div>
  );
}

ImageUpload.propTypes = {
  setImage: PropTypes.func.isRequired,
  lastUploadedImage: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  max: PropTypes.number,
  fileList: PropTypes.array,
  onChange: PropTypes.func
};

export default ImageUpload;

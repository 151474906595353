import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox, Row, Col, message } from "antd";

import {
  getAdvancedPreferencesRequest,
  patchAdvancedPreferencesRequest,
  patchSystemPreferencesRequest,
  patchGeneralPropertiesRequest,
  resetErrorRequest,
  resetErrorsRequest
} from "../../../redux/settings/systemPreferences/systemPreferencesActions";

import { getCurrencyCodesRequest } from "../../../redux/settings/currency/currencyCodes/currencyCodesActions";

import LabeledInput from "../../shared/LabeledInput";


function SystemPreferences(
  {
    getAdvancedPreferences,
    getCurrency,
    patchAdvancedPreferences,
    patchSystemPreferences,
    data,
    currency,
    advancedFields,
    systemPreferencesFields,
    generalPropertiesField,
    patchGeneralProperties,
    errors,
    loading,
    resetError,
    resetErrors
  }) {

  useEffect(() => {
    getAdvancedPreferences();
    getCurrency({ page: 1, perPage: 200 });

    return () => {
      resetErrors();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loading) {
      message.loading({ content: "Saving..." });
    } else {
      setTimeout(() => message.destroy(), 300);
    }
  }, [loading]); // eslint-disable-line

  // Methods
  const onSave = (value, key) => {
    resetError(key);

    if (advancedFields.includes(key)) {
      patchAdvancedPreferences({
        [key]: value
      });
    }

    if (systemPreferencesFields.includes(key)) {
      patchSystemPreferences({
        [key]: value
      })
    }

    if (generalPropertiesField.includes(key)) {
      patchGeneralProperties({
        [key]: value
      })
    }
  };

  const currencyOptions = currency.map(e => ({ label: e.currency, value: e.id }));

  return (
    <div className="component system-preferences">
      <div className="general-form--container">
        <p className="general-form--title mb-16">General Properties</p>

        <LabeledInput
          label="Site Title"
          defaultValue={data.siteTitle}
          onBlur={value => onSave(value, "siteTitle")}
          errorText={errors.siteTitle && errors.siteTitle[0]}
        />

        <LabeledInput
          label="Site Keywords"
          type="textarea"
          defaultValue={data.siteKeywords}
          onBlur={value => onSave(value, "siteKeywords")}
          errorText={errors.siteKeywords && errors.siteKeywords[0]}
        />

        <LabeledInput
          label="Site Description"
          type="textarea"
          defaultValue={data.siteDescription}
          onBlur={value => onSave(value, "siteDescription")}
          errorText={errors.siteDescription && errors.siteDescription[0]}
        />
      </div>
      <div className="general-form--container">
        <p className="general-form--title">System Preferences</p>
        <div className="general-form--checkbox-holder">
          <Checkbox
            checked={data.allowUserRegistration}
            className="checkbox-small"
            onChange={e => onSave(e.target.checked, "allowUserRegistration")}
          >
            Allow user registration
          </Checkbox>
        </div>
        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="Google Analytics Account"
              defaultValue={data.googleAnalyticsAccount}
              onBlur={value => onSave(value, "googleAnalyticsAccount")}
              errorText={errors.googleAnalyticsAccount && errors.googleAnalyticsAccount[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="Google Analytics Domain"
              defaultValue={data.googleAnalyticsDomain}
              onBlur={value => onSave(value, "googleAnalyticsDomain")}
              errorText={errors.googleAnalyticsDomain && errors.googleAnalyticsDomain[0]}
            />
          </div>
        </div>
        <LabeledInput
          label="Google Map API Key"
          defaultValue={data.googleMapApiKey}
          onBlur={value => onSave(value, "googleMapApiKey")}
          errorText={errors.googleMapApiKey && errors.googleMapApiKey[0]}
        />
        <Row gutter={30}>
          <Col span={8}>
            <LabeledInput
              label="Primary Fixed Currency"
              type="select"
              value={data.primaryFixedCurrency && data.primaryFixedCurrency.id}
              options={currencyOptions}
              onChange={value => onSave(value, "primaryFixedCurrency")}
              errorText={errors.primaryFixedCurrency && errors.primaryFixedCurrency[0]}
            />
          </Col>
          <Col span={8}>
            <LabeledInput
              label="Second Fixed Currency"
              type="select"
              value={data.secondFixedCurrency && data.secondFixedCurrency.id}
              options={currencyOptions}
              onChange={value => onSave(value, "secondFixedCurrency")}
              errorText={errors.secondFixedCurrency && errors.secondFixedCurrency[0]}
            />
          </Col>
          <Col span={8}>
            <LabeledInput
              label="Third Fixed Currency"
              type="select"
              value={data.thirdFixedCurrency && data.thirdFixedCurrency.id}
              options={currencyOptions}
              onChange={value => onSave(value, "thirdFixedCurrency")}
              errorText={errors.thirdFixedCurrency && errors.thirdFixedCurrency[0]}
            />
          </Col>
        </Row>
        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="Support Email"
              defaultValue={data.supportEmail}
              onBlur={value => onSave(value, "supportEmail")}
              errorText={errors.supportEmail && errors.supportEmail[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="Dealer Contact Telephone"
              defaultValue={data.dealerContactTelephone}
              onBlur={value => onSave(value, "dealerContactTelephone")}
              errorText={errors.dealerContactTelephone && errors.dealerContactTelephone[0]}
            />
          </div>
        </div>
      </div>
      <div className="general-form--container">
        <p className="general-form--title mb-16">Advanced</p>
        <LabeledInput
          label="Declaration Text Default"
          type="textarea"
          defaultValue={data.declarationTextDefault}
          onBlur={value => onSave(value, "declarationTextDefault")}
          errorText={errors.declarationTextDefault && errors.declarationTextDefault[0]}
        />

        <LabeledInput
          label="SMS Message for Callback Enquiries"
          type="textarea"
          defaultValue={data.smsMessageForCallbackEnquiries}
          onBlur={value => onSave(value, "smsMessageForCallbackEnquiries")}
          errorText={errors.smsMessageForCallbackEnquiries && errors.smsMessageForCallbackEnquiries[0]}
        />

        <LabeledInput
          label="SMS Message for Dealer Enquiries"
          rows={1}
          type="textarea"
          defaultValue={data.smsMessageForDealerEnquiries}
          onBlur={value => onSave(value, "smsMessageForDealerEnquiries")}
          errorText={errors.smsMessageForDealerEnquiries && errors.smsMessageForDealerEnquiries[0]}
        />

        <LabeledInput
          label="SMS Message for Item Enquiries"
          rows={2}
          type="textarea"
          defaultValue={data.smsMessageForItemEnquiries}
          onBlur={value => onSave(value, "smsMessageForItemEnquiries")}
          errorText={errors.smsMessageForItemEnquiries && errors.smsMessageForItemEnquiries[0]}
        />
      </div>
    </div>
  )
}

SystemPreferences.propTypes = {
  getAdvancedPreferences: PropTypes.func.isRequired,
  getCurrency: PropTypes.func.isRequired,
  patchAdvancedPreferences: PropTypes.func.isRequired,
  patchSystemPreferences: PropTypes.func.isRequired,
  patchGeneralProperties: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  advancedFields: PropTypes.array.isRequired,
  systemPreferencesFields: PropTypes.array.isRequired,
  generalPropertiesField: PropTypes.array.isRequired,
  currency: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object])
};

export default connect(
  state => ({
    ...state.systemPreferences,
    currency: state.currencyCodes.items
  }),
  dispatch => ({
    getAdvancedPreferences: () => dispatch(getAdvancedPreferencesRequest()),
    getCurrency: payload => dispatch(getCurrencyCodesRequest(payload)),
    patchAdvancedPreferences: payload => dispatch(patchAdvancedPreferencesRequest(payload)),
    patchSystemPreferences: payload => dispatch(patchSystemPreferencesRequest(payload)),
    patchGeneralProperties: payload => dispatch(patchGeneralPropertiesRequest(payload)),
    resetError: payload => dispatch(resetErrorRequest(payload)),
    resetErrors: () => dispatch(resetErrorsRequest())
  })
)(SystemPreferences);

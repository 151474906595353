import * as types from "./adminAccountsActionsTypes";

const initialState = {
  adminAccounts: [],
  total: 0,
  adminRoles: [],
  historyList: [],
  historyTotal: 0,
  error: null,
  processing: false,
  searchedAdmins: [],
  loading: false
};

const adminAccounts = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.GET_ADMIN_ACCOUNTS_REQUEST_SUCCESS:
      return {
        ...state,
        adminAccounts: action.payload.results,
        total: action.payload.count,
      };

    case types.GET_ADMIN_ROLES_REQUEST_SUCCESS:
      return {
        ...state,
        adminRoles: action.payload
      };

    case types.SEARCH_ADMINS_REQUEST_SUCCESS:
      return {
        ...state,
        searchedAdmins: action.payload
      };

    case types.GET_ADMIN_ACCOUNTS_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        historyList: action.payload.results,
        historyTotal: action.payload.count
      };

    case types.DELETE_ADMIN_ACCOUNT_REQUEST_SUCCESS:
    case types.UPDATE_ADMIN_ACCOUNT_REQUEST_SUCCESS:
    case types.ADD_ADMIN_ACCOUNT_REQUEST_SUCCESS:
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case types.GET_ADMIN_ACCOUNTS_REQUEST_ERROR:
    case types.DELETE_ADMIN_ACCOUNT_REQUEST_ERROR:
    case types.UPDATE_ADMIN_ACCOUNT_REQUEST_ERROR:
    case types.GET_ADMIN_ROLES_REQUEST_ERROR:
    case types.ADD_ADMIN_ACCOUNT_REQUEST_ERROR:
    case types.SEARCH_ADMINS_REQUEST_ERROR:
    case types.RESET_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload
      };

    default:
      return state;
  }
};

export default adminAccounts;

import React from "react";
import PropTypes from "prop-types";


function UserManagementIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -395.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-31-icon/24/users" transform="translate(16.000000, 392.000000)">
              <path d="M4,20 C4,16.4232988 6.89948924,13.5238095 10.4761905,13.5238095 C14.0528917,13.5238095 16.952381,16.4232988 16.952381,20 L15.3333333,20 C15.3333333,17.3174741 13.1587164,15.1428571 10.4761905,15.1428571 C7.79366455,15.1428571 5.61904762,17.3174741 5.61904762,20 L4,20 L4,20 Z M10.4761905,12.7142857 C7.79261905,12.7142857 5.61904762,10.5407143 5.61904762,7.85714286 C5.61904762,5.17357143 7.79261905,3 10.4761905,3 C13.1597619,3 15.3333333,5.17357143 15.3333333,7.85714286 C15.3333333,10.5407143 13.1597619,12.7142857 10.4761905,12.7142857 Z M10.4761905,11.0952381 C12.2652381,11.0952381 13.7142857,9.64619048 13.7142857,7.85714286 C13.7142857,6.06809524 12.2652381,4.61904762 10.4761905,4.61904762 C8.68714286,4.61904762 7.23809524,6.06809524 7.23809524,7.85714286 C7.23809524,9.64619048 8.68714286,11.0952381 10.4761905,11.0952381 Z M17.1822857,14.0929048 C19.5060844,15.1394805 21.0002619,17.4514002 21,20 L19.3809524,20 C19.3813314,18.0884464 18.2606526,16.3543567 16.5176667,15.5694762 L17.1814762,14.0929048 L17.1822857,14.0929048 Z M16.6253333,4.95338095 C18.2933983,5.64095091 19.3816226,7.26721331 19.3809527,9.07142857 C19.3816589,11.3739628 17.626368,13.2970595 15.3333333,13.506 L15.3333333,11.8764286 C16.564631,11.7000785 17.5369227,10.7412789 17.7304604,9.51256558 C17.9239981,8.28385221 17.2935173,7.07259597 16.1760476,6.52628571 L16.6253333,4.95338095 L16.6253333,4.95338095 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

UserManagementIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default UserManagementIcon

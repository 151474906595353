import * as types from "./periodsActionTypes";


const initialState = {
  items: [],
  total: 0,
  error: "",
  processing: false,
};


const periods = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    case types.GET_PERIODS_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count,
      };

    case types.PERIODS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ""
      };

    default:
      return state;
  }
};

export default periods;

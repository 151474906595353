import React, { useEffect, useState } from "react";
import { Popconfirm } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  patchDealerStatusDataRequest,
} from "../../../../../redux/dealers/dealersActions";

const DoubleStatusesBlock = ({
  value,
  leftSideTitle,
  rightSideTitle,
  leftSideValue,
  rightSideValue,
  dealerUrl,
  patchDealerStatusData,
  statusTitle,
}) => {
  const [activeSide, setActiveSide] = useState(value);

  const leftSideClass = activeSide === leftSideValue ?
    "double-toggle-button--left-side-active" :
    "double-toggle-button--left-side";
  const rightSideClass = activeSide === rightSideValue ?
    "double-toggle-button--right-side-active" :
    "double-toggle-button--right-side";

  // Effects

  useEffect(() => {
    setActiveSide(value);
  }, [value]);

  // Methods

  const patchDealerStatuses = (valueToSet) => {
    const dataToSend = {};

    dataToSend[statusTitle] = valueToSet;
    patchDealerStatusData({ data: dataToSend, url: dealerUrl });
  };

  // Render

  return (
    <div className="double-toggle-button">
      <Popconfirm
        title="Are you sure? Changes you are going to make will impact dealer’s page/items appearance on the website"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          setActiveSide(leftSideValue);
          patchDealerStatuses(leftSideValue);
        }}
      >
        <div
          className={leftSideClass}
        >
          {leftSideTitle}
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure? Changes you are going to make will impact dealer’s page/items appearance on the website"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          setActiveSide(rightSideValue);
          patchDealerStatuses(rightSideValue);
        }}
      >
        <div
          className={rightSideClass}
        >
          {rightSideTitle}
        </div>
      </Popconfirm>
    </div>
  );
};

DoubleStatusesBlock.propTypes = {
  leftSideTitle: PropTypes.string.isRequired,
  rightSideTitle: PropTypes.string.isRequired,
  dealerUrl: PropTypes.string,
  value: PropTypes.bool,
  rightSideValue: PropTypes.bool.isRequired,
  leftSideValue: PropTypes.bool.isRequired,
  patchDealerStatusData: PropTypes.func.isRequired,
  statusTitle: PropTypes.string.isRequired,
};


export default connect(
  null,
  dispatch => ({
    patchDealerStatusData: (entityUrl, data) => dispatch(patchDealerStatusDataRequest(entityUrl, data)),
  })
)(DoubleStatusesBlock);
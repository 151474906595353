import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Checkbox } from "antd";
import AddButton from "../../../../shared/AddButton";
import Table from "../../../../shared/Table/Table";
import TableRow from "../../../../shared/Table/TableRow";
import TableFooter from "../../../../shared/Table/TableFooter";
import EditIcon from "../../../../Icon/EditIcon";

import {
  getDealerAddressesRequest,
  patchDealerAddressRequest
} from "../../../../../redux/dealers/dealersActions";
import navigation from "../../../../../helpers/navigation";

const AddressTab = ({
  match,
  history,
  location,
  getDealerAddresses,
  dealerAddresses,
  patchDealerAddress
}) => {
  const url = match.params.url;
  const { page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getDealerAddresses({ url });
  }, [match]); // eslint-disable-line

  const onCheck = (e, data) => {
    data = Object.assign(data, { isActive: e.target.checked });
    patchDealerAddress({
      data,
      url
    })
  };

  const renderAddressColumn = (address, region, city, country) => {
    return (
      <>
        <div className="dealer-address-tab--address-column--address">{address}</div>
        <div className="dealer-address-tab--address-column--region">{region}</div>
        <div className="dealer-address-tab--address-column--city">{city}</div>
        <div className="dealer-address-tab--address-column--country">{country}</div>
      </>
    );
  };

  const renderContactColumn = (email, phone, mobile) => {
    return (
      <div>
        <div className="dealer-address-tab--contact-column--email-row">
          <div className="dealer-address-tab--contact-column--email-label">Email</div>
          <div className="dealer-address-tab--contact-column--email-value">{email}</div>
        </div>
        <div className="dealer-address-tab--contact-column--phone-row">
          <div className="dealer-address-tab--contact-column--phone-label">Phone</div>
          <div className="dealer-address-tab--contact-column--phone-value">{phone}</div>
        </div>
        <div className="dealer-address-tab--contact-column--mobile-row">
          <div className="dealer-address-tab--contact-column--mobile-label">Mobile</div>
          <div className="dealer-address-tab--contact-column--mobile-value">{mobile}</div>
        </div>
      </div>
    )
  };

  const renderAddressesTable = () => (
    <>
      <Table
        className="dealer-address-tab--table"
        columnWidths={["40px", "25%", "24%", "42%", "80px"]}
        headerCells={[
          {},
          { label: "ADDRESS" },
          { label: "CONTACT" },
          { label: "LOCATION" },
          { label: "ACTION" }
        ]}
      >
        {
          dealerAddresses && dealerAddresses.map((addressData) => {
            const {
              id,
              isActive,
              address,
              email,
              phone,
              mobile,
              region,
              city,
              country
            } = addressData;

            return (
              <TableRow key={`dealer-address-tab--row-${id}`}>
                <Checkbox
                  checked={isActive}
                  onChange={e => onCheck(e, addressData)}
                />
                <div className="dealer-address-tab--address-column flex">
                  {renderAddressColumn(address, region, city, country)}
                </div>
                <div className="dealer-address-tab--contact-column flex">
                  {renderContactColumn(email, phone, mobile)}
                </div>
                <div className="dealer-address-tab--location-column flex">
                  {country}
                </div>
                <div className="dealer-address-tab--actions-column flex">
                  <EditIcon
                    className="pointer"
                    onClick={() => history.push(`/dealers/dealers-list/${url}/addresses-tab/${id}`)}
                  />
                </div>
              </TableRow>
            )
          })
        }

        {dealerAddresses && dealerAddresses.length > 0 && (
          <TableFooter
            key="address-tab-table"
            page={page}
            perPage={perPage}
            total={dealerAddresses.length}
          />
        )}
      </Table>
    </>
  )

  return (
    <div className="dealer-address-tab">
      <div className="dealer-address-tab--add-button">
        <AddButton
          onClick={() => history.push({ pathname: location.pathname.concat("/add-address") })}
        />
      </div>

      {renderAddressesTable()}

    </div>
  )
}

AddressTab.propTypes = {
  match: PropTypes.object.isRequired,
  getDealerAddresses: PropTypes.func.isRequired,
  dealerAddresses: PropTypes.array.isRequired,
  patchDealerAddress: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  ({ dealers }) => dealers,
  dispatch => ({
    getDealerAddresses: payload => dispatch(getDealerAddressesRequest(payload)),
    patchDealerAddress: payload => dispatch(patchDealerAddressRequest(payload)),
  })
)(withRouter(AddressTab));

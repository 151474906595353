import fetch from "./customFetch";

const items = {
  async getItems({ page = 1, perPage = 10, query = {} }) {
    const { status, ordering, dealer, by_ref, has_artisan, is_approved } = query;
    const queryParams = new URLSearchParams();

    let statusField = "status";
    if (ordering === "DEALER_WEBSITE") {
      statusField = "wb_status";
    } else {
      statusField = "la_status";
    }

    queryParams.set(statusField, status.toUpperCase());

    if (ordering === "LOVE_ANTIQUES" || ordering === "DEALER_WEBSITE") {
      queryParams.set("show_in", `${ordering},BOTH`);
    } else {
      queryParams.set("ordering", ordering);
      queryParams.set("show_in", "BOTH,LOVE_ANTIQUES");
    }
    if (dealer) queryParams.set("dealer", dealer);
    if (by_ref) queryParams.set("by_ref", by_ref);
    if (has_artisan) queryParams.set("has_artisan", has_artisan);
    if (is_approved) queryParams.set("is_approved", is_approved);
    queryParams.set("exclude", "DRAFT");

    try {
      return await fetch({
        url: `/items/?page=${page}&page_size=${perPage}&${queryParams.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getItemsWithSearch({ page = 1, perPage = 10, query }) {
    const { status, ordering, dealer, by_ref, has_artisan, is_approved, query: searchQuery } = query;
    const queryParams = new URLSearchParams();

    let statusField = "status";
    if (ordering === "LOVE_ANTIQUES") {
      statusField = "la_status";
    }
    if (ordering === "DEALER_WEBSITE") {
      statusField = "wb_status";
    }

    queryParams.set(statusField, status.toUpperCase());

    if (ordering === "LOVE_ANTIQUES" || ordering === "DEALER_WEBSITE") {
      queryParams.set("show_in", `${ordering},BOTH`);
    } else {
      queryParams.set("ordering", ordering);
      queryParams.set("show_in", "BOTH,LOVE_ANTIQUES");
    }

    if (searchQuery) queryParams.set("query", searchQuery);
    if (dealer) queryParams.set("dealer", dealer);
    if (by_ref) queryParams.set("by_ref", by_ref);
    if (has_artisan) queryParams.set("has_artisan", has_artisan);
    if (is_approved) queryParams.set("is_approved", is_approved);
    queryParams.set("exclude", "DRAFT");

    try {
      return await fetch({
        url: `/items/search/?page=${page}&page_size=${perPage}&${queryParams.toString()}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getItemDetails(ref) {
    try {
      return await fetch({
        url: `/items/${ref}/`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchItems(id, data) {
    try {
      return await fetch({
        url: `/items/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getChoices() {
    try {
      return await fetch({
        url: "/items/choices/",
        method: "GET"
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteItemsCatalogElement(id, itemId) {
    try {
      return await fetch({
        url: `/items/${itemId}/catalog-element/`,
        method: "DELETE",
        data: {
          id
        }
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateItemsStatus(ref, data) {
    try {
      return await fetch({
        url: `/items/${ref}/status/`,
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postRotateImage(data) {
    try {
      return await fetch({
        url: "/items/rotate/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateShowItemStatus({ ref, data }) {
    try {
      return await fetch({
        url: `/items/${ref}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getItemHistory(itemRef) {
    try {
      return await fetch({
        url: `/items/history/?item=${itemRef}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default items;

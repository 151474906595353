import { all, put, call, takeLatest } from "redux-saga/effects";


import * as types from "./pagesActionsTypes";
import pages from "../../../api/content";


function *getPages (action) {
  try {
    yield put({ type: types.LOADING, payload: true });
    const response = yield call(pages.getPages, action.payload);
    yield put({ type: types.GET_CONTENT_PAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_CONTENT_PAGES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.LOADING, payload: false });
  }
}

function *createNewPage (action) {
  try {
    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: true });
    yield call(pages.createNewPage, action.payload);
    yield put({ type: types.CREATE_NEW_PAGE_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_NEW_PAGE_REQUEST_FAILED, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: false });
  }
}

function *updatePageStatus (action) {
  try {
    const { pageUpdate, page, perPage } = action.payload;

    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: true });
    yield call(pages.patchPageStatus, pageUpdate);
    yield put({ type: types.PATCH_PAGES_STATUS_SUCCESS });
    yield put({ type: types.GET_CONTENT_PAGES_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_PAGES_STATUS_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: false });
  }
}

function *updatePage (action) {
  try {
    const { id, data, pagePerPage } = action.payload;

    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: true });
    yield call(pages.updatePage, id, data);
    yield put({ type: types.UPDATE_PAGE_REQUEST_SUCCESS });
    yield put({ type: types.GET_CONTENT_PAGES_REQUEST, payload: { pagePerPage } })
  } catch (error) {
    yield put({ type: types.UPDATE_PAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: false });
  }
}

function *deletePage (action) {
  try {
    const { id, pagePerPage } = action.payload;

    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: true });
    yield call(pages.deletePage, id);
    yield put({ type: types.DELETE_PAGE_REQUEST_SUCCESS });
    yield put({ type: types.GET_CONTENT_PAGES_REQUEST, payload: { pagePerPage } })
  } catch (error) {
    yield put({ type: types.DELETE_PAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PAGE_PROCESSING, payload: false });
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_CONTENT_PAGES_REQUEST, getPages),
    yield takeLatest(types.CREATE_NEW_PAGE_REQUEST, createNewPage),
    yield takeLatest(types.UPDATE_PAGE_REQUEST, updatePage),
    yield takeLatest(types.PATCH_PAGES_STATUS_REQUEST, updatePageStatus),
    yield takeLatest(types.DELETE_PAGE_REQUEST, deletePage),
  ])
}

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";


const renderSearchDealers = (collection) => {
  return (
    <div className="search-results--collection" key="SearchDealers">
      <p className="search-results--name-of-collection">Dealers</p>
      {
        collection.map(({ id, businessName, url }) => {
          return (
            <div className="search-result--elements-of-collection" key={`${businessName}-${id}`}>
              <Link
                className="search-results--title-of-element"
                to={`/dealers/dealers-list/${url}`}
              >
                {businessName}
              </Link>
            </div>
          )
        })
      }
    </div>
  )
};

const renderSearchUsersCollection = (collection) => {
  return (
    <div className="search-results--collection" key="SearchUsersCollection">
      <p className="search-results--name-of-collection">Users</p>
      {
        collection.map(({ firstName, lastName }, index) => {
          const userName = `${firstName}-${lastName}`;

          return (
            <div className="search-result--elements-of-collection" key={`${userName}-${index}`}>
              <Link
                className="search-results--title-of-element"
                to="/user-management/users/"
              >
                {`${firstName} ${lastName}`}
              </Link>
            </div>
          )
        })
      }

    </div>
  )
};

const renderSearchArtisansCollection = (collection) => {
  return (
    <div className="search-results--collection" key="SearchArtisansCollection">
      <p className="search-results--name-of-collection">Artisans</p>
      {
        collection.map(({ name }, index) => {
          return (
            <div className="search-result--elements-of-collection" key={`${name}-${index}`}>
              <Link
                className="search-results--title-of-element"
                to="/catalog/artisan"
              >
                {name}
              </Link>
            </div>
          )
        })
      }

    </div>
  )
};

const renderSearchPeriods = (collection) => {
  return (
    <div className="search-results--collection" key="SearchPeriods">
      <p className="search-results--name-of-collection">Periods</p>
      {
        collection.map(({ id, title }) => {
          return (
            <div className="search-result--elements-of-collection" key={`${title}-${id}`}>
              <Link
                className="search-results--title-of-element"
                to="/catalog/period"
              >
                {title}
              </Link>
            </div>
          )
        })
      }
    </div>
  )
};

const renderSearchResults = (items, blockTitle, linkPrefix, itemDisplayKey = "title", itemLinkKey) => {
  return (
    <div className="search-results--collection" key={`searchResults-${blockTitle}`}>
      <p className="search-results--name-of-collection">{blockTitle}</p>
      {
        items.map((item, index) => {
          let link = linkPrefix;
          if (itemLinkKey) link += `/${item[itemLinkKey]}`;
          else if (item.catalog) link += `/${item.catalog.toLowerCase()}`;

          return (
            <div className="search-result--elements-of-collection" key={`${blockTitle}-${index}`}>
              <Link
                className="search-results--title-of-element"
                to={link}
              >
                {item[itemDisplayKey] || ""}
              </Link>
            </div>
          )
        })
      }
    </div>
  )
}


const SearchResults = ({ searchResult = {} }) => {
  const renderSearchResult = () => {
    const {
      items,
      catalogElements,
      categories,
      users,
      artisans,
      dealers,
      orders,
      periods,
    } = searchResult;
    const renderItems = !_.isEmpty(items) && renderSearchResults(items, "Items", "/items");
    const renderCatalogElements = !_.isEmpty(catalogElements) && renderSearchResults(catalogElements, "Catalog", "/catalog");
    const renderCategories = !_.isEmpty(categories) && renderSearchResults(categories, "Categories", "/catalog/categories");
    const renderUsers = !_.isEmpty(users) ? renderSearchUsersCollection(users) : null;
    const renderArtisans = !_.isEmpty(artisans) ? renderSearchArtisansCollection(artisans) : null;
    const renderDealers = !_.isEmpty(dealers) ? renderSearchDealers(dealers) : null;
    const renderOrders = !_.isEmpty(orders) ? renderSearchResults(orders, "Orders", "/orders", "title", "id") : null;
    const renderPeriods = !_.isEmpty(periods) ? renderSearchPeriods(periods) : null;

    if (
      !renderItems &&
      !renderCatalogElements &&
      !renderCategories &&
      !renderUsers &&
      !renderArtisans &&
      !renderDealers &&
      !renderOrders &&
      !renderPeriods
    ) {
      return (
        <div>
          <p className="search-results--empty-search">
            No matches found. Please try another search.
          </p>
        </div>
      );
    }

    return (
      <div>
        {
          [
            renderItems,
            renderCatalogElements,
            renderCategories,
            renderUsers,
            renderArtisans,
            renderDealers,
            renderOrders,
            renderPeriods
          ]
        }
      </div>
    )
  };

  return (
    <div className="search-results--wrapper">
      {
        searchResult && renderSearchResult()
      }
    </div>
  );
};

SearchResults.propTypes = {
  searchResult: PropTypes.object,
  processing: PropTypes.bool.isRequired,
};

export default connect(
  ({ dashboard }) => dashboard
)(SearchResults);

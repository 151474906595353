export const SHOW_LOADING = "SHOW_SEO_LOADING";
export const HIDE_LOADING = "HIDE_SEO_LOADING";

export const SHOW_PROCESSING = "SHOW_SEO_PROCESSING";
export const HIDE_PROCESSING = "HIDE_SEO_PROCESSING";

export const SEO_REQUEST_ERROR = "SEO_REQUEST_ERROR";

export const GET_SEO_REQUEST = "GET_SEO_REQUEST";
export const GET_SEO_REQUEST_SUCCESS = "GET_SEO_REQUEST_SUCCESS";

export const PATCH_SEO_REQUEST = "PATCH_SEO_REQUEST";

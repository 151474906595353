import fetch from "./customFetch";
import { UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR } from "../constants";


const orders = {
  async getOrders({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/orders/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async getOrder(id) {
    try {
      return await fetch({
        url: `/orders/${id}/`,
        method: "GET"
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async getOrdersChoices(data) {
    try {
      return await fetch({
        url: "/orders/choices/",
        method: "GET",
        data
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async getDealersList({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/dealers/?page=${page}&page_size=${perPage}`,
        method: "GET",
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async searchOrders({ page, perPage, query }) {
    try {
      return await fetch({
        url: `/orders/search/?page=${page}&page_size=${perPage}&query=${query}`,
        method: "GET"
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async patchOrder(data) {
    try {
      return await fetch({
        url: `/orders/${data.id}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },

  async getFilteredOrders(params) {
    const { status, dealer, payment, fromDate, toDate, page, perPage } = params;
    let url = `/orders/?page=${page}&page_size=${perPage}`;

    if (status) url = url.concat("&status=", status);
    if (dealer) url = url.concat("&dealer=", dealer);
    if (payment) url = url.concat("&payment=", payment);
    if (fromDate) url = url.concat("&from_date=", fromDate);
    if (toDate) url = url.concat("&to_date=", toDate);

    try {
      return await fetch({
        url,
        method: "GET"
      });
    }
    catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR;
    }
  },
};

export default orders;

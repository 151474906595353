import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Tabs } from "antd";

// components
import DealersNews from "./DealersNews";
import DealersNewsPopUp from "./DealersNewsPopUp";
import DealersNewsPopUpAddEdit from "./DealersNewsPopUpAddEdit";
import AddNewsItem from "./AddNewsItem";


function DealersNewsRouter() {
  const history = useHistory();
  const { pathname } = history.location;


  return (<>
    <Tabs className="dealers-news--tabs" onChange={history.push} activeKey={pathname}>
      <Tabs.TabPane tab="News" key="/dealers/dealer-news" />
      <Tabs.TabPane tab="Modal News" key="/dealers/dealer-news/pop-up" />
    </Tabs>
    <Switch>
      <Route
        exact
        path="/dealers/dealer-news"
        component={DealersNews}
      />
      <Route
        exact
        path="/dealers/dealer-news/pop-up"
        component={DealersNewsPopUp}
      />
      <Route
        exact
        path="/dealers/dealer-news/pop-up/new"
        component={DealersNewsPopUpAddEdit}
      />
      <Route
        exact
        path="/dealers/dealer-news/pop-up/:id"
        component={DealersNewsPopUpAddEdit}
      />
      <Route exact path="/dealers/dealer-news/add-new" component={AddNewsItem} />
      <Route exact path="/dealers/dealer-news/:url" component={AddNewsItem} />
    </Switch>
  </>);
}

export default DealersNewsRouter;
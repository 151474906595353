import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Empty, Spin } from "antd";
import lodash from "lodash";

// components
import TableHeaderCell from "./TableHeaderCell";

// helpers
import navigation from "../../../helpers/navigation";


function Table(props) {
  const { children, columnWidths, headerCells, className, location, history, isLoading } = props;

  const childrenArray = [];
  if (lodash.isArray(children)) childrenArray.push(...lodash.flattenDeep(children).filter(c => c));
  else if (children) childrenArray.push(children);

  const renderChildren = () => {
    if (!childrenArray.length) {
      return isLoading ? (
        <Spin className="mt-20 pt-16" />
      ) : (
        <Empty key="empty-list" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
    }

    return (
      childrenArray.map(child => {
        if (typeof child === "function") {
          if (!child({ columnWidths }).length) return <Empty key="empty-list" image={Empty.PRESENTED_IMAGE_SIMPLE} />;

          return child({ columnWidths });
        }

        return React.cloneElement(child, { columnWidths })
      })
    )
  };

  return (
    <div className={`table ${className || ""}`}>
      <div className="table--header">
        {headerCells.map((headerCell, index) => {
          const style = {};
          if (columnWidths[index] === "auto") style.flex = 1;
          else style.width = columnWidths[index];

          const headerClassName = headerCell.className ? headerCell.className : "";

          return (
            <TableHeaderCell
              key={`headerCell${index}`}
              className={`table--column table--header-cell ${headerClassName}`}
              style={style}
              label={headerCell.label}
              orderKey={headerCell.orderKey}
              changeOrder={orderKey => navigation.setOrder(orderKey, location, history)}
              currentOrder={navigation.getTableQueryParams(location).order}
            />
          )
        })}
      </div>

      {renderChildren()}
    </div>
  )
}

Table.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  columnWidths: PropTypes.array,
  headerCells: PropTypes.array,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Table);

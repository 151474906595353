import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Popconfirm, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";

//components
import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import TableAndFormLayout from "../../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditArtisansTag from "./AddEditArtisansTag";

//helpers
import navigation from "../../../../helpers/navigation";
import artisans from "../../../../api/artisans";


function ArtisansTags(props) {
  const { userRole } = props;

  const [selected, setSelected] = useState(null);
  const [artisansTags, setArtisansTags] = useState(null);
  const [total, setTotal] = useState(null);

  const location = useLocation();
  const history = useHistory();

  const { page, perPage } = navigation.getTableQueryParams(location);

  const hasUpdatePermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1" || role === "AdminLv2");
  const isSuperAdmin = userRole.some(role => role === "Super Admin");

  const fetchArtisansTags = () => {
    artisans.getTags({ page, perPage }).then(resp => {
      setArtisansTags(resp.results);
      setTotal(resp.count);
    });
  }

  // Pagination/search api call here
  useEffect(() => {
    fetchArtisansTags();
  }, [page, perPage]); // eslint-disable-line


  // Methods
  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) {
      target = selected.id;
    }

    artisans.deleteArtisanTags(target).then(() => {
      message.success("Artisan tag was deleted");
      fetchArtisansTags();
    });
  };

  return (
    <div className="artisans">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <AddButton onClick={onAddClick} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            className="artisans--table"
            columnWidths={["auto", "auto", "auto", "80px"]}
            headerCells={[
              { label: "Name" },
              { label: "Title" },
              { label: "Created" },
              { label: "Actions" },
            ]}
          >
            {
              artisansTags && artisansTags.map(artisanTag => {
                return (
                  <TableRow key={`artisan-${artisanTag.id}`}>
                    <div className="flex artisans--row-block">{artisanTag.name}</div>
                    <div className="flex artisans--row-block">{artisanTag.title}</div>
                    <div className="flex artisans--row-block artisans__created-column">
                      {moment(artisanTag.createdAt).format("DD-MMM-YYYY hh:mm")}
                    </div>
                    <div className="flex artisans--row-block artisans--row-block__actions">
                      {hasUpdatePermission && (
                        <img
                          className="groups--table-icon"
                          src="/images/edit@3x.svg"
                          onClick={() => onStartEditClick(artisanTag)}
                          alt=""
                          width="16"
                        />
                      )}
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure you want to delete this artisan tag?"
                          onConfirm={() => onRemove(artisanTag.id)}
                          placement="topRight"
                        >
                          <img
                            className="groups--table-icon"
                            src="/images/delete_3x.svg"
                            alt=""
                            width="16"
                          />
                        </Popconfirm>
                      )}
                    </div>
                  </TableRow>
                )
              })
            }

            { total > perPage && total
              ? <TableFooter
                key="tableFooter"
                page={page}
                perPage={perPage}
                total={total}
              />
              : null
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditArtisansTag
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

ArtisansTags.propTypes = {
  userRole: PropTypes.array.isRequired,
};

export default connect(state => ({
  userRole: state.auth.userProfile.roles,
}), null)(ArtisansTags);

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Switch, message, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import TableRow from "../../shared/Table/TableRow";

const DealerWebsiteRow = ({
  columnWidths,
  websiteData,
  changeStatus
}) => {
  const handleCopy = () => {
    message.success("Link was copied", 2);
  };

  return (
    <div className="w-100">
      <TableRow columnWidths={columnWidths} className="website-builder--container--content--table-row">
        <div>{websiteData.dealer.businessName}</div>
        <div>{websiteData.dealer.siteDomain}</div>
        <div>
          {websiteData.dealer.awsLink ? (
            <CopyToClipboard
              text={websiteData.dealer.awsLink}
              onCopy={handleCopy}
            >
              <Tooltip title="Copy link">
                <CopyOutlined className="website-builder--container--content--table-row--copy-icon" />
              </Tooltip>
            </CopyToClipboard>
          ) : "-"}
        </div>
        <div>{websiteData.publishDate ? moment(websiteData.publishDate).format("DD-MMM-YYYY") : "-"}</div>
        <div>{moment(websiteData.lastUpdateDate).format("DD-MMM-YYYY")}</div>
        <div>
          <Switch
            onChange={value => changeStatus(value, websiteData.id)}
            defaultChecked={websiteData.isPublished}
            size="small"
            className="publish-switch"
          />
        </div>
      </TableRow>
    </div>
  )
};

DealerWebsiteRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  websiteData: PropTypes.object.isRequired,
  changeStatus: PropTypes.func.isRequired
};

export default DealerWebsiteRow;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Button, message, Popconfirm, Upload } from "antd";
import { DeleteOutlined, FileAddOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

//components
import LabeledInput from "../../../shared/LabeledInput";

//helpers
import dealers from "../../../../api/dealers";
import contentAPI from "../../../../api/content";


const DealersNewsPopUpAddEdit = ({
  error,
  processing,
  history,
}) => {
  const [showInPopup, setShowInPopup] = useState(true);
  const [title, setTitle] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [content, setContent] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachment] = useState([]);

  const { id } = useParams();

  const validateFields = () => {
    setIsTitleValid(title !== "");
  };

  useEffect(() => {
    validateFields();
  }, [title]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing) return;
    if (error) return setShowErrorText(error);

    if (!error) setShowErrorText(false);

    return history.push("/dealers/dealer-news/");
  }, [error, processing]); // eslint-disable-line

  useEffect(() => {
    if (id) {
      dealers.getSingleDealerPopupNews(id)
        .then((resp) => {
          const dealerPopupAttachment = resp.dealerPopupAttachment
            .map(item => ({ id: item.id, uid: item.id, name: item.attachment.name, status: "done", url: item.attachment.url }));

          setTitle(resp.title);
          setContent(resp.content);
          setShowInPopup(resp.showInPopup);
          setAttachment(dealerPopupAttachment);
        })
    }
  }, [id]); // eslint-disable-line

  const onCheck = (e) => {
    setShowInPopup(e.target.checked)
  };

  const _createDealerNewsItem = () => {
    validateFields();
    if (!isTitleValid) return;

    setFormSubmitted(true);
    const dealerPopupAttachment = attachments.length
      ? attachments.map(item => (item.id ? { attachment: item, id: item.id } : { attachment: item }))
      : null;

    if (id) {
      dealers.patchSingleDealerPopupNews(id, {
        showInPopup,
        title,
        content,
        dealerPopupAttachment,
      })
        .then(() => history.push("/dealers/dealer-news/pop-up"))
        .catch(() => message.error("Something went wrong"));

      return;
    }
    dealers.postDealerPopupNews({
      showInPopup,
      title,
      content,
      dealerPopupAttachment
    })
      .then(() => history.push("/dealers/dealer-news/pop-up"))
  };

  const onDelete = () => {
    dealers.deleteSingleDealerPopupNews(id)
      .then(() => history.push("/dealers/dealer-news/pop-up"))
      .catch(() => message.error("Something went wrong"));
  }

  const renderPageTitle = () => (
    <div className="add-dealer-news-item--row">
      <div className="add-dealer-news-item--title">{!id ? "Add" : "Edit"} News Item</div>
    </div>
  );

  const handleFileUpload = (file) => {
    const isImage = file.type.split("/")[0] === "image";

    setLoading(true);

    if (isImage) {
      contentAPI.uploadNewImage(file)
        .then(({ response }) => setAttachment(state => [...state, { uid: uuidv4(), name: response.name, status: "done", url: response.url }]))
        .catch(() => message.error("Something is wrong"))
        .finally(() => setLoading(false));

      return;
    }

    contentAPI.uploadFile(file)
      .then(({ response }) => setAttachment(state => [...state, { uid: uuidv4(), name: response.name, status: "done", url: response.url }]))
      .catch(() => message.error("Something is wrong"))
      .finally(() => setLoading(false));
  }

  const handleRemoveFile = (fileData) => {
    const filteredAttachmentsList = attachments.filter(item => item.uid !== fileData.uid);

    setAttachment(filteredAttachmentsList);
  }

  const renderItemCheckbox = () => (
    <div className="add-dealer-news-item--row checkbox-holder">
      <Checkbox
        className="checkbox-small"
        checked={showInPopup}
        onChange={onCheck}
      >
        Active
      </Checkbox>
    </div>
  );

  const renderItemTitleField = () => (
    <>
      <LabeledInput
        label="Title"
        isRequired
        value={title}
        onChange={setTitle}
      />
      {
        !isTitleValid
          ? <p className="error">Can't be blank. This field is required</p>
          : showErrorText
            ? <p className="error">News item with such title is already exist</p>
            : null
      }
    </>
  );

  const renderItemPageContentField = () => (
    <LabeledInput
      label="Page Content"
      type="rich-text"
      value={content}
      onChange={setContent}
      className="add-dealer-news-item--content-input"
    />
  )

  const renderPageActionButtons = () => (
    <div className="add-dealer-news-item--button-holder">
      <Button
        className="ant-btn-primary"
        onClick={_createDealerNewsItem}
      >
        SAVE & CLOSE
      </Button>
      <div className="del-btn-holder">
        <Button
          className="btn-secondary"
          onClick={() => history.push("/dealers/dealer-news/pop-up/")}
        >
          CANCEL
        </Button>
      </div>
      {
        id
          ? (
            <Popconfirm
              placement="top"
              onConfirm={onDelete}
              title="Are you sure you want to delete this news?"
            >
              <div className="del-btn-holder">
                <Button
                  className="btn-secondary"
                >
                  <DeleteOutlined/> DELETE
                </Button>
              </div>
            </Popconfirm>
          )
          : null
      }
    </div>
  )

  return (
    <div className="add-dealer-news-item">
      {renderPageTitle()}
      {renderItemCheckbox()}
      {renderItemTitleField()}
      {renderItemPageContentField()}
      <div className="add-dealer-news__upload-section">
        <Upload
          listType="picture-card"
          accept=".doc,.docx,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.txt,.jpeg,.png"
          customRequest={({ file }) => handleFileUpload(file)}
          className="add-dealer-news__uploader-container"
          fileList={attachments}
          onRemove={handleRemoveFile}
          showUploadList={{
            showDownloadIcon: !loading,
          }}
        >
          { attachments.length < 8
            ? (
              <div className={"add-dealer-news__upload-btn"}>
                <FileAddOutlined className="add-dealer-news__upload-icon" />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )
            : null
          }
        </Upload>
      </div>
      {renderPageActionButtons()}
    </div>
  )
};

DealersNewsPopUpAddEdit.propTypes = {
  error: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => state.dealers,
  null
)(DealersNewsPopUpAddEdit);

export const SHOW_LOADING = "SHOW_GROUPS_LOADING";
export const HIDE_LOADING = "HIDE_GROUPS_LOADING";

export const SHOW_PROCESSING = "SHOW_CATALOG_ENTITY_PROCESSING";
export const HIDE_PROCESSING = "HIDE_CATALOG_ENTITY_PROCESSING";

export const CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR = "CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR";
export const RESET_ERROR = "RESET_CATALOG_ERROR";

export const GET_CATALOG_ENTITY_ELEMENTS_REQUEST = "GET_CATALOG_ENTITY_ELEMENTS_REQUEST";
export const GET_CATALOG_ENTITY_ELEMENTS_REQUEST_SUCCESS = "GET_CATALOG_ENTITY_ELEMENTS_REQUEST_SUCCESS";

export const CREATE_CATALOG_ENTITY_ELEMENT_REQUEST = "CREATE_CATALOG_ENTITY_ELEMENT_REQUEST";
export const UPDATE_CATALOG_ENTITY_ELEMENT_REQUEST = "UPDATE_CATALOG_ENTITY_ELEMENT_REQUEST";
export const DELETE_CATALOG_ENTITY_ELEMENT_REQUEST = "DELETE_CATALOG_ENTITY_ELEMENT_REQUEST";

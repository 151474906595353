import * as types from "./phoneEnquiriesActionsTypes";


const initialState = {
  items: [],
  refItems: {},
  total: 0,
  loading: false,
};


const phoneEnquiries = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.GET_PHONE_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count,
        refItems: { ...state.refItems, ...action.payload.refItems },
      };

    default:
      return state;
  }
};

export default phoneEnquiries;

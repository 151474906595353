import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";


function TableRow({
  children,
  columnWidths,
  className = "",
  isHighlighted,
  spinning = false,
  ...rest
}) {
  const childrenArray = React.Children.toArray(children);

  const classNames = ["table--row"];
  if (className) classNames.push(className);
  if (isHighlighted) classNames.push("table--row__highlighted");

  return (
    <Spin spinning={spinning}>
      <div className={classNames.join(" ")} {...rest}>
        {
          childrenArray.map((child, index) => {
            const width = columnWidths[index];
            const childProps = { style: {}, ...child.props };

            if (!("className" in childProps)) childProps.className = "";
            childProps.className += " table--column";

            if (width === "auto") childProps.style.flex = 1;
            else childProps.style.width = width;

            return React.cloneElement(child, childProps)
          })
        }
      </div>
    </Spin>
  )
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  columnWidths: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  isHighlighted: PropTypes.bool,
  spinning: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TableRow;

import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (data) => {
  return fetch({
    url: "/fairs/events/",
    method: "POST",
    data
  });
};

export const useFairEventCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["fair", resp?.fair]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

export const SHOW_PROCESSING = "SHOW_ARTISANS_PROCESSING";
export const HIDE_PROCESSING = "HIDE_ARTISANS_PROCESSING";

export const RESET_ERROR = "RESET_ARTISANS_ERROR";

export const GET_ARTISANS_REQUEST = "GET_ARTISANS_REQUEST";
export const GET_ARTISANS_REQUEST_SUCCESS = "GET_ARTISANS_REQUEST_SUCCESS";
export const GET_ARTISANS_REQUEST_ERROR = "GET_ARTISANS_REQUEST_ERROR";

export const GET_ARTISANS_TAGS_REQUEST = "GET_ARTISANS_TAGS_REQUEST";
export const GET_ARTISANS_TAGS_REQUEST_SUCCESS = "GET_ARTISANS_TAGS_REQUEST_SUCCESS";

export const CREATE_ARTISANS_REQUEST = "CREATE_ARTISANS_REQUEST";
export const UPDATE_ARTISANS_REQUEST = "UPDATE_ARTISANS_REQUEST";
export const DELETE_ARTISANS_REQUEST = "DELETE_ARTISANS_REQUEST";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Empty, Select, Spin } from "antd";
import lodash from "lodash";

import AddIcon from "../../../Icon/AddIcon";
import ArtisansApi from "../../../../api/artisans";
import LabeledInput from "../../../shared/LabeledInput";
import DeleteIcon from "../../../Icon/DeleteIcon";


function AddEditArtisansLinkedField({ linked, setLinked }) {
  const [gettingArtisans, setGettingArtisans] = useState(false);
  const [artisansOptions, setArtisansOptions] = useState([]);
  const [selectedArtisan, setSelectedArtisan] = useState("");


  // Methods
  const searchArtisan = value => {
    if (!value) {
      return;
    }

    setGettingArtisans(true);
    setArtisansOptions([]);

    ArtisansApi.search({ page: 1, perPage: 200, search: value, isApproved: true, isVisible: true }).then(result => {
      const options = result.results.filter(item => !lodash.find(linked, { id: item.id }));

      setGettingArtisans(false);
      setArtisansOptions(options);
    }).catch(console.warn);

  };
  const searchArtisanDebounced = lodash.debounce(searchArtisan, 150);

  const onAddToLinked = () => {
    if (!selectedArtisan) {
      return;
    }

    const artisan = lodash.find(artisansOptions, { id: selectedArtisan });
    if (artisan) {
      setLinked([...linked, artisan]);
    }

    setSelectedArtisan("");
    setArtisansOptions([]);
  };

  const onRemoveFromLinked = id => {
    const index = lodash.findIndex(artisansOptions, { id: id });

    const newLinked = [...linked];
    newLinked.splice(index, 1);
    setLinked(newLinked)
  };


  // Render
  return (
    <div className="labeled-input">
      <span className="labeled-input--label">Linked Artisans</span>

      { linked.map(artisan => {
        return (
          <LabeledInput
            key={`selected:${artisan.url}`}
            className="mt-0"
            value={artisan.name}
            onChange={() => {}}
            readonly
            icon={<DeleteIcon className="artisans--remove-linked-icon" onClick={() => onRemoveFromLinked(artisan.id)} />}
          />
        )
      })}

      <div className="general-form--row">
        <Select
          showSearch
          allowClear
          onSearch={searchArtisanDebounced}
          filterOption={false}
          notFoundContent={
            gettingArtisans ?
              <Spin size="small" /> :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
          }
          className="add-catalog-entity--select labeled-input--input"
          value={selectedArtisan}
          onChange={el => setSelectedArtisan(el)}
        >
          {
            artisansOptions.map(el => {
              return <Select.Option key={el.url} value={el.id}>{el.name}</Select.Option>
            })
          }
        </Select>

        <div className="general-form--plus-button" onClick={onAddToLinked}>
          <AddIcon />
        </div>
      </div>
    </div>
  )
}

AddEditArtisansLinkedField.propTypes = {
  linked: PropTypes.array.isRequired,
  setLinked: PropTypes.func.isRequired,
};

export default AddEditArtisansLinkedField;

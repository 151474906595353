import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";
import { ALL_DIRECTORY_CATEGORIES_PARAMS } from "../../constants";

const fetcher = (data) => {
  return fetch({
    url: "/directory/categories/",
    method: "POST",
    data
  });
};

export const useDirectoryCategoryCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["directory-category-list", JSON.stringify(ALL_DIRECTORY_CATEGORIES_PARAMS)]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

import React from "react";
import PropTypes from "prop-types";
import { Col, Input, Form, Row, Select, Switch } from "antd";
import uniqBy from "lodash/uniqBy";

// components
import {
  GoogleAutocomplete,
  LantForm,
  LantFormItem,
  LantFormSection
} from "../../../../components";
import { DirectoryEntryEmailSection } from "./DirectoryEntryEmailSection";

// react-queries
import { useDirectoryCategoryList, useDealerCategories } from "../../../../react-query";
import { useSpecialities } from "../../pages/Specialities/hooks";

// helpers
import { formErrorsHandler } from "../../../../helpers";

// constants
import { ALL_DIRECTORY_CATEGORIES_PARAMS, GB_COUNTRY_LIST } from "../../../../constants";

export const DirectoryEntryForm = ({ form, title, isEdit, isFair = false, onSubmit, onCancel, initData }) => {
  const showAddressDetails = !Form.useWatch("hideAddressDetails", form);

  const country = Form.useWatch("country", form);
  const categories = Form.useWatch("categories", form);

  const handleSubmit = () => {
    if (typeof onSubmit !== "function") {
      return;
    }

    form.validateFields()
      .then(({ hideAddressDetails, mapMetadata, website, city, ...values }) => {
        const directoryWebsite = website?.includes("https://")
          ? website
          : `https://${website}`;


        const data = {
          ...values,
          city: form.getFieldValue("city"),
          isVisibleMap: !hideAddressDetails,
          mapMetadata: JSON.stringify({
            google_map_link: mapMetadata?.google_map_link,
            latitude: values.latitude || mapMetadata?.latitude,
            longitude: values.longitude || mapMetadata?.longitude,
            place_id: mapMetadata?.place_id,
          }),
          categories: values.categories?.map((el) => el.value),
          dealerCategories: values.dealerCategories?.map((el) => el.value),
          specialities: values.specialities?.map((el) => el.value),
          website: website ? directoryWebsite : null,
        };
        
        onSubmit(data);
      })
      .catch(formErrorsHandler);
  };

  const { data: categoriesOptions = [] } = useDirectoryCategoryList(ALL_DIRECTORY_CATEGORIES_PARAMS, {
    select: (resp) => resp.results
      .map((el) => ({ value: el.id, label: el.title })),
    staleTime: Infinity,
    enabled: !isFair
  });

  const { data: dealerCategories = [], isLoading } = useDealerCategories({
    select: (resp) => resp.map((el) => ({ value: el.id, label: el.title })),
  });

  const { data } = useSpecialities({ type: isFair ? "fairs" : "directory", params: { page: 1, page_size: 1000 } });

  const specialitiesOptionsDraft = data?.results.filter((el) => el.isVisible) || [];
  const specialitiesOptions = uniqBy([...specialitiesOptionsDraft, ...(initData?.specialities || [])], "id").map((el) => ({ value: el.id, label: el.name || el.title }));

  const handleCategoriesChange = (event, infoEvent) => {
    const isDealerDirectorySelected = infoEvent.some((el) => el.label === "Directory Dealer");

    if (!isDealerDirectorySelected) {
      form.setFieldValue("dealerCategories", []);
    }

    return event;
  }

  const selectedCategories = categoriesOptions.filter((el) => (categories || [])
    .map(item => item.value).includes(el.value));
  const isDealerDirectory = selectedCategories.some(item => item.label === "Directory Dealer");

  // renders
  return (
    <LantForm
      form={form}
      initialValues={{ country: "GB", status: "ACTIVE" }}
      title={title}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <LantFormSection title="General information">
        <LantFormItem
          name="name"
          label="Name"
          lg={12}
          xs={24}
          rules={[{ required: true }]}
        >
          <Input />
        </LantFormItem>
        <DirectoryEntryEmailSection isEdit={isEdit} isFair={isFair} />
        <Col lg={8} xs={24}>
          <Row gutter={16}>
            <LantFormItem name="phone" label="Phone" lg={24} xs={12}>
              <Input />
            </LantFormItem>
            <LantFormItem name="website" label="Website" lg={24} xs={12}>
              <Input addonBefore="https://" />
            </LantFormItem>
          </Row>
        </Col>
        <LantFormItem name="description" label="Description" lg={16}>
          <Input.TextArea style={{ height: "110px" }} />
        </LantFormItem>
        {!isFair && (
          <>
            <LantFormItem
              name="categories"
              label="Categories"
              rules={[{ required: true }]}
              getValueFromEvent={handleCategoriesChange}
            >
              <Select options={categoriesOptions} mode="multiple" labelInValue />
            </LantFormItem>
            {
              isDealerDirectory && (
                <LantFormItem
                  name="dealerCategories"
                  label="Type"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={dealerCategories}
                    mode="multiple"
                    labelInValue
                    isLoading={isLoading}
                  />
                </LantFormItem>
              )
            }
          </>
        )}
        <LantFormItem name="specialities" label="Specialities" rules={[{ required: true }]} >
          <Select
            options={specialitiesOptions}
            mode="multiple"
            labelInValue
          />
        </LantFormItem>
      </LantFormSection>
      <LantFormSection title="Additional information">
        <LantFormItem name="directions" label="Directions">
          <Input.TextArea style={{ minHeight: "76px" }} />
        </LantFormItem>
      </LantFormSection>
      <LantFormSection title="Address information">
        <LantFormItem
          name="country"
          label="Country"
          lg={8}
          md={12}
          xs={24}
          rules={[{ required: true }]}
        >
          <Select
            options={[{ value: "GB", label: "United Kingdom" }]}
            placeholder="Select Country"
          />
        </LantFormItem>
        <LantFormItem
          name="ukCounty"
          label="UK County"
          lg={8}
          md={12}
          xs={24}
          {...(country === "GB" && { rules: [{ required: true }] })}
        >
          <Select
            options={GB_COUNTRY_LIST}
            placeholder="Select UK County"
            showSearch
          />
        </LantFormItem>
        <LantFormItem
          name="city"
          label="Town / City"
          lg={8}
          md={12}
          xs={24}
          rules={[{ required: true }]}
          getValueFromEvent={(e) => e.name}
        >
          <GoogleAutocomplete
            options={{
              componentRestrictions: { country: "gb" },
              types: ["locality", "administrative_area_level_2"],
            }}
            placeholder="Enter city name"
            onChange={(el) => {
              form.setFieldsValue({
                mapMetadata: el,
                latitude: el.latitude,
                longitude: el.longitude,
              });
              
              return el.name;
            }}
          />
        </LantFormItem>
        <LantFormItem name="mapMetadata" hidden noStyle>
          <Input />
        </LantFormItem>
        <LantFormItem
          name="addressLineOne"
          label="Address line 1"
          md={16}
          xs={24}
          {...(showAddressDetails ? { rules: [{ required: true }] } : {})}
        >
          <Input />
        </LantFormItem>
        <LantFormItem
          name="addressLineTwo"
          label="Address line 2"
          md={8}
          xs={24}
        >
          <Input />
        </LantFormItem>
        <LantFormItem
          name="longitude"
          label="Longitude"
          md={8}
          xs={12}
          {...(showAddressDetails ? { rules: [{ required: true }] } : {})}
        >
          <Input type="number" placeholder="Enter Longitude" />
        </LantFormItem>
        <LantFormItem
          name="latitude"
          label="Latitude"
          md={8}
          xs={12}
          {...(showAddressDetails ? { rules: [{ required: true }] } : {})}
        >
          <Input type="number" placeholder="Enter Latitude" />
        </LantFormItem>
        <LantFormItem
          name="hideAddressDetails"
          label="Hide address (Directory entry wouldn't be visible on the map)"
          valuePropName="checked"
        >
          <Switch />
        </LantFormItem>
      </LantFormSection>
    </LantForm>
  );
};

DirectoryEntryForm.propTypes = {
  form: PropTypes.object.isRequired,
  title: PropTypes.string,
  isEdit: PropTypes.bool,
  isFair: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initData: PropTypes.object,
};

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({ url: `/settings/admin-logs/?${buildQueryString(params)}`, method: "GET" });
};

export const useAdminLogsList = (params, options) => {
  return useQuery(["admin-logs-list", JSON.stringify(params)], () => fetcher(params), options);
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";

import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditPeriods from "./AddEditPeriods";
import PeriodsRow from "./PeriodsRow";

import {
  getPeriodsRequest,
  updatePeriodsRequest,
  createPeriodsRequest,
  deletePeriodsRequest,
} from "../../../redux/periods/periodsActions";


function Periods(props) {
  const { history, location, items, total, userRole } = props;

  const [selected, setSelected] = useState(null);
  const { search, page, perPage, order } = navigation.getTableQueryParams(location);

  const creationPermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1");

  // Pagination/search api call here
  useEffect(() => {
    props.getPeriods({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line


  // Methods
  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onAddSubClick = itemUrl => {
    history.push({
      pathname: `${location.pathname}/add`,
      search: `?parent=${itemUrl}`
    });
  };

  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onChangeActive = (itemUrl, event) => {
    props.updatePeriods(itemUrl, { isVisible: event.target.checked });
  };

  const onRemove = target => {
    if (!target) {
      target = selected.url;
    }

    props.deletePeriods(target, () => {
      message.success("Period was successfully deleted", 2);
      setSelected(null);
    });
  };

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };


  // Render
  const periodsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="artisans">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            {creationPermission && <AddButton onClick={onAddClick} />}
            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            className="artisans--table"
            columnWidths={["40px", "auto", "auto", "80px", "80px", "142px"]}
            headerCells={[
              {},
              { label: "Name" },
              { label: "Url" },
              { label: "AVAIL." },
              { label: "RESER." },
              { label: "Actions" },
            ]}
          >
            {({ columnWidths }) => {
              return periodsToRender.map(period => {
                return (
                  <PeriodsRow
                    key={`period:${period.id}`}
                    columnWidths={columnWidths}
                    item={period}
                    onChangeActive={onChangeActive}
                    onAddSub={onAddSubClick}
                    onEdit={onStartEditClick}
                    onRemove={onRemove}
                    isSearch={!!search}
                  />
                )
              })
            }}

            {
              total && (
                <TableFooter key="tableFooter" page={page} perPage={perPage} total={total} />
              )
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditPeriods
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

Periods.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  getPeriods: PropTypes.func.isRequired,
  createPeriods: PropTypes.func.isRequired,
  updatePeriods: PropTypes.func.isRequired,
  deletePeriods: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    items: state.periods.items,
    total: state.periods.total,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getPeriods: params => dispatch(getPeriodsRequest(params)),
    createPeriods: data => dispatch(createPeriodsRequest(data)),
    updatePeriods: (url, data) => dispatch(updatePeriodsRequest(url, data, true)),
    deletePeriods: (url, callback) => dispatch(deletePeriodsRequest(url, callback)),
  })
)(Periods);

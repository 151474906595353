import * as types from "./paymentsActionsTypes";

const initialState = {
  data: {},
  processing: false,
  loading: false,
  updated: false,
  errors: {}
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYPAL_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload
      };

    case types.GET_PAYPAL_REQUEST_ERROR:
    case types.PATCH_PAYPAL_REQUEST_ERROR:
      return {
        ...state,
        errors: action.payload,
        updated: false
      };

    case types.PATCH_PAYPAL_REQUEST:
      return {
        ...state,
        updated: false
      };

    case types.PATCH_PAYPAL_REQUEST_SUCCESS:
      return {
        ...state,
        updated: true,
        errors: {}
      };

    case types.RESET_ERRORS:
      return {
        ...state,
        errors: {},
        updated: false
      };

    case types.SHOW_PAYMENT_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_PAYMENT_PROCESSING:
      return {
        ...state,
        processing: false
      };

    case types.SHOW_PAYMENT_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_PAYMENT_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default paymentReducer;

import { useMutation } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (data) => {
  return fetch({
    url: "/directory/",
    method: "POST",
    data
  });
};

export const useDirectoryEntryCreate = (options) => {
  return useMutation(fetcher, options);
};

const navigation = {
  getTableQueryParams: location => {
    const queryParams = {};
    for (const [key, value] of new URLSearchParams(location.search)) {
      queryParams[key] = value;
    }

    const {
      orderBy,
      orderDirection,
      search,
      page,
      perPage,
      filter,
      dateFilter,
      typeFilter,
      statusOption,
      dateOption,
      dealer,
      period,
      material,
      origin,
      title,
      toDate,
      fromDate
    } = queryParams;

    return {
      order: { key: orderBy, direction: orderDirection },
      search: search || "",
      page: +page || 1,
      perPage: +perPage || 10,
      filter: filter || "",
      dateFilter: dateFilter || "",
      typeFilter: typeFilter || "",
      statusOption: statusOption || "",
      dateOption: dateOption || "-date",
      dealer: dealer || "",
      period: period || "",
      material: material || "",
      origin: origin || "",
      title: title || "",
      toDate: toDate || "",
      fromDate: fromDate || ""
    };
  },

  setOrder: (orderKey, location, history) => {
    const queryParams = new URLSearchParams(location.search);
    const currentOrderBy = queryParams.get("orderBy");
    const currentOrderDirection = queryParams.get("orderDirection");

    if (!currentOrderBy || currentOrderBy !== orderKey) {
      queryParams.set("orderBy", orderKey);
      queryParams.set("orderDirection", "asc");
    }
    else if (currentOrderDirection === "asc") {
      queryParams.set("orderDirection", "desc");
    }
    else {
      queryParams.set("orderDirection", "asc");
    }

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  },

  setSearch: (value, location, history) => {
    const queryParams = new URLSearchParams(location.search);
    const currentPage = Number.parseInt(queryParams.get("page"), 10) || 1;

    if (value) {
      currentPage !== 1 && queryParams.set("page", "1");
      queryParams.set("search", value);
    } else {
      queryParams.delete("search");
    }

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  },

  setPage: (value, location, history) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", value);

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  },

  setPerPage: (value, location, history) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("perPage", value);

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  },

  setPageAndPerPage: (pageValue, perPageValue, location, history) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", pageValue);
    queryParams.set("perPage", perPageValue);

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  },

  setDynamicKeys: (obj, location, history) => {
    const queryParams = new URLSearchParams(location.search);

    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        queryParams.set(key, obj[key]);
      }
      else {
        queryParams.delete(key);
      }
    });

    const newLocation = { ...location, search: `?${queryParams.toString()}` };
    history.replace(newLocation)
  }
};

export default navigation

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Checkbox, Button, Select, Spin, Empty, Popconfirm, message } from "antd";
import _ from "lodash";

//components
import ArticlePreview from "./ArticlePreview";
import TabNavigation from "../../../shared/TabNavigation";
import navigation from "../../../../helpers/navigation";
import LabeledInput from "../../../shared/LabeledInput";
import ImageUpload from "../../../shared/ImageUpload";

//redux
import {
  deleteBlogArticleRequest,
  updateBlogArticleRequest,
  uploadImageToServer
} from "../../../../redux/content/blog/blogActions";

//helpers
import contentApi from "../../../../api/content";


const EditArticle = ({
  location,
  history,
  match,
  error,
  lastUploadedImage,
  deleteBlogArticle,
  uploadImage,
  updateBlogArticle,
  userRole,
}) => {
  const [currentArticleCategoriesIds, setCurrentArticleCategoriesIds] = useState([]);
  const [currentArticleAuthorsIds, setCurrentArticleAuthorsIds] = useState([]);

  const [articleTitle, setArticleTitle] = useState("");
  const [articleURL, setArticleURL] = useState("");
  const [articlePageContent, setArticlePageContent] = useState("");
  const [articleIsActive, setArticleIsActive] = useState(false);
  const [articleImage, setArticleImage] = useState({});
  const [prefix, setPrefix] = useState("");
  const [notIndexArticle, setNotIndexArticle] = useState(false);

  const [searchingCategories, setSearchingCategories] = useState(false);
  const [existingCategories, setExistingCategories] = useState([]);
  const [searchingAuthors, setSearchingAuthors] = useState(false);
  const [existingAuthors, setExistingAuthors] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");

  const [loading, setLoading] = useState(false);

  const { page, perPage } = navigation.getTableQueryParams(location);

  const hasUpdatePermission = !userRole.some(role => role === "Super Admin" || role === "Admin Seo");
  const hasDeletePermission = userRole.some(role => role === "Super Admin");


  // Get selected article
  useEffect(() => {
    setLoading(true);
    contentApi.getBlogArticleData(match.params.url).then(response => {
      const title = _.get(response, "title", "");
      const url = _.get(response, "url", "");
      const text = _.get(response, "text", "");
      const isActive = _.get(response, "isActive", false);
      const image = _.get(response, "image", "");
      const categories = _.get(response, "categories", []);
      const authors = _.get(response, "authors", []);
      const categoriesIds = categories.map(({ id }) => id);
      const authorsIds = authors.map(({ id }) => id);
      const fullURL = _.get(response, "fullUrl", "");
      const urlPrefix = fullURL.replace(`/${url}`, "/");
      const metaDescrValue = _.get(response, "meta", "");

      setArticleTitle(title);
      setArticleURL(url);
      setArticlePageContent(text);
      setArticleIsActive(isActive);
      setArticleImage(image);
      setExistingCategories(categories);
      setCurrentArticleCategoriesIds(categoriesIds);
      setExistingAuthors(authors);
      setCurrentArticleAuthorsIds(authorsIds);
      setPrefix(urlPrefix);
      setMetaDescription(metaDescrValue);
      setNotIndexArticle(!!response.noIndex);

      setLoading(false);
    });
  }, []); // eslint-disable-line

  // Upload image
  const handleImageChange = (image) => {
    if (_.isEmpty(image)) {
      return;
    }
    uploadImage(image);
    setArticleImage(image);
  }

  const onEditBlogSuccess = async () => {
    await message.success("Changes were saved", 2);
    history.push({ pathname: "/content/blog/articles" });
  }

  const onCheck = e => {
    setArticleIsActive(e.target.checked);
  };

  const searchCategories = value => {
    if (!value) {
      return;
    }

    setSearchingCategories(true);
    setExistingCategories([]);
    contentApi.getCategories({ page: 1, perPage: 200, search: value }).then(result => {
      setSearchingCategories(false);
      setExistingCategories(result.results);
    }).catch(console.warn)
  };
  const searchCategoriesDebounced = useCallback(_.debounce(searchCategories, 150), []);

  const searchAuthors = value => {
    if (!value) {
      return;
    }

    setSearchingAuthors(true);
    setExistingAuthors([]);
    contentApi.getAuthors({ page: 1, perPage: 200, search: value }).then(result => {
      setSearchingAuthors(false);
      setExistingAuthors(result.results);
    }).catch(console.warn)
  };
  const searchAuthorsDebounced = useCallback(_.debounce(searchAuthors, 150), []);

  const _updateBlogArticle = () => {
    const article = {
      title: articleTitle,
      url: articleURL,
      isActive: articleIsActive,
      authors: currentArticleAuthorsIds,
      categories: currentArticleCategoriesIds,
      text: articlePageContent,
      meta: metaDescription,
      noIndex: notIndexArticle,
    };
    if (lastUploadedImage) {
      article.image = lastUploadedImage;
    }

    updateBlogArticle({ url: match.params.url, article, page, perPage, callback: onEditBlogSuccess });
  };

  const _deleteBlogArticle = () => {
    deleteBlogArticle({ url: articleURL, history });
  };


  // Render
  const imageToShow = lastUploadedImage || articleImage;
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;
  const serverError = error || {};

  return loading ? <Spin /> : (
    <>
      <TabNavigation
        items={[
          {
            label: "ARTICLE",
            link: `/content/blog/articles/edit-article/${match.params.url}`
          },
          {
            label: "PREVIEW",
            link: `/content/blog/articles/edit-article/${match.params.url}/preview`
          }
        ]}
      />
      <Switch>
        <Route
          exact
          path="/content/blog/articles/edit-article/:url/preview"
          render={props => (
            <ArticlePreview
              {...props}
              currentArticle={{
                title: articleTitle,
                pageContent: articlePageContent,
              }}
            />
          )}
        />
        <Route
          exact
          path="/content/blog/articles/edit-article/:url"
          render={() => (
            <div className="edit-article">
              <div className="edit-article--row">
                <span className="edit-article--title">Edit Article</span>
              </div>

              <div className="edit-article--row-checkboxes checkbox-holder">
                <Checkbox
                  className="checkbox-small"
                  checked={articleIsActive}
                  onChange={onCheck}
                >
                  Active
                </Checkbox>
                <Checkbox
                  className="checkbox-small"
                  checked={notIndexArticle}
                  onChange={(e) => setNotIndexArticle(e.target.checked)}
                >
                  Not index this article
                </Checkbox>
              </div>

              <LabeledInput
                label="Title"
                isRequired
                value={articleTitle}
                onChange={setArticleTitle}
                errorText={serverError.title}
              />

              <div className="edit-article--url-input-holder">
                <LabeledInput
                  label="URL"
                  isRequired
                  value={articleURL}
                  onChange={setArticleURL}
                  inputClassName="input-with-prefix"
                  addonBefore={prefix}
                  suffix={urlFieldImage}
                  errorText={serverError.url}
                />
              </div>

              <div className="edit-article--categories-select">
                <p className="edit-article--categories-select-label">
                  Categories
                </p>

                <Select
                  showSearch
                  allowClear
                  onSearch={searchCategoriesDebounced}
                  notFoundContent={
                    searchingCategories ?
                      <Spin size="small" /> :
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
                  }
                  className="labeled-input--input"
                  mode="multiple"
                  value={currentArticleCategoriesIds}
                  onChange={setCurrentArticleCategoriesIds}
                  placeholder="Please select categories"
                  filterOption={false}
                >
                  {
                    existingCategories.map(el => {
                      return <Select.Option key={el.url} value={el.id}>{el.name}</Select.Option>
                    })
                  }
                </Select>
              </div>

              <div className="edit-article--authors-select">
                <p className="edit-article--authors-select-label">Authors</p>

                <Select
                  showSearch
                  allowClear
                  onSearch={searchAuthorsDebounced}
                  notFoundContent={
                    searchingAuthors ?
                      <Spin size="small" /> :
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
                  }
                  className="labeled-input--input"
                  mode="multiple"
                  value={currentArticleAuthorsIds}
                  onChange={setCurrentArticleAuthorsIds}
                  placeholder="Please select categories"
                  filterOption={false}
                >
                  {
                    existingAuthors.map(el => {
                      return <Select.Option key={el.url} value={el.id}>{el.name}</Select.Option>
                    })
                  }
                </Select>
              </div>

              <LabeledInput
                label="Page Content"
                type="rich-text"
                value={articlePageContent}
                onChange={setArticlePageContent}
                className="edit-article--page-content-input"
              />

              <LabeledInput
                label="Meta Description"
                type="textarea"
                value={metaDescription}
                onChange={setMetaDescription}
                className="edit-article--page-content-input"
              />

              <ImageUpload
                topText="Cover Image"
                setImage={handleImageChange}
                lastUploadedImage={imageToShow}
                bottomText="Image must be at least 300 x 300 pixels"
                width={256}
                height={256}
              />

              <div className="edit-article--button-holder">
                <Button
                  className="ant-btn-primary"
                  onClick={_updateBlogArticle}
                  disabled={hasUpdatePermission}
                >
                  SAVE & CLOSE
                </Button>
                <div className="cancel-btn-holder">
                  <Button
                    className="cancel-btn"
                    onClick={() => history.push("/content/blog/articles")}
                  >
                    CANCEL
                  </Button>
                </div>
                <div className="del-btn-holder">
                  <Popconfirm
                    title="Are you sure you want to delete this article?"
                    okText="Yes"
                    cancelText="No"
                    placement="top"
                    onConfirm={_deleteBlogArticle}
                  >
                    <Button
                      className="btn-secondary"
                      disabled={!hasDeletePermission}
                    >
                      <img src="/images/delete_3x.svg" alt="" />
                      DELETE ITEM
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          )}
        />
      </Switch>
    </>
  );
};

EditArticle.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  articles: PropTypes.array,
  error: PropTypes.string,
  lastUploadedImage: PropTypes.object,
  deleteBlogArticle: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  updateBlogArticle: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    blogs: state.blogs,
    lastUploadedImage: state.blogs.lastUploadedImage,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    uploadImage: payload => dispatch(uploadImageToServer(payload)),
    updateBlogArticle: payload => dispatch(updateBlogArticleRequest(payload)),
    deleteBlogArticle: payload => dispatch(deleteBlogArticleRequest(payload))
  })
)(EditArticle);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox, message, Popover, Tooltip } from "antd";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import moment from "moment";
import merge from "lodash/merge";

// components
import SearchInput from "../../shared/SearchInput";
import LabeledInput from "../../shared/LabeledInput";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import EditUser from "./EditUser";
import ResentButton from "./ResentButton";

// api
import userManagementApi from "../../../api/userManagement";

// redux
import {
  getUsersRequest,
  patchUserRequest,
  deleteUserRequest,
  convertUserRoleRequest
} from "../../../redux/userManagement/users/usersActions";

// helpers
import navigation from "../../../helpers/navigation";
import { Utils } from "../../../helpers";

// assets
const arrowUpIcon = "/images/arrowUp.svg";
const arrowDownIcon = "/images/arrowDown.svg";
const upgradeUserRoleIcon = "/images/upgradeUserRole.svg";
const downgradeUserRoleIcon = "/images/downgradeUserRole.svg";
const csvIcon = "/images/csv@3x.svg";
const editIcon = "/images/edit@3x.svg";
const deleteIcon = "/images/delete_3x.svg";


const Users = ({
  location,
  history,
  getUsers,
  items,
  totalNumberOfUsers,
  error,
  patchUser,
  deleteUser,
  convertUserRole,
  userProfile,
  userRole,
}) => {
  const [query, setQuery] = useState({});
  const [selected, setSelected] = useState(null);
  const [showFormFullscreen, setShowFormFullscreen] = useState(false);

  const { order, search, page, perPage } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");

  const handleSortItems = (value, option, key) => {
    const newQuery = merge(query, {
      [key]: {
        label: option.props.children,
        value
      }
    });

    setQuery(newQuery);

    // validation for filter change on first page
    if (page !== 1) {
      navigation.setPage(1, location, history);
    } else {
      getUsers({
        page: 1,
        perPage,
        search,
        query: Utils.formatFilters(newQuery)
      });
    }
  };

  useEffect(() => {
    getUsers({ page, perPage, search, query: Utils.formatFilters(query) });
  }, [page, perPage, search]); // eslint-disable-line

  useEffect(() => {
    if (error?.nonFieldErrors?.length > 0) {
      const errorMessage = error.nonFieldErrors.join(". ");

      message.error(errorMessage);
    }
  }, [error]);

  // Methods
  const onStartEditClick = item => {
    if (selected) return;
    setSelected(item);
  };

  const _downloadCsv = () => {
    userManagementApi.csvExport().then(data => {
      saveAs(data, "users.csv");
    });
  };

  const _downloadDealersCsv = () => {
    userManagementApi.dealersCsvExport().then(data => {
      saveAs(data, "active-dealers.csv");
    });
  };

  const onChangeActive = (id, event) => {
    if (userProfile.id === id) {
      return message.warning("You can't deactivate yourself");
    }

    patchUser({
      params: {
        page,
        perPage,
        search,
        query: Utils.formatFilters(query),
      },
      id,
      data: {
        isActive: event.target.checked,
      },
      showProcessing: false,
    });
  };

  const updateRole = (id, role) => convertUserRole({
    id,
    data: { role },
    page,
    perPage,
    search,
    query: Utils.formatFilters(query),
  })

  const renderTypeColumn = (id, innerUserRole, dealer) => {
    const renderUserRoleRow = () => {
      switch (innerUserRole) {
        case "WEBSITE_VISITOR":
          return (
            <Popover
              placement="leftBottom"
              title="Change To:"
              content={(
                <div className="updgrade-user-role">
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowUpIcon}
                      alt="Upgrade user to Dealer"
                      onClick={() => updateRole(id, "DEALER")}
                    />
                    <div>Dealer</div>
                  </div>
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowUpIcon}
                      alt="Upgrade user to Trade user"
                      onClick={() => updateRole(id, "TRADE")}
                    />
                    <div>Trade user</div>
                  </div>
                </div>
              )}
              trigger="click"
            >
              <div className="users--type-column--user-role-row flex">
                <div>User</div>
                <div>
                  <img
                    className="groups--table-icon"
                    src={upgradeUserRoleIcon}
                    alt="Upgrade role"
                  />
                </div>
              </div>
            </Popover>
          );

        case "TRADE":
          return (
            <Popover
              placement="leftBottom"
              title="Change To:"
              content={(
                <div className="updgrade-user-role">
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowUpIcon}
                      alt="Upgrade Trade user to Dealer"
                      onClick={() => updateRole(id, "DEALER")}
                    />
                    <div>Dealer</div>
                  </div>
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowDownIcon}
                      alt="Downgrade Trade user to User"
                      onClick={() => updateRole(id, "WEBSITE_VISITOR")}
                    />
                    <div>User</div>
                  </div>
                </div>
              )}
              trigger="click"
            >
              <div className="users--type-column--user-role-row flex">
                <div>Trade user</div>
                <div>
                  <img
                    className="groups--table-icon"
                    src={downgradeUserRoleIcon}
                    alt="Downgrade role"
                  />
                </div>
              </div>
            </Popover>
          );

        case "DEALER":
          return (
            <Popover
              placement="leftBottom"
              title="Change To:"
              content={(
                <div className="updgrade-user-role">
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowDownIcon}
                      alt="Downgrade Dealer to Trade user"
                      onClick={() => updateRole(id, "TRADE")}
                    />
                    <div>Trade user</div>
                  </div>
                  <div className="flex">
                    <img
                      className="groups--table-icon"
                      src={arrowDownIcon}
                      alt="Downgrade Dealer to User"
                      onClick={() => updateRole(id, "WEBSITE_VISITOR")}
                    />
                    <div>User</div>
                  </div>
                </div>
              )}
              trigger="click"
            >
              <div className="users--type-column--user-role-row flex">
                <div>Dealer</div>
                <div>
                  <img
                    className="groups--table-icon"
                    src={downgradeUserRoleIcon}
                    alt="Downgrade role"
                  />
                </div>
              </div>
            </Popover>
          );
        default:
          return null;
      }
    };

    const renderUserStatusRow = () => {
      if (innerUserRole !== "DEALER" || !dealer) return null;

      if (dealer.status === "ACTIVE") {
        return (
          <div className="users--type-column--user-status-row--active">Live</div>
        );
      }

      if (dealer.status === "PENDING") {
        return (
          <div className="users--type-column--user-status-row--pending">Pending</div>
        );
      }

      if (dealer.status === "INACTIVE") {
        return (
          <div className="users--type-column--user-status-row--inactive">Inactive</div>
        );
      }
    };

    return (
      <div className="users--type-column flex">
        {renderUserRoleRow()}
        {renderUserStatusRow()}
      </div>
    );
  }

  const renderContentOfEmailColumn = (innerUserRole, email, dealer = {}) => {
    let emailValue = email;
    let name = "u";

    if (innerUserRole === "TRADE") {
      name = "t";
    }
    if (innerUserRole === "DEALER") {
      emailValue = dealer ? dealer.email : "";
      name = "d";
    }

    return (
      <div className="flex">
        <div className="status-panel disabled">
          <div className={`status-panel--item text-capitalize shorter ${name}`}>
            {name}
          </div>
        </div>
        {emailValue}
      </div>
    );
  };

  // Render
  const usersToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="component users">
      <TableAndFormLayout showForm={!!selected} isFormFullscreen={showFormFullscreen}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="component--header-section left">
              <LabeledInput
                className="type mr-16p"
                label="Type"
                type="select"
                options={[
                  {
                    label: "All",
                    value: ""
                  },
                  {
                    label: "Live Users",
                    value: "users"
                  },
                  {
                    label: "Active Dealers",
                    value: "dealers"
                  }
                ]}
                value={query.type && query.type.label}
                placeholder="By Type"
                onChange={(value, option) => handleSortItems(value, option, "type")}
                horizontal
              />
              <LabeledInput
                className="status mr-16p"
                label="Status"
                type="select"
                options={[
                  {
                    label: "All",
                    value: ""
                  },
                  {
                    label: "Confirmed",
                    value: "confirmed"
                  },
                  {
                    label: "Unconfirmed",
                    value: "unconfirmed"
                  }
                ]}
                value={query.status && query.status.label}
                placeholder="By Status"
                onChange={(value, option) => handleSortItems(value, option, "status")}
                horizontal
              />
              <LabeledInput
                className="status mr-16p"
                label=""
                type="select"
                options={[
                  {
                    label: "Date",
                    value: "date"
                  },
                  {
                    label: "Last Login",
                    value: "last_login"
                  },
                  {
                    label: "Email",
                    value: "email"
                  },
                  {
                    label: "Name",
                    value: "name"
                  }
                ]}
                value={query.ordering && query.ordering.label}
                placeholder="Sort by"
                onChange={(value, option) => handleSortItems(value, option, "ordering")}
                horizontal
              />
            </div>
            <div className="component--header-section">
              <div className="button" onClick={_downloadCsv}>
                <img className="button--icon" src={csvIcon} alt="csv" />
                <span className="button--text">CSV</span>
              </div>
              <div className="button" onClick={_downloadDealersCsv}>
                <img className="button--icon" src={csvIcon} alt="csv" />
                <span className="button--text">ACTIVE DEALERS CSV</span>
              </div>
              <SearchInput
                value={search}
                onChange={value => navigation.setDynamicKeys({ page: 1, search: value }, location, history)}
              />
            </div>
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <div className="flex users--content">
            <Table
              columnWidths={["56px", "25%", "10%", "15%", "12%", "25%", "auto"]}
              headerCells={[
                { label: "active" },
                { label: "email address" },
                { label: "details" },
                { label: "saved search" },
                { label: "type" },
                { label: "history" },
                { label: "actions" }
              ]}
            >
              {
                usersToRender.map(item => {
                  const {
                    isActive,
                    id,
                    email,
                    isDealer,
                    dealer,
                    dateJoined,
                    lastLoginDate,
                    passwordChangeDate,
                    firstName,
                    lastName,
                    favoriteItems,
                    favoriteDealers,
                    userRole: itemUserRole,
                    confirmation
                  } = item;
                  const currentUser = userProfile.id === id;

                  return (
                    <TableRow key={`user__${id}`}>
                      <div className="flex justify-center">
                        <Checkbox
                          disabled={(selected || {}).id === id}
                          onChange={event => onChangeActive(id, event)}
                          defaultChecked={isActive}
                        />
                      </div>
                      <div className="flex email-address column">
                        {renderContentOfEmailColumn(itemUserRole, email, dealer)}
                      </div>
                      <div className="flex column details">
                        <div className={ !isDealer ? "bold" : "" }>{ `${firstName} ${lastName}` }</div>
                        {isDealer && (
                          <>
                            <div>
                              <span className="bold dealer-name">{`${dealer.firstName} ${dealer.lastName}`}</span>
                              <span className="red">{ dealer.likedUsers }</span>
                            </div>
                            {dealer.phone && (
                              <div>{dealer.phone}</div>
                            )}
                          </>
                        )}
                        <div className="favorites">
                          <div>
                            <span className="same-width">Favorites Items</span>
                            <span className="red">{ favoriteItems }</span>
                          </div>
                          <div>
                            <span className="same-width">Favorites Dealers</span>
                            <span className="red">{ favoriteDealers }</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <span className="same-width">Dealers</span>
                          <span className="red ml-1">
                            {item.searchCount.dealer}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Artisans</span>
                          <span className="red ml-1">
                            {item.searchCount.artisan}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Periods</span>
                          <span className="red ml-1">
                            {item.searchCount.period}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Keyword searches</span>
                          <span className="red ml-1">
                            {item.searchCount.keywordSearch}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Categories</span>
                          <span className="red ml-1">
                            {item.searchCount.category}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Origins</span>
                          <span className="red ml-1">
                            {item.searchCount.origin}
                          </span>
                        </div>

                        <div>
                          <span className="same-width">Materials</span>
                          <span className="red ml-1">
                            {item.searchCount.material}
                          </span>
                        </div>
                      </div>
                      <div className="flex type">
                        {renderTypeColumn(id, itemUserRole, dealer)}
                      </div>
                      <div className="history column flex">
                        <div className="flex">
                          <span className="left">Created</span>
                          <span className="bold">
                            {Utils.emptyFieldFormat(dateJoined && moment(dateJoined).format("DD/MM/YYYY HH:mm:ss"), "-")}
                          </span>
                        </div>
                        <div className="flex">
                          <span className="left">Last Login</span>
                          <span className="bold">
                            {Utils.emptyFieldFormat(lastLoginDate && moment(lastLoginDate).format("DD/MM/YYYY HH:mm:ss"), "-")}
                          </span>
                        </div>
                        <div className="flex">
                          <span className="left">Password Changed</span>
                          <span className="bold">
                            {Utils.emptyFieldFormat(passwordChangeDate && moment(passwordChangeDate).format("DD/MM/YYYY HH:mm:ss"), "-")}
                          </span>
                        </div>
                      </div>
                      <div className="flex actions-column">
                        {(isDealer && dealer.status !== "INACTIVE" && !isDisabled) && (
                          <img
                            className="groups--table-icon"
                            src={editIcon}
                            onClick={() => onStartEditClick(item)}
                            alt=""
                            width="16"
                          />
                        )}
                        {confirmation === "UNCONFIRMED" && (
                          <Tooltip title="Resend confirmation email">
                            <span>
                              <ResentButton usedId={id} />
                            </span>
                          </Tooltip>
                        )}
                        {(!isDealer && isActive && !currentUser) && (
                          <img
                            className="groups--table-icon"
                            src={deleteIcon}
                            alt=""
                            width="16"
                            onClick={() => deleteUser({
                              id,
                              page, perPage, search, query: Utils.formatFilters(query)
                            })}
                          />
                        )}
                      </div>
                    </TableRow>
                  )
                })
              }

              {totalNumberOfUsers && (
                <TableFooter
                  key="users-table-footer"
                  page={page}
                  perPage={perPage}
                  total={totalNumberOfUsers}
                />
              )}
            </Table>
          </div>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <EditUser
            item={selected}
            filtersData={{ page, perPage, search, query: Utils.formatFilters(query) }}
            isFullScreen={showFormFullscreen}
            toggleFullscreen={() => setShowFormFullscreen(!showFormFullscreen)}
            close={() => {
              setSelected(null);
              setShowFormFullscreen(false);
            }}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
};

Users.propTypes = {
  error: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  patchUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  convertUserRole: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  totalNumberOfUsers: PropTypes.number.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    ...state.users,
    userProfile: state.auth.userProfile,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getUsers: payload => dispatch(getUsersRequest(payload)),
    patchUser: payload => dispatch(patchUserRequest(payload)),
    deleteUser: payload => dispatch(deleteUserRequest(payload)),
    convertUserRole: payload => dispatch(convertUserRoleRequest(payload))
  })
)(Users);

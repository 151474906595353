import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal } from "antd";

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";

import { Utils } from "../../../../helpers";
import navigation from "../../../../helpers/navigation";
import {
  getDealerSpecialitiesRequest,
  deleteDealerSpecialityRequest,
} from "../../../../redux/dealers/dealersActions";
import DeleteIcon from "../../../Icon/DeleteIcon";
import EditIcon from "../../../Icon/EditIcon";

const DealerSpecialities = ({
  location,
  history,
  getDealerSpecialities,
  dealerSpecialities,
  dealerSpecialitiesTotalCount,
  userRole,
  deleteDealerSpeciality,
}) => {
  const { page, perPage, order } = navigation.getTableQueryParams(location);
  const [delSpecIntentId, setDelSpecIntentId] = useState(null);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");

  useEffect(() => {
    getDealerSpecialities({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const dealerSpecialitiesToRender = Utils.filterAndOrder(dealerSpecialities, { order });

  const renderActions = (id) => (
    <>
      <div className="dealer-specialities--action">
        <EditIcon onClick={() => history.push(`/dealers/dealer-specialities/edit/${id}`)} />
      </div>
      <div
        className="dealer-specialities--action"
        onClick={() => setDelSpecIntentId(id)}
      >
        <DeleteIcon />
      </div>
    </>
  )

  const renderDealerSpecialitiesTable = () => (
    <>
      <Table
        className="dealer-specialities--table"
        columnWidths={["17%", "15%", "10%", "calc(58% - 64px)", "64px"]}
        headerCells={[
          { label: "TITLE" },
          { label: "NAME" },
          { label: "DEALERS" },
          { label: "DESCRIPTION" }
        ]}
      >
        {dealerSpecialitiesToRender && dealerSpecialitiesToRender.map(({
          id,
          name,
          title,
          dealers,
          description
        }) => (
          <TableRow key={`dealer-specialities--row-${id}`}>
            <div className="dealer-specialities--title-column flex">{title}</div>
            <div className="dealer-specialities--name-column flex">{name}</div>
            <div className="dealer-specialities--dealers-column flex">{dealers}</div>
            <div className="dealer-specialities--description-column flex">{description}</div>
            <div className="dealer-specialities--actions flex">{renderActions(id)}</div>
          </TableRow>
        ))}

        {dealerSpecialitiesTotalCount > 0 && (
          <TableFooter
            page={page}
            perPage={perPage}
            total={dealerSpecialitiesTotalCount}
          />
        )}
      </Table>
    </>
  )

  const renderDeleteSpecModal = () => {
    const specToDelete = dealerSpecialitiesToRender?.find(item => item.id === delSpecIntentId);

    const onCancelClick = () => setDelSpecIntentId(null);
    const onDeleteClick = () => {
      deleteDealerSpeciality({ page, perPage, url: specToDelete?.url ?? -1 });
      setDelSpecIntentId(null);
    };

    return (
      <Modal
        title="Delete"
        open={!!delSpecIntentId}
        onCancel={() => setDelSpecIntentId(null)}
        centered
        footer={[
          <Button key="cancel" onClick={onCancelClick}>Cancel</Button>,
          <Button key="delete" onClick={onDeleteClick} type="primary" danger>Yes, delete it</Button>
        ]}
      >
        <p>Are you sure you want to delete {specToDelete?.title ?? ""} speciality?
        </p>
      </Modal>
    );
  };


  return (
    <div className="dealer-specialities">
      <div className="dealer-specialities--add-button">
        {!isDisabled && (
          <AddButton onClick={() => history.push("/dealers/dealer-specialities/add-new")} />
        )}
      </div>

      {renderDealerSpecialitiesTable()}

      {renderDeleteSpecModal()}
    </div>
  )
};

DealerSpecialities.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDealerSpecialities: PropTypes.func.isRequired,
  dealerSpecialities: PropTypes.array.isRequired,
  dealerSpecialitiesTotalCount: PropTypes.number.isRequired,
  userRole: PropTypes.array.isRequired,
  deleteDealerSpeciality: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    dealerSpecialities: state.dealers.dealerSpecialities,
    dealerSpecialitiesTotalCount: state.dealers.dealerSpecialitiesTotalCount,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getDealerSpecialities: payload => dispatch(getDealerSpecialitiesRequest(payload)),
    deleteDealerSpeciality: payload => dispatch(deleteDealerSpecialityRequest(payload)),
  })
)(DealerSpecialities);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import HeaderSection from "./HeaderSection";
import BillingAndShippingSection from "./BillingAndShippingSection";
import OrderHistorySection from "./OrderHistorySection";
import PaymentSection from "./PaymentSection";
import TotalsSection from "./TotalsSection";
import CustomerNotesSection from "./CustomerNotesSection";
import OrderNotesSection from "./OrderNotesSection";

import navigation from "../../../../helpers/navigation";

import { getOrdersRequest } from "../../../../redux/orders/ordersActions";


const HeaderTab = ({ location, match, orders, getOrders }) => {
  const { page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getOrders({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const order =
    orders && orders.find(({ id }) => id.toString(10) === match.params.id);

  const headerData = _.get(order, "header", []);
  const statusData = _.get(order, "status", {});
  const billingData = _.get(order, "billTo", []);
  const historyData = _.get(order, "history", []);
  const paymentData = _.get(order, "payment", []);
  const totalData = _.get(order, "total", []);
  const customerNotes = _.get(order, "customerNotes", "None");

  return (
    <div className="order-header">
      <div className="order-header--left-column">
        <HeaderSection headerData={headerData} status={statusData?.status} />
        <BillingAndShippingSection billingData={billingData} />
        {<OrderHistorySection historyData={historyData} />}
      </div>
      <div className="order-header--right-column">
        <PaymentSection paymentData={paymentData} />
        <TotalsSection totalData={totalData} />
        <CustomerNotesSection customerNotes={customerNotes} />
        <OrderNotesSection order={order} />
      </div>
    </div>
  );
};

HeaderTab.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
  getOrders: PropTypes.func.isRequired,
};

export default connect(
  ({ orders }) => orders,
  (dispatch) => ({
    getOrders: (payload) => dispatch(getOrdersRequest(payload)),
  })
)(withRouter(HeaderTab));

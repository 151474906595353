import * as types from "./adminAccountsActionsTypes";

export const getAdminAccountsRequest = payload => {
  return { type: types.GET_ADMIN_ACCOUNTS_REQUEST, payload }
};

export const deleteAdminAccountRequest = payload => {
  return { type: types.DELETE_ADMIN_ACCOUNT_REQUEST, payload }
};

export const updateAdminAccountRequest = payload => {
  return { type: types.UPDATE_ADMIN_ACCOUNT_REQUEST, payload }
};

export const getAdminRolesRequest = payload => {
  return { type: types.GET_ADMIN_ROLES_REQUEST, payload }
};

export const addAdminAccountRequest = payload => {
  return { type: types.ADD_ADMIN_ACCOUNT_REQUEST, payload }
};

export const getAdminAccountsHistoryRequest = payload => {
  return { type: types.GET_ADMIN_ACCOUNTS_HISTORY_REQUEST, payload }
};

export const searchAdminsRequest = payload => {
  return { type: types.SEARCH_ADMINS_REQUEST, payload }
};

export const resetPasswordRequest = payload => {
  return { type: types.RESET_PASSWORD_REQUEST, payload }
};

import fetch from "./customFetch";
import { UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR } from "../constants";


const dashboard = {
  async getUsers() {
    try {
      const result = await fetch({
        url: "/users/?admin=true",
        method: "GET"
      });

      return result.results;
    } catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR
    }
  },

  async handleElasticSearch(query) {
    try {
      return await fetch({
        url: `/search/?query=${query}`,
        method: "GET"
      });
    } catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR
    }
  },

  async getWebsiteLiveData() {
    try {
      return await fetch({
        url: "/analytics/counters/",
        method: "GET"
      })
    } catch (err) {
      throw UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR
    }
  }
};

export default dashboard;

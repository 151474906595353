import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Radio } from "antd";
import { Bar } from "react-chartjs-2";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import {
  getDealerAnalyticsRequest
} from "../../../../../redux/analytics/analyticsActions";


const StatsSection = ({
  dealerURL,
  getDealerAnalytics,
  dealerAnalytics,
}) => {
  const [dealerAnalyticsPeriod, setDealerAnalyticsPeriod] = useState("week");

  const [itemViewsData, setItemViewsData] = useState([]);
  const [dealerViewsData, setDealerViewsData] = useState([]);
  const [impressionsData, setImpressionsData] = useState([]);
  const [phoneAndEmailEnquiries, setPhoneAndEmailEnquiries] = useState([]);
  const [numberOfEnquiries, setNumberOfEnquiries] = useState([]);

  const getWeekLabels = () => {
    const today = moment().format("ddd");
    const weekLabels = [];

    for (let i = 1; i < 7; i++) {
      const nextDay = moment().add(i, "days").format("ddd");

      weekLabels.push(nextDay);
    }

    weekLabels.push(today);

    return weekLabels;
  };

  const getLastMonths = (amountOfMonths) => {
    const lastThreeMonth = [];

    for (let i = 0; i < amountOfMonths; i++) {
      lastThreeMonth.push(moment().subtract(i, "months").format("MMM"))
    }

    return lastThreeMonth.reverse();
  };

  const formChartLabels = chartOption => {
    if (chartOption === "all_time") return ["All Time"];
    if (chartOption === "week") return getWeekLabels();
    if (chartOption === "three_month") return getLastMonths(3);
    if (chartOption === "six_month") return getLastMonths(6);

    return getLastMonths(12);
  };

  const statsLabels = formChartLabels(dealerAnalyticsPeriod);

  const data = {
    labels: statsLabels,
    datasets: [
      {
        label: "Item Views",
        backgroundColor: "#FBACBE",
        borderColor: "#FD87A1",
        borderWidth: 1,
        hoverBackgroundColor: "#FBACBE",
        hoverBorderColor: "#FD87A1",
        data: itemViewsData,
      }, {
        label: "Dealer Views",
        backgroundColor: "#97CCF1",
        borderColor: "#68B7EE",
        borderWidth: 1,
        hoverBackgroundColor: "#97CCF1",
        hoverBorderColor: "#68B7EE",
        data: dealerViewsData
      }, {
        label: "Impressions",
        backgroundColor: "#FBE1A6",
        borderColor: "#FDD77F",
        borderWidth: 1,
        hoverBackgroundColor: "#FBE1A6",
        hoverBorderColor: "#FDD77F",
        data: impressionsData
      }, {
        label: "Phone and Email enquiries",
        backgroundColor: "#A0DBDC",
        borderColor: "#75CDCE",
        borderWidth: 1,
        hoverBackgroundColor: "#A0DBDC",
        hoverBorderColor: "#75CDCE",
        data: phoneAndEmailEnquiries
      }, {
        label: "Number of enquiries",
        backgroundColor: "#C8AEFC",
        borderColor: "#B18AFE",
        borderWidth: 1,
        hoverBackgroundColor: "#C8AEFC",
        hoverBorderColor: "#B18AFE",
        data: numberOfEnquiries,
      }
    ]
  };

  // Effects

  useEffect(() => {
    getDealerAnalytics({ url: dealerURL, period: dealerAnalyticsPeriod });
  }, [dealerAnalyticsPeriod]); // eslint-disable-line

  useEffect(() => {
    const innerItemViewsData = [];
    const innerDealerViewsData = [];
    const innerImpressionsData = [];
    const innerPhoneAndEmailEnquiries = [];
    const innerNumberOfEnquiries = [];

    if (!isEmpty(dealerAnalytics)) {
      for (const element of dealerAnalytics) {
        innerItemViewsData.push(element.value.itemViews);
        innerDealerViewsData.push(element.value.dealerViews);
        innerImpressionsData.push(element.value.impressions);
        innerPhoneAndEmailEnquiries.push(element.value.phoneAndEmailEnquiries);
        innerNumberOfEnquiries.push(element.value.numberOfEnquiries);
      }
    }

    setItemViewsData(innerItemViewsData);
    setDealerViewsData(innerDealerViewsData);
    setImpressionsData(innerImpressionsData);
    setPhoneAndEmailEnquiries(innerPhoneAndEmailEnquiries);
    setNumberOfEnquiries(innerNumberOfEnquiries);
  }, [dealerAnalytics]); // eslint-disable-line


  // Render

  return (
    <div className="stats-section">
      <div className="stats-section--header">
        <div className="stats-section--title">Stats</div>
        <Radio.Group
          name="radiogroup"
          value={dealerAnalyticsPeriod}
          onChange={(e) => setDealerAnalyticsPeriod(e.target.value)}>
          <Radio value="week">Week</Radio>
          <Radio value="three_month">3 Month</Radio>
          <Radio value="six_month">6 month</Radio>
          <Radio value="year">Year</Radio>
          <Radio value="all_time">All time</Radio>
        </Radio.Group>
      </div>


      <div className="stats-section--content">
        <Bar
          data={data}
          height={550}
          options={{ maintainAspectRatio: false }}
          legend={{ position: "bottom" }}
        />
      </div>
    </div>
  )
};

StatsSection.propTypes = {
  dealerURL: PropTypes.string.isRequired,
  dealerAnalytics: PropTypes.array.isRequired,
  getDealerAnalytics: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  dealerAnalytics: state.analytics.dealerAnalytics,
});

const mapDispatchToProps = {
  getDealerAnalytics: getDealerAnalyticsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsSection);

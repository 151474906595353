import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import _ from "lodash";
import { Select, Switch, Tooltip } from "antd";
import { ShopOutlined, ShopFilled } from "@ant-design/icons";

import { ConfirmDeleteModal, Impersonate } from "../../../components";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import EditIcon from "../../Icon/EditIcon";
import EarthIcon from "../../Icon/EarthIcon";
import DeleteIcon from "../../Icon/DeleteIcon";
import SearchInput from "../../shared/SearchInput";

import navigation from "../../../helpers/navigation";
import dealerAPI from "../../../api/dealers";

import {
  getDealersRequest,
  getDealersStatusChoicesRequest,
  deleteDealerRequest,
  patchDealerRequest,
  searchDealersRequest,
} from "../../../redux/dealers/dealersActions";

const WEBSITE_BUILDER_TOOLTIP = "Allow website builder functionality for this dealer";

const DealersList = ({
  location,
  history,
  getDealers,
  dealers,
  totalNumberOfDealers,
  getDealersStatusChoices,
  dealersStatusChoices,
  deleteDealer,
  patchDealer,
  userRole,
  searchDealers,
}) => {
  const { page, perPage } = navigation.getTableQueryParams(location);

  const [dealersToRender, setDealersToRender] = useState([]);
  const [sortByStatus, setSortByStatus] = useState();
  const [newestOrOldestFilter, setNewestOrOldestFilter] = useState();
  const [marketplace, setMarketplace] = useState(null);
  const [sortByDate, setSortByDate] = useState();
  const [dateRange, setDateRange] = useState(null);
  const [dateTitle, setDateTitle] = useState("Custom");
  const [focusedInput, setFocusedInput] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [openedDeleteModal, setOpenedDeleteModal] = useState(false);
  const [dealerIdToBeDeleted, setDealerIdToBeDeleted] = useState(false);
  const [showInFeaturedField, setShowInFeaturedField] = useState(null);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");
  const isWebsiteBuilderSwitchShown = userRole.includes("Super Admin") || userRole.includes("Admin Financial");

  // Helpers

  const calculateLast14Days = () => {
    const now = moment().format("YYYY-MM-DD");
    const last14Days = moment(now).subtract(14, "d").format("YYYY-MM-DD");
    setDateFilter([now, last14Days]);
  }

  const calculateLast30Days = () => {
    const now = moment().format("YYYY-MM-DD");
    const last30Days = moment(now).subtract(30, "d").format("YYYY-MM-DD");
    setDateFilter([now, last30Days]);
  }

  const calculateLastMonth = () => {
    const now = moment().format("YYYY-MM-DD");
    const startOfMonth = moment(now).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(now).endOf("month").format("YYYY-MM-DD");
    setDateFilter([startOfMonth, endOfMonth]);
  }

  // Effects

  useEffect(() => {
    getDealersStatusChoices({});
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!showInFeaturedField) return;
    const dealerURL = dealers.find(({ id }) => id === showInFeaturedField.id).url;
    const data = { ...showInFeaturedField, url: dealerURL };
    const getDealersParams = {
      page,
      perPage,
    };
    if (sortByStatus) getDealersParams.status = sortByStatus;
    if (newestOrOldestFilter) getDealersParams.ordering = newestOrOldestFilter;
    if (dateFilter) {
      getDealersParams.fromDate = dateFilter[0];
      getDealersParams.toDate = dateFilter[1];
    }

    const params = {
      page,
      perPage,
    };

    if (sortByStatus) params.status = sortByStatus;
    if (newestOrOldestFilter) params.ordering = newestOrOldestFilter;
    if (dateFilter) {
      params.fromDate = dateFilter[0];
      params.toDate = dateFilter[1];
    }

    data && patchDealer({
      data,
      dealerURL,
      getDealersParams,
      page,
      perPage
    }, () => getDealers(params));
  }, [showInFeaturedField]); // eslint-disable-line

  useEffect(() => {
    const dealersArray = dealers && _.sortBy(dealers, [(dealer) => !dealer.showInFeatured]);
    setDealersToRender(dealersArray);
  }, [dealers]); // eslint-disable-line

  useEffect(() => {
    const params = {
      page,
      perPage,
    };
    if (sortByStatus) params.status = sortByStatus;
    if (marketplace) params.marketplace = marketplace;
    if (newestOrOldestFilter) params.ordering = newestOrOldestFilter;
    if (dateFilter) {
      params.fromDate = dateFilter[0];
      params.toDate = dateFilter[1];
    }

    getDealers(params);
  }, [page, perPage, sortByStatus, newestOrOldestFilter, dateFilter, marketplace]); // eslint-disable-line

  useEffect(() => {
    if (sortByDate === "All Time") setDateFilter(["", ""]);
    if (sortByDate === "Last 14 days") calculateLast14Days();
    if (sortByDate === "Last 30 days") calculateLast30Days();
    if (sortByDate === "Last month") calculateLastMonth();
  }, [sortByDate]); // eslint-disable-line

  useEffect(() => {
    if (dateRange) {
      const startD = moment(dateRange[0]).format("L");
      const endD = moment(dateRange[1]).format("L");
      const title = `${startD} - ${endD}`
      setDateTitle(title);
      setDateFilter([startD, endD]);

      if (startD === "Invalid date" || endD === "Invalid date") setDateFilter([]);
    }
    if (dateRange && dateRange.includes(null)) setDateTitle("Custom")
  }, [dateRange]); // eslint-disable-line

  // Constants

  const dealerToBeDeleted = dealers.find(({ id }) => id === dealerIdToBeDeleted);
  const dealerToBeDeletedTitle = _.get(dealerToBeDeleted, "businessName", "")
  const dealerToBeDeletedURL = _.get(dealerToBeDeleted, "url", "")

  // Methods

  const onSearch = value => {
    const params = {
      page,
      pageSize: perPage,
    };

    searchDealers(params, value);
  };

  const debouncedSearch = useCallback(_.debounce(onSearch, 200), []);

  const handleDeleteIconClick = (id) => {
    setOpenedDeleteModal(true);
    setDealerIdToBeDeleted(id);
  }

  const _deleteDealer = () => {
    deleteDealer({ url: dealerToBeDeletedURL, page, perPage });
    setOpenedDeleteModal(false);
  }

  const toggleWebsiteBuilderSwitch = (value, dealerUrl, e) => {
    e.preventDefault();

    dealerAPI.patchDealer({ isBuilderFunctionality: value }, dealerUrl)
      .catch(console.warn);
  }

  const renderStatusColumn = (status) => {
    const {
      active,
      confirmed,
      index,
      API,
      merchantFeed,
      PayPal,
      invoicing,
    } = status;
    const DLZAccess = _.get(status, "DLZ Access");

    /* eslint-disable */
    let activeValue,
      confirmedValue,
      indexValue,
      APIValue,
      merchantFeedValue,
      PayPalValue,
      invoicingValue,
      DLZAccessValue,
      activeClass,
      confirmedClass,
      indexClass,
      APIClass,
      merchantFeedClass,
      PayPalClass,
      invoicingClass,
      DLZAccessClass;
    /* eslint-enable */

    if (active === "ACTIVE") {
      activeValue = "Active";
      activeClass = "dealers-list--status-column--active-value-is-active";
    } else if (active === "PENDING") {
      activeValue = "Pending";
      activeClass = "dealers-list--status-column--active-value-is-pending";
    } else if (active === "INACTIVE") {
      activeValue = "Inactive";
      activeClass = "dealers-list--status-column--active-value-is-inactive";
    }

    // eslint-disable-next-line prefer-const
    confirmedValue = confirmed ? "Confirmed" : "Unconfirmed";
    // eslint-disable-next-line prefer-const
    confirmedClass = confirmed
      ? "dealers-list--status-column--confirmed"
      : "dealers-list--status-column--unconfirmed";

    if (API === "ACTIVE") {
      APIValue = "API";
      APIClass = "dealers-list--status-column--api-active";
    } else if (API === "INACTIVE") {
      APIValue = "API";
      APIClass = "dealers-list--status-column--api-inactive"
    }

    if (index === "INDEX") {
      indexValue = "Index";
      indexClass = "dealers-list--status-column--index-active";
    } else if (index === "DO_NOT_INDEX") {
      indexValue = "Index";
      indexClass = "dealers-list--status-column--index-inactive";
    }

    // eslint-disable-next-line prefer-const
    merchantFeedValue = "Merchant Feed";
    // eslint-disable-next-line prefer-const
    merchantFeedClass = merchantFeed
      ? "dealers-list--status-column--merchant-feed-active"
      : "dealers-list--status-column--merchant-feed-inactive";

    // eslint-disable-next-line prefer-const
    PayPalValue = "PayPal";
    // eslint-disable-next-line prefer-const
    PayPalClass = PayPal
      ? "dealers-list--status-column--paypal-active"
      : "dealers-list--status-column--paypal-inactive";

    // eslint-disable-next-line prefer-const
    invoicingValue = "Invoicing";
    // eslint-disable-next-line prefer-const
    invoicingClass = invoicing
      ? "dealers-list--status-column--invoicing-active"
      : "dealers-list--status-column--invoicing-inactive";

    // eslint-disable-next-line prefer-const
    DLZAccessValue = "DLZ Access";
    // eslint-disable-next-line prefer-const
    DLZAccessClass = DLZAccess
      ? "dealers-list--status-column--DLZ-access-active"
      : "dealers-list--status-column--DLZ-access-inactive";

    return (
      <>
        <div className={activeClass}>{activeValue}</div>
        <div className={confirmedClass}>{confirmedValue}</div>
        <div className={indexClass}>{indexValue}</div>
        <div className={APIClass}>{APIValue}</div>
        <div className={merchantFeedClass}>{merchantFeedValue}</div>
        <div className={PayPalClass}>{PayPalValue}</div>
        <div className={invoicingClass}>{invoicingValue}</div>
        <div className={DLZAccessClass}>{DLZAccessValue}</div>
      </>
    )
  }

  const renderDetailsColumn = (params) => {
    const {
      logo,
      images,
      businessName,
      email,
      website,
      phone,
      mobile,
      createdAt
    } = params;

    const imgPath = images && images.shouldUseTextLogo && images.textLogoUrl ? images.textLogoUrl : logo && logo.url;
    const date = moment(createdAt).format("LL");
    const dealerBusinessName = businessName ? businessName : <div className="dealer-detail-column-info--no-business-name">No Dealer Name</div>;

    return (
      <>
        <div className="dealer-detail-column-image">
          <img src={imgPath} alt="Dealer avatar" />
        </div>
        <div className="dealer-detail-column-info">
          <div className="dealer-detail-column-info--business-name">{dealerBusinessName}</div>
          <div className="dealer-detail-column-info--email">{email}</div>
          <div className="dealer-detail-column-info--website">{website}</div>
          <div className="dealer-detail-column-info--phone">{phone}</div>
          <div className="dealer-detail-column-info--mobile">{mobile}</div>
          <div className="dealer-detail-column-info--created-at">{`Since ${date}`}</div>
        </div>
      </>
    );
  }

  const renderAreasColumn = (specialities = [], categories = []) => {
    const mapAreas = (areas) => areas.map((area, index) => area.title && (
      <p
        key={`dealer-area-${area.title}-${index}`}
        className="lant-table--tag thin category"
      >
        {area.title}
      </p>
    )
    ).filter(Boolean);


    return (
      <div className="dealers-list--specialities-column--items-wrapper">
        {!!categories.length && (
          <div className="dealers-list--enquiries-column--areas-row">
            <p className="dealers-list--enquiries-column--areas-label">
              Type:
            </p>
            {mapAreas(categories)}
          </div>
        )}
        {!!specialities.length && (
          <div className="dealers-list--enquiries-column--areas-row">
            <p className="dealers-list--enquiries-column--areas-label">
              Specialities:
            </p>
            {mapAreas(specialities)}
          </div>
        )}
      </div>
    );
  }

  const renderAddressColumn = (address) => {
    const {
      address: dealerAddress,
      city,
      region,
      country,
      postCode
    } = address;

    return (
      <>
        <div className="dealers-list--address-column--address">{dealerAddress}</div>
        <div className="dealers-list--address-column--city">{city}</div>
        <div className="dealers-list--address-column--region">{region}</div>
        <div className="dealers-list--address-column--country">{country}</div>
        <div className="dealers-list--address-column--postCode">{postCode}</div>
      </>
    );
  }

  const renderOptionsColumn = (options, favoriteDealers, favoriteItems) => {
    const {
      itemsAvailable = 0,
      soldItemsAmount = 0,
      reservedItems = 0,
      invoices = 0,
    } = options;

    return (
      <>
        <div className="dealers-list--options-column--items-available-and-items-sold">
          <div className="dealers-list--options-column--items-available-row flex">
            <div className="dealers-list--options-column--items-available-label">Items Available</div>
            <div className="dealers-list--options-column--items-available-value">{itemsAvailable}</div>
          </div>
          <div className="dealers-list--options-column--items-sold-row flex">
            <div className="dealers-list--options-column--items-sold-label">Items Sold</div>
            <div className="dealers-list--options-column--items-sold-value">{soldItemsAmount}</div>
          </div>
        </div>
        <div className="dealers-list--options-column--billing-items-and-invoices">
          <div className="dealers-list--options-column--billing-items-row flex">
            <div className="dealers-list--options-column--billing-items-label">Reserved Items</div>
            <div className="dealers-list--options-column--billing-items-value">{reservedItems}</div>
          </div>
          <div className="dealers-list--options-column--invoices-row flex">
            <div className="dealers-list--options-column--invoices-label">Invoices</div>
            <div className="dealers-list--options-column--invoices-value">{invoices}</div>
          </div>
        </div>
        <div className="dealers-list--options-column--favorite-dealers-and-favorite-items">
          <div className="dealers-list--options-column--favorite-dealers-row flex">
            <div className="dealers-list--options-column--favorite-dealers-label">Favorite Dealers</div>
            <div className="dealers-list--options-column--favorite-dealers-value">{favoriteDealers}</div>
          </div>
          <div className="dealers-list--options-column--favorite-items-row flex">
            <div className="dealers-list--options-column--favorite-items-label">Favorite Items</div>
            <div className="dealers-list--options-column--favorite-items-value">{favoriteItems}</div>
          </div>
        </div>
      </>
    );
  }

  const renderEnquiriesColumn = (enquiries) => {
    const { email, phone, callDealer, requestedCallbacks } = enquiries;

    return (
      <>
        <div className="dealers-list--enquiries-column--email-row flex">
          <div className="dealers-list--enquiries-column--email-label">Email</div>
          <div className="dealers-list--enquiries-column--email-value">{email}</div>
        </div>
        <div className="dealers-list--enquiries-column--call-dealer-row flex">
          <div className="dealers-list--enquiries-column--call-dealer-label">Call Dealer</div>
          <div className="dealers-list--enquiries-column--call-dealer-value">{callDealer}</div>
        </div>
        <div className="dealers-list--enquiries-column--phone-row flex">
          <div className="dealers-list--enquiries-column--phone-label">Phone</div>
          <div className="dealers-list--enquiries-column--phone-value">{phone}</div>
        </div>
        <div className="dealers-list--enquiries-column--requested-callbacks-row flex">
          <div className="dealers-list--enquiries-column--requested-callbacks-label">Requested Callbacks</div>
          <div className="dealers-list--enquiries-column--requested-callbacks-value">{requestedCallbacks}</div>
        </div>
      </>
    );
  }

  const { itemStatus } = dealersStatusChoices;

  // Render

  return (
    <div className="dealers-list">
      <div className="component--header">
        <div className="dealers-list--filters-and-calendar-row">
          <div className="dealers-list--sort-by-status-select">
            <Select
              placeholder="Active"
              className="select"
              onChange={setSortByStatus}
            >
              {
                itemStatus && Object.keys(itemStatus).map(
                  (key) => <Select.Option key={key} value={key}>{itemStatus[key]}</Select.Option>
                )
              }
            </Select>
          </div>
          <div className="dealers-list--newest-or-oldest-filter-select">
            <Select
              placeholder="Newest First"
              className="select"
              onChange={setNewestOrOldestFilter}
            >
              <Select.Option value="date">Newest First</Select.Option>
              <Select.Option value="-date">Oldest First</Select.Option>
            </Select>
          </div>
          <div className="dealers-list--calendar-label">
            Marketplaces
          </div>
          <div className="dealers-list--newest-or-oldest-filter-select">
            <Select
              placeholder="Default"
              className="select"
              onChange={(e) => setMarketplace(e === "default" ? null : e)}
            >
              <Select.Option value="default">Default</Select.Option>
              <Select.Option value="ebay">Ebay</Select.Option>
              <Select.Option value="instagram">Instagram</Select.Option>
              <Select.Option value="facebook">Facebook</Select.Option>
            </Select>
          </div>
          <div className="dealers-list--calendar-label">
            Enquiries
          </div>
          <div className="dealers-list--calendar">
            <Select
              placeholder="All Time"
              className="select"
              onChange={setSortByDate}
            >
              <Select.Option value={"All Time"}>All Time</Select.Option>
              <Select.Option value={"Last 14 days"}>Last 14 days</Select.Option>
              <Select.Option value={"Last 30 days"}>Last 30 days</Select.Option>
              <Select.Option value={"Last month"}>Last month</Select.Option>
              <Select.Option
                value={"Custom"}
                onClick={() => setFocusedInput("startDate")}
              >
                {dateTitle}
              </Select.Option>
            </Select>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={dateRange && dateRange[0]}
              endDate={dateRange && dateRange[1]}
              onDatesChange={
                ({ startDate, endDate }) => setDateRange([startDate, endDate])
              }
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              withPortal={true}
            />
          </div>
        </div>
      </div>

      <div className="dealers-search">
        <SearchInput onChange={(value) => debouncedSearch(value)} />
      </div>

      <div className="dealers-list--content flex">
        <Table
          columnWidths={["85px", "12%", "9%", "12%", "15%", "auto", "115px", "110px"]}
          headerCells={[
            { label: "status" },
            { label: "details" },
            { label: "address" },
            { label: "options" },
            { label: "enquiries" },
            { label: "areas" },
            { label: "website builder" },
            { label: "actions", className: "dealers-table-action-column" }
          ]}
        >
          {
            ({ columnWidths }) => {
              return (
                dealersToRender.length > 0 && dealersToRender.map(({
                  status,
                  businessName,
                  createdAt,
                  soldItemsAmount,
                  email,
                  id,
                  images,
                  mobile,
                  phone,
                  specialities,
                  categories,
                  website,
                  options,
                  enquiries,
                  url,
                  address,
                  showInFeatured,
                  favoriteItems,
                  favoriteDealers,
                  isBuilderFunctionality,
                  hasPhysicalStore,
                  user,
                }) => {
                  const detailsColumnParams = {
                    logo: _.get(images, "logo", {}),
                    images,
                    businessName,
                    email,
                    website,
                    phone,
                    mobile,
                    createdAt
                  };

                  return (
                    <div
                      key={`dealers-list-${id}`}
                      className="dealers-list--content__row"
                    >
                      <div
                        className="line-height-1 cursor-pointer"
                        onClick={() => history.push(`/dealers/dealers-list/${url}/status`)
                        }
                      >
                        <TableRow columnWidths={columnWidths}>
                          <div className="dealers-list--status-column flex">
                            {renderStatusColumn(status)}
                          </div>
                          <div className="dealers-list--details-column flex">
                            {renderDetailsColumn(detailsColumnParams)}
                          </div>
                          <div className="dealers-list--address-column flex">
                            {address
                              ? renderAddressColumn(address)
                              : "No address provided"}
                          </div>
                          <div className="dealers-list--options-column flex">
                            {renderOptionsColumn(
                              { ...options, soldItemsAmount },
                              favoriteDealers,
                              favoriteItems
                            )}
                          </div>
                          <div className="dealers-list--enquiries-column flex">
                            {renderEnquiriesColumn(enquiries)}
                          </div>
                          <div className="dealers-list--specialities-column">
                            {renderAreasColumn(specialities, categories)}
                          </div>
                          <div
                            className="dealers-list--website-column cursor-default"
                            onClick={(evt) => evt.stopPropagation()}
                          >
                            {isWebsiteBuilderSwitchShown && (
                              <Tooltip
                                placement="bottom"
                                title={WEBSITE_BUILDER_TOOLTIP}
                              >
                                <Switch
                                  size="small"
                                  onChange={(value, e) => toggleWebsiteBuilderSwitch(value, url, e)
                                  }
                                  defaultChecked={isBuilderFunctionality}
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div
                            className="dealers-list--actions-column cursor-default"
                            onClick={(evt) => evt.stopPropagation()}
                          >
                            <div className="dealers-list--actions-column--icons-wrapper">
                              <div
                                className="cursor-pointer"
                                onClick={() => setShowInFeaturedField({
                                  id,
                                  showInFeatured: !showInFeatured,
                                })
                                }
                              >
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  fill={showInFeatured ? "#F89407" : "none"}
                                  stroke={showInFeatured ? "#F89407" : "black"}
                                  width="20px"
                                  viewBox="0 0 510 510"
                                >
                                  <g strokeWidth="10">
                                    <polygon points="255,402.212 412.59,497.25 370.897,318.011 510,197.472 326.63,181.738 255,12.75 183.371,181.738 0,197.472 139.103,318.011 97.41,497.25" />
                                  </g>
                                </svg>
                              </div>
                              <div
                                className="cursor-pointer"
                                onClick={() => setShowInFeaturedField({
                                  id,
                                  hasPhysicalStore: !hasPhysicalStore,
                                })
                                }
                              >
                                {hasPhysicalStore ? (
                                  <ShopFilled
                                    style={{
                                      color: "#4F9A00",
                                      fontSize: "18px",
                                    }}
                                  />
                                ) : (
                                  <ShopOutlined
                                    style={{
                                      color: "#717171",
                                      fontSize: "18px",
                                    }}
                                  />
                                )}
                              </div>
                              {!isDisabled && (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => history.push(
                                      `/dealers/dealers-list/${url}/details-tab`
                                  )
                                  }
                                >
                                  <EditIcon />
                                </div>
                              )}
                              {!isDisabled && (
                                <Impersonate id={user} type="dealer" />
                              )}
                              {website ? (
                                <a
                                  href={website}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <EarthIcon />
                                </a>
                              ) : (
                                <div>
                                  <EarthIcon />
                                </div>
                              )}
                              <div>
                                {!isDisabled && (
                                  <DeleteIcon
                                    className="cursor-pointer"
                                    onClick={() => handleDeleteIconClick(id)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </TableRow>
                      </div>
                    </div>
                  );
                })
              )
            }
          }

          {
            totalNumberOfDealers &&
            <TableFooter
              key="dealers-table-footer"
              page={page}
              perPage={perPage}
              total={totalNumberOfDealers}
            />
          }
        </Table>
      </div>

      <ConfirmDeleteModal
        title={`Delete ${dealerToBeDeletedTitle}`}
        open={openedDeleteModal}
        closeModal={() => setOpenedDeleteModal(false)}
        onConfirm={_deleteDealer}
      />
    </div>
  )
};

DealersList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDealers: PropTypes.func.isRequired,
  dealers: PropTypes.array.isRequired,
  totalNumberOfDealers: PropTypes.number.isRequired,
  getDealersStatusChoices: PropTypes.func.isRequired,
  dealersStatusChoices: PropTypes.object.isRequired,
  deleteDealer: PropTypes.func.isRequired,
  patchDealer: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
  searchDealers: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    dealers: state.dealers.dealers,
    totalNumberOfDealers: state.dealers.totalNumberOfDealers,
    dealersStatusChoices: state.dealers.dealersStatusChoices,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getDealers: payload => dispatch(getDealersRequest(payload)),
    getDealersStatusChoices: payload => dispatch(getDealersStatusChoicesRequest(payload)),
    deleteDealer: payload => dispatch(deleteDealerRequest(payload)),
    patchDealer: (payload, callback) => dispatch(patchDealerRequest(payload, callback)),
    searchDealers: (params, query) => dispatch(searchDealersRequest({ params, query })),
  })
)(DealersList);

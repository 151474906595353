import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { Checkbox, Button, Popconfirm, message } from "antd";
import { SyncOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

// components
import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";

// helpers
import dealers from "../../../../api/dealers";
import { Utils } from "../../../../helpers";
import navigation from "../../../../helpers/navigation";

const DealersNewsPopUp = ({
  location,
  history,
  userRole,
}) => {
  const [dealerNewsResp, setDealerNewsResp] = useState({ count: 0, results: [] });

  const { page, perPage, order } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");
  const hasActiveNews = dealerNewsResp.results.some(item => item && item.showInPopup);

  const fetchDealerNew = (params = {}) => {
    dealers.getDealerPopupNews({ page, perPage, ...params })
      .then(resp => setDealerNewsResp(resp))
      .catch(() => message.error("Something is wrong"));
  }

  useEffect(() => fetchDealerNew(), [page, perPage]); // eslint-disable-line

  const onCheck = async (e, id) => {
    await dealers.patchSingleDealerPopupNews(id, { showInPopup: e.target.checked })
      .catch(() => message.error("Something is wrong"));

    fetchDealerNew();
  };

  const dealersNewsToRender = Utils.filterAndOrder(dealerNewsResp.results, { order });

  const showHideDealerNewsModal = ({ showPopup }) => {
    dealers.showDealerNewsPopup({ showPopup })
      .then(() => {
        message.success(showPopup ? "Dealer news model now will be shown for dealers when they will login" : "Dealer news model will be hidden");

        if (!showPopup) fetchDealerNew();
      })
      .catch(() => message.error("Something is wrong"))
  }

  const handleDelete = (id) => {
    dealers.deleteSingleDealerPopupNews(id)
      .then(() => {
        dealerNewsResp.results.length > 1
          ? fetchDealerNew()
          : navigation.setPageAndPerPage(+page - 1, perPage, location, history);
      })
      .catch(() => message.error("Something is wrong"));
  }

  const renderDealersNewsTable = () => (
    <>
      <Table
        className="dealers-news--table"
        columnWidths={["40px", "15%", "auto", "10%", "80px"]}
        headerCells={[
          {},
          { label: "TITLE" },
          { label: "ARTICLE" },
          { label: "DATE" },
          { label: "ACTIONS", },
        ]}
      >
        {
          dealersNewsToRender && dealersNewsToRender.map((newsItem) => {
            const {
              id,
              createdAt,
              showInPopup,
              content,
              title,
            } = newsItem;
            const dateToDisplay = moment(createdAt).format("L HH:mm:ss");

            return (
              <TableRow key={`dealers-news--row-${id}`}>
                <Checkbox
                  checked={showInPopup}
                  onChange={e => onCheck(e, id)}
                />
                <Link to={`/dealers/dealer-news/pop-up/${id}`} className="dealers-news--title-column flex">{title}</Link>
                <div className="dealers-news--article-column flex" dangerouslySetInnerHTML={{ __html: content }} />
                <div className="dealers-news--date-column flex">{dateToDisplay}</div>
                <div className="dealers-news--actions-column">
                  <Link to={`/dealers/dealer-news/pop-up/${id}`} className="dealers-news--title-column flex">
                    <EditOutlined className="dealers-news--action-icon" />
                  </Link>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => handleDelete(id)}
                  >
                    <DeleteOutlined className="dealers-news--action-icon" />
                  </Popconfirm>
                </div>
              </TableRow>
            )
          })
        }
        {
          dealerNewsResp.count > 0 && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={dealerNewsResp.count}
            />
          )
        }
      </Table>
    </>
  )

  return (
    <div className="dealers-news">
      <div className="dealers-news__header-controls">
        {!isDisabled &&
          <>
            <AddButton
              onClick={() => history.push("/dealers/dealer-news/pop-up/new")}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => showHideDealerNewsModal({ showPopup: true })}
              disabled={!hasActiveNews}
            >
              <Button
                type="primary"
                disabled={!hasActiveNews}
              >
                <SyncOutlined />
                Activate
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => showHideDealerNewsModal({ showPopup: false })}
            >
              <Button>
                Deactivate
              </Button>
            </Popconfirm>
          </>
        }
      </div>

      {renderDealersNewsTable()}

    </div>
  )
}

DealersNewsPopUp.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getDealersNews: PropTypes.func.isRequired,
  dealersNews: PropTypes.array.isRequired,
  dealerNewsTotal: PropTypes.number.isRequired,
  patchDealersNewsItem: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  userRole: state.auth.userProfile.roles,
})

export default connect(mapStateToProps, null)(DealersNewsPopUp);

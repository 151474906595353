import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//components
import TableAndFormLayout from "../../../shared/TableAndFormLayout/TableAndFormLayout";
import Table from "../../../shared/Table/Table";
import TableFooter from "../../../shared/Table/TableFooter";
import ApiKeyRequestRow from "./ApiKeyRequestRow";

//redux
import {
  getDealersApiKeysRequests,
  changeDealerApiKeyRequestStatus,
  revokeDealerApiKey
} from "../../../../redux/dealers/dealersActions";

//helpers
import navigation from "../../../../helpers/navigation";

const DealersApiKeyRequests = ({
  location,
  history,
  dealersApiTokens,
  getDealersApiKeys,
  changeDealerApiKeyStatus,
  dealersApiTokensCount,
  revokeApiKey
}) => {
  const { page, perPage, search, statusOption, dateOption } = navigation.getTableQueryParams(location);

  // Effects

  useEffect(() => {
    getDealersApiKeys({ page, pageSize: perPage, statusOption, dateOption, search });
  }, [page, perPage, search, statusOption, dateOption]); // eslint-disable-line

  // Methods

  const handleChangeToken = (id, data) => {
    changeDealerApiKeyStatus({
      id,
      data,
      location,
      history,
      params: { page, pageSize: perPage, statusOption, dateOption, search }
    });
  };

  const handleRevokeApiKey = (dealerId) => {
    revokeApiKey({ dealerId, params: { page, pageSize: perPage, statusOption, dateOption, search } });
  }

  // Render

  return (
    <div className="website-builder--container">
      <TableAndFormLayout>
        <TableAndFormLayout.Table className="Header">
          <div className="website-builder--container--content">
            <Table
              columnWidths={["10%", "15%", "20%", "23%", "15%", "12%", "10%"]}
              headerCells={[
                { label: "Status" },
                { label: "Date of Request" },
                { label: "Dealer Email" },
                { label: "Business Name" },
                { label: "Domain" },
                { label: "Rights" },
                { label: "Actions" },
              ]}
            >
              {({ columnWidths }) => {
                return dealersApiTokens.map(item => {
                  return (
                    <ApiKeyRequestRow
                      key={item.id}
                      columnWidths={columnWidths}
                      rowData={item}
                      handleChangeToken={handleChangeToken}
                      handleDelete={() => handleRevokeApiKey(item.dealer.id)}
                    />
                  )
                })
              }}

              {dealersApiTokensCount && (
                <TableFooter
                  key="footer"
                  page={page}
                  perPage={perPage}
                  total={dealersApiTokensCount}
                />
              )}
            </Table>
          </div>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
};

DealersApiKeyRequests.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dealersApiTokens: PropTypes.array.isRequired,
  getDealersApiKeys: PropTypes.func.isRequired,
  changeDealerApiKeyStatus: PropTypes.func.isRequired,
  revokeApiKey: PropTypes.func.isRequired,
  dealersApiTokensCount: PropTypes.number.isRequired
}

export default connect(
  state => ({
    dealersApiTokens: state.dealers.dealersApiTokens,
    dealersApiTokensCount: state.dealers.dealersApiTokensCount
  }),
  {
    getDealersApiKeys: getDealersApiKeysRequests,
    changeDealerApiKeyStatus: changeDealerApiKeyRequestStatus,
    revokeApiKey: revokeDealerApiKey,
  }
)(DealersApiKeyRequests);

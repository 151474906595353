import React, { useState } from "react";
import { message, Form, Upload, Spin, Button, Input } from "antd";
import ReactPlayer from "react-player";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

// api
import content from "../../../api/content";

// components
import { LantFormItem } from "../LantFormItem";


export const UploadVideosTemp = ({ name, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const formInstance = Form.useFormInstance();
  const videos = Form.useWatch(name, formInstance) || [];

  const handleDeleteVideo = (value) => {
    formInstance.setFieldValue(name, videos?.filter((el) => el.url !== value && el.video?.url !== value));
  };

  const addVideoFromLink = () => {
    formInstance.setFieldValue(name, videos?.concat([{
      video: {},
      url: videoLink,
      useFromUrl: true
    }]));

    setVideoLink("");
  };

  return (
    <LantFormItem name={name} {...rest}>
      <Spin spinning={loading}>
        <div className="multiple-videos--preview">
          {videos?.map((el) => {
            const url = el.useFromUrl && el.url ? el.url : el.video?.url;

            return (
              <div key={`video-preview-${url}`} className="la-video__preview">
                <ReactPlayer
                  className="la-video__preview-tag"
                  url={url}
                  controls
                  width="256px"
                  height="144px"
                />
                <span className="la-video__remove-icon" onClick={() => handleDeleteVideo(url)}>
                  <CloseOutlined />
                </span>
              </div>
            );
          })}
          {videos?.length < 5 && (
            <div className="multiple-videos--uploader">
              <Upload.Dragger
                className="la-video__uploader"
                name="file"
                accept=".mp4,.mov"
                customRequest={({ file }) => {
                  setLoading(true);

                  content
                    .uploadNewVideo(file)
                    .then(resp => {
                      formInstance.setFieldValue(name, videos?.concat([{
                        video: resp,
                        url: null,
                        useFromUrl: false
                      }]));

                      setVideoLink("");
                    })
                    .catch(() => message.error("Something went wrong. Please try again."))
                    .finally(() => setLoading(false))
                }}
                showUploadList={false}
                multiple={false}
              >
                <div>
                  <PlusOutlined />
                  <div className="ant-upload-text">Upload</div>
                </div>
              </Upload.Dragger>
              <div className="multiple-videos--divider">or</div>
              <div className="multiple-videos--url">
                <Input
                  type="url"
                  placeholder="Add YouTube or Vimeo URL"
                  value={videoLink}
                  onChange={(evt) => setVideoLink(evt.target.value)}
                />
                <Button type="primary" onClick={addVideoFromLink}>Add</Button>
              </div>
            </div>
          )}
        </div>
      </Spin>
      <div className="multiple-images-uploader--bottom-label">
        You can upload no more than 5 videos. Please upload .mp4 or .mov
      </div>
    </LantFormItem>
  );
};

UploadVideosTemp.propTypes = {
  name: PropTypes.string.isRequired,
}

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import CustomDateRangePicker from "../../shared/CustomDateRangePicker";
import DealerSelectWithSearch from "../../shared/DealerSelectWithSearch";
import DeliveryEnquiriesRow from "./DeliveryEnquiriesRow";

import enquiries from "../../../api/enquiries";
import { patchEmailEnquiriesRequest } from "../../../redux/enquiries/emailEnquiries/emailEnquiriesActions";
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";


function EmailEnquiries(props) {
  const { location, history, userProfile } = props;

  const { search, page, perPage, order, dateFilter } = navigation.getTableQueryParams(location);
  const [dealerId, setDealerId] = useState();
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const searchDeliveryEnquiry = useCallback(debounce(params => {
    enquiries.searchDelivery(params).then(({ count, results }) => {
      setDeliveryItems(results);
      setTotalItems(count);
    });
  }, 200), []); // eslint-disable-line

  // Pagination/search api call here
  useEffect(() => {
    const params = {
      page,
      perPage,
      dateFilter,
      query: search
    };

    if (dealerId) params.dealer = dealerId;
    if (dealerId === "All dealers") delete params.dealer;

    if (search) {
      searchDeliveryEnquiry(params);

      return;
    }

    enquiries.getDelivery(params).then(({ count, results }) => {
      setDeliveryItems(results);
      setTotalItems(count);
    });

  }, [page, perPage, dateFilter, dealerId, search]); // eslint-disable-line

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const itemsToRender = Utils.filterAndOrder(deliveryItems, { order });

  return (
    <div className="enquiries">
      <TableAndFormLayout showForm={false}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="flex">
              <CustomDateRangePicker dateFilter={dateFilter} />
              <div className="email-enq--filter-by-dealer">
                <div className="email-enq--filter-by-dealer--label">Dealer:</div>
                <DealerSelectWithSearch
                  placeholder="By Dealer"
                  onSelect={(value) => setDealerId(value)}
                />
              </div>
            </div>

            <div className="flex">
              <SearchInput value={search} onChange={onSearch} />
            </div>
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["150px", "22%", "20%", "20%", "auto"]}
            headerCells={[
              { label: "Date" },
              { label: "Item" },
              { label: "Dealer" },
              { label: "Customer" },
              { label: "Customer Message" },
            ]}
          >
            {({ columnWidths }) => {
              return itemsToRender.map(enquiry => {
                return (
                  <DeliveryEnquiriesRow
                    key={`group:${enquiry.id}`}
                    columnWidths={columnWidths}
                    enquiry={enquiry}
                    userRole={userProfile.roles[0]}
                    history={history}
                  />
                )
              })
            }}

            {totalItems && (
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            )}
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
}

EmailEnquiries.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  patchEmailEnquiries: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    userProfile: state.auth.userProfile,
  }),
  dispatch => ({
    patchEmailEnquiries: (id, data, status) => dispatch(patchEmailEnquiriesRequest(id, data, status)),
  })
)(EmailEnquiries);

import fetch from "./customFetch";


const catalog = {
  async get() {
    const result = await fetch({
      url: "/catalogs/?page=1&page_size=200",
      method: "GET"
    });

    return result.results;
  },

  async create(data) {
    try {
      return await fetch({
        url: "/catalogs/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatch(entityUrl, data) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePut(entityUrl, data) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async delete(entityUrl) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getEntityElements(entityUrl, { page, perPage }) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getEntityElement(entityUrl, itemUrl) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/${itemUrl}/`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchEntityElements(entityUrl, { page, perPage, search }) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/search/?page=${page}&page_size=${perPage}&query=${search}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createEntityElement(entityUrl, data) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/`,
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatchEntityElement(entityUrl, itemUrl, data) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/${itemUrl}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePutEntityElement(entityUrl, itemUrl, data) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/${itemUrl}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteEntityElement(entityUrl, itemUrl) {
    try {
      return await fetch({
        url: `/catalogs/${entityUrl}/elements/${itemUrl}/`,
        method: "DELETE"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getHomeAndLivingList({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/home-and-living/?page=${page}&page_size=${perPage}`,
        method: "GET",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchInHomeAndLivingList({ page = 1, perPage = 10, search }) {
    try {
      return await fetch({
        url: `/home-and-living/search/?page=${page}&page_size=${perPage}&query=${search}/`,
        method: "GET",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateHomeAndLivingItem({ data, url }) {
    try {
      return await fetch({
        url: `/home-and-living/${url}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteHomeAndLivingItem(url) {
    try {
      return await fetch({
        url: `/home-and-living/${url}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getHomeAndLivingItem(url) {
    try {
      return await fetch({
        url: `/home-and-living/${url}/`,
        method: "GET",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createHomeAndLivingItem(data) {
    try {
      return await fetch({
        url: "/home-and-living/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default catalog;

import * as types from "./offersActionsTypes";

export const getOffersRequest = payload => {
  return { type: types.GET_OFFERS_REQUEST, payload };
};

export const getUserOffersRequest = payload => {
  return { type: types.GET_USER_OFFERS_REQUEST, payload };
};

export const patchOfferRequest = payload => {
  return { type: types.PATCH_OFFERS_REQUEST, payload };
};

export const createOfferRequest = payload => {
  return { type: types.CREATE_OFFER_REQUEST, payload };
};

export const createUserOfferRequest = payload => {
  return { type: types.CREATE_USER_OFFER_REQUEST, payload };
};

export const resetErrorsRequest = () => {
  return { type: types.CLEAR_ERRORS };
};

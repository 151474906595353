import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import Pages from "./Pages/Pages"
import HelpText from "./HelpText/HelpText"
import Blog from "./Blog/Blog";
import AddNewPage from "./Pages/AddNewPage"
import AddHelpTextItem from "./HelpText/AddHelpTextItem";


function Content() {
  return (
    <div>
      <Switch>
        <Route exact path="/content/pages" component={Pages} />
        <Route path="/content/pages/add-new-page" component={AddNewPage} />
        <Route exact path="/content/help-text" component={HelpText} />
        <Route path="/content/help-text/add-help-text-item" component={AddHelpTextItem} />
        <Route path="/content/blog" component={Blog} />
        <Redirect to="/content/pages" />
      </Switch>
    </div>
  )
}

export default Content;

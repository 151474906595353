import { all, put, call, takeLatest } from "redux-saga/effects";

import enquiries from "../../../api/enquiries";
import * as types from "./enquiryActivityActionsTypes";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    let response = {};
    if (params.search) {
      response = yield call(enquiries.searchActivity, params);
    } else {
      response = yield call(enquiries.getActivity, params);
    }

    yield put({ type: types.GET_ENQUIRY_ACTIVITY_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ENQUIRY_ACTIVITY_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_ENQUIRY_ACTIVITY_REQUEST, get),
  ])
}

// helpers
import buildQueryString from "../helpers/buildQueryString";
import fetch from "./customFetch";


const categories = {
  async get({ page, perPage, isParentCategories }) {
    const query = buildQueryString({
      page,
      "page_size": perPage,
      parent: isParentCategories && "null",
    });

    try {
      return await fetch({
        url: `/categories/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSelectList({ page, perPage, isParentCategories }) {
    const query = buildQueryString({
      page,
      "page_size": perPage,
      parent: isParentCategories && "null",
    });

    try {
      return await fetch({
        url: `/categories/select-list/?${query}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async search({ page, perPage, search }) {
    try {
      return await fetch({
        url: `/categories/search/?page=${page}&page_size=${perPage}&query=${search}&is_active=false`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async create(data) {
    try {
      return await fetch({
        url: "/categories/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }
      if (err.message) {
        throw err.message;
      }

      throw err;
    }
  },

  async updatePut(url, data) {
    try {
      return await fetch({
        url: `/categories/${url}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatch(url, data) {
    try {
      return await fetch({
        url: `/categories/${url}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async delete(url) {
    try {
      return await fetch({
        url: `/categories/${url}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getOne(url) {
    try {
      return await fetch({
        url: `/categories/${url}/`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getOneById(id) {
    try {
      return await fetch({
        url: `/categories/detail/${id}/`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getCategoryFAQs(id) {
    try {
      return await fetch({
        url: `/categories/${id}/articles/`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async createCategoryFAQs({ id, data }) {
    try {
      return await fetch({
        url: `/categories/${id}/articles/`,
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchCategoryFAQ({ categoryId, faqId, data }) {
    try {
      return await fetch({
        url: `/categories/${categoryId}/articles/${faqId}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default categories;

import * as types from "./categoriesActionTypes";


const initialState = {
  items: [],
  total: 0,
  processing: false,
  error: "",
  categoryFAQs: [],
  loadingFAQ: false,
  processingFAQ: false,
  errorFAQ: null,
};


const categories = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.GET_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results || [],
        total: action.payload.count,
      };

    case types.GET_CATEGORIES_REQUEST_ERROR:
    case types.CREATE_CATEGORIES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: "",
      };

    case types.GET_CATEGORY_FAQ_REQUEST_SUCCESS:
      return {
        ...state,
        categoryFAQs: action.payload,
      };

    case types.TOGGLE_CATEGORY_FAQ_PROCESSING:
      return {
        ...state,
        processingFAQ: action.payload,
      };

    case types.TOGGLE_CATEGORY_FAQ_LOADING:
      return {
        ...state,
        loadingFAQ: action.payload,
      };

    case types.GET_CATEGORY_FAQ_REQUEST_ERROR:
    case types.CREATE_CATEGORY_FAQ_REQUEST_ERROR:
    case types.PATCH_CATEGORY_FAQ_REQUEST_ERROR:
      return {
        ...state,
        errorFAQ: action.payload,
      };

    default:
      return state;
  }
};

export default categories;

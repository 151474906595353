import * as types from "./artisansActionTypes";


const initialState = {
  items: [],
  total: 0,
  tags: [],
  processing: false,
  error: "" // not sure if we need it here
};


const artisans = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    case types.GET_ARTISANS_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results || [],
        total: action.payload.count,
      };

    case types.GET_ARTISANS_TAGS_REQUEST_SUCCESS:
      return {
        ...state,
        tags: action.payload.results,
      };

    case types.GET_ARTISANS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ""
      };

    default:
      return state;
  }
};

export default artisans;

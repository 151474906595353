import { useQuery } from "react-query";

import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({ url: `/directory/categories/?${buildQueryString(params)}`, method: "GET" });
};

export const useDirectoryCategoryList = (params, options) => {
  return useQuery(["directory-category-list", JSON.stringify(params)], () => fetcher(params), options);
};

import React from "react";
import PropTypes from "prop-types";

// components
import ActiveInvoiceBlock from "./ActiveInvoiceBlock";

// helpers
import formatPrice from "../../../../../helpers/formatPrice";


const ActiveInvoicesSection = ({ activeInvoicesData }) => {
  const {
    availableAntiques,
    lastInvoiceAmount,
    inactiveAntiques,
    reservedAntiques,
    soldAntiques,
    totalValueOfStock,
  } = activeInvoicesData;

  return (
    <div className="active-invoices-section">
      <div className="active-invoices-section--title">Active Invoices</div>
      <div className="active-invoices-section--content">
        <div className="active-invoices-section--left-column">
          <ActiveInvoiceBlock
            value={availableAntiques ? availableAntiques : 0}
            text="Number of available antiques"
          />
          <ActiveInvoiceBlock
            value={reservedAntiques ? reservedAntiques : 0}
            text="Number of reserved antiques"
          />
          <ActiveInvoiceBlock
            value={totalValueOfStock ? `£${formatPrice(totalValueOfStock)}` : 0}
            text="Total value of stock"
          />
        </div>
        <div className="active-invoices-section--right-column">
          <ActiveInvoiceBlock
            value={soldAntiques ? soldAntiques : 0}
            text="Number of sold antiques"
          />
          <ActiveInvoiceBlock
            value={lastInvoiceAmount ? lastInvoiceAmount : 0}
            text="Last invoice amount (Net)"
          />
          <ActiveInvoiceBlock
            value={inactiveAntiques ? inactiveAntiques : 0}
            text="Number of inactive antiques"
          />
        </div>
      </div>
    </div>
  )
};

ActiveInvoicesSection.propTypes = {
  activeInvoicesData: PropTypes.object.isRequired,
};

export default ActiveInvoicesSection;

import * as types from "./periodsActionTypes";


export const getPeriodsRequest = params => {
  return { type: types.GET_PERIODS_REQUEST, params };
};

export const createPeriodsRequest = (data, callback) => {
  return { type: types.CREATE_PERIODS_REQUEST, data, callback };
};

export const updatePeriodsRequest = (url, data, isPatch, callback) => {
  return { type: types.UPDATE_PERIODS_REQUEST, url, data, isPatch, callback };
};

export const deletePeriodsRequest = (url, callback) => {
  return { type: types.DELETE_PERIODS_REQUEST, url, callback };
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR };
};

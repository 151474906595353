import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import _ from "lodash";

import {
  getContentHelpTextRequest,
  patchHelpTextItemRequest
} from "../../../redux/content/helptext/helpTextActions";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";
import LabeledInput from "../../shared/LabeledInput";
import EditHelpTextItem from "./EditHelpTextItem";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";


const HelpText = ({
  location,
  history,
  getContentHelpTextItems,
  patchHelpTextItem,
  helpTextItems,
  totalNumberOfHelpTextItems = 10
}) => {
  const [selectedHelpTextItem, setSelectedHelpTextItem] = useState(null);
  const [isEditFullScreen, setEditFullScreen] = useState(false);
  const [sortValue, setSortValue] = useState();
  const [renderAddNewHelpTextItem, setRenderAddNewHelpTextItem] = useState(false);
  const { order, search = "", page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getContentHelpTextItems({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const sortHelpTextList = (items) => {
    const sortBy = sortValue === "By Title" ? "helpTitle" : "helpType";
    items.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return -1;
      if (a[sortBy] > b[sortBy]) return 1;

      return 0;
    });

    return items;
  };

  const helpTextItemsToRender = Utils.filterAndOrder(helpTextItems, { order, search, searchKey: "helpTitle" });
  const sortedHelpTextItemsToRender = sortValue
    ? sortHelpTextList(helpTextItemsToRender)
    : helpTextItemsToRender;
  const currentSelectedHelpTextItem = _.find(helpTextItems, { id: selectedHelpTextItem });

  const onCheck = (e, helpTextItem) => {
    helpTextItem = Object.assign(helpTextItem, { isActive: e.target.checked });
    patchHelpTextItem({ helpTextItem, page, perPage });
  };

  const handleSortHelpTextItems = (value) => {
    setSortValue(value)
  };

  const handleCloseEditPage = () => {
    setEditFullScreen(false);
    setSelectedHelpTextItem("");
  }

  if (renderAddNewHelpTextItem) return <Redirect to="/content/help-text/add-help-text-item" />

  return (
    <div className="help-text component">
      <TableAndFormLayout showForm={!!currentSelectedHelpTextItem} isFormFullscreen={isEditFullScreen}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <div className="component--header-section">
              <AddButton onClick={() => { setRenderAddNewHelpTextItem(true) }} />

              <div className="component--header-sort-button">
                <LabeledInput
                  className="sort"
                  label="Sort"
                  type="select"
                  options={["By Title", "By Type"]}
                  value={sortValue}
                  placeholder="By Title"
                  onChange={(e) => handleSortHelpTextItems(e)}
                  horizontal
                />
              </div>
            </div>

            <SearchInput
              value={search}
              onChange={value => navigation.setSearch(value, location, history)}
            />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            className={`help-text--table ${currentSelectedHelpTextItem ? "help-text--table__shrink" : ""}`}
            columnWidths={["40px", "25%", "25%", "auto"]}
            headerCells={[
              {},
              { label: "help ID" },
              { label: "help title" },
              { label: "help type" },
            ]}
          >
            {
              sortedHelpTextItemsToRender.map(helpTextItem => {
                const { id, helpId, isActive, helpTitle, helpType } = helpTextItem;

                return (
                  <TableRow
                    key={`help-text-${id}`}
                    isHighlighted={selectedHelpTextItem === id || !helpTextItem.isActive}
                  >
                    <Checkbox
                      onChange={e => onCheck(e, helpTextItem)}
                      checked={isActive}
                    />
                    <div
                      className="title-column flex"
                      onClick={() => setSelectedHelpTextItem(id)}
                    >
                      {helpId}
                    </div>
                    <div
                      className="title-column flex"
                      onClick={() => setSelectedHelpTextItem(id)}
                    >
                      {helpTitle}
                    </div>
                    <div
                      className="url-column flex"
                      onClick={() => setSelectedHelpTextItem(id)}
                    >
                      {helpType}
                    </div>
                  </TableRow>
                )
              })
            }

            {totalNumberOfHelpTextItems > 0 && (
              <TableFooter
                key="help-text-table-footer"
                page={page}
                perPage={perPage}
                total={totalNumberOfHelpTextItems}
              />
            )}
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          {
            currentSelectedHelpTextItem ? (
              <EditHelpTextItem
                key={`selectedItem:${currentSelectedHelpTextItem.id}`}
                helpTextItem={currentSelectedHelpTextItem}
                isFullScreen={isEditFullScreen}
                toggleFullScreen={setEditFullScreen}
                close={handleCloseEditPage}
                page={page}
                perPage={perPage}
              />
            ) : null
          }
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

HelpText.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getContentHelpTextItems: PropTypes.func.isRequired,
  patchHelpTextItem: PropTypes.func.isRequired,
  helpTextItems: PropTypes.array.isRequired,
  totalNumberOfHelpTextItems: PropTypes.number.isRequired,
};

export default connect(
  ({ helpText }) => helpText,
  dispatch => ({
    getContentHelpTextItems: payload => dispatch(getContentHelpTextRequest(payload)),
    patchHelpTextItem: payload => dispatch(patchHelpTextItemRequest(payload))
  })
)(HelpText);

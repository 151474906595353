import * as types from "./modalActionsTypes";


export const openModal = (content, props) => {
  return {
    type: types.OPEN_MODAL,
    content,
    props
  }
};

export const closeModal = () => {
  return { type: types.CLOSE_MODAL }
};

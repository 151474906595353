import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Button, message, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

//components
import LabeledInput from "../../../shared/LabeledInput";

//helpers
import dealers from "../../../../api/dealers";
import {
  createDealerNewsItemRequest
} from "../../../../redux/dealers/dealersActions";


const AddNewsItem = ({
  error,
  processing,
  history,
  createDealerNewsItem
}) => {
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState("Title");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [content, setContent] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { url } = useParams();

  const validateFields = () => {
    setIsTitleValid(title !== "");
  };

  useEffect(() => {
    validateFields();
  }, [title]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing) return;
    if (error) return setShowErrorText(error);

    if (!error) setShowErrorText(false);

    return history.push("/dealers/dealer-news/");
  }, [error, processing]); // eslint-disable-line

  useEffect(() => {
    if (url) {
      dealers.getSingleDealersNews(url)
        .then((resp) => {
          setTitle(resp.title);
          setContent(resp.content);
          setIsActive(resp.isActive);
        })
    }
  }, [url]); // eslint-disable-line

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createDealerNewsItem = () => {
    validateFields();
    if (!isTitleValid) return;

    setFormSubmitted(true);
    const dealerNewsAttachment = null;

    if (url) {
      dealers.patchDealersNews({
        url,
        isActive,
        title,
        content,
        dealerNewsAttachment,
      })
        .then(() => history.push("/dealers/dealer-news"))
        .catch(() => message.error("Something went wrong"));

      return;
    }
    createDealerNewsItem({
      isActive,
      title,
      content,
      dealerNewsAttachment
    })
  };

  const onDelete = () => {
    dealers.deleteSingleDealersNews(url)
      .then(() => history.push("/dealers/dealer-news"))
      .catch(() => message.error("Something went wrong"));
  }

  const renderPageTitle = () => (
    <div className="add-dealer-news-item--row">
      <div className="add-dealer-news-item--title">{!url ? "Add" : "Edit"} News Item</div>
    </div>
  );

  const renderItemCheckbox = () => (
    <div className="add-dealer-news-item--row checkbox-holder">
      <Checkbox
        className="checkbox-small"
        checked={isActive}
        onChange={onCheck}
      >
        Active
      </Checkbox>
    </div>
  );

  const renderItemTitleField = () => (
    <>
      <LabeledInput
        label="Title"
        isRequired
        value={title}
        onChange={setTitle}
      />
      {
        !isTitleValid
          ? <p className="error">Can't be blank. This field is required</p>
          : showErrorText
            ? <p className="error">News item with such title is already exist</p>
            : null
      }
    </>
  );

  const renderItemPageContentField = () => (
    <LabeledInput
      label="Page Content"
      type="rich-text"
      value={content}
      onChange={setContent}
      className="add-dealer-news-item--content-input"
    />
  )

  const renderPageActionButtons = () => (
    <div className="add-dealer-news-item--button-holder">
      <Button
        className="ant-btn-primary"
        onClick={_createDealerNewsItem}
      >
        SAVE & CLOSE
      </Button>
      <div className="del-btn-holder">
        <Button
          className="btn-secondary"
          onClick={() => history.push("/dealers/dealer-news/")}
        >
          CANCEL
        </Button>
      </div>
      {
        url
          ? (
            <Popconfirm
              placement="top"
              onConfirm={onDelete}
              title="Are you sure you want to delete this news?"
            >
              <div className="del-btn-holder">
                <Button
                  className="btn-secondary"
                >
                  <DeleteOutlined/> DELETE
                </Button>
              </div>
            </Popconfirm>
          )
          : null
      }
    </div>
  )

  return (
    <div className="add-dealer-news-item">
      {renderPageTitle()}
      {renderItemCheckbox()}
      {renderItemTitleField()}
      {renderItemPageContentField()}
      {renderPageActionButtons()}
    </div>
  )
};

AddNewsItem.propTypes = {
  createDealerNewsItem: PropTypes.func.isRequired,
  error: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => state.dealers,
  dispatch => ({
    createDealerNewsItem: payload => dispatch(createDealerNewsItemRequest(payload))
  })
)(AddNewsItem);

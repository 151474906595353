import * as types from "./emailProfilesActionsTypes";


export const getEmailProfilesRequest = payload => {
  return { type: types.GET_EMAIL_PROFILES_REQUEST, payload };
};

export const createEmailProfileRequest = payload => {
  return { type: types.CREATE_EMAIL_PROFILE_REQUEST, payload };
};

export const resetErrorsAction = () => {
  return { type: types.RESET_EMAIL_PROFILES_ERRORS };
};

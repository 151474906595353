import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Checkbox, Button } from "antd";

import LabeledInput from "../../shared/LabeledInput";
import { connect } from "react-redux";
import { deletePageRequest, updatePageRequest } from "../../../redux/content/pages/pagesActions";


function EditPage({
  userRole,
  page,
  isFullScreen,
  toggleFullScreen,
  close,
  deletePage,
  updatePage,
  pagePerPage,
  error,
  processing,
}) {
  const [isActive, setIsActive] = useState(page.isActive);
  const [title, setTitle] = useState(page.title || "");
  const [url, setUrl] = useState(page.url || "");
  const [content, setContent] = useState(page.pageContent || "");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [closeEditOnSuccess, setCloseEditOnSuccess] = useState(false);

  const isDisabled = !userRole.includes("Super Admin");

  const defaultURL = "https://www.loveantiques.com/";
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;
  const serverError = error || {};


  // Methods

  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    setCloseEditOnSuccess(true);
  }, [error, processing, formSubmitted]); // eslint-disable-line

  const _deletePage = () => {
    deletePage({ id: page.id, pagePerPage });
  };

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _updatePage = () => {
    updatePage({
      id: page.id,
      data: {
        title: title,
        url: url,
        page_content: content,
        is_active: isActive,
        pagePerPage
      }
    });
  };

  const classNames = ["edit-page"];
  if (isFullScreen) classNames.push("edit-page__fullscreen");
  const fullScreenIcon = isFullScreen ? "/images/fullScreenExitIcon.png" : "/images/fullscreen_3x.svg";
  const fullScreenIconId = isFullScreen ? "full-screen-exit-icon" : "";

  closeEditOnSuccess && close("");

  // Render Functions

  const renderFields = () => (
    <>
      <div className="edit-page--row checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isActive}
          onChange={onCheck}
        >
          Active
        </Checkbox>
      </div>
      <LabeledInput
        label="Title"
        isRequired
        value={title}
        errorText={serverError.title}
        onChange={value => { setTitle(value) }}
      />
      <div className="edit-page--url-input-holder">
        <LabeledInput
          label="URL"
          isRequired
          value={url}
          addonBefore={defaultURL}
          suffix={urlFieldImage}
          errorText={serverError.url}
          inputClassName="input-with-prefix"
          onChange={value => { setUrl(value) }}
        />
      </div>
      <LabeledInput
        label="Page Content"
        type="rich-text"
        value={content}
        onChange={value => { setContent(value) }}
        className="edit-page--page-content-input"
      />
      <div className="edit-page--button-holder">
        <Button className="ant-btn-primary" onClick={_updatePage} disabled={isDisabled}>
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button className="btn-secondary" onClick={_deletePage} disabled={isDisabled}>
            <img src="/images/delete_3x.svg" alt="delete icon" />
            DELETE ITEM
          </Button>
        </div>
      </div>
    </>
  );

  const renderActionsButtons = () => (
    <div className="edit-page--button-holder">
      <Button className="ant-btn-primary" onClick={_updatePage}>SAVE & CLOSE</Button>
      <div className="del-btn-holder">
        <Button className="btn-secondary" onClick={_deletePage}>
          <img src="/images/delete_3x.svg" alt="delete icon" />
          DELETE ITEM
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classNames.join(" ")}>
      <div className="edit-page--row">
        <span className="edit-page--title">Edit Page</span>

        <div className="edit-page--row image-holder">
          <img
            id={fullScreenIconId}
            src={fullScreenIcon}
            alt="fullscreen"
            onClick={() => toggleFullScreen(!isFullScreen)}
          />
          <img src="/images/close.svg" alt="close" onClick={close} />
        </div>
      </div>

      {renderFields()}
      {renderActionsButtons()}
    </div>
  )
}

EditPage.propTypes = {
  page: PropTypes.object.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  toggleFullScreen: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  deletePage: PropTypes.func.isRequired,
  updatePage: PropTypes.func.isRequired,
  pagePerPage: PropTypes.object.isRequired,
  error: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    error: state.pages.error,
    processing: state.pages.processing,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    updatePage: payload => dispatch(updatePageRequest(payload)),
    deletePage: payload => dispatch(deletePageRequest(payload))
  })
)(EditPage);

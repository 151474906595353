import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

import fetch from "../../api/customFetch";
import navigation from "../../helpers/navigation";
import { ALL_DIRECTORY_CATEGORIES_PARAMS } from "../../constants";

const fetcher = ({ id, ...data }) => {
  return fetch({
    url: `/directory/categories/${id}/`,
    method: "PATCH",
    data
  });
};

export const useDirectoryCategoryToggle = (options) => {
  const queryClient = useQueryClient();

  const location = useLocation();
  const { page, perPage } = navigation.getTableQueryParams(location);
  const queryKey = ["directory-category-list", JSON.stringify({ page, page_size: perPage })];

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      const prevData = queryClient.getQueryData(queryKey);

      queryClient.invalidateQueries(["directory-category-list", JSON.stringify(ALL_DIRECTORY_CATEGORIES_PARAMS)]);

      queryClient.setQueryData(queryKey, {
        ...prevData,
        results: prevData?.results.map((el) => el.id === resp.id ? { ...el, ...resp } : el)
      });

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { selectCurrencySymbol } from "../../../../../helpers/currency";

import "../../../../../assets/styles/components/orders/templates/paypalBuyerThankYou.scss";

const PaypalBuyerThankYou = ({ order }) => {
  return (
    <div className="root-container">
      <div className="template-name">
        Purchase Confirmation from LoveAntiques.com
      </div>

      <p className="text-align-center">
        <span className="red-color">Dear</span>{" "}
        <span className="fw-700">{order.billTo.name || "customer"}</span>,
      </p>
      <p className="text-align-center">
        Thank you for purchasing an item from{" "}
        <span className="fw-700">
          {`${order.dealer.businessName || "Dealer"}`}
        </span>
      </p>
      <p className="fw-700 text-align-center">
        {order.item.ref || "-"} - {order.item.title || "-"}
      </p>

      <div className="grey-block">
        <p className="grey-block-title">Item Information</p>

        <table>
          <tr>
            <td className="info-row-title">Name:</td>
            <td className="info-row-value">{order.billTo.name || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Date:</td>
            <td className="info-row-value">
              {order.createdAt
                ? moment(order.createdAt).format("L h:mm:ss")
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">
              {order.payment.method === "PAYPAL" ? "Paypal" : "Stripe"}{" "}
              Reference:
            </td>
            <td className="info-row-value">{order.payment.ref}</td>
          </tr>
          <tr>
            <td className="info-row-title">Cart ID:</td>
            <td className="info-row-value">{order.header.cartId || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Order No.:</td>
            <td className="info-row-value">
              {typeof order.header.orderNumber === "number"
                ? order.header.orderNumber
                : "-"}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">Item Amount:</td>
            <td className="info-row-value">
              {`${order.total.productTotal || "-"} ${selectCurrencySymbol(
                order.total.currency
              )}`}
            </td>
          </tr>
          <tr>
            <td className="info-row-title">Shipping Amount:</td>
            <td className="info-row-value">
              {`${order.total.shippingTotal || "-"} ${selectCurrencySymbol(
                order.total.currency
              )}`}
            </td>
          </tr>
        </table>
      </div>

      <div className="grey-block">
        <p className="grey-block-title">Dealer Information</p>

        <table>
          <tr>
            <td className="info-row-title">Dealer Business Name:</td>
            <td className="info-row-value">{order.dealer.businessName || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Dealer Telephone:</td>
            <td className="info-row-value">{order.dealer.phone || "-"}</td>
          </tr>
          <tr>
            <td className="info-row-title">Dealer Email:</td>
            <td className="info-row-value">{order.dealer.email || "-"}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

PaypalBuyerThankYou.propTypes = {
  order: PropTypes.object.isRequired,
};

export default PaypalBuyerThankYou;

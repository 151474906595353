import * as types from "./dealersActionsTypes";

const initialState = {
  dealers: [],
  totalNumberOfDealers: 0,
  error: null,
  loading: false,
  processing: false,
  dealersStatusChoices: {},
  dealerSpecialities: [],
  dealerSpecialitiesTotalCount: 0,
  dealerCategories: [],
  dealerCategoriesTotalCount: 0,
  dealerData: {},
  dealerAddresses: [],
  dealerAddressData: {},
  dealerInvoices: [],
  dealerNotes: [],
  dealerNotesTotalNumber: null,
  dealerStatusData: {},
  listOfSearchedDealers: [],
  listOfSearchedDealersCount: 0,
  dealerImages: {},
  dealerStockImages: [],
  dealersApiTokens: [],
  dealersApiTokensCount: 0,
  dealersTestimonialsCount: 0,
  uploadedDealerLogo: null,
  uploadedDealerPageImage: null,
  uploadedDealerEmailImage: null,
  dealerInvoicesTotalCount: 0,
  tradeAssociations: [],
  historyList: [],
  historyTotal: 0
};

const dealers = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case types.GET_DEALERS_SUCCESS:
      return {
        ...state,
        dealers: action.payload.results,
        totalNumberOfDealers: action.payload.count
      };

    case types.SEARCH_LIST_OF_DEALERS_REQUEST_SUCCESS:
      return {
        ...state,
        listOfSearchedDealers: action.payload,
        error: null,
      };

    case types.GET_DEALERS_STATUS_CHOICES_REQUEST_SUCCESS:
      return {
        ...state,
        dealersStatusChoices: action.payload,
      };

    case types.GET_DEALERS_NEWS_SUCCESS:
      return {
        ...state,
        dealersNews: action.payload.results,
        dealerNewsTotal: action.payload.count
      };

    case types.GET_DEALER_INVOICES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerInvoices: action.payload.results,
        dealerInvoicesTotalCount: action.payload.count
      };

    case types.CREATE_DEALER_NEWS_ITEM_REQUEST_SUCCESS:
    case types.CREATE_DEALER_SPECIALITY_REQUEST_SUCCESS:
    case types.PATCH_DEALER_SUCCESS:
    case types.PATCH_DEALER_ADDRESS_REQUEST_SUCCESS:
    case types.DELETE_DEALER_INVOICE_REQUEST_SUCCESS:
    case types.ADD_DEALER_NOTE_REQUEST_SUCCESS:
    case types.CREATE_DEALER_ADDRESS_REQUEST_SUCCESS:
    case types.PATCH_DEALER_STATUS_DATA_REQUEST_SUCCESS:
    case types.PATCH_DEALERS_NEWS_ITEM_SUCCESS:
    case types.PATCH_DEALER_IMAGES_REQUEST_SUCCESS:
    case types.PUT_DEALER_IMAGES_REQUEST_SUCCESS:
    case types.CREATE_DEALER_STOCK_IMAGES_REQUEST_SUCCESS:
    case types.PATCH_DEALER_STOCK_IMAGES_REQUEST_SUCCESS:
    case types.DELETE_DEALER_STOCK_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.GET_DEALER_SPECIALITIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerSpecialities: action.payload.results,
        dealerSpecialitiesTotalCount: action.payload.count
      };

    case types.GET_DEALER_CATEGORIES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerCategories: action.payload.results,
        dealerCategoriesTotalCount: action.payload.count
      };

    case types.GET_SINGLE_DEALER_REQUEST_SUCCESS:
      return {
        ...state,
        dealerData: action.payload,
        error: null,
      };

    case types.GET_DEALER_ADDRESSES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerAddresses: action.payload.results
      };

    case types.GET_DEALER_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        dealerAddressData: action.payload
      };

    case types.GET_DEALER_NOTES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerNotes: action.payload.results,
        dealerNotesTotalNumber: action.payload.count,
      };

    case types.GET_DEALER_STATUS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        dealerStatusData: action.payload
      };

    case types.GET_DEALER_IMAGES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerImages: action.payload,
        error: null,
      };

    case types.GET_DEALER_STOCK_IMAGES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerStockImages: action.payload.results,
        error: null,
      };

    case types.GET_API_KEY_REQUESTS_SUCCESS:
      return {
        ...state,
        dealersApiTokens: action.payload.results,
        dealersApiTokensCount: action.payload.count
      };

    case types.GET_DEALERS_ERROR:
    case types.GET_DEALERS_STATUS_CHOICES_REQUEST_ERROR:
    case types.DELETE_DEALER_REQUEST_ERROR:
    case types.GET_DEALERS_NEWS_ERROR:
    case types.PATCH_DEALERS_NEWS_ITEM_ERROR:
    case types.CREATE_DEALER_NEWS_ITEM_REQUEST_ERROR:
    case types.GET_DEALER_SPECIALITIES_REQUEST_ERROR:
    case types.GET_DEALER_CATEGORIES_REQUEST_ERROR:
    case types.CREATE_DEALER_SPECIALITY_REQUEST_ERROR:
    case types.GET_SINGLE_DEALER_REQUEST_ERROR:
    case types.PATCH_DEALER_ERROR:
    case types.GET_DEALER_ADDRESSES_REQUEST_ERROR:
    case types.PATCH_DEALER_ADDRESS_REQUEST_ERROR:
    case types.GET_DEALER_ADDRESS_REQUEST_ERROR:
    case types.GET_DEALER_INVOICES_REQUEST_ERROR:
    case types.DELETE_DEALER_INVOICE_REQUEST_ERROR:
    case types.GET_DEALER_NOTES_REQUEST_ERROR:
    case types.ADD_DEALER_NOTE_REQUEST_ERROR:
    case types.GET_DEALER_STATUS_DATA_REQUEST_ERROR:
    case types.CREATE_DEALER_ADDRESS_REQUEST_ERROR:
    case types.PATCH_DEALER_STATUS_DATA_REQUEST_ERROR:
    case types.SEARCH_LIST_OF_DEALERS_REQUEST_ERROR:
    case types.GET_DEALER_IMAGES_REQUEST_ERROR:
    case types.PATCH_DEALER_IMAGES_REQUEST_ERROR:
    case types.PUT_DEALER_IMAGES_REQUEST_ERROR:
    case types.GET_DEALER_STOCK_IMAGES_REQUEST_ERROR:
    case types.CREATE_DEALER_STOCK_IMAGES_REQUEST_ERROR:
    case types.PATCH_DEALER_STOCK_IMAGES_REQUEST_ERROR:
    case types.DELETE_DEALER_STOCK_IMAGE_REQUEST_ERROR:
    case types.UPLOAD_NEW_DEALER_LOGO_REQUEST_ERROR:
    case types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_ERROR:
    case types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_ERROR:
    case types.SEARCH_DEALERS_ERROR:
    case types.GET_TRADE_ASSOCIATIONS_REQUEST_ERROR:
    case types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_ERROR:
    case types.DELETE_TRADE_ASSOCIATION_REQUEST_ERROR:
    case types.CREATE_TRADE_ASSOCIATION_REQUEST_ERROR:
    case types.UPDATE_TRADE_ASSOCIATION_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: null
      };

    case types.UPLOAD_NEW_DEALER_LOGO_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        uploadedDealerLogo: action.payload.response,
      };

    case types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        uploadedDealerEmailImage: action.payload.response,
      };

    case types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        uploadedDealerPageImage: action.payload.response,
      };

    case types.SEARCH_DEALERS_SUCCESS:
      return {
        ...state,
        dealers: action.payload.results,
        totalNumberOfDealers: action.payload.count,
      }

    case types.CLEAR_DEALER_DATA_ACTION:
      return {
        ...state,
        dealerData: initialState.dealerData,
        dealerSpecialities: initialState.dealerSpecialities,
        dealersStatusChoices: initialState.dealersStatusChoices
      }

    case types.GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS:
    case types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        tradeAssociations: action.payload,
        error: null
      }

    case types.DELETE_DEALER_SPECIALITY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case types.DELETE_DEALER_SPECIALITY_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.DELETE_DEALER_SPECIALITY_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_DEALER_SPECIALITY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case types.UPDATE_DEALER_SPECIALITY_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.UPDATE_DEALER_SPECIALITY_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.SET_DEALERS_TESTIMONIALS_COUNT_ACTION:
      return {
        ...state,
        dealersTestimonialsCount: action.payload,
      };

    case types.GET_DEALER_LOGS_SUCCESS:
      return {
        ...state,
        historyList: action.payload.results,
        historyTotal: action.payload.count
      };

    default:
      return state;
  }
};

export default dealers;

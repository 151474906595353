import React from "react";
import { Checkbox, message } from "antd";
import PropTypes from "prop-types";

// react-queries
import { useDirectoryCategoryToggle } from "../../../react-query";


export const DirectoryCategoryActiveToggle = ({ item, disabled }) => {
  const updateDirectoryCategory = useDirectoryCategoryToggle({
    onError: (err) => {
      message.error(err?.data);
    }
  });

  const handleToggle = () => {
    updateDirectoryCategory.mutate({ id: item.id, isActive: !item.isActive });
  };

  return (
    <Checkbox
      className="lant-checkbox"
      checked={item.isActive}
      disabled={disabled || updateDirectoryCategory.isLoading}
      onChange={handleToggle}
    />
  )
};

DirectoryCategoryActiveToggle.propTypes = {
  item: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

import fetch from "./customFetch";

const website = {
  async getPublishRequests({ page, perPage, statusOption, dateOption, search }) {
    try {
      const url = `/dealer_site_builder/publish-request${search ? "/search" : ""}`;

      const query = new URLSearchParams();
      if (page) query.set("page", page);
      if (perPage) query.set("page_size", perPage);
      if (statusOption) query.set("status", statusOption);
      if (dateOption) query.set("ordering", dateOption);
      if (search) query.set("query", search);

      return await fetch({
        url: `${url}/?${query.toString()}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async changePublishRequestStatus({ id, data }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/publish-request/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDomainRequests({ page, perPage, statusOption, dateOption, search }) {
    try {
      const url = `/dealer_site_builder/dealers/domain-requests${search ? "/search" : ""}`;

      const query = new URLSearchParams();
      if (page) query.set("page", page);
      if (perPage) query.set("page_size", perPage);
      if (statusOption) query.set("status", statusOption);
      if (dateOption) query.set("ordering", dateOption);
      if (search) query.set("query", search);

      return await fetch({
        url: `${url}/?${query.toString()}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async changeDomainRequestStatus({ id, data }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealers/domain-requests/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerWebsites({ page, perPage, statusOption, dateOption, search }) {
    const url = `/dealer_site_builder/dealers/sites${search ? "/search" : ""}`;
    const query = new URLSearchParams();

    if (page) query.set("page", page);
    if (perPage) query.set("page_size", perPage);
    if (statusOption) query.set("is_published", statusOption);
    if (dateOption) query.set("ordering", dateOption);
    if (search) query.set("query", search);

    try {
      return await fetch({
        url: `${url}/?${query.toString()}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async changeDealerWebsiteStatus({ id, data }) {
    try {
      return await fetch({
        url: `/dealer_site_builder/dealers/sites/${id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default website;

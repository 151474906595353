import React from "react";
import { useHistory, useLocation } from "react-router-dom";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { InlineIcon } from "../../../icons";

// react-queries
import { useTradeSubscriptionCancelRequestList } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";
import { buildFullName } from "../../../helpers";


export const DirectorySubscriptionCancelRequests = () => {
  const location = useLocation();
  const history = useHistory();

  const { page, perPage } = navigation.getTableQueryParams(location);

  const { data } = useTradeSubscriptionCancelRequestList({
    page: page,
    page_size: perPage,
    status: "SUBMITTED"
  });

  const redirectToEdit = (item) => {
    const isFair = !!item.fair?.id;

    history.push({
      pathname: isFair
        ? `/directory/fairs/${item.fair?.id}/subscription`
        : `/directory/directory-entries/${item.directoryEntry?.id}/subscription`,
      state: { path: isFair ? item.fair?.name : item.directoryEntry?.name }
    });
  };

  return (
    <div className="directory-subscription-cancel-requests">
      <Table
        className="directory-subscription-cancel-requests--table"
        columnWidths={["70px", "180px", "180px", "auto", "120px", "70px"]}
        headerCells={[
          { label: "STATUS" },
          { label: "NAME" },
          { label: "USER EMAIL" },
          { label: "MESSAGE" },
          { label: "PLAN" },
          { label: "ACTIONS" },
        ]}
      >
        {data?.results?.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex">
                {item.status !== "SUBMITTED" ? (
                  <div className="dealers-list--status-column--index-active" style={{ margin: 0 }}>Approved</div>
                ) : (
                  <div className="dealers-list--status-column--active-value-is-pending" style={{ margin: 0 }}>Submitted</div>
                )}
              </div>
              <div className="flex">
                {item.directoryEntry?.name || item.directoryEntry?.owner?.businessName || buildFullName(item.subscription?.user) || ""}
              </div>
              <div className="flex">
                {item.subscription?.user?.email || "-"}
              </div>
              <div className="flex">
                {item.message}
              </div>
              <div className="flex">
                {item.subscription?.plan?.name || "-"}
              </div>
              <div className="flex">
                <InlineIcon type="edit" className="cursor-pointer" onClick={() => redirectToEdit(item)} />
              </div>
            </TableRow>
          )
        })}
      </Table>

      {data?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={data.count}
        />
      )}
    </div>
  )
};

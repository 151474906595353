import * as types from "./analyticsActionTypes";


export const getEnquiriesAnalyticsRequest = (payload) => {
  return { type: types.GET_ENQUIRIES_ANALYTICS_REQUEST, payload };
};

export const getEnquiriesAnalyticsRequestError = (payload) => {
  return { type: types.GET_ENQUIRIES_ANALYTICS_ERROR, payload };
};

export const getEnquiriesAnalyticsRequestSuccess = (payload) => {
  return { type: types.GET_ENQUIRIES_ANALYTICS_SUCCESS, payload };
};

export const getItemsAnalyticsRequest = (payload) => {
  return { type: types.GET_ITEMS_ANALYTICS_REQUEST, payload };
};

export const getItemsAnalyticsRequestError = (payload) => {
  return { type: types.GET_ITEMS_ANALYTICS_ERROR, payload };
};

export const getItemsAnalyticsRequestSuccess = (payload) => {
  return { type: types.GET_ITEMS_ANALYTICS_SUCCESS, payload };
};

export const getDealerAnalyticsRequest = (payload) => ({
  type: types.GET_DEALER_ANALYTICS_REQUEST,
  payload
});

export const getDealerAnalyticsRequestError = (payload) => ({
  type: types.GET_DEALER_ANALYTICS_REQUEST_ERROR,
  payload
});

export const getDealerAnalyticsRequestSuccess = (payload) => ({
  type: types.GET_DEALER_ANALYTICS_REQUEST_SUCCESS,
  payload
});

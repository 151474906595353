import React from "react";

export const renderAdminLog = (item = {}) => {
  const { updatedFields = {}, entity = "" } = item;

  switch (true) {
    case !updatedFields:
      return entity === "FAIR_EVENT" ? (
        <span>Created(or deleted) <b>FAIR EVENT</b> with ID </span>
      ) : (
        <span>Created <b>{entity}</b></span>
      );
    case !!updatedFields.status:
      return (
        <span>
          Changed status from <b>{updatedFields?.status?.old}</b> to <b>{updatedFields?.status?.new}</b>
        </span>
      );
    case !!updatedFields.isManuallyUpgraded:
      return (
        <span>
          {updatedFields.isManuallyUpgraded?.new ? "Enabled" : "Disabled"} <b>PREMIUM</b> manually
        </span>
      );
    default:
      return (
        <span>
          {`Updated ${entity === "FAIR_EVENT" ? "Fair event " : ""}fields:`} <b>{Object.keys(updatedFields).join(", ")}</b>
        </span>
      );
  }
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Row, Radio } from "antd";
import { Bar } from "react-chartjs-2";
import moment from "moment";

// components
import StatusBlock from "./StatusBlock";

// helpers
import formatPrice from "../../../helpers/formatPrice";
import { getInvoicesOverviewRequest } from "../../../redux/invoices/overview/overviewActions";
import { Utils } from "../../../helpers";


function Overview({ getInvoicesOverview, data }) {
  const [period, setPeriod] = useState(6);

  useEffect(() => {
    getInvoicesOverview(6);
  }, []); // eslint-disable-line

  const onOverviewChange = event => {
    setPeriod(event.target.value);
    getInvoicesOverview(event.target.value);
  };

  const getWeekLabels = () => {
    const today = moment().format("ddd");
    const weekLabels = [today];

    for (let i = 1; i < 8; i++) {
      const nextDay = moment().add(i, "days").format("ddd");

      weekLabels.push(nextDay);
    }

    return weekLabels;
  };

  const {
    status: {
      activeDealersEnabledForInvoicing,
      inactiveDealersEnabledForInvoicing
    },
    activeInvoices: {
      last12Months,
      last24Months,
      numberOfActiveDealers
    },
    lastMonth: {
      totalNet: {
        total: lastMonthsTotalNetTotal,
        percentage: lastMonthsTotalNetPercentage
      },
      totalGross: {
        total: lastMonthsTotalGrossTotal,
        percentage: lastMonthsTotalGrossPercentage
      }
    },
    thisMonth: {
      totalNet: {
        total: thisMonthTotalNetTotal,
        percentage: thisMonthTotalNetPercentage
      },
      totalGross: {
        total: thisMonthTotalGrossTotal,
        percentage: thisMonthTotalGrossPercentage
      }
    },
    tableOverview
  } = data;

  const dataOverview = {
    labels: period === "week" ?
      getWeekLabels() :
      [].concat(...tableOverview.map(e => Object.keys(e))),
    datasets: [
      {
        label: "Number of dealers enabled for invoicing",
        backgroundColor: "rgba(255,98,132,0.5)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.7)",
        hoverBorderColor: "rgba(255,98,132,1)",
        data: tableOverview.map(e => Object.values(e)[0].dealers)
      }, {
        label: "Net amount invoiced",
        backgroundColor: "rgba(55,162,235,0.5)",
        borderColor: "rgba(55,162,235,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(55,162,235,0.7)",
        hoverBorderColor: "rgba(55,162,235,1)",
        data: tableOverview.map(e => Object.values(e)[0].netAmountInvoiced)
      }, {
        label: "Gross amount invoiced",
        backgroundColor: "rgba(255,205,86,0.5)",
        borderColor: "rgba(255,205,86,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,205,86,0.7)",
        hoverBorderColor: "rgba(255,205,86,1)",
        data: tableOverview.map(e => Object.values(e)[0].grossAmountInvoiced)
      }
    ]
  };

  const barChartOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{ ticks: { beginAtZero: true } }]
    }
  };


  return (
    <div className="component overview">
      <Row gutter={30}>
        <Col span={12}>
          <div className="overview--block">
            <div className="overview--block-header">
              <p className="overview--block-title">Status</p>
            </div>
            <Row gutter={32}>
              <Col span={12} className="flex">
                <StatusBlock
                  number={Utils.emptyFieldFormat(activeDealersEnabledForInvoicing, "-")}
                  text="Active Dealers enabled for Invoicing"
                />
              </Col>
              <Col span={12} className="flex">
                <StatusBlock
                  number={Utils.emptyFieldFormat(inactiveDealersEnabledForInvoicing, "-")}
                  text="Inactive Dealers enabled for Invoicing"
                />
              </Col>
            </Row>
          </div>
          <div className="overview--block">
            <div className="overview--block-header">
              <p className="overview--block-title">Active Invoices</p>
            </div>
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(formatPrice(last12Months), "-")}`}
                  text="Last 12 month (NET)"
                />
              </Col>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(formatPrice(last24Months), "-")}`}
                  text="Last 24 month (NET)"
                />
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <StatusBlock
                  number={Utils.emptyFieldFormat(numberOfActiveDealers, "-")}
                  text="Number of active dealers"
                />
              </Col>
            </Row>
          </div>
          <div className="overview--block">
            <Row gutter={32}>
              <Col span={12}>
                <div className="overview--block-header">
                  <p className="overview--block-title">
                    Last Month : { moment().subtract(1, "months").format("MMM YYYY") }
                  </p>
                </div>
              </Col>
              <Col span={12}>
                <div className="overview--block-header">
                  <p className="overview--block-title">This Month : { moment().format("MMM YYYY") }</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(formatPrice(lastMonthsTotalNetTotal), "-")}`}
                  text="Total (NET)"
                  percentage={lastMonthsTotalNetPercentage}
                />
              </Col>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(formatPrice(thisMonthTotalNetTotal), "-")}`}
                  text="Total Charge (NET)"
                  percentage={thisMonthTotalNetPercentage}
                />
              </Col>
            </Row>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(formatPrice(lastMonthsTotalGrossTotal), "-")}`}
                  text="Total (GROSS)"
                  percentage={lastMonthsTotalGrossPercentage}
                />
              </Col>
              <Col span={12}>
                <StatusBlock
                  number={`£${Utils.emptyFieldFormat(thisMonthTotalGrossTotal)}`}
                  text="Total (GROSS)"
                  percentage={thisMonthTotalGrossPercentage}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className="overview--block">
            <div className="overview--block-header">
              <p className="overview--block-title">Overview</p>
              <Radio.Group name="radiogroup" defaultValue={6} onChange={onOverviewChange}>
                <Radio value="week">Week</Radio>
                <Radio value={6}>6 month</Radio>
                <Radio value={12}>12 month</Radio>
                <Radio value={24}>24 month</Radio>
                <Radio value="all">All time</Radio>
              </Radio.Group>
            </div>

            <div className="overview--block-content">
              <Bar
                data={dataOverview}
                height={330}
                options={barChartOptions}
                legend={{ position: "bottom" }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

Overview.propTypes = {
  getInvoicesOverview: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default connect(
  state => state.overview,
  dispatch => ({
    getInvoicesOverview: payload => dispatch(getInvoicesOverviewRequest(payload))
  })
)(Overview);

import React, { useEffect, useState } from "react";
import { Button } from "antd";
import moment from "moment";

//helpers
import auth from "../../../api/auth";

function Pinterest() {
  const [refreshTokenExpiration, setRefreshTokenExpiration] = useState("");
  const daysLeft = moment(refreshTokenExpiration).diff(moment(), "days");

  useEffect(() => {
    auth.getPinterestTokenInfo()
      .then(resp => setRefreshTokenExpiration(resp.refreshTokenExpiration))
      .catch(() => setRefreshTokenExpiration(""));
  }, []); // eslint-disable-line


  return (
    <div className="component">
      <p className="general-form--title">Pinterest Integration</p>
      <div className="general-form--container mt-20">
        <div className="pinterest__main-info">
          <p className={daysLeft <= 3 ? "red-color" : ""}>The token will expire in: { refreshTokenExpiration ? daysLeft : "-" } days</p>
          <p>Expiration Date: <span>{ refreshTokenExpiration ? moment(refreshTokenExpiration).format("DD MMM YYYY") : "-" }</span></p>
        </div>
        {/*//todo remove hardcoded link*/}
        <Button className="modal-decline__red-btn" href={process.env.REACT_APP_PINTEREST_REDIRECT || "https://www.pinterest.com/oauth/?consumer_id=1474338&redirect_uri=https://admin-staging.la-test.me.uk/settings/pinterest-token&response_type=code&refreshable=True&scope=user_accounts:read,pins:read,boards:read,pins:read_secret,pins:write,board:write"} target="_blank">
          { daysLeft <= 3 ? "Refresh Pinterest Token" : "Get Pinterest Token" }
        </Button>
      </div>
    </div>
  );
}

export default Pinterest;

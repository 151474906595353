import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./usersActionsTypes";
import userManagementApi from "../../../api/userManagement";
import { message } from "antd";

function *getUsersList(action) {
  try {
    yield put({ type: types.SHOW_USERS_LOADING });
    let response = {};
    if (action.payload.search) {
      response = yield call(userManagementApi.usersSearch, action.payload);
    } else {
      response = yield call(userManagementApi.getUsers, action.payload);
    }

    yield put({ type: types.GET_USERS_LIST_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_USERS_LIST_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_USERS_LOADING });
  }
}

function *patchUser(action) {
  try {
    const { id, data, showProcessing = true } = action.payload;

    if (showProcessing) {
      yield put({ type: types.SHOW_USERS_PROCESSING });
    }
    yield put({ type: types.RESET_ERRORS });

    yield call(userManagementApi.patchUser, { id, data });
    yield put({ type: types.GET_USERS_LIST_REQUEST, payload: action.payload.params });
  } catch (error) {
    yield put({ type: types.PATCH_USER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_USERS_PROCESSING });
  }
}

function *deleteUser(action) {
  yield put({ type: types.SHOW_USERS_PROCESSING });
  yield put({ type: types.RESET_ERRORS });

  try {
    const { id, ...rest } = action.payload;
    yield call(userManagementApi.deleteUser, id);
    yield put({ type: types.GET_USERS_LIST_REQUEST, payload: rest });

  } catch (error) {
    yield put({ type: types.DELETE_USER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_USERS_PROCESSING });
  }
}

function *convertUserRole(action) {
  try {
    const { id, data, ...rest } = action.payload;
    yield call(userManagementApi.convertUserRole, id, data);
    yield put({ type: types.CONVERT_USER_ROLE_SUCCESS });
    yield put({ type: types.GET_USERS_LIST_REQUEST, payload: rest });

    if (data?.role === "DEALER") {
      message.warning("Note that the business name was set automatically");
    } else {
      message.success("Your changes have been saved");
    }
  } catch (error) {
    yield put({ type: types.CONVERT_USER_ROLE_FAILED, payload: error });
  }
}

function* getSubscribersList(action) {
  try {
    yield put({ type: types.SHOW_USERS_LOADING });
    const response = yield call(userManagementApi.getSubscribers, action.payload);

    yield put({ type: types.GET_SUBSCRIBERS_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_SUBSCRIBERS_LIST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_USERS_LOADING });
  }
}

function* deleteAnonymousSubscriber(action) {
  yield put({ type: types.SHOW_USERS_PROCESSING });
  yield put({ type: types.RESET_ERRORS });

  try {
    const { id, ...rest } = action.payload;
    yield call(userManagementApi.deleteAnonymousSubscriber, id);
    yield put({ type: types.GET_SUBSCRIBERS_LIST_REQUEST, payload: rest });

  } catch (error) {
    yield put({ type: types.DELETE_USER_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_USERS_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_USERS_LIST_REQUEST, getUsersList),
    yield takeLatest(types.PATCH_USER_REQUEST, patchUser),
    yield takeLatest(types.DELETE_USER_REQUEST, deleteUser),
    yield takeLatest(types.CONVERT_USER_ROLE_REQUEST, convertUserRole),
    yield takeLatest(types.GET_SUBSCRIBERS_LIST_REQUEST, getSubscribersList),
    yield takeLatest(types.DELETE_ANONYMOUS_SUBSCRIBER_REQUEST, deleteAnonymousSubscriber)
  ])
}

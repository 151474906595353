import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { connect } from "react-redux";

import { Utils } from "../../../../helpers";
import {
  getBlogCategoriesRequest,
  updateBlogCategoryStatusRequest
} from "../../../../redux/content/blog/blogActions";

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import navigation from "../../../../helpers/navigation";


function BlogCategories({
  location,
  history,
  categories,
  getBlogCategories,
  categoriesTotal,
  updateBlogCategoryStatus
}) {
  const { order, page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getBlogCategories({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const onCheck = (e, category) => {
    category = Object.assign(category, { isVisible: e.target.checked });
    // will be improved on backend soon: field children have to be replaced with parent field
    delete category.children;
    updateBlogCategoryStatus({ category, page, perPage });
  };

  const categoriesToRender = Utils.filterAndOrder(categories, { order });

  return (
    <div className="blog-categories-wrapper">
      <div className="blog--add-button">
        <AddButton
          onClick={() => history.push("/content/blog/categories/add-new-category")}
        />
      </div>

      <Table
        className="article--table"
        columnWidths={["40px", "15%", "15%", "auto"]}
        headerCells={[
          {},
          { label: "NAME" },
          { label: "URL" },
          { label: "DESCRIPTION" }
        ]}
      >
        {
          categoriesToRender.map(category => {

            return (
              <TableRow key={`categories-${category.id}`}>
                <Checkbox
                  checked={category.isVisible}
                  onChange={e => onCheck(e, category)}
                />

                <div className="flex categories--row-block artisans--row-block__name">
                  <span>{category.name}</span>
                </div>
                <div className="flex categories--row-block__url">{category.url}</div>

                <div className="flex categories--row-block__description">{category.description}</div>
              </TableRow>
            )
          })
        }

        {
          categoriesTotal > 0 && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={categoriesTotal}
            />
          )
        }
      </Table>
    </div>
  )
}

BlogCategories.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  categoriesTotal: PropTypes.number.isRequired,
  getBlogCategories: PropTypes.func.isRequired,
  updateBlogCategoryStatus: PropTypes.func.isRequired,
};

export default connect(
  state => state.blogs,
  dispatch => ({
    getBlogCategories: payload => dispatch(getBlogCategoriesRequest(payload)),
    updateBlogCategoryStatus: payload => dispatch(updateBlogCategoryStatusRequest(payload)),
  })
)(BlogCategories);

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";


function QuickLink({ text, icon: Icon, href }) {
  return (
    <Link className="dashboard--quick-link-block" to={href || ""}>
      { Icon && <Icon fillColor="#FF0000" /> }
      <span className="dashboard--quick-link-text">{text}</span>
    </Link>
  )
}

QuickLink.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  href: PropTypes.string,
};

export default QuickLink;

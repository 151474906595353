import React from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Popconfirm, Radio, Row } from "antd";
import { useQueryClient } from "react-query";
import moment from "moment";
import PropTypes from "prop-types";

// components
import { StatusCardField, StatusColumn } from "../../../../components";
import TableRow from "../../../shared/Table/TableRow";
import Table from "../../../shared/Table/Table";

// react-queries
import {
  useDirectoryEntryPatch, useFairPatch,
  useTradeSubscriptionCancelRequestApprove,
  useTradeSubscriptionCancelRequestDelete
} from "../../../../react-query";

// helpers
import { DIRECTORY_CANCEL_SUBSCRIPTION_STATUSES, DIRECTORY_SUBSCRIPTION_STATUSES } from "../../../../constants";


export const DirectoryEntrySubscription = ({ data, isFair, isLoading }) => {
  const { id } = useParams();

  const subscription = data?.subscription || {};
  const isManuallyUpgraded = data?.isManuallyUpgraded || false;

  const queryClient = useQueryClient();

  const approveRequest = useTradeSubscriptionCancelRequestApprove();

  const deleteRequest = useTradeSubscriptionCancelRequestDelete();

  const patchDirectoryEntry = useDirectoryEntryPatch({
    onSuccess: (resp) => {
      queryClient.setQueryData(["directory-entry", Number(id)], resp);
    }
  });

  const patchFair = useFairPatch({
    onSuccess: (resp) => {
      queryClient.setQueryData(["fair", Number(id)], resp);
    }
  });

  if (isLoading) {
    return null;
  }

  const changeManualPremium = (evt) => {
    if (isFair) {
      patchFair.mutate({ id: Number(id), isManuallyUpgraded: evt.target.value });
    } else {
      patchDirectoryEntry.mutate({ id: Number(id), isManuallyUpgraded: evt.target.value });
    }
  }

  return (
    <Row className="pt-16" gutter={24}>
      <Col xl={12} xs={24} style={{ maxWidth: "540px" }}>
        <div className="status-card mb-32">
          <div className="status-card--title">Subscription Status</div>
          <div className="status-card--content">
            <StatusCardField title="Status">
              <StatusColumn
                currentStatus={subscription.isActive ? "ACTIVE" : "INACTIVE"}
                statuses={DIRECTORY_SUBSCRIPTION_STATUSES}
                direction="horizontal"
              />
            </StatusCardField>
            {subscription.isActive ? (
              <>
                <StatusCardField title="Start Date">
                  {subscription.startDate && moment(subscription.startDate).format("MMMM D, Y HH:MM")}
                </StatusCardField>
                {!!(subscription.cancelAtPeriodEnd && subscription.canceledAt && subscription.currentPeriodEnd) && (
                  <StatusCardField title="Billing Period End">
                    {moment(subscription.currentPeriodEnd).format("MMMM D, Y HH:MM")}
                  </StatusCardField>
                )}
                {subscription.plan?.id ? (
                  <StatusCardField title="Plan">
                    <div>{subscription.plan.name}</div>
                    <div>{`£ ${subscription.plan.price}`}</div>
                  </StatusCardField>
                ) : (
                  <StatusCardField title="Plan">
                    <div>-</div>
                  </StatusCardField>
                )}
              </>
            ) : !!subscription.canceledAt && (
              <StatusCardField title="Cancelled At">
                {moment(subscription.canceledAt).format("MMMM D, Y HH:MM")}
              </StatusCardField>
            )}
          </div>
        </div>
        {subscription.cancelRequests?.filter(({ status }) => status === "SUBMITTED")?.map((el) => (
          <div key={el.id} className="status-card mb-32">
            <div className="status-card--title">Cancel Subscription Request</div>
            <div className="status-card--content">
              <StatusCardField title="Status">
                <StatusColumn
                  currentStatus={el.status}
                  statuses={DIRECTORY_CANCEL_SUBSCRIPTION_STATUSES}
                  direction="horizontal"
                />
              </StatusCardField>
              <StatusCardField title="Reason">
                {el.message}
              </StatusCardField>
              <StatusCardField title="Submitted At">
                {el.createdAt ? moment(el.createdAt).format("MMMM D, Y HH:MM") : "-"}
              </StatusCardField>
              <div className="flex justify-between pt-24">
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure you want to delete this request?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteRequest.mutate({
                      id: el.id,
                      directoryEntryId: id
                    });
                  }}
                >
                  <Button
                    loading={deleteRequest.isLoading}
                    disabled={deleteRequest.isLoading}
                  >
                    Delete request
                  </Button>
                </Popconfirm>
                {el.status === "SUBMITTED" && (
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure you want to cancel this subscription?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      approveRequest.mutate({
                        id: el.id,
                        directoryEntryId: id
                      })
                    }}
                  >
                    <Button
                      type="primary"
                      loading={approveRequest.isLoading}
                      disabled={approveRequest.isLoading}
                    >
                      Cancel subscription
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="dealer-details-tab--approved-listing">
          <div className="dealer-details-tab--approved-listing--title">
            Manual Premium
          </div>
          <div className="dealer-details-tab--approved-listing--content">
            <div className="dealer-details-tab--approved-listing--content--pay-pal-status">
              <Radio.Group
                value={!subscription.isActive && isManuallyUpgraded}
                disabled={isLoading || !!(subscription.isActive && subscription.plan)}
                onChange={changeManualPremium}
              >
                <Radio value={true}>Enabled</Radio>
                <Radio value={false}>Disabled</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Col>
      <Col xl={12} xs={24} style={{ maxWidth: "540px" }}>
        <div className="status-card">
          <div className="status-card--title">Invoices</div>
          <div className="pt-8">
            <Table
              className="directory-plans--table"
              columnWidths={["160px", "70px", "auto"]}
              headerCells={[
                { label: "DATE" },
                { label: "AMOUNT" },
                { label: "INVOICE ID" },
              ]}
            >
              {subscription.invoices?.map((item) => {
                return (
                  <TableRow key={item.invoiceNumber} style={{ fontSize: "12px" }}>
                    <div className="flex">
                      {moment(item.createdAt).format("DD MMM, YYYY HH:MM")}
                    </div>
                    <div className="flex">
                      {`£ ${item.amount}`}
                    </div>
                    <div className="flex">
                      <a href={String(item?.url)} target="_blank" rel="noopener noreferrer">{item?.invoiceNumber}</a>
                    </div>
                  </TableRow>
                )
              })}
            </Table>
          </div>
        </div>
      </Col>
    </Row>
  );
};

DirectoryEntrySubscription.propTypes = {
  data: PropTypes.object,
  isFair: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

export const SHOW_LOADING = "SHOW_CURRENCY_RATES_LOADING";
export const HIDE_LOADING = "HIDE_CURRENCY_RATES_GROUPS_LOADING";
export const TOGGLE_PROCESSING = "TOGGLE_CURRENCY_RATES_PROCESSING";
export const RESET_ERROR = "RESET_CURRENCY_RATES_ERROR";

export const GET_CURRENCY_RATES_REQUEST = "GET_CURRENCY_RATES_REQUEST";
export const GET_CURRENCY_RATES_REQUEST_SUCCESS = "GET_CURRENCY_RATES_REQUEST_SUCCESS";
export const GET_CURRENCY_RATES_REQUEST_ERROR = "GET_CURRENCY_RATES_REQUEST_ERROR";

export const UPDATE_CURRENCY_RATES_REQUEST = "UPDATE_CURRENCY_RATES_REQUEST";

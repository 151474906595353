import * as types from "./helpTextActionsTypes";


const initialState = {
  helpTextItems: [],
  totalNumberOfHelpTextItems: 0,
  error: null,
  processing: false,
  loading: false
};


const helpTextItems = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        helpTextItems: action.payload.results,
        totalNumberOfHelpTextItems: action.payload.count
      };
    
    case types.GET_HELP_TEXT_CHOICES_REQUEST_SUCCESS:
      return {
        ...state,
        iconChoices: action.payload.helpIcon,
        helpTypeChoices: action.payload.helpType,
      };

    case types.TOGGLE_CONTENT_HELP_TEXT_PROCESSING:
      return {
        ...state,
        processing: action.payload
      }

    case types.PUT_HELP_TEXT_ITEM_REQUEST_SUCCESS:
    case types.PATCH_HELP_TEXT_ITEM_REQUEST_SUCCESS:
    case types.CREATE_HELP_TEXT_ITEM_REQUEST_SUCCESS:
    case types.DELETE_HELP_TEXT_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };
  
    case types.GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_ERROR:
    case types.PUT_HELP_TEXT_ITEM_REQUEST_ERROR:
    case types.DELETE_HELP_TEXT_ITEM_REQUEST_ERROR:
    case types.GET_HELP_TEXT_CHOICES_REQUEST_ERROR:
    case types.CREATE_HELP_TEXT_ITEM_REQUEST_ERROR:
    case types.PATCH_HELP_TEXT_ITEM_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.LOADING:
      return {
        ...state,
        loading: action.payload
      }

    default:
      return state;
  }
};

export default helpTextItems;

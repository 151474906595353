import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Select } from "antd";
import lodash from "lodash";

import { getArtisansTagsRequest } from "../../../../redux/artisans/artisansActions";


function AddEditArtisansTagsField({ tags, setTags, artisanTags, getArtisansTags }) {
  const tagsToRender = tags.map(tag => ({ value: tag.id, label: tag.name }));

  useEffect(() => {
    getArtisansTags();
  }, []); // eslint-disable-line

  const onChange = selectedTags => {
    const newTags = selectedTags
      .map(selectedTag => lodash.find(artisanTags, { id: selectedTag.value }))
      .filter(el => el);

    setTags(newTags);
  };


  return (
    <div className="labeled-input">
      <span className="labeled-input--label">Tags</span>

      <Select
        mode="multiple"
        className="add-catalog-entity--select labeled-input--input artisan-tag-selector"
        labelInValue
        options={artisanTags.map((el) => ({ value: el.id, label: el.name }))}
        value={tagsToRender}
        onChange={onChange}
      />
    </div>
  )
}

const ArtisanTagPropTypes = PropTypes.shape({
  createdAt: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
});

AddEditArtisansTagsField.propTypes = {
  tags: PropTypes.arrayOf(ArtisanTagPropTypes).isRequired,
  setTags: PropTypes.func.isRequired,
  artisanTags: PropTypes.arrayOf(ArtisanTagPropTypes).isRequired,
  getArtisansTags: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    artisanTags: state.artisans.tags
  }),
  dispatch => ({
    getArtisansTags: () => dispatch(getArtisansTagsRequest()),
  })
)(AddEditArtisansTagsField);

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

/* TODO(BE): need endpoint GET /profiles/trade-profiles/:id/ */
const fetcher = async (id) => {
  const resp = await fetch({ url: `/profiles/trade-profiles/?page=1&page_size=200`, method: "GET" });

  return resp.results?.find((el) => el.id === Number(id));
  // return fetch({ url: `/directory/trade-profiles/${id}/`, method: "GET" });
};

export const useTradeProfile = (id, options) => {
  return useQuery(["trade-profile", id], () => fetcher(id), options);
};

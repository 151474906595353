import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./ordersActionsTypes";
import ordersAPI from "../../api/orders";


function *getOrders (action) {
  try {
    const response = yield call(ordersAPI.getOrders, action.payload);
    yield put({ type: types.GET_ORDERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ORDERS_REQUEST_ERROR, payload: error.message });
  }
}

function *getOrdersChoices (action) {
  try {
    const response = yield call(ordersAPI.getOrdersChoices, action.payload);
    yield put({ type: types.GET_ORDERS_CHOICES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ORDERS_CHOICES_ERROR, payload: error.message });
  }
}

function *getDealersList (action) {
  try {
    const response = yield call(ordersAPI.getDealersList, action.payload);
    yield put({ type: types.GET_DEALERS_LIST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALERS_LIST_ERROR, payload: error.message });
  }
}

function *searchOrders (action) {
  try {
    yield put({ type: types.TOGGLE_SEARCH_PROCESSING });
    const response = yield call(ordersAPI.searchOrders, action.payload);
    yield put({ type: types.SEARCH_ORDERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SEARCH_ORDERS_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_SEARCH_PROCESSING });
  }
}

function *patchOrder (action) {
  try {
    const { data, page, perPage } = action.payload;
    yield call(ordersAPI.patchOrder, data);
    yield put({ type: types.PATCH_ORDER_REQUEST_SUCCESS });
    yield put({ type: types.GET_ORDERS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_ORDER_REQUEST_ERROR, payload: error.message });
  }
}

function *getFilteredOrders (action) {
  try {
    const response = yield call(ordersAPI.getFilteredOrders, action.payload);
    yield put({ type: types.GET_FILTERED_ORDERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_FILTERED_ORDERS_REQUEST_ERROR, payload: error.message });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_ORDERS_REQUEST, getOrders),
    yield takeLatest(types.GET_ORDERS_CHOICES_REQUEST, getOrdersChoices),
    yield takeLatest(types.GET_DEALERS_LIST_REQUEST, getDealersList),
    yield takeLatest(types.SEARCH_ORDERS_REQUEST, searchOrders),
    yield takeLatest(types.PATCH_ORDER_REQUEST, patchOrder),
    yield takeLatest(types.GET_FILTERED_ORDERS_REQUEST, getFilteredOrders),
  ])
}

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = async () => {
  const resp = await fetch({
    url: "/dealers/categories/",
    method: "GET",
  });

  return resp.results;
};

export const useDealerCategories = (options) => {
  return useQuery(["dealer-category"], fetcher, options);
};

import { all, put, call, takeLatest } from "redux-saga/effects";

import enquiriesApi from "../../../api/enquiries";
import * as types from "./blacklistsEnquiriesActionsTypes";
import navigation from "../../../helpers/navigation";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    let response = {};
    if (params.search) {
      response = yield call(enquiriesApi.searchBlacklists, params);
    } else {
      response = yield call(enquiriesApi.getBlacklists, params);
    }

    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *create({ data }) {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(enquiriesApi.createBlacklists, data);

    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}


export function *update({ id, data, isPatch }) {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) yield call(enquiriesApi.patchBlacklists, id, data);
    else yield call(enquiriesApi.putBlacklists, id, data);

    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *remove({ id }) {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(enquiriesApi.deleteBlacklists, id);

    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST, params: { page, perPage, search } });
  } catch (error) {
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function *getChoices () {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(enquiriesApi.getBlacklistsChoices);
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

function *getSpamList(action) {
  try {
    const response = yield call(enquiriesApi.getSpamList, action.payload);
    yield put({ type: types.GET_SPAM_LIST_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_SPAM_LIST_REQUEST_ERROR, payload: error.message });
  }
}

export function *deleteEnquiryFromBlacklist({ id, params }) {

  yield put({ type: types.SHOW_PROCESSING });

  try {
    yield call(enquiriesApi.deleteEnquiryFromBlacklist, id);

    yield put({ type: types.GET_BLACKLISTS_ENQUIRIES_REQUEST, params });
  } catch (error) {
    yield put({ type: types.DELETE_ENQUIRY_FROM_BLACKLIST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_BLACKLISTS_ENQUIRIES_REQUEST, get),
    yield takeLatest(types.CREATE_BLACKLISTS_ENQUIRIES_REQUEST, create),
    yield takeLatest(types.UPDATE_BLACKLISTS_ENQUIRIES_REQUEST, update),
    yield takeLatest(types.DELETE_BLACKLISTS_ENQUIRIES_REQUEST, remove),
    yield takeLatest(types.GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST, getChoices),
    yield takeLatest(types.GET_SPAM_LIST_REQUEST, getSpamList),
    yield takeLatest(types.DELETE_ENQUIRY_FROM_BLACKLIST, deleteEnquiryFromBlacklist),
  ])
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import moment from "moment";
import { Select, Modal as ModalWindow, Button, message } from "antd";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import Table from "../../../../shared/Table/Table";
import TableRow from "../../../../shared/Table/TableRow";
import TableFooter from "../../../../shared/Table/TableFooter";
import DeleteIcon from "../../../../Icon/DeleteIcon";
import DownloadIcon from "../../../../Icon/DownloadIcon";

import navigation from "../../../../../helpers/navigation";

import {
  getDealerInvoicesRequest,
  deleteDealerInvoiceRequest
} from "../../../../../redux/dealers/dealersActions";
import invoicesAPI from "../../../../../api/invoices";


const InvoicesTab = ({
  location,
  match,
  getDealerInvoices,
  dealerInvoices,
  dealerInvoicesTotalCount,
  deleteDealerInvoice
}) => {
  const dealerUrl = match.params.url;
  const { page, perPage } = navigation.getTableQueryParams(location);

  const [sortByStatus, setSortByStatus] = useState("All");
  const [sortByDate, setSortByDate] = useState("All Time");
  const [dateRange, setDateRange] = useState(null);
  const [dateTitle, setDateTitle] = useState("Custom");
  const [focusedInput, setFocusedInput] = useState();
  const [dateFilter, setDateFilter] = useState(["", ""]);
  const [openDeleteInvoiceModal, setOpenDeleteInvoiceModal] = useState(false);
  const [invoiceNumberToBeDeleted, setInvoiceNumberToBeDeleted] = useState(false);

  // Helpers

  const calculateLast14Days = () => {
    const now = moment().format("YYYY-MM-DD");
    const last14Days = moment(now).subtract(14, "d").format("YYYY-MM-DD");
    setDateFilter([last14Days, now]);
  }

  const calculateLast30Days = () => {
    const now = moment().format("YYYY-MM-DD");
    const last30Days = moment(now).subtract(30, "d").format("YYYY-MM-DD");
    setDateFilter([last30Days, now]);
  }

  const calculateLastMonth = () => {
    const now = moment().format("YYYY-MM-DD");
    const startOfMonth = moment(now).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(now).endOf("month").format("YYYY-MM-DD");
    setDateFilter([startOfMonth, endOfMonth]);
  }

  // Effects

  useEffect(() => {
    const params = {
      page,
      perPage,
      dealerUrl,
    };
    if (sortByStatus && sortByStatus !== "All") params.status = sortByStatus;
    if (dateFilter) {
      params.fromDate = dateFilter[0];
      params.toDate = dateFilter[1];
    }

    getDealerInvoices(params);
  }, [page, perPage, sortByStatus, dateFilter]); // eslint-disable-line

  useEffect(() => {
    if (dateRange) {
      const startD = moment(dateRange[0]).format("L");
      const endD = moment(dateRange[1]).format("L");
      const title = `${startD} - ${endD}`
      setDateTitle(title);
      setDateFilter([startD, endD]);

      if (startD === "Invalid date" || endD === "Invalid date") setDateFilter([]);
    }
    if (dateRange && dateRange.includes(null)) setDateTitle("Custom")
  }, [dateRange]); // eslint-disable-line

  // Methods

  const handleDeleteIconClick = (invoiceNumber) => {
    setOpenDeleteInvoiceModal(true);
    setInvoiceNumberToBeDeleted(invoiceNumber);
  }

  const _deleteDealerInvoice = () => {
    deleteDealerInvoice({ url: dealerUrl, id: invoiceNumberToBeDeleted });
    setOpenDeleteInvoiceModal(false);
  }

  const downloadInvoice = (id, number) => {
    invoicesAPI.getInvoicePDF(id)
      .then(data => saveAs(data, `Invoice_${number}.pdf`))
      .catch((e) => message.error(e.data || "Something went wrong!"));
  };

  // Render methods

  const renderStatusColumn = (status) => {
    /* eslint-disable */
    let statusValue, statusClass;
    /* eslint-enable */

    if (status === "ACTIVE") {
      statusValue = "Active";
      statusClass = "dealer-invoices-tab--status-column--status-is-active";
    } else if (status === "PENDING") {
      statusValue = "Pending";
      statusClass = "dealer-invoices-tab--status-column--status-is-pending";
    } else if (status === "INACTIVE") {
      statusValue = "Inactive";
      statusClass = "dealer-invoices-tab--status-column--status-is-inactive";
    }

    return (
      <div className={statusClass}>{statusValue}</div>
    )
  }

  // Render

  return (
    <div className="dealer-invoices-tab">
      <div className="component--header">
        <div className="dealer-invoices-tab--filters-and-calendar-row">
          <div className="dealer-invoices-tab--sort-by-status-select">
            <Select
              className="select"
              defaultValue={sortByStatus}
              onChange={setSortByStatus}
            >
              <Select.Option value="All">All</Select.Option>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Pending">Pending</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          </div>
          <div className="dealer-invoices-tab--calendar">
            <Select
              className="select"
              defaultValue={sortByDate}
              onChange={setSortByDate}
            >
              <Select.Option
                value={"All Time"}
                onClick={() => setDateFilter(["", ""])}
              >
                All Time
              </Select.Option>
              <Select.Option
                value={"Last 14 days"}
                onClick={() => calculateLast14Days()}
              >
                Last 14 days
              </Select.Option>
              <Select.Option
                value={"Last 30 days"}
                onClick={() => calculateLast30Days()}
              >
                Last 30 days
              </Select.Option>
              <Select.Option
                value={"Last month"}
                onClick={() => calculateLastMonth()}
              >
                Last month
              </Select.Option>
              <Select.Option
                value={"Custom"}
                onClick={() => setFocusedInput("startDate")}
              >
                {dateTitle}
              </Select.Option>
            </Select>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={dateRange && dateRange[0]}
              endDate={dateRange && dateRange[1]}
              onDatesChange={
                ({ startDate, endDate }) => setDateRange([startDate, endDate])
              }
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              withPortal={true}
            />
          </div>
        </div>
      </div>

      {
        dealerInvoicesTotalCount ? (
          <div className="dealer-invoices-tab--content flex">
            <Table
              columnWidths={[
                "8%",
                "12%",
                "8.5%",
                "10%",
                "8%",
                "6%",
                "4.5%",
                "7.5%",
                "8.5%",
                "6.5%",
                "5.5%",
                "9.5%",
                "6%"
              ]}
              headerCells={[
                { label: "status" },
                { label: "invoice number" },
                { label: "method" },
                { label: "invoice date" },
                { label: "due date" },
                { label: "lines" },
                { label: "id" },
                { label: "charge" },
                { label: "discount " },
                { label: "line" },
                { label: "tax" },
                { label: "total" },
                { label: "actions" }
              ]}
            >
              {
                ({ columnWidths }) => (
                  dealerInvoices.map(({
                    id,
                    status,
                    invoiceNumber,
                    method,
                    invoiceDate,
                    dueDate,
                    lines,
                    invoiceId,
                    charge,
                    discount,
                    line,
                    tax,
                    total
                  }) => {
                    const invoiceDateFormatted = moment(invoiceDate).format("YYYY/MM/DD");
                    const dueDateFormatted = moment(dueDate).format("YYYY/MM/DD");

                    return (
                      <div
                        key={`dealer-invoices-tab-${id}`}
                        className="dealer-invoices-tab--content__row"
                      >
                        <TableRow columnWidths={columnWidths}>
                          <div className="dealer-invoices-tab--status-column flex">
                            {renderStatusColumn(status)}
                          </div>
                          <div className="dealer-invoices-tab--invoice-number-column flex">{invoiceNumber}</div>
                          <div className="dealer-invoices-tab--method-column flex">{method}</div>
                          <div className="dealer-invoices-tab--invoice-date-column flex">{invoiceDateFormatted}</div>
                          <div className="dealer-invoices-tab--due-date-column flex">{dueDateFormatted}</div>
                          <div className="dealer-invoices-tab--lines-column">{lines}</div>
                          <div className="dealer-invoices-tab--id-column">{invoiceId}</div>
                          <div className="dealer-invoices-tab--charge-column">£{charge}</div>
                          <div className="dealer-invoices-tab--discount-column">£{discount}</div>
                          <div className="dealer-invoices-tab--line-column">£{line}</div>
                          <div className="dealer-invoices-tab--tax-column">£{tax}</div>
                          <div className="dealer-invoices-tab--total-column">£{total}</div>
                          <div className="dealer-invoices-tab--actions-column flex">
                            <DownloadIcon
                              onClick={() => downloadInvoice(id, invoiceNumber)}
                            />
                            <DeleteIcon
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteIconClick(invoiceNumber)
                              }}
                            />
                          </div>
                        </TableRow>
                      </div>
                    )
                  })
                )
              }

              {
                dealerInvoicesTotalCount &&
                <TableFooter
                  key="footer"
                  page={page}
                  perPage={perPage}
                  total={dealerInvoicesTotalCount}
                />
              }
            </Table>
          </div>
        )
          : (
            <div className="dealer-invoices-tab--empty-data-message">
              There are no invoices available for this dealer.
            </div>
          )
      }
      {
        <ModalWindow
          className="modal-block delete-invoice-modal"
          centered
          visible={openDeleteInvoiceModal}
          onCancel={() => setOpenDeleteInvoiceModal(false)}
          title={(
            <div className="delete-modal-info">
              <div className="delete-modal-info--title">Delete invoice #{invoiceNumberToBeDeleted}</div>
              <div className="delete-modal-info--warning">Are you sure? This action cannot be undone.</div>
            </div>
          )}
          footer={(
            <div className="delete-modal-buttons">
              <Button
                className="ant-btn-primary delete-invoice-btn"
                onClick={_deleteDealerInvoice}
              >
                <DeleteIcon fillColor="#FFFFFF" />
                DELETE
              </Button>
              <Button
                className="cancel-delete-invoice-btn"
                onClick={() => setOpenDeleteInvoiceModal(false)}
              >
                CANCEL
              </Button>
            </div>
          )}
        >
        </ModalWindow>
      }
    </div>
  )
}

InvoicesTab.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getDealerInvoices: PropTypes.func.isRequired,
  dealerInvoices: PropTypes.array.isRequired,
  dealerInvoicesTotalCount: PropTypes.number.isRequired,
  deleteDealerInvoice: PropTypes.func.isRequired,
};

export default connect(
  ({ dealers }) => dealers,
  dispatch => ({
    getDealerInvoices: payload => dispatch(getDealerInvoicesRequest(payload)),
    deleteDealerInvoice: payload => dispatch(deleteDealerInvoiceRequest(payload)),
  })
)(withRouter(InvoicesTab));

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// components
import AddButton from "../../shared/AddButton";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";

// redux
import { getEmailProfilesRequest } from "../../../redux/settings/emailProfiles/emailProfilesActions";

// helpers
import navigation from "../../../helpers/navigation";
import { Utils } from "../../../helpers";


function EmailProfiles({ history, location, items, totalNumber, getEmailProfiles, userRole }) {
  const { order, page, perPage } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  useEffect(() => {
    getEmailProfiles({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const emailProfilesToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="component email-profiles">
      <div className="component--header">
        {!isDisabled && <AddButton onClick={() => history.push("/settings/email-profiles/add")} />}
      </div>
      <Table
        columnWidths={["15%", "10%", "auto", "20%", "20%"]}
        headerCells={[
          { label: "mail template id" },
          { label: "format" },
          { label: "mail subject" },
          { label: "from email address" },
          { label: "from email address name" }
        ]}
      >
        {
          emailProfilesToRender.map(emailProfileItem => {
            const { id, mailTemplateId, format, body, fromEmailAddress, fromEmailAddressName } = emailProfileItem;
            const formatStatus = format?.toLowerCase() || "";

            return (
              <TableRow key={`email-profiles-${id}`}>
                <div className="flex mail-template-id">
                  {mailTemplateId}
                </div>
                <div className="flex">
                  <div className="status-panel disabled">
                    <div className={`status-panel--item text-capitalize shorter ${formatStatus}`}>
                      {formatStatus}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  {Utils.emptyFieldFormat(body, "-")}
                </div>
                <div className="flex">
                  {Utils.emptyFieldFormat(fromEmailAddress, "-")}
                </div>
                <div className="flex">
                  {Utils.emptyFieldFormat(fromEmailAddressName, "-")}
                </div>
              </TableRow>
            )
          })
        }

        {totalNumber && (
          <TableFooter
            page={page}
            perPage={perPage}
            total={totalNumber}
          />
        )}
      </Table>
    </div>
  )
}

EmailProfiles.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  totalNumber: PropTypes.number.isRequired,
  getEmailProfiles: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    items: state.emailProfiles.items,
    totalNumber: state.emailProfiles.totalNumber,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getEmailProfiles: payload => dispatch(getEmailProfilesRequest(payload))
  })
)(EmailProfiles);

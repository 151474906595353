import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

// components
import Users from "./Users/Users";
import Subscribers from "./Subscribers/Subscribers";
import SearchQueryLog from "./SearchQueryLog/SearchQueryLog";

function UserManagement() {
  return (
    <Switch>
      <Route path="/user-management/users" component={Users} />
      <Route path="/user-management/subscribers" component={Subscribers} />
      <Route path="/user-management/search-query-log" component={SearchQueryLog} />
      <Redirect to="/user-management/users" />
    </Switch>
  )
}

export default UserManagement;

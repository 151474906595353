import React from "react";
import { Form, message } from "antd";
import PropTypes from "prop-types";

// api
import content from "../../../api/content";

// components
import { LantFormItem } from "../LantFormItem";
import MultipleImagesUploader from "../../../view/shared/MultipleImagesUploader";


export const UploadImagesTemp = ({ name, maxImagesNumber, ...rest }) => {
  const formInstance = Form.useFormInstance();
  const images = Form.useWatch(name, formInstance) || [];

  const handleUploadImage = (value) => {
    if (maxImagesNumber && images?.length >= maxImagesNumber) {
      message.error(`Max ${maxImagesNumber} images allowed`);

      return;
    }

    content
      .uploadNewImage(value)
      .then((resp) => {
        formInstance.setFieldValue(name, images?.concat(resp?.response));
      })
      .catch(console.error);
  };

  const handleDeleteImage = (value) => {
    formInstance.setFieldValue(name, images?.filter((el) => el.url !== value.url));
  };

  return (
    <LantFormItem name={name} {...rest}>
      <MultipleImagesUploader
        className="lant-form--upload"
        onImageDelete={handleDeleteImage}
        onImageUpload={handleUploadImage}
        images={images}
        maxImagesNumber={maxImagesNumber}
        bottomLabel="You can upload no more than 10 pictures"
      />
    </LantFormItem>
  );
};

UploadImagesTemp.propTypes = {
  name: PropTypes.string.isRequired,
  maxImagesNumber: PropTypes.number,
}

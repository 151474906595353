import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import TableRow from "../../shared/Table/TableRow";

export default function ModalItem({ item, checked, onChange, renderIds }) {
  const [isSelected, setIsSelected] = useState(checked);

  const onCheck = e => {
    setIsSelected(e.target.checked);
    onChange(e.target.checked, item);
  };

  return (
    <TableRow className="mb-0" isHighlighted={isSelected} columnWidths={["40px", "auto"]}>
      <Checkbox checked={isSelected} onChange={onCheck} />
      <div className="flex">{ item.url }</div>
      {renderIds ? <div className="flex category-modal--id-field">{ item.id }</div> : null}
    </TableRow>
  )
}

ModalItem.propTypes = {
  item: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  renderIds: PropTypes.bool.isRequired
};

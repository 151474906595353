import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, message } from "antd";
import lodash from "lodash";
import PropTypes from "prop-types";

// components
import { DirectoryEntryForm } from "../DirectoryEntryForm/DirectoryEntryForm";

// react-queries
import { useDirectoryEntryPatch, useFairPatch } from "../../../../react-query";

// helpers
import { setServerErrorsToForm } from "../../../../helpers";


export const DirectoryEntryDetails = ({ data, isFair }) => {
  const { id } = useParams();

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      const directoryWebsite = data?.website?.includes("https://")
        ? data?.website?.replace("https://", "")
        : data?.website;

      form.setFieldsValue({
        ...data,
        website: data?.website ? directoryWebsite : null,
        hideAddressDetails: !data.isVisibleMap,
        categories: data?.categories?.map((el) => ({
          value: el.id,
          label: el.title,
        })),
        dealerCategories: data?.dealerCategories?.map((el) => ({
          value: el.id,
          label: el.title,
        })),
        specialities: data?.specialities
          ?.map((el) => ({ value: el.id, label: el.title })),
      });
    }
  }, [JSON.stringify(data)]); // eslint-disable-line

  const redirectToList = () => {
    history.push(isFair ? "/directory/fairs" : "/directory/directory-entries");
  };

  const updateDirectoryEntry = useDirectoryEntryPatch({
    onSuccess: () => {
      message.success("Directory was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      setServerErrorsToForm(form, err?.data);
    }
  });

  const updateFair = useFairPatch({
    onSuccess: () => {
      message.success("Fair was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      setServerErrorsToForm(form, err?.data);
    }
  });

  const onSubmit = (values) => {
    const prevValues = {
      ...data,
      categories: data?.categories?.map((el) => el.id),
      dealerCategories: data?.dealerCategories?.map((el) => el.id),
      specialities: data?.specialities?.map((el) => el.id)
    };

    if (form.getFieldValue("dealerCategories")) {
      values.dealerCategories = form.getFieldValue("dealerCategories").map((el) => el.value);
    }

    const newValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (!lodash.isEqual(value, prevValues[key])) {
        acc[key] = value;
      }

      return acc;
    }, { id });

    if (newValues.dealerCategories && !newValues.categories) {
      newValues.categories = prevValues.categories;
    }

    if (isFair) {
      updateFair.mutate(newValues);
    } else {
      updateDirectoryEntry.mutate(newValues);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <div className="add-directory">
      <DirectoryEntryForm
        form={form}
        isEdit
        initData={data}
        isFair={isFair}
        onSubmit={onSubmit}
        onCancel={redirectToList}
      />
    </div>
  );
};

DirectoryEntryDetails.propTypes = {
  isFair: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    addressLineOne: PropTypes.string,
    addressLineTwo: PropTypes.string,
    admission: PropTypes.string,
    author: PropTypes.object,
    categories: PropTypes.array,
    dealerCategories: PropTypes.array,
    city: PropTypes.string,
    country: PropTypes.string,
    createdAt: PropTypes.string,
    description: PropTypes.string,
    directions: PropTypes.string,
    email: PropTypes.string,
    events: PropTypes.array,
    facebook: PropTypes.string,
    googleMaps: PropTypes.string,
    id: PropTypes.number.isRequired,
    images: PropTypes.array,
    instagram: PropTypes.string,
    isFair: PropTypes.bool,
    isPremium: PropTypes.bool,
    isVisibleMap: PropTypes.bool,
    linkedin: PropTypes.string,
    logo: PropTypes.shape({
      mobileUrl: PropTypes.string,
      name: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      url: PropTypes.string,
    }),
    mapMetadata: PropTypes.string,
    name: PropTypes.string,
    openingTimes: PropTypes.string,
    owner: PropTypes.shape({
      businessName: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      isFair: PropTypes.bool,
      phone: PropTypes.string,
      tradeName: PropTypes.string,
      user: PropTypes.object,
      website: PropTypes.string
    }),
    phone: PropTypes.string,
    region: PropTypes.string,
    shouldUseTextLogo: PropTypes.bool,
    specialities: PropTypes.array,
    status: PropTypes.oneOf(["ACTIVE", "PENDING", "INACTIVE"]),
    subscription: PropTypes.shape({
      cancelAtPeriodEnd: PropTypes.bool,
      cancelRequests: PropTypes.array,
      canceledAt: PropTypes.string,
      card: PropTypes.string,
      currentPeriodEnd: PropTypes.string,
      endingBalance: PropTypes.number,
      id: PropTypes.number,
      invoices: PropTypes.array,
      isActive: PropTypes.bool,
      plan: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        price: PropTypes.number,
        period: PropTypes.string,
        saving: PropTypes.number
      }),
      startDate: PropTypes.string,
      user: PropTypes.object
    }),
    textLogo: PropTypes.string,
    textLogoUrl: PropTypes.string,
    tiktok: PropTypes.string,
    twitter: PropTypes.string,
    ukCounty: PropTypes.string,
    url: PropTypes.string,
    videos: PropTypes.array,
    website: PropTypes.string,
    youtube: PropTypes.string,
  })
};

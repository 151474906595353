export const GET_DEALERS_REQUEST = "GET_DEALERS_REQUEST";
export const GET_DEALERS_SUCCESS = "GET_DEALERS_SUCCESS";
export const GET_DEALERS_ERROR = "GET_DEALERS_ERROR";

export const GET_DEALERS_STATUS_CHOICES_REQUEST = "GET_DEALERS_STATUS_CHOICES_REQUEST";
export const GET_DEALERS_STATUS_CHOICES_REQUEST_SUCCESS = "GET_DEALERS_STATUS_CHOICES_REQUEST_SUCCESS";
export const GET_DEALERS_STATUS_CHOICES_REQUEST_ERROR = "GET_DEALERS_STATUS_CHOICES_REQUEST_ERROR";

export const DELETE_DEALER_REQUEST = "DELETE_DEALER_REQUEST";
export const DELETE_DEALER_REQUEST_SUCCESS = "DELETE_DEALER_REQUEST_SUCCESS";
export const DELETE_DEALER_REQUEST_ERROR = "DELETE_DEALER_REQUEST_ERROR";

export const GET_DEALERS_NEWS_REQUEST = "GET_DEALERS_NEWS_REQUEST";
export const GET_DEALERS_NEWS_SUCCESS = "GET_DEALERS_NEWS_SUCCESS";
export const GET_DEALERS_NEWS_ERROR = "GET_DEALERS_NEWS_ERROR";

export const PATCH_DEALERS_NEWS_ITEM_REQUEST = "PATCH_DEALERS_NEWS_ITEM_REQUEST";
export const PATCH_DEALERS_NEWS_ITEM_SUCCESS = "PATCH_DEALERS_NEWS_ITEM_SUCCESS";
export const PATCH_DEALERS_NEWS_ITEM_ERROR = "PATCH_DEALERS_NEWS_ITEM_ERROR";

export const CREATE_DEALER_NEWS_ITEM_REQUEST = "CREATE_DEALER_NEWS_ITEM_REQUEST";
export const CREATE_DEALER_NEWS_ITEM_REQUEST_SUCCESS = "CREATE_DEALER_NEWS_ITEM_REQUEST_SUCCESS";
export const CREATE_DEALER_NEWS_ITEM_REQUEST_ERROR = "CREATE_DEALER_NEWS_ITEM_REQUEST_ERROR";

export const GET_DEALER_SPECIALITIES_REQUEST = "GET_DEALER_SPECIALITIES_REQUEST";
export const GET_DEALER_SPECIALITIES_REQUEST_SUCCESS = "GET_DEALER_SPECIALITIES_REQUEST_SUCCESS";
export const GET_DEALER_SPECIALITIES_REQUEST_ERROR = "GET_DEALER_SPECIALITIES_REQUEST_ERROR";

export const GET_DEALER_CATEGORIES_REQUEST = "GET_DEALER_CATEGORIES_REQUEST";
export const GET_DEALER_CATEGORIES_REQUEST_SUCCESS = "GET_DEALER_CATEGORIES_REQUEST_SUCCESS";
export const GET_DEALER_CATEGORIES_REQUEST_ERROR = "GET_DEALER_CATEGORIES_REQUEST_ERROR";

export const CREATE_DEALER_SPECIALITY_REQUEST = "CREATE_DEALER_SPECIALITY_REQUEST";
export const CREATE_DEALER_SPECIALITY_REQUEST_SUCCESS = "CREATE_DEALER_SPECIALITY_REQUEST_SUCCESS";
export const CREATE_DEALER_SPECIALITY_REQUEST_ERROR = "CREATE_DEALER_SPECIALITY_REQUEST_ERROR";

export const GET_SINGLE_DEALER_REQUEST = "GET_SINGLE_DEALER_REQUEST";
export const GET_SINGLE_DEALER_REQUEST_SUCCESS = "GET_SINGLE_DEALER_REQUEST_SUCCESS";
export const GET_SINGLE_DEALER_REQUEST_ERROR = "GET_SINGLE_DEALER_REQUEST_ERROR";

export const PATCH_DEALER_REQUEST = "PATCH_DEALER_REQUEST";
export const PATCH_DEALER_SUCCESS = "PATCH_DEALER_SUCCESS";
export const PATCH_DEALER_ERROR = "PATCH_DEALER_ERROR";

export const GET_DEALER_ADDRESSES_REQUEST = "GET_DEALER_ADDRESSES_REQUEST";
export const GET_DEALER_ADDRESSES_REQUEST_SUCCESS = "GET_DEALER_ADDRESSES_REQUEST_SUCCESS";
export const GET_DEALER_ADDRESSES_REQUEST_ERROR = "GET_DEALER_ADDRESSES_REQUEST_ERROR";

export const PATCH_DEALER_ADDRESS_REQUEST = "PATCH_DEALER_ADDRESS_REQUEST";
export const PATCH_DEALER_ADDRESS_REQUEST_SUCCESS = "PATCH_DEALER_ADDRESS_REQUEST_SUCCESS";
export const PATCH_DEALER_ADDRESS_REQUEST_ERROR = "PATCH_DEALER_ADDRESS_REQUEST_ERROR";

export const CREATE_DEALER_ADDRESS_REQUEST = "CREATE_DEALER_ADDRESS_REQUEST";
export const CREATE_DEALER_ADDRESS_REQUEST_SUCCESS = "CREATE_DEALER_ADDRESS_REQUEST_SUCCESS";
export const CREATE_DEALER_ADDRESS_REQUEST_ERROR = "CREATE_DEALER_ADDRESS_REQUEST_ERROR";

export const GET_DEALER_ADDRESS_REQUEST = "GET_DEALER_ADDRESS_REQUEST";
export const GET_DEALER_ADDRESS_REQUEST_SUCCESS = "GET_DEALER_ADDRESS_REQUEST_SUCCESS";
export const GET_DEALER_ADDRESS_REQUEST_ERROR = "GET_DEALER_ADDRESS_REQUEST_ERROR";

export const GET_DEALER_INVOICES_REQUEST = "GET_DEALER_INVOICES_REQUEST";
export const GET_DEALER_INVOICES_REQUEST_SUCCESS = "GET_DEALER_INVOICES_REQUEST_SUCCESS";
export const GET_DEALER_INVOICES_REQUEST_ERROR = "GET_DEALER_INVOICES_REQUEST_ERROR";

export const DELETE_DEALER_INVOICE_REQUEST = "DELETE_DEALER_INVOICE_REQUEST";
export const DELETE_DEALER_INVOICE_REQUEST_SUCCESS = "DELETE_DEALER_INVOICE_REQUEST_SUCCESS";
export const DELETE_DEALER_INVOICE_REQUEST_ERROR = "DELETE_DEALER_INVOICE_REQUEST_ERROR";

export const GET_DEALER_NOTES_REQUEST = "GET_DEALER_NOTES_REQUEST";
export const GET_DEALER_NOTES_REQUEST_SUCCESS = "GET_DEALER_NOTES_REQUEST_SUCCESS";
export const GET_DEALER_NOTES_REQUEST_ERROR = "GET_DEALER_NOTES_REQUEST_ERROR";

export const ADD_DEALER_NOTE_REQUEST = "ADD_DEALER_NOTE_REQUEST";
export const ADD_DEALER_NOTE_REQUEST_SUCCESS = "ADD_DEALER_NOTE_REQUEST_SUCCESS";
export const ADD_DEALER_NOTE_REQUEST_ERROR = "ADD_DEALER_NOTE_REQUEST_ERROR";

export const GET_DEALER_STATUS_DATA_REQUEST = "GET_DEALER_STATUS_DATA_REQUEST";
export const GET_DEALER_STATUS_DATA_REQUEST_SUCCESS = "GET_DEALER_STATUS_DATA_REQUEST_SUCCESS";
export const GET_DEALER_STATUS_DATA_REQUEST_ERROR = "GET_DEALER_STATUS_DATA_REQUEST_ERROR";

export const PATCH_DEALER_STATUS_DATA_REQUEST = "PATCH_DEALER_STATUS_DATA_REQUEST";
export const PATCH_DEALER_STATUS_DATA_REQUEST_SUCCESS = "PATCH_DEALER_STATUS_DATA_REQUEST_SUCCESS";
export const PATCH_DEALER_STATUS_DATA_REQUEST_ERROR = "PATCH_DEALER_STATUS_DATA_REQUEST_ERROR";

export const SEARCH_LIST_OF_DEALERS_REQUEST = "SEARCH_LIST_OF_DEALERS_REQUEST";
export const SEARCH_LIST_OF_DEALERS_REQUEST_SUCCESS = "SEARCH_LIST_OF_DEALERS_REQUEST_SUCCESS";
export const SEARCH_LIST_OF_DEALERS_REQUEST_ERROR = "SEARCH_LIST_OF_DEALERS_REQUEST_ERROR";

export const GET_DEALER_IMAGES_REQUEST = "GET_DEALER_IMAGES_REQUEST";
export const GET_DEALER_IMAGES_REQUEST_SUCCESS = "GET_DEALER_IMAGES_REQUEST_SUCCESS";
export const GET_DEALER_IMAGES_REQUEST_ERROR = "GET_DEALER_IMAGES_REQUEST_ERROR";

export const PATCH_DEALER_IMAGES_REQUEST = "PATCH_DEALER_IMAGES_REQUEST";
export const PATCH_DEALER_IMAGES_REQUEST_SUCCESS = "PATCH_DEALER_IMAGES_REQUEST_SUCCESS";
export const PATCH_DEALER_IMAGES_REQUEST_ERROR = "PATCH_DEALER_IMAGES_REQUEST_ERROR";

export const PUT_DEALER_IMAGES_REQUEST = "PUT_DEALER_IMAGES_REQUEST";
export const PUT_DEALER_IMAGES_REQUEST_SUCCESS = "PUT_DEALER_IMAGES_REQUEST_SUCCESS";
export const PUT_DEALER_IMAGES_REQUEST_ERROR = "PUT_DEALER_IMAGES_REQUEST_ERROR";

export const GET_DEALER_STOCK_IMAGES_REQUEST = "GET_DEALER_STOCK_IMAGES_REQUEST";
export const GET_DEALER_STOCK_IMAGES_REQUEST_SUCCESS = "GET_DEALER_STOCK_IMAGES_REQUEST_SUCCESS";
export const GET_DEALER_STOCK_IMAGES_REQUEST_ERROR = "GET_DEALER_STOCK_IMAGES_REQUEST_ERROR";

export const CREATE_DEALER_STOCK_IMAGES_REQUEST = "CREATE_DEALER_STOCK_IMAGES_REQUEST";
export const CREATE_DEALER_STOCK_IMAGES_REQUEST_SUCCESS = "CREATE_DEALER_STOCK_IMAGES_REQUEST_SUCCESS";
export const CREATE_DEALER_STOCK_IMAGES_REQUEST_ERROR = "CREATE_DEALER_STOCK_IMAGES_REQUEST_ERROR";

export const PATCH_DEALER_STOCK_IMAGES_REQUEST = "PATCH_DEALER_STOCK_IMAGES_REQUEST";
export const PATCH_DEALER_STOCK_IMAGES_REQUEST_SUCCESS = "PATCH_DEALER_STOCK_IMAGES_REQUEST_SUCCESS";
export const PATCH_DEALER_STOCK_IMAGES_REQUEST_ERROR = "PATCH_DEALER_STOCK_IMAGES_REQUEST_ERROR";

export const DELETE_DEALER_STOCK_IMAGE_REQUEST = "DELETE_DEALER_STOCK_IMAGE_REQUEST";
export const DELETE_DEALER_STOCK_IMAGE_REQUEST_SUCCESS = "DELETE_DEALER_STOCK_IMAGE_REQUEST_SUCCESS";
export const DELETE_DEALER_STOCK_IMAGE_REQUEST_ERROR = "DELETE_DEALER_STOCK_IMAGE_REQUEST_ERROR";

export const UPLOAD_NEW_DEALER_LOGO_REQUEST = "UPLOAD_NEW_DEALER_LOGO_REQUEST";
export const UPLOAD_NEW_DEALER_LOGO_REQUEST_ERROR = "UPLOAD_NEW_DEALER_LOGO_REQUEST_ERROR";
export const UPLOAD_NEW_DEALER_LOGO_REQUEST_SUCCESS = "UPLOAD_NEW_DEALER_LOGO_REQUEST_SUCCESS";

export const UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST = "UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST";
export const UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_ERROR = "UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_ERROR";
export const UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_SUCCESS = "UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_SUCCESS";

export const UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST = "UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST";
export const UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_ERROR = "UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_ERROR";
export const UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_SUCCESS = "UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_SUCCESS";

export const GET_API_KEY_REQUESTS = "GET_API_KEY_REQUESTS";
export const GET_API_KEY_REQUESTS_ERROR = "GET_API_KEY_REQUESTS_ERROR";
export const GET_API_KEY_REQUESTS_SUCCESS = "GET_API_KEY_REQUESTS_SUCCESS";

export const CHANGE_API_KEY_REQUEST_STATUS = "CHANGE_API_KEY_REQUEST_STATUS";
export const CHANGE_API_KEY_REQUEST_STATUS_ERROR = "CHANGE_API_KEY_REQUEST_STATUS_ERROR";

export const REVOKE_API_KEY_REQUEST = "REVOKE_API_KEY_REQUEST";
export const REVOKE_API_KEY_REQUEST_ERROR = "REVOKE_API_KEY_REQUEST_ERROR";

export const SEARCH_DEALERS = "SEARCH_DEALERS";
export const SEARCH_DEALERS_SUCCESS = "SEARCH_DEALERS_SUCCESS";
export const SEARCH_DEALERS_ERROR = "SEARCH_DEALERS_ERROR";

export const GET_TRADE_ASSOCIATIONS_REQUEST = "GET_TRADE_ASSOCIATIONS_REQUEST";
export const GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS = "GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS";
export const GET_TRADE_ASSOCIATIONS_REQUEST_ERROR = "GET_TRADE_ASSOCIATIONS_REQUEST_ERROR";

export const CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST = "CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST";
export const CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_SUCCESS = "CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_SUCCESS";
export const CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_ERROR = "CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_ERROR";

export const DELETE_TRADE_ASSOCIATION_REQUEST = "DELETE_TRADE_ASSOCIATION_REQUEST";
export const DELETE_TRADE_ASSOCIATION_REQUEST_ERROR = "DELETE_TRADE_ASSOCIATION_REQUEST_ERROR";

export const CREATE_TRADE_ASSOCIATION_REQUEST = "CREATE_TRADE_ASSOCIATION_REQUEST";
export const CREATE_TRADE_ASSOCIATION_REQUEST_ERROR = "CREATE_TRADE_ASSOCIATION_REQUEST_ERROR";

export const UPDATE_TRADE_ASSOCIATION_REQUEST = "UPDATE_TRADE_ASSOCIATION_REQUEST";
export const UPDATE_TRADE_ASSOCIATION_REQUEST_ERROR = "UPDATE_TRADE_ASSOCIATION_REQUEST_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_DEALERS_PROCESSING";

export const SHOW_LOADING = "SHOW_DEALERS_LOADING";
export const HIDE_LOADING = "HIDE_DEALERS_LOADING";

export const RESET_ERROR = "RESET_DEALERS_ERROR";

export const CLEAR_DEALER_DATA_ACTION = "CLEAR_DEALER_DATA_ACTION";

export const SET_DEALERS_TESTIMONIALS_COUNT_ACTION = "SET_DEALERS_TESTIMONIALS_COUNT_ACTION";

export const DELETE_DEALER_SPECIALITY_REQUEST = "DELETE_DEALER_SPECIALITY_REQUEST";
export const DELETE_DEALER_SPECIALITY_REQUEST_ERROR = "DELETE_DEALER_SPECIALITY_REQUEST_ERROR";
export const DELETE_DEALER_SPECIALITY_REQUEST_SUCCESS = "DELETE_DEALER_SPECIALITY_REQUEST_SUCCESS";

export const UPDATE_DEALER_SPECIALITY_REQUEST = "UPDATE_DEALER_SPECIALITY_REQUEST";
export const UPDATE_DEALER_SPECIALITY_REQUEST_ERROR = "UPDATE_DEALER_SPECIALITY_REQUEST_ERROR";
export const UPDATE_DEALER_SPECIALITY_REQUEST_SUCCESS = "UPDATE_DEALER_SPECIALITY_REQUEST_SUCCESS";

export const GET_DEALER_LOGS_REQUEST = "GET_DEALER_LOGS_REQUEST";
export const GET_DEALER_LOGS_SUCCESS = "GET_DEALER_LOGS_SUCCESS";
export const GET_DEALER_LOGS_FAILED = "GET_DEALER_LOGS_FAILED";

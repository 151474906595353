import React from "react";
import PropTypes from "prop-types";


const DirectoryIcon = ({ fillColor = "#717171", className = "" }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2421 15.1676H19.2189V14.5028C19.2189 13.2905 18.2716 12.3128 17.0968 12.3128H12.4926V10.1229H14.6337C14.8421 10.1229 15.0126 9.94693 15.0126 9.73184V6.2905C15.0126 6.07542 14.8421 5.89944 14.6337 5.89944H12.0568C11.9432 5.89944 11.8295 5.84078 11.7537 5.74302L11.3558 5.15642C11.28 5.05866 11.1663 5 11.0526 5H9.36632C9.15789 5 8.98737 5.17598 8.98737 5.39106V9.73184C8.98737 9.94693 9.15789 10.1229 9.36632 10.1229H11.5074V12.3128H6.90316C5.72842 12.3128 4.78105 13.2905 4.78105 14.5028V15.1676H3.75789C3.34105 15.1676 3 15.5196 3 15.9497V18.2179C3 18.648 3.34105 19 3.75789 19H6.77053C7.18737 19 7.52842 18.648 7.52842 18.2179V15.9302C7.52842 15.5 7.18737 15.148 6.77053 15.148H5.74737V14.4832C5.74737 13.838 6.25895 13.3101 6.88421 13.3101H11.5074V15.148H10.4842C10.0674 15.148 9.72632 15.5 9.72632 15.9302V18.1983C9.72632 18.6285 10.0674 18.9804 10.4842 18.9804H13.5158C13.9326 18.9804 14.2737 18.6285 14.2737 18.1983V15.9302C14.2737 15.5 13.9326 15.148 13.5158 15.148H12.4926V13.3101H17.1158C17.7411 13.3101 18.2526 13.838 18.2526 14.4832V15.148H17.2295C16.8126 15.148 16.4716 15.5 16.4716 15.9302V18.1983C16.4716 18.6285 16.8126 18.9804 17.2295 18.9804H20.2421C20.6589 18.9804 21 18.6285 21 18.1983V15.9302C21 15.5 20.6589 15.1676 20.2421 15.1676Z"
        fill={fillColor}
      />
    </svg>
  )
}

DirectoryIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default DirectoryIcon

export const GET_CONTENT_HELP_TEXT_ITEMS_REQUEST = "GET_CONTENT_HELP_TEXT_ITEMS_REQUEST";
export const GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_SUCCESS = "GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_SUCCESS";
export const GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_ERROR = "GET_CONTENT_HELP_TEXT_ITEMS_REQUEST_ERROR";

export const PATCH_HELP_TEXT_ITEM_REQUEST = "PATCH_HELP_TEXT_ITEM_REQUEST";
export const PATCH_HELP_TEXT_ITEM_REQUEST_SUCCESS = "PATCH_HELP_TEXT_ITEM_REQUEST_SUCCESS";
export const PATCH_HELP_TEXT_ITEM_REQUEST_ERROR = "PATCH_HELP_TEXT_ITEM_REQUEST_ERROR";

export const CREATE_HELP_TEXT_ITEM_REQUEST = "CREATE_HELP_TEXT_ITEM_REQUEST";
export const CREATE_HELP_TEXT_ITEM_REQUEST_SUCCESS = "CREATE_HELP_TEXT_ITEM_REQUEST_SUCCESS";
export const CREATE_HELP_TEXT_ITEM_REQUEST_ERROR = "CREATE_HELP_TEXT_ITEM_REQUEST_ERROR";

export const DELETE_HELP_TEXT_ITEM_REQUEST = "DELETE_HELP_TEXT_ITEM_REQUEST";
export const DELETE_HELP_TEXT_ITEM_REQUEST_SUCCESS = "DELETE_HELP_TEXT_ITEM_REQUEST_SUCCESS";
export const DELETE_HELP_TEXT_ITEM_REQUEST_ERROR = "DELETE_HELP_TEXT_ITEM_REQUEST_ERROR";

export const PUT_HELP_TEXT_ITEM_REQUEST = "PUT_HELP_TEXT_ITEM_REQUEST";
export const PUT_HELP_TEXT_ITEM_REQUEST_SUCCESS = "PUT_HELP_TEXT_ITEM_REQUEST_SUCCESS";
export const PUT_HELP_TEXT_ITEM_REQUEST_ERROR = "PUT_HELP_TEXT_ITEM_REQUEST_ERROR";

export const GET_HELP_TEXT_CHOICES_REQUEST = "GET_HELP_TEXT_CHOICES_REQUEST";
export const GET_HELP_TEXT_CHOICES_REQUEST_SUCCESS = "GET_HELP_TEXT_CHOICES_REQUEST_SUCCESS";
export const GET_HELP_TEXT_CHOICES_REQUEST_ERROR = "GET_HELP_TEXT_CHOICES_REQUEST_ERROR";

export const TOGGLE_CONTENT_HELP_TEXT_PROCESSING = "TOGGLE_CONTENT_HELP_TEXT_PROCESSING";

export const LOADING = "HELP_TEXT_LOADING";

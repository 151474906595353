import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Checkbox } from "antd";
import _ from "lodash";

import LabeledInput from "../../shared/LabeledInput";
import GeneralFormControl from "../../shared/GeneralFormControl";

import {
  createGroupRequest,
  updateGroupRequest,
  resetErrorAction,
} from "../../../redux/groups/groupActions";

function AddEditGroup(props) {
  const {
    item,
    isFullScreen,
    toggleFullscreen,
    history,
    close,
    remove,
    processing,
    error,
  } = props;

  const fullURL = _.get(item, "fullUrl", "");
  const urlPrefix = item && fullURL.replace(`/${item.url}`, "/");
  const err = typeof error === "object" ? error : {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  const [submitted, setSubmitted] = useState(false);
  const [active, setActive] = useState(item ? item.isActive : true);
  const [title, setTitle] = useState(item ? item.title || "" : "");
  const [url, setUrl] = useState(item ? item.url || "" : "");
  const [description, setDescription] = useState(
    item ? item.description || "" : ""
  );

  const isEdit = !!item;
  const oldUrlBeforeEdit = item ? item.url || "" : "";

  // Reset error on component unmount
  useEffect(() => props.resetError, []); // eslint-disable-line

  // Close form on successful submit
  useEffect(() => {
    if (!submitted || processing || error) {
      return;
    }

    if (isEdit) {
      close();
    } else history.push("/catalog/groups");
  }, [processing, submitted, error]); // eslint-disable-line

  // Methods
  const onSave = () => {
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }
    setSubmitted(true);

    const data = {
      isActive: active,
      title: title,
      url: url,
      description: description,
    };

    if (isEdit) {
      return props.updateGroup(oldUrlBeforeEdit, data);
    }
    props.createGroup(data);
  };

  const onCancel = () => {
    if (isEdit) {
      return remove();
    }
    history.push("/catalog/groups");
  };

  // Render

  return (
    <div className="general-form">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"} Group</p>

        {isEdit && (
          <GeneralFormControl
            isFullScreen={isFullScreen}
            toggleFullscreen={toggleFullscreen}
            close={close}
          />
        )}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={active}
          onChange={setActive}
        >
          Active
        </Checkbox>
      </div>

      <LabeledInput
        label="Title"
        value={title}
        onChange={setTitle}
        isRequired
        hasError={!!err.title}
      />

      <LabeledInput
        label="URL"
        value={url}
        onChange={setUrl}
        isRequired
        hasError={!!err.url}
        inputClassName="input-with-prefix"
        addonBefore={urlPrefix ? urlPrefix : "https://www.loveantiques.com/"}
        suffix={urlFieldImage}
      />

      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={setDescription}
        hasError={!!err.description}
      />

      <div className="general-form--button-holder">
        <Button
          className="ant-btn-primary"
          onClick={onSave}
          loading={processing}
        >
          SAVE & CLOSE
        </Button>

        <Button onClick={onCancel}>
          {isEdit && (
            <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />
          )}
          {isEdit ? "DELETE ITEM" : "CANCEL"}
        </Button>
      </div>
    </div>
  );
}

AddEditGroup.propTypes = {
  item: PropTypes.object,
  isFullScreen: PropTypes.bool,
  history: PropTypes.object,
  toggleFullscreen: PropTypes.func,
  close: PropTypes.func,
  remove: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  createGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    processing: state.groups.processing,
    error: state.groups.error,
  }),
  (dispatch) => ({
    createGroup: (data) => dispatch(createGroupRequest(data)),
    updateGroup: (url, data) => dispatch(updateGroupRequest(url, data)),
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddEditGroup);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";

// components
import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditCatalogEntity from "./AddEditCatalogEntity";
import CatalogEntityRow from "./CatalogEntityRow";

// redux
import {
  getCatalogEntityElementsRequest,
  updateCatalogEntityElementRequest,
  deleteCatalogEntityElementRequest,
} from "../../../redux/catalog/catalogActions";

// helpers
import { firstLetterCapitalize, Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";


function CatalogEntity({
  entity = {},
  items: stateItems,
  totalItems,
  location,
  history,
  userRole,
  getCatalogEntityElements,
  updateCatalogEntityElement,
  deleteCatalogEntityElement,
}) {
  const items = stateItems[entity.url] || [];

  const [selected, setSelected] = useState(null);
  const { search, page, perPage, order } = navigation.getTableQueryParams(location);

  const creationPermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1");

  // Pagination/search api call here
  useEffect(() => {
    entity.url && getCatalogEntityElements(entity.url, { page, perPage, search });
  }, [page, perPage, search, entity.url]); // eslint-disable-line


  // Methods
  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onAddSubClick = itemUrl => {
    history.push({
      pathname: `${location.pathname}/add`,
      search: `?parent=${itemUrl}`
    });
  };

  const onChangeActive = (url, event) => {
    updateCatalogEntityElement(entity.url, url, { isVisible: event.target.checked });
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) {
      target = selected.url;
    }

    deleteCatalogEntityElement(
      entity.url,
      target,
      () => {
        message.success(`${firstLetterCapitalize(entity.url)} was successfully deleted`, 2);
        setSelected(null);
      }
    );
  };

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };


  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div>
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            {creationPermission && <AddButton onClick={onAddClick} />}
            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["5%", "auto", "20%", "10%", "10%", "142px"]}
            headerCells={[
              {},
              { label: "Name" },
              { label: "Url" },
              { label: "Items" },
              { label: "Artisans" },
              { label: "Actions" },
            ]}
          >
            {({ columnWidths }) => {
              return itemsToRender.map(item => {
                return (
                  <CatalogEntityRow
                    key={`catalog-entity:${item.id}`}
                    columnWidths={columnWidths}
                    item={item}
                    onChangeActive={onChangeActive}
                    onEdit={onStartEditClick}
                    onAddSub={onAddSubClick}
                    onRemove={onRemove}
                    isSearch={!!search}
                    entity={entity.url}
                  />
                )
              })
            }}

            {
              totalItems &&
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditCatalogEntity
            entity={entity}
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

CatalogEntity.propTypes = {
  entity: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getCatalogEntityElements: PropTypes.func.isRequired,
  updateCatalogEntityElement: PropTypes.func.isRequired,
  deleteCatalogEntityElement: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  (state) => ({
    items: state.catalog.items,
    totalItems: state.catalog.total,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getCatalogEntityElements: (entityUrl, params) => dispatch(getCatalogEntityElementsRequest(entityUrl, params)),
    updateCatalogEntityElement: (entityUrl, itemUrl, data) => {
      dispatch(updateCatalogEntityElementRequest(entityUrl, itemUrl, data, true))
    },
    deleteCatalogEntityElement: (entityUrl, itemUrl, callback) => {
      dispatch(deleteCatalogEntityElementRequest(entityUrl, itemUrl, callback))
    }
  })
)(CatalogEntity);

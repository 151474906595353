import * as types from "./blacklistsEnquiriesActionsTypes";


const initialState = {
  items: [],
  options: {},
  total: 0,
  loading: false,
  processing: false,
  error: "",
  spamList: [],
  spamListTotal: 0
};


const blacklistsEnquiries = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false
      };

    case types.GET_BLACKLISTS_ENQUIRIES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count,
      };

    case types.GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST_SUCCESS:
      return {
        ...state,
        options: action.payload.blackListTypes,
        error: null,
      };

    case types.GET_SPAM_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        spamList: action.payload.results,
        spamListTotal: action.payload.count,
      };

    case types.GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR:
    case types.GET_SPAM_LIST_REQUEST_ERROR:
    case types.ADD_ELEMENT_TO_SPAM_LIST_REQUEST_ERROR:
    case types.DELETE_ENQUIRY_FROM_BLACKLIST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.ADD_ELEMENT_TO_SPAM_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        error: null
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ""
      };

    default:
      return state;
  }
};

export default blacklistsEnquiries;

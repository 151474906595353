import React from "react";
import PropTypes from "prop-types";

const ActiveInvoiceBlock = ({
  value,
  text
}) => (
  <div className="flex active-invoice-block">
    <div className="active-invoice-block--value">{value}</div>
    <div className="active-invoice-block--text">{text}</div>
  </div>
)

ActiveInvoiceBlock.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.string.isRequired,
};

export default ActiveInvoiceBlock;

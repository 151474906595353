export const GET_ITEMS_REQUEST = "GET_ITEMS_REQUEST";
export const GET_ITEMS_REQUEST_SUCCESS = "GET_ITEMS_REQUEST_SUCCESS";
export const GET_ITEMS_REQUEST_ERROR = "GET_ITEMS_REQUEST_ERROR";

export const PATCH_ITEMS_DATA_REQUEST = "PATCH_ITEMS_DATA_REQUEST";
export const PATCH_ITEMS_DATA_SUCCESS = "PATCH_ITEMS_DATA_SUCCESS";
export const PATCH_ITEMS_DATA_ERROR = "PATCH_ITEMS_DATA_ERROR";

export const GET_CHOICES_REQUEST = "GET_CHOICES_REQUEST";
export const GET_CHOICES_REQUEST_SUCCESS = "GET_CHOICES_REQUEST_SUCCESS";
export const GET_CHOICES_REQUEST_ERROR = "GET_CHOICES_REQUEST_ERROR";

export const DELETE_ITEMS_CATALOG_ELEMENT_REQUEST = "DELETE_ITEMS_CATALOG_ELEMENT_REQUEST";
export const DELETE_ITEMS_CATALOG_ELEMENT_SUCCESS = "DELETE_ITEMS_CATALOG_ELEMENT_SUCCESS";
export const DELETE_ITEMS_CATALOG_ELEMENT_ERROR = "DELETE_ITEMS_CATALOG_ELEMENT_ERROR";

export const UPDATE_ITEMS_STATUS_REQUEST = "UPDATE_ITEMS_STATUS_REQUEST";
export const UPDATE_ITEMS_STATUS_SUCCESS = "UPDATE_ITEMS_STATUS_SUCCESS";
export const UPDATE_ITEMS_STATUS_ERROR = "UPDATE_ITEMS_STATUS_ERROR";

export const ROTATE_IMAGE_REQUEST = "ROTATE_IMAGE_REQUEST";
export const ROTATE_IMAGE_SUCCESS = "ROTATE_IMAGE_SUCCESS";
export const ROTATE_IMAGE_ERROR = "ROTATE_IMAGE_ERROR";

export const UPDATE_SHOW_ON_HOME_STATUS_REQUEST = "UPDATE_SHOW_ON_HOME_STATUS_REQUEST";
export const UPDATE_SHOW_ON_HOME_STATUS_REQUEST_SUCCESS = "UPDATE_SHOW_ON_HOME_STATUS_REQUEST_SUCCESS";
export const UPDATE_SHOW_ON_HOME_STATUS_ERROR = "UPDATE_SHOW_ON_HOME_STATUS_ERROR";

const formCategoriesToSend = formValues => {
  const categoriesToSend = []

  for (const key in formValues) {
    if (key.includes("Category") && Array.isArray(formValues[key])) {
      categoriesToSend.push(...formValues[key].map(value => value.id ? value.id : value))

    }

    else if (key.includes("Category") && formValues[key] && !Array.isArray(formValues[key])) {
      categoriesToSend.push(formValues[key].id ? formValues[key].id : formValues[key])
    }
  }

  return categoriesToSend
};

export default formCategoriesToSend;

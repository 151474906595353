import React from "react";
import PropTypes from "prop-types";

const CustomerNotesSection = ({ customerNotes }) => {
  return (
    <div className="order-header--customer-notes-wrapper">
      <div className="header-tab-column-title">Customer Notes</div>
      <div className="customer-notes-column-info">
        <div className="customer-notes-column-info-field">
          <div className="customer-notes-field-value">
            {customerNotes || "-"}
          </div>
        </div>
      </div>
    </div>
  )
}

CustomerNotesSection.propTypes = {
  customerNotes: PropTypes.string.isRequired
};

export default CustomerNotesSection;

import { useMutation } from "react-query";

// helpers
import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/users/${id}/token/`, method: "GET" });
};

export const useImpersonate = (options) => {
  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp) => {
      const windowReference = window.open();

      document.cookie = `accessToken=${resp.access}; domain=${process.env.REACT_APP_DOMAIN_NAME}; path=/; SameSite=Lax`;
      windowReference.location = process.env.REACT_APP_STOREFRONT_URL;
    },
    onError: console.warn,
  });
}
import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";

// components
import DeleteIcon from "../../view/Icon/DeleteIcon";


export const ConfirmDeleteModal = ({
  title = "Delete",
  open,
  processing = false,
  closeModal,
  onConfirm
}) => {

  return (
    <Modal
      className="modal-block delete-dealer-modal"
      centered
      open={open}
      onCancel={closeModal}
      title={(
        <div className="delete-modal-info">
          <div className="delete-modal-info--title">{title}</div>
          <div className="delete-modal-info--warning">Are you sure? This action cannot be undone.</div>
        </div>
      )}
      footer={(
        <div className="delete-modal-buttons">
          <Button className="ant-btn-primary delete-dealer-btn" loading={processing} onClick={onConfirm}>
            <DeleteIcon fillColor="#FFFFFF" />
            DELETE
          </Button>
          <Button className="cancel-delete-dealer-btn" onClick={closeModal}>
            CANCEL
          </Button>
        </div>
      )}
    >
    </Modal>
  )
};

ConfirmDeleteModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

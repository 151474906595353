export const SHOW_LOADING = "SHOW_BLACKLISTS_ENQUIRIES_LOADING";
export const HIDE_LOADING = "HIDE_BLACKLISTS_ENQUIRIES_LOADING";

export const SHOW_PROCESSING = "SHOW_BLACKLISTS_ENQUIRIES_PROCESSING";
export const HIDE_PROCESSING = "HIDE_BLACKLISTS_ENQUIRIES_PROCESSING";

export const RESET_ERROR = "RESET_BLACKLISTS_ENQUIRIES_ERROR";

export const GET_BLACKLISTS_ENQUIRIES_REQUEST = "GET_BLACKLISTS_ENQUIRIES_REQUEST";
export const GET_BLACKLISTS_ENQUIRIES_REQUEST_SUCCESS = "GET_BLACKLISTS_ENQUIRIES_REQUEST_SUCCESS";
export const GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR = "GET_BLACKLISTS_ENQUIRIES_REQUEST_ERROR";

export const CREATE_BLACKLISTS_ENQUIRIES_REQUEST = "CREATE_BLACKLISTS_ENQUIRIES_REQUEST";
export const UPDATE_BLACKLISTS_ENQUIRIES_REQUEST = "UPDATE_BLACKLISTS_ENQUIRIES_REQUEST";
export const DELETE_BLACKLISTS_ENQUIRIES_REQUEST = "DELETE_BLACKLISTS_ENQUIRIES_REQUEST";

export const GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST = "GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST";
export const GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST_SUCCESS = "GET_BLACKLISTS_ENQUIRIES_CHOICES_REQUEST_SUCCESS";

export const GET_SPAM_LIST_REQUEST = "GET_SPAM_LIST_REQUEST";
export const GET_SPAM_LIST_REQUEST_ERROR = "GET_SPAM_LIST_REQUEST_ERROR";
export const GET_SPAM_LIST_REQUEST_SUCCESS = "GET_SPAM_LIST_REQUEST_SUCCESS";

export const ADD_ELEMENT_TO_SPAM_LIST_REQUEST = "ADD_ELEMENT_TO_SPAM_LIST_REQUEST";
export const ADD_ELEMENT_TO_SPAM_LIST_REQUEST_ERROR = "ADD_ELEMENT_TO_SPAM_LIST_REQUEST_ERROR";
export const ADD_ELEMENT_TO_SPAM_LIST_REQUEST_SUCCESS = "ADD_ELEMENT_TO_SPAM_LIST_REQUEST_SUCCESS";

export const DELETE_ENQUIRY_FROM_BLACKLIST = "DELETE_ENQUIRY_FROM_BLACKLIST";
export const DELETE_ENQUIRY_FROM_BLACKLIST_ERROR = "DELETE_ENQUIRY_FROM_BLACKLIST_ERROR";

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

import { useDebounce } from "../../hooks";
import dealers from "../../api/dealers";


const DealerSelectWithSearch = ({ placeholder = "", onSelect }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebounce(search, 250);

  useEffect(() => {
    setLoading(true);

    dealers
      .getDealers({ page: 1, perPage: 10, business_name: debouncedSearch })
      .then((resp) => {
        setPage(1);
        setTotal(resp.count);
        setOptions(resp.results?.map((item) => ({ value: item.id, label: item.businessName })) || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debouncedSearch]); // eslint-disable-line

  const handleClear = () => {
    setSearch("");
  };

  const handleScroll = async (event) => {
    const target = event.target;

    if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight && total > options.length) {
      setLoading(true);

      dealers
        .getDealers({ page: page + 1, perPage: 10, business_name: debouncedSearch })
        .then((resp) => {
          setPage(page + 1);
          setTotal(resp.count);
          setOptions(options.concat(resp.results?.map((item) => ({ value: item.id, label: item.businessName })) || []));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="list-of-orders--sort-by-dealer-btn">
      <p className="list-of-orders--dealer-btn-label">
        Dealer:
      </p>
      <Select
        className="select"
        placeholder={placeholder}
        options={options}
        onChange={onSelect}
        showSearch
        allowClear
        filterOption={false}
        loading={loading}
        onPopupScroll={handleScroll}
        onSearch={setSearch}
        onClear={handleClear}
      />
    </div>
  )
}

DealerSelectWithSearch.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default DealerSelectWithSearch;

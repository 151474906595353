import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./emailProfilesActionsTypes";
import settingsApi from "../../../api/settings";


function *getEmailProfiles(action) {
  try {
    yield put({ type: types.SHOW_EMAIL_PROFILES_PROCESSING });

    const response = yield call(settingsApi.getEmailProfiles, action.payload);
    yield put({ type: types.GET_EMAIL_PROFILES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_EMAIL_PROFILES_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_EMAIL_PROFILES_PROCESSING });
  }
}

function *createEmailProfile(action) {
  try {
    yield put({ type: types.SHOW_EMAIL_PROFILES_PROCESSING });
    yield call(settingsApi.postEmailProfile, action.payload);
    yield put({ type: types.CREATE_EMAIL_PROFILE_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_EMAIL_PROFILE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_EMAIL_PROFILES_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_EMAIL_PROFILES_REQUEST, getEmailProfiles),
    yield takeLatest(types.CREATE_EMAIL_PROFILE_REQUEST, createEmailProfile)
  ])
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Checkbox, Popconfirm, message } from "antd";
import { connect } from "react-redux";

//components
import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Tag from "../../shared/Tag";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditArtisans from "./AddEditArtisans/AddEditArtisans";

//redux
import {
  getArtisansRequest,
  updateArtisansRequest,
  createArtisansRequest,
  deleteArtisansRequest,
} from "../../../redux/artisans/artisansActions";

//helpers
import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";


function Artisans(props) {
  const { history, location, items, total, userRole } = props;

  const [selected, setSelected] = useState(null);
  const { search, page, perPage, order } = navigation.getTableQueryParams(location);

  const hasUpdatePermission = userRole.some(role => role === "Super Admin" || role === "Admin Seo" || role === "AdminLv1" || role === "AdminLv2");
  const isSuperAdmin = userRole.some(role => role === "Super Admin");

  // Pagination/search api call here
  useEffect(() => {
    props.getArtisans({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line


  // Methods
  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onChangeActive = (itemUrl, event) => {
    props.updateArtisans(itemUrl, { isVisible: event.target.checked });
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) {
      target = selected.url;
    }

    props.deleteArtisans(target, () => {
      message.success("Artisan was successfully deleted", 2);
      setSelected(null);
    });
  };

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };


  // Render
  const artisansToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="artisans">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            <AddButton onClick={onAddClick} />
            <SearchInput value={search} onChange={onSearch} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            className="artisans--table"
            columnWidths={["40px", "auto", "auto", "20%", "80px", "80px", "80px", "80px", "110px"]}
            headerCells={[
              {},
              { label: "Name" },
              { label: "Tags" },
              { label: "Url" },
              { label: "Status" },
              { label: "AVAIL." },
              { label: "RESER." },
              { label: "SOLD" },
              { label: "Actions" },
            ]}
          >
            {
              artisansToRender.map(artisan => {
                return (
                  <TableRow key={`artisan-${artisan.id}`}>
                    <Checkbox checked={artisan.isVisible} onChange={e => onChangeActive(artisan.url, e)} />

                    <div className="flex artisans--row-block artisans--row-block__name">
                      <Avatar src="https://picsum.photos/id/826/32/32" size={32} shape="square" />
                      <span>{artisan.name}</span>
                    </div>

                    <div className="flex artisans--row-block">
                      {artisan.tags.map(tag => <Tag key={tag.name} label={tag.name} />)}
                    </div>
                    <div className="flex artisans--row-block">{artisan.url}</div>
                    <div className="flex artisans--row-block">{artisan.isSuggested ? "Suggested" : "Approved"}</div>
                    <div className="flex artisans--row-block">{artisan.itemsCount || 0}</div>
                    <div className="flex artisans--row-block">{artisan.reserved || 0}</div>
                    <div className="flex artisans--row-block">{artisan.sold || 0}</div>
                    <div className="flex artisans--row-block artisans--row-block__actions">
                      <img
                        className="groups--table-icon"
                        src="/images/eye_3x.svg"
                        alt=""
                        width="18"
                        onClick={() => null}
                      />
                      {hasUpdatePermission && (
                        <img
                          className="groups--table-icon"
                          src="/images/edit@3x.svg"
                          onClick={() => onStartEditClick(artisan)}
                          alt=""
                          width="16"
                        />
                      )}
                      {isSuperAdmin && (
                        <Popconfirm
                          title="Are you sure you want to delete this artisan?"
                          onConfirm={() => onRemove(artisan.url)}
                          placement="topRight"
                        >
                          <img
                            className="groups--table-icon"
                            src="/images/delete_3x.svg"
                            alt=""
                            width="16"
                          />
                        </Popconfirm>
                      )}
                    </div>
                  </TableRow>
                )
              })
            }

            {
              total && (
                <TableFooter key="tableFooter" page={page} perPage={perPage} total={total} />
              )
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditArtisans
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

Artisans.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  getArtisans: PropTypes.func.isRequired,
  createArtisans: PropTypes.func.isRequired,
  updateArtisans: PropTypes.func.isRequired,
  deleteArtisans: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    ...state.artisans,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getArtisans: params => dispatch(getArtisansRequest(params)),
    createArtisans: data => dispatch(createArtisansRequest(data)),
    updateArtisans: (url, data) => dispatch(updateArtisansRequest(url, data, true)),
    deleteArtisans: (url, callback) => dispatch(deleteArtisansRequest(url, callback)),
  })
)(Artisans);

import { all, put, call, takeLatest } from "redux-saga/effects";

import seoApi from "../../api/seo";
import * as types from "./seoActionsTypes";


export function *get ({ params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(seoApi.get, params);

    yield put({ type: types.GET_SEO_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SEO_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING })
  }
}

export function *patch({ id, data, callback }) {
  yield put({ type: types.SHOW_PROCESSING });

  try {
    yield call(seoApi.patch, id, data);

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.SEO_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}


export default function *() {
  yield all([
    yield takeLatest(types.GET_SEO_REQUEST, get),
    yield takeLatest(types.PATCH_SEO_REQUEST, patch),
  ])
}

import React from "react";
import PropTypes from "prop-types";

import Header from "../shared/Header";
import Sidebar from "../shared/Sidebar/Sidebar";


function AdminLayout({ children }) {
  return (
    <div className="admin">
      <Sidebar />

      <div className="admin-content">
        <Header />

        {children}
      </div>
    </div>
  )
}

AdminLayout.propTypes = {
  children: PropTypes.any,
};

export default AdminLayout;

import { combineReducers } from "redux";

import auth from "./auth/authReducer";
import modal from "./modal/modalReducer";
import groups from "./groups/groupsReducer";
import artisans from "./artisans/artisansReducer";
import blogs from "./content/blog/blogReducer";
import pages from "./content/pages/pagesReducer";
import items from "./items/itemsReducer";
import dashboard from "./dashboard/dashboardReducer";
import catalog from "./catalog/catalogReducer";
import helpText from "./content/helptext/helpTextReducer";
import dealers from "./dealers/dealersReducer";
import orders from "./orders/ordersReducer";
import overview from "./invoices/overview/overviewReducer";
import invoiceList from "./invoices/invoicelist/invoiceListReducer";
import dealerCharges from "./invoices/dealercharges/dealerChargesReducer";
import enquiryActivity from "./enquiries/enquiryActivity/enquiryActivityReducer";
import emailEnquiries from "./enquiries/emailEnquiries/emailEnquiriesReducer";
import phoneEnquiries from "./enquiries/phoneEnquiries/phoneEnquiriesReducer";
import blacklistsEnquiries from "./enquiries/blacklistsEnquiries/blacklistsEnquiriesReducer";
import users from "./userManagement/users/usersReducer";
import emailProfiles from "./settings/emailProfiles/emailProfilesReducer";
import currencyCodes from "./settings/currency/currencyCodes/currencyCodesReducer";
import systemPreferences from "./settings/systemPreferences/systemPreferencesReducer";
import payments from "./settings/payments/paymentsReducer";
import offers from "./settings/offers/offersReducer";
import categories from "./categories/categoriesReducer";
import currencyRates from "./settings/currency/currencyRates/currencyRatesReducer";
import currencyFeed from "./settings/currency/currencyFeed/currencyFeedReducer";
import adminAccounts from "./settings/adminAccounts/adminAccountsReducer";
import homeAndLiving from "./homeAndLiving/homeAndLivingReducer";
import periods from "./periods/periodsReducer";
import seo from "./seo/seoReducer";
import analytics from "./analytics/analyticsReducer";
import website from "./website/websiteReducer";


// Combine reducer into single one
const rootReducer = combineReducers({
  auth,
  modal,
  groups,
  artisans,
  blogs,
  pages,
  items,
  dashboard,
  catalog,
  helpText,
  dealers,
  orders,
  overview,
  invoiceList,
  dealerCharges,
  enquiryActivity,
  emailEnquiries,
  phoneEnquiries,
  blacklistsEnquiries,
  users,
  emailProfiles,
  offers,
  currencyCodes,
  systemPreferences,
  payments,
  categories,
  currencyRates,
  currencyFeed,
  adminAccounts,
  homeAndLiving,
  periods,
  seo,
  analytics,
  website,
});

export default rootReducer;

import * as types from "./dashboardActionTypes";


const initialState = {
  users: [],
  searchResult: null,
  processing: false
};


const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case types.GET_USERS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.ELASTIC_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
      };

    case types.ELASTIC_SEARCH_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case types.TOGGLE_ELASTIC_SEARCH_PROCESSING:
      return {
        ...state,
        processing: !state.processing
      }

    default:
      return state;
  }
};

export default dashboard;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import navigation from "../../../../helpers/navigation";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";

import { Utils } from "../../../../helpers";
import {
  getCurrencyFeedRequest,
} from "../../../../redux/settings/currency/currencyFeed/currencyFeedActions";
import TableFooter from "../../../shared/Table/TableFooter";


function CurrencyFeedHistory({ items, totalItems, getCurrencyFeed }) {
  const location = useLocation();
  const { page, perPage, order } = navigation.getTableQueryParams(location);


  // Pagination api call here
  useEffect(() => {
    getCurrencyFeed({ page, perPage });
  }, [page, perPage]); // eslint-disable-line


  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="currency">
      <Table
        columnWidths={["90px", "90px", "150px", "120px", "75px", "90px", "auto"]}
        headerCells={[
          { label: "Run ID" },
          { label: "Type" },
          { label: "Date" },
          { label: "TimeStamp" },
          { label: "Base" },
          { label: "EUR" },
          { label: "GBP" },
        ]}
      >
        {
          itemsToRender.map(item => {
            return (
              <TableRow key={`feed:${item.id}`}>
                <div className="flex table-sm-text">{item.id}</div>
                <div className="flex table-sm-text">{item.type}</div>
                <div className="flex table-sm-text">{moment(item.createdAt).format("YYYY/MM/DD HH:mm:ss")}</div>
                <div className="flex table-sm-text">{Math.floor((new Date().getTime()) / 1000)}</div>
                <div className="flex table-sm-text">{item.base.currency}</div>
                <div className="flex table-sm-text">{item.eur.toFixed(6)}</div>
                <div className="flex table-sm-text">{item.gbp.toFixed(6)}</div>
              </TableRow>
            )
          })
        }
      </Table>

      {
        totalItems && (
          <TableFooter
            page={page}
            perPage={perPage}
            total={totalItems}
          />
        )
      }
    </div>
  )
}

CurrencyFeedHistory.propTypes = {
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  getCurrencyFeed: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    items: state.currencyFeed.items,
    totalItems: state.currencyFeed.total,
  }),
  dispatch => ({
    getCurrencyFeed: params => dispatch(getCurrencyFeedRequest(params)),
  })
)(CurrencyFeedHistory);

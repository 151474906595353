import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/fairs/${id}/`, method: "GET" });
};

export const useFair = (id, options) => {
  return useQuery(["fair", Number(id)], () => fetcher(id), {
    ...options,
    select: (resp) => {
      const newResp = { ...resp, isFairEntry: true };

      if (typeof options?.select === "function") {
        return options.select(newResp);
      }

      return newResp;
    },
  });
};

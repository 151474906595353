import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popconfirm } from "antd";


export const StatusColumn = ({ currentStatus, statuses = [], direction = "vertical", onClick }) => {
  const isInteraction = typeof onClick === "function";

  return (
    <div
      className={classNames("status-panel", "enabled", direction)}
      {...(isInteraction && { onClick: (evt) => evt.stopPropagation() })}
    >
      {statuses.map(({ status, title = "Are you sure you want to change status?" }) => {
        const isClickable = currentStatus && currentStatus !== status && isInteraction;
        const label = status.toLowerCase();

        return (
          <div
            key={status}
            className={classNames(
              "status-panel--item",
              isClickable ? "clickable" : "no-clickable",
              "shorter",
              status === currentStatus && currentStatus?.toLowerCase()
            )}
          >
            <Popconfirm
              title={title}
              onConfirm={() => onClick(status)}
              disabled={!isClickable || !isInteraction}
              overlayStyle={{ maxWidth: "400px" }}
            >
              <div>{label}</div>
            </Popconfirm>
          </div>
        );
      })}
    </div>
  )
};

StatusColumn.propTypes = {
  currentStatus: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string.isRequired,
    title: PropTypes.string,
  })).isRequired,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  onClick: PropTypes.func,
};

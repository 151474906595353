import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Button, Input } from "antd";

import LabeledInput from "../../../shared/LabeledInput";

import {
  createDealerSpecialyRequest,
  updateDealerSpecialityRequest,
  resetErrorAction,
} from "../../../../redux/dealers/dealersActions";


const AddEditDealerSpeciality = ({
  error,
  processing,
  history,
  createDealerSpecialy,
  dealerSpecialities,
  updateDealerSpeciality,
  resetError,
}) => {
  const [isActive, setIsActive] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { id } = useParams();

  const err = typeof error === "object" ? error : {};
  const isEditing = !!id;
  const specEditing = dealerSpecialities?.find((specItem) => specItem.id === +id);

  const [title, setTitle] = useState(isEditing && specEditing ? specEditing.title : "");
  const [name, setName] = useState(isEditing && specEditing ? specEditing.name : "");
  const [description, setDescription] = useState(isEditing && specEditing ? specEditing.description : "");

  // Clear server errors after leaving the page
  useEffect(() => {
    return resetError;
  }, []); // eslint-disable-line

  // Mark form as submitted
  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    history.push("/dealers/dealer-specialities/");
  }, [formSubmitted, processing, error]); // eslint-disable-line


  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createDealerSpecialy = () => {
    createDealerSpecialy({
      isActive,
      title,
      name,
      description
    });
  };

  const onUpdateDealerSpecialy = () => {
    updateDealerSpeciality({
      data: {
        isActive,
        title,
        name,
        description
      },
      url: specEditing?.url ?? "",
    });
  };

  const onSaveClick = isEditing ? onUpdateDealerSpecialy : _createDealerSpecialy;

  const renderPageTitle = () => (
    <div className="add-dealer-speciality--row">
      <div className="add-dealer-speciality--title">{isEditing ? "Edit Speciality" : "Add New Speciality"}</div>
    </div>
  );

  const renderSpecialityCheckbox = () => (
    <div className="add-dealer-speciality--row checkbox-holder">
      <Checkbox
        className="checkbox-small"
        checked={isActive}
        onChange={onCheck}
      >
        Active
      </Checkbox>
    </div>
  );

  const renderSpecialityTitleField = () => (
    <div className="add-dealer-speciality--title-field">
      <LabeledInput
        label="Title"
        isRequired
        value={title}
        onChange={setTitle}
        hasError={err && err.title}
        errorText={err?.title && err.title[0]}
      />
    </div>
  );

  const renderSpecialityNameField = () => (
    <div className="add-dealer-speciality--name-field">
      <LabeledInput
        label="Name"
        isRequired
        value={name}
        onChange={setName}
        hasError={err && err.name}
        errorText={err?.name && err.name[0]}
      />
    </div>
  );

  const renderSpecialityPageContentField = () => (
    <>
      <div className="add-dealer-speciality--description-field-label">
        Description
      </div>
      <Input.TextArea
        value={description}
        onChange={({ target }) => setDescription(target.value)}
        id="add-dealer-speciality--description-field"
      />
    </>
  );

  const renderPageActionButtons = () => (
    <div className="add-dealer-speciality--button-holder">
      <Button
        className="ant-btn-primary add-dealer-speciality--save-and-close-btn"
        onClick={onSaveClick}
      >
        SAVE & CLOSE
      </Button>
      <div className="del-btn-holder">
        <Button
          className="btn-secondary add-dealer-speciality--delete-btn"
          onClick={() => history.push("/dealers/dealer-specialities/")}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );

  if (isEditing && !specEditing) return null;

  return (
    <div className="add-dealer-speciality">
      {renderPageTitle()}
      {renderSpecialityCheckbox()}
      {renderSpecialityTitleField()}
      {renderSpecialityNameField()}
      {renderSpecialityPageContentField()}
      {renderPageActionButtons()}
    </div>
  )
};

AddEditDealerSpeciality.propTypes = {
  createDealerSpecialy: PropTypes.func.isRequired,
  error: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  dealerSpecialities: PropTypes.array.isRequired,
  updateDealerSpeciality: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default connect(
  state => state.dealers,
  dispatch => ({
    createDealerSpecialy: payload => dispatch(createDealerSpecialyRequest(payload)),
    updateDealerSpeciality: payload => dispatch(updateDealerSpecialityRequest(payload)),
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddEditDealerSpeciality);

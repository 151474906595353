import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Checkbox, Button } from "antd";
import { Link } from "react-router-dom";

import { loginRequest, clearError } from "../../redux/auth/authActions";


const Login = ({ isProcessing, error, login, clearAuthError }) => {
  // Component will mount
  useEffect(() => clearAuthError, []); // eslint-disable-line

  const validate = values => {
    const errors = {};

    if (!values.email) errors.general = "Email is required";
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) errors.general = "Invalid email address";

    if (!values.password) errors.general = "Password cannot be empty";

    return errors;
  };


  return (
    <div className="auth">
      <div className="auth-block">
        <img className="auth-block--logo" src="/images/cms@3x.svg" width="144" height="32" alt="" />

        <Formik
          initialValues={{ email: "dev-antique@gmail.com", password: "", remember: false }}
          validate={validate}
          onSubmit={login}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formikProps => (
            <Form className="auth-block--form" onSubmitCapture={formikProps.handleSubmit}>
              {
                (formikProps.errors.general || error) && (
                  <div className="auth-block--form-error">
                    <img className="auth-block--form-error-icon" src="/images/warning@3x.svg" alt="" />
                    {formikProps.errors.general || error}
                  </div>
                )
              }

              <div className="auth-block--input-header">
                <label className="auth-block--input-label">Username</label>
              </div>
              <Field type="email" name="email" className="auth-block--form-input" />

              <div className="auth-block--input-header">
                <label className="auth-block--input-label">Password</label>

                <Link className="auth-block--input-label auth-block--input-label__highlighted" to="/auth/forgot-password">
                  Forgotten Password?
                </Link>
              </div>
              <Field type="password" name="password" className="auth-block--form-input" />

              <Field name="remember" render={({ field }) => {
                const { value, ...innerProps } = field;

                return (
                  <Checkbox className="auth-block--form-checkbox" checked={value} {...innerProps}>Remember me</Checkbox>
                )
              }} />

              <Button
                className="auth-block--form-submit"
                type="primary"
                htmlType="submit"
                disabled={isProcessing}
                loading={isProcessing}
                onClick={formikProps.handleSubmit}
              >
                {isProcessing ? "" : "LOGIN" }
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

Login.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  clearAuthError: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default connect(
  state => ({ isProcessing: state.auth.processing, error: state.auth.error }),
  dispatch => ({
    login: data => dispatch(loginRequest(data)),
    clearAuthError: () => dispatch(clearError())
  }),
)(Login);

export const SHOW_PROCESSING = "SHOW_PERIODS_PROCESSING";
export const HIDE_PROCESSING = "HIDE_PERIODS_PROCESSING";

export const PERIODS_REQUEST_ERROR = "PERIODS_REQUEST_ERROR";
export const RESET_ERROR = "RESET_PERIODS_ERROR";

export const GET_PERIODS_REQUEST = "GET_PERIODS_REQUEST";
export const GET_PERIODS_REQUEST_SUCCESS = "GET_PERIODS_REQUEST_SUCCESS";

export const CREATE_PERIODS_REQUEST = "CREATE_PERIODS_REQUEST";
export const UPDATE_PERIODS_REQUEST = "UPDATE_PERIODS_REQUEST";
export const DELETE_PERIODS_REQUEST = "DELETE_PERIODS_REQUEST";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";


const ArticlePreview = ({ currentArticle }) => {
  const {
    title,
    pageContent,
    createdAt,
  } = currentArticle;

  useEffect(() => {
    const contentBlock = document.getElementById("article-preview--content-row");
    contentBlock.innerHTML = pageContent;
  }, [currentArticle]); // eslint-disable-line

  const renderPostedDate = () => {
    const postDate = moment(createdAt).format("MMMM D, Y");

    return (
      <div>{`Posted ${postDate}`}</div>
    )
  }

  return (
    <div className="article-preview">
      <div className="article-preview--title">{title}</div>
      <div className="article-preview--posted-date">
        {renderPostedDate()}
      </div>
      <div id="article-preview--content-row" />
    </div>
  );
};

ArticlePreview.propTypes = {
  currentArticle: PropTypes.object.isRequired,
};

export default connect(
  state => state.blogs
)(ArticlePreview);

import fetch from "./customFetch";


const analytics = {
  async getEnquiriesAnalytics(period = "all_time") {
    try {
      return await fetch({
        url: `/analytics/enquiries/?period=${period}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getItemsAnalytics(period = "week") {
    try {
      return await fetch({
        url: `/analytics/approved-items/?period=${period}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getDealerAnalyticsRequest({ url, period = "week" }) {
    try {
      return await fetch({
        url: `/analytics/dealers/${url}/?period=${period}`,
        method: "GET",
      });
    } catch (err) {
      if (err && err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default analytics;

import * as types from "./dealerChargesActionsTypes";

const initialState = {
  items: [],
  totalItems: 0,
  error: null,
  processing: false,
};

const dealerCharges = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICE_DEALER_CHARGES_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        totalItems: action.payload.count
      };

    case types.GET_INVOICE_DEALER_CHARGES_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default dealerCharges;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Avatar } from "antd";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import lodash from "lodash";

import GlobalSearch from "../Dashboard/GlobalSearch";
import SearchResults from "../Dashboard/SearchResults";
import BreadCrumbs from "./Breadcrumbs";

import { logoutRequest, userProfileRequest } from "../../redux/auth/authActions"
import { UserOutlined } from "@ant-design/icons";
import { buildFullName } from "../../helpers";

const logoutIcon = "/images/logout_3x.svg";


const Header = ({ logout, getUserProfile, userProfile }) => {
  const [showSearchResult, setShowSearchResult] = useState(false);
  const searchRef = useRef();

  useEffect(() => {
    getUserProfile();
  }, []); // eslint-disable-line

  // Hide search on click outside
  const onClick = e => {
    if (!searchRef.current || searchRef.current?.contains(e.target)) return;

    setShowSearchResult(false);
  }
  const memoizedOnClick = useCallback(onClick, []);

  useEffect(() => {
    if (showSearchResult) {
      document.addEventListener("click", memoizedOnClick);
    } else {
      document.removeEventListener("click", memoizedOnClick);
    }
  }, [showSearchResult]); // eslint-disable-line

  const userName = buildFullName(userProfile);

  return (
    <div className="header">
      <div className="header--part">
        <BreadCrumbs />
      </div>

      <div className="flex justify-end">
        <div className="header--part">
          <div className="search-results" ref={searchRef}>
            <GlobalSearch setShowSearchResult={setShowSearchResult} />
            {showSearchResult && <SearchResults />}
          </div>
        </div>

        <div className="header--part">
          <Avatar {...(userProfile?.image?.url ? { src: userProfile?.image?.url } : { icon: <UserOutlined /> })} />

          <div className="header--user-info">
            <span className="header--user-text header--user-text__bold">{userName}</span>
            <span className="header--user-text">
              {lodash.get(userProfile, "roles[0]", "SUPER ADMIN")}
            </span>
          </div>

          <img
            className="header--logout"
            src={logoutIcon}
            width="15"
            height="16"
            alt=""
            onClick={logout}
          />
        </div>
      </div>
    </div>
  )
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.any
};

export default connect(
  state => state.auth,
  dispatch => ({
    logout: () => dispatch(logoutRequest()),
    getUserProfile: () => dispatch(userProfileRequest())
  })
)(Header);

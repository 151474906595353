import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Button, Spin } from "antd";

import LabeledInput from "../../shared/LabeledInput";
import InfoIcon from "../../Icon/InfoIcon";
import HelpIcon from "../../Icon/HelpIcon";

import {
  createHelpTextItemRequest,
  getHelpTextChoicesRequest
} from "../../../redux/content/helptext/helpTextActions";


const AddHelpTextItem = ({
  error,
  processing,
  createHelpTextItem,
  getHelpTextChoices,
  helpTypeChoices = {},
  history,
  loading
}) => {
  const [isActive, setIsActive] = useState(true);
  const [helpId, setHelpId] = useState("");
  const [appearOn, setAppearOn] = useState("");
  const [helpType, setHelpType] = useState();
  const [icon, setIcon] = useState();
  const [helpTitle, setHelpTitle] = useState("PayPal");
  const [helpText, setHelpText] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const serverError = error || {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    getHelpTextChoices({ helpTitle });
  }, [helpTitle]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    history.push({ pathname: "/content/help-text/" });
  }, [error, processing]); // eslint-disable-line

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createHelpTextItem = () => {
    createHelpTextItem({
      isActive,
      helpId,
      helpType,
      icon,
      helpTitle,
      helpText
    });
  };

  const renderHelpTypeSelect = () => {
    const options = helpTypeChoices && Object.keys(helpTypeChoices).map((key) => (
      {
        label: helpTypeChoices[key],
        value: key,
      }
    ));

    return (
      <div className="add-help-text-item--help-type-select">
        <p className="add-help-text-item--help-type-select-label">
          Help Type
        </p>
        <LabeledInput
          className="add-help-text-item--icon-select"
          type="select"
          value={helpType}
          options={options || []}
          onChange={setHelpType}
          horizontal
        />
      </div>
    );
  };

  const renderIconSelect = () => {
    // TODO: To add icon field on backend & display iconChoices
    const iconChoices = [{
      label: "No Icon",
      value: "No Icon",
    },
    {
      label: "Help",
      value: "HELP",
      icon: HelpIcon
    },
    {
      label: "Info",
      value: "INFO",
      icon: InfoIcon
    }];

    return (
      <div className="add-help-text-item--icon-select">
        <p className="add-help-text-item--icon-select-label">
          Icon
        </p>
        <LabeledInput
          className="add-help-text-item--icon-select"
          type="select"
          value={icon}
          options={iconChoices || []}
          onChange={setIcon}
          horizontal
        />
      </div>
    );
  };

  return (
    <div className="add-help-text-item">
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className="add-help-text-item--row">
            <span className="add-help-text-item--title">Add New Item</span>
          </div>

          <div className="add-help-text-item--row checkbox-holder">
            <Checkbox
              className="checkbox-small"
              checked={isActive}
              onChange={onCheck}
            >
              Active
            </Checkbox>
          </div>

          <LabeledInput
            label="Help ID"
            isRequired
            value={helpId}
            onChange={setHelpId}
            errorText={serverError.helpId}
          />
          <div className="add-help-text-item--url-input-holder">
            <LabeledInput
              label="Appear On"
              value={appearOn}
              suffix={urlFieldImage}
              onChange={setAppearOn}
            />
          </div>

          <div className="add-help-text-item--help-type-and-icon-row">
            {renderHelpTypeSelect()}
            {renderIconSelect()}
          </div>

          <div className="add-help-text-item--url-input-holder">
            <LabeledInput
              label="helpTitle"
              value={helpTitle}
              onChange={setHelpTitle}
            />
          </div>

          <LabeledInput
            label="Help Text"
            type="rich-text"
            value={helpText}
            onChange={setHelpText}
            className="add-help-text-item--help-text-content-input"
          />

          <div className="add-help-text-item--button-holder">
            <Button className="ant-btn-primary" onClick={_createHelpTextItem}>
              SAVE & CLOSE
            </Button>
            <div className="del-btn-holder">
              <Button
                className="btn-secondary"
                onClick={() => history.push({ pathname: "/content/help-text" })}
              >
                <img src="/images/delete_3x.svg" alt="" />
                DELETE ITEM
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

AddHelpTextItem.propTypes = {
  createHelpTextItem: PropTypes.func.isRequired,
  error: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  getHelpTextChoices: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  helpTypeChoices: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  state => state.helpText,
  dispatch => ({
    createHelpTextItem: payload => dispatch(createHelpTextItemRequest(payload)),
    getHelpTextChoices: payload => dispatch(getHelpTextChoicesRequest(payload)),
  })
)(AddHelpTextItem);

import { all, put, call, takeLatest } from "redux-saga/effects";

import catalog from "../../api/catalog";
import * as types from "./catalogActionTypes";
import navigation from "../../helpers/navigation";


export function* getEntityElements({ entityUrl, params }) {
  yield put({ type: types.SHOW_LOADING });

  try {
    let response = {};
    if (params.search) {
      response = yield call(catalog.searchEntityElements, entityUrl, params);
    } else {
      response = yield call(catalog.getEntityElements, entityUrl, params);
    }

    yield put({ type: types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST_SUCCESS, payload: { url: entityUrl, response } });
  } catch (error) {
    yield put({ type: types.CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

export function* create({ entityUrl, data, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(catalog.createEntityElement, entityUrl, data);

    yield put({ type: types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST, entityUrl, params: { page: 1, perPage: 10 } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export function* update({ entityUrl, itemUrl, data, isPatch, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) {
      yield call(catalog.updatePatchEntityElement, entityUrl, itemUrl, data);
    } else {
      yield call(catalog.updatePutEntityElement, entityUrl, itemUrl, data);
    }

    yield put({ type: types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST, entityUrl, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export function* remove({ entityUrl, itemUrl, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(catalog.deleteEntityElement, entityUrl, itemUrl);

    yield put({ type: types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST, entityUrl, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.CATALOG_ENTITY_ELEMENTS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST, getEntityElements),
    yield takeLatest(types.CREATE_CATALOG_ENTITY_ELEMENT_REQUEST, create),
    yield takeLatest(types.UPDATE_CATALOG_ENTITY_ELEMENT_REQUEST, update),
    yield takeLatest(types.DELETE_CATALOG_ENTITY_ELEMENT_REQUEST, remove),
  ])
}

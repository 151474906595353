import { Form, Modal, Spin } from "antd";
import React, { useState } from "react";

// components
import { PromoCodeForm } from "./PromoCodeForm";
import AddButton from "../../../shared/AddButton";

// react-queries
import { usePromoCodesCreate } from "../../../../react-query/directory/usePromoCodesCreate";

const formatErrors = (error) => {
  return Object.keys(error?.data || {}).map((key) => ({
    name: key,
    errors: error.data[key],
  }));
}

export const CreatePromoCode = () => {
  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const { mutate, isLoading } = usePromoCodesCreate({
    onSuccess: () => {
      toggleModal();
      
      form.resetFields();
    },
    onError: (error) => {
      const formattedErrors = formatErrors(error);

      form.setFields(formattedErrors);
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const data = {
        ...values,
        activeTo: values.activeTo.format("YYYY-MM-DD"),
      };

      mutate(data);
    });
  };

  return (
    <>
      <div className="component--header">
        <AddButton onClick={toggleModal} />
      </div>
      <Modal open={isOpen} onCancel={toggleModal} footer={null}>
        <Spin spinning={isLoading}>
          <PromoCodeForm
            form={form}
            title="Create Promo Code"
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

import { useMutation } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (data) => {
  return fetch({ url: `/dealers/url/${data.url}/`, method: "PATCH", data });
};

export const useDealerProfilePatch = (options) => {
  return useMutation(fetcher, options);
};

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Select, Spin, Tooltip } from "antd";
import { useQueryClient } from "react-query";

// components
import AddButton from "../../shared/AddButton";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import SearchInput from "../../shared/SearchInput";
import { ConfirmDeleteModal, StatusColumn } from "../../../components";
import { InlineIcon } from "../../../icons";
import { AuthorSelectWithSearch } from "../components/AuthorSelectWithSearch";

// react-queries
import {
  useDirectoryCategoryList,
  useDirectoryEntryList,
  useDirectoryEntryPatch,
  useDirectoryEntryDelete
} from "../../../react-query";

// hooks
import { useDebounce } from "../../../hooks";

// helpers
import navigation from "../../../helpers/navigation";
import { buildFullName } from "../../../helpers";

// constants
import {
  ALL_DIRECTORY_CATEGORIES_PARAMS,
  DIRECTORY_ENTRY_STATUSES,
  DIRECTORY_STATUS_OPTIONS,
  DIRECTORY_UPGRADED_TO_PREMIUM_OPTIONS,
} from "../../../constants";


export const DirectoryEntries = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const { page, perPage } = navigation.getTableQueryParams(location);
  const [search, setSearch] = useState("");
  const [author, setAuthor] = useState("");
  const [premium, setPremium] = useState("");
  const [upgradedToPremium, setUpgradedToPremium] = useState("");
  const [category, setCategory] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);

  const deleteItem = useDirectoryEntryDelete({
    onSuccess: () => setDeletingItem(null),
    onError: (err) => console.error(err)
  });

  const onDelete = () => {
    deleteItem.mutate(deletingItem.id)
  }

  const updateStateValue = (setState) => (value) => {
    page !== 1 && navigation.setPage(1, location, history);

    setState(value);
  };

  const debouncedSearch = useDebounce(search, 400);

  const { data: categoriesOptions = [] } = useDirectoryCategoryList(ALL_DIRECTORY_CATEGORIES_PARAMS, {
    select: (resp) => resp.results.map((el) => ({ value: el.id, label: el.title })),
    staleTime: Infinity
  });

  const { data: directoryEntries, isFetching } = useDirectoryEntryList({
    page: page,
    page_size: perPage,
    author: author,
    premium: premium,
    is_manually_upgraded: upgradedToPremium,
    category: category,
    name: debouncedSearch,
  });

  const patchDirectoryEntry = useDirectoryEntryPatch({
    onSuccess: () => {
      queryClient.invalidateQueries(["directory-list"]);
    }
  });

  const updateStatus = (item, status) => {
    patchDirectoryEntry.mutate({
      id: item.id,
      status
    });
  }

  const redirectToCreate = () => {
    history.push({ pathname: "/directory/directory-entries/add" });
  };

  const redirectToEdit = (item, tab) => {
    history.push({
      pathname: `/directory/directory-entries/${item.id}/${tab}`,
      state: { path: item.name }
    });
  };

  return (
    <div className="directory-entries--page">
      <div className="component--header">
        <AddButton onClick={redirectToCreate} />
      </div>

      <div className="flex justify-between mb-32">
        <div className="list-of-orders--sort-by-dealer-btn">
          <p className="list-of-orders--dealer-btn-label">
            Is Premium
          </p>
          <Select
            placeholder="Premium status"
            style={{ width: "110px" }}
            options={DIRECTORY_STATUS_OPTIONS}
            value={premium}
            onChange={updateStateValue(setPremium)}
          />
        </div>
        <div className="list-of-orders--sort-by-dealer-btn">
          <p className="list-of-orders--dealer-btn-label">
            Upgrade to Premium
          </p>
          <Select
            placeholder="Upgrade type"
            style={{ width: "110px" }}
            options={DIRECTORY_UPGRADED_TO_PREMIUM_OPTIONS}
            value={upgradedToPremium}
            onChange={updateStateValue(setUpgradedToPremium)}
          />
        </div>
        <div className="list-of-orders--sort-by-dealer-btn">
          <p className="list-of-orders--dealer-btn-label">
            Category
          </p>
          <Select
            placeholder="All Categories"
            style={{ width: "160px" }}
            options={categoriesOptions}
            value={category}
            allowClear
            onChange={updateStateValue(setCategory)}
          />
        </div>
        <AuthorSelectWithSearch placeholder="Filter by author" onSelect={updateStateValue(setAuthor)} />
      </div>

      <SearchInput
        placeholder="Type to search by name"
        style={{ width: "200px", margin: "-16px 0 32px" }}
        onChange={updateStateValue(setSearch)}
      />

      <Spin spinning={isFetching}>
        <Table
          className="crm-table directory-entries--table"
          columnWidths={["120px", "auto", "auto", "auto", "220px", "220px", "70px"]}
          headerCells={[
            { label: "STATUS" },
            { label: "NAME" },
            { label: "PREMIUM STATUS" },
            { label: "CATEGORIES" },
            { label: "CREATED BY" },
            { label: "OWNER" },
            { label: "ACTIONS" },
          ]}
        >
          {directoryEntries?.results?.map((item) => {
            return (
              <TableRow
                key={`group:${item.id}`}
                style={{ fontSize: "12px", cursor: "pointer" }}
                onClick={() => redirectToEdit(item, "status")}
              >
                <div className="flex pl-16">
                  <StatusColumn
                    key={`status-column-${item.id}`}
                    {...item}
                    currentStatus={item.status}
                    statuses={DIRECTORY_ENTRY_STATUSES}
                    onClick={(status) => updateStatus(item, status)}
                  />
                </div>
                <div className="flex">
                  {item.name}
                </div>
                <div className="flex">
                  {item.isPremium
                    ? `Premium (${
                      item.isManuallyUpgraded
                        ? "Manually"
                        : "Automatically"
                      } upgraded)`
                    : "Not Premium"
                  }
                </div>
                <div className="flex">
                  {item.categories?.map(({ title, isActive }) => isActive ? (
                    <div key={`${item.id}-${title}`} className="lant-table--tag thin category">
                      {title}
                    </div>
                  ) : (
                    <Tooltip key={`${item.id}-${title}`} title="This category is inactive">
                      <div key={`${item.id}-${title}`} className="lant-table--tag thin category disabled">
                        {title}
                      </div>
                    </Tooltip>
                  ))}
                </div>
                <div className="flex">
                  <div className="detail-column">
                    <div className="detail-column--business-name">
                      {buildFullName(item.author)}
                    </div>
                    <div className="detail-column--text">
                      {item.author?.email || "-"}
                    </div>
                  </div>
                </div>
                {item.owner?.id ? (
                  <div className="flex">
                    <div className="detail-column">
                      <div className="detail-column--business-name">
                        {item.owner?.businessName || buildFullName(item.owner?.user)}
                      </div>
                      <div className="detail-column--text">
                        {item.owner?.user?.email || "-"}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">-</div>
                )}
                <div
                  className="flex lant-table--actions-column"
                  onClick={(evt) => evt.stopPropagation()}
                >
                  <InlineIcon type="edit" onClick={() => redirectToEdit(item, "details")} />
                  <InlineIcon type="delete" danger onClick={() => setDeletingItem(item)} />
                </div>
              </TableRow>
            )
          })}
        </Table>
      </Spin>

      {directoryEntries?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={directoryEntries.count}
        />
      )}

      <ConfirmDeleteModal
        title="Delete directory entry"
        open={!!deletingItem?.id}
        processing={deleteItem.isLoading}
        closeModal={() => setDeletingItem(null)}
        onConfirm={onDelete}
      />
    </div>
  );
};

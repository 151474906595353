import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Modal as ModalComponent } from "antd";

import { closeModal } from "../../redux/modal/modalActions";


class Modal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.any,
    props: PropTypes.object,
    close: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.modalTarget = document.getElementById("modal");
    this.el = document.createElement("div");
  }

  componentDidMount() {
    this.modalTarget.appendChild(this.el);
  }

  componentWillUnmount() {
    this.el.removeEventListener("click", this.props.close);
    this.modalTarget.removeChild(this.el);
  }

  render() {
    const { isOpen, content, close, props } = this.props;

    if (!isOpen) return null;

    return ReactDOM.createPortal(
      <ModalComponent
        className="modal-block"
        centered
        visible={isOpen}
        onCancel={close}
        { ...props }
      >
        { content }
      </ModalComponent>,
      this.el,
    );
  }
}

export default connect(
  state => state.modal,
  dispatch => ({ close: bindActionCreators(closeModal, dispatch) })
)(Modal);


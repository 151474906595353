import React from "react";
import { ConfigProvider } from "antd";
import PropTypes from "prop-types";

const GLOBAL_CONFIG_VALIDATE_MESSAGES = {
  // required: "'${label}' is required",
  types: {
    email: "Invalid email address",
    url: "Please enter a valid URL"
  }
};

export const AntdConfigProvider = ({ children, ...props }) => {
  ConfigProvider.config({
    theme: {
      primaryColor: "#ff0000"
    }
  });

  return (
    <ConfigProvider
      form={{ validateMessages: GLOBAL_CONFIG_VALIDATE_MESSAGES }}
      theme={{
        token: {
          borderRadius: 4
        },
        components: {
          DatePicker: {
            fontWeightStrong: 500
          }
        }
      }}
      {...props}
    >
      {children}
    </ConfigProvider>
  );
};

AntdConfigProvider.propTypes = {
  children: PropTypes.node,
};

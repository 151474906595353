export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_REQUEST_SUCCESS = "GET_USERS_LIST_REQUEST_SUCCESS";
export const GET_USERS_LIST_REQUEST_ERROR = "GET_USERS_LIST_REQUEST_ERROR";

export const GET_SUBSCRIBERS_LIST_REQUEST = "GET_SUBSCRIBERS_LIST_REQUEST";
export const GET_SUBSCRIBERS_LIST_SUCCESS = "GET_SUBSCRIBERS_LIST_SUCCESS";
export const GET_SUBSCRIBERS_LIST_ERROR = "GET_SUBSCRIBERS_LIST_ERROR";

export const PATCH_USER_REQUEST = "PATCH_USER_REQUEST";
export const PATCH_USER_REQUEST_ERROR = "PATCH_USER_REQUEST_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_ERROR = "DELETE_USER_REQUEST_ERROR";

export const DELETE_ANONYMOUS_SUBSCRIBER_REQUEST = "DELETE_ANONYMOUS_SUBSCRIBER_REQUEST";

export const CONVERT_USER_ROLE_REQUEST = "CONVERT_USER_ROLE_REQUEST";
export const CONVERT_USER_ROLE_SUCCESS = "CONVERT_USER_ROLE_SUCCESS";
export const CONVERT_USER_ROLE_FAILED = "CONVERT_USER_ROLE_FAILED";

export const SHOW_USERS_PROCESSING = "SHOW_USERS_PROCESSING";
export const HIDE_USERS_PROCESSING = "HIDE_USERS_PROCESSING";

export const SHOW_USERS_LOADING = "SHOW_USERS_LOADING";
export const HIDE_USERS_LOADING = "HIDE_USERS_LOADING";

export const RESET_ERRORS = "RESET_USERS_ERRORS";

import React, { useState } from "react";
import PropTypes from "prop-types";

const searchLogo = "/images/search_3x.svg";


function SearchInput({ value = "", onChange, placeholder = "Type to search", ...rest }) {
  const [searchValue, setSearchValue] = useState(value);

  const _onChange = event => {
    const newValue = event.target.value;

    onChange(newValue);
    setSearchValue(newValue)
  };

  return (
    <div className="search-input" {...rest}>
      <img className="search-input--icon" src={searchLogo} alt="search" />
      <input className="search-input--input" value={searchValue} onChange={_onChange} placeholder={placeholder} />
    </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SearchInput

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox } from "antd";

import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import navigation from "../../../helpers/navigation";

import {
  getSpamListRequest,
} from "../../../redux/enquiries/blacklistsEnquiries/blacklistsEnquiriesActions";
import {
  patchEmailEnquiriesRequest,
} from "../../../redux/enquiries/emailEnquiries/emailEnquiriesActions";

const Spam = ({
  location,
  history,
  getSpamList,
  spamList,
  spamListTotal,
  patchEmailEnquiries
}) => {
  const { search, page, perPage } = navigation.getTableQueryParams(location);

  useEffect(() => {
    getSpamList({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line

  const onSearch = (value) => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const renderSpamHeader = () => (
    <div className="spam-page--header">
      <SearchInput value={search} onChange={onSearch} />
    </div>
  );

  const handleCheckboxClick = (id, isSpam) => {
    patchEmailEnquiries(id, { isSpam });
  }

  const renderSpamTable = () => {
    return (
      <div className="spam-page--table">
        <Table
          columnWidths={["40px", "80px", "30%", "auto", "9%", "9%"]}
          headerCells={[
            {},
            { label: "ID" },
            { label: "value" },
            { label: "TextMail" },
            { label: "Origin" },
            { label: "dfa" },
          ]}
        >
          {spamList.map(({ id, customerEmail, message, isSpam }) => (
            <TableRow key={`spam-page--table-row-${id}`}>
              <Checkbox
                checked={isSpam}
                onChange={({ target }) => handleCheckboxClick(id, target.checked)}
              />
              <div className="spam-page--table--id-field flex">{id}</div>
              <div className="spam-page--table--value-field flex">{customerEmail}</div>
              <div className="spam-page--table--text-mail-field flex">{message}</div>
              <div className="spam-page--table--origin-field flex">{id}</div>
              <div className="spam-page--table--dfa-field flex">{id}</div>
            </TableRow>
          ))}

          {spamListTotal && (
            <TableFooter page={page} perPage={perPage} total={spamListTotal} />
          )}
        </Table>
      </div>
    )
  }

  return (
    <div className="spam-page">
      {renderSpamHeader()}
      {renderSpamTable()}
    </div>
  )
}

Spam.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getSpamList: PropTypes.func.isRequired,
  spamList: PropTypes.array.isRequired,
  spamListTotal: PropTypes.number.isRequired,
  patchEmailEnquiries: PropTypes.func.isRequired,
};

export default connect(
  state => state.blacklistsEnquiries,
  dispatch => ({
    getSpamList: params => dispatch(getSpamListRequest(params)),
    patchEmailEnquiries: params => dispatch(patchEmailEnquiriesRequest(params)),
  })
)(Spam);

import React from "react";
import { useHistory } from "react-router-dom";
import { Form, message } from "antd";
import lodash from "lodash";

// components
import { DirectoryCategoryForm } from "../components/DirectoryCategoryForm";

// react-queries
import { useDirectoryCategoryCreate } from "../../../react-query";

// helpers
import { setServerErrorsToForm } from "../../../helpers";


export const AddDirectoryCategory = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const redirectToList = () => {
    history.replace("/directory/directory-categories");
  };

  const createDirectoryCategory = useDirectoryCategoryCreate({
    onSuccess: () => {
      message.success("Category was successfully created");

      redirectToList();
    },
    onError: (err) => {
      const obj = {};
      if (err?.data?.title?.title) {
        obj.title = err.data.title.title;
      } else if (err?.data?.url?.url) {
        obj.url = err.data.url.url;
      }

      setServerErrorsToForm(form, lodash.isEmpty(obj) ? err?.data : obj);
    }
  });

  const onSubmit = (values) => {
    createDirectoryCategory.mutate(values);
  };

  return (
    <div className="add-directory-category">
      <DirectoryCategoryForm form={form} title="Add" onSubmit={onSubmit} onCancel={redirectToList} />
    </div>
  );
};

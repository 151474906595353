import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox } from "antd";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import navigation from "../../../helpers/navigation";
import AddEditGroup from "./AddEditGroup";

import { deleteGroupRequest, updateGroupRequest } from "../../../redux/groups/groupActions";


function Groups({ items, updateGroup, deleteGroup, location, history, userRole }) {
  const [selected, setSelected] = useState(null);
  const [showFormFullscreen, setShowFormFullscreen] = useState(false);
  const { search } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  // Search items
  const searchLower = search.toLowerCase();
  const itemsToRender = items.filter(item => {
    return (
      item.title.toLowerCase().includes(searchLower) ||
      item.url.toLowerCase().includes(searchLower)
    );
  });

  // TODO we should render Artisans in this list too

  // Methods
  const onStartEditClick = item => {
    if (selected) {
      return;
    }
    setSelected(item);
  };

  const onChangeActive = (url, event) => {
    updateGroup(url, { isActive: event.target.checked });
  };

  const onRemove = target => {
    // Remove from Edit form
    if (!target) {
      target = selected.url;
    }

    deleteGroup(target);
    setSelected(null);
  };


  // Render
  return (
    <div className="groups">
      <TableAndFormLayout showForm={!!selected} isFormFullscreen={showFormFullscreen}>
        <TableAndFormLayout.Header>
          <div className="component--header">
            {!isDisabled && <AddButton onClick={() => history.push("/catalog/groups/add")} />}
            <SearchInput value={search} onChange={value => navigation.setSearch(value, location, history)} />
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["5%", "20%", "20%", "auto", "76px"]}
            headerCells={[
              {},
              { label: "Name" },
              { label: "Categories" },
              { label: "Items" },
              { label: "Actions" },
            ]}
          >
            {
              itemsToRender.map(item => {
                return (
                  <TableRow key={`group:${item.id}`}>
                    <Checkbox checked={item.isActive} onChange={event => onChangeActive(item.url, event)} />
                    <div className="flex">{item.title}</div>
                    <div className="flex">0</div>
                    <div className="flex">0</div>
                    <div className="flex">
                      {!isDisabled && <React.Fragment>
                        <img className="groups--table-icon"
                          src="/images/edit@3x.svg"
                          onClick={() => onStartEditClick(item)}
                          alt=""
                          width="16"
                        />
                        <img
                          className="groups--table-icon"
                          src="/images/delete_3x.svg"
                          alt=""
                          width="16"
                          onClick={() => onRemove(item.url)}
                        />
                      </React.Fragment>
                      }
                    </div>
                  </TableRow>
                )
              })
            }
          </Table>
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditGroup
            item={selected}
            isFullScreen={showFormFullscreen}
            toggleFullscreen={() => setShowFormFullscreen(!showFormFullscreen)}
            close={() => {
              setSelected(null);
              setShowFormFullscreen(false);
            }}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

Groups.propTypes = {
  items: PropTypes.array.isRequired,
  updateGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    items: state.groups.items,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    updateGroup: (url, data) => dispatch(updateGroupRequest(url, data, true)),
    deleteGroup: url => dispatch(deleteGroupRequest(url)),
  })
)(Groups);

import React from "react";
import PropTypes from "prop-types";
import { Popconfirm } from "antd";

const StatusBarWithThreeColours = ({
  label,
  value,
  onClick,
  item,
  disabled,
  confirmTitle = "Are you sure, this make impact dealers website appearance?",
  popConfirm = true
}) => {
  const classNames = ["publish-req--status-block--status"];
  if (item.status === value) classNames.push(item.status.toLowerCase());
  if (disabled) classNames.push("publish-req--status-block--status_disabled");

  if (popConfirm) {
    return (
      <Popconfirm
        title={confirmTitle}
        onConfirm={() => !disabled && onClick(item.id, value)}
        okText="Yes"
        disabled={disabled}
        cancelText="No"
      >
        <span className={classNames.join(" ")}>{label}</span>
      </Popconfirm>
    );
  }

  // Render
  return <span className={classNames.join(" ")} onClick={() => !disabled && onClick && onClick(item.id, value)}>{label}</span>
};

StatusBarWithThreeColours.propTypes = {
  popConfirm: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  confirmTitle: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default StatusBarWithThreeColours;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Input,
  Modal,
  Spin,
  Switch,
  Tooltip,
  message,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  EyeOutlined,
  HistoryOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Form, Formik } from "formik";
import moment from "moment";
import merge from "lodash/merge";

// components
import LabeledInput from "../shared/LabeledInput";
import SearchInput from "../shared/SearchInput";
import TableRow from "../shared/Table/TableRow";
import TableFooter from "../shared/Table/TableFooter";
import Table from "../shared/Table/Table";
import StatusPanel from "../shared/StatusPanel";
import DealerSelectWithSearch from "../shared/DealerSelectWithSearch";
import ItemListContent from "./ItemListContent";
import EmailIcon from "../Icon/EmailIcon";

// redux
import {
  getItemsRequest,
  getChoicesRequest,
  updateItemsStatusRequest,
  patchItemsRequest,
  postRotateImage,
  updateShowOnHomeStatus,
} from "../../redux/items/itemsActions";

// helpers
import formatPrice from "../../helpers/formatPrice";
import { formatCreationTime } from "../../helpers/formatCreationTime";
import navigation from "../../helpers/navigation";
import { firstLetterCapitalize, Utils } from "../../helpers";
import itemsApi from "../../api/items";
import { selectCurrencySymbol } from "../../helpers/currency";

const HIDE_SHOW_ON_HOME_PAGE_TOOLTIP = "Show item on home page";
const SHOW_ITEM_ON_LA_TOOLTIP = "Show item on LoveAntiques.com";
const SEND_EMAIL_TOOLTIP = "Send email";
const ITEM_HISTORY_LOG_TOOLTIP = "Show item history log";

const filteredStatusOptions = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Available", value: "available" },
];

const statusOptions = [
  ...filteredStatusOptions,
  { label: "Reserved", value: "reserved" },
  { label: "Sold", value: "sold" },
  { label: "Declined", value: "declined" },
];

const dateOptions = [
  { label: "Newest items", value: "-date" },
  { label: "Oldest items", value: "date" },
  { label: "LA items", value: "LOVE_ANTIQUES" },
  { label: "Website builder items", value: "DEALER_WEBSITE" },
];

const hasArtisanOptions = [
  { label: "Default", value: null },
  { label: "Without Artisans", value: "false" },
  { label: "With Artisans", value: "true" },
];

const isApprovedOptions = [
  { label: "All", value: null },
  { label: "Approved", value: "true" },
  { label: "Not Approved", value: "false" },
];

const ItemsList = ({
  location,
  history,
  items,
  getItems,
  getChoices,
  patchItems,
  rotateImage,
  updateItemsStatus,
  choices,
  totalNumberOfItems = 10,
  loading,
  error,
  userProfile,
  groups,
  updateItemStatusOnHome,
}) => {
  const {
    search = "",
    page,
    perPage,
  } = navigation.getTableQueryParams(location);
  const [query, setQuery] = useState({
    status: { label: "Pending", value: "pending" },
    ordering: { label: "Oldest items", value: "date" },
  });
  const [activeItem, setActiveItem] = useState();
  const [loadingForTable, setLoadingForTable] = useState(false);
  const [declineModalData, setDeclineModalData] = useState({});
  const [itemHistoryModalVisible, setItemHistoryModalVisible] = useState(false);
  const [itemHistoryData, setItemHistoryData] = useState([]);

  const isDealerWebsiteItems = query.ordering.value === "DEALER_WEBSITE";
  const isUserItemVerifier =
    userProfile.roles.length === 1 && userProfile.roles[0] === "Item Verifier";

  // Helpers

  const approvalStatusesToArray = (itemToRender) => {
    const itemStatusKey =
      query.ordering.value === "DEALER_WEBSITE" ? "wbStatus" : "laStatus";

    if (choices && choices.approvalStatus) {
      return Object.values(choices.approvalStatus).map((item) => ({
        name: item,
        active:
          item.toLowerCase() ===
          itemToRender.status[itemStatusKey].toLowerCase(),
      }));
    }

    return Array.from("---").map((name) => ({ name }));
  };

  // Effects

  useEffect(() => {
    getChoices();
  }, []); // eslint-disable-line

  useEffect(() => {
    setLoadingForTable(true);
    getItems(
      { page, perPage, query: Utils.formatFilters(query) },
      setLoadingForTable
    );
  }, [page, perPage]); // eslint-disable-line

  // Methods

  const handleSortItems = (value, option, key) => {
    const isStatusTriggered = key === "status";
    const isOrderingTriggered = key === "ordering";
    const isStatusValuePending = value === "pending";

    let orderingToSet = {};

    if (isOrderingTriggered) {
      orderingToSet = { label: option.children, value };
    }

    if (value === "sold") {
      orderingToSet = { label: "Newest items", value: "-sold_date" };
    } else if (isStatusTriggered) {
      orderingToSet = { label: "Newest items", value: "-date" };

      if (isStatusValuePending) {
        orderingToSet = { label: "Oldest items", value: "date" };
      }
    }

    const newQuery = merge(query, {
      [key]: {
        label: option.children,
        value,
      },
      ordering: orderingToSet,
    });

    if (value === "All dealers") delete newQuery.dealer;

    setQuery(newQuery);

    navigation.setPage(1, location, history);
    setLoadingForTable(true);

    getItems(
      {
        page: 1,
        perPage,
        query: Utils.formatFilters(newQuery),
      },
      setLoadingForTable
    );
  };

  const handleSearchItems = (value) => {
    const newQuery = merge(query, {
      query: {
        label: "query",
        value,
      },
    });

    setLoadingForTable(true);
    navigation.setPage(1, location, history);

    getItems(
      {
        page: 1,
        perPage,
        query: Utils.formatFilters(newQuery),
      },
      setLoadingForTable
    );
  };

  const toggleCollapse = (innerSelectedItem) => {
    let activeItemValue = innerSelectedItem.id;

    if (activeItem === innerSelectedItem.id) activeItemValue = "";

    setActiveItem(activeItemValue);
  };

  const onDealerNameClick = (dealerUrl) => {
    const userRole = userProfile.roles[0];
    if (userRole === "Super Admin" || userRole === "Super Admin") {
      history.push({
        pathname: `/dealers/dealers-list/${dealerUrl}/status-tab`,
      });
    }

    return null;
  };

  const handleChangeItemStatusOnHomePage = (event, value, ref) => {
    const dataToSend = {
      status: {
        show_in_homepage: value,
      },
    };

    updateItemStatusOnHome({
      ref,
      data: dataToSend,
    });

    event.stopPropagation();
  };

  const handleItemDecline = ({ declineReason }, formMethods) => {
    updateItemsStatus({
      ...declineModalData,
      data: {
        ...declineModalData.data,
        declineReason,
      },
    });

    setDeclineModalData({});
    formMethods.resetForm();
  };

  const handleStatusClick = (e, { name, active }, itemToRender) => {
    e.stopPropagation();

    if (isDealerWebsiteItems) {
      return;
    }

    const itemToUpdate = {
      ref: itemToRender.ref,
      data: { laStatus: name.toUpperCase() },
      page,
      perPage,
      query: Utils.formatFilters(query),
    };

    if (!active && name === "Declined") {
      setDeclineModalData(itemToUpdate);
    } else if (!active) {
      updateItemsStatus(itemToUpdate);
    }
  };

  const coptItemHistoryLog = (historyLog) => {
    navigator.clipboard.writeText(
      historyLog
        .map((historyAction) => {
          let madeAction = `changed status from ${firstLetterCapitalize(
            historyAction.fromStatus
          )} to ${firstLetterCapitalize(historyAction.toStatus)}`;
          if (!historyAction.fromStatus) {
            madeAction = "edited item field";
          }

          return `${historyAction.changedBy} ${madeAction} ${moment(
            historyAction.createdAt
          ).format("DD/MM/YYYY HH:mm")};`;
        })
        .join("\n")
    );
    message.success("Item history log was copied");
  };

  // Render Methods

  const renderDealer = (itemToRender) => {
    if (itemToRender.dealer) {
      return (
        <div
          className="items--dealer-column--dealer-name"
          onClick={() => onDealerNameClick(itemToRender.dealer.url)}
        >
          <div className="items--dealer-column--dealer-name--title">
            {itemToRender.dealer.businessName}
          </div>
          <div className="items--dealer-column--dealer-name--id">
            {itemToRender.dealer.id}
          </div>
        </div>
      );
    }

    return "-";
  };

  const renderTable = () => {
    return (
      <Table
        columnWidths={["11%", "32%", "10%", "8%", "12%", "12%", "15%"]}
        headerCells={[
          { label: "status" },
          { label: "item" },
          { label: "price" },
          { label: "ref" },
          { label: "dealer" },
          { label: "created" },
          { label: "actions" },
        ]}
      >
        {({ columnWidths }) => {
          return items.map((itemToRender) => {
            const mainImage = itemToRender.images.find((e) => e.isMain) || null;

            const isItemApproved =
              itemToRender.status.laStatus !== "DRAFT" &&
              itemToRender.status.laStatus !== "PENDING" &&
              itemToRender.status.laStatus !== "DECLINED";

            const itemCurrency = selectCurrencySymbol(itemToRender.currency);

            return (
              <div
                key={`items-${itemToRender.id}`}
                className="items--content__row"
              >
                <TableRow
                  columnWidths={columnWidths}
                  onClick={() => toggleCollapse(itemToRender)}
                >
                  <div className="status-column flex">
                    {activeItem && activeItem === itemToRender.id ? (
                      <UpOutlined style={{ fontSize: "8px" }} />
                    ) : (
                      <DownOutlined style={{ fontSize: "8px" }} />
                    )}
                    <StatusPanel
                      onItemClick={handleStatusClick}
                      itemToRender={itemToRender}
                      statuses={approvalStatusesToArray(itemToRender)}
                      isStatusLarger
                      hasPermissionToChangeStatus={!isDealerWebsiteItems}
                    />
                  </div>
                  <div className="item-column flex">
                    {mainImage ? (
                      <img className="img" src={mainImage.url} alt=" " />
                    ) : (
                      <Avatar
                        style={{ backgroundColor: "#ccc" }}
                        className="img"
                        shape="square"
                      >
                        none
                      </Avatar>
                    )}
                    <div>
                      <div className="item-column--status-panel">
                        {isItemApproved && (
                          <div className="item-column--approved-by">
                            <span>Approved by:</span>
                            <br />
                            <span className="item-column--approved-by--title">
                              {itemToRender.status.approvedBy || "NA"}
                            </span>
                          </div>
                        )}
                      </div>
                      {itemToRender.title}
                      {itemToRender.status.laStatus === "DECLINED" && (
                        <div className="item-column--status-panel">
                          <span className="red-color">Decline reason:</span>
                          <br />
                          <span>
                            {itemToRender.status.declineReason || "NA"}
                          </span>
                        </div>
                      )}
                      <Tooltip
                        placement="bottom"
                        title={HIDE_SHOW_ON_HOME_PAGE_TOOLTIP}
                      >
                        <div className="show-on-home-page">
                          <Switch
                            onChange={(value, event) => {
                              handleChangeItemStatusOnHomePage(
                                event,
                                value,
                                itemToRender.ref
                              );
                            }}
                            defaultChecked={itemToRender.status.showInHomepage}
                            size="small"
                            checked={itemToRender.status.showInHomepage}
                          />
                        </div>
                      </Tooltip>
                      {itemToRender.status.laStatus === "SOLD" &&
                      itemToRender.soldDate ? (
                          <div className="created-column flex item-column--sold-label">
                          Sold date:{" "}
                            {moment(itemToRender.soldDate).format("DD-MMM-YYYY")}
                            <br />
                            {formatCreationTime(itemToRender.soldDate)}
                          </div>
                        ) : null}
                    </div>
                  </div>
                  <div className="price-column flex">
                    {!itemToRender.makeThisPricePoa
                      ? `${itemCurrency}${formatPrice(itemToRender.price)}`
                      : "POA"}
                  </div>
                  <div className="ref-column flex">
                    {Utils.emptyFieldFormat(itemToRender.ref, "-")}
                  </div>
                  <div className="dealer-column">
                    <div
                      className="dealer-column__left"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {renderDealer(itemToRender)}
                    </div>
                  </div>
                  <div className="created-column flex">
                    {moment(itemToRender.createdAt).format("DD-MMM-YYYY")}
                    <br />
                    {formatCreationTime(itemToRender.createdAt)}
                  </div>
                  <div className="actions-column flex">
                    <Tooltip placement="bottom" title={SEND_EMAIL_TOOLTIP}>
                      <a
                        className="dealer-column__right"
                        href={`mailto:${itemToRender.dealer.addressEmail}`}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          width: "20px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      >
                        <EmailIcon />
                      </a>
                    </Tooltip>
                    {isItemApproved && (
                      <Tooltip
                        placement="bottom"
                        title={SHOW_ITEM_ON_LA_TOOLTIP}
                      >
                        <a
                          href={`https://www.loveantiques.com/items/listings/${itemToRender.url}`}
                          className="line-height-1"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <EyeOutlined
                            style={{ fontSize: "20px", color: "#717171" }}
                          />
                        </a>
                      </Tooltip>
                    )}
                    <Tooltip
                      placement="bottom"
                      title={ITEM_HISTORY_LOG_TOOLTIP}
                    >
                      <div
                        className="item-history-icon"
                        onClick={(e) => {
                          e.stopPropagation();

                          itemsApi
                            .getItemHistory(itemToRender.ref)
                            .then((itemHistory) => setItemHistoryData(itemHistory.results)
                            )
                            .catch(console.warn)
                            .finally(() => setItemHistoryModalVisible(true));
                        }}
                      >
                        <HistoryOutlined />
                      </div>
                    </Tooltip>
                  </div>
                </TableRow>

                {activeItem === itemToRender.id && (
                  <ItemListContent
                    mainImage={mainImage || {}}
                    open
                    rotateImage={rotateImage}
                    patchItems={patchItems}
                    fetchData={{
                      page,
                      perPage,
                      query: Utils.formatFilters(query),
                    }}
                    groups={groups}
                    loading={loading}
                    error={error}
                    itemRef={itemToRender.ref}
                    queryStatus={Utils.formatFilters(query)}
                    showOnHomePage={handleChangeItemStatusOnHomePage}
                    itemCreationDate={itemToRender.dateCreated}
                    showInHomePage={itemToRender.status.showInHomepage}
                  />
                )}
              </div>
            );
          });
        }}

        {totalNumberOfItems && (
          <TableFooter
            key="items-table-footer"
            page={page}
            perPage={perPage}
            total={totalNumberOfItems}
          />
        )}
      </Table>
    );
  };

  const renderEditAsDealerLog = (historyAction) => {
    const [adminName, dealerName] = historyAction.changedBy.split(" as Dealer");

    return (
      <span>
        <span className="red-color">{adminName}</span>
        <span>{` as Dealer ${dealerName}`}</span>
      </span>
    );
  };

  // Render

  return (
    <div className="component items">
      <div>
        <Row gutter={16}>
          <Col sm={12} lg={6}>
            <LabeledInput
              className="status mr-16p"
              label="Status"
              type="select"
              options={isUserItemVerifier ? filteredStatusOptions : statusOptions}
              value={query.status && query.status.label}
              placeholder="By Status"
              onChange={(value, option) => handleSortItems(value, option, "status")
              }
              horizontal
            />
          </Col>
          <Col sm={12} lg={6}>
            <div className="items--dealer-filter">
              <DealerSelectWithSearch
                placeholder="By Dealer"
                onSelect={(value, option) => {
                  if (value || option) {
                    handleSortItems(value, option, "dealer");
                  } else {
                    handleSortItems(
                      "All dealers",
                      { value: "All dealers", label: "All dealers" },
                      "dealer"
                    );
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <LabeledInput
              className="status mr-16p"
              label="Approved Status"
              type="select"
              options={isApprovedOptions}
              value={(query.is_approved && query.is_approved.value) || null}
              onChange={(value, option) => handleSortItems(value, option, "is_approved")
              }
              horizontal
            />
          </Col>
          <Col sm={12} lg={6}>
            <LabeledInput
              className="status mr-16p"
              label="Artisan"
              type="select"
              options={hasArtisanOptions}
              value={(query.has_artisan && query.has_artisan.value) || null}
              onChange={(value, option) => handleSortItems(value, option, "has_artisan")
              }
              horizontal
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={18}>
            <div className="items--search">
              <SearchInput value={search} onChange={handleSearchItems} />
              <Checkbox
                checked={query.by_ref && query.by_ref.value}
                onChange={(e) => handleSortItems(
                  e.target.checked,
                  { children: "by_ref" },
                  "by_ref"
                )
                }
              >
                Search by Refs.
              </Checkbox>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <LabeledInput
              className="status mr-16p"
              label="Sort by"
              type="select"
              options={dateOptions}
              value={query.ordering && query.ordering.label}
              placeholder="By Date"
              onChange={(value, option) => handleSortItems(value, option, "ordering")
              }
              horizontal
            />
          </Col>
        </Row>
      </div>

      <div className="flex items--content">
        {loading || loadingForTable ? <Spin size="large" /> : renderTable()}
      </div>
      <Modal
        visible={declineModalData && declineModalData.ref}
        closeIcon={() => null}
        footer={null}
        centered
        className="modal-block"
      >
        <Formik
          onSubmit={handleItemDecline}
          initialValues={{ declineReason: "" }}
        >
          {({ values, setValues }) => (
            <Form className="modal-decline__form">
              <p className="modal-decline__title">Decline Request?</p>
              <div>
                <p className="modal-decline__text">
                  Please, specify the reason of declining
                </p>
                <Input.TextArea
                  value={values.declineReason}
                  required
                  onChange={(e) => setValues({ declineReason: e.target.value })}
                />
              </div>
              <div className="modal-decline__btn-group">
                <Button className="modal-decline__red-btn" htmlType="submit">
                  DECLINE
                </Button>
                <Button onClick={() => setDeclineModalData(null)}>
                  CANCEL
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        visible={itemHistoryModalVisible}
        footer={null}
        title={
          itemHistoryData.length ? (
            <div className="item-history-modal__footer-content">
              <Tooltip title="Copy Item history log">
                <CopyOutlined
                  className="item-history-modal__footer-action-icon"
                  onClick={() => coptItemHistoryLog(itemHistoryData)}
                />
              </Tooltip>
            </div>
          ) : null
        }
        centered
        onCancel={() => setItemHistoryModalVisible(false)}
        className="item-history-modal"
      >
        <div className="history-actions-container">
          {itemHistoryData.length
            ? itemHistoryData.map((historyAction) => {
              let madeAction = "";
              const updatedFields = historyAction.updatedFields;

              if (historyAction.fromStatus && historyAction.toStatus) {
                madeAction = `changed status from ${firstLetterCapitalize(
                  historyAction.fromStatus
                )} to ${firstLetterCapitalize(historyAction.toStatus)}`;
              }

              if (!historyAction.fromStatus && updatedFields) {
                madeAction = `
                edited "${updatedFields.replace(new RegExp("_", "g"), " ")}"
                 ${updatedFields.includes(",") ? "fields" : "field"}`;
              }

              if (
                !historyAction.fromStatus &&
                  !historyAction.toStatus &&
                  !updatedFields
              ) {
                madeAction = "edited fields";
              }

              return (
                <div key={historyAction.id} className="history-action">
                  <span
                    className={`history-action--approved-by ${
                        historyAction.changedBy.includes("Dealer")
                          ? "by-dealer"
                          : "by-admin"
                      }`}
                  >
                    {historyAction.changedBy.includes(" as Dealer")
                      ? renderEditAsDealerLog(historyAction)
                      : historyAction.changedBy}
                  </span>{" "}
                  {madeAction &&
                      `${madeAction} ${moment(historyAction.createdAt).format(
                        "DD/MM/YYYY HH:mm"
                      )}`}
                </div>
              );
            })
            : "No actions were made by admin"}
        </div>
      </Modal>
    </div>
  );
};

ItemsList.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  getChoices: PropTypes.func.isRequired,
  patchItems: PropTypes.func.isRequired,
  updateItemsStatus: PropTypes.func.isRequired,
  rotateImage: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  choices: PropTypes.object.isRequired,
  totalNumberOfItems: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.any,
  userProfile: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  updateItemStatusOnHome: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, groups, items }) => ({
  ...items,
  userProfile: auth.userProfile,
  groups: groups.items,
});

export default connect(mapStateToProps, (dispatch) => ({
  getItems: (payload, callBack) => dispatch(getItemsRequest(payload, callBack)),
  getChoices: (payload) => dispatch(getChoicesRequest(payload)),
  patchItems: (payload) => dispatch(patchItemsRequest(payload)),
  updateItemsStatus: (payload) => dispatch(updateItemsStatusRequest(payload)),
  rotateImage: (payload) => dispatch(postRotateImage(payload)),
  updateItemStatusOnHome: (ref, data) => dispatch(updateShowOnHomeStatus(ref, data)),
}))(ItemsList);

import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import Modal from "./Modal/Modal";

import PrivateRoute from "./router/PrivateRoute"
import PublicRoute from "./router/PublicRoute"
import AuthRoutes from "./router/AuthRoutes";
import AdminRoutes from "./router/AdminRoutes";

import { useCreateQueryClient } from "./providers/useCreateQueryClient";
import { AntdConfigProvider } from "./providers/AntdConfigProvider";


const App = () => {
  const queryClient = useCreateQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AntdConfigProvider>
        <Router>
          <Switch>
            <PublicRoute path="/auth" component={AuthRoutes} />
            <PrivateRoute path="/" component={AdminRoutes} />
          </Switch>
        </Router>

        <Modal />
      </AntdConfigProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { Checkbox, Modal, Tooltip, Switch as Toggle } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import ArticlePreview from "./ArticlePreview";
import { Utils } from "../../../../helpers";
import {
  getBlogArticlesRequest,
  getBlogAuthorsRequest,
  updateBlogArticleRequest,
  updateShowBlogStatus,
} from "../../../../redux/content/blog/blogActions";

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import Tag from "../../../shared/Tag";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import navigation from "../../../../helpers/navigation";

const SHOW_BLOG_TOOLTIP = "Show blog post";
const SHOW_BLOG_ON_HOME_TOOLTIP = "Show blog on home page";


function BlogArticles({
  location,
  history,
  articles,
  authors,
  getBlogArticles,
  getBlogAuthors,
  articlesTotal,
  updateBlogArticleStatus,
  updateBlogStatus,
}) {
  const [currentArticleData, setCurrentArticleData] = useState(null);
  const { order, page, perPage } = navigation.getTableQueryParams(location);


  useEffect(() => {
    getBlogArticles({ page, perPage });
    getBlogAuthors({ page: 1, perPage: 10 });
  }, [page, perPage]); // eslint-disable-line

  useEffect(() => {
    currentArticleData && history.push(`/content/blog/articles/preview/${currentArticleData.url}`)
  }, [currentArticleData]); // eslint-disable-line


  const onCheck = (e, article) => {
    const isActive = e.target.checked;
    updateBlogArticleStatus({ url: article.url, article: { isActive }, page, perPage });
  };

  const handleAddNewArticle = () => {
    if (!authors || authors.length === 0) {
      return Modal.info({
        content: "To be able to create a new article, please create an author first"
      });
    }

    history.push("/content/blog/articles/add-new-article");
  };

  const handleShowBlogStatus = (value, url) => {
    const dataToSend = {
      show_on_homepage: value,
    };

    updateBlogStatus({ url, data: dataToSend });
  };


  // Render
  const articlesToRender = Utils.filterAndOrder(articles, { order });

  return (
    <Switch>
      <Route
        path="/content/blog/articles/preview/:url"
        render={props => (
          <ArticlePreview
            {...props}
            currentArticle={{
              title: currentArticleData.title,
              pageContent: currentArticleData.text,
              createdAt: currentArticleData.createdAt
            }}
          />
        )}
      />

      <Route
        exact path="/content/blog/articles"
        render={() => (
          <div className="blog-articles-wrapper">
            <div className="blog--add-button">
              <AddButton onClick={handleAddNewArticle} />
            </div>

            <Table
              className="article--table"
              columnWidths={["40px", "auto", "auto", "auto", "100px", "100px", "10%"]}
              headerCells={[
                {},
                { label: "TITLE" },
                { label: "URL" },
                { label: "CATEGORIES" },
                { label: "AUTHOR" },
                { label: "DATE" },
                { label: "ACTIONS" },
              ]}
            >
              {
                articlesToRender.map(article => {
                  const articleImage = _.get(article, "image.url", "");
                  const goToEdit = () => {
                    history.push(`/content/blog/articles/edit-article/${article.url}`)
                  };


                  return (
                    <TableRow
                      key={`article-${article.id}`}
                      isHighlighted={!article.isActive}
                    >
                      <Checkbox
                        checked={article.isActive}
                        onChange={e => onCheck(e, article)}
                      />

                      <div
                        className="flex article--row-block article--row-block__name"
                        onClick={goToEdit}
                      >
                        <img className="article--avatar" src={articleImage} width="32" height="32" alt="" />
                        <span>{article.title}</span>
                      </div>
                      <div
                        className="flex article--row-block__url"
                        onClick={goToEdit}
                      >
                        {article.url}
                      </div>
                      <div
                        className="flex article--row-block"
                        onClick={goToEdit}
                      >
                        {article.categories.length > 0 ? article.categories.map(category => (
                          <Tag key={category.id} label={category.name} />
                        )) : (
                          <Tag key={"no"} label={"No Categories"} />
                        )}
                      </div>
                      <div
                        className="flex article--row-block__author"
                        onClick={goToEdit}
                      >
                        {
                          article.authors.map(author => <span key={author.id}>{author.name}</span>)
                        }
                      </div>
                      <div
                        className="flex article--row-block__data"
                        onClick={goToEdit}
                      >
                        {
                          moment(article.createdAt).format("DD/MM/YYYY")
                        }
                      </div>
                      <div className="flex article--row-block article--row-block__actions">
                        <Tooltip title={SHOW_BLOG_TOOLTIP}>
                          <img
                            src="/images/eye_3x.svg"
                            onClick={() => {
                              const { url, title, createdAt, text } = article;
                              setCurrentArticleData({ url, title, createdAt, text });
                            }}
                            alt=""
                          />
                        </Tooltip>

                        <div className="show-blog-on-home">
                          <Tooltip title={SHOW_BLOG_ON_HOME_TOOLTIP}>
                            <Toggle
                              onChange={value => handleShowBlogStatus(value, article.url)}
                              defaultChecked={article.showOnHomepage}
                              size="small"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </TableRow>
                  )
                })
              }

              {
                articlesTotal > 0 && (
                  <TableFooter
                    key="tableFooter"
                    page={page}
                    perPage={perPage}
                    total={articlesTotal}
                  />
                )
              }
            </Table>
          </div>
        )}
      />
      <Redirect to="/content/blog/articles" />
    </Switch>
  );
}

BlogArticles.propTypes = {
  location: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
  articlesTotal: PropTypes.number.isRequired,
  getBlogArticles: PropTypes.func.isRequired,
  getBlogAuthors: PropTypes.func.isRequired,
  updateBlogArticleStatus: PropTypes.func,
  history: PropTypes.object.isRequired,
  updateBlogStatus: PropTypes.func.isRequired,
};

export default connect(
  state => state.blogs,
  dispatch => ({
    getBlogArticles: payload => dispatch(getBlogArticlesRequest(payload)),
    getBlogAuthors: payload => dispatch(getBlogAuthorsRequest(payload)),
    updateBlogArticleStatus: payload => dispatch(updateBlogArticleRequest(payload)),
    updateBlogStatus: (url, data) => dispatch(updateShowBlogStatus(url, data)),
  })
)(BlogArticles);

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import DealersList from "./components/DealersList";
import DealersNews from "./components/DealerNews/index";
import DealerTestimonials from "./components/DealerTestimonials";
import DealerSpecialities from "./components/DealerSpecialities/DealerSpecialities";
import AddEditDealerSpeciality from "./components/DealerSpecialities/AddEditDealerSpeciality";
import DealerItem from "./components/DealerItem/DealerItem";
import DealerAssociations from "./DealerAssociations/DealerAssociations";
import AddEditTradeAssociationPage from "./DealerAssociations/AddEditTradeAssociationPage";
import DealersApiKeyRequests from "./components/DealersApiKeyRequests/DealersApiKeyRequests";


function Dealers() {
  return (
    <div className="component">
      <Switch>
        <Route exact path="/dealers/dealers-list/" component={DealersList} />
        <Route path="/dealers/dealers-list/:url/" component={DealerItem} />
        <Route path="/dealers/dealer-news/" component={DealersNews} />
        <Route exact path="/dealers/api-key-requests" component={DealersApiKeyRequests} />
        <Route exact path="/dealers/dealers-testimonials" component={DealerTestimonials} />
        <Route exact path="/dealers/dealer-specialities" component={DealerSpecialities} />
        <Route path="/dealers/dealer-specialities/add-new" component={AddEditDealerSpeciality} />
        <Route path="/dealers/dealer-specialities/edit/:id" component={AddEditDealerSpeciality} />
        <Route path="/dealers/trade-associations/add" component={AddEditTradeAssociationPage} />
        <Route path="/dealers/trade-associations/edit/:id" component={AddEditTradeAssociationPage} />
        <Route path="/dealers/trade-associations" component={DealerAssociations} />
        <Redirect to="/dealers/dealers-list/" />
      </Switch>
    </div>
  )
}

export default Dealers;

import { COLORS_HASH } from "../constants/color";

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const colorNameToRgba = (color) => {
  const colorHex = COLORS_HASH[color.toLowerCase()] || color;
  const { r, g, b } = hexToRgb(colorHex);

  return {
    main: `rgb(${r}, ${g}, ${b})`,
    transparent: `rgb(${r}, ${g}, ${b}, 0.4)`,
  };
}

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./paymentsActionsTypes";
import settingsApi from "../../../api/settings";

function *getPayPalObject() {
  try {
    yield put({ type: types.SHOW_PAYMENT_PROCESSING });
    const response = yield call(settingsApi.getPayPalObject);
    yield put({ type: types.GET_PAYPAL_REQUEST_SUCCESS, payload: response });

  } catch (error) {
    yield put({ type: types.GET_PAYPAL_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PAYMENT_PROCESSING });
  }
}

function *patchPayPalObject(action) {
  try {
    yield put({ type: types.SHOW_PAYMENT_LOADING });
    const response = yield call(settingsApi.updatePayPal, action.payload);
    yield put({ type: types.PATCH_PAYPAL_REQUEST_SUCCESS, payload: response });

  } catch (error) {
    yield put({ type: types.PATCH_PAYPAL_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PAYMENT_LOADING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_PAYPAL_REQUEST, getPayPalObject),
    yield takeLatest(types.PATCH_PAYPAL_REQUEST, patchPayPalObject)
  ])
}

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


export const StatusCardField = ({ title, children, className, ...rest }) => {
  return (
    <div className={classNames("status-card--field", className)} {...rest}>
      <div className="status-card--field-label">{title}</div>
      <div className="status-card--field-value">
        {children || "-"}
      </div>
    </div>
  )
};

StatusCardField.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

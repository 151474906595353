import React, { useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { message } from "antd";

//helpers
import auth from "../../../api/auth";


function PinterestToken() {
  const history = useHistory();

  useEffect(() => {
    const code = history.location.search ? new URLSearchParams(history.location.search).get("code") : null;

    if (code) {
      auth.authPinterest({ code })
        .catch((e) => message.error(e, 6))
        .finally(() => message.loading("Saving pinterest token").then(() => history.replace("/settings/pinterest")))
    }
  }, []); // eslint-disable-line

  if (!history.location.search) {
    return <Redirect to="/settings/pinterest" />
  }


  return null
}

export default PinterestToken;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import EditIcon from "../../Icon/EditIcon";
import KeyIcon from "../../Icon/KeyIcon";
import AccountIcon from "../../Icon/AccountIcon";


const AccountPreview = ({
  id,
  history,
  firstName,
  lastName,
  nickName,
  role,
  email,
  phone,
  mobile,
  lastLoginDate,
  image
}) => {
  const renderAccountImage = () => {
    if (image) return <img src={image.url} alt="" />;

    return <div className="account-preview--account-image--image-placeholder"><AccountIcon/></div>
  };

  const lastActivity = moment(lastLoginDate).format("L k:m:s");
  const accountRole = role && role[0];

  return (
    <div className="account-preview">
      <div className="account-preview--account-image">
        {renderAccountImage()}
      </div>

      <div className="account-preview--account-info">
        <div className="account-preview--account-info--name">{`${firstName} ${lastName}`}</div>
        <div className="account-preview--account-info--nickname">{nickName}</div>
        <div className="account-preview--account-info--role">{accountRole}</div>
        <div className="account-preview--account-info--email-field">
          <div className="account-preview--account-info--label">Email:</div>
          <div className="account-preview--account-info--value">{email}</div>
        </div>
        <div className="account-preview--account-info--phone-field">
          <div className="account-preview--account-info--label">Phone:</div>
          <div className="account-preview--account-info--value">{phone}</div>
        </div>
        <div className="account-preview--account-info--mobile-field">
          <div className="account-preview--account-info--label">Mobile:</div>
          <div className="account-preview--account-info--value">{mobile}</div>
        </div>
        <div className="account-preview--account-info--last-activity-field">
          <div className="account-preview--account-info--label">Last Activity:</div>
          <div className="account-preview--account-info--value">{lastActivity}</div>
        </div>
      </div>

      <div className="account-preview--account-actions">
        <EditIcon
          className="account-preview--account-actions--edit-icon"
          onClick={() => history.push(`/settings/admin-accounts/${id}`)}
        />
        <KeyIcon/>
      </div>
    </div>
  );
};

AccountPreview.propTypes = {
  id: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  lastLoginDate: PropTypes.string,
  nickName: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  mobile: PropTypes.string,
  role: PropTypes.array.isRequired,
  image: PropTypes.object,
};

export default AccountPreview;

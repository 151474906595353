import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

//components
import Overview from "./Overview/Overview";
import InvoiceList from "./InvoiceList/InvoiceList";
import DealerCharges from "./DealerCharges/DealerCharges";


function Invoices() {
  return (
    <Switch>
      <Route exact path="/invoices/overview" component={Overview} />
      <Route path="/invoices/invoice-list" component={InvoiceList} />
      <Route path="/invoices/dealer-charges" component={DealerCharges} />
      <Redirect to="/invoices/overview" />
    </Switch>
  )
}

export default Invoices;

import React, { useState } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

// react-queries
import { useTradeProfileList, useUserList } from "../../../react-query";

// helpers
import { buildFullName } from "../../../helpers";

const renderOption = (item) => ({
  value: item.id,
  label: <span>{buildFullName(item)}{" "}<span className="thin-label">({item.email})</span></span>,
  labelText: `${buildFullName(item)} (${item.email})`.toLowerCase()
});


export const AuthorSelectWithSearch = ({ placeholder = "", onSelect }) => {
  const [search, setSearch] = useState("");

  const { data: admins = [], isLoading: isAdminsLoading } = useUserList({
    page: 1,
    page_size: 200,
    admin: "True"
  }, {
    select: (resp) => resp.results.map(renderOption)
  });

  const { data: trades = [], isLoading: isTradesLoading } = useTradeProfileList({
    page: 1,
    page_size: 200
  }, {
    select: (resp) => resp.results.filter((el) => el.user?.email).map((el) => renderOption(el.user))
  });

  const handleClear = () => {
    setSearch("");
  };

  return (
    <div className="list-of-orders--sort-by-dealer-btn">
      <p className="list-of-orders--dealer-btn-label">
        Author
      </p>
      <Select
        className="select"
        style={{ width: "240px" }}
        placeholder={placeholder}
        options={admins.concat(trades).filter(({ labelText }) => labelText.includes(search))}
        onChange={onSelect}
        showSearch
        allowClear
        filterOption={false}
        loading={isAdminsLoading || isTradesLoading}
        onSearch={(value) => setSearch(value.toLowerCase().trim())}
        onClear={handleClear}
      />
    </div>
  )
}

AuthorSelectWithSearch.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import PropTypes from "prop-types";
import moment from "moment";

import {
  getOffersRequest,
  patchOfferRequest
} from "../../../../redux/settings/offers/offersActions";

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import navigation from "../../../../helpers/navigation";
import { Utils } from "../../../../helpers";


function OffersOffers({ location, history, getOffers, patchOffer, offers, offersTotalNumber, userRole }) {
  const { order, page, perPage } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  // Effects
  useEffect(() => {
    getOffers({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  // Methods
  const onCheck = (e, id) => {
    patchOffer({
      id,
      data: {
        isActive: e.target.checked
      },
      page,
      perPage
    });
  };

  const offersToRender = Utils.filterAndOrder(offers, { order });

  return (
    <div className="offers-wrapper">
      <div className="offers--add-button">
        {!isDisabled && <AddButton onClick={() => history.push("/settings/offers/add")} />}
      </div>

      <Table
        className="offers--table"
        columnWidths={["40px", "auto", "auto", "auto", "auto", "30%", "auto", "15%"]}
        headerCells={[
          {},
          { label: "from date" },
          { label: "to date" },
          { label: "location id" },
          { label: "name" },
          { label: "description" },
          { label: "offer url" }
        ]}
      >
        {
          offersToRender.map(offer => {
            const { id, isActive, fromDate, toDate, locationId, name, description, url } = offer;

            return (
              <TableRow key={`offers_${id}`}>
                <Checkbox
                  checked={isActive}
                  onChange={e => onCheck(e, id)}
                />
                <div className="flex bold">
                  { Utils.emptyFieldFormat(fromDate && moment(fromDate).format("MM/DD/YYYY"), "-") }
                </div>
                <div className="flex bold">
                  { Utils.emptyFieldFormat(toDate && moment(toDate).format("MM/DD/YYYY"), "-") }
                </div>
                <div className="flex">{ Utils.emptyFieldFormat(locationId, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(name, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(description, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(url, "-") }</div>
              </TableRow>
            )
          })
        }

        {
          offersTotalNumber && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={offersTotalNumber}
            />
          )
        }
      </Table>
    </div>
  )
}

OffersOffers.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getOffers: PropTypes.func.isRequired,
  patchOffer: PropTypes.func.isRequired,
  offers: PropTypes.array.isRequired,
  offersTotalNumber: PropTypes.number.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    offers: state.offers.offers,
    offersTotalNumber: state.offers.offersTotalNumber,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getOffers: payload => dispatch(getOffersRequest(payload)),
    patchOffer: payload => dispatch(patchOfferRequest(payload))
  })
)(OffersOffers);

import { useQuery } from "react-query";

import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({ url: `/fairs/notes/?${buildQueryString(params)}`, method: "GET" });
};

export const useFairNoteList = (params, options) => {
  return useQuery(["fair-notes-list", JSON.stringify(params)], () => fetcher(params), {
    keepPreviousData: true,
    ...options
  });
};

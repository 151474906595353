import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import {
  getUserOffersRequest
} from "../../../../redux/settings/offers/offersActions"

import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import navigation from "../../../../helpers/navigation";
import { Utils } from "../../../../helpers";


function OffersUserOffers({ location, history, getUserOffers, userOffers, userOffersTotalNumber, userRole }) {
  const { order, page, perPage } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  // Effects
  useEffect(() => {
    getUserOffers({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  const userOffersToRender = Utils.filterAndOrder(userOffers, { order });

  return (
    <div className="offers-wrapper">
      <div className="offers--add-button">
        {!isDisabled && <AddButton onClick={() => history.push("/settings/offers/user-offers/add")} />}
      </div>

      <Table
        className="offers--table ml-0"
        columnWidths={["auto", "auto", "auto", "auto", "45%", "15%"]}
        headerCells={[
          { label: "id" },
          { label: "offer id" },
          { label: "location id" },
          { label: "name" },
          { label: "description" },
          { label: "date first activity" }
        ]}
      >
        {
          userOffersToRender.map(userOffer => {
            const { id, locationId, name, description, dateFirstActivity, offerId } = userOffer;

            return (
              <TableRow key={`user-offers_${id}`}>
                <div className="flex">{ id }</div>
                <div className="flex">{ Utils.emptyFieldFormat(offerId, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(locationId, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(name, "-") }</div>
                <div className="flex">{ Utils.emptyFieldFormat(description, "-") }</div>
                <div className="flex">
                  { Utils.emptyFieldFormat(dateFirstActivity && moment(dateFirstActivity).format("DD/MM/YYYY HH:mm:ss"), "-") }
                </div>
              </TableRow>
            )
          })
        }

        {
          userOffersTotalNumber && (
            <TableFooter
              page={page}
              perPage={perPage}
              total={userOffersTotalNumber}
            />
          )
        }
      </Table>
    </div>
  )
}

OffersUserOffers.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getUserOffers: PropTypes.func.isRequired,
  userOffers: PropTypes.array,
  userOffersTotalNumber: PropTypes.number.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    userOffers: state.offers.userOffers,
    userOffersTotalNumber: state.offers.userOffersTotalNumber,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getUserOffers: payload => dispatch(getUserOffersRequest(payload))
  })
)(OffersUserOffers);

import fetch from "./customFetch";

const periods = {
  async get({ page, perPage }) {
    try {
      return await fetch({
        url: `/periods/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getOne(url) {
    try {
      return await fetch({
        url: `/periods/${url}/`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async search({ page, perPage, search }) {
    try {
      return await fetch({
        url: `/periods/search/?page=${page}&page_size=${perPage}&query=${search}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async create(data) {
    try {
      return await fetch({
        url: "/periods/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      if (err.message) {
        throw err.message;
      }

      throw err;
    }
  },

  async updatePut(url, data) {
    try {
      return await fetch({
        url: `/periods/${url}/`,
        method: "PUT",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updatePatch(url, data) {
    try {
      return await fetch({
        url: `/periods/${url}/`,
        method: "PATCH",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async delete(url) {
    try {
      return await fetch({
        url: `/periods/${url}/`,
        method: "DELETE",
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  }
};

export default periods;

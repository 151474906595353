import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./periodsActionTypes";
import periodsAPI from "../../api/periods";
import navigation from "../../helpers/navigation";


export function* get({ params }) {
  try {
    let response = {};
    if (params.search) {
      response = yield call(periodsAPI.search, params);
    } else {
      response = yield call(periodsAPI.get, params);
    }

    yield put({ type: types.GET_PERIODS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.PERIODS_REQUEST_ERROR, payload: error });
  }
}

export function* create({ data, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(periodsAPI.create, data);

    yield put({ type: types.GET_PERIODS_REQUEST, params: { page: 1, perPage: 10 } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.PERIODS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function* update({ url, data, isPatch, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) {
      yield call(periodsAPI.updatePatch, url, data);
    } else {
      yield call(periodsAPI.updatePut, url, data);
    }

    yield put({ type: types.GET_PERIODS_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.PERIODS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function* remove({ url, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(periodsAPI.delete, url);

    yield put({ type: types.GET_PERIODS_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.PERIODS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_PERIODS_REQUEST, get),
    yield takeLatest(types.CREATE_PERIODS_REQUEST, create),
    yield takeLatest(types.UPDATE_PERIODS_REQUEST, update),
    yield takeLatest(types.DELETE_PERIODS_REQUEST, remove),
  ])
}

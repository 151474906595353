export const GET_HOME_AND_LIVING_LIST_REQUEST = "GET_HOME_AND_LIVING_LIST_REQUEST";
export const GET_HOME_AND_LIVING_LIST_REQUEST_SUCCESS = "GET_HOME_AND_LIVING_LIST_REQUEST_SUCCESS";
export const GET_HOME_AND_LIVING_LIST_REQUEST_ERROR = "GET_HOME_AND_LIVING_LIST_REQUEST_ERROR";

export const GET_HOME_AND_LIVING_ITEM_REQUEST = "GET_HOME_AND_LIVING_ITEM_REQUEST";
export const GET_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS = "GET_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS";
export const GET_HOME_AND_LIVING_ITEM_REQUEST_ERROR = "GET_HOME_AND_LIVING_ITEM_REQUEST_ERROR";

export const UPDATE_HOME_AND_LIVING_ITEM_REQUEST = "UPDATE_HOME_AND_LIVING_ITEM_REQUEST";
export const UPDATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS = "UPDATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS";
export const UPDATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR = "UPDATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR";

export const DELETE_HOME_AND_LIVING_ITEM_REQUEST = "DELETE_HOME_AND_LIVING_ITEM_REQUEST";
export const DELETE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS = "DELETE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS";
export const DELETE_HOME_AND_LIVING_ITEM_REQUEST_ERROR = "DELETE_HOME_AND_LIVING_ITEM_REQUEST_ERROR";

export const CREATE_HOME_AND_LIVING_ITEM_REQUEST = "CREATE_HOME_AND_LIVING_ITEM_REQUEST";
export const CREATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS = "CREATE_HOME_AND_LIVING_ITEM_REQUEST_SUCCESS";
export const CREATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR = "CREATE_HOME_AND_LIVING_ITEM_REQUEST_ERROR";

export const TOGGLE_PROCESSING = "TOGGLE_PROCESSING_HOME_AND_LIVING";

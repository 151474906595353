import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Checkbox } from "antd";
import PropTypes from "prop-types";

import LabeledInput from "../../shared/LabeledInput";
import GeneralFormControl from "../../shared/GeneralFormControl";

import {
  createBlacklistsEnquiriesRequest,
  updateBlacklistsEnquiriesRequest,
  getBlacklistsEnquiriesChoicesRequest,
  resetBlacklistsEnquiriesErrorAction
} from "../../../redux/enquiries/blacklistsEnquiries/blacklistsEnquiriesActions";


function AddEditBlacklistsEnquiries({
  item,
  history,
  close,
  remove,
  processing,
  options,
  error,
  getBlacklistsEnquiriesChoices,
  resetBlacklistsEnquiriesError,
  updateBlacklistsEnquiries,
  createBlacklistsEnquiries,
}) {
  const location = useLocation();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [active, setActive] = useState(item ? item.isActive : true);
  const [type, setType] = useState(item ? item.type || "" : "");
  const [value, setValue] = useState(item ? item.value || "" : "");
  const [reason, setReason] = useState(item ? item.reason || "" : "");
  const [origin, setOrigin] = useState(item ? item.origin || "" : "");
  const [dfa, setDfa] = useState(item ? item.dfa || "" : "");

  const isEdit = !!item;

  // Get types of Blacklists
  useEffect(() => {
    if (Object.keys(options).length === 0) {
      getBlacklistsEnquiriesChoices();
    }

    // Reset error on component unmount
    return resetBlacklistsEnquiriesError
  }, []); // eslint-disable-line

  // Close form after successful submit
  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    if (isEdit) return close();
    history.push({ pathname: location.pathname.replace("/add", "") })
  }, [formSubmitted, processing, error]); // eslint-disable-line


  // Methods
  const onSave = () => {
    setFormSubmitted(true);

    const data = {
      isActive: active,
      type,
      value,
      origin,
      reason,
      dfa,
    };

    if (isEdit) return updateBlacklistsEnquiries(item.id, data);

    createBlacklistsEnquiries(data);
  };

  const onCancel = () => {
    if (isEdit) return remove();
    history.push("/enquiries/blacklists")
  };


  // Render
  const err = error || {};

  return (
    <div className="general-form">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "New"} Blacklist Item</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen/>}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox className="checkbox-small" checked={active} onChange={setActive}>Active</Checkbox>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="Type"
            type="select"
            options={Object.keys(options).map(key => ({ label: options[key], value: key }))}
            value={type}
            onChange={val => setType(val)}
            isRequired
            hasError={!!err.type}
          />

          <LabeledInput
            label="Value"
            value={value}
            onChange={setValue}
            isRequired
            hasError={!!err.value}
          />

          <LabeledInput
            label="Reason"
            type="textarea"
            value={reason}
            onChange={setReason}
            hasError={!!err.reason}
          />

          <LabeledInput
            label="Origin"
            value={origin}
            onChange={setOrigin}
            hasError={!!err.origin}
          />

          <LabeledInput
            label="DFA"
            value={dfa}
            onChange={setDfa}
            hasError={!!err.dfa}
          />
        </div>

        <div className="general-form--column" />
      </div>

      <div className="general-form--button-holder">
        <Button className="ant-btn-primary" onClick={onSave} loading={processing}>
          SAVE & CLOSE
        </Button>

        <Button onClick={onCancel}>
          {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
          {isEdit ? "DELETE ITEM" : "CANCEL"}
        </Button>
      </div>
    </div>
  )
}

AddEditBlacklistsEnquiries.propTypes = {
  item: PropTypes.object,
  history: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  options: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  createBlacklistsEnquiries: PropTypes.func.isRequired,
  updateBlacklistsEnquiries: PropTypes.func.isRequired,
  getBlacklistsEnquiriesChoices: PropTypes.func.isRequired,
  resetBlacklistsEnquiriesError: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    processing: state.blacklistsEnquiries.processing,
    options: state.blacklistsEnquiries.options,
    error: state.blacklistsEnquiries.error,
  }),
  dispatch => ({
    createBlacklistsEnquiries: data => dispatch(createBlacklistsEnquiriesRequest(data)),
    updateBlacklistsEnquiries: (url, data) => dispatch(updateBlacklistsEnquiriesRequest(url, data)),
    getBlacklistsEnquiriesChoices: () => dispatch(getBlacklistsEnquiriesChoicesRequest()),
    resetBlacklistsEnquiriesError: () => dispatch(resetBlacklistsEnquiriesErrorAction()),
  })
)(AddEditBlacklistsEnquiries);

export default function findAllAttachments(array, key) {
  const result = [];

  array.forEach(item => {
    result.push(item);

    if (item[key] && item[key].length) {
      result.push(...findAllAttachments(item[key], key));
    }
  });

  return result;
}

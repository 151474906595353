import * as types from "./invoiceListActionsTypes";

export const getInvoiceListRequest = payload => {
  return { type: types.GET_INVOICE_LIST_REQUEST, payload };
};

export const deleteInvoiceRequest = payload => {
  return { type: types.DELETE_INVOICE_REQUEST, payload };
};

export const getInvoicePDFRequest = (payload) => ({
  type: types.GET_INVOICE_PDF_REQUEST,
  payload
});

export const getInvoicePDFRequestError = (payload) => ({
  type: types.GET_INVOICE_PDF_REQUEST_ERROR,
  payload
});

export const getInvoicePDFRequestSuccess = (payload) => ({
  type: types.GET_INVOICE_PDF_REQUEST_SUCCESS,
  payload
});

import { useQuery } from "react-query";

// helpers
import fetch from "../../api/customFetch";
import buildQueryString from "../../helpers/buildQueryString";

const fetcher = (params) => {
  return fetch({
    url: `/subscriptions/promocodes/?${buildQueryString(params)}`,
    method: "GET",
  });
};

export const usePromoCodesList = (params, options) => {
  return useQuery(
    ["directory", "promo-codes", "list", JSON.stringify(params)],
    () => fetcher(params),
    options
  );
};

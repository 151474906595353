import { all, put, call, takeLatest } from "redux-saga/effects";
import { message } from "antd";

import * as types from "./dealersActionsTypes";
import dealers from "../../api/dealers";
import content from "../../api/content";


function* getDealers(action) {
  try {
    const response = yield call(dealers.getDealers, action.payload);
    yield put({ type: types.GET_DEALERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALERS_ERROR, payload: error.message });
  }
}

function* getDealersStatusChoices(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealersStatusChoices, action.payload);
    yield put({ type: types.GET_DEALERS_STATUS_CHOICES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALERS_STATUS_CHOICES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* deleteDealer(action) {
  try {
    const { url, page, perPage } = action.payload;
    yield call(dealers.deleteDealer, url);
    yield put({ type: types.DELETE_DEALER_REQUEST_SUCCESS });
    yield put({ type: types.GET_DEALERS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_REQUEST_ERROR, payload: error.message });
  }
}

function* getDealersNews(action) {
  try {
    const response = yield call(dealers.getDealersNews, action.payload);
    yield put({ type: types.GET_DEALERS_NEWS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALERS_NEWS_ERROR, payload: error.message });
  }
}

function* patchDealersNews(action) {
  try {
    const { data, page, perPage } = action.payload;
    yield call(dealers.patchDealersNews, data);
    yield put({ type: types.GET_DEALERS_NEWS_REQUEST, payload: { page, perPage } })
  } catch (error) {
    yield put({ type: types.PATCH_DEALERS_NEWS_ITEM_ERROR, payload: error });
  }
}

function* createDealersNewsItem(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.createDealersNewsItem, action.payload);
    yield put({ type: types.CREATE_DEALER_NEWS_ITEM_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_NEWS_ITEM_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerSpecialities(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealerSpecialities, action.payload);
    yield put({ type: types.GET_DEALER_SPECIALITIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_SPECIALITIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* getDealerCategories(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealerCategories, action.payload);
    yield put({ type: types.GET_DEALER_CATEGORIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_CATEGORIES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* createDealerSpeciality(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.createDealerSpeciality, action.payload);
    yield put({ type: types.CREATE_DEALER_SPECIALITY_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_SPECIALITY_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerData(action) {
  yield put({ type: types.SHOW_LOADING });

  try {
    const response = yield call(dealers.getDealerData, action.payload);
    yield put({ type: types.GET_SINGLE_DEALER_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_SINGLE_DEALER_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* patchDealer(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });

  try {
    yield call(dealers.patchDealer, action.payload.data, action.payload.data.url);
    yield put({ type: types.PATCH_DEALER_SUCCESS });

    if (action.callback) {
      yield call(action.callback)
    }
  } catch (error) {
    yield put({ type: types.PATCH_DEALER_ERROR, payload: error });
  }

  yield put({ type: types.TOGGLE_PROCESSING, payload: false });
}

function* getDealerAddresses(action) {
  try {
    const { url } = action.payload;
    const response = yield call(dealers.getDealerAddresses, url);
    yield put({ type: types.GET_DEALER_ADDRESSES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_ADDRESSES_REQUEST_ERROR, payload: error.message });
  }
}

function* patchDealerAddress(action) {
  try {
    const { data, url } = action.payload;
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.patchDealerAddress, data, url);
    yield put({ type: types.PATCH_DEALER_ADDRESS_REQUEST_SUCCESS });
    yield put({ type: types.GET_DEALER_ADDRESSES_REQUEST, payload: { url } })
  } catch (error) {
    yield put({ type: types.PATCH_DEALER_ADDRESS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerAddress(action) {
  try {
    const { url, id } = action.payload;
    const response = yield call(dealers.getDealerAddress, url, id);
    yield put({ type: types.GET_DEALER_ADDRESS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_ADDRESS_REQUEST_ERROR, payload: error.message });
  }
}

function* getDealerInvoices(action) {
  try {
    const response = yield call(dealers.getDealerInvoices, action.payload);
    yield put({ type: types.GET_DEALER_INVOICES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_INVOICES_REQUEST_ERROR, payload: error.message });
  }
}

function* deleteDealerInvoice(action) {
  try {
    const { url, id } = action.payload;
    const response = yield call(dealers.deleteDealerInvoice, url, id);
    yield put({ type: types.DELETE_DEALER_INVOICE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_INVOICE_REQUEST_ERROR, payload: error.message });
  }
}

function* getDealerNotes(action) {
  try {
    const response = yield call(dealers.getDealerNotes, action.payload);
    yield put({ type: types.GET_DEALER_NOTES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_NOTES_REQUEST_ERROR, payload: error.message });
  }
}

function* createDealerNote(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.createDealerNote, action.payload);
    yield put({ type: types.ADD_DEALER_NOTE_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.ADD_DEALER_NOTE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerStatusData(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    const response = yield call(dealers.getDealerStatusData, action.payload);
    yield put({ type: types.GET_DEALER_STATUS_DATA_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_STATUS_DATA_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* patchDealerStatusData(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    const response = yield call(dealers.patchDealerStatusData, action.payload);
    yield put({ type: types.PATCH_DEALER_STATUS_DATA_REQUEST_SUCCESS, payload: response });
    yield put({ type: types.GET_DEALER_STATUS_DATA_REQUEST, payload: action.payload.url });

    if (action.callback) {
      yield action.callback();
    }
  } catch (error) {
    yield put({ type: types.PATCH_DEALER_STATUS_DATA_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* createDealerAddress(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.createDealerAddress, action.payload);
    yield put({ type: types.CREATE_DEALER_ADDRESS_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_ADDRESS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* searchListOfDealers(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    const response = yield call(dealers.searchListOfDealers, action.payload);
    yield put({ type: types.SEARCH_LIST_OF_DEALERS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SEARCH_LIST_OF_DEALERS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerImages(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealerImages, action.payload);
    yield put({ type: types.GET_DEALER_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* patchDealerImages(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(dealers.patchDealerImages, action.payload);
    yield put({ type: types.PATCH_DEALER_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.PATCH_DEALER_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
    yield put({ type: types.GET_DEALER_IMAGES_REQUEST, payload: action.payload.url });
  }
}

function* putDealerImages(action) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const response = yield call(dealers.putDealerImages, action.payload);
    yield put({ type: types.PUT_DEALER_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.PUT_DEALER_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
    yield put({ type: types.GET_DEALER_IMAGES_REQUEST, payload: action.payload.url });
  }
}

function* getDealerStockImages(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealerStockImages, action.payload);
    yield put({ type: types.GET_DEALER_STOCK_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_STOCK_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* createDealerStockImages(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.createDealerStockImages, action.payload);
    yield put({ type: types.CREATE_DEALER_STOCK_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_STOCK_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
    yield put({ type: types.GET_DEALER_STOCK_IMAGES_REQUEST, payload: action.payload.url });
  }
}

function* patchDealerStockImages(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.patchDealerStockImages, action.payload);
    yield put({ type: types.CREATE_DEALER_STOCK_IMAGES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CREATE_DEALER_STOCK_IMAGES_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
    yield put({ type: types.GET_DEALER_STOCK_IMAGES_REQUEST, payload: action.payload.url });
  }
}

function* deleteDealerStockImages(action) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.deleteDealerStockImages, action.payload);
    yield put({ type: types.DELETE_DEALER_STOCK_IMAGE_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_STOCK_IMAGE_REQUEST_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
    yield put({ type: types.GET_DEALER_STOCK_IMAGES_REQUEST, payload: action.payload.url });
  }
}

function* uploadNewDealerLogoRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const image = yield call(content.uploadNewImage, payload);
    yield put({ type: types.UPLOAD_NEW_DEALER_LOGO_REQUEST_SUCCESS, payload: image });
  } catch (error) {
    yield put({ type: types.UPLOAD_NEW_DEALER_LOGO_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* uploadNewDealerEmailImageRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const image = yield call(content.uploadNewImage, payload);
    yield put({ type: types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_SUCCESS, payload: image });
  } catch (error) {
    yield put({ type: types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* uploadNewDealerPageImageRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const image = yield call(content.uploadNewImage, payload);
    yield put({ type: types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_SUCCESS, payload: image });
  } catch (error) {
    yield put({ type: types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* searchDealers({ payload }) {
  try {
    const response = yield call(dealers.searchDealers, payload.params, payload.query);
    yield put({ type: types.SEARCH_DEALERS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.SEARCH_DEALERS_ERROR, payload: error });
  }
}

function* getTradeAssociations({ payload }) {
  try {
    const response = yield call(dealers.getTradeAssociations, payload);
    yield put({ type: types.GET_TRADE_ASSOCIATIONS_REQUEST_SUCCESS, payload: response.results });
  } catch (error) {
    yield put({ type: types.GET_TRADE_ASSOCIATIONS_REQUEST_ERROR, payload: error });
  }
}

function* modifyTradeAssociationsOrder({ payload }) {
  try {
    const response = yield call(dealers.changeTradeAssociationsOrder, payload);
    yield put({ type: types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST_ERROR, payload: error });
  }
}

function* deleteTradeAssociation({ payload, callback }) {
  try {
    yield call(dealers.deleteTradeAssociation, payload);
    if (callback) {
      return yield call(callback);
    }
    yield put({ type: types.GET_TRADE_ASSOCIATIONS_REQUEST });
  } catch (error) {
    yield put({ type: types.DELETE_TRADE_ASSOCIATION_REQUEST_ERROR, payload: error });
  }
}

function* createTradeAssociation({ payload, callback }) {
  try {
    yield call(dealers.createTradeAssociation, payload);
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.CREATE_TRADE_ASSOCIATION_REQUEST_ERROR, payload: error });
  }
}

function* updateTradeAssociation({ id, payload, callback }) {
  try {
    yield call(dealers.updateTradeAssociation, id, payload);
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.UPDATE_TRADE_ASSOCIATION_REQUEST_ERROR, payload: error });
  }
}

function* getDealersApiTokens({ payload }) {
  yield put({ type: types.SHOW_LOADING });
  try {
    const response = yield call(dealers.getDealersApiTokens, payload);
    yield put({ type: types.GET_API_KEY_REQUESTS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_API_KEY_REQUESTS_ERROR, payload: error.message });
  } finally {
    yield put({ type: types.HIDE_LOADING });
  }
}

function* uploadDealerApiTokenRequest({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const { id, data } = payload;

    yield call(dealers.updateDealerApiTokenStatus, { tokenId: id, data });

    const response = yield call(dealers.getDealersApiTokens, payload.params);
    yield put({ type: types.GET_API_KEY_REQUESTS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.CHANGE_API_KEY_REQUEST_STATUS_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* revokeDealerApiKey({ payload }) {
  yield put({ type: types.TOGGLE_PROCESSING, payload: true });
  try {
    const { dealerId, params } = payload;

    yield call(dealers.revokeDealerApiTokenStatus, { dealerId });

    const response = yield call(dealers.getDealersApiTokens, params);
    yield put({ type: types.GET_API_KEY_REQUESTS_SUCCESS, payload: response });

    yield message.success("Dealer API key revoked");
  } catch (error) {
    yield put({ type: types.REVOKE_API_KEY_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* deleteDealerSpeciality(action) {
  try {
    yield call(dealers.deleteDealerSpeciality, action.payload.url);
    yield put({ type: types.DELETE_DEALER_SPECIALITY_REQUEST_SUCCESS, payload: action.payload });

    const response = yield call(
      dealers.getDealerSpecialities,
      { page: action.payload.page, perPage: action.payload.perPage },
    );
    yield put({ type: types.GET_DEALER_SPECIALITIES_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.DELETE_DEALER_SPECIALITY_REQUEST_ERROR, payload: error.message });
  }
}

function* updateDealerSpeciality(action) {
  try {
    yield put({ type: types.TOGGLE_PROCESSING, payload: true });
    yield call(dealers.updateDealerSpeciality, action.payload);
    yield put({ type: types.UPDATE_DEALER_SPECIALITY_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: types.UPDATE_DEALER_SPECIALITY_REQUEST_ERROR, payload: error.message || error });
  } finally {
    yield put({ type: types.TOGGLE_PROCESSING, payload: false });
  }
}

function* getDealerLogsSaga(action) {
  try {
    const response = yield call(dealers.getDealerLogs, action.payload);
    yield put({ type: types.GET_DEALER_LOGS_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_DEALER_LOGS_FAILED, payload: error.message });
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_DEALERS_REQUEST, getDealers),
    yield takeLatest(types.GET_DEALERS_STATUS_CHOICES_REQUEST, getDealersStatusChoices),
    yield takeLatest(types.DELETE_DEALER_REQUEST, deleteDealer),
    yield takeLatest(types.GET_DEALERS_NEWS_REQUEST, getDealersNews),
    yield takeLatest(types.PATCH_DEALERS_NEWS_ITEM_REQUEST, patchDealersNews),
    yield takeLatest(types.CREATE_DEALER_NEWS_ITEM_REQUEST, createDealersNewsItem),
    yield takeLatest(types.GET_DEALER_SPECIALITIES_REQUEST, getDealerSpecialities),
    yield takeLatest(types.GET_DEALER_CATEGORIES_REQUEST, getDealerCategories),
    yield takeLatest(types.CREATE_DEALER_SPECIALITY_REQUEST, createDealerSpeciality),
    yield takeLatest(types.GET_SINGLE_DEALER_REQUEST, getDealerData),
    yield takeLatest(types.PATCH_DEALER_REQUEST, patchDealer),
    yield takeLatest(types.GET_DEALER_ADDRESSES_REQUEST, getDealerAddresses),
    yield takeLatest(types.PATCH_DEALER_ADDRESS_REQUEST, patchDealerAddress),
    yield takeLatest(types.GET_DEALER_ADDRESS_REQUEST, getDealerAddress),
    yield takeLatest(types.GET_DEALER_INVOICES_REQUEST, getDealerInvoices),
    yield takeLatest(types.DELETE_DEALER_INVOICE_REQUEST, deleteDealerInvoice),
    yield takeLatest(types.GET_DEALER_NOTES_REQUEST, getDealerNotes),
    yield takeLatest(types.ADD_DEALER_NOTE_REQUEST, createDealerNote),
    yield takeLatest(types.GET_DEALER_STATUS_DATA_REQUEST, getDealerStatusData),
    yield takeLatest(types.CREATE_DEALER_ADDRESS_REQUEST, createDealerAddress),
    yield takeLatest(types.PATCH_DEALER_STATUS_DATA_REQUEST, patchDealerStatusData),
    yield takeLatest(types.SEARCH_LIST_OF_DEALERS_REQUEST, searchListOfDealers),
    yield takeLatest(types.GET_DEALER_IMAGES_REQUEST, getDealerImages),
    yield takeLatest(types.PATCH_DEALER_IMAGES_REQUEST, patchDealerImages),
    yield takeLatest(types.PUT_DEALER_IMAGES_REQUEST, putDealerImages),
    yield takeLatest(types.GET_DEALER_STOCK_IMAGES_REQUEST, getDealerStockImages),
    yield takeLatest(types.CREATE_DEALER_STOCK_IMAGES_REQUEST, createDealerStockImages),
    yield takeLatest(types.PATCH_DEALER_STOCK_IMAGES_REQUEST, patchDealerStockImages),
    yield takeLatest(types.DELETE_DEALER_STOCK_IMAGE_REQUEST, deleteDealerStockImages),
    yield takeLatest(types.UPLOAD_NEW_DEALER_LOGO_REQUEST, uploadNewDealerLogoRequest),
    yield takeLatest(types.UPLOAD_NEW_DEALER_EMAIL_IMAGE_REQUEST, uploadNewDealerEmailImageRequest),
    yield takeLatest(types.UPLOAD_NEW_DEALER_PAGE_IMAGE_REQUEST, uploadNewDealerPageImageRequest),
    yield takeLatest(types.GET_API_KEY_REQUESTS, getDealersApiTokens),
    yield takeLatest(types.CHANGE_API_KEY_REQUEST_STATUS, uploadDealerApiTokenRequest),
    yield takeLatest(types.REVOKE_API_KEY_REQUEST, revokeDealerApiKey),
    yield takeLatest(types.SEARCH_DEALERS, searchDealers),
    yield takeLatest(types.GET_TRADE_ASSOCIATIONS_REQUEST, getTradeAssociations),
    yield takeLatest(types.CHANGE_TRADE_ASSOCIATIONS_ORDER_REQUEST, modifyTradeAssociationsOrder),
    yield takeLatest(types.DELETE_TRADE_ASSOCIATION_REQUEST, deleteTradeAssociation),
    yield takeLatest(types.CREATE_TRADE_ASSOCIATION_REQUEST, createTradeAssociation),
    yield takeLatest(types.UPDATE_TRADE_ASSOCIATION_REQUEST, updateTradeAssociation),
    yield takeLatest(types.DELETE_DEALER_SPECIALITY_REQUEST, deleteDealerSpeciality),
    yield takeLatest(types.UPDATE_DEALER_SPECIALITY_REQUEST, updateDealerSpeciality),
    yield takeLatest(types.GET_DEALER_LOGS_REQUEST, getDealerLogsSaga),
  ])
}

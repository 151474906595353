import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Checkbox, Button } from "antd";
import LabeledInput from "../../../shared/LabeledInput";
import ImageUpload from "../../../shared/ImageUpload";

import {
  createNewAuthorRequest,
  uploadImageToServer
} from "../../../../redux/content/blog/blogActions";


const AddNewAuthor = ({
  error,
  processing,
  createNewAuthor,
  uploadImage,
  lastUploadedImage,
  history
}) => {
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("Author");
  const [isNameValid, setIsNameValid] = useState(true);
  const [url, setUrl] = useState(name);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [description, setDescription] = useState("description");
  const [pictureOfAuthor, setPictureOfAuthor] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    validateFields();
  }, [name, url]); // eslint-disable-line

  useEffect(() => {
    pictureOfAuthor && uploadImage(pictureOfAuthor);
  }, [pictureOfAuthor]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing) return;
    if (error) return setShowErrorText(true);

    return history.push("/content/blog/authors");
  }, [error, processing]); // eslint-disable-line

  useEffect(() => {
    name && transformNameToURL();
  }, [name]); // eslint-disable-line

  const transformNameToURL = () => {
    const transformedURL = name.toLowerCase().replace(/ /ig, "-").replace(/&/ig, "and");
    setUrl(transformedURL);
  };

  const validateFields = () => {
    setIsNameValid(name !== "");
    setIsUrlValid(url !== "");
  };

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createNewAuthor = () => {
    validateFields();
    if (!isNameValid || !isUrlValid) return;

    setFormSubmitted(true);

    createNewAuthor({
      isVisible: isActive,
      name,
      url,
      description
    });
  };

  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  return (
    <div className="add-author">
      <div className="add-author--row">
        <span className="add-author--title">Add Author</span>
      </div>

      <div className="add-author--row checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isActive}
          onChange={onCheck}
        >
          Active
        </Checkbox>
      </div>

      <LabeledInput
        label="Name"
        isRequired
        value={name}
        onChange={(value) => setName(value)}
      />
      {!isNameValid ? (
        <p className="error">Can't be blank. This field is required</p>
      ) : showErrorText ? (
        <p className="error">Author with such name is already exist</p>
      ) : null}
      <div className="add-author--url-input-holder">
        <LabeledInput
          label="URL"
          isRequired
          value={url}
          inputClassName="input-with-prefix"
          addonBefore="https://www.loveantiques.com/blog/author/"
          onChange={(value) => setUrl(value)}
          icon={urlFieldImage}
        />
        {!isUrlValid && (
          <p className="error">Can't be blank. This field is required</p>
        )}
      </div>

      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={(value) => setDescription(value)}
        className="add-author--description-input"
      />

      <ImageUpload
        topText="Picture of Author"
        setImage={setPictureOfAuthor}
        lastUploadedImage={lastUploadedImage || {}}
        bottomText="Image must be at least 300 x 300 pixels"
        width={256}
        height={256}
      />

      <div className="add-author--button-holder">
        <Button className="ant-btn-primary" onClick={_createNewAuthor}>
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button className="btn-secondary" onClick={() => history.push("/content/blog/authors")}>
            CANCEL
          </Button>
        </div>

      </div>
    </div>
  )
}

AddNewAuthor.propTypes = {
  createNewAuthor: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  error: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  lastUploadedImage: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default connect(
  state => state.blogs,
  dispatch => ({
    createNewAuthor: payload => dispatch(createNewAuthorRequest(payload)),
    uploadImage: payload => dispatch(uploadImageToServer(payload))
  })
)(AddNewAuthor);

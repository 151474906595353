import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

// components
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";

// react-queries
import { useTradeSubscriptionList } from "../../../react-query";

// helpers
import navigation from "../../../helpers/navigation";
import { buildFullName } from "../../../helpers";


export const DirectorySubscriptions = () => {
  const location = useLocation();
  const { page, perPage } = navigation.getTableQueryParams(location);

  const { data } = useTradeSubscriptionList({
    page: page,
    page_size: perPage
  });

  return (
    <div className="directory-subscriptions">
      <Table
        className="directory-subscriptions--table"
        columnWidths={["120px", "auto", "110px", "130px", "150px"]}
        headerCells={[
          { label: "STATUS" },
          { label: "TRADER" },
          { label: "PLAN" },
          { label: "CARD" },
          { label: "CURRENT PERIOD END" },
        ]}
      >
        {data?.results?.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <div className="flex">
                {item.isActive ? (
                  <div className="dealers-list--status-column--index-active">Active</div>
                ) : (
                  <div className="dealers-list--status-column--unconfirmed">Inactive</div>
                )}
              </div>
              <div className="flex">
                {buildFullName(item.user) || item.user?.email}
              </div>
              <div className="flex">
                {item.plan?.name || "-"}
              </div>
              <div className="flex">
                {`**** **** **** ${item.card || "****"}`}
              </div>
              <div className="flex">
                {item.currentPeriodEnd ? moment(item.currentPeriodEnd).format("ddd D MMM YYYY HH:mm") : "-"}
              </div>
            </TableRow>
          )
        })}
      </Table>

      {data?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={data.count}
        />
      )}
    </div>
  )
};

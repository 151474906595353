import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { DatePicker, Input, InputNumber } from "antd";

// components
import { LantForm, LantFormItem } from "../../../../components";

export const PromoCodeForm = ({ form, title, onSubmit }) => {
  const dayToday = moment();

  return (
    <LantForm
      form={form}
      initialValues={{ country: "GB", status: "ACTIVE" }}
      title={title}
      onSubmit={onSubmit}
    >
      <LantFormItem
        lg={12}
        xs={24}
        name="code"
        label="Promo Code"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter Promo Code" />
      </LantFormItem>
      <LantFormItem
        lg={12}
        xs={24}
        name="termDays"
        label="Term (days)"
        rules={[{ required: true }]}
      >
        <InputNumber
          className="w-100"
          min={0}
          placeholder="Enter Term (days)"
        />
      </LantFormItem>
      <LantFormItem
        lg={12}
        xs={24}
        name="activeTo"
        label="Active To"
        rules={[{ required: true }]}
      >
        <DatePicker
          className="w-100"
          placeholder="Select Active To"
          disabledDate={(date) => date && date.isBefore(dayToday)}
        />
      </LantFormItem>
      <LantFormItem
        lg={12}
        xs={24}
        name="activationsLimit"
        label="Activations Limit"
        rules={[{ required: true }]}
      >
        <InputNumber
          className="w-100"
          min={0}
          placeholder="Enter Activations Limit"
        />
      </LantFormItem>
    </LantForm>
  );
};

PromoCodeForm.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
};

import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = async ({ id, isFairChanged, ...data }) => {
  const resp = await fetch({ url: `/profiles/trade-profiles/${id}/`, method: "PATCH", data });

  /* TODO(BE): need to be moved directories deleting to BE side */
  if (isFairChanged && data?.isFair) {
    const directories = await fetch({ url: `/directory/?owner=${id}&page=1&page_size=200`, method: "GET" });

    if (directories?.results?.length) {
      try {
        await Promise.all(directories.results.map((el) => fetch({
          url: `/directory/${el.id}/`,
          method: "DELETE"
        })));
      } catch (err) {
        console.error(err);

        return resp;
      }
    }
  } else if (isFairChanged) {
    const fairs = await fetch({ url: `/fairs/?owner=${id}&page=1&page_size=200`, method: "GET" });

    if (fairs?.results?.length) {
      try {
        await Promise.all(fairs.results.map((el) => fetch({
          url: `/fairs/${el.id}/`,
          method: "DELETE"
        })));
      } catch (err) {
        console.error(err);

        return resp;
      }
    }
  }

  return resp;
};

export const useTradeProfilePatch = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["trade-profile-list"]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

import React from "react";
import PropTypes from "prop-types";


function DeleteIcon({ fillColor = "#FF0000", className = "", onClick }) {
  return (
    <svg onClick={onClick} className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      {/*<title>EF36A6B8-25B1-4221-A058-EFC0D5B3F240@3x</title>*/}
      <desc>Created with sketchtool.</desc>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Items-Lightbox" transform="translate(-748.000000, -833.000000)" fill={fillColor}>
          <g id="icon/24/delete" transform="translate(744.000000, 829.000000)">
            <path d="M16,7.2 L20,7.2 L20,8.8 L18.4,8.8 L18.4,19.2 C18.4,19.6418278 18.0418278,20 17.6,20 L6.4,20 C5.9581722,20 5.6,19.6418278 5.6,19.2 L5.6,8.8 L4,8.8 L4,7.2 L8,7.2 L8,4.8 C8,4.3581722 8.3581722,4 8.8,4 L15.2,4 C15.6418278,4 16,4.3581722 16,4.8 L16,7.2 Z M16.8,8.8 L7.2,8.8 L7.2,18.4 L16.8,18.4 L16.8,8.8 Z M13.1312,13.6 L14.5456,15.0144 L13.4144,16.1456 L12,14.7312 L10.5856,16.1456 L9.4544,15.0144 L10.8688,13.6 L9.4544,12.1856 L10.5856,11.0544 L12,12.4688 L13.4144,11.0544 L14.5456,12.1856 L13.1312,13.6 Z M9.6,5.6 L9.6,7.2 L14.4,7.2 L14.4,5.6 L9.6,5.6 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  )
}

DeleteIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default DeleteIcon

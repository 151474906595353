import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Form, message } from "antd";

// components
import { LantItemLayout } from "../../../../components";
import { FairSpecialityForm } from "../../components/FairSpecialityForm";

// helpers
import { setServerErrorsToForm } from "../../../../helpers";
import { useSingleSpeciality, useCreateSpeciality, usePatchSpeciality } from "./hooks";


export const SingleSpeciality = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const type = location.pathname.includes("fairs") ? "fairs" : "directory";

  const [form] = Form.useForm();

  const redirectToList = () => {
    history.push(type === "fairs" ? "/directory/fairs-specialities" : "/directory/directory-specialities");
  };

  const { data, isFetching, error } = useSingleSpeciality({
    id,
    type,
    options: {
      onSuccess: (resp) => {
        history.replace({
          pathname: location.pathname,
          state: { path: resp.title }
        });
  
        form.setFieldsValue(resp);
      },
      enabled: !!id
    }
  });

  const mutateOptions = {
    onSuccess: () => {
      message.success(id ? "Speciality was successfully updated" : "Speciality was successfully created");
      redirectToList();
    },
    onError: (err) => {
      setServerErrorsToForm(form, err?.data);
    }
  };

  const {
    mutateAsync: createSpeciality,
    isLoading: isCreating
  } = useCreateSpeciality({ type, options: mutateOptions });
  const {
    mutateAsync: updateSpeciality,
    isLoading: isUpdating
  } = usePatchSpeciality({ type, options: mutateOptions });

  const onSubmit = (values) => {
    if (id) {
      updateSpeciality({ data: values, id });
    } else {
      createSpeciality(values);
    }
  };

  return (
    <LantItemLayout item="fair-speciality" error={error} processing={isFetching || isCreating || isUpdating}>
      <FairSpecialityForm
        form={form}
        title={id ? `Edit ${data?.title || ""}` : "Add speciality"}
        onSubmit={onSubmit}
        onCancel={redirectToList}
      />
    </LantItemLayout>
  );
};

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const OrderHistorySection = ({ historyData }) => {
  return (
    <div className="order-header--order-history-wrapper">
      <div className="header-tab-column-title">Order History</div>
      {historyData.length ? (
        historyData.map((item, index) => (
          <div className="order-history-column-info" key={index}>
            <div className="order-history-column-info-field">
              <div className="header-tab-column-label">
                {moment(item.date).format("D/M/YYYY hh:mm:ss")}
              </div>
              <div className="history-description-field-value">
                {item.description}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="order-history-column-info">
          <div className="history-description-empty-field">
            No order history
          </div>
        </div>
      )}
    </div>
  );
};

OrderHistorySection.propTypes = {
  historyData: PropTypes.array.isRequired,
};

export default OrderHistorySection;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const STATUS_CLASSNAMES = {
  Paid: "order-status-field-value--paid",
  Completed: "order-status-field-value--completed",
  Bounced: "order-status-field-value--bounced",
  Refunded: "order-status-field-value--refunded",
};

const HeaderSection = ({ headerData, status }) => {
  const { cartId, attempts, date, invoiceDate, invoiceRef, orderRef } =
    headerData;

  return (
    <div className="order-header--header-column-wrapper">
      <div className="header-tab-column-title">Header</div>
      <div className="header-column-info">
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Order Status</div>
          <div className="order-status-field-value">
            {status ? (
              <div key={`key-${status}`} className={STATUS_CLASSNAMES[status]}>
                {status}
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Cart ID</div>
          <div className="cart-id-field-value">{cartId || "-"}</div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Date</div>
          <div className="date-field-value">
            {date ? moment(date).format("LLL") : "-"}
          </div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Attempts</div>
          <div className="attempts-field-value">{attempts || "-"}</div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Order Ref</div>
          <div className="order-ref-field-value">{orderRef || "-"}</div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Invoice Ref</div>
          <div className="invoice-ref-field-value">{invoiceRef || "-"}</div>
        </div>
        <div className="header-column-info-field">
          <div className="header-tab-column-label">Invoice Date</div>
          <div className="invoice-date-field-value">
            {invoiceDate ? moment(invoiceDate).format("LLL") : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSection.propTypes = {
  headerData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default HeaderSection;

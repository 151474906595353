import fetch from "./customFetch";
import { UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR, SOMETHING_IS_WRONG_ERROR } from "../constants";


const auth = {
  async login(data) {
    try {
      const result = await fetch({
        url: "/auth/",
        method: "POST",
        data
      });

      localStorage.setItem("token", JSON.stringify(result));

      return result;
    } catch (err) {
      if (err?.response?.data?.detail) {
        throw Error(err.response.data.detail);
      }
      throw Error(err.message || UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR);
    }
  },

  async getUserProfile() {
    try {
      return await fetch({
        url: "/profiles/",
        method: "GET"
      });
    }
    catch (err) {
      throw Error(UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR);
    }
  },

  async postResendConfirmationEmail(data) {
    try {
      return await fetch({
        url: "/profiles/resend-confirmation-email/",
        method: "POST",
        data
      });
    }
    catch (err) {
      throw Error(UNAUTHORIZED_SOMETHING_IS_WRONG_ERROR);
    }
  },

  async forgotPassword(data) {
    try {
      return await fetch({
        url: "/profiles/password/forget/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.response && err.response.data && err.response.data.detail) {
        throw Error(err.response.data.detail);
      }
      throw Error(SOMETHING_IS_WRONG_ERROR);
    }
  },

  async resetPassword(data) {
    try {
      return await fetch({
        url: "/profiles/password/reset/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err && err.data && err.data) {
        const message = [];
        Object.keys(err.data).forEach(key => message.push(err.data[key].join(", ")));

        throw message.join(", ");
      }
      throw SOMETHING_IS_WRONG_ERROR;
    }
  },

  logout() {
    localStorage.removeItem("token");

    return true;
  },

  async authPinterest(data) {
    try {
      return await fetch({
        url: "/pinterest/auth/",
        method: "POST",
        data
      });
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.detail) {
        throw Error(err.response.data.detail);
      }
      throw Error(SOMETHING_IS_WRONG_ERROR);
    }
  },

  async getPinterestTokenInfo() {
    try {
      return await fetch({
        url: "/pinterest/token/",
        method: "GET",
      });
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.detail) {
        throw Error(err.response.data.detail);
      }
      throw Error(SOMETHING_IS_WRONG_ERROR);
    }
  }
};

export default auth;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ChromePicker } from "react-color";
import { Button, Modal as ModalWindow } from "antd";

import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";

import {
  addAdminAccountRequest,
  getAdminRolesRequest,
} from "../../../redux/settings/adminAccounts/adminAccountsActions";

import { useClickOutside } from "../../../hooks";

import helper from "./helpers/AdminHelper";
import contentAPI from "../../../api/content";

const AddAdminAccount = ({
  history,
  getAdminRoles,
  adminRoles,
  addAdminAccount,
  processing,
  error,
  userRole,
}) => {
  const [confirmationValue, setConfirmationValue] = useState("UNCONFIRMED");
  const [loginValue, setLoginValue] = useState(false);
  const [accountFirstName, setAccountFirstName] = useState();
  const [accountLastName, setAccountLastName] = useState();
  const [accountNickName, setAccountNickName] = useState();
  const [accountRole, setAccountRole] = useState();
  const [accountEmail, setAccountEmail] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [accountMobile, setAccountMobile] = useState();
  const [accountImage, setAccountImage] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [color, setColor] = useState("chartreuse");
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  const ref = useRef(null);
  useClickOutside(ref, () => setColorPickerOpen(false));

  const isDisabled =
    !userRole.includes("Super Admin") && !userRole.includes("AdminLv1");

  useEffect(() => {
    getAdminRoles();
  }, []); // eslint-disable-line

  // Mark form as submitted
  useEffect(() => {
    if (!processing) return;
    setOpenConfirmationModal(true);
  }, [processing]); // eslint-disable-line

  const uploadImage = (file, type) => {
    contentAPI
      .uploadNewImage(file)
      .then((result) => {
        if (type === "image") setAccountImage(result.response);
      })
      .catch(console.warn);
  };

  const validationHandler = (fields) => {
    const errors = {};
    Object.keys(fields).forEach((key) => {
      if (!fields[key]) errors[key] = "Field is required";
    });
    setValidationErrors(errors);

    return !Object.keys(errors).length;
  };

  const handleSaveButton = () => {
    const requiredFields = {
      email: accountEmail,
      firstName: accountFirstName,
      lastName: accountLastName,
      role: accountRole,
    };

    const data = {
      ...requiredFields,
      color,
      mobile: accountMobile,
      phone: accountPhone,
      nickname: accountNickName,
      confirmation: confirmationValue,
      isActive: loginValue,
      shouldInvite: confirmationValue === "UNCONFIRMED",
      url: `${window.location.origin}/auth/reset-password`,
    };

    if (accountImage) {
      data.image = accountImage;
    }

    if (validationHandler(requiredFields)) {
      addAdminAccount(data);
    }
  };

  const fieldOnChange = (setValue, key) => (value) => {
    const copyValidationErrors = { ...validationErrors };
    delete copyValidationErrors[key];
    setValidationErrors(copyValidationErrors);

    setValue(value);
  };

  const onOkModal = () => {
    setOpenConfirmationModal(false);
    if (!error) history.push("/settings/admin-accounts");
  };

  const renderConfirmationModal = () => (
    <ModalWindow
      centered
      visible={openConfirmationModal}
      className="modal-block add-admin-acc-confirmation-modal"
      onCancel={() => setOpenConfirmationModal(false)}
      onOk={onOkModal}
      title={
        <div className="add-admin-acc-confirmation-modal--info">
          {!error && accountRole ? (
            <div className="add-admin-acc-confirmation-modal--warning">
              Admin account info has been successfully created!
            </div>
          ) : (
            <div className="add-admin-acc-confirmation-modal--warning">
              Oops, something went wrong! Please try again.
            </div>
          )}
          {error?.nonFieldErrors?.length && (
            <div>
              {error?.nonFieldErrors.map((nonFieldError, index) => (
                <p key={index} className="labeled-input--error">
                  {nonFieldError}
                </p>
              ))}
            </div>
          )}
        </div>
      }
    />
  );

  // Render
  const err = typeof error === "object" ? error : {};

  return (
    <div className="add-admin-account">
      <div className="add-admin-account--top-section">
        <div className="add-admin-account--top-section--account-image-and-info">
          <div className="add-admin-account--top-section--account-image-and-info--account-image">
            <ImageUpload
              lastUploadedImage={accountImage || {}}
              setImage={(file) => uploadImage(file, "image")}
              width={65}
              height={65}
            />
          </div>

          <div className="add-admin-account--top-section--account-image-and-info--account-info">
            <div className="add-admin-account--top-section--account-image-and-info--account-info--confirmation">
              <div className="add-admin-account--top-section--account-image-and-info--account-info--label">
                Confirmation
              </div>
              <div className="add-admin-account--top-section--account-image-and-info--account-info--value">
                {helper.confirmationDoubleToggleButton(
                  confirmationValue === "CONFIRMED",
                  "Confirmed",
                  "Unconfirmed",
                  setConfirmationValue
                )}
              </div>
            </div>
            <div className="add-admin-account--top-section--account-image-and-info--account-info--login">
              <div className="add-admin-account--top-section--account-image-and-info--account-info--label">
                Login
              </div>
              <div className="add-admin-account--top-section--account-image-and-info--account-info--value">
                {helper.loginDoubleToggleButton(
                  loginValue,
                  "Enabled",
                  "Disabled",
                  setLoginValue
                )}
              </div>
            </div>
            <div className="edit-admin-account--color-input">
              <div className="edit-admin-account--top-section--account-image-and-info--account-info--label">
                Chart Color
              </div>
              <div
                className="edit-admin-account--color-input-preview"
                style={{ backgroundColor: color }}
                onClick={() => setColorPickerOpen(!colorPickerOpen)}
              />
              {colorPickerOpen ? (
                <div ref={ref} className="edit-admin-account--color-picker">
                  <ChromePicker
                    disableAlpha={true}
                    color={color}
                    onChangeComplete={(e) => setColor(e.hex)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="add-admin-account--fields-section">
        <div className="add-admin-account--fields-section--first-name-and-last-name-row">
          <LabeledInput
            isRequired
            label="First Name"
            value={accountFirstName}
            onChange={fieldOnChange(setAccountFirstName, "firstName")}
            errorText={err && (err.firstName || validationErrors.firstName)}
          />
          <LabeledInput
            isRequired
            label="Last Name"
            value={accountLastName}
            onChange={fieldOnChange(setAccountLastName, "lastName")}
            errorText={err && (err.lastName || validationErrors.lastName)}
          />
        </div>
        <div className="add-admin-account--fields-section--nickname-and-role-row">
          <LabeledInput
            label="Nickname"
            value={accountNickName}
            onChange={setAccountNickName}
            errorText={err && err.nickname}
          />

          <LabeledInput
            isRequired
            label="Role"
            type="select"
            value={accountRole}
            onChange={fieldOnChange(setAccountRole, "role")}
            options={adminRoles.map((r) => ({ label: r.name, value: r.id }))}
            errorText={validationErrors.role}
          />
        </div>

        <div className="add-admin-account--fields-section--email-row">
          <LabeledInput
            isRequired
            label="Email"
            value={accountEmail}
            onChange={fieldOnChange(setAccountEmail, "email")}
            errorText={err && (err.email || validationErrors.email)}
          />
        </div>
        <div className="add-admin-account--fields-section--phone-and-mobile-row">
          <LabeledInput
            label="Phone"
            value={accountPhone}
            onChange={setAccountPhone}
            errorText={err && err.phone}
          />
          <LabeledInput
            label="Mobile"
            value={accountMobile}
            onChange={setAccountMobile}
            errorText={err && err.mobile}
          />
        </div>
      </div>
      <div className="add-admin-account--action-buttons">
        <Button
          className="ant-btn-primary add-admin-account--action-buttons--save-btn"
          onClick={() => handleSaveButton()}
          disabled={isDisabled}
        >
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button
            className="btn-secondary add-admin-account--action-buttons--cancel-btn"
            onClick={() => history.push("/settings/admin-accounts")}
            disabled={isDisabled}
          >
            CANCEL
          </Button>
        </div>
      </div>
      {renderConfirmationModal()}
    </div>
  );
};

AddAdminAccount.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getAdminRoles: PropTypes.func.isRequired,
  addAdminAccount: PropTypes.func.isRequired,
  adminRoles: PropTypes.array.isRequired,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.any,
  userRole: PropTypes.array,
};

export default connect(
  (state) => ({
    adminRoles: state.adminAccounts.adminRoles,
    processing: state.adminAccounts.processing,
    error: state.adminAccounts.error,
    userRole: state.auth.userProfile.roles,
  }),
  (dispatch) => ({
    addAdminAccount: (payload) => dispatch(addAdminAccountRequest(payload)),
    getAdminRoles: (payload) => dispatch(getAdminRolesRequest(payload)),
  })
)(AddAdminAccount);

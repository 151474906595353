import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/fairs/${id}/statistic/`, method: "GET" });
};

export const useFairStatistic = (id, options) => {
  return useQuery(["fair-statistic", id], () => fetcher(id), options);
};

import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { LoginOutlined } from "@ant-design/icons";

// react-queries
import { useImpersonate } from "../../react-query";

export const Impersonate = ({ type, id }) => {
  const { mutate } = useImpersonate();

  const handleClick = (event) => {
    event.preventDefault();

    mutate(id);
  };

  return (
    <Tooltip title={`Log in as a ${type} to LoveAntiques.com`}>
      <div className="cursor-pointer lant-icon" onClick={handleClick}>
        <LoginOutlined />
      </div>
    </Tooltip>
  );
};

Impersonate.propTypes = {
  type: PropTypes.oneOf(["dealer", "trade user"]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const SHOW_LOADING = "SHOW_CURRENCY_CODES_LOADING";
export const HIDE_LOADING = "HIDE_CURRENCY_CODES_GROUPS_LOADING";
export const TOGGLE_PROCESSING = "TOGGLE_CURRENCY_CODES_PROCESSING";
export const RESET_ERROR = "RESET_CURRENCY_CODES_ERROR";

export const GET_CURRENCY_CODES_REQUEST = "GET_CURRENCY_CODES_REQUEST";
export const GET_CURRENCY_CODES_REQUEST_SUCCESS = "GET_CURRENCY_CODES_REQUEST_SUCCESS";

export const CREATE_CURRENCY_CODES_REQUEST = "CREATE_CURRENCY_CODES_REQUEST";
export const UPDATE_CURRENCY_CODES_REQUEST = "UPDATE_CURRENCY_CODES_REQUEST";
export const DELETE_CURRENCY_CODES_REQUEST = "DELETE_CURRENCY_CODES_REQUEST";

export const CURRENCY_CODES_REQUEST_ERROR = "CURRENCY_CODES_REQUEST_ERROR";

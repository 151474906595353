import React from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment";
import PropTypes from "prop-types";

// components
import { StatusCardField, StatusColumn } from "../../../../components";
import { DirectoryEntryStatistic } from "./DirectoryEntryStatistic";

// react-queries
import { useDirectoryEntryPatch, useDirectoryStatistic, useFairPatch, useFairStatistic } from "../../../../react-query";

// helpers
import { buildFullName } from "../../../../helpers";
import { DIRECTORY_ENTRY_STATUSES } from "../../../../constants";


export const DirectoryEntryStatus = ({ data, isFair, isLoading }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const directoryStatistic = useDirectoryStatistic(id, {
    enabled: !isFair
  });
  const fairStatistic = useFairStatistic(id, {
    enabled: isFair
  });

  const patchDirectoryEntry = useDirectoryEntryPatch({
    onSuccess: (resp) => {
      queryClient.setQueryData(["directory-entry", Number(id)], resp);
    }
  });

  const patchFair = useFairPatch({
    onSuccess: (resp) => {
      queryClient.setQueryData(["fair", Number(id)], resp);
    }
  });

  if (isLoading) {
    return null;
  }

  return (
    <div className="pt-16" style={{ maxWidth: "540px" }}>
      <div className="status-card">
        <div className="status-card--title">
          {isFair ? "Fair Status" : "Directory Entry Status"}
        </div>
        <div className="status-card--content" style={{ minWidth: "540px" }}>
          <StatusCardField title="Status">
            <StatusColumn
              currentStatus={data?.status}
              statuses={DIRECTORY_ENTRY_STATUSES}
              direction="horizontal"
              onClick={(status) => {
                if (isFair) {
                  patchFair.mutate({ id: Number(id), status })
                } else {
                  patchDirectoryEntry.mutate({ id: Number(id), status })
                }
              }}
            />
          </StatusCardField>
          <StatusCardField title="Author">
            {data?.author?.id && (
              <>
                <b>{buildFullName(data.author)}</b>
                <div>{data.author?.email}</div>
              </>
            )}
          </StatusCardField>
          <StatusCardField title="Owner">
            {data?.owner?.user?.id && (
              <>
                <b>{data.owner.businessName}</b>
                <b>{buildFullName(data.owner.user)}</b>
                <div>{data.owner.user.email}</div>
              </>
            )}
          </StatusCardField>
          <StatusCardField title="Owner Since">
            {data?.owner?.user?.dateJoined && (
              moment(data.owner.user.dateJoined).format("M/D/Y")
            )}
          </StatusCardField>
          <StatusCardField title="Phone">
            {data?.phone || data?.owner?.user?.phone}
          </StatusCardField>
          <StatusCardField title="Mobile">
            {data?.owner?.user?.mobile}
          </StatusCardField>
          <StatusCardField title="Website">
            {data?.website}
          </StatusCardField>
        </div>
      </div>
      <DirectoryEntryStatistic
        label={isFair ? "Fair" : "Directory entry"}
        statistic={isFair ? fairStatistic.data : directoryStatistic.data}
        processing={fairStatistic.isLoading || directoryStatistic.isLoading}
      />
    </div>
  );
};

DirectoryEntryStatus.propTypes = {
  data: PropTypes.object,
  isFair: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

import React from "react";
import PropTypes from "prop-types";


function GeneralFormControl({ isFullScreen, toggleFullscreen, close, hideToggleFullscreen }) {
  const fullScreenIcon = isFullScreen ? "/images/fullScreenExitIcon.png" : "/images/fullscreen_3x.svg";

  return (
    <div className="general-form--row general-form--control">
      { !hideToggleFullscreen && (
        <img className="general-form--control-icon" src={fullScreenIcon} onClick={toggleFullscreen} alt="" />
      )}

      <img className="general-form--control-icon" src="/images/close.svg" onClick={close} alt="" />
    </div>
  )
}

GeneralFormControl.propTypes = {
  isFullScreen: PropTypes.bool,
  toggleFullscreen: PropTypes.func,
  hideToggleFullscreen: PropTypes.bool,
  close: PropTypes.func.isRequired
};

export default GeneralFormControl;

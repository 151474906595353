import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import AddButton from "../../../../shared/AddButton";
import Table from "../../../../shared/Table/Table";
import TableRow from "../../../../shared/Table/TableRow";
import TableFooter from "../../../../shared/Table/TableFooter";
import { Utils } from "../../../../../helpers";

import {
  getDealerNotesRequest
} from "../../../../../redux/dealers/dealersActions";
import navigation from "../../../../../helpers/navigation";

const NotesTab = ({
  match,
  history,
  location,
  getDealerNotes,
  dealerNotes,
  dealerNotesTotalNumber,
}) => {
  const url = match.params.url;
  const { order, page, perPage } = navigation.getTableQueryParams(location);


  useEffect(() => {
    getDealerNotes({ url, page, perPage });
  }, [page, perPage]); // eslint-disable-line


  const renderReminderDate = (date) => {
    if (!date) return "-";

    return moment().to(moment(date));
  };


  // Render
  const orderedDealerNotes = Utils.filterAndOrder(dealerNotes, { order });

  return (
    <div className="dealer-notes-tab">
      <div className="dealer-notes-tab--add-button">
        <AddButton
          onClick={() => history.push(`/dealers/dealers-list/${url}/notes-tab/add-new-note`)}
        />
      </div>

      {
        dealerNotesTotalNumber ? (
          <Table
            className="dealer-notes-tab--table"
            columnWidths={["12%", "11%", "7%", "61%", "9%"]}
            headerCells={[
              { label: "date" },
              { label: "status" },
              { label: "user" },
              { label: "notes" },
              { label: "reminder in" },
            ]}
          >
            {
              orderedDealerNotes && orderedDealerNotes.map(({
                id,
                user,
                date,
                status,
                notes,
                reminderIn,
              }) => {
                const formattedDate = moment(date).format("D/M/YYYY hh:mm:ss");
                const userFullName = user ? `${user.firstName} ${user.lastName}` : "";

                return (
                  <TableRow key={`dealer-notes-tab--row-${id}`}>
                    <div className="dealer-notes-tab--date-column flex">
                      {formattedDate}
                    </div>
                    <div className="dealer-notes-tab--status-column flex">
                      {status}
                    </div>
                    <div className="dealer-notes-tab--user-column flex">
                      {userFullName}
                    </div>
                    <div className="dealer-notes-tab--notes-column flex">
                      {notes}
                    </div>
                    <div className="dealer-notes-tab--reminder-in-column flex">
                      {renderReminderDate(reminderIn)}
                    </div>
                  </TableRow>
                )
              })
            }

            {
              dealerNotesTotalNumber && (
                <TableFooter
                  page={page}
                  perPage={perPage}
                  total={dealerNotesTotalNumber}
                />
              )
            }
          </Table>
        ) : (
          <div className="dealer-notes-tab--empty-data-message">
            There aren't available notes for this dealer.
          </div>
        )
      }
    </div>
  )
};

NotesTab.propTypes = {
  match: PropTypes.object.isRequired,
  getDealerNotes: PropTypes.func.isRequired,
  dealerNotes: PropTypes.array.isRequired,
  dealerNotesTotalNumber: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  ({ dealers }) => dealers,
  dispatch => ({
    getDealerNotes: payload => dispatch(getDealerNotesRequest(payload)),
  })
)(withRouter(NotesTab));

import * as types from "./offersActionsTypes";

const initialState = {
  offers: [],
  userOffers: [],
  offersTotalNumber: 0,
  userOffersTotalNumber: 0,
  processing: false,
  errors: {},
};

const offers = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OFFERS_REQUEST_SUCCESS:
      return {
        ...state,
        offers: action.payload.results,
        offersTotalNumber: action.payload.count
      };

    case types.GET_USER_OFFERS_REQUEST_SUCCESS:
      return {
        ...state,
        userOffers: action.payload.results,
        userOffersTotalNumber: action.payload.count
      };

    case types.GET_OFFERS_REQUEST_ERROR:
    case types.PATCH_OFFERS_REQUEST_ERROR:
    case types.CREATE_OFFER_REQUEST_ERROR:
    case types.CREATE_USER_OFFER_REQUEST_ERROR:
    case types.GET_USER_OFFERS_REQUEST_ERROR:
      return {
        ...state,
        errors: action.payload
      };

    case types.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };

    case types.SHOW_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_PROCESSING:
      return {
        ...state,
        processing: false
      };

    default:
      return state;
  }
};

export default offers;

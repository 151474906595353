import React from "react";


export const EditIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    focusable="false"
    data-icon="edit"
    aria-hidden="true"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-1300.000000, -316.000000)">
        <g transform="translate(1296.000000, 312.000000)">
          <path
            d="M15.3321696,9.9267625 L14.0732375,8.6678304 L5.78066776,16.9604001 L5.78066776,18.2193322 L7.03959986,18.2193322 L15.3321696,9.9267625 Z M16.5911017,8.6678304 L17.8500338,7.40889829 L16.5911017,6.14996619 L15.3321696,7.40889829 L16.5911017,8.6678304 Z M7.77679631,20 L4,20 L4,16.2223134 L15.9616357,4.2606777 C16.3093109,3.91310743 16.8728925,3.91310743 17.2205678,4.2606777 L19.7393223,6.77943224 C20.0868926,7.12710749 20.0868926,7.6906891 19.7393223,8.03836434 L7.77768665,20 L7.77679631,20 Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

import * as types from "./catalogActionTypes";


export const getCatalogEntityElementsRequest = (entityUrl, params) => {
  return { type: types.GET_CATALOG_ENTITY_ELEMENTS_REQUEST, entityUrl, params };
};

export const createCatalogEntityElementRequest = (entityUrl, data, callback) => {
  return { type: types.CREATE_CATALOG_ENTITY_ELEMENT_REQUEST, entityUrl, data, callback };
};

export const updateCatalogEntityElementRequest = (entityUrl, itemUrl, data, isPatch = false, callback) => {
  return { type: types.UPDATE_CATALOG_ENTITY_ELEMENT_REQUEST, entityUrl, itemUrl, data, isPatch, callback };
};

export const deleteCatalogEntityElementRequest = (entityUrl, itemUrl, callback) => {
  return { type: types.DELETE_CATALOG_ENTITY_ELEMENT_REQUEST, entityUrl, itemUrl, callback };
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR };
};

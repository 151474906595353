import * as types from "./websiteActionTypes";

const initialState = {
  publishRequests: {
    publishRequests: [],
    totalPublishRequests: 0,
    error: null,
  },
  domainRequests: {
    domainRequests: [],
    totalDomainRequests: 0,
    error: null
  },
  dealerWebsites: {
    websites: [],
    totalWebsites: 0,
    error: null
  }
};

const websiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEBSITE_PUBLISH_REQUESTS_SUCCESS:
      return {
        ...state,
        publishRequests: {
          publishRequests: action.payload.results,
          totalPublishRequests: action.payload.count,
          error: null,
        }
      };

    case types.GET_WEBSITE_PUBLISH_REQUESTS_ERROR:
    case types.CHANGE_PUBLISH_REQUEST_STATUS_ERROR:
      return {
        ...state,
        publishRequests: {
          ...state.publishRequests,
          error: action.payload,
        }
      }

    case types.GET_WEBSITE_DOMAIN_REQUESTS_SUCCESS:
      return {
        ...state,
        domainRequests: {
          domainRequests: action.payload.results,
          totalDomainRequests: action.payload.count,
          error: null
        }
      }

    case types.GET_WEBSITE_DOMAIN_REQUESTS_ERROR:
    case types.CHANGE_DOMAIN_REQUEST_STATUS_ERROR:
      return {
        ...state,
        domainRequests: {
          ...state.domainRequests,
          error: action.payload
        }
      }

    case types.GET_DEALER_WEBSITES_REQUEST_SUCCESS:
      return {
        ...state,
        dealerWebsites: {
          websites: action.payload.results,
          totalWebsites: action.payload.count,
          error: null
        }
      }

    case types.CHANGE_DEALER_WEBSITE_STATUS_REQUEST_ERROR:
    case types.GET_DEALER_WEBSITES_REQUEST_ERROR:
      return {
        ...state,
        dealerWebsites: {
          ...state.dealerWebsites,
          error: action.payload
        }
      }

    default:
      return state
  }
};

export default websiteReducer;

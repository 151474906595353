import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { EditTradeUser } from "./pages/EditTradeUser";
import { TraderList } from "./pages/TraderList";


export const TraderRoutes = () => {
  return (
    <div className="component traders-page">
      <Switch>
        <Route path="/trade-users" exact component={TraderList} />
        <Route path="/trade-users/:id" exact component={EditTradeUser} />
        <Redirect to="/trade-users" />
      </Switch>
    </div>
  )
};

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";

// helpers
import { Utils } from "../../helpers";

const PATH_MAP = {
  "dashboard": "Dashboard",
  "content": "Content",
  "help-text": "Help Text",
  "pages": "Pages",
  "catalog": "Catalog",
  "dealers-list": "Dealers List",
  "dealer-news": "Dealer News",
};


const BreadCrumbs = () => {
  const location = useLocation();

  const [items, setItems] = useState([]);

  useEffect(() => {
    let isNumberPath = false;

    const parts = location.pathname
      .split("/")
      .map((el) => {
        if (isNumberPath) {
          return "";
        } else if (Number.parseFloat(el)) {
          isNumberPath = true;

          return location.state?.path || "Edit";
        }

        return el;
      })
      .filter((el) => el);

    if (!parts.length) {
      parts.push("dashboard");
    }

    let currentPath = "";

    setItems(parts.map(part => {
      currentPath += `/${part}`;

      return {
        label: PATH_MAP[part] || Utils.humanizePath(part),
        link: currentPath || "/"
      };
    }));
  // eslint-disable-next-line
  }, [location.pathname, location.state?.path]);

  return (
    <div className="breadcrumbs">
      <Breadcrumb separator=">">
        {items.map((item, index) => items.length - 1 > index ? (
          <Breadcrumb.Item key={item.link} className="breadcrumbs--link">
            <Link to={item.link}>
              {item.label}
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={item.link} className="breadcrumbs--link breadcrumbs--link__selected">
            {item.label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  )
};

export default BreadCrumbs;

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./websiteActionTypes";
import websiteAPI from "../../api/website";
import navigation from "../../helpers/navigation";

function* getWebsitePublishRequests({ payload }) {
  try {
    const response = yield call(websiteAPI.getPublishRequests, payload);
    yield put({ type: types.GET_WEBSITE_PUBLISH_REQUESTS_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_WEBSITE_PUBLISH_REQUESTS_ERROR, payload: err.detail });
  }
}

function* changeWebsitePublishRequestStatus({ payload }) {
  try {
    const {
      page,
      perPage,
      search,
      statusOption,
      dateOption
    } = navigation.getTableQueryParams(payload.location);
    const params = {
      page,
      perPage,
      search,
      statusOption,
      dateOption
    };

    yield call(websiteAPI.changePublishRequestStatus, { id: payload.id, data: payload.data });
    yield put({ type: types.GET_WEBSITE_PUBLISH_REQUESTS, payload: params });
  } catch (err) {
    yield put({ type: types.CHANGE_PUBLISH_REQUEST_STATUS_ERROR, payload: err.detail });
  }
}

function* getWebsiteDomainRequests({ payload }) {
  try {
    const response = yield call(websiteAPI.getDomainRequests, payload);
    yield put({ type: types.GET_WEBSITE_DOMAIN_REQUESTS_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_WEBSITE_DOMAIN_REQUESTS_ERROR, payload: err.detail });
  }
}

function* changeWebsiteDomainRequestStatus({ payload }) {
  try {
    const {
      page,
      perPage,
      search,
      statusOption,
      dateOption
    } = navigation.getTableQueryParams(payload.location);
    const params = {
      page,
      perPage,
      search,
      statusOption,
      dateOption
    }

    yield call(websiteAPI.changeDomainRequestStatus, { id: payload.id, data: payload.data });
    yield put({ type: types.GET_WEBSITE_DOMAIN_REQUESTS, payload: params });
  } catch (err) {
    yield put({ type: types.CHANGE_DOMAIN_REQUEST_STATUS_ERROR, payload: err.detail });
  }
}

function* getDealerWebsites({ payload }) {
  try {
    const response = yield call(websiteAPI.getDealerWebsites, payload);
    yield put({ type: types.GET_DEALER_WEBSITES_REQUEST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_DEALER_WEBSITES_REQUEST_ERROR, payload: err.detail });
  }
}

function* changeDealerWebsiteStatus({ payload }) {
  try {
    yield call(websiteAPI.changeDealerWebsiteStatus, payload);
  } catch (err) {
    yield put({ type: types.CHANGE_DEALER_WEBSITE_STATUS_REQUEST_ERROR, payload: err.detail });
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_WEBSITE_PUBLISH_REQUESTS, getWebsitePublishRequests),
    yield takeLatest(types.CHANGE_PUBLISH_REQUEST_STATUS, changeWebsitePublishRequestStatus),
    yield takeLatest(types.GET_WEBSITE_DOMAIN_REQUESTS, getWebsiteDomainRequests),
    yield takeLatest(types.CHANGE_DOMAIN_REQUEST_STATUS, changeWebsiteDomainRequestStatus),
    yield takeLatest(types.GET_DEALER_WEBSITES_REQUEST, getDealerWebsites),
    yield takeLatest(types.CHANGE_DEALER_WEBSITE_STATUS_REQUEST, changeDealerWebsiteStatus)
  ])
}

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from 'lodash';

import { elasticSearchRequest } from "../../redux/dashboard/dashboardActions";

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    }
    this.elasticSearch = _.debounce(this.props.elasticSearch, 250);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.setShowSearchResult(false);
      this.setState({
        query: ''
      })
    }
  }

  handleInputChange = () => {
    this.setState({
      query: this.search.value
    }, () => {
      if (this.state.query && this.state.query.length >= 1) {
        this.props.setShowSearchResult(true)
        this.elasticSearch(this.state.query);
      } else this.props.setShowSearchResult(false)
    })
  }

  onFocus = () => {
    this.state.query.length >= 1 && this.props.setShowSearchResult(true);
  }

  render() {
    return (
      <div className="search-input" id="global-search">
        <img
          className="search-input--icon"
          src="/images/search_3x.svg"
          alt="search"
        />
        <input
          className="search-input--input"
          placeholder="Type to search"
          ref={input => this.search = input}
          onChange={this.handleInputChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.state.query}
        />
      </div>
    )
  }
}

GlobalSearch.propTypes = {
  elasticSearch: PropTypes.func.isRequired,
  setShowSearchResult: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  ({ dashboard }) => dashboard,
  dispatch => ({
    elasticSearch: payload => dispatch(elasticSearchRequest(payload)),
  })
)(withRouter(GlobalSearch));

import { useRef, useEffect } from "react"

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]); // eslint-disable-line

  return ref.current;
}

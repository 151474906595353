import * as types from "./pagesActionsTypes";

export const getContentPagesRequest = payload => {
  return { type: types.GET_CONTENT_PAGES_REQUEST, payload };
};

export const createNewPageRequest = payload => {
  return { type: types.CREATE_NEW_PAGE_REQUEST, payload };
};

export const updatePageStatusRequest = payload => {
  return { type: types.PATCH_PAGES_STATUS_REQUEST, payload };
};

export const deletePageRequest = payload => {
  return { type: types.DELETE_PAGE_REQUEST, payload };
};

export const updatePageRequest = payload => {
  return { type: types.UPDATE_PAGE_REQUEST, payload };
};

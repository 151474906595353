import React from "react";
import PropTypes from "prop-types";


function CatalogIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="16px" height="15px" viewBox="0 0 16 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -157.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-32-icon/24/folder" transform="translate(16.000000, 152.000000)">
              <path d="M12.3312,6.66666667 L19.2,6.66666667 C19.6418278,6.66666667 20,7.03976271 20,7.5 L20,19.1666667 C20,19.626904 19.6418278,20 19.2,20 L4.8,20 C4.3581722,20 4,19.626904 4,19.1666667 L4,5.83333333 C4,5.37309604 4.3581722,5 4.8,5 L10.7312,5 L12.3312,6.66666667 Z M5.6,8.33333333 L5.6,18.3333333 L18.4,18.3333333 L18.4,8.33333333 L5.6,8.33333333 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

CatalogIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default CatalogIcon

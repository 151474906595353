import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminLayout from "../AdminLayout/AdminLayout";

import Dashboard from "../Dashboard/Dashboard";
import Content from "../Content/Content";
import Catalog from "../Catalog/Catalog";
import Orders from "../Orders/Orders";
import Items from "../Items/Items";
import Invoices from "../Invoices/Invoices";
import Enquiries from "../Enquiries/Enquiries";
import Dealers from "../Dealers/Dealers";
import { DirectoryRoutes } from "../Directory/DirectoryRoutes";
import { TraderRoutes } from "../Traders/TraderRoutes";
import UserManagement from "../UserManagement/UserManagement";
import Settings from "../Settings/Settings";
import WebsiteBuilder from "../WebsiteBuilder/WebsilteBuilder";


export default function AdminRoutes() {
  return (
    <AdminLayout>
      <Switch>
        <Route path="/content" component={Content} />
        <Route path="/catalog" component={Catalog} />
        <Route path="/items" component={Items} />
        <Route path="/orders" component={Orders} />
        <Route path="/invoices" component={Invoices} />
        <Route path="/enquiries" component={Enquiries} />
        <Route path="/website-builder" component={WebsiteBuilder} />
        <Route path="/dealers" component={Dealers} />
        <Route path="/directory" component={DirectoryRoutes} />
        <Route path="/trade-users" component={TraderRoutes} />
        <Route path="/user-management" component={UserManagement} />
        <Route path="/settings" component={Settings} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </AdminLayout>
  )
}

import React from "react";
import PropTypes from "prop-types";


function ItemsIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-21.000000, -198.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-30-icon/24/list" transform="translate(16.000000, 192.000000)">
              <path d="M11.6666667,6 L20,6 L20,7.625 L11.6666667,7.625 L11.6666667,6 Z M11.6666667,9.25 L16.6666667,9.25 L16.6666667,10.875 L11.6666667,10.875 L11.6666667,9.25 Z M11.6666667,14.125 L20,14.125 L20,15.75 L11.6666667,15.75 L11.6666667,14.125 Z M11.6666667,17.375 L16.6666667,17.375 L16.6666667,19 L11.6666667,19 L11.6666667,17.375 Z M5,6 L10,6 L10,10.875 L5,10.875 L5,6 Z M6.66666667,7.625 L6.66666667,9.25 L8.33333333,9.25 L8.33333333,7.625 L6.66666667,7.625 Z M5,14.125 L10,14.125 L10,19 L5,19 L5,14.125 Z M6.66666667,15.75 L6.66666667,17.375 L8.33333333,17.375 L8.33333333,15.75 L6.66666667,15.75 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

ItemsIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default ItemsIcon

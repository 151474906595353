import React from "react";
import { Button, Popconfirm } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";


export const LantFormFooter = ({
  className,
  okConfirmText,
  okBtnProps,
  cancelConfirmText,
  cancelBtnProps,
  onSubmit,
  onCancel
}) => {
  if (typeof onSubmit !== "function" && typeof onCancel !== "function") {
    return null;
  }

  return (
    <div className={classNames("lant-form--footer", className)}>
      {typeof onSubmit === "function" && (
        <Popconfirm
          placement="topLeft"
          title={okConfirmText}
          okText="Yes"
          cancelText="No"
          disabled={!okConfirmText}
          onConfirm={onSubmit}
        >
          <Button
            type="primary"
            {...okBtnProps}
            className={classNames("lant-form--footer__btn", okBtnProps?.className)}
            onClick={() => !okConfirmText && onSubmit?.()}
          >
            Save & close
          </Button>
        </Popconfirm>
      )}
      {typeof onCancel === "function" && (
        <Popconfirm
          placement="topLeft"
          title={cancelConfirmText}
          okText="Yes"
          cancelText="No"
          disabled={!cancelConfirmText}
          onConfirm={onCancel}
        >
          <Button
            {...cancelBtnProps}
            className={classNames("lant-form--footer__btn", cancelBtnProps?.className)}
            onClick={() => !cancelConfirmText && onCancel?.()}
          >
            {cancelBtnProps?.label || "Cancel"}
          </Button>
        </Popconfirm>
      )}
    </div>
  )
}

LantFormFooter.propTypes = {
  className: PropTypes.string,
  okConfirmText: PropTypes.string,
  okBtnProps: PropTypes.shape({
    className: PropTypes.string,
    loading: PropTypes.bool,
  }),
  cancelConfirmText: PropTypes.string,
  cancelBtnProps: PropTypes.shape({
    className: PropTypes.string,
    loading: PropTypes.bool,
    label: PropTypes.node,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import DealerStatusSection from "./DealerStatusSection";
import ActiveInvoicesSection from "./ActiveInvoicesSection";
import StatsSection from "./StatsSection";

import { getDealerStatusDataRequest } from "../../../../../redux/dealers/dealersActions";


const StatusTab = ({
  match,
  getDealerStatusData,
  dealerStatusData
}) => {
  const activeInvoicesData = dealerStatusData && _.get(dealerStatusData, "activeInvoices", {});
  const dealerStatisticsData = dealerStatusData && _.get(dealerStatusData, "stats", []);
  const url = match.params.url;

  useEffect(() => {
    getDealerStatusData(url);
  }, [match]); // eslint-disable-line

  return (
    <div className="dealer-status-tab">
      <div className="dealer-status-tab--left-column">
        <DealerStatusSection
          statusData={dealerStatusData}
        />
        <ActiveInvoicesSection
          activeInvoicesData={activeInvoicesData}
        />
      </div>
      <div className="dealer-status-tab--right-column">
        <StatsSection
          dealerStatisticsData={dealerStatisticsData}
          dealerURL={url}
        />
      </div>
    </div>
  )
};

StatusTab.propTypes = {
  match: PropTypes.object.isRequired,
  dealerStatusData: PropTypes.object.isRequired,
  getDealerStatusData: PropTypes.func.isRequired,
};

export default connect(
  ({ dealers }) => dealers,
  dispatch => ({
    getDealerStatusData: payload => dispatch(getDealerStatusDataRequest(payload))
  })
)(withRouter(StatusTab));

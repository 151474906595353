import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Tooltip, Radio, Checkbox } from "antd";

//components
import TableRow from "../../shared/Table/TableRow";
import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";

//helpers
import ContentApi from "../../../api/content";


function SeoCategoriesRow({ columnWidths, item, patchItem }) {
  const category = item.category || {};

  const [image, setImage] = useState(item ? item.image || false : false);
  const [banner, setBanner] = useState(item ? item.banner || false : false);
  const [startUploading, setStartUploading] = useState(false);
  const [description, setDescription] = useState(item ? item.description || "" : "");
  const [bottomDescription, setBottomDescription] = useState(item ? item.bottomDescription || "" : "");
  const [faqSchema, setFaqSchema] = useState(item ? item.faqSchema || "" : "");
  const [descriptionEditorType, setDescriptionEditorType] = useState("rich-text");
  const [bottomDescriptionEditorType, setBottomDescriptionEditorType] = useState("rich-text");


  useEffect(() => {
    if (!startUploading) {
      return;
    }
    if (startUploading && image) {
      patchItem(item.id, { image });
    }
    if (startUploading && banner) {
      patchItem(item.id, { banner });
    }
  }, [image, banner, startUploading]); // eslint-disable-line


  const onStatusChange = newStatus => {
    patchItem(item.id, { status: newStatus });
  };

  const onInputBlur = key => value => {
    patchItem(item.id, { [key]: value });
  };

  const uploadImage = (file, type) => {
    setStartUploading(true);
    ContentApi.uploadNewImage(file)
      .then(result => {
        if (type === "image") {
          setImage(result.response);
        }
        if (type === "banner") {
          setBanner(result.response);
        }
      })
      .catch(console.warn);
  };


  return (
    <TableRow className="seo-cat--row" columnWidths={columnWidths}>
      <div className="seo-cat--status-block">
        <StatusLabel
          label="Ok"
          currentStatus={item.status}
          value="APPROVED"
          onClick={onStatusChange}
          activeColor="#4F9A00"
          tooltipTitle="SEO category is available on LoveAntiques website"
        />
        <StatusLabel
          label="Suspended"
          currentStatus={item.status}
          value="SUSPENDED"
          onClick={onStatusChange}
          activeColor="#F89407"
          tooltipTitle="SEO category needs to be reviewed "
        />
        <StatusLabel
          label="Ignore"
          currentStatus={item.status}
          value="IGNORE"
          onClick={onStatusChange}
          activeColor="#FF0000"
          tooltipTitle="SEO category is not available on LoveAntiques website"
        />
      </div>

      <div className="seo-cat--title-block">
        <p className="seo-cat--title">{item.title || "No title"}</p>
        <p className="seo-cat--sub-title">{category.title}</p>
        <p className="seo-cat--link">/{item.url}</p>
        <p className="seo-cat--sub-title">Last Generated: {moment(item.createdAt).format("DD/MM/YYYY")}</p>
      </div>

      <div className="table-sm-text seo-cat--column-number seo-cat--column-number__red">{item.available}</div>

      <div className="seo-cat--details">
        <LabeledInput className="seo-cat--input" label="Title" defaultValue={item.title || ""} onBlur={onInputBlur("title")} />
        <LabeledInput className="seo-cat--input" label="H1" defaultValue={item.h1 || ""} onBlur={onInputBlur("h1")} />
        <LabeledInput
          className="seo-cat--input"
          label="Meta" defaultValue={item.metaDescription || ""}
          type="textarea" onBlur={onInputBlur("metaDescription")}
        />
        <LabeledInput
          className="seo-cat--input"
          label="Canonical"
          placeholder="vintage-jewellery/brooches"
          defaultValue={item.canonical || ""}
          onBlur={onInputBlur("canonical")}
        />
        <Checkbox
          className="checkbox-small seo-cat--checkbox"
          defaultChecked={item.shouldUseMeta}
          onChange={({ target }) => patchItem(item.id, { shouldUseMeta: target.checked })}
        >
          Use Custom Meta Description
        </Checkbox>
        <Checkbox
          className="checkbox-small seo-cat--checkbox"
          defaultChecked={item.noIndex}
          onChange={({ target }) => patchItem(item.id, { noIndex: target.checked })}
        >
          Not index this Seo Category
        </Checkbox>
      </div>

      <div className="seo-cat--image-holder">
        <div className="seo-cat--image-picker">
          <ImageUpload
            lastUploadedImage={image}
            setImage={file => uploadImage(file, "image")}
            topText="Seo Category Image"
            bottomText="Image must be at least 300 x 300 pixels"
            height={150}
          />
        </div>
        <div className="seo-cat--banner-picker">
          <ImageUpload
            lastUploadedImage={banner}
            setImage={file => uploadImage(file, "banner")}
            topText="Seo Category Banner"
            bottomText="Image must be at least 1420 x 440 pixels"
            height={150}
            width={300}
          />
        </div>
      </div>
      <div className="seo-cat--description-options">
        <div className="editor-type-switcher">
          <Radio.Group value={descriptionEditorType} onChange={e => setDescriptionEditorType(e.target.value)}>
            <Radio value="rich-text">
            Editor
            </Radio>
            <Radio value="textarea">
            HTML Editor
            </Radio>
          </Radio.Group>
        </div>

        <LabeledInput
          label="Description"
          type={descriptionEditorType}
          value={description}
          onChange={setDescription}
          onBlur={onInputBlur("description")}
        />

        <div className="editor-type-switcher">
          <Radio.Group
            value={bottomDescriptionEditorType}
            onChange={e => setBottomDescriptionEditorType(e.target.value)}
          >
            <Radio value="rich-text">
            Editor
            </Radio>
            <Radio value="textarea">
            HTML Editor
            </Radio>
          </Radio.Group>
        </div>

        <LabeledInput
          label="Bottom Description"
          type={bottomDescriptionEditorType}
          value={bottomDescription}
          onChange={setBottomDescription}
          onBlur={onInputBlur("bottomDescription")}
        />

        <div className="mt-15">
          <LabeledInput
            label="FAQ Schema"
            type="textarea"
            value={faqSchema}
            onChange={setFaqSchema}
            onBlur={onInputBlur("faqSchema")}
          />
        </div>
      </div>
    </TableRow>
  )
}

SeoCategoriesRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  patchItem: PropTypes.func.isRequired,
};

export default SeoCategoriesRow;


function StatusLabel({ label, value, currentStatus, activeColor, onClick, tooltipTitle }) {
  const styles = {};
  if (currentStatus === value) {
    styles.backgroundColor = activeColor || "#F89407";
  }

  // Render
  return (
    <Tooltip title={tooltipTitle}>
      <span className="seo-cat--status" style={styles} onClick={() => onClick(value)}>{label}</span>
    </Tooltip>
  )
}

StatusLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
  activeColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  tooltipTitle: PropTypes.string
};

import React from "react";
import { Form, Row } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";

// components
import { LantFormFooter } from "./LantFormFooter";


export const LantForm = ({
  form,
  children,
  title,
  className,
  gutter = 16,
  formFooterProps,
  onSubmit,
  onCancel,
  ...rest
}) => {
  return (
    <div className={classNames("lant-form", className)}>
      {!!title && (
        <div className="lant-form--header">
          <div className="lant-form--title">{title}</div>
        </div>
      )}

      <Form
        form={form}
        layout="vertical"
        className="lant-form--content"
        validateTrigger="onChange"
        {...rest}
      >
        <Row gutter={gutter}>
          {children}
        </Row>
      </Form>

      <LantFormFooter onSubmit={onSubmit} onCancel={onCancel} {...formFooterProps} />
    </div>
  )
}

LantForm.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  gutter: PropTypes.number,
  formFooterProps: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

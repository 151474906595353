import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Checkbox, Button } from "antd";
import LabeledInput from "../../shared/LabeledInput";
import InfoIcon from "../../Icon/InfoIcon";
import HelpIcon from "../../Icon/HelpIcon";

import {
  deleteHelpTextItemRequest,
  putHelpTextItemRequest,
  getHelpTextChoicesRequest
} from "../../../redux/content/helptext/helpTextActions";


const EditHelpTextItem = ({
  helpTextItem,
  isFullScreen,
  toggleFullScreen,
  close,
  deleteHelpTextItem,
  putHelpTextItem,
  getHelpTextChoices,
  page,
  perPage,
  error,
  processing,
  helpTypeChoices,
  userRole
}) => {
  const [isActive, setIsActive] = useState(helpTextItem.isActive);
  const [helpId, setHelpId] = useState(helpTextItem.helpId);
  const [appearOn, setAppearOn] = useState("");
  const [helpType, setHelpType] = useState(helpTextItem.helpType);
  const [icon, setIcon] = useState(helpTextItem.icon);
  const [helpTitle, setHelpTitle] = useState(helpTextItem.helpTitle);
  const [helpText, setHelpText] = useState(helpTextItem.helpText);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [closeEditOnSuccess, setCloseEditOnSuccess] = useState(false);

  const serverError = error || {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  const isDisabled = !userRole.includes("Super Admin");


  // Methods

  useEffect(() => {
    if (!processing) return;
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    getHelpTextChoices({ helpTitle });
  }, [helpTitle]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) return;

    return setCloseEditOnSuccess(true);
  }, [formSubmitted, error, processing]); // eslint-disable-line

  const _deletePage = () => {
    setFormSubmitted(true);
    deleteHelpTextItem({ id: helpTextItem.id, page, perPage });
  };

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _updatePage = () => {
    putHelpTextItem({
      id: helpTextItem.id,
      data: {
        isActive,
        helpId,
        helpType,
        icon,
        helpTitle,
        helpText
      },
      page,
      perPage
    });
  };

  const renderHelpTypeSelect = () => {
    const options = helpTypeChoices && Object.keys(helpTypeChoices).map((key) => (
      {
        label: helpTypeChoices[key],
        value: key,
      }
    ));

    return (
      <div className="add-help-text-item--help-type-select">
        <p className="add-help-text-item--help-type-select-label">
          Help Type
        </p>
        <LabeledInput
          className="add-help-text-item--icon-select"
          type="select"
          value={helpType}
          options={options || []}
          onChange={setHelpType}
          horizontal
        />
      </div>
    );
  };

  const renderIconSelect = () => {
    // TODO: To add icon field on backend & display iconChoices
    const iconChoices = [{
      label: "No Icon",
      value: "No Icon",
    },
    {
      label: "Help",
      value: "HELP",
      icon: HelpIcon
    },
    {
      label: "Info",
      value: "INFO",
      icon: InfoIcon
    }];

    return (
      <div className="add-help-text-item--icon-select">
        <p className="add-help-text-item--icon-select-label">
          Icon
        </p>
        <LabeledInput
          className="add-help-text-item--icon-select"
          type="select"
          value={icon}
          options={iconChoices || []}
          onChange={setIcon}
          horizontal
        />
      </div>
    );
  };


  // Render

  const classNames = ["edit-help-text-item"];
  if (isFullScreen) classNames.push("edit-help-text-item__fullscreen");
  const fullScreenIcon = isFullScreen ? "/images/fullScreenExitIcon.png" : "/images/fullscreen_3x.svg";
  const fullScreenIconId = isFullScreen ? "full-screen-exit-icon" : "";

  closeEditOnSuccess && close("");

  return (
    <div className={classNames.join(" ")}>
      <div className="edit-help-text-item--row">
        <span className="edit-help-text-item--title">{`Edit “${helpTextItem.helpId}”`}</span>

        <div className="edit-help-text-item--row image-holder">
          <img
            id={fullScreenIconId}
            src={fullScreenIcon}
            onClick={() => toggleFullScreen(!isFullScreen)}
            alt="fullscreen"
          />
          <img src="/images/close.svg" onClick={close} alt="close" />
        </div>
      </div>

      <div className="edit-help-text-item--row checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isActive}
          onChange={onCheck}
        >
          Active
        </Checkbox>
      </div>

      <LabeledInput
        label="Help ID"
        isRequired
        value={helpId}
        onChange={setHelpId}
        errorText={serverError.helpId}
      />
      <div className="edit-help-text-item--url-input-holder">
        <LabeledInput
          label="Appear On"
          value={appearOn}
          suffix={urlFieldImage}
          onChange={setAppearOn}
        />
      </div>

      <div className="edit-help-text-item--help-type-and-icon-row">
        {renderHelpTypeSelect()}
        {renderIconSelect()}
      </div>

      <div className="edit-help-text-item--url-input-holder">
        <LabeledInput
          label="helpTitle"
          value={helpTitle}
          onChange={value => { setHelpTitle(value) }}
        />
      </div>

      <LabeledInput
        label="Help Text"
        type="rich-text"
        value={helpText}
        onChange={value => { setHelpText(value) }}
        className="edit-help-text-item--help-text-content-input"
      />

      <div className="edit-help-text-item--button-holder">
        <Button className="ant-btn-primary" onClick={_updatePage} disabled={isDisabled}>
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button className="btn-secondary" onClick={_deletePage} disabled={isDisabled}>
            <img src="/images/delete_3x.svg" alt="" />
            DELETE ITEM
          </Button>
        </div>

      </div>
    </div>
  )
}

EditHelpTextItem.propTypes = {
  helpTextItem: PropTypes.object.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  toggleFullScreen: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  deleteHelpTextItem: PropTypes.func.isRequired,
  putHelpTextItem: PropTypes.func.isRequired,
  getHelpTextChoices: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  error: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  helpTypeChoices: PropTypes.object.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    helpText: state.helpText,
    processing: state.pages.processing,
    userRole: state.auth.userProfile.roles,
    helpTypeChoices: state.helpText.helpTypeChoices,
  }),
  dispatch => ({
    putHelpTextItem: payload => dispatch(putHelpTextItemRequest(payload)),
    deleteHelpTextItem: payload => dispatch(deleteHelpTextItemRequest(payload)),
    getHelpTextChoices: payload => dispatch(getHelpTextChoicesRequest(payload)),
  })
)(EditHelpTextItem);

import * as types from "./blogActionTypes";

export const getBlogArticlesRequest = payload => {
  return { type: types.GET_BLOG_ARTICLES_REQUEST, payload };
};

export const getBlogCategoriesRequest = payload => {
  return { type: types.GET_BLOG_CATEGORIES_REQUEST, payload };
};

export const getBlogAuthorsRequest = payload => {
  return { type: types.GET_BLOG_AUTHORS_REQUEST, payload };
};

export const updateBlogAuthorStatusRequest = payload => {
  return { type: types.PATCH_BLOG_AUTHOR_STATUS_REQUEST, payload };
};

export const updateBlogCategoryStatusRequest = payload => {
  return { type: types.PATCH_BLOG_CATEGORY_STATUS_REQUEST, payload };
};

export const createNewArticleRequest = (payload) => {
  return { type: types.CREATE_NEW_ARTICLE_REQUEST, payload };
};

export const deleteBlogArticleRequest = payload => {
  return { type: types.DELETE_BLOG_ARTICLE_REQUEST, payload };
};

export const updateBlogArticleRequest = payload => {
  return { type: types.PATCH_BLOG_ARTICLE_REQUEST, payload };
};

export const uploadImageToServer = (payload) => {
  return { type: types.UPLOAD_NEW_IMAGE_REQUEST, payload };
};

export const createNewAuthorRequest = (payload) => {
  return { type: types.CREATE_NEW_AUTHOR_REQUEST, payload };
};

export const createNewCategoryRequest = (payload) => {
  return { type: types.CREATE_NEW_CATEGORY_REQUEST, payload };
};

export const getBlogArticleDataRequest = (payload) => {
  return { type: types.GET_BLOG_ARTICLE_DATA_REQUEST, payload };
};

export const updateShowBlogStatus = payload => {
  return { type: types.UPDATE_SHOW_BLOG_STATUS, payload };
};

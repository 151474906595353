import React, { useMemo } from "react";
import { Col, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";


export const DirectoryEntryStatistic = ({ label, statistic, processing }) => {
  const values = useMemo(() => {
    const allTimeValues = statistic?.allTime?.split("/");

    return {
      monthViews: statistic?.directoryEntryViews || 0,
      monthImpressions: statistic?.directoryEntryImpressions || 0,
      allTimeViews: allTimeValues?.[0] || 0,
      allTimeImpressions: allTimeValues?.[1] || 0,
    }
  }, [statistic]);

  if (processing) {
    return null;
  }

  return (
    <div className="pt-16">
      <div className="active-invoices-section">
        <div className="active-invoices-section--title">Statistic</div>
        <Row gutter={24} className="active-invoices-section--content">
          <Col sm={12} xs={24}>
            <div className="active-invoice-block">
              <div className="active-invoice-block--value">
                {values.monthViews}
              </div>
              <div className="active-invoice-block--text">
                {`${label} views (${moment().format("MMMM YYYY")})`}
              </div>
            </div>
            <div className="active-invoice-block">
              <div className="active-invoice-block--value">
                {values.allTimeViews}
              </div>
              <div className="active-invoice-block--text">
                Directory entry views (All time)
              </div>
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div className="active-invoice-block">
              <div className="active-invoice-block--value">
                {values.monthImpressions}
              </div>
              <div className="active-invoice-block--text">
                {`${label} impressions (${moment().format("MMMM YYYY")})`}
              </div>
            </div>
            <div className="active-invoice-block">
              <div className="active-invoice-block--value">
                {values.allTimeImpressions}
              </div>
              <div className="active-invoice-block--text">
                Directory entry impressions (All time)
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

DirectoryEntryStatistic.propTypes = {
  statistic: PropTypes.object,
  label: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
};

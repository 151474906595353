import React from "react"
import PropTypes from "prop-types";

// components
import LabeledInput from "./LabeledInput";

const ADDON = "https://";

export const LinkInput = ({ label = "Website", value, onChange, ...props }) => {
  const handleChange = (newValue) => {
    if (newValue) {
      onChange(newValue.includes(ADDON) ? newValue : `${ADDON}${newValue}`);

      return;
    }

    onChange(newValue);
  }
  
  return (
    <LabeledInput
      label={label}
      addonBefore={ADDON}
      value={value?.replace(ADDON, "")}
      onChange={handleChange}
      inputClassName="pt-0 pb-0 pr-0 pl-0"
      {...props}
    />
  );
}

LinkInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
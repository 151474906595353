import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import SystemPreferences from "./SystemPreferences/SystemPreferences";
import Payments from "./Payments/Payments";
import EmailProfiles from "./EmailProfiles/EmailProfiles";
import AddEmailProfile from "./EmailProfiles/AddEmailProfile";
import Offers from "./Offers/Offers";
import Currency from "./Currency/Currency";
import AdminAccounts from "./AdminAccounts/AdminAccounts";
import Pinterest from "./Pinterest/Pinterest";
import PinterestToken from "./Pinterest/PinterestToken";


const Settings = () => (
  <>
    <Switch>
      <Route path="/settings/system-preferences" component={SystemPreferences} />
      <Route path="/settings/payments" component={Payments} />
      <Route path="/settings/email-profiles/add" component={AddEmailProfile} />
      <Route exact path="/settings/email-profiles" component={EmailProfiles} />
      <Route path="/settings/offers" component={Offers} />
      <Route path="/settings/currency" component={Currency} />
      <Route path="/settings/admin-accounts" component={AdminAccounts} />
      <Route path="/settings/pinterest-token" component={PinterestToken} />
      <Route path="/settings/pinterest" component={Pinterest} />
      <Redirect to="/settings/system-preferences" />
    </Switch>
  </>
);

export default Settings;

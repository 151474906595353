import { useQuery } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/directory/${id}/statistic/`, method: "GET" });
};

export const useDirectoryStatistic = (id, options) => {
  return useQuery(["directory-statistic", id], () => fetcher(id), options);
};

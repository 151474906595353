import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";
import { Select, DatePicker } from "antd";

// components
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import Table from "../../shared/Table/Table";
import DealerSelectWithSearch from "../../shared/DealerSelectWithSearch";
import OrderHeaderIcon from "../../Icon/OrderHeaderIcon";
import OrderHistoryIcon from "../../Icon/OrderHistoryIcon";
import OrdersSearch from "./OrdersSearch";

// helpers
import { selectCurrencySymbol } from "../../../helpers/currency";
import formatPrice from "../../../helpers/formatPrice";
import navigation from "../../../helpers/navigation";
import { formatCreationTime } from "../../../helpers/formatCreationTime";

// redux
import {
  getOrdersRequest,
  getOrdersChoicesRequest,
  getFilteredOrdersRequest,
} from "../../../redux/orders/ordersActions";

const ListOfAllOrders = ({
  getOrders,
  orders,
  getOrdersChoices,
  location,
  history,
  getFilteredOrders,
  userProfile,
}) => {
  const { page, perPage, dealer, statusOption, typeFilter, fromDate, toDate } =
    navigation.getTableQueryParams(location);

  const paginationQuery = new URLSearchParams(location.search);

  const getOrdersHelper = () => {
    const isQueryParams = statusOption || typeFilter || dealer || fromDate || toDate;

    if (!isQueryParams) {
      getOrders({ page, perPage });

      return
    }
    
    getFilteredOrders({
      page,
      perPage,
      dealer,
      payment: typeFilter,
      status: statusOption,
      ...(fromDate && toDate ? { fromDate, toDate } : {}),
    });
  };

  useEffect(() => {
    getOrdersChoices({});
  }, []); // eslint-disable-line

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", "1");

    history.replace({ ...location, search: `?${queryParams.toString()}` });

    getOrdersHelper();
  }, [fromDate, toDate, dealer, statusOption, typeFilter]); // eslint-disable-line

  useEffect(() => {
    getOrdersHelper();
  }, [page, perPage]); // eslint-disable-line

  const renderOrderStatusField = (header, status) => {
    const orderStatus = status && status.status;

    let className = "";
    let formattedStatus = "";

    if (orderStatus === "Paid") {
      className = "status-column--paid";
      formattedStatus = "Paid";
    }
    if (orderStatus === "Completed") {
      className = "status-column--completed";
      formattedStatus = "Completed";
    }
    if (orderStatus === "Bounced") {
      className = "status-column--bounced";
      formattedStatus = "Bounced";
    }
    if (orderStatus === "Refunded") {
      className = "status-column--refunded";
      formattedStatus = "Refunded";
    }

    return (
      <>
        <div className="status-column--statuses-row">
          <div key={`key-${status}`} className={className}>
            {formattedStatus}
          </div>
        </div>
        <div className="status-column--cart-id">{header.cartId}</div>
      </>
    );
  };

  const renderOrderPaymentField = ({ method, status }) => {
    if (status !== "COMPLETED") return;

    return (
      // Here should be returned image instead of string after backend changes will be ready
      <p id="status-column-status-value">{method}</p>
    );
  };

  const transformOrderPrice = ({
    orderTotal,
    productTotal,
    shippingTotal,
    currency,
  }) => {
    const currencySymbol = selectCurrencySymbol(currency);

    return {
      orderTotal: `${currencySymbol}${formatPrice(orderTotal)}`,
      productTotal: `${currencySymbol}${formatPrice(productTotal)}`,
      shippingTotal: `${currencySymbol}${formatPrice(shippingTotal)}`,
    };
  };

  const onDealerNameClick = (dealerUrl) => {
    const userRole = userProfile.roles[0];
    if (userRole === "Super Admin" || userRole === "Super Admin") {
      history.push({
        pathname: `/dealers/dealers-list/${dealerUrl}/status-tab`,
      });
    }

    return null;
  };

  const onSetQueryParams = (queryParams) => {
    navigation.setDynamicKeys(queryParams, location, history);
  };

  /* TODO need to be reverted after backed will be changed */
  // const statusChoicesList = Object.assign({ "": "All" }, orders.statusChoices);
  // const paymentStatusChoicesList = Object.assign({ "": "All" }, orders.paymentStatusChoices);
  const statusChoicesList = Object.assign(
    { "": "All" },
    { COMPLETED: "Completed", REFUNDED: "Refunded" }
  );
  const paymentStatusChoicesList = Object.assign(
    { "": "All" },
    { STRIPE: "Stripe", PAYPAL: "PayPal" }
  );

  return (
    <div className="list-of-orders">
      <div className="component--header">
        <div className="list-of-orders--sort-buttons-and-calendar-row">
          <div className="list-of-orders--sort-by-status-btn">
            <p className="list-of-orders--status-btn-label">Status</p>
            <Select
              placeholder="All"
              className="select"
              onChange={(value) => onSetQueryParams({ statusOption: value })}
            >
              {statusChoicesList &&
                Object.keys(statusChoicesList).map((key) => (
                  <Select.Option key={key} value={key}>
                    {statusChoicesList[key]}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div className="list-of-orders--sort-by-payment-status-btn">
            <p className="list-of-orders--payment-status-btn-label">Payment</p>
            <Select
              placeholder="All"
              className="select"
              onChange={(value) => onSetQueryParams({ typeFilter: value })}
            >
              {paymentStatusChoicesList &&
                Object.keys(paymentStatusChoicesList).map((key) => (
                  <Select.Option key={key} value={key}>
                    {paymentStatusChoicesList[key]}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <DealerSelectWithSearch
            placeholder="All"
            onSelect={(value) => onSetQueryParams({ dealer: value })}
          />

          <div
            className="list-of-orders--sort-by-date-btn"
            id="date-picker-container"
          >
            <DatePicker.RangePicker
              ranges={{
                "Last 14 days": [moment().subtract(14, "d"), moment()],
                "Last 30 days": [moment().subtract(30, "d"), moment()],
                "Last month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              onChange={(value) => {
                if (value) {
                  const [newFromDate, newToDate] = value;

                  onSetQueryParams({
                    fromDate: newFromDate.format("YYYY-MM-DD"),
                    toDate: newToDate.format("YYYY-MM-DD"),
                  });

                  return;
                }

                onSetQueryParams({ fromDate: null, toDate: null });
              }}
            />
          </div>

          <OrdersSearch />
        </div>
      </div>

      <div className="list-of-orders--content flex">
        <Table
          columnWidths={[
            "80px",
            "auto",
            "11%",
            "15%",
            "8%",
            "8%",
            "10%",
            "10%",
            "6%",
          ]}
          headerCells={[
            { label: "order" },
            { label: "item details" },
            { label: "dealer" },
            { label: "customer" },
            { label: "total" },
            { label: "status" },
            { label: "date/time" },
            { label: "payment" },
            { label: "actions" },
          ]}
        >
          {({ columnWidths }) => {
            return (orders.orders || []).map(
              ({
                billTo = {},
                dealer: orderDealer = {},
                header = {},
                id,
                item = {},
                payment = {},
                status,
                total = {},
                createdAt,
              }) => {
                const { orderTotal, productTotal, shippingTotal } =
                  transformOrderPrice(total);
                const orderMainImage =
                  item.images && item.images.find((img) => img.isMain === true);
                const mainImagePath = get(orderMainImage, "url", "");

                return (
                  <div
                    key={`list-of-orders-${id}`}
                    className="list-of-orders--content__row"
                  >
                    <TableRow columnWidths={columnWidths}>
                      <div className="list-of-orders--order-column">
                        <p id="order-column-id-value">{orderDealer.id}</p>
                        <p id="order-column-created-date-value">{item.id}</p>
                      </div>
                      <div className="list-of-orders--item-details-column">
                        <img
                          src={mainImagePath}
                          alt="Order"
                          className="item-details-column--image"
                        />
                        <div className="item-details-column--info">
                          <p id="info-ref-value">{item.ref || ""}</p>
                          <p id="info-title-value">{item.title || ""}</p>
                          <div id="info-item-total-value">
                            <p>Item total</p>
                            <span>{productTotal}</span>
                          </div>
                          <div id="info-shipping-value">
                            <p>Shipping</p>
                            <span>{shippingTotal}</span>
                          </div>
                        </div>
                      </div>
                      <div className="list-of-orders--dealer-column flex">
                        <div
                          onClick={() => onDealerNameClick(orderDealer.dealerUrl)
                          }
                        >
                          {orderDealer.businessName}
                        </div>
                      </div>
                      <div className="list-of-orders--customer-column">
                        <p id="customer-column-name-value">{billTo.name}</p>
                        <p id="customer-column-email-value">{billTo.email}</p>
                      </div>
                      <div className="list-of-orders--total-column flex">
                        <p id="total-column-shipping-value">{orderTotal}</p>
                      </div>
                      <div className="list-of-orders--status-column">
                        {renderOrderStatusField(header, status)}
                      </div>
                      <div className="list-of-orders--payment-column flex">
                        <p className="list-of-orders--payment-column--time">
                          {formatCreationTime(createdAt)}
                        </p>
                        <p className="list-of-orders--payment-column--date">
                          {moment(new Date(createdAt)).format("DD/MM/YYYY")}
                        </p>
                      </div>
                      <div className="list-of-orders--payment-column flex">
                        {renderOrderPaymentField(payment)}
                      </div>
                      <div className="list-of-orders--actions-column flex">
                        <Link
                          to={`/orders/${id}/header?${paginationQuery.toString()}`}
                          className="line-height-1"
                        >
                          <OrderHeaderIcon className="order-header-icon" />
                        </Link>
                        <Link
                          to={`/orders/${id}/history?${paginationQuery.toString()}`}
                          className="line-height-1"
                        >
                          <OrderHistoryIcon className="order-history-icon" />
                        </Link>
                      </div>
                    </TableRow>
                  </div>
                );
              }
            );
          }}

          {orders.ordersTotalNumber && (
            <TableFooter
              key="footer-of-the-orders-table"
              page={page}
              perPage={perPage}
              total={orders.ordersTotalNumber}
            />
          )}
        </Table>
      </div>
    </div>
  );
};

ListOfAllOrders.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  getOrdersChoices: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  getFilteredOrders: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  method: PropTypes.string,
  status: PropTypes.string,
};

export default connect(
  ({ orders, auth }) => ({
    orders,
    userProfile: auth.userProfile,
  }),
  (dispatch) => ({
    getOrders: (payload) => dispatch(getOrdersRequest(payload)),
    getOrdersChoices: (payload) => dispatch(getOrdersChoicesRequest(payload)),
    getFilteredOrders: (payload) => dispatch(getFilteredOrdersRequest(payload)),
  })
)(ListOfAllOrders);

export const SHOW_LOADING = "SHOW_EMAIL_ENQUIRIES_LOADING";
export const HIDE_LOADING = "HIDE_EMAIL_ENQUIRIES_LOADING";

export const SHOW_PROCESSING = "SHOW_EMAIL_ENQUIRIES_PROCESSING";
export const HIDE_PROCESSING = "HIDE_EMAIL_ENQUIRIES_PROCESSING";

export const GET_EMAIL_ENQUIRIES_REQUEST = "GET_EMAIL_ENQUIRIES_REQUEST";
export const GET_EMAIL_ENQUIRIES_REQUEST_SUCCESS = "GET_EMAIL_ENQUIRIES_REQUEST_SUCCESS";
export const GET_EMAIL_ENQUIRIES_REQUEST_ERROR = "GET_EMAIL_ENQUIRIES_REQUEST_ERROR";

export const PATCH_EMAIL_ENQUIRIES_REQUEST = "PATCH_EMAIL_ENQUIRIES_REQUEST";
export const REJECT_ALL_BLACKLISTED_REQUEST = "REJECT_ALL_BLACKLISTED_REQUEST";

export const DELETE_EMAIL_ENQUIRY = "DELETE_EMAIL_ENQUIRY";
export const DELETE_EMAIL_ENQUIRY_ERROR = "DELETE_EMAIL_ENQUIRY_ERROR";

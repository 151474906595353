import * as types from "./seoActionsTypes";


export const getSeoRequest = params => {
  return { type: types.GET_SEO_REQUEST, params };
};

export const patchSeoRequest = (id, data, callback) => {
  return { type: types.PATCH_SEO_REQUEST, id, data, callback };
};

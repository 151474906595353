import React from "react";
import { useHistory } from "react-router-dom";
import { Form, message } from "antd";

// components
import { DirectoryEntryForm } from "../components/DirectoryEntryForm/DirectoryEntryForm";

// react-queries
import { useFairCreate } from "../../../react-query";

// helpers
import { setServerErrorsToForm } from "../../../helpers";


export const AddFair = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const redirectToList = () => {
    history.replace("/directory/fairs");
  };

  const createDirectoryEntry = useFairCreate({
    onSuccess: () => {
      message.success("Fair was successfully created");

      redirectToList();
    },
    onError: (err) => {
      setServerErrorsToForm(form, err?.data);
    }
  });

  const onSubmit = (values) => {
    createDirectoryEntry.mutate(values);
  };

  return (
    <div className="add-directory">
      <DirectoryEntryForm form={form} title="Add" isFair onSubmit={onSubmit} onCancel={redirectToList} />
    </div>
  );
};

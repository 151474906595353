import React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";


function StatusBlock({
  number,
  text,
  isNumberHighlighted,
  isTextHighlighted,
  onClick,
  className
}) {
  const numberClassNames = ["dashboard--status-block-number"];
  if (isNumberHighlighted) numberClassNames.push("dashboard--status-block-number__highlighted");
  if (className) numberClassNames.push(className);

  const textClassNames = ["dashboard--status-block-text"];
  if (isTextHighlighted) numberClassNames.push("dashboard--status-block-text__highlighted");

  return (
    <Col span={12} className="flex dashboard--status-block" onClick={onClick ? onClick : null}>
      <span className={numberClassNames.join(" ")}>{number}</span>
      <span className={textClassNames.join(" ")}>{text}</span>
    </Col>
  )
}

StatusBlock.propTypes = {
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  text: PropTypes.string.isRequired,
  isNumberHighlighted: PropTypes.bool,
  isTextHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default StatusBlock;

import * as types from "./analyticsActionTypes";


const initialState = {
  error: null,
  enquiriesAnalyticsData: [],
  enquiriesAnalyticsLoading: false,
  itemsAnalyticsData: [],
  itemsAnalyticsLoading: false,
  dealerAnalytics: [],
  dealerAnalyticsLoading: false,
};


const analytics = (state = initialState, action) => {
  switch (action.type) {
    case types.ENQUIRIES_ANALYTICS_LOADING:
      return {
        ...state,
        enquiriesAnalyticsLoading: action.payload,
      };

    case types.ITEMS_ANALYTICS_LOADING:
      return {
        ...state,
        itemsAnalyticsLoading: action.payload,
      };

    case types.GET_ENQUIRIES_ANALYTICS_ERROR:
    case types.GET_ITEMS_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case types.GET_ENQUIRIES_ANALYTICS_SUCCESS:
      return {
        ...state,
        enquiriesAnalyticsData: action.payload,
      };

    case types.GET_ITEMS_ANALYTICS_SUCCESS:
      return {
        ...state,
        itemsAnalyticsData: action.payload,
      };

    case types.DEALER_ANALYTICS_REQUEST_LOADING:
      return {
        ...state,
        dealerAnalyticsLoading: action.payload,
      };

    case types.GET_DEALER_ANALYTICS_REQUEST_SUCCESS:
      return {
        ...state,
        dealerAnalytics: action.payload,
      };

    default:
      return state;
  }
};

export default analytics;

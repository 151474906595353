import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./dealerChargesActionsTypes";
import invoicesApi from "../../../api/invoices";


function *getInvoiceDealerCharges(action) {
  try {
    const response = yield call(invoicesApi.getInvoicesDealersCharge, action.payload);
    yield put({ type: types.GET_INVOICE_DEALER_CHARGES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_INVOICE_DEALER_CHARGES_ERROR, payload: error });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_INVOICE_DEALER_CHARGES_REQUEST, getInvoiceDealerCharges),
  ])
}

import * as types from "./ordersActionsTypes";

export const getOrdersRequest = payload => {
  return { type: types.GET_ORDERS_REQUEST, payload };
};

export const getOrdersChoicesRequest = payload => {
  return { type: types.GET_ORDERS_CHOICES_REQUEST, payload };
};

export const getDealersListRequest = payload => {
  return { type: types.GET_DEALERS_LIST_REQUEST, payload };
};

export const searchOrdersRequest = payload => {
  return { type: types.SEARCH_ORDERS_REQUEST, payload };
};

export const patchOrderRequest = payload => {
  return { type: types.PATCH_ORDER_REQUEST, payload };
};

export const getFilteredOrdersRequest = payload => {
  return { type: types.GET_FILTERED_ORDERS_REQUEST, payload };
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

import TableAndFormLayout from "../../../shared/TableAndFormLayout/TableAndFormLayout";
import AddButton from "../../../shared/AddButton";
import navigation from "../../../../helpers/navigation";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import AddEditCurrencyCodes from "./AddEditCurrencyCodes";

import { Utils } from "../../../../helpers";
import {
  getCurrencyCodesRequest,
  updateCurrencyCodesRequest,
  deleteCurrencyCodesRequest
} from "../../../../redux/settings/currency/currencyCodes/currencyCodesActions";
import TableFooter from "../../../shared/Table/TableFooter";


function CurrencyCodes({
  onToggleEdit,
  items,
  totalItems,
  history,
  userRole,
  getCurrencyCodes,
  updateCurrencyCodes,
  deleteCurrencyCodes,
}) {
  const location = useLocation();

  const [selected, setSelected] = useState(null);
  const { page, perPage, order } = navigation.getTableQueryParams(location);

  const isDisabled = !userRole.includes("Super Admin");

  // Hide Tab navigator on edit
  useEffect(() => {
    onToggleEdit(!!selected);
  }, [selected]); // eslint-disable-line

  // Pagination api call here
  useEffect(() => {
    getCurrencyCodes({ page, perPage });
  }, [page, perPage]); // eslint-disable-line

  // Methods
  const onChangeActive = (event, id) => {
    updateCurrencyCodes(id, { isActive: event.target.checked });
  };

  const onRemove = () => {
    deleteCurrencyCodes({ id: selected.id });
    setSelected(null);
  };

  // Render
  const itemsToRender = Utils.filterAndOrder(items, { order });

  return (
    <div className="currency">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        <TableAndFormLayout.Header>
          <div className="component--header">
            {!isDisabled && <AddButton onClick={() => history.push("/settings/currency/codes/add")} />}
          </div>
        </TableAndFormLayout.Header>

        <TableAndFormLayout.Table>
          <Table
            columnWidths={["40px", "120px", "auto"]}
            headerCells={[
              {},
              { label: "Currency" },
              { label: "Description" },
            ]}
          >
            {
              itemsToRender.map(item => {
                return (
                  <TableRow key={`group:${item.id}`}>
                    <Checkbox checked={item.isActive} onChange={e => onChangeActive(e, item.id)} />
                    <div className="flex with-cursor table-text" onClick={() => setSelected(item)}>
                      {item.currency}
                    </div>
                    <div className="flex table-sm-text">{item.description}</div>
                  </TableRow>
                )
              })
            }
          </Table>

          {
            totalItems && (
              <TableFooter
                page={page}
                perPage={perPage}
                total={totalItems}
              />
            )
          }
        </TableAndFormLayout.Table>

        <TableAndFormLayout.Form>
          <AddEditCurrencyCodes
            item={selected}
            close={() => setSelected(null)}
            remove={onRemove}
          />
        </TableAndFormLayout.Form>
      </TableAndFormLayout>
    </div>
  )
}

CurrencyCodes.propTypes = {
  onToggleEdit: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  getCurrencyCodes: PropTypes.func.isRequired,
  updateCurrencyCodes: PropTypes.func.isRequired,
  deleteCurrencyCodes: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    items: state.currencyCodes.items,
    totalItems: state.currencyCodes.total,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getCurrencyCodes: params => dispatch(getCurrencyCodesRequest(params)),
    updateCurrencyCodes: (id, data) => dispatch(updateCurrencyCodesRequest(id, data, true)),
    deleteCurrencyCodes: id => dispatch(deleteCurrencyCodesRequest(id)),
  })
)(CurrencyCodes);

import * as types from "./enquiryActivityActionsTypes";


const initialState = {
  items: [],
  total: 0,
  loading: false,
};


const enquiryActivity = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.GET_ENQUIRY_ACTIVITY_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        total: action.payload.count
      };

    default:
      return state;
  }
};

export default enquiryActivity;

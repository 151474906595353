import * as types from "./homeAndLivingActionsTypes";

export const getHomeAndLivingListRequest = (payload) => {
  return { type: types.GET_HOME_AND_LIVING_LIST_REQUEST, payload };
};

export const getHomeAndLivingItemRequest = (payload) => {
  return { type: types.GET_HOME_AND_LIVING_ITEM_REQUEST, payload };
};

export const updateHomeAndLivingItemRequest = (payload) => {
  return { type: types.UPDATE_HOME_AND_LIVING_ITEM_REQUEST, payload };
};

export const deleteHomeAndLivingItemRequest = (payload) => {
  return { type: types.DELETE_HOME_AND_LIVING_ITEM_REQUEST, payload };
};

export const createHomeAndLivingItemRequest = (payload) => {
  return { type: types.CREATE_HOME_AND_LIVING_ITEM_REQUEST, payload };
};

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./view/App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";

// Styles

import "antd/dist/antd.variable.min.css";
import "./assets/styles/antd-override-variables.scss";

import "react-dates/lib/css/_datepicker.css";

import "react-quill/dist/quill.snow.css";

import "./assets/styles/index.scss";


window.invalidateToken = function() {
  const a = JSON.parse(localStorage.getItem("token") || "{}");
  a.access = "invalid";
  localStorage.setItem("token", JSON.stringify(a));
};

if (process.env.NODE_ENV !== "development") {
  console.info(`Release date: ${process.env.REACT_APP_RELEASE_DATE || "-"}`);
}

const store = configureStore();


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

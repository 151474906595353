import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Select,
  Spin,
  Empty,
  message,
  Popconfirm
} from "antd";
import debounce from "lodash/debounce";

//components
import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";
import GeneralFormControl from "../../shared/GeneralFormControl";

//redux
import {
  createCatalogEntityElementRequest,
  updateCatalogEntityElementRequest,
  resetErrorAction
} from "../../../redux/catalog/catalogActions";

//helpers
import CatalogApi from "../../../api/catalog";
import ContentApi from "../../../api/content";


function AddEditCatalogEntity(props) {
  const { entity, item, close, remove, history, location, processing, error } = props;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [gettingParents, setGettingParents] = useState(false);
  const [parentOptions, setParentOptions] = useState(item && item.parentItem ? [item.parentItem] : []);

  const [active, setActive] = useState(item ? item.isVisible : true);
  const [parent, setParent] = useState(item ? item.parent : "");
  const [title, setTitle] = useState(item ? item.title || "" : "");
  const [subheader, setSubheader] = useState(item ? item.subheader || "" : "");
  const [url, setUrl] = useState(item ? item.url || "" : "");
  const [overrideUrl, setOverrideUrl] = useState(item ? item.overrideUrl || "" : "");
  const [image, setImage] = useState(item ? item.image || false : false);
  const [bannerImage, setBannerImage] = useState(item ? item.banner || false : false);
  const [metaDescription, setMetaDescription] = useState(item ? item.metaDescription || "" : "");
  const [description, setDescription] = useState(item ? item.description || "" : "");
  const [bottomDescription, setBottomDescription] = useState(item ? item.bottomDescription || "" : "");

  const isEdit = !!item;
  const oldUrlBeforeEdit = item ? item.url || "" : "";
  const hasDeletePermission = props.userRole.some(role => role === "Super Admin") || !isEdit;

  // Compute page title and heading
  let pageTitle = `${title} For Sale | LoveAntiques.com`;
  let heading = `${title} Antiques`;
  if (entity.title === "Material") {
    pageTitle = `Antique and Vintage ${title} items For Sale UK | LoveAntiques.com`;
    heading = `Antique & Vintage ${title}`;
  }
  else if (entity.title === "Origin") {
    pageTitle = `${title} Antiques For Sale UK | LoveAntiques.com`;
    heading = `${title} Antiques`;
  }

  // Add default parent if there is a query param
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const parentUrl = query.get("parent");

    if (parentUrl) {
      CatalogApi.getEntityElement(entity.url, parentUrl).then(result => {
        setParent(result.id);
        setParentOptions([result])
      }).catch(console.warn)
    }

    // Reset error when component unmounts
    return props.resetError;
  }, []); // eslint-disable-line

  // Close form after successful submit
  useEffect(() => {
    if (!formSubmitted || processing || error) {
      return;
    }

    if (isEdit) {
      return close();
    }
    history.push({ pathname: location.pathname.replace("/add", "") })
  }, [formSubmitted, processing, error]); // eslint-disable-line


  // Methods
  const getParents = value => {
    if (!value) {
      return;
    }

    setGettingParents(true);
    setParentOptions([]);
    CatalogApi.searchEntityElements(entity.url, { page: 1, perPage: 200, search: value }).then(result => {
      setGettingParents(false);
      setParentOptions(result.results);
    }).catch(console.warn)
  };
  const getParentsDebounced = debounce(getParents, 150);

  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file).then(result => {
      if (type === "image") {
        setImage(result.response);
      }
      if (type === "banner") {
        setBannerImage(result.response);
      }
    }).catch(console.warn)
  };

  const onSave = () => {
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }

    setFormSubmitted(true);

    const callback = () => message.success(`${entity.title} was successfully ${isEdit ? "updated" : "created"}`);

    const data = {
      catalog: entity.id,
      title: title,
      catalogTitle: pageTitle,
      heading: heading,
      subheader: subheader,
      metaDescription: metaDescription,
      url: url,
      description: description,
      bottomDescription: bottomDescription,
      isVisible: active,
    };
    if (parent) {
      data.parent = parent;
    }
    if (image) {
      data.image = image;
    }
    if (bannerImage) {
      data.banner = bannerImage;
    }

    if (isEdit) {
      return props.updateCatalogEntityElement(entity.url, oldUrlBeforeEdit, data, callback);
    }
    props.createCatalogEntityElement(entity.url, data, callback);
  };


  // Render
  const err = typeof error === "object" ? error : {};

  return (
    <div className="general-form add-catalog-entity">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"}</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox className="checkbox-small" checked={active} onChange={setActive}>Active</Checkbox>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <div className="labeled-input">
            <span className="labeled-input--label">Parent</span>
            <Select
              showSearch
              allowClear
              onSearch={getParentsDebounced}
              filterOption={false}
              notFoundContent={
                gettingParents ?
                  <Spin size="small" /> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
              }
              className="add-catalog-entity--select labeled-input--input"
              value={parent}
              onChange={setParent}
            >
              {
                parentOptions.map(el => {
                  return <Select.Option key={el.url} value={el.id}>{el.title}</Select.Option>
                })
              }
            </Select>
          </div>

          <LabeledInput label="Title" value={title} onChange={setTitle} isRequired errorText={err.title} />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="URL"
            value={url}
            onChange={setUrl}
            icon={<img src="/images/link_3x.svg" alt="" />}
            isRequired
            errorText={err.url}
          />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="Override Page Title"
            value={pageTitle}
            errorText={err.catalogTitle}
            readonly
          />

          <LabeledInput
            label="Override Heading"
            value={heading}
            errorText={err.heading}
            readonly
          />

          <LabeledInput label="Override Subheader" value={subheader} onChange={setSubheader} errorText={err.subheader} />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="Override URL"
            value={overrideUrl}
            onChange={setOverrideUrl}
            icon={<img src="/images/link_3x.svg" alt="" />}
            errorText={err.overrideUrl}
          />

          <LabeledInput
            label="Meta Description"
            type="textarea"
            value={metaDescription}
            onChange={setMetaDescription}
            hasError={!!err.metaDescription}
          />
        </div>
      </div>

      <div className="general-form--row general-form--row__align-start">
        <div className="add-catalog-entity--image-picker">
          <ImageUpload
            lastUploadedImage={image}
            setImage={file => uploadImage(file, "image")}
            topText="Category Image"
            bottomText="Image must be at least 300 x 300 pixels"
            width={256}
            height={256}
          />
        </div>

        <ImageUpload
          lastUploadedImage={bannerImage}
          setImage={file => uploadImage(file, "banner")}
          topText="Category Banner"
          bottomText="Image must be at least 1420 x 440 pixels"
          width={826}
          height={256}
        />
      </div>

      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={setDescription}
        hasError={!!err.description}
      />

      <LabeledInput
        label="Bottom Description"
        type="rich-text"
        value={bottomDescription}
        onChange={setBottomDescription}
        hasError={!!err.bottomDescription}
      />

      <div className="general-form--button-holder">
        <Button className="ant-btn-primary" onClick={onSave} loading={processing}>
          SAVE & CLOSE
        </Button>

        <Popconfirm
          placement="top"
          title="Are you sure you want to delete this category?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => remove()}
          disabled={!hasDeletePermission || !isEdit}
        >
          <Button
            onClick={!isEdit ? () => history.push({ pathname: location.pathname.replace("/add", "") }) : () => { }}
            disabled={!hasDeletePermission}
          >
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

AddEditCatalogEntity.propTypes = {
  entity: PropTypes.object.isRequired,
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  createCatalogEntityElement: PropTypes.func.isRequired,
  updateCatalogEntityElement: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    processing: state.catalog.processing,
    error: state.catalog.error,
    userRole: state.auth.userProfile.roles
  }),
  dispatch => ({
    createCatalogEntityElement: (entityUrl, data, callback) => {
      dispatch(createCatalogEntityElementRequest(entityUrl, data, callback))
    },
    updateCatalogEntityElement: (entityUrl, itemUrl, data, callback) => {
      dispatch(updateCatalogEntityElementRequest(entityUrl, itemUrl, data, false, callback))
    },
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddEditCatalogEntity);

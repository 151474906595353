import React, { useEffect } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import DealerWebsiteRow from "./DealerWebsiteRow";
import LabeledInput from "../../shared/LabeledInput";
import SearchInput from "../../shared/SearchInput";

import {
  getDealerWebsitesRequest,
  changeDealerWebsiteStatusRequest
} from "../../../redux/website/websiteActions";

import navigation from "../../../helpers/navigation";

const DealerWebsite = ({
  location,
  history,
  dealerWebsites,
  getDealerWebsites,
  totalWebsites,
  changeDealerWebsiteStatus
}) => {
  const { page, perPage, search, statusOption, dateOption } = navigation.getTableQueryParams(location);

  // Effects

  useEffect(() => {
    getDealerWebsites({ page, perPage, statusOption, dateOption, search });
  }, [page, perPage, statusOption, dateOption, search]); // eslint-disable-line

  // Methods

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const handleChangeStatus = value => {
    navigation.setDynamicKeys({ page: 1, statusOption: value }, location, history);
  };

  const handleChangeDateOption = value => {
    navigation.setDynamicKeys({ page: 1, dateOption: value }, location, history);
  };

  const handleChangeDealerWebsiteStatus = (value, id) => {
    const data = {
      isPublished: value
    };
    changeDealerWebsiteStatus({ id, data });
  };

  // Render

  return (
    <div className="website-builder--container">
      <TableAndFormLayout>
        <TableAndFormLayout.Header>
          <div className="website-builder--container--header">
            <div className="website-builder--container--header--left-side">
              <LabeledInput
                label="Status"
                type="select"
                className="requests-select"
                value={statusOption}
                options={[{
                  label: "All Websites",
                  value: ""
                }, {
                  label: "Published",
                  value: "true"
                }, {
                  label: "Unpublished",
                  value: "false"
                }]}
                onChange={handleChangeStatus}
                horizontal
              />

              <Select
                className="select"
                value={dateOption}
                onChange={handleChangeDateOption}
              >
                <Select.Option value="-date">Newest First</Select.Option>
                <Select.Option value="date">Oldest First</Select.Option>
              </Select>
            </div>

            <div className="website-builder--container--header--right-side">
              <SearchInput value={search} onChange={onSearch} />
            </div>
          </div>
        </TableAndFormLayout.Header>
        <TableAndFormLayout.Table>
          <Table
            columnWidths={["19%", "19%", "10%", "18%", "19%", "15%"]}
            headerCells={[
              { label: "Business Name" },
              { label: "Link" },
              { label: "AWS Link" },
              { label: "Published" },
              { label: "Last Updates" },
              { label: "Publish" },
            ]}
          >
            {({ columnWidths }) => {
              return dealerWebsites.map(item => {
                return <DealerWebsiteRow
                  key={item.id}
                  columnWidths={columnWidths}
                  websiteData={item}
                  changeStatus={handleChangeDealerWebsiteStatus}
                />
              })
            }}

            {totalWebsites && (
              <TableFooter
                key="footer"
                page={page}
                perPage={perPage}
                total={totalWebsites}
              />
            )}
          </Table>
        </TableAndFormLayout.Table>
      </TableAndFormLayout>
    </div>
  )
};

DealerWebsite.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dealerWebsites: PropTypes.array.isRequired,
  totalWebsites: PropTypes.number.isRequired,
  getDealerWebsites: PropTypes.func.isRequired,
  changeDealerWebsiteStatus: PropTypes.func.isRequired
};

export default connect(
  state => ({
    dealerWebsites: state.website.dealerWebsites.websites,
    totalWebsites: state.website.dealerWebsites.totalWebsites
  }), {
    getDealerWebsites: getDealerWebsitesRequest,
    changeDealerWebsiteStatus: changeDealerWebsiteStatusRequest
  }
)(DealerWebsite);

import fetch from "./customFetch";
import objToQuery from "../helpers/objToQuery";

const userManagement = {

  async getUsers({ page = 1, perPage = 10, query }) {
    try {
      return await fetch({
        url: `/users/?page=${page}&page_size=${perPage}&${objToQuery(query)}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async usersSearch({ page = 1, perPage = 10, search, query }) {
    try {
      return await fetch({
        url: `/users/search/?page=${page}&page_size=${perPage}&query=${search}&${objToQuery(query)}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchUser({ id, data }) {
    try {
      return await fetch({
        url: `/users/${id}/`,
        method: "PATCH",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteUser(id) {
    try {
      return await fetch({
        url: `/users/${id}/`,
        method: "DELETE"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async convertUserRole(id, data) {
    try {
      return await fetch({
        url: `/users/${id}/convert/`,
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async csvExport() {
    try {
      return await fetch({
        url: "/users/csv_export/",
        method: "GET",
        responseType: "blob"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async dealersCsvExport() {
    try {
      return await fetch({
        url: "/users/dealers_csv_export/",
        method: "GET",
        responseType: "blob"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSearchQueryLogs({ page, pageSize }) {
    const query = new URLSearchParams();
    query.set("page", page);
    query.set("page_size", pageSize);

    try {
      return await fetch({
        url: `/search/search-query/?${query.toString()}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getSubscribers({ page = 1, perPage = 10, search }) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/anonymous/?page=${page}&page_size=${perPage}${search ? `&target_email=${search}` : ""}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteAnonymousSubscriber(id) {
    try {
      return await fetch({
        url: `/profiles/email-preferences/anonymous/${id}/`,
        method: "DELETE"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default userManagement;

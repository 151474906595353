import React, { useState } from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import moment from "moment";
import { message, Tooltip, Checkbox, Modal, Button, Input, Popconfirm } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";

//components
import TableRow from "../../../shared/Table/TableRow";
import StatusBarWithThreeColours from "../../../shared/StatusBarWithThreeColours";


const getItemStatus = ({ isApproved, isRejected, isRevoked }) => {
  switch (true) {
    case isApproved:
      return "DONE";
    case isRejected:
      return "DECLINED";
    case isRevoked:
      return "CANCELLED";
    default:
      return "PENDING";
  }
}


const ApiKeyRequestRow = ({
  columnWidths,
  handleChangeToken,
  rowData,
  handleDelete
}) => {
  const [tokenRights, setTokenRights] = useState({ isRead: rowData.isRead, isWrite: rowData.isWrite });
  const [modalOpen, setModalOpen] = useState(false);

  const { isApproved, isRejected, isRead, isWrite, createdAt, token, dealer, isRevoked, revokedByAdmin } = rowData;
  const itemStatus = getItemStatus({ isApproved, isRejected, isRevoked });

  const handleCopy = () => {
    message.success("Link was copied", 2);
  };

  const handleTokenRightsChange = (rights) => {
    setTokenRights({
      ...tokenRights,
      ...rights
    });
    handleChangeToken(rowData.id, rights);
  };

  const onTokenStatusChange = id => {
    handleChangeToken(id, { isApproved: true })
  }

  const onSubmit = ({ declineReason }) => {
    handleChangeToken(rowData.id, {
      declineReason,
      isRejected: true,
    });
    setModalOpen(false);
  };

  return (
    <>
      <div className="w-100">
        <TableRow columnWidths={columnWidths} className="website-builder--container--content--table-row">
          <div className="publish-req--status-block">
            <StatusBarWithThreeColours
              label="Approved"
              value="DONE"
              popConfirm={false}
              item={{
                id: rowData.id,
                status: itemStatus
              }}
              disabled={isApproved || isRevoked}
              onClick={onTokenStatusChange}
            />
            <StatusBarWithThreeColours
              label="Pending"
              value="PENDING"
              popConfirm={false}
              item={{
                id: rowData.id,
                status: itemStatus
              }}
              disabled={true}
            />
            <StatusBarWithThreeColours
              label="Declined"
              value="DECLINED"
              popConfirm={false}
              item={{
                id: rowData.id,
                status: itemStatus
              }}
              onClick={() => setModalOpen(true)}
              disabled={itemStatus === "DONE"}
            />
            <StatusBarWithThreeColours
              label="Cancelled"
              value="CANCELLED"
              popConfirm={false}
              disabled
              item={{
                id: rowData.id,
                status: itemStatus
              }}
            />
          </div>

          <div>
            {moment(createdAt).format("DD/MM/YYYY HH:mm")}
            {
              revokedByAdmin
                ? <span className="red-color">Revoked By Admin</span>
                : null
            }
          </div>
          <div>{dealer.email}</div>
          <div>{dealer.businessName}</div>
          <div>{rowData.domain || "-"}</div>
          <div>
            <Checkbox
              defaultChecked={isRead}
              disabled={token || !tokenRights.isWrite || isRejected}
              onChange={e => handleTokenRightsChange({ isRead: e.target.checked })}
            >Read</Checkbox>
            <div>
              <Checkbox
                defaultChecked={isWrite}
                disabled={token || !tokenRights.isRead || isRejected}
                onChange={e => handleTokenRightsChange({ isWrite: e.target.checked })}
              >Write</Checkbox>
            </div>
          </div>

          <div>
            <CopyToClipboard
              text={token}
              onCopy={() => token && handleCopy()}
            >
              <Tooltip title="Copy API Key">
                <CopyOutlined className={`website-builder--container--content--table-row--copy-icon ${!token ? "website-builder--container--content--table-row--copy-icon_disabled" : ""}`} />
              </Tooltip>
            </CopyToClipboard>
            {
              isApproved
                ? (
                  <Popconfirm
                    title="Are you sure you want to delete this API key?"
                    placement="left"
                    onConfirm={handleDelete}
                  >
                    <DeleteOutlined className="website-builder--container--content--table-row--copy-icon website-builder--container--content--table-row--copy-icon_delete" />
                  </Popconfirm>
                )
                : null
            }
          </div>
        </TableRow>
      </div>
      <Modal visible={modalOpen} closeIcon={() => null} footer={null} centered className="modal-block">
        <Formik onSubmit={onSubmit} initialValues={{ declineReason: "" }}>
          {
            formikProps => (
              <Form className="modal-decline__form">
                <p className="modal-decline__title">Decline Request?</p>
                <div>
                  <p className="modal-decline__text">Please, specify the reason of declining</p>
                  <Input.TextArea required onChange={e => formikProps.setValues({ declineReason: e.target.value })} />
                </div>
                <div className="modal-decline__btn-group">
                  <Button className="modal-decline__red-btn" htmlType="submit">
                    DECLINE
                  </Button>
                  <Button onClick={() => setModalOpen(false)}>
                    CANCEL
                  </Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </Modal>
    </>
  )
};

ApiKeyRequestRow.propTypes = {
  columnWidths: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
  handleChangeToken: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ApiKeyRequestRow;

import React from "react";
import PropTypes from "prop-types";


function EnquiriesIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-19.000000, -316.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-40-icon/24/enquiries" transform="translate(16.000000, 312.000000)">
              <path d="M3.9,4 L20.1,4 C20.5970563,4 21,4.39796911 21,4.88888889 L21,19.1111111 C21,19.6020309 20.5970563,20 20.1,20 L3.9,20 C3.40294373,20 3,19.6020309 3,19.1111111 L3,4.88888889 C3,4.39796911 3.40294373,4 3.9,4 Z M19.2,7.76711111 L12.0648,14.0782222 L4.8,7.74755556 L4.8,18.2222222 L19.2,18.2222222 L19.2,7.76711111 Z M5.2599,5.77777778 L12.0549,11.6995556 L18.7518,5.77777778 L5.2599,5.77777778 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

EnquiriesIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default EnquiriesIcon

import * as types from "./usersActionsTypes";

const initialState = {
  items: [],
  totalNumberOfUsers: 0,
  subscribers: [],
  totalNumberOfSubscribers: 0,
  loading: false,
  processing: false,
  error: "",
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_USERS_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.HIDE_USERS_LOADING:
      return {
        ...state,
        loading: false
      };

    case types.SHOW_USERS_PROCESSING:
      return {
        ...state,
        processing: true
      };

    case types.HIDE_USERS_PROCESSING:
      return {
        ...state,
        processing: false
      };

    case types.GET_USERS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        totalNumberOfUsers: action.payload.count
      };

    case types.GET_SUBSCRIBERS_LIST_SUCCESS:
      return {
        ...state,
        subscribers: action.payload?.results || [],
        totalNumberOfSubscribers: action.payload.count || 0
      };


    case types.RESET_ERRORS:
      return {
        ...state,
        error: ""
      };

    case types.CONVERT_USER_ROLE_SUCCESS:
      return {
        ...state,
        error: null,
        processing: false
      };

    case types.GET_USERS_LIST_REQUEST_ERROR:
    case types.DELETE_USER_REQUEST_ERROR:
    case types.CONVERT_USER_ROLE_FAILED:
    case types.PATCH_USER_REQUEST_ERROR:
    case types.GET_SUBSCRIBERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default users;

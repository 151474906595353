import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, message } from "antd";
import PropTypes from "prop-types";

import {
  getCurrencyCodesRequest
} from "../../../redux/settings/currency/currencyCodes/currencyCodesActions";

import {
  getPayPalRequest,
  patchPayPalRequest,
  resetErrorsAction
} from "../../../redux/settings/payments/paymentsActions";

import LabeledInput from "../../shared/LabeledInput";


function Payments(
  {
    history,
    getCurrency,
    getPayPal,
    updatePayPal,
    data,
    currency,
    loading,
    updated,
    errors,
    resetErrors,
    userRole,
  }) {
  const [business, setBusiness] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [location, setLocation] = useState("");
  const [orderAdminEmail, setOrderAdminEmail] = useState("");

  const isDisabled = !userRole.includes("Super Admin");

  // Effects
  useEffect(() => {
    getCurrency({ page: 1, perPage: 200 });
    getPayPal();

    return resetErrors;
  }, []); // eslint-disable-line

  useEffect(() => {
    if (updated) message.success("Updated", 1);
  }, [updated]); // eslint-disable-line

  useEffect(() => {
    if (data.currency) setSelectedCurrency(data.currency.id);
    setBusiness(data.business);
    setLocation(data.location);
    setOrderAdminEmail(data.orderAdminEmail);
  }, [data]); // eslint-disable-line

  // Methods
  const onSubmit = () => {
    updatePayPal({
      business,
      currency: selectedCurrency,
      location,
      orderAdminEmail
    })
  };

  return (
    <div className="component payments">
      <div className="general-form--container">
        <p className="general-form--title">Paypal</p>

        <LabeledInput
          label="PayPal Business"
          value={business}
          onChange={setBusiness}
          errorText={errors.business && errors.business[0]}
        />

        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="PayPal Currency Code"
              type="select"
              value={selectedCurrency}
              options={currency.map(e => ({ label: e.currency, value: e.id }))}
              onChange={value => setSelectedCurrency(value)}
              errorText={errors.currency && errors.currency[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="PayPal Location"
              value={location}
              onChange={setLocation}
              errorText={errors.location && errors.location[0]}
            />
          </div>
        </div>

        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="Order Admin Email"
              value={orderAdminEmail}
              onChange={setOrderAdminEmail}
              errorText={errors.orderAdminEmail && errors.orderAdminEmail[0]}
            />
          </div>
          <div className="general-form--column" />
        </div>

        <div className="general-form--button-holder">
          <Button loading={loading} className="ant-btn-primary" onClick={onSubmit} disabled={isDisabled}>
            SAVE & CLOSE
          </Button>
          <Button className="btn-secondary" onClick={() => history.goBack()} disabled={isDisabled}>
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  )
}

Payments.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  updatePayPal: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  getPayPal: PropTypes.func.isRequired,
  currency: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  errors: PropTypes.any,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    ...state.payments,
    currency: state.currencyCodes.items,
    userRole: state.auth.userProfile.roles,
  }),
  dispatch => ({
    getCurrency: payload => dispatch(getCurrencyCodesRequest(payload)),
    getPayPal: () => dispatch(getPayPalRequest()),
    updatePayPal: payload => dispatch(patchPayPalRequest(payload)),
    resetErrors: () => dispatch(resetErrorsAction())
  })
)(Payments);

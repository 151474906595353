export const SHOW_LOADING = "SHOW_GROUPS_LOADING";
export const HIDE_LOADING = "HIDE_GROUPS_LOADING";

export const SHOW_PROCESSING = "SHOW_GROUPS_PROCESSING";
export const HIDE_PROCESSING = "HIDE_GROUPS_PROCESSING";

export const RESET_ERROR = "RESET_GROUPS_ERROR";

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export const GET_GROUPS_REQUEST_SUCCESS = "GET_GROUPS_REQUEST_SUCCESS";
export const GET_GROUPS_REQUEST_ERROR = "GET_GROUPS_REQUEST_ERROR";

export const CREATE_GROUP_REQUEST = "CREATE_GROUP_REQUEST";
export const CREATE_GROUP_REQUEST_ERROR = "CREATE_GROUP_REQUEST_ERROR";

export const UPDATE_GROUP_REQUEST = "UPDATE_GROUP_REQUEST";
export const UPDATE_GROUP_REQUEST_ERROR = "UPDATE_GROUP_REQUEST_ERROR";

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_REQUEST_ERROR = "DELETE_GROUP_REQUEST_ERROR";

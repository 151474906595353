import React from "react";
import PropTypes from "prop-types";

// helpers
import formatPrice from "../../../../helpers/formatPrice";
import { selectCurrencySymbol } from "../../../../helpers/currency";


const transformOrderPrice = ({
  orderTotal,
  productTotal,
  shippingTotal,
  currency
}) => {
  const currencySymbol = selectCurrencySymbol(currency);

  return {
    currency,
    orderTotal: `${currencySymbol}${formatPrice(orderTotal)}`,
    productTotal: `${currencySymbol}${formatPrice(productTotal)}`,
    shippingTotal: `${currencySymbol}${formatPrice(shippingTotal)}`,
  };
}

const TotalsSection = ({ totalData }) => {
  const { currency, orderTotal, productTotal, shippingTotal } = transformOrderPrice(totalData);

  return (
    <div className="order-header--totals-wrapper">
      <div className="header-tab-column-title">Totals</div>
      <div className="totals-column-info">
        <div className="totals-column-info-field">
          <div className="header-tab-column-label">Currency</div>
          <div className="totals-currency-field-value">
            {currency || "-"}
          </div>
        </div>
        <div className="totals-column-info-field">
          <div className="header-tab-column-label">Product Total</div>
          <div className="totals-method-field-value">
            {productTotal || "-"}
          </div>
        </div>
        <div className="totals-column-info-field">
          <div className="header-tab-column-label">Shipping Total</div>
          <div className="totals-date-field-value">
            {shippingTotal || "-"}
          </div>
        </div>
        <div className="totals-column-info-field">
          <div className="header-tab-column-label">Order Total</div>
          <div className="totals-ref-field-value">
            {orderTotal || "-"}
          </div>
        </div>
      </div>
    </div>
  )
}

TotalsSection.propTypes = {
  totalData: PropTypes.object.isRequired
};

export default TotalsSection;

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Checkbox, Form, Input, message, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

// components
import { LantForm, LantFormItem, LantItemLayout } from "../../../components";

// react-queries
import { useTradeProfile, useTradeProfilePatch } from "../../../react-query";

// helpers
import { setServerErrorsToForm } from "../../../helpers";


export const EditTradeUser = () => {
  const { id } = useParams();

  const history = useHistory();
  const [form] = Form.useForm();

  const redirectToList = () => {
    history.replace("/trade-users");
  };

  const { data, error, isFetching } = useTradeProfile(id, {
    onSuccess: (resp) => {
      history.replace({
        pathname: `/trade-users/${resp.id}`,
        state: { path: resp.title }
      });

      const tradeWebsite = resp.website?.includes("https://")
        ? resp.website?.replace("https://", "")
        : resp.website;

      form.setFieldsValue({
        ...resp,
        email: resp.user?.email,
        website: tradeWebsite,
      });
    },
    enabled: !!id
  });

  const updateTradeProfile = useTradeProfilePatch({
    onSuccess: () => {
      message.success("Trade user was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      setServerErrorsToForm(form, err?.data);
    }
  });

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        const tradeWebsite = values.website?.includes("https://")
          ? values?.website
          : `https://${values?.website}`;

        updateTradeProfile.mutate({
          ...values,
          id,
          website: values.website ? tradeWebsite : null,
          isFairChanged: data?.isFair !== values.isFair,
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <LantItemLayout item="directory-category" error={error} processing={isFetching}>
      <LantForm
        form={form}
        title="Update trade profile"
        onSubmit={handleSubmit}
        onCancel={redirectToList}
      >
        <LantFormItem name="isFair" valuePropName="checked">
          <Checkbox>
            <span className="flex">
              Is Fair organiser
              <Tooltip title="Please be aware that change of trade user status will remove their existing directory information">
                <InfoCircleOutlined className="ml-2" />
              </Tooltip>
            </span>
          </Checkbox>
        </LantFormItem>
        <LantFormItem name="businessName" label="Business Name" lg={12}>
          <Input />
        </LantFormItem>
        <LantFormItem name="email" label="Email" lg={12}>
          <Input disabled />
        </LantFormItem>
        <LantFormItem name="phone" label="Phone" lg={12}>
          <Input />
        </LantFormItem>
        <LantFormItem name="website" label="Website" lg={12}>
          <Input addonBefore="https://" />
        </LantFormItem>
        <LantFormItem name="description" label="Description">
          <Input.TextArea />
        </LantFormItem>
      </LantForm>
    </LantItemLayout>
  );
};

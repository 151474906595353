import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Input } from "antd";

import { LantForm, LantFormItem } from "../../../components";

export const FairSpecialityForm = ({ form, title, onSubmit, onCancel }) => {
  const handleSubmit = () => {
    if (typeof onSubmit !== "function") {
      return;
    }

    form
      .validateFields()
      .then((values) => {
        onSubmit(values);
      })
      .catch((err) => console.error(err));
  };

  return (
    <LantForm
      form={form}
      initialValues={{ isVisible: false }}
      title={title}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    >
      <LantFormItem name="isVisible" valuePropName="checked">
        <Checkbox>Active</Checkbox>
      </LantFormItem>
      <LantFormItem
        name="title"
        label="Title"
        lg={12}
        rules={[{ required: true }]}
      >
        <Input />
      </LantFormItem>
      <LantFormItem
        name="name"
        label="Name"
        lg={12}
        rules={[{ required: true }]}
      >
        <Input />
      </LantFormItem>
      <LantFormItem name="description" label="Description">
        <Input.TextArea />
      </LantFormItem>
    </LantForm>
  );
};

FairSpecialityForm.propTypes = {
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

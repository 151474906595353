import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Checkbox, Button } from "antd";

//components
import LabeledInput from "../../../shared/LabeledInput";
import ImageUpload from "../../../shared/ImageUpload";

//redux
import {
  createNewCategoryRequest,
  uploadImageToServer
} from "../../../../redux/content/blog/blogActions";


const AddNewCategory = ({
  error,
  processing,
  createNewCategory,
  uploadImage,
  lastUploadedImage,
  history
}) => {
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("Category");
  const [isNameValid, setIsNameValid] = useState(true);
  const [url, setUrl] = useState(name);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [description, setDescription] = useState("description");
  const [categoryPicture, setCategoryPicture] = useState("");
  const [showErrorText, setShowErrorText] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const transformNameToURL = () => {
    const transformedURL = name.toLowerCase().replace(/ /ig, "-").replace(/&/ig, "and");
    setUrl(transformedURL);
  };

  const validateFields = () => {
    setIsNameValid(name !== "");
    setIsUrlValid(url !== "");
  };

  useEffect(() => {
    categoryPicture && uploadImage(categoryPicture);
  }, [categoryPicture]); // eslint-disable-line

  useEffect(() => {
    validateFields();
  }, [name, url]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing) return;
    if (error) return setShowErrorText(true);

    return history.push("/content/blog/categories");
  }, [error, processing]); // eslint-disable-line

  useEffect(() => {
    name && transformNameToURL();
  }, [name]); // eslint-disable-line

  const onCheck = (e) => {
    setIsActive(e.target.checked)
  };

  const _createNewCategory = () => {
    validateFields();
    if (!isNameValid || !isUrlValid) return;

    setFormSubmitted(true);

    createNewCategory({
      isVisible: isActive,
      name,
      url,
      description
    });
  };

  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  return (
    <div className="add-category">
      <div className="add-category--row">
        <span className="add-category--title">Add Category</span>
      </div>

      <div className="add-category--row checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isActive}
          onChange={onCheck}
        >
          Active
        </Checkbox>
      </div>

      <LabeledInput
        label="Name"
        isRequired
        value={name}
        onChange={(value) => setName(value)}
      />
      {
        !isNameValid
          ? <p className="error">Can't be blank. This field is required</p>
          : showErrorText
            ? <p className="error">Category with this name already exist</p>
            : null
      }
      <div className="add-category--url-input-holder">
        <LabeledInput
          label="URL"
          isRequired
          value={url}
          onChange={(value) => setUrl(value)}
          icon={urlFieldImage}
          inputClassName="input-with-prefix"
          addonBefore="https://www.loveantiques.com/blog/category/"
        />
        {
          !isUrlValid
            ? <p className="error">Can't be blank. This field is required</p>
            : null
        }
      </div>

      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={(value) => setDescription(value)}
        className="add-category--description-input"
      />

      <ImageUpload
        topText="Category Banner"
        setImage={setCategoryPicture}
        lastUploadedImage={lastUploadedImage || {}}
        bottomText="Image must be at least 1420 x 440 pixels"
        width={826}
        height={256}
      />

      <div className="add-category--button-holder">
        <Button
          className="ant-btn-primary"
          onClick={_createNewCategory}
        >
          SAVE & CLOSE
        </Button>
        <div className="del-btn-holder">
          <Button
            className="btn-secondary"
            onClick={() => history.push("/content/blog/categories")}
          >
            CANCEL
          </Button>
        </div>

      </div>
    </div>
  )
}

AddNewCategory.propTypes = {
  createNewCategory: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  error: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  lastUploadedImage: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => state.blogs,
  dispatch => ({
    createNewCategory: payload => dispatch(createNewCategoryRequest(payload)),
    uploadImage: payload => dispatch(uploadImageToServer(payload))
  })
)(AddNewCategory);

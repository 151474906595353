import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../Login/Login"
import ForgotPassword from "../ForgotPassword/ForgotPassword"
import ResetPassword from "../ResetPassword/ResetPassword"


export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/auth/forgot-password" component={ForgotPassword} />
      <Route exact path="/auth/reset-password" component={ResetPassword} />
    </Switch>
  )
}

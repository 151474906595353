import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";


export const GoogleAutocomplete = ({
  value,
  placeholder,
  options,
  onChange,
}) => {
  const [searchQuery, setSearchQuery] = useState(value);
  const inputRef = useRef();

  useEffect(() => {
    const google = window.google;

    const placeChangedTrigger = (autocomplete) => {
      const place = autocomplete.getPlace();
      const { lat, lng } = (place && place.geometry && place.geometry.location) || {};

      onChange({
        address_components: place.address_components,
        address: place.formatted_address || place.name || "",
        formatted_address: place.formatted_address || "",
        google_map_link: place.url || "",
        latitude: typeof lat === "function" ? lat().toFixed(6) : "",
        longitude: typeof lng === "function" ? lng().toFixed(6) : "",
        name: place.name,
        place_id: place.place_id
      });
    };

    if (google?.maps) {
      const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);
      autocomplete.addListener("place_changed", () => placeChangedTrigger(autocomplete));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(window.google || {})]);

  useEffect(() => {
    setSearchQuery(value);
  }, [value]);

  const handleKeyPress = evt => {
    if (evt.charCode === 13 || evt.key === "Enter") {
      evt.preventDefault();
    }
  };

  return (
    <input
      className="ant-input"
      type="text"
      placeholder={placeholder}
      ref={inputRef}
      value={searchQuery}
      onBlur={() => setSearchQuery(value)}
      onKeyPress={handleKeyPress}
      onChange={e => setSearchQuery(e.target.value)}
      onFocus={e => e.target.setAttribute("autocomplete", "new-password")}
    />
  );
};

GoogleAutocomplete.propTypes = {
  value: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

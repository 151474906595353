import React, { useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import PropTypes from "prop-types";

// helpers
import auth from "../../../api/auth";


function ResentButton({ usedId }) {
  const [loading, setLoading] = useState(false);


  const handleClick = () => {
    setLoading(true);

    auth.postResendConfirmationEmail({ user_id: usedId })
      .then(() => message.success("Confirmation email was resent successfully"))
      .catch(() => message.error("Something is wrong"))
      .finally(() => setLoading(false))
  }

  return (
    <div className="actions-column-resent-btn">
      {
        loading
          ? <Spin className="actions-column-resent-btn__loader" />
          : <RedoOutlined className="actions-column-resent-btn__icon" onClick={() => handleClick()} />
      }
    </div>
  );
}

ResentButton.propTypes = {
  usedId: PropTypes.number.isRequired,
}

export default ResentButton;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { searchOrdersRequest } from "../../../redux/orders/ordersActions";

import navigation from "../../../helpers/navigation";

class OrdersSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    }
    this.searchOrders = _.debounce(this.props.searchOrders, 250);
  }

  handleInputChange = () => {
    const { location, history } = this.props;
    const { page = 1, perPage = 10 } = navigation.getTableQueryParams(location);
    const firstPage = 1;
    const perPageDefault = 10;
    this.setState({
      query: this.search.value
    }, () => {
      if (this.state.query && this.state.query.length >= 1) {
        this.searchOrders({ page, perPage, query: this.state.query });
        navigation.setPage(firstPage, location, history);
        navigation.setPerPage(perPageDefault, location, history);
      }
    })
  }

  render() {
    return (
      <div className="search-input" id="orders-search">
        <img
          className="search-input--icon"
          src="/images/search_3x.svg"
          alt="search"
        />
        <input
          className="search-input--input"
          placeholder="Type to search"
          ref={input => this.search = input}
          onChange={this.handleInputChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={this.state.query}
        />
      </div>
    )
  }
}

OrdersSearch.propTypes = {
  searchOrders: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(
  ({ orders }) => orders,
  dispatch => ({
    searchOrders: payload => dispatch(searchOrdersRequest(payload)),
  })
)(withRouter(OrdersSearch));

import React, { useState } from "react";
import { Upload, Modal } from "antd";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


const MultipleImagesUploader = ({
  className = "",
  images,
  maxImagesNumber = 5,
  topLabel = "",
  bottomLabel = "",
  maxWidth = "100%",
  maxHeight = "256px",
  onImageDelete,
  onImageUpload,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState();

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      //eslint-disable-next-line
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ files }) => setFileList(files);

  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <>
      <div className="multiple-images-uploader--top-label">{topLabel}</div>
      <div className={`multiple-images-uploader ${className}`} >
        <Upload
          // action={actionUrl}
          listType="picture-card"
          fileList={images}
          customRequest={({ file }) => onImageUpload(file)}
          onRemove={file => onImageDelete(file)}
          onPreview={handlePreview}
          onChange={handleChange}
          style={{ maxWidth, maxHeight }}
        >
          {fileList && fileList.length >= maxImagesNumber ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
      <div className="multiple-images-uploader--bottom-label">{bottomLabel}</div>
    </>
  );
};

MultipleImagesUploader.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array,
  maxImagesNumber: PropTypes.number,
  topLabel: PropTypes.string,
  bottomLabel: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  onImageUpload: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired,
};

export default MultipleImagesUploader;

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import ItemsList from "./ItemsList";


function Items() {
  return (
    <>
      <Switch>
        <Route exact path="/items" component={ItemsList} />
        <Redirect to="/items" />
      </Switch>
    </>
  )
}

export default Items

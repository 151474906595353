import React, { useCallback, useEffect } from "react";
import { Table } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import useDimensions from "react-use-dimensions";
import update from "immutability-helper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

//components
import AddButton from "../../shared/AddButton";
import DraggableRow from "./DragableRow";
import ExternalLinkIcon from "../../Icon/ExternalLinkIcon";

//redux
import {
  getTradeAssociationsRequest,
  changeAssociationsOrderRequest,
  deleteTradeAssociationRequest
} from "../../../redux/dealers/dealersActions";


const DealerAssociations = ({
  getTradeAssociations,
  tradeAssociations,
  changeAssociationsOrder,
  deleteTradeAssociation
}) => {
  const [ref, { width }] = useDimensions({ liveMeasure: true });
  const isMobileView = width < 600;
  const history = useHistory();

  const components = {
    body: {
      row: DraggableRow,
    },
  };

  useEffect(() => {
    getTradeAssociations({ page: 1, pageSize: 100 });
  }, []); // eslint-disable-line

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = tradeAssociations[dragIndex];

      const updatedData = update(tradeAssociations, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      changeAssociationsOrder(
        updatedData.map((association, index) => ({ associationId: association.id, priority: index }))
      );
    },
    [tradeAssociations], // eslint-disable-line
  );

  const renderNameColumnContent = item => {
    return (
      <div>
        <img
          src={item.logo.mobileUrl}
          alt="Trade Association Logo"
          className="associations-table--logo"
        />
        <span className="associations-table--name">{item.name}</span>
      </div>
    )
  }

  const renderLinkColumnContent = item => {
    return (
      <div className="associations-table--link-container">
        <span className="associations-table--link">{item.link}</span>
        <a
          className="associations-table--link-icon"
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExternalLinkIcon />
        </a>
      </div>
    )
  }

  const renderActionsColumnContent = item => {
    return (
      <div className="associations-table--actions-container">
        <img
          className="associations-table--actions-container--icon"
          src="/images/edit@3x.svg"
          alt="Edit Icon"
          width="16"
          onClick={() => history.push({ pathname: `/dealers/trade-associations/edit/${item.id}` })}
        />
        <img
          className="associations-table--actions-container--icon"
          src="/images/delete_3x.svg"
          alt="Delete Icon"
          width="16"
          onClick={() => deleteTradeAssociation(item.id)}
        />
      </div>
    )
  }

  return (
    <div className="trade-associations">
      <div className="trade-associations--header">
        <AddButton onClick={() => history.push({ pathname: "/dealers/trade-associations/add" })} />
      </div>

      <div className="trade-associations--content">
        <div ref={ref} key={`table-${isMobileView ? "mobile" : "desktop"}`}>
          <DndProvider backend={isMobileView ? TouchBackend : HTML5Backend}>
            <Table
              className="associations-table"
              columns={[{
                title: "NAME",
                width: 200,
                render: renderNameColumnContent
              },
              {
                title: "LINK",
                width: 150,
                render: renderLinkColumnContent
              }, {
                title: "ACTIONS",
                width: 50,
                render: renderActionsColumnContent
              }
              ]}
              dataSource={tradeAssociations.map(association => ({ ...association, key: `association-${association.id}` }))}
              components={components}
              pagination={false}
              onRow={(record, index) => ({
                index,
                moveRow
              })}
            />
          </DndProvider>
        </div>
      </div>
    </div>
  )
}

DealerAssociations.propTypes = {
  getTradeAssociations: PropTypes.func.isRequired,
  tradeAssociations: PropTypes.array.isRequired,
  changeAssociationsOrder: PropTypes.func.isRequired,
  deleteTradeAssociation: PropTypes.func.isRequired
}

export default connect(
  state => ({
    tradeAssociations: state.dealers.tradeAssociations
  }), {
    getTradeAssociations: getTradeAssociationsRequest,
    changeAssociationsOrder: changeAssociationsOrderRequest,
    deleteTradeAssociation: deleteTradeAssociationRequest
  }
)(DealerAssociations);

import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";

//components
import SidebarRow from "./SidebarRow";
import SidebarPlainRow from "./SidebarPlainRow";

// helpers
import dashboard from "../../../api/dashboard";
import { SIDEBAR_MENU_ITEMS } from "../../../constants";

// redux
import { getGroupsRequest } from "../../../redux/groups/groupActions";
import { setDealersTestimonialsCountAction } from "../../../redux/dealers/dealersActions";

// react-query
import { useTradeSubscriptionCancelRequestList } from "../../../react-query";

const logoCmsIcon = "/images/logo-cms.svg";
const menuShowIcon = "/images/menu_show.svg";
const menuHideIcon = "/images/menu_hide.svg";

const Sidebar = ({
  groups,
  location,
  userRole,
  getGroups,
  dealersTestimonialsCount,
  setDealersTestimonialsCount,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [apiRequestsCount, setApiRequestsCount] = useState(0);
  const reversedGroups = cloneDeep(groups).reverse();

  const updateDealersCounters = () => {
    dashboard
      .getWebsiteLiveData()
      .then((resp) => {
        setDealersTestimonialsCount(resp.nonApprovedTestimonials);

        setApiRequestsCount(resp.nonApprovedApiRequests);
      })
      .catch(console.warn);
  };

  const cancelRequestsCount = useTradeSubscriptionCancelRequestList({
    page: 1,
    page_size: 1,
    status: "SUBMITTED",
  });

  // Get catalog groups, set openedKeys after reload.
  useEffect(() => {
    getGroups();

    updateDealersCounters();

    // Open target collapse item on rerender
    const openedItem = SIDEBAR_MENU_ITEMS.find(
      (menuItem) => menuItem.items && location.pathname.includes(menuItem.link)
    );
    if (openedItem) setOpenKeys([openedItem.link]);
  }, []); // eslint-disable-line

  const onOpenChange = (newOpenKeys) => {
    // User has collapsed menu item
    if (!newOpenKeys.length) return setOpenKeys([]);

    // User has uncollapsed menu item
    const lastKey = newOpenKeys[newOpenKeys.length - 1];
    setOpenKeys([lastKey]);

    if (lastKey === "/dealers") {
      updateDealersCounters();
    } else if (lastKey === "/directory") {
      cancelRequestsCount.refetch();
    }
  };

  const menuToRender = cloneDeep(SIDEBAR_MENU_ITEMS);

  if (reversedGroups.length) {
    const catalogIndex = findIndex(menuToRender, { label: "Catalog" });

    for (let i = 0; i < reversedGroups.length; i++) {
      const group = {
        label: reversedGroups[i].title,
        link: `/catalog/${reversedGroups[i].url}`,
      };
      let index = 3;
      menuToRender[catalogIndex].items.splice(index, 0, group);
      index++;
    }
  }

  return (
    <div className={`sidebar ${collapsed ? "sidebar__collapsed" : ""}`}>
      {!collapsed && (
        <div className="sidebar__logo-holder">
          <img src={logoCmsIcon} alt="" />
        </div>
      )}

      <div
        className={`sidebar--collapse ${collapsed ? "sidebar--collapse__collapsed" : ""}`}
        onClick={() => setCollapsed(!collapsed)}
      >
        <img src={collapsed ? menuShowIcon : menuHideIcon} alt="showMenu" />
      </div>

      <Menu
        triggerSubMenuAction="click"
        mode={collapsed ? "vertical" : "inline"}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {menuToRender.map((item) => {
          const { label, link, icon: Icon, items, userPermission } = item;

          // Check permission
          if (!userPermission.some((p) => userRole && userRole.includes(p))) {
            return null;
          }

          // Standalone menu item
          if (!items) {
            return (
              <Menu.Item key={link}>
                <SidebarRow
                  label={label}
                  link={link}
                  icon={Icon}
                  isStandalone
                />
              </Menu.Item>
            );
          }

          if (userRole.includes("Blogger")) {
            const blogLabel = items.find(
              (innerItem) => innerItem.label === "Blog"
            );

            return (
              <Menu.Item key={blogLabel.link}>
                <SidebarRow
                  label={blogLabel.label}
                  link={blogLabel.link}
                  icon={Icon}
                  isStandalone
                />
              </Menu.Item>
            );
          }

          // Accordion menu items
          const isHighlighted = items.some((inner) => {
            return location.pathname.includes(inner.link);
          });

          return (
            <Menu.SubMenu
              key={link}
              title={
                <SidebarPlainRow
                  label={label}
                  icon={Icon}
                  isHighlighted={isHighlighted}
                />
              }
            >
              {items.map((innerItem) => {
                const deniedSeoAdminTabs = ["Pages", "Help Text", "Groups"];
                let style = {};

                if (innerItem.label === "Home & Living") {
                  style = { borderTop: "1.5px #E6E6E6 solid" };
                }

                if (
                  userRole.includes("Admin Seo") &&
                  deniedSeoAdminTabs.includes(innerItem.label)
                ) {
                  return null;
                }
                
                if (
                  innerItem.link === "/dealers/dealers-testimonials" &&
                  dealersTestimonialsCount
                ) {
                  innerItem.label = (
                    <div className="sidebar--inner-menu__label-with-counter">
                      {innerItem.label}
                      <span className="sidebar--inner-menu__counter">
                        {dealersTestimonialsCount}
                      </span>
                    </div>
                  );
                } else if (
                  innerItem.link === "/dealers/api-key-requests" &&
                  apiRequestsCount
                ) {
                  innerItem.label = (
                    <div className="sidebar--inner-menu__label-with-counter">
                      {innerItem.label}
                      <span className="sidebar--inner-menu__counter">
                        {apiRequestsCount}
                      </span>
                    </div>
                  );
                } else if (
                  innerItem.link ===
                    "/directory/subscription-cancel-requests" &&
                  !!cancelRequestsCount.data?.count
                ) {
                  innerItem.label = (
                    <div className="sidebar--inner-menu__label-with-counter">
                      {innerItem.label}
                      <span className="sidebar--inner-menu__counter">
                        {cancelRequestsCount.data.count}
                      </span>
                    </div>
                  );
                }

                return (
                  <Menu.Item
                    key={innerItem.link}
                    className="sidebar--inner-menu"
                    style={style}
                  >
                    <SidebarRow
                      key={innerItem.link}
                      label={innerItem.label}
                      link={innerItem.link}
                      icon={innerItem.icon}
                      isInner
                    />
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        })}
      </Menu>
    </div>
  );
};

Sidebar.propTypes = {
  groups: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  userRole: PropTypes.array.isRequired,
  dealersTestimonialsCount: PropTypes.number.isRequired,
  getGroups: PropTypes.func.isRequired,
  setDealersTestimonialsCount: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      groups: state.groups.items,
      dealersTestimonialsCount: state.dealers.dealersTestimonialsCount,
      userRole: state.auth.userProfile.roles,
    }),
    (dispatch) => ({
      getGroups: () => dispatch(getGroupsRequest()),
      setDealersTestimonialsCount: (data) => {
        dispatch(setDealersTestimonialsCountAction(data));
      },
    })
  )(Sidebar)
);

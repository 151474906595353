import * as types from "./currencyCodesActionsTypes";


export const getCurrencyCodesRequest = params => {
  return { type: types.GET_CURRENCY_CODES_REQUEST, params };
};

export const createCurrencyCodesRequest = data => {
  return { type: types.CREATE_CURRENCY_CODES_REQUEST, data };
};

export const updateCurrencyCodesRequest = (id, data, isPatch = false) => {
  return { type: types.UPDATE_CURRENCY_CODES_REQUEST, data, id, isPatch };
};

export const deleteCurrencyCodesRequest = (payload) => {
  return { type: types.DELETE_CURRENCY_CODES_REQUEST, payload };
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR }
};

import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";


export const LantFormSection = ({ title, children, className, gutter = 16, }) => {
  return (
    <Col className={classNames("lant-form--section", className)} span={24}>
      {!!title && <div className="lant-form--title">{title}</div>}
      <Row gutter={gutter}>
        {children}
      </Row>
    </Col>
  )
}

LantFormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  gutter: PropTypes.number,
};

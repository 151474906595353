import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message, Spin, Form, Input } from "antd";
import PropTypes from "prop-types";
import lodash from "lodash";

// components
import { LantForm, LantFormItem, LantFormSection, UploadImagesTemp, UploadVideosTemp } from "../../../../components";
import { DirectoryEntryCoverImage } from "./DirectoryEntryCoverImage";
import { DEFAULT_LOGO_SETTINGS } from "../TextLogo";

// react-queries
import { useDirectoryEntryPatch, useFairPatch } from "../../../../react-query";

// constants
import { SOMETHING_IS_WRONG_ERROR } from "../../../../constants";

const ADDON = "https://";

const formatUrlForUi = (url) => {
  if (!url) {
    return url;
  }

  return url.includes(ADDON) ? url.replace(ADDON, "") : url;
}

const formatUrlForApi = (url) => {
  if (!url) {
    return url;
  }

  return url.includes(ADDON) ? url : `${ADDON}${url}`;
}

export const DirectoryEntryMedia = ({ data, isFair }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const textLogo = data?.textLogo || JSON.stringify(DEFAULT_LOGO_SETTINGS);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        facebook: formatUrlForUi(data?.facebook),
        twitter: formatUrlForUi(data?.twitter),
        instagram: formatUrlForUi(data?.instagram),
        linkedin: formatUrlForUi(data?.linkedin),
        youtube: formatUrlForUi(data?.youtube),
        tiktok: formatUrlForUi(data?.tiktok),
        googleMaps: formatUrlForUi(data?.googleMaps),
        videos: data.videos.map((el) => ({ ...el, video: el.video || {} }))
      });
    }
  }, [data]); // eslint-disable-line

  const redirectToList = () => {
    history.push(`/directory/${isFair ? "fairs" : "directory-entries"}`);
  };

  const updateDirectoryEntry = useDirectoryEntryPatch({
    onSuccess: () => {
      message.success("Directory logo was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      console.error(err)
      message.error(SOMETHING_IS_WRONG_ERROR);
    }
  });

  const updateFair = useFairPatch({
    onSuccess: () => {
      message.success("Fair was successfully updated");

      redirectToList();
    },
    onError: (err) => {
      console.error(err)
      message.error(SOMETHING_IS_WRONG_ERROR);
    }
  });

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const prevValues = {
          ...data,
          categories: data?.categories?.map((el) => el.id),
          specialities: data?.specialities?.map((el) => el.id)
        };
        const newValues = Object.entries(values).reduce((acc, [key, value]) => {
          if (key === "images" || key === "videos") {
            if (
              !lodash.isEqual(
                value?.map((el) => el.url || el.video?.url),
                prevValues[key]?.map((el) => el.url || el.video?.url)
              )
            ) {
              acc[key] = value;
            }
          } else if (
            [
              "facebook",
              "twitter",
              "instagram",
              "linkedin",
              "youtube",
              "tiktok",
              "googleMaps",
            ].includes(key)
          ) {
            acc[key] = formatUrlForApi(value);
          } else if (!lodash.isEqual(value, prevValues[key])) {
            acc[key] = value;
          }

          return acc;
        }, { id: data?.id });

        if (isFair) {
          updateFair.mutate(newValues);
        } else {
          updateDirectoryEntry.mutate(newValues);
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <div className="pt-32">
      <Spin spinning={false}>
        <LantForm
          form={form}
          onSubmit={handleSubmit}
          onCancel={redirectToList}
        >
          <LantFormSection title="Cover image">
            <DirectoryEntryCoverImage form={form} textLogo={textLogo} />
          </LantFormSection>
          <LantFormSection title="Upload images">
            <UploadImagesTemp name="images" maxImagesNumber={10} />
          </LantFormSection>
          <LantFormSection title="Upload videos">
            <UploadVideosTemp name="videos" />
          </LantFormSection>
          <LantFormSection title="Social links">
            <LantFormItem name="facebook" label="Facebook" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="twitter" label="Twitter" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="instagram" label="Instagram" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="linkedin" label="LinkedIn" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="youtube" label="YouTube" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="tiktok" label="TikTok" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
            <LantFormItem name="googleMaps" label="Google Maps" lg={8} md={12} xs={24}>
              <Input type="url" addonBefore={ADDON} />
            </LantFormItem>
          </LantFormSection>
        </LantForm>
      </Spin>
    </div>
  );
};

DirectoryEntryMedia.propTypes = {
  data: PropTypes.object,
  isFair: PropTypes.bool,
}

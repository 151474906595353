import React from "react";
import { withRouter, Redirect, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import lodash from "lodash";

import Groups from "./Groups/Groups"
import Artisans from "./Artisans";
import CatalogEntity from "./CatalogEntity/CatalogEntity";
import AddEditGroup from "./Groups/AddEditGroup";
import AddEditCatalogEntity from "./CatalogEntity/AddEditCatalogEntity";
import Categories from "./Categories/Categories";
import AddEditCategories from "./Categories/AddEditCategories";
import HomeAndLiving from "./HomeAndLiving/HomeAndLiving";
import AddEditHomeAndLiving from "./HomeAndLiving/AddEditHomeAndLiving";
import SeoCategories from "./SeoCategories/SeoCategories";
import Periods from "./Periods/Periods";
import AddEditPeriods from "./Periods/AddEditPeriods";


function Catalog(props) {
  const parts = props.location.pathname.split("/").filter(o => o);
  const targetPart = parts[1];
  // Search current url in groups fetched from server, if present than render CatalogEntity
  const targetEntity = lodash.find(props.groups, { url: targetPart });
  
  return (
    <div className="component">
      <Switch>
        <Route exact path="/catalog/seo-categories" component={SeoCategories} />
        <Route exact path="/catalog/seo-categories/add" component={SeoCategories} />
        <Route exact path="/catalog/groups" component={Groups} />
        <Route exact path="/catalog/groups/add" component={AddEditGroup} />
        <Route path="/catalog/artisan" component={Artisans} />
        <Route exact path="/catalog/categories" component={Categories} />
        <Route exact path="/catalog/categories/add" component={AddEditCategories} />
        <Route exact path="/catalog/home-and-living" component={HomeAndLiving} />
        <Route exact path="/catalog/home-and-living/add" component={AddEditHomeAndLiving} />
        <Route exact path="/catalog/period" component={Periods} />
        <Route exact path="/catalog/period/add" component={AddEditPeriods} />
        <Route exact path="/catalog/material" render={() => <CatalogEntity {...props} entity={targetEntity} key={targetPart} />} />
        <Route exact path="/catalog/material/add" render={() => <AddEditCatalogEntity {...props} entity={targetEntity} key={targetPart} />} />
        <Route exact path="/catalog/origin" render={() => <CatalogEntity {...props} entity={targetEntity} key={targetPart} />} />
        <Route exact path="/catalog/origin/add" render={() => <AddEditCatalogEntity {...props} entity={targetEntity} key={targetPart} />} />
        <Redirect to="/catalog/groups" />
      </Switch>
    </div>
  )
}

Catalog.propTypes = {
  groupsFetched: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    groupsFetched: state.groups.fetched,
    groups: state.groups.items
  })
)(withRouter(Catalog));

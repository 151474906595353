import { all, put, call, takeLatest } from "redux-saga/effects";

import artisans from "../../api/artisans";
import * as types from "./artisansActionTypes";
import navigation from "../../helpers/navigation";


export function* get({ params }) {
  try {
    let response = {};
    if (params.search) {
      response = yield call(artisans.search, params);
    }
    else {
      response = yield call(artisans.get, params);
    }

    yield put({ type: types.GET_ARTISANS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ARTISANS_REQUEST_ERROR, payload: error });
  }
}

export function* create({ data, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    yield call(artisans.create, data);

    yield put({ type: types.GET_ARTISANS_REQUEST, params: { page: 1, perPage: 10 } });

    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_ARTISANS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function* update({ url, data, isPatch, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    if (isPatch) {
      yield call(artisans.updatePatch, url, data);
    }
    else {
      yield call(artisans.updatePut, url, data);
    }

    yield put({ type: types.GET_ARTISANS_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_ARTISANS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function* remove({ url, callback }) {
  yield put({ type: types.SHOW_PROCESSING });
  yield put({ type: types.RESET_ERROR });

  try {
    const { page, perPage, search } = navigation.getTableQueryParams(window.location);

    yield call(artisans.delete, url);

    yield put({ type: types.GET_ARTISANS_REQUEST, params: { page, perPage, search } });
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    yield put({ type: types.GET_ARTISANS_REQUEST_ERROR, payload: error });
  } finally {
    yield put({ type: types.HIDE_PROCESSING })
  }
}

export function* getTags() {
  try {
    const response = yield call(artisans.getTags, {});

    yield put({ type: types.GET_ARTISANS_TAGS_REQUEST_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ARTISANS_REQUEST_ERROR, payload: error });
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET_ARTISANS_REQUEST, get),
    yield takeLatest(types.CREATE_ARTISANS_REQUEST, create),
    yield takeLatest(types.UPDATE_ARTISANS_REQUEST, update),
    yield takeLatest(types.DELETE_ARTISANS_REQUEST, remove),
    yield takeLatest(types.GET_ARTISANS_TAGS_REQUEST, getTags),
  ])
}

import { all, put, call, takeLatest } from "redux-saga/effects";

import * as types from "./overviewActionsTypes";
import invoicesApi from "../../../api/invoices";


function *getInvoicesOverview(action) {
  try {
    yield put({ type: types.TOGGLE_INVOICES_OVERVIEW_PROCESSING });
    const response = yield call(invoicesApi.getInvoicesOverview, action.payload);

    yield put({ type: types.GET_INVOICES_OVERVIEW_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_INVOICES_OVERVIEW_ERROR, payload: error });
  } finally {
    yield put({ type: types.TOGGLE_INVOICES_OVERVIEW_PROCESSING });
  }
}

export default function *() {
  yield all([
    yield takeLatest(types.GET_INVOICES_OVERVIEW_REQUEST, getInvoicesOverview),
  ])
}

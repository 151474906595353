import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import AddButton from "../../shared/AddButton";
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableFooter from "../../shared/Table/TableFooter";
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import AddEditHomeAndLiving from "./AddEditHomeAndLiving";
import HomeAndLivingRow from "./HomeAndLivingRow";

import { Utils } from "../../../helpers";
import navigation from "../../../helpers/navigation";
import {
  getHomeAndLivingListRequest,
  updateHomeAndLivingItemRequest,
  deleteHomeAndLivingItemRequest
} from "../../../redux/homeAndLiving/homeAndLivingActions";


const HomeAndLiving = ({
  history,
  location,
  homeAndLivingList,
  totalNumber,
  getHomeAndLivingList,
  updateHomeAndLivingItem,
  deleteHomeAndLivingItem
}) => {
  const [selected, setSelected] = useState(null);
  const { search, page, perPage, order } = navigation.getTableQueryParams(location);
  const itemsToRender = Utils.filterAndOrder(homeAndLivingList, { order })

  useEffect(() => {
    getHomeAndLivingList({ page, perPage, search });
  }, [page, perPage, search]); // eslint-disable-line

  const onAddClick = () => {
    history.push({ pathname: `${location.pathname}/add` });
  };

  useEffect(() => {
    if (!selected) {
      return;
    }

    const selectedElement = homeAndLivingList.find(el => el.id === selected.id);
    selectedElement && setSelected(selectedElement);
  }, [homeAndLivingList]); // eslint-disable-line

  const onStartEditClick = item => {
    if (selected) {
      return;
    }

    setSelected(item);
  };

  const onChangeActive = (url, event) => {
    updateHomeAndLivingItem({
      data: {
        isActive: event.target.checked
      },
      url
    });
  };

  const onRemove = (target) => {
    if (!target) {
      target = selected.url;
    }

    deleteHomeAndLivingItem(target);
  };

  const onSearch = value => {
    navigation.setDynamicKeys({ page: 1, search: value }, location, history);
  };

  const renderHeader = () => (
    <TableAndFormLayout.Header>
      <div className="home-and-living--header flex">
        <AddButton onClick={onAddClick} />
        <SearchInput value={search} onChange={onSearch} />
      </div>
    </TableAndFormLayout.Header>
  );

  const renderTable = () => (
    <TableAndFormLayout.Table>
      <Table
        className="home-and-living--table"
        columnWidths={["40px", "auto", "25%", "13%", "13%", "115px"]}
        headerCells={[
          {},
          { label: "Name" },
          { label: "Url" },
          { label: "Items" },
          { label: "Artisans" },
          { label: "Actions" }
        ]}
      >
        {
          ({ columnWidths }) => (
            itemsToRender.map(item => (
              <HomeAndLivingRow
                key={`catalog-entity:${item.id}`}
                columnWidths={columnWidths}
                item={item}
                onChangeActive={onChangeActive}
                onEdit={onStartEditClick}
                onRemove={onRemove}
                isSearch={!!search}
              />
            ))
          )
        }
        {
          totalNumber && (
            <TableFooter
              key="table-footer"
              page={page}
              perPage={perPage}
              total={totalNumber}
            />
          )
        }
      </Table>
    </TableAndFormLayout.Table>
  );

  const renderAddEditForm = () => (
    <TableAndFormLayout.Form>
      <AddEditHomeAndLiving
        item={selected}
        close={() => setSelected(null)}
        remove={onRemove}
      />
    </TableAndFormLayout.Form>
  );

  return (
    <div className="home-and-living">
      <TableAndFormLayout showForm={!!selected} formFullscreenOnly>
        {renderHeader()}
        {renderTable()}
        {renderAddEditForm()}
      </TableAndFormLayout>
    </div>
  );
};

HomeAndLiving.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  homeAndLivingList: PropTypes.array.isRequired,
  totalNumber: PropTypes.number.isRequired,
  getHomeAndLivingList: PropTypes.func.isRequired,
  updateHomeAndLivingItem: PropTypes.func.isRequired,
  deleteHomeAndLivingItem: PropTypes.func.isRequired
};

export default connect(
  state => state.homeAndLiving,
  dispatch => ({
    getHomeAndLivingList: params => dispatch(getHomeAndLivingListRequest(params)),
    updateHomeAndLivingItem: params => dispatch(updateHomeAndLivingItemRequest(params)),
    deleteHomeAndLivingItem: params => dispatch(deleteHomeAndLivingItemRequest(params)),
  })
)(HomeAndLiving);

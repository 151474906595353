import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import moment from "moment";

// components
import TableAndFormLayout from "../../shared/TableAndFormLayout/TableAndFormLayout";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";

// helpers
import userManagementApi from "../../../api/userManagement";
import navigation from "../../../helpers/navigation";

const SearchQueryLog = () => {
  const location = useLocation();

  const [searchQueryLogData, setSearchQueryLogData] = useState([]);
  const [searchQueryLogCount, setSearchQueryLogCount] = useState(0);
  const [searchQueryLogProcessing, setSearchQueryLogProcessing] = useState(false);

  const { page, perPage } = navigation.getTableQueryParams(location);


  useEffect(() => {
    setSearchQueryLogProcessing(true);
    userManagementApi.getSearchQueryLogs({ page, pageSize: perPage })
      .then(searchQueryLogsResp => {
        setSearchQueryLogData(searchQueryLogsResp.results);
        setSearchQueryLogCount(searchQueryLogsResp.count)
      })
      .catch(console.warn)
      .finally(() => setSearchQueryLogProcessing(false))
  }, [page, perPage]);

  if (searchQueryLogProcessing) {
    return <Spin size="large" />
  }

  return (
    <div className="px-30">
      <TableAndFormLayout.Table>
        <Table
          className="search-query-logs--table"
          columnWidths={["25%", "20%", "25%", "15%", "15%"]}
          headerCells={[
            { label: "Search Query" },
            { label: "Type of User" },
            { label: "Timestamp" },
            { label: "Search Results" },
            { label: "Search Query Count" }
          ]}
        >
          {searchQueryLogData.map(searchQueryLog => {
            return (
              <TableRow key={searchQueryLog.id}>
                <div>{searchQueryLog.query}</div>
                <div>{searchQueryLog.userType}</div>
                <div>{moment(searchQueryLog.createdAt).format("DD-MMM-YYYY HH:mm:ss")}</div>
                <div>{searchQueryLog.searchResultAmount}</div>
                <div>{searchQueryLog.searchQueryCount}</div>
              </TableRow>
            )
          })}

          {searchQueryLogCount && (
            <TableFooter key="tableFooter" page={page} perPage={perPage} total={searchQueryLogCount} />
          )}
        </Table>
      </TableAndFormLayout.Table>
    </div>
  )
}

export default SearchQueryLog;

import fetch from "./customFetch";
import objToQuery from "../helpers/objToQuery";

const invoices = {

  async getInvoices({ page = 1, perPage = 10, query }) {
    try {
      return await fetch({
        url: `/invoices/?page=${page}&page_size=${perPage}&${objToQuery(query)}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoicesSearch({ page = 1, perPage = 10, search, query }) {
    try {
      return await fetch({
        url: `/invoices/search/?page=${page}&page_size=${perPage}&query=${search}&${objToQuery(query)}`,
        method: "GET"
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoicesDealersCharge({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/invoices/dealers/charge/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoiceDealers({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/invoices/dealers/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoiceTaxRates({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/invoices/tax-rates/?page=${page}&page_size=${perPage}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async patchInvoiceTaxRates(data) {
    try {
      return await fetch({
        url: `/invoices/tax-rates/${data.id}/`,
        method: "PATCH",
        data
      })
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async postInvoiceTaxRate(data) {
    try {
      return await fetch({
        url: "/invoices/tax-rates/",
        method: "POST",
        data
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteInvoice(id) {
    try {
      return await fetch({
        url: `/invoices/${id}/`,
        method: "DELETE"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoicesOverview(months) {
    try {
      return await fetch({
        url: `/invoices/overview/?months=${months}`,
        method: "GET"
      });
    }
    catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getInvoicePDF (id) {
    try {
      return await fetch({
        url: `/invoices/${id}/generate-pdf/`,
        method: "GET",
        responseType: "blob",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default invoices;

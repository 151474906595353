import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Checkbox } from "antd";

import LabeledInput from "../../../shared/LabeledInput";
import GeneralFormControl from "../../../shared/GeneralFormControl";

import {
  createCurrencyCodesRequest,
  updateCurrencyCodesRequest,
  resetErrorAction
} from "../../../../redux/settings/currency/currencyCodes/currencyCodesActions";


function AddEditCurrencyCodes(props) {
  const { item, history, close, remove, processing, error } = props;

  const [submitted, setSubmitted] = useState(false);

  const [active, setActive] = useState(item ? item.isActive : true);
  const [currency, setCurrency] = useState(item ? item.currency || "" : "");
  const [description, setDescription] = useState(item ? item.description || "" : "");
  const [dlm, setDlm] = useState(item ? item.dlm || "" : "");
  const [isMain, setIsMain] = useState(item ? item.isMain || false : false);

  const isEdit = !!item;

  // Reset error on component unmount
  useEffect(() => props.resetError, []); // eslint-disable-line

  // Close form on successful submit
  useEffect(() => {
    if (!submitted || processing || error) return;

    if (isEdit) close();
    else history.push("/settings/currency/codes");
  }, [processing, submitted, error]); // eslint-disable-line


  // Methods
  const onSave = () => {
    setSubmitted(true);

    const data = {
      isActive: active,
      currency: currency,
      description: description,
      isMain,
    };
    if (dlm) data.dlm = dlm;

    if (isEdit) return props.updateCurrencyCode(item.id, data);
    props.createCurrencyCode(data);
  };

  const onCancel = () => {
    if (isEdit) return remove();
    history.push("/settings/currency/codes")
  };


  // Render
  const err = typeof error === "object" ? error : {};

  return (
    <div className="currency">
      <div className="general-form">
        <div className="general-form--row">
          <p className="general-form--title">{isEdit ? "Edit" : "Add"} Currency</p>

          {isEdit && <GeneralFormControl close={close} hideToggleFullscreen/>}
        </div>

        <div className="general-form--row general-form--checkbox-holder">
          <Checkbox
            className="checkbox-small"
            checked={active}
            onChange={({ target }) => setActive(target.checked)}
          >
            Active
          </Checkbox>
        </div>


        <div className="general-form--row general-form--checkbox-holder">
          <Checkbox
            className="checkbox-small"
            checked={isMain}
            onChange={({ target }) => setIsMain(target.checked)}
          >
            Main currency
          </Checkbox>
        </div>

        <LabeledInput
          label="Currency"
          value={currency}
          onChange={setCurrency}
          isRequired
          hasError={!!err.currency}
        />

        <LabeledInput
          label="Description"
          type="textarea"
          value={description}
          onChange={setDescription}
          hasError={!!err.description}
        />

        <LabeledInput
          label="DLM"
          type="date-time"
          value={dlm}
          onChange={setDlm}
          hasError={!!err.dlm}
        />

        <div className="general-form--button-holder">
          <Button className="ant-btn-primary" onClick={onSave} loading={processing}>
            SAVE & CLOSE
          </Button>

          <Button onClick={onCancel}>
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </div>
      </div>
    </div>
  )
}

AddEditCurrencyCodes.propTypes = {
  history: PropTypes.object,
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  createCurrencyCode: PropTypes.func.isRequired,
  updateCurrencyCode: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    processing: state.currencyCodes.processing,
    error: state.currencyCodes.error
  }),
  dispatch => ({
    createCurrencyCode: data => dispatch(createCurrencyCodesRequest(data)),
    updateCurrencyCode: (id, data) => dispatch(updateCurrencyCodesRequest(id, data, false)),
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddEditCurrencyCodes);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Select,
  Spin,
  Empty,
  Popconfirm,
  Radio,
  message
} from "antd";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import get from "lodash/get";

//components
import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";
import GeneralFormControl from "../../shared/GeneralFormControl";

//redux
import {
  createCategoriesRequest,
  updateCategoriesRequest,
  resetErrorAction,
  getCategoryFAQRequest,
  createCategoryFAQRequest,
  patchCategoryFAQRequest,
} from "../../../redux/categories/categoriesActions";

//helpers
import CategoriesApi from "../../../api/categories";
import ContentApi from "../../../api/content";


function AddEditCategories(props) {
  const { item, close, remove, history, location, processing, error } = props;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [gettingParents, setGettingParents] = useState(false);
  const [parentOptions, setParentOptions] = useState(item && item.parentItem ? [item.parentItem] : []);
  const [linkedCategoryLoading, setLinkedCategoryLoading] = useState(false);

  const [active, setActive] = useState(item ? item.isActive : true);
  const [isSeoMain, setIsSeoMain] = useState(item ? item.isSeoMain : false);
  const [parent, setParent] = useState(item ? item.parent : "");
  const [title, setTitle] = useState(item ? item.title || "" : "");
  const [subheader, setSubheader] = useState(item ? item.subheader || "" : "");
  const [url, setUrl] = useState(item ? item.url || "" : "");
  const [overrideUrl, setOverrideUrl] = useState(item ? item.overrideUrl || "" : "");
  const [image, setImage] = useState(item ? item.image || false : false);
  const [bannerImage, setBannerImage] = useState(item ? item.banner || false : false);
  const [metaDescription, setMetaDescription] = useState(item ? item.metaDescription || "" : "");
  const [description, setDescription] = useState(item ? item.description || "" : "");
  const [bottomDescription, setBottomDescription] = useState(item ? item.bottomDescription || "" : "");
  const [selectedLinkedCategory, setSelectedLinkedCategory] = useState(
    item && !isEmpty(item.linkedCategory)
      ? item.linkedCategory.map(category => ({ key: category.id, label: category.title }))
      : []
  );
  const [linkedCategories, setLinkedCategories] = useState([]);
  const [categoryHeading, setCategoryHeading] = useState(item ? item.h1 || "" : "");
  const [pageTitle, setPageTitle] = useState(item ? item.pageTitle || "" : "");
  const [faqSchema, setFaqSchema] = useState(item ? item.faqSchema || "" : "");
  const [markupSchema, setMarkupSchema] = useState(item ? item.markupSchema || "" : "");

  const [descriptionEditorType, setDescriptionEditorType] = useState("rich-text");
  const [bottomDescriptionEditorType, setBottomDescriptionEditorType] = useState("rich-text");

  const fullURL = get(item, "fullUrl", "");
  const urlPrefix = item && fullURL.replace(`/${item.url}`, "/");

  const isEdit = !!item;
  const oldUrlBeforeEdit = item ? item.url || "" : "";

  const hasDeletePermission = props.userRole.some(role => role === "Super Admin") || !isEdit;


  useEffect(() => {
    item && props.getCategoryFAQ(item.id);
  }, []); // eslint-disable-line

  // Reset error when component unmounts

  useEffect(() => props.resetError, []); // eslint-disable-line

  // Add default parent if there is a query param

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const parentUrl = query.get("parent");

    if (parentUrl) {
      CategoriesApi.getOne(parentUrl).then(result => {
        setParent(result.id);
        setParentOptions([result])
      }).catch(console.warn)
    }
  }, []); // eslint-disable-line

  // Close form after successful submit
  useEffect(() => {
    if (!processing) {
      return;
    }
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) {
      return;
    }

    if (isEdit) {
      return close();
    }
    history.push({ pathname: location.pathname.replace("/add", "") });
  }, [formSubmitted, processing, error]); // eslint-disable-line

  useEffect(() => {
    setLinkedCategoryLoading(true);
    CategoriesApi.get({ isParentCategories: true })
      .then(response => setLinkedCategories(response.results))
      .catch(console.warn)
      .finally(() => setLinkedCategoryLoading(false))
  }, []); // eslint-disable-line


  // Methods

  const getParents = value => {
    if (!value) {
      return;
    }

    setGettingParents(true);
    setParentOptions([]);
    CategoriesApi.search({ page: 1, perPage: 200, search: value }).then(result => {
      setGettingParents(false);
      setParentOptions(result.results);
    }).catch(console.warn)
  };
  const getParentsDebounced = debounce(getParents, 150);

  const getLinkedCategory = value => {
    if (!value) {
      return;
    }

    setLinkedCategoryLoading(true);
    setLinkedCategories([]);
    CategoriesApi.search({ page: 1, perPage: 200, search: value }).then(result => {
      setLinkedCategoryLoading(false);
      setLinkedCategories(result.results);
    }).catch(console.warn)
  };
  const getLinkedCategoryDebounced = debounce(getLinkedCategory, 150);

  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file).then(result => {
      if (type === "image") {
        setImage(result.response);
      }
      if (type === "banner") {
        setBannerImage(result.response);
      }
    }).catch(console.warn)
  };

  const onSave = () => {
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }

    const callback = () => message.success(`Category was successfully ${isEdit ? "updated" : "created"}`);

    const data = {
      title: title,
      catalogTitle: `Antique ${title} For Sale UK | LoveAntiques.com`,
      heading: `Antique ${title}`,
      h1: categoryHeading,
      pageTitle,
      faqSchema,
      markupSchema,
      subheader: subheader,
      metaDescription: metaDescription,
      url: url,
      description: description,
      bottomDescription: bottomDescription,
      isActive: active,
      isSeoMain,
      linkedCategory: selectedLinkedCategory.map(category => category.value || category.key),
    };
    if (parent) {
      data.parent = parent;
    }
    if (image) {
      data.image = image;
    }
    if (bannerImage) {
      data.banner = bannerImage;
    }

    if (isEdit) {
      return props.updateCategories(oldUrlBeforeEdit, data, callback);
    }
    props.createCategories(data, callback);
  };


  // Render

  const err = typeof error === "object" ? error : {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  // do not show pop confirmation when creating item

  let isModalConfirmationDisabled = true;
  if (isEdit) {
    isModalConfirmationDisabled = item.url === url;
  }

  let handleSubmit = onSave;
  if (!isModalConfirmationDisabled) {
    handleSubmit = () => { }; // pass control to pop up
  }

  let titleError = "";
  let urlError = "";

  if (error.title) {
    titleError = error.title[0];
  }
  if (error.url) {
    urlError = error.url[0];
  }

  return (
    <div className="general-form add-catalog-entity">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"}</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={active}
          onChange={({ target }) => setActive(target.checked)}
        >
          Active
        </Checkbox>
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={isSeoMain}
          onChange={({ target }) => setIsSeoMain(target.checked)}
        >
          Is seo main
        </Checkbox>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <div className="labeled-input">
            <span className="labeled-input--label">Parent Category</span>
            <Select
              showSearch
              allowClear
              onSearch={getParentsDebounced}
              filterOption={false}
              notFoundContent={
                gettingParents ?
                  <Spin size="small" /> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
              }
              className="add-catalog-entity--select labeled-input--input"
              value={parent}
              onChange={setParent}
            >
              {
                parentOptions.map(el => {
                  return <Select.Option key={el.url} value={el.id}>{el.title}</Select.Option>
                })
              }
            </Select>
          </div>

          <LabeledInput
            label="URL"
            value={url}
            onChange={setUrl}
            hasError={!!error.url}
            inputClassName="input-with-prefix"
            addonBefore={urlPrefix ? urlPrefix : "https://www.loveantiques.com/"}
            isRequired
            suffix={urlFieldImage}
            errorText={urlError}
          />

          <span className="labeled-input--label">Linked Category</span>
          <Select
            showSearch
            allowClear
            onSearch={getLinkedCategoryDebounced}
            filterOption={false}
            mode="multiple"
            notFoundContent={
              linkedCategoryLoading ?
                <Spin size="small" /> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
            }
            className="add-catalog-entity--select labeled-input--input"
            value={selectedLinkedCategory}
            onChange={setSelectedLinkedCategory}
            labelInValue
          >
            {
              linkedCategories.map(el => {
                return <Select.Option key={el.url} value={el.id}>{el.title}</Select.Option>
              })
            }
          </Select>
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="Title"
            value={title}
            onChange={setTitle}
            isRequired
            hasError={!!error.title}
            errorText={titleError}
          />

          <LabeledInput
            label="Page Title"
            value={pageTitle}
            onChange={setPageTitle}
            hasError={!!error.pageTitle}
          />

          <LabeledInput
            label="Meta Description"
            type="textarea"
            value={metaDescription}
            onChange={setMetaDescription}
            hasError={!!err.metaDescription}
          />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="Override Page Title"
            value={`Antique ${title} For Sale UK | LoveAntiques.com`}
            hasError={!!err.catalogTitle}
            readonly
          />

          <LabeledInput
            label="Override Heading"
            value={`Antique ${title}`}
            hasError={!!err.heading}
            readonly
          />

          <LabeledInput label="Override Subheader" value={subheader} onChange={setSubheader} hasError={!!err.subheader} />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="Override URL"
            value={overrideUrl}
            onChange={setOverrideUrl}
            icon={<img src="/images/link_3x.svg" alt="" />}
            hasError={!!err.overrideUrl}
          />
        </div>
      </div>

      <div className="general-form--row general-form--row__align-start">
        <div className="add-catalog-entity--image-picker">
          <ImageUpload
            lastUploadedImage={image}
            setImage={file => uploadImage(file, "image")}
            topText="Category Image"
            bottomText="Image must be at least 300 x 300 pixels"
            width={256}
            height={256}
          />
        </div>

        <ImageUpload
          lastUploadedImage={bannerImage}
          setImage={file => uploadImage(file, "banner")}
          topText="Category Banner"
          bottomText="Image must be at least 1420 x 440 pixels"
          width={826}
          height={256}
        />
      </div>

      <LabeledInput
        label="H1"
        value={categoryHeading}
        onChange={setCategoryHeading}
        hasError={!!err.h1}
      />

      <div className="editor-type-switcher">
        <Radio.Group value={descriptionEditorType} onChange={e => setDescriptionEditorType(e.target.value)}>
          <Radio value="rich-text">
            Editor
          </Radio>
          <Radio value="textarea">
            HTML Editor
          </Radio>
        </Radio.Group>
      </div>

      <LabeledInput
        label="Description"
        type={descriptionEditorType}
        value={description}
        onChange={setDescription}
        hasError={!!err.description}
      />

      <div className="editor-type-switcher">
        <Radio.Group value={bottomDescriptionEditorType} onChange={e => setBottomDescriptionEditorType(e.target.value)}>
          <Radio value="rich-text">
            Editor
          </Radio>
          <Radio value="textarea">
            HTML Editor
          </Radio>
        </Radio.Group>
      </div>

      <LabeledInput
        label="Bottom Description"
        type={bottomDescriptionEditorType}
        value={bottomDescription}
        onChange={setBottomDescription}
        hasError={!!err.bottomDescription}
      />

      <div className="mt-15">
        <LabeledInput
          label="FAQ Schema"
          type="textarea"
          value={faqSchema}
          onChange={setFaqSchema}
          hasError={!!err.faqSchema}
        />
      </div>

      <div className="mt-15">
        <LabeledInput
          label="Markup Schema"
          type="textarea"
          value={markupSchema}
          onChange={setMarkupSchema}
          hasError={!!err.markupSchema}
        />
      </div>

      <div className="general-form--button-holder">
        <Popconfirm
          placement="top"
          title="Are you sure you want to change the URL?"
          okText="Yes"
          cancelText="No"
          disabled={isModalConfirmationDisabled}
          onConfirm={onSave}
        >
          <Button
            className="ant-btn-primary"
            onClick={handleSubmit}
            loading={processing}
          >
            SAVE & CLOSE
          </Button>
        </Popconfirm>

        <Popconfirm
          placement="top"
          title="Are you sure you want to delete this category?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => remove(item.url)}
          disabled={!hasDeletePermission || !isEdit}
        >
          <Button
            onClick={!isEdit ? () => history.push({ pathname: location.pathname.replace("/add", "") }) : () => { }}
            disabled={!hasDeletePermission}
          >
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </Popconfirm>
      </div>
    </div>
  )
}

AddEditCategories.propTypes = {
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  createCategories: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  categoryFAQs: PropTypes.array.isRequired,
  getCategoryFAQ: PropTypes.func.isRequired,
  createCategoryFAQ: PropTypes.func.isRequired,
  patchCategoryFAQ: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    processing: state.categories.processing,
    error: state.categories.error,
    categoryFAQs: state.categories.categoryFAQs || [],
    userRole: state.auth.userProfile.roles
  }),
  dispatch => ({
    createCategories: (data, callback) => dispatch(createCategoriesRequest(data, callback)),
    updateCategories: (itemUrl, data, callback) => dispatch(updateCategoriesRequest(itemUrl, data, false, callback)),
    resetError: () => dispatch(resetErrorAction()),
    getCategoryFAQ: (payload) => dispatch(getCategoryFAQRequest(payload)),
    createCategoryFAQ: (payload) => dispatch(createCategoryFAQRequest(payload)),
    patchCategoryFAQ: (payload) => dispatch(patchCategoryFAQRequest(payload)),
  })
)(AddEditCategories);

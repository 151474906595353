export const GET_CONTENT_PAGES_REQUEST = "GET_CONTENT_PAGES_REQUEST";
export const GET_CONTENT_PAGES_REQUEST_SUCCESS = "GET_CONTENT_PAGES_REQUEST_SUCCESS";
export const GET_CONTENT_PAGES_REQUEST_ERROR = "GET_CONTENT_PAGES_REQUEST_ERROR";

export const PATCH_PAGES_STATUS_REQUEST = "PATCH_PAGES_STATUS_REQUEST";
export const PATCH_PAGES_STATUS_SUCCESS = "PATCH_PAGES_STATUS_SUCCESS";
export const PATCH_PAGES_STATUS_ERROR = "PATCH_PAGES_STATUS_ERROR";

export const DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST";
export const DELETE_PAGE_REQUEST_SUCCESS = "DELETE_PAGE_REQUEST_SUCCESS";
export const DELETE_PAGE_REQUEST_ERROR = "DELETE_PAGE_REQUEST_ERROR";

export const UPDATE_PAGE_REQUEST = "UPDATE_PAGE_REQUEST";
export const UPDATE_PAGE_REQUEST_SUCCESS = "UPDATE_PAGE_REQUEST_SUCCESS";
export const UPDATE_PAGE_REQUEST_ERROR = "UPDATE_PAGE_REQUEST_ERROR";

export const CREATE_NEW_PAGE_REQUEST = "CREATE_NEW_PAGE_REQUEST";
export const CREATE_NEW_PAGE_REQUEST_FAILED = "CREATE_NEW_PAGE_REQUEST_FAILED";
export const CREATE_NEW_PAGE_REQUEST_SUCCESS = "CREATE_NEW_PAGE_REQUEST_SUCCESS";

export const TOGGLE_PAGE_PROCESSING = "TOGGLE_PAGE_PROCESSING";

export const LOADING = "LOADING_PAGES";

import * as types from "./categoriesActionTypes";


export const getCategoriesRequest = params => {
  return { type: types.GET_CATEGORIES_REQUEST, params };
};

export const createCategoriesRequest = (data, callback) => {
  return { type: types.CREATE_CATEGORIES_REQUEST, data, callback };
};

export const updateCategoriesRequest = (url, data, isPatch, callback) => {
  return { type: types.UPDATE_CATEGORIES_REQUEST, url, data, isPatch, callback };
};

export const deleteCategoriesRequest = (url, callback) => {
  return { type: types.DELETE_CATEGORIES_REQUEST, url, callback };
};

export const resetErrorAction = () => {
  return { type: types.RESET_ERROR };
};

export const getCategoryFAQRequest = payload => {
  return { type: types.GET_CATEGORY_FAQ_REQUEST, payload };
};

export const createCategoryFAQRequest = payload => {
  return { type: types.CREATE_CATEGORY_FAQ_REQUEST, payload };
};

export const patchCategoryFAQRequest = payload => {
  return { type: types.PATCH_CATEGORY_FAQ_REQUEST, payload };
};

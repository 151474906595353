// helpers
import buildQueryString from "../helpers/buildQueryString";
import fetch from "./customFetch";


const admins = {
  async getAdminAccounts({ page = 1, perPage = 10 }) {
    try {
      return await fetch({
        url: `/settings/admins/?page=${page}&page_size=${perPage}`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getOne(id) {
    try {
      return await fetch({
        url: `/settings/admins/${id}/`,
        method: "GET"
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async deleteAdminAccount(id) {
    try {
      return await fetch({
        url: `/settings/admins/${id}/`,
        method: "DELETE",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async updateAdminAccount(data) {
    try {
      return await fetch({
        url: `/settings/admins/${data.id}/`,
        method: "PATCH",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async addAdminAccount(data) {
    try {
      return await fetch({
        url: "/settings/admins/",
        method: "POST",
        data
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getAdminRoles() {
    try {
      return await fetch({
        url: "/settings/admins/roles/",
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async uploadNewImage(data) {
    try {
      const response = await fetch({
        url: "/static/image/",
        headers: { "Content-Type": data.type },
        method: "POST",
        data,
      });

      return ({ response });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getAdminHistory({ page = 1, perPage = 10, fromDate, toDate, name, activityType }) {
    const query = buildQueryString({
      page,
      name,
      "page_size": perPage,
      "from_date": fromDate,
      "to_date": toDate,
      "activity_type": activityType,
    });

    try {
      return await fetch({
        url: `/settings/history/?${query}`,
        method: "GET",
      })
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async getFilterOptions() {
    try {
      return await fetch({
        url: "/settings/admins/activity-types/",
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async searchAdmins(query) {
    try {
      return await fetch({
        url: `/settings/admins/autocomplete/?query=${query}`,
        method: "GET",
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },

  async resetPassword(id, data) {
    try {
      return await fetch({
        url: `/settings/admins/${id}/reset-password/`,
        method: "POST",
        data
      });
    } catch (err) {
      if (err.data) {
        throw err.data;
      }

      throw err;
    }
  },
};

export default admins;

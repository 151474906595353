import React from "react";
import PropTypes from "prop-types";


function EarthIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill={fillColor} fillRule="evenodd">
        <g id="icon/24/edit-copy-89-icon/24/web" fill="#717171">
          <path d="M12,21 C7.0293,21 3,16.9707 3,12 C3,7.0293 7.0293,3 12,3 C16.9707,3 21,7.0293 21,12 C21,16.9707 16.9707,21 12,21 Z M17.7195,15.5568 L17.7195,15.4623 C17.7195,14.6325 17.7195,14.2536 17.1327,13.9179 C16.9440939,13.8109766 16.7503616,13.7133595 16.5522,13.6254 C16.2219,13.4751 16.0032,13.377 15.708,12.945 C15.6727551,12.8937014 15.6379538,12.8420995 15.6036,12.7902 C15.2931,12.3177 15.069,11.9775 14.2158,12.1125 C12.5373,12.3789 12.4131,12.6741 12.3393,13.1727 L12.3276,13.2546 C12.2187,13.9836 12.1989,14.2284 12.5031,14.5479 C13.6416,15.7422 14.3238,16.6035 14.5308,17.1075 C14.6316,17.3532 14.8908,18.0975 14.7126,18.8337 C15.8050419,18.3985662 16.7774407,17.7082279 17.5485,16.8204 C17.6475,16.4838 17.7195,16.0644 17.7195,15.5568 L17.7195,15.5568 Z M12,4.64969601 C9.9147,4.64969601 8.031,5.5191 6.6936,6.9141 C6.8529,7.0248 6.9915,7.1805 7.0869,7.3947 C7.2705,7.806 7.2705,8.2299 7.2705,8.6052 C7.2705,8.9004 7.2705,9.1812 7.365,9.3837 C7.4946,9.6609 8.0544,9.7797 8.5485,9.8823 C8.7258,9.9201 8.9076,9.9579 9.0732,10.0038 C9.5286,10.1298 9.8814,10.5393 10.1631,10.8678 C10.2801,11.0037 10.4538,11.2044 10.5411,11.2548 C10.5861,11.2224 10.731,11.0649 10.8021,10.8066 C10.8579,10.6086 10.8417,10.434 10.7616,10.3386 C10.2576,9.7446 10.2855,8.6016 10.4412,8.1795 C10.686,7.5144 11.451,7.5639 12.0108,7.5999 C12.2196,7.6134 12.4158,7.6269 12.5634,7.608 C13.1232,7.5378 13.296,6.6855 13.4175,6.519 C13.6803,6.159 14.4849,5.6163 14.9835,5.2815 C14.044347,4.86389357 13.0278143,4.64862783 12,4.64969601 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}

EarthIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default EarthIcon

export const GET_PAYPAL_REQUEST = "GET_PAYPAL_REQUEST";
export const GET_PAYPAL_REQUEST_SUCCESS = "GET_PAYPAL_REQUEST_SUCCESS";
export const GET_PAYPAL_REQUEST_ERROR = "GET_PAYPAL_REQUEST_ERROR";

export const PATCH_PAYPAL_REQUEST = "PATCH_PAYPAL_REQUEST";
export const PATCH_PAYPAL_REQUEST_SUCCESS = "PATCH_PAYPAL_REQUEST_SUCCESS";
export const PATCH_PAYPAL_REQUEST_ERROR = "PATCH_PAYPAL_REQUEST_ERROR";

export const RESET_ERRORS = "RESET_PAYMENTS_ERRORS";

export const SHOW_PAYMENT_PROCESSING = "SHOW_PAYMENT_PROCESSING";
export const HIDE_PAYMENT_PROCESSING = "HIDE_PAYMENT_PROCESSING";

export const SHOW_PAYMENT_LOADING = "SHOW_PAYMENT_LOADING";
export const HIDE_PAYMENT_LOADING = "HIDE_PAYMENT_LOADING";

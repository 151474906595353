import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Spin, Button } from "antd";
import _ from "lodash";

import ModalItem from "../../Items/AddCategories/ModalItem";
import { Utils } from "../../../helpers";
import findAllAttachments from "../../../helpers/findAllAttachments";

import {
  getCatalogEntityElementsRequest
} from "../../../redux/catalog/catalogActions";
import { getArtisansRequest } from "../../../redux/artisans/artisansActions";
import { getCategoriesRequest } from "../../../redux/categories/categoriesActions";

import artisansAPI from "../../../api/artisans";
import periodsApi from "../../../api/periods";
import categoriesApi from "../../../api/categories";
import catalogAPI from "../../../api/catalog";

const AddEntity = ({
  activeGroup,
  search,
  onClose,
  activeTab,
  dependenciesOfSelectedTab,
  onSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [listOfItems, setListOfItems] = useState([]);
  const [itemsToSave, setItemsToSave] = useState({});

  useEffect(() => {
    if (activeTab === "Artisans") {
      setLoading(true);
      artisansAPI.get({ page: 1, perPage: 200 }).then(res => {
        setListOfItems(res.results);
        setLoading(false);
      });
    } else if (activeTab === "Categories") {
      setLoading(true);
      categoriesApi.getSelectList({ page: 1, perPage: 200 }).then(res => {
        setListOfItems(res.results);
        setLoading(false);
      });
    } else if (activeTab === "Periods") {
      setLoading(true);
      periodsApi.get({ page: 1, perPage: 200 }).then(res => {
        setListOfItems(res.results);
        setLoading(false);
      });
    } else {
      setLoading(true);
      catalogAPI.getEntityElements(activeGroup.toLowerCase(), { page: 1, perPage: 200 }).then(res => {
        setListOfItems(res.results);
        setLoading(false)
      });
    }
  }, [activeTab]); // eslint-disable-line

  if (loading) {
    return (
      <div className="text-center wrapper">
        <Spin tip="Loading..." />
      </div>
    );
  }

  const onChange = (checked, item) => {
    const newItemsToSave = _.cloneDeep(itemsToSave);

    if (!newItemsToSave[activeTab]) {
      newItemsToSave[activeTab] = [...dependenciesOfSelectedTab];
    }

    if (checked) {
      newItemsToSave[activeTab].push(item);
    } else {
      newItemsToSave[activeTab] = newItemsToSave[activeTab].filter(e => e.url !== item.url);
    }
    setItemsToSave(newItemsToSave);
  };

  const filteredListOfItems = Utils.filterAndOrder(findAllAttachments(
    listOfItems,
    "children"),
  { order: {}, search, searchKey: "url" }
  );

  return (
    <div className="content">
      {
        filteredListOfItems.length ?
          filteredListOfItems.map((item, key) => (
            <ModalItem
              key={`${item.id}-${key}`}
              item={item}
              renderIds={true}
              checked={
                !!(itemsToSave[activeTab] || dependenciesOfSelectedTab).find(e => (e.url === item.url))
              }
              onChange={onChange}
            />
          )) :
          <div className="wrapper text-center">
            No data
          </div>
      }
      <div className="text-center home-and-living--add-entity-modal--action-buttons">
        <Button
          onClick={() => onSave(itemsToSave)}
          className="home-and-living--add-entity-modal--save-button"
          type="primary"
        >
          SAVE & CLOSE
        </Button>
        <Button
          onClick={() => onClose(false)}
        >
          CANCEL
        </Button>
      </div>
    </div>
  )
};

AddEntity.propTypes = {
  activeGroup: PropTypes.string,
  search: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  dependenciesOfSelectedTab: PropTypes.array.isRequired
};

export default connect(
  ({
    catalog,
    artisans,
    periods,
    categories
  }) => ({
    catalog,
    artisans,
    periods,
    categories
  }),
  dispatch => ({
    getCatalogEntityElements: (entityUrl, params) => dispatch(getCatalogEntityElementsRequest(entityUrl, params)),
    getArtisans: (entityUrl, params) => dispatch(getArtisansRequest(entityUrl, params)),
    getCategories: (entityUrl, params) => dispatch(getCategoriesRequest(entityUrl, params)),
  })
)(AddEntity);

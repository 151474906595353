import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Carousel, { Modal, ModalGateway } from "react-images";
import _ from "lodash";
import { Button, Checkbox, Collapse, Modal as ModalWindow } from "antd";

import LabeledInput from "../../shared/LabeledInput";
import AddEntity from "./AddEntity";
import ModalHeader from "../../Items/AddCategories/ModalHeader";
import ImageUpload from "../../shared/ImageUpload";
import GeneralFormControl from "../../shared/GeneralFormControl";
import CloseIcon from "../../Icon/CloseIcon";

import {
  updateHomeAndLivingItemRequest,
  createHomeAndLivingItemRequest
} from "../../../redux/homeAndLiving/homeAndLivingActions";

import ContentApi from "../../../api/content";

const AddEditHomeAndLiving = ({
  item,
  close,
  remove,
  history,
  location,
  processing,
  error,
  updateHomeAndLivingItem,
  createHomeAndLivingItem,
  groups,
  userRole
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [active, setActive] = useState(item ? item.isActive : true);
  const [title, setTitle] = useState(item?.title || "");
  const [overridePageTitle, setOverridePageTitle] = useState(item?.overridePageTitle || "");
  const [overrideHeading, setOverrideHeading] = useState(item?.overrideHeading || "");
  const [overrideSubheader, setOverrideSubheader] = useState(item?.overrideSubheader || "");
  const [url, setUrl] = useState(item?.url || "");
  const [overrideUrl, setOverrideUrl] = useState(item?.overrideUrl || "");
  const [image, setImage] = useState(item?.image || false);
  const [bannerImage, setBannerImage] = useState(item?.banner || false);
  const [metaDescription, setMetaDescription] = useState(item?.metaDescription || "");
  const [description, setDescription] = useState(item?.description || "");
  const [bottomDescription, setBottomDescription] = useState(item?.bottomDescription || "");

  let dependencies = {};

  if (item) {
    dependencies = {
      ...item.catalogsElements,
      Artisans: { items: item.artisans },
      Categories: { items: item.categories },
      Periods: { items: item.periods }
    }
  } else {
    dependencies = groups.reduce((res, el) => {
      res[el.title] = { url: el.url, items: [] }

      return res;
    }, {});
    dependencies.Artisans = { items: [] };
    dependencies.Categories = { items: [] };
    dependencies.Periods = { items: [] };
  }

  const [homeAndLivingDependencies, setHomeAndLivingDependencies] = useState(dependencies);

  const [showTitleError, setShowTitleError] = useState(false);
  const [showUrlError, setShowUrlError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);

  const isEdit = !!item;
  const oldUrlBeforeEdit = item ? item.url || "" : "";
  const hasDeletePermission = userRole.some(role => role === "Super Admin") || !isEdit;


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [catalogModalIsOpen, setCatalogModalIsOpen] = useState(false);
  const [activeModalItem, setActiveModalItem] = useState({});
  const [activeModalItemUrl, setActiveModalItemUrl] = useState("");
  const [searchCategoryModal, setSearchCategoryModal] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTabOfModal, setActiveTabOfModal] = useState("");

  // Mark the form as submitted
  useEffect(() => {
    if (!processing) {
      return;
    }
    setFormSubmitted(true);
  }, [processing]); // eslint-disable-line

  useEffect(() => {
    if (!formSubmitted || processing || error) {
      return;
    }
    if (isEdit) {
      close();
    } else {
      history.push({ pathname: location.pathname.replace("/add", "") });
    }
  }, [formSubmitted, processing, error]); // eslint-disable-line

  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file)
      .then(result => {
        if (type === "image") {
          setImage(result.response);
          setShowImageError(false);
        }
        if (type === "banner") {
          setBannerImage(result.response);
        }
      })
      .catch(console.warn);
  };

  const groupedCatalogsElementsIds = () => {
    const copyOfHomeAndLivingDependencies = _.cloneDeep(homeAndLivingDependencies);
    delete copyOfHomeAndLivingDependencies.Periods;
    delete copyOfHomeAndLivingDependencies.Artisans;
    delete copyOfHomeAndLivingDependencies.Categories;

    const catalogsElementsIds = Object.values(copyOfHomeAndLivingDependencies)
      .filter(e => e.items.length)
      .map(obj => obj.items.map(el => el.id)).flat();

    return catalogsElementsIds;
  };

  const onSave = () => {
    if (!title) {
      setShowTitleError(true);
    }
    if (!url) {
      setShowUrlError(true);
    }
    if (!image) {
      setShowImageError(true);
    }
    if (!title || !url || !image) {
      return;
    }
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }

    const artisanItems = _.get(homeAndLivingDependencies, "Artisans.items", []);
    const artisanIds = artisanItems === [] ? [] : artisanItems.map(el => el.id).flat();
    const categoriesItems = _.get(homeAndLivingDependencies, "Categories.items", []);
    const categoriesIds = categoriesItems.map(el => el.id).flat();
    const periodsItems = _.get(homeAndLivingDependencies, "Period.items", []);
    const periodsIds = periodsItems.map(el => el.id).flat() || [];

    const data = {
      title: title,
      catalogTitle: overridePageTitle,
      metaDescription: metaDescription,
      url: url,
      description: description,
      bottomDescription: bottomDescription,
      isActive: active,
      periods: periodsIds,
      categories: categoriesIds,
      artisans: artisanIds,
      catalogsElements: groupedCatalogsElementsIds(),
      overridePageTitle: overridePageTitle,
      overrideHeading: overrideHeading,
      overrideSubheader: overrideSubheader,
      overrideUrl: overrideUrl
    };

    if (image) {
      data.image = image;
    }
    if (bannerImage) {
      data.banner = bannerImage;
    }

    if (isEdit) {
      updateHomeAndLivingItem({ data, url: oldUrlBeforeEdit });
    } else {
      createHomeAndLivingItem(data);
    }
  };

  const onCancel = () => {
    if (isEdit) {
      remove(oldUrlBeforeEdit);
    } else {
      history.push({ pathname: "/catalog/home-and-living" });
    }
  };

  // Render
  const serverError = error || {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  const renderHeader = () => (
    <>
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"} Home And Living</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={active}
          onChange={setActive}
        >
          Active
        </Checkbox>
      </div>
    </>
  );

  const renderTitleAndURLRow = () => (
    <div className="general-form--row add-edit-home-and-living-entity--title-and-url-row">
      <div className="general-form--column">
        <LabeledInput
          label="Title"
          value={title}
          onChange={(value) => {
            showTitleError && setShowTitleError(false);
            setTitle(value);
          }}
          isRequired
          hasError={!!serverError.title}
        />
        {showTitleError && (
          <div className="category-image-uploader-error-text">
            Title can not be empty.
          </div>
        )}
      </div>

      <div className="general-form--column">
        <LabeledInput
          label="URL"
          value={url}
          onChange={(value) => {
            showUrlError && setShowUrlError(false);
            setUrl(value);
          }}
          isRequired
          hasError={!!serverError.url}
          suffix={urlFieldImage}
        />
        {showUrlError && (
          <div className="category-image-uploader-error-text">
            Url can not be empty.
          </div>
        )}
      </div>
    </div>
  );

  const renderOverrideInputs = () => (
    <div className="general-form--row override-inputs">
      <div className="general-form--column">
        <LabeledInput
          label="Override Page Title"
          value={overridePageTitle}
          onChange={setOverridePageTitle}
          hasError={!!(serverError.catalogTitle || serverError.overridePageTitle)}
        />
        <LabeledInput
          label="Override Heading"
          value={overrideHeading}
          onChange={setOverrideHeading}
          hasError={!!serverError.overrideHeading}
        />
        <LabeledInput
          label="Override Subheader"
          value={overrideSubheader}
          onChange={setOverrideSubheader}
          hasError={!!(serverError.subheader || serverError.overrideSubheader)}
        />
      </div>

      <div className="general-form--column">
        <LabeledInput
          label="Override URL"
          value={overrideUrl}
          onChange={setOverrideUrl}
          icon={<img src="/images/link_3x.svg" alt="" />}
          hasError={!!serverError.overrideUrl}
        />

        <LabeledInput
          label="Meta Description"
          type="textarea"
          className="home-and-living-text-area"
          value={metaDescription}
          onChange={setMetaDescription}
          hasError={!!serverError.metaDescription}
        />
      </div>
    </div>
  );

  const renderImageUploadRow = () => (
    <div className="general-form--row general-form--row__align-start">
      <div className="add-edit-home-and-living-entity--image-picker">
        <ImageUpload
          lastUploadedImage={image}
          setImage={file => uploadImage(file, "image")}
          topText="Category Image"
          bottomText="Image must be at least 300 x 300 pixels"
          height={256}
        />
        {showImageError && (
          <div className="category-image-uploader-error-text">
            Please attach an image. The image should be 300x300
          </div>
        )}
      </div>

      <ImageUpload
        lastUploadedImage={bannerImage}
        setImage={file => uploadImage(file, "banner")}
        topText="Category Banner"
        bottomText="Image must be at least 1420 x 440 pixels"
        height={256}
      />
    </div>
  );

  const renderDescriptions = () => (
    <>
      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={setDescription}
        hasError={!!serverError.description}
      />

      <LabeledInput
        label="Bottom Description"
        className="add-edit-home-and-living-entity--bottom-description"
        type="rich-text"
        value={bottomDescription}
        onChange={setBottomDescription}
        hasError={!!serverError.bottomDescription}
      />
    </>
  );

  const renderActionButtons = () => (
    <div className="general-form--button-holder">
      <Button
        className="ant-btn-primary"
        onClick={onSave}
        loading={processing}
      >
        SAVE & CLOSE
      </Button>

      <Button onClick={onCancel} disabled={!hasDeletePermission}>
        {isEdit && (
          <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />
        )}
        {isEdit ? "DELETE ITEM" : "CANCEL"}
      </Button>
    </div>
  );

  const updateHomeAndLivingDependencies = (id, key) => {
    const copyOfHomeAndLivingDependencies = _.cloneDeep(homeAndLivingDependencies);
    const updatedHomeAndLivingDependencies = copyOfHomeAndLivingDependencies[key].items.filter((el) => el.id !== id);
    copyOfHomeAndLivingDependencies[key].items = updatedHomeAndLivingDependencies;
    setHomeAndLivingDependencies(copyOfHomeAndLivingDependencies);
  };

  const renderCatalogElementsItems = (items, key) => {
    return items.map((e, index) => (
      <div key={`catalog-url__${index}`} className="disabled-input many">
        {e.url}
        <CloseIcon
          className="close-icon"
          onClick={() => updateHomeAndLivingDependencies(e.id, key)}
        />
      </div>
    ));
  };

  const renderCatalogElements = () => {
    return (
      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={Object.keys(homeAndLivingDependencies)}
      >
        <Collapse.Panel key={"Categories"} header={
          <>
            Categories
            <div
              onClick={e => openAddCategoryModal(e, "Categories", homeAndLivingDependencies)}
              className="add-category"
            >
              +
            </div>
          </>
        }>
          <div>
            {homeAndLivingDependencies.Categories.items.length ? (
              <>
                {renderCatalogElementsItems(homeAndLivingDependencies.Categories.items, "Categories")}
              </>
            ) : <div className="disabled-input no-assigned"><span>No Assigned</span></div>}
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  };

  const openAddCategoryModal = (e, key, dependenciesList) => {
    e.stopPropagation();

    setActiveTabOfModal(key);
    setActiveModalItemUrl(dependenciesList[key].url);
    setActiveModalItem({
      key,
      dependencies: dependenciesList
    });
    setCatalogModalIsOpen(true);
  };

  const saveChangesAndCloseModal = (updatedDependencies) => {
    const copyOfHomeAndLivingDependencies = _.cloneDeep(homeAndLivingDependencies);
    for (const key in updatedDependencies) {
      if (copyOfHomeAndLivingDependencies[key].items !== updatedDependencies[key]) {
        copyOfHomeAndLivingDependencies[key].items = updatedDependencies[key];
      }
    }

    setHomeAndLivingDependencies(copyOfHomeAndLivingDependencies);
    setCatalogModalIsOpen(false);
  };

  const modalTabChanged = (key, dependenciesList) => {
    setActiveTabOfModal(key);
    setActiveModalItemUrl(dependenciesList[key].url);
    setActiveModalItem({
      key: [key],
      dependencies: dependenciesList
    })
  };

  const toggleLightbox = selectedIndex => {
    setModalIsOpen(!modalIsOpen);
    setCurrentIndex(selectedIndex);
  };

  const renderModal = () => (
    <>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal allowFullscreen={false} onClose={toggleLightbox}>
            <Carousel
              currentIndex={currentIndex}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      {
        catalogModalIsOpen && <ModalWindow
          className="modal-block"
          centered
          visible
          onCancel={() => setCatalogModalIsOpen(false)}
          title={
            <ModalHeader
              modalTabChanged={modalTabChanged}
              categories={{ Categories: activeModalItem.dependencies.Categories }}
              activeKeys={activeModalItem.key || []}
              setSearch={setSearchCategoryModal}
              selectedItem={item}
            />
          }
          footer={null}
        >
          <AddEntity
            activeGroup={activeModalItemUrl}
            search={searchCategoryModal}
            setCategory={dependency => setActiveModalItem({
              key: activeModalItem.key,
              dependencies: {
                ...activeModalItem.dependencies,
                [activeModalItem.key]: dependency
              }
            }
            )}

            activeTab={activeTabOfModal}
            dependenciesOfSelectedTab={homeAndLivingDependencies[activeTabOfModal].items}
            onSave={saveChangesAndCloseModal}
            onClose={setCatalogModalIsOpen}
          />
        </ModalWindow>
      }
    </>
  );

  return (
    <div className="general-form add-edit-home-and-living-entity">
      {renderHeader()}
      {renderTitleAndURLRow()}
      {renderOverrideInputs()}
      {renderCatalogElements()}
      {renderImageUploadRow()}
      {renderDescriptions()}
      {renderActionButtons()}

      {renderModal()}
    </div>
  );
};

AddEditHomeAndLiving.propTypes = {
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.object,
  updateHomeAndLivingItem: PropTypes.func.isRequired,
  createHomeAndLivingItem: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    processing: state.homeAndLiving.processing,
    error: state.homeAndLiving.error,
    groups: state.groups.items,
    userRole: state.auth.userProfile.roles
  }),
  dispatch => ({
    updateHomeAndLivingItem: data => dispatch(updateHomeAndLivingItemRequest(data)),
    createHomeAndLivingItem: data => dispatch(createHomeAndLivingItemRequest(data)),
  })
)(AddEditHomeAndLiving);

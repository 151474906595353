import React from "react";
import PropTypes from "prop-types";


function Header({ children }) {
  return children;
}
Header.displayName = "Header";

function Table({ children }) {
  return children;
}
Table.displayName = "Table";

function Form({ children }) {
  return children;
}
Form.displayName = "Form";


function TableAndFormLayout({ children, showForm, isFormFullscreen, formFullscreenOnly }) {
  let header = null;
  let table = null;
  let form = null;

  React.Children.forEach(children, child => {
    if (child.type.displayName === "Header") return header = child;
    if (child.type.displayName === "Table") return table = child;
    if (child.type.displayName === "Form") return form = child;
  });

  const shouldShowForm = form && ((typeof showForm === "undefined" || showForm) || isFormFullscreen);
  const hideOthers = shouldShowForm && (isFormFullscreen || formFullscreenOnly);

  // Class names for table
  const tableClassNames = ["tafl--table"];
  if (shouldShowForm) tableClassNames.push("tafl--table__shrink");

  // Class names for form
  const formClassNames = ["tafl--form"];
  if (hideOthers) formClassNames.push("tafl--form__full");

  return (
    <div className="tafl">
      {!hideOthers && header}

      <div className="tafl--content">
        {!hideOthers && <div className={tableClassNames.join(" ")}>{table}</div>}
        {shouldShowForm && <div className={formClassNames.join(" ")}>{form}</div>}
      </div>
    </div>
  )
}
TableAndFormLayout.Header = Header;
TableAndFormLayout.Table = Table;
TableAndFormLayout.Form = Form;

TableAndFormLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  showForm: PropTypes.bool,
  isFormFullscreen: PropTypes.bool,
  formFullscreenOnly: PropTypes.bool,
};

export default TableAndFormLayout;

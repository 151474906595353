import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Checkbox } from "antd";

import {
  patchUserRequest,
  deleteUserRequest,
  resetErrorsAction,
} from "../../../redux/userManagement/users/usersActions";

import LabeledInput from "../../shared/LabeledInput";
import GeneralFormControl from "../../shared/GeneralFormControl";

function EditUser({
  item = {},
  filtersData,
  patchUser,
  isFullScreen,
  toggleFullscreen,
  close,
  processing,
  error,
  resetErrors,
}) {
  const [submitted, setSubmitted] = useState(false);
  const [active, setActive] = useState(item.isActive);
  const [userEmail, setUserEmail] = useState(item.email);
  const [userFirstName, setUserFirstName] = useState(item.firstName);
  const [userLastName, setUserLastName] = useState(item.lastName);

  // Reset error on component unmount
  useEffect(() => resetErrors, []); // eslint-disable-line

  // Close form on successful submit
  useEffect(() => {
    if (!submitted || processing || error) return;

    close();
  }, [processing, submitted, error]); // eslint-disable-line

  // Methods
  const onSave = () => {
    setSubmitted(true);

    patchUser({
      id: item.id,
      data: {
        isActive: active,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
      },
      ...filtersData,
    });
  };

  // Render
  const err = typeof error === "object" ? error : {};

  return (
    <div className="general-form">
      <div className="general-form--row">
        <p className="general-form--title">Edit User</p>
        <GeneralFormControl
          isFullScreen={isFullScreen}
          toggleFullscreen={toggleFullscreen}
          close={close}
        />
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox
          className="checkbox-small"
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
        >
          Active
        </Checkbox>
      </div>

      <LabeledInput
        label="User Email"
        value={userEmail}
        onChange={setUserEmail}
        isRequired
        errorText={err.email && err.email?.[0]}
      />

      <LabeledInput
        label="User First Name"
        value={userFirstName}
        onChange={setUserFirstName}
        isRequired
        errorText={err.firstName && err.firstName[0]}
      />
      <LabeledInput
        label="User Last Name"
        value={userLastName}
        onChange={setUserLastName}
        isRequired
        errorText={err.lastName && err.lastName[0]}
      />

      <div className="general-form--button-holder">
        <Button
          className="ant-btn-primary"
          onClick={onSave}
          loading={processing}
        >
          SAVE & CLOSE
        </Button>
      </div>
    </div>
  );
}

EditUser.propTypes = {
  item: PropTypes.object,
  filtersData: PropTypes.object.isRequired,
  patchUser: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool,
  toggleFullscreen: PropTypes.func,
  close: PropTypes.func,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};

export default connect(
  (state) => state.users,
  (dispatch) => ({
    patchUser: (payload) => dispatch(patchUserRequest(payload)),
    deleteUser: (payload) => dispatch(deleteUserRequest(payload)),
    resetErrors: () => dispatch(resetErrorsAction()),
  })
)(EditUser);

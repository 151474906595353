import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { Checkbox } from "antd";

// components
import AddButton from "../../../shared/AddButton";
import Table from "../../../shared/Table/Table";
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import { InlineIcon } from "../../../../icons";
import { ConfirmDeleteModal } from "../../../../components";

// helpers
import navigation from "../../../../helpers/navigation";
import { useDeleteSpeciality, useSpecialities, usePatchSpeciality } from "./hooks";

export const SpecialitiesList = () => {
  const [deletingItem, setDeletingItem] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const { page = 1, perPage = 10 } = navigation.getTableQueryParams(location);

  const type = location.pathname.includes("fairs") ? "fairs" : "directory";

  const { data: specialities, refetch } = useSpecialities({ type, params: { page, page_size: perPage } });
  const { mutateAsync: deleteSpeciality, isLoading: isDeleting } = useDeleteSpeciality({ type });
  const { mutateAsync: updateSpeciality } = usePatchSpeciality({ type });
  
  // methods
  const redirectToCreate = () => {
    history.push({ pathname: type === "fairs" ? "/directory/fairs-specialities/add" : "/directory/directory-specialities/add" });
  };

  const redirectToEdit = (item) => {
    history.push({
      pathname: [
        type === "fairs" ? "/directory/fairs-specialities" : "/directory/directory-specialities",
        item.id
      ].join("/"),
      state: { path: item.title }
    });
  };

  // renders
  return (
    <div className="fairs-specialities">
      <div className="component--header">
        <AddButton onClick={redirectToCreate} />
      </div>

      <Table
        className="fairs-specialities--table"
        columnWidths={["40px", "auto", "auto", "auto", "88px", "160px", "70px"]}
        headerCells={[
          {},
          { label: "Title" },
          { label: "Name" },
          { label: "Description" },
          { label: "Fairs count" },
          { label: "Created date" },
          { label: "Actions" },
        ]}
      >
        {specialities?.results?.map((item) => {
          return (
            <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
              <Checkbox
                checked={item.isVisible}
                onChange={() => updateSpeciality({ id: item.id, data: { isVisible: !item.isVisible } }).then(refetch)}
              />
              <div className="flex">
                {item.title}
              </div>
              <div className="flex">
                {item.name}
              </div>
              <div className="flex">
                {item.description}
              </div>
              <div className="flex">
                {item.specialityDirectoryEntries}
              </div>
              <div className="flex created-column">
                {moment(item.createdAt).format("DD-MMM-YYYY hh:mm:ss")}
              </div>
              <div className="flex lant-table--actions-column" style={{ fontSize: "16px" }}>
                <InlineIcon type="edit" onClick={() => redirectToEdit(item)} />
                <InlineIcon type="delete" danger onClick={() => setDeletingItem(item)} />
              </div>
            </TableRow>
          )
        })}
      </Table>

      {specialities?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={specialities.count}
        />
      )}

      <ConfirmDeleteModal
        title={`Delete ${deletingItem?.title}`}
        open={!!deletingItem?.id}
        processing={isDeleting}
        closeModal={() => setDeletingItem(null)}
        onConfirm={() => deleteSpeciality(deletingItem.id).then(() => setDeletingItem(null))}
      />
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";


function OrdersIcon({ fillColor = "#717171", className = "" }) {
  return (
    <svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -236.000000)" fill={fillColor} id="system/side/dealers-system/side/dashboard">
          <g>
            <g id="icon/empty-copy-35-icon/24/order" transform="translate(16.000000, 232.000000)">
              <path d="M17.6,20 L6.4,20 C5.0745166,20 4,18.9254834 4,17.6 L4,4.8 C4,4.3581722 4.3581722,4 4.8,4 L16,4 C16.4418278,4 16.8,4.3581722 16.8,4.8 L16.8,14.4 L20,14.4 L20,17.6 C20,18.9254834 18.9254834,20 17.6,20 Z M16.8,16 L16.8,17.6 C16.8,18.0418278 17.1581722,18.4 17.6,18.4 C18.0418278,18.4 18.4,18.0418278 18.4,17.6 L18.4,16 L16.8,16 Z M15.2,18.4 L15.2,5.6 L5.6,5.6 L5.6,17.6 C5.6,18.0418278 5.9581722,18.4 6.4,18.4 L15.2,18.4 Z M7.2,8 L13.6,8 L13.6,9.6 L7.2,9.6 L7.2,8 Z M7.2,11.2 L13.6,11.2 L13.6,12.8 L7.2,12.8 L7.2,11.2 Z M7.2,14.4 L11.2,14.4 L11.2,16 L7.2,16 L7.2,14.4 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

OrdersIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default OrdersIcon

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import PropTypes from "prop-types";

import {
  createEmailProfileRequest,
  resetErrorsAction
} from "../../../redux/settings/emailProfiles/emailProfilesActions";

import LabeledInput from "../../shared/LabeledInput";

function AddEmailProfile({ history, createEmailProfile, errors, processing, resetErrors }) {
  const [mailTemplateId, setMailTemplateId] = useState("");
  const [format, setFormat] = useState("HTML");
  const [fromEmailAddress, setFromEmailAddress] = useState("");
  const [fromEmailAddressName, setFromEmailAddressName] = useState("");
  const [ccEmailAddress, setCcEmailAddress] = useState("");
  const [ccEmailAddressName, setCcEmailAddressName] = useState("");
  const [body, setBody] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // Effects
  useEffect(() => {
    if (!submitted || processing) return;
    if (Object.keys(errors).length) return;

    history.push("/settings/email-profiles");
  }, [errors, processing]); // eslint-disable-line

  // clear errors on unmount component
  useEffect(() => resetErrors, []); // eslint-disable-line

  // Methods
  const validateFields = () => {
    if (errors) {
      setSubmitted(false);
      resetErrors();
    }
  };

  const onSubmit = () => {
    validateFields();
    setSubmitted(true);

    createEmailProfile({
      mailTemplateId,
      format,
      fromEmailAddress,
      fromEmailAddressName,
      ccEmailAddress,
      ccEmailAddressName,
      body
    })
  };

  return (
    <div className="component add-email-profile">
      <div className="general-form--container">
        <p className="general-form--title">Add Email Profile</p>

        <LabeledInput
          label="Mail Template ID"
          value={mailTemplateId}
          onChange={setMailTemplateId}
          isRequired
          errorText={errors.mailTemplateId && errors.mailTemplateId[0]}
        />

        <LabeledInput
          className="format"
          label="Format"
          type="select"
          options={["HTML", "TEXT"]}
          value={format}
          onChange={setFormat}
          errorText={errors.format && errors.format[0]}
        />

        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="From Email Address"
              value={fromEmailAddress}
              onChange={setFromEmailAddress}
              errorText={errors.fromEmailAddress && errors.fromEmailAddress[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="From Email Address Name"
              value={fromEmailAddressName}
              onChange={setFromEmailAddressName}
              errorText={errors.fromEmailAddressName && errors.fromEmailAddressName[0]}
            />
          </div>
        </div>
        <div className="general-form--row">
          <div className="general-form--column">
            <LabeledInput
              label="CC Email Address"
              value={ccEmailAddress}
              onChange={setCcEmailAddress}
              errorText={errors.ccEmailAddress && errors.ccEmailAddress[0]}
            />
          </div>
          <div className="general-form--column">
            <LabeledInput
              label="CC Email Address Name"
              value={ccEmailAddressName}
              onChange={setCcEmailAddressName}
              errorText={errors.ccEmailAddressName && errors.ccEmailAddressName[0]}
            />
          </div>
        </div>
      </div>

      <LabeledInput
        label="Body"
        type="textarea"
        value={body}
        onChange={setBody}
        rows={8}
        errorText={errors.body && errors.body[0]}
      />

      <div className="general-form--button-holder">
        <Button loading={processing} className="ant-btn-primary" onClick={onSubmit}>
          SAVE & CLOSE
        </Button>
        <Button className="btn-secondary" onClick={() => history.push("/settings/email-profiles")}>
          CANCEL
        </Button>
      </div>
    </div>
  )
}

AddEmailProfile.propTypes = {
  history: PropTypes.object.isRequired,
  createEmailProfile: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  errors: PropTypes.any,
  processing: PropTypes.bool.isRequired
};

export default connect(
  state => state.emailProfiles,
  dispatch => ({
    createEmailProfile: payload => dispatch(createEmailProfileRequest(payload)),
    resetErrors: () => dispatch(resetErrorsAction())
  })
)(AddEmailProfile);

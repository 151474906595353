import { useMutation, useQueryClient } from "react-query";

import fetch from "../../api/customFetch";

const fetcher = (id) => {
  return fetch({ url: `/directory/${id}/`, method: "DELETE" });
};

export const useDirectoryEntryDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation(fetcher, {
    ...options,
    onSuccess: (resp, ...args) => {
      queryClient.invalidateQueries(["directory-list"]);

      typeof options?.onSuccess === "function" && options.onSuccess(resp, ...args);
    }
  });
};

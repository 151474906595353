import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Select,
  Spin,
  Empty,
  message,
  Popconfirm
} from "antd";
import debounce from "lodash/debounce";
import get from "lodash/get";

//components
import LabeledInput from "../../shared/LabeledInput";
import ImageUpload from "../../shared/ImageUpload";
import GeneralFormControl from "../../shared/GeneralFormControl";

//redux
import {
  createPeriodsRequest,
  updatePeriodsRequest,
  resetErrorAction
} from "../../../redux/periods/periodsActions";

//helpers
import PeriodsApi from "../../../api/periods";
import ContentApi from "../../../api/content";


function AddEditPeriods({
  item,
  close,
  remove,
  history,
  location,
  processing,
  userRole,
  error,
  createCategories,
  updateCategories,
  resetError,
}) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [gettingParents, setGettingParents] = useState(false);
  const [parentOptions, setParentOptions] = useState(item && item.parentItem ? [item.parentItem] : []);
  const [active, setActive] = useState(item ? item.isActive : true);
  const [noIndex, setNoIndex] = useState(item && item.noIndex);
  const [parent, setParent] = useState(item ? item.parent : "");
  const [title, setTitle] = useState(item ? item.title || "" : "");
  const [subheader, setSubheader] = useState(item ? item.subheader || "" : "");
  const [url, setUrl] = useState(item ? item.url || "" : "");
  const [overrideUrl, setOverrideUrl] = useState(item ? item.overrideUrl || "" : "");
  const [image, setImage] = useState(item ? item.image || false : false);
  const [bannerImage, setBannerImage] = useState(item ? item.banner || false : false);
  const [metaDescription, setMetaDescription] = useState(item ? item.metaDescription || "" : "");
  const [description, setDescription] = useState(item ? item.description || "" : "");
  const [bottomDescription, setBottomDescription] = useState(item ? item.bottomDescription || "" : "");
  const [periodFromYear, setPeriodFromYear] = useState(item ? item.fromYear : "");
  const [periodToYear, setPeriodToYear] = useState(item ? item.toYear : "");
  const [h1Label, setH1Label] = useState(item ? item.h1 : "");
  const [showImageError, setShowImageError] = useState(false);

  const fullURL = get(item, "fullUrl", "");
  const urlPrefix = item && fullURL.replace(`/${item.url}`, "/");

  const isEdit = !!item;
  const oldUrlBeforeEdit = item ? item.url || "" : "";
  const pageTitle = `${title} Antiques For Sale UK | LoveAntiques.com`;
  const heading = title;
  const hasDeletePermission = userRole.some(role => role === "Super Admin") || !isEdit;

  // Add default parent if there is a query param
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const parentUrl = query.get("parent");

    if (parentUrl) {
      PeriodsApi.getOne(parentUrl).then(result => {
        setParent(result.id);
        setParentOptions([result])
      }).catch(console.warn)
    }

    // Reset error when component unmounts
    return resetError;
  }, []); // eslint-disable-line

  // Close form after successful submit
  useEffect(() => {
    if (!formSubmitted || processing || error) {
      return;
    }

    if (isEdit) {
      return close();
    }
    history.push({ pathname: location.pathname.replace("/add", "") })
  }, [formSubmitted, processing, error]); // eslint-disable-line


  // Methods
  const getParents = value => {
    if (!value) {
      return;
    }

    setGettingParents(true);
    setParentOptions([]);
    PeriodsApi.search({ page: 1, perPage: 200, search: value }).then(result => {
      setGettingParents(false);
      setParentOptions(result.results);
    }).catch(console.warn)
  };
  const getParentsDebounced = useCallback(debounce(getParents, 150), []);

  const uploadImage = (file, type) => {
    ContentApi.uploadNewImage(file).then(result => {
      if (type === "image") {
        setImage(result.response);
        setShowImageError(false);
      }
      if (type === "banner") {
        setBannerImage(result.response);
      }
    }).catch(console.warn)
  };

  const onSave = () => {
    if (isEdit && !oldUrlBeforeEdit) {
      return console.warn("something is broken");
    }

    const callback = () => message.success(`Period was successfully ${isEdit ? "updated" : "created"}`);

    setFormSubmitted(true);

    const data = {
      title: title,
      pageTitle: pageTitle,
      heading: heading,
      subheader: subheader,
      metaDescription: metaDescription,
      url: url,
      overrideUrl: overrideUrl,
      description: description,
      bottomDescription: bottomDescription,
      isActive: active,
      noIndex: noIndex,
      fromYear: periodFromYear,
      toYear: periodToYear,
      h1: h1Label
    };
    if (parent) {
      data.parent = parent;
    }
    if (image) {
      data.image = image;
    }
    if (bannerImage) {
      data.banner = bannerImage;
    }

    if (isEdit) {
      return updateCategories(oldUrlBeforeEdit, data, callback);
    }
    createCategories(data, callback);
  };


  // Render
  const err = typeof error === "object" ? error : {};
  const urlFieldImage = <img src="/images/link_3x.svg" alt="" />;

  return (
    <div className="general-form add-catalog-entity">
      <div className="general-form--row">
        <p className="general-form--title">{isEdit ? "Edit" : "Add"} Period</p>

        {isEdit && <GeneralFormControl close={close} hideToggleFullscreen />}
      </div>

      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox className="checkbox-small" checked={active} onChange={setActive}>Active</Checkbox>
      </div>
      <div className="general-form--row general-form--checkbox-holder">
        <Checkbox className="checkbox-small" checked={noIndex} onChange={(e) => setNoIndex(e.target.checked)}>Not index this period</Checkbox>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <div className="labeled-input">
            <span className="labeled-input--label">Parent Category</span>
            <Select
              showSearch
              allowClear
              onSearch={getParentsDebounced}
              filterOption={false}
              notFoundContent={
                gettingParents ?
                  <Spin size="small" /> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data. Type to search" />
              }
              className="add-catalog-entity--select labeled-input--input"
              value={parent}
              onChange={setParent}
            >
              {
                parentOptions.map(el => {
                  return <Select.Option key={el.url} value={el.id}>{el.title}</Select.Option>
                })
              }
            </Select>
          </div>

          <LabeledInput
            label="URL"
            value={url}
            onChange={setUrl}
            hasError={!!err.url}
            inputClassName="input-with-prefix"
            addonBefore={urlPrefix ? urlPrefix : "https://www.loveantiques.com/"}
            suffix={urlFieldImage}
          />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="Title"
            value={title}
            onChange={setTitle}
            isRequired
            hasError={!!err.title}
          />

          <LabeledInput
            label="H1"
            value={h1Label}
            onChange={setH1Label}
            hasError={!!err.h1Label}
          />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="From:"
            placeholder="Type year"
            className="period-field"
            value={periodFromYear}
            onChange={value => setPeriodFromYear(value)}
          />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="To:"
            placeholder="Type year"
            className="period-field"
            value={periodToYear}
            onChange={value => setPeriodToYear(value)}
          />
        </div>
      </div>

      <div className="general-form--row">
        <div className="general-form--column">
          <LabeledInput
            label="Override Page Title"
            value={pageTitle}
            hasError={!!err.catalogTitle}
            readonly
          />

          <LabeledInput
            label="Override Heading"
            value={heading}
            hasError={!!err.heading}
            readonly
          />

          <LabeledInput label="Override Subheader" value={subheader} onChange={setSubheader} hasError={!!err.subheader} />
        </div>

        <div className="general-form--column">
          <LabeledInput
            label="Override URL"
            value={overrideUrl}
            onChange={setOverrideUrl}
            icon={<img src="/images/link_3x.svg" alt="" />}
            hasError={!!err.overrideUrl}
          />

          <LabeledInput
            label="Meta Description"
            type="textarea"
            value={metaDescription}
            onChange={setMetaDescription}
            hasError={!!err.metaDescription}
          />
        </div>
      </div>

      <div className="general-form--row general-form--row__align-start">
        <div className="add-catalog-entity--image-picker">
          <ImageUpload
            lastUploadedImage={image}
            setImage={file => uploadImage(file, "image")}
            topText="Category Image"
            bottomText="Image must be at least 300 x 300 pixels"
            width={256}
            height={256}
          />
          {showImageError && (
            <div className="category-image-uploader-error-text">
              Please attach an image. The image should be 300x300
            </div>
          )}
        </div>

        <ImageUpload
          lastUploadedImage={bannerImage}
          setImage={file => uploadImage(file, "banner")}
          topText="Category Banner"
          bottomText="Image must be at least 1420 x 440 pixels"
          width={826}
          height={256}
        />
      </div>

      <LabeledInput
        label="Description"
        type="rich-text"
        value={description}
        onChange={setDescription}
        hasError={!!err.description}
      />

      <LabeledInput
        label="Bottom Description"
        type="rich-text"
        value={bottomDescription}
        onChange={setBottomDescription}
        hasError={!!err.bottomDescription}
      />

      <div className="general-form--button-holder">
        <Button className="ant-btn-primary" onClick={onSave} loading={processing}>
          SAVE & CLOSE
        </Button>

        <Popconfirm
          placement="top"
          title="Are you sure you want to delete this category?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => remove(item.url)}
          disabled={!hasDeletePermission || !isEdit}
        >
          <Button onClick={!isEdit ? () => history.push({ pathname: location.pathname.replace("/add", "") }) : () => { }} disabled={!hasDeletePermission}>
            {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
            {isEdit ? "DELETE ITEM" : "CANCEL"}
          </Button>
        </Popconfirm>

        {/* <Button onClick={onCancel} disabled={!hasDeletePermission}>
          {isEdit && <img className="ant-btn-icon" src="/images/delete_3x.svg" alt="" />}
          {isEdit ? "DELETE ITEM" : "CANCEL"}
        </Button> */}
      </div>
    </div>
  )
}

AddEditPeriods.propTypes = {
  item: PropTypes.object,
  close: PropTypes.func,
  remove: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  processing: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  createCategories: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  userRole: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    processing: state.catalog.processing,
    error: state.catalog.error,
    userRole: state.auth.userProfile.roles
  }),
  dispatch => ({
    createCategories: (data, callback) => dispatch(createPeriodsRequest(data, callback)),
    updateCategories: (itemUrl, data, callback) => dispatch(updatePeriodsRequest(itemUrl, data, false, callback)),
    resetError: () => dispatch(resetErrorAction()),
  })
)(AddEditPeriods);

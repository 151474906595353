import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Spin } from "antd";
import PropTypes from "prop-types";

// components
import TableRow from "../../../shared/Table/TableRow";
import TableFooter from "../../../shared/Table/TableFooter";
import Table from "../../../shared/Table/Table";

// react-queries
import { useAdminLogsList } from "../../../../react-query";

// helpers
import navigation from "../../../../helpers/navigation";
import { renderAdminLog } from "../../../../helpers";


export const DirectoryEntryHistory = ({ directoryEntry }) => {
  const location = useLocation();
  const { page, perPage } = navigation.getTableQueryParams(location);

  const { data: directoryLogs, isFetching } = useAdminLogsList({
    page: page,
    page_size: perPage,
    directory_entry: directoryEntry
  },
  {
    enabled: !!directoryEntry
  });

  return (
    <div className="directory-history">
      <Spin spinning={isFetching}>
        <Table
          className="directory-history--table"
          columnWidths={["auto", "130px", "160px"]}
          headerCells={[
            { label: "Description" },
            { label: "Activity time" },
            { label: "Updated by" }
          ]}
        >
          {directoryLogs?.results?.map((item) => {
            return (
              <TableRow key={`group:${item.id}`} style={{ fontSize: "12px" }}>
                <div className="flex">
                  {renderAdminLog(item)}
                </div>
                <div className="flex">
                  {moment(item.changedAt).format("DD/MM/YYYY HH:mm:ss")}
                </div>
                <div className="flex created-column">
                  {item.changedByName}
                </div>
              </TableRow>
            )
          })}
        </Table>

        {directoryLogs?.count > 0 && (
          <TableFooter
            key="footer-of-the-orders-table"
            page={page}
            perPage={perPage}
            total={directoryLogs.count}
          />
        )}
      </Spin>
    </div>
  );
};

DirectoryEntryHistory.propTypes = {
  directoryEntry: PropTypes.number,
}

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, message, Modal, Spin, Switch, Tooltip } from "antd";
import moment from "moment";

// components
import SearchInput from "../../shared/SearchInput";
import Table from "../../shared/Table/Table";
import TableRow from "../../shared/Table/TableRow";
import TableFooter from "../../shared/Table/TableFooter";
import { InlineIcon } from "../../../icons";
import { ConfirmDeleteModal, Impersonate } from "../../../components";

// hooks
import {
  useTradeProfileList,
  useTradeProfileDelete,
  useTradeProfilePatch,
} from "../../../react-query";

// helpers
import { buildFullName } from "../../../helpers";
import navigation from "../../../helpers/navigation";

// constants
import { SOMETHING_IS_WRONG_ERROR } from "../../../constants";

export const TraderList = () => {
  const [updatingRecord, setUpdatingRecord] = useState(null);
  const [deletingItem, setDeletingItem] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const { page, perPage, search } = navigation.getTableQueryParams(location);

  const closeConfirmModal = () => {
    setUpdatingRecord(null);
  };

  const { data, isFetching } = useTradeProfileList({
    page: page,
    page_size: perPage,
    email: search,
  });

  const updateTradeProfile = useTradeProfilePatch({
    onSuccess: () => {
      closeConfirmModal();
    },
    onError: (err) => {
      console.error(err);
      message.error(SOMETHING_IS_WRONG_ERROR);
    },
  });

  const deleteItem = useTradeProfileDelete({
    onSuccess: () => setDeletingItem(null),
    onError: (err) => console.error(err),
  });

  const onDelete = () => {
    deleteItem.mutate(deletingItem);
  };

  return (
    <div className="trade-users">
      <div className="flex justify-between mb-32">
        <SearchInput
          placeholder="Type to search by email"
          value={search}
          onChange={(value) => navigation.setSearch(value, location, history)}
        />
      </div>

      <Spin spinning={isFetching}>
        <Table
          className="crm-table directory-entries--table"
          columnWidths={[
            "70px",
            "auto",
            "auto",
            "auto",
            "auto",
            "120px",
            "92px",
          ]}
          headerCells={[
            { label: "STATUS" },
            { label: "EMAIL" },
            { label: "BUSINESS NAME" },
            { label: "USER NAME" },
            { label: "IS FAIR OWNER" },
            { label: "JOINED AT" },
            { label: "ACTIONS" },
          ]}
        >
          {data?.results?.map((item) => (
            <TableRow
              key={`trade-${item.id}`}
              style={{ fontSize: "12px", cursor: "pointer" }}
            >
              <div className="dealers-list--status-column flex justify-center">
                <div
                  className={
                    item.user?.isActive
                      ? "dealers-list--status-column--active-value-is-active"
                      : "dealers-list--status-column--unconfirmed"
                  }
                >
                  {item.user?.isActive ? "Active" : "Inactive"}
                </div>
              </div>
              <div className="flex">{item.user?.email || "-"}</div>
              <div className="flex">{item.businessName || "-"}</div>
              <div className="flex">{buildFullName(item.user, "-")}</div>
              <div className="flex">
                <Tooltip
                  placement="bottom"
                  title="Fair owner can create antique fairs only"
                >
                  <div className="dealers-list--switch">
                    <Switch
                      onChange={() => setUpdatingRecord(item)}
                      checked={!!item.isFair}
                      size="small"
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="flex">
                {item.user?.dateJoined
                  ? moment(item.user.dateJoined).format("YYYY-MM-DD")
                  : "-"}
              </div>
              <div className="flex lant-table--actions-column justify-end">
                <Impersonate type="trade user" id={item.user.id} />
                <InlineIcon
                  type="edit"
                  onClick={() => history.push(`/trade-users/${item.id}`)}
                />
                {item.user?.id && (
                  <InlineIcon
                    type="delete"
                    danger
                    onClick={() => setDeletingItem(item)}
                  />
                )}
              </div>
            </TableRow>
          ))}
        </Table>
      </Spin>

      {data?.count > 0 && (
        <TableFooter
          key="footer-of-the-orders-table"
          page={page}
          perPage={perPage}
          total={data.count}
        />
      )}

      <Modal
        title="Change Trade User"
        open={!!updatingRecord?.id}
        onCancel={closeConfirmModal}
        footer={[
          <Button key="cancel" onClick={closeConfirmModal}>
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            danger
            loading={updateTradeProfile.isLoading}
            onClick={() => {
              updateTradeProfile.mutate({
                id: updatingRecord.id,
                isFair: !updatingRecord.isFair,
                isFairChanged: true,
              });
            }}
          >
            Yes
          </Button>,
        ]}
      >
        {updatingRecord?.isFair ? (
          <div>
            Please be aware that change of trade user status to ‘Not Fair owner’
            will remove their existing fairs information from the directory
          </div>
        ) : (
          <div>
            Please be aware that change of trade user status to ‘Fair owner’
            will remove their existing business information from the directory
          </div>
        )}
      </Modal>

      <ConfirmDeleteModal
        title="Delete trade user"
        open={!!deletingItem?.id}
        processing={deleteItem.isLoading}
        closeModal={() => setDeletingItem(null)}
        onConfirm={onDelete}
      />
    </div>
  );
};

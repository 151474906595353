import * as types from "./itemsActionsTypes";

export const getItemsRequest = (payload, callBack) => {
  return { type: types.GET_ITEMS_REQUEST, payload, callBack };
};

export const patchItemsRequest = (payload) => {
  return { type: types.PATCH_ITEMS_DATA_REQUEST, payload };
};

export const getChoicesRequest = payload => {
  return { type: types.GET_CHOICES_REQUEST, payload };
};

export const deleteItemsCatalogElementRequest = payload => {
  return { type: types.DELETE_ITEMS_CATALOG_ELEMENT_REQUEST, payload };
};

export const updateItemsStatusRequest = payload => {
  return { type: types.UPDATE_ITEMS_STATUS_REQUEST, payload };
};

export const postRotateImage = payload => {
  return { type: types.ROTATE_IMAGE_REQUEST, payload };
};

export const updateShowOnHomeStatus = payload => {
  return { type: types.UPDATE_SHOW_ON_HOME_STATUS_REQUEST, payload };
};

export const updateShowOnHomeStatusError = payload => {
  return { type: types.UPDATE_SHOW_ON_HOME_STATUS_ERROR, payload };
};
